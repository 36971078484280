import {
  BranchesOutlined,
  EllipsisOutlined,
  PlusOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Col,
  Dropdown,
  Image,
  Modal,
  Row,
  Skeleton,
  Tooltip,
  Typography
} from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Tree from "react-d3-tree";
import { MdOutlineFullscreen, MdOutlineFullscreenExit } from "react-icons/md";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { HiChevronDown } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import plusIcon from "../../../assets/img/add-button.svg";
import addUser from "../../../assets/img/user-add.svg";
import { actionGetUsersOrganogram } from "../../../store/services/usersService";
import PageTitle from "../../common/PageTitle";
import CreateUser from "../../user/CreateUser";

const Organogram = () => {
  const containerRef = useRef(null);
  const svgContainerRef = useRef(null);
  const svgRef = useRef(null);
  const dispatch = useDispatch();
  const nodeRefs = useRef({});

  const { organogramHierachy, getUsersHierachyLoader } = useSelector(
    (state) => state.users
  );

  const [centerX, setCenterX] = useState(0);
  const [treeData, setTreeData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reportsTo, setReportsTo] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [highlightedNodes, setHighlightedNodes] = useState([]);

  const handle = useFullScreenHandle();
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    if (isFullScreen) {
      handle.exit();
    } else {
      handle.enter();
    }
    setIsFullScreen(!isFullScreen);
  };

  useEffect(() => {
    const onFullScreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", onFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", onFullScreenChange);
    };
  }, []);

  useEffect(() => {
    dispatch(actionGetUsersOrganogram());
  }, [dispatch]);

  const calculateCenter = useCallback(() => {
    if (containerRef.current) {
      const { offsetWidth } = containerRef.current;
      setCenterX(offsetWidth / 2);
    }
  }, []);

  useEffect(() => {
    calculateCenter();
    window.addEventListener("resize", calculateCenter);

    return () => window.removeEventListener("resize", calculateCenter);
  }, [calculateCenter]);

  useEffect(() => {
    if (containerRef.current) {
      calculateCenter();
    }
  }, [containerRef.current]);



  const onModalClose = () => {
    setReportsTo(null);
    setIsModalOpen(false);
  };

  const memoizedTreeData = useMemo(
    () => organogramHierachy,
    [organogramHierachy]
  );





  return (
    <div className="organogram-container" ref={containerRef}>
      <Row className="organogram-header mb-4">
        <Col span={12}>
          <PageTitle
            title="Organogram"
            icon={<BranchesOutlined style={{ fontSize: 20 }} />}
            rightIcon={false}
          />
        </Col>
        <Col span={12} className="flex gap-x-3 ml-auto">
          <Button
            className="root-btn text-white plus-button ml-auto hover:text-white py-5 flex-center !rounded-md"
            icon={<Image src={plusIcon} alt="Plus Icon" preview={false} />}
            children="Add New"
            onClick={() => setIsModalOpen(true)}
          />
          {/*   <Button
            onClick={exportAsSVG}
            className="mb-4"
          >
            <DownloadOutlined className="mr-2 h-4 w-4" /> Export as SVG
          </Button> */}
        </Col>
      </Row>

      <FullScreen handle={handle}>
        <div
          ref={svgContainerRef}
          style={{
            height: "100vh",
            width: "100%",
            overflow: "auto",
          }}
          className="orgonagram-card w-full relative flex-1 border bg-white border-neutralGray"
        >
          {isFullScreen ? (
            <MdOutlineFullscreenExit
              fontWeight={400}
              onClick={toggleFullScreen}
              className="mr-2 absolute cursor-pointer size-7 text-black/70 right-5 top-5"
            />
          ) : (
            <MdOutlineFullscreen
              fontWeight={400}
              onClick={toggleFullScreen}
              className="mr-2 absolute cursor-pointer size-7 text-black/70 right-5 top-5"
            />
          )}
          <Tree svgClassName="orgTreeSVG"
            /* className="organogram-svg" */
            style={{ maxWidth: "90%" }}
            data={memoizedTreeData}
            key={memoizedTreeData[0]?.attributes?.id || ""}
            renderCustomNodeElement={(rd3tProps) => (
              <CustomNode
                {...rd3tProps}
                setIsModalOpen={setIsModalOpen}
                setReportsTo={setReportsTo}
                loading={getUsersHierachyLoader}
                nodeRefs={nodeRefs}
              />
            )}
            orientation="vertical"
            centeringTransitionDuration={true}
            zoomable={true}
            zoom={1}
            pathFunc="elbow"
            pathClassFunc={() => "dotted-line"}
            translate={{ x: centerX, y: 100 }}
            enableLegacyTransitions={true}
            separation={{
              siblings: 2.1,
              nonSiblings: 2,
              yAxis: 2,
            }}
          />
        </div>
      </FullScreen>

      <Modal
        afterClose={onModalClose}
        footer={false}
        width={700}
        title={
          <div className="text-brand flex items-center gap-x-3">
            <Image
              src={addUser}
              alt="addUser"
              height={20}
              width={20}
              preview={false}
            />
            <h1 className="lg:text-lg text-base font-popinsRegular font-normal">
              Add New
            </h1>
          </div>
        }
        open={isModalOpen}
        onCancel={onModalClose}
        onOk={onModalClose}
      >
        <CreateUser
          isHierchy={true}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          reportsTo={reportsTo}
        />
      </Modal>
    </div>
  );
};

export default Organogram;

const CustomNode = React.memo(
  ({
    nodeDatum = {},
    toggleNode = () => { },
    setIsModalOpen = () => { },
    setReportsTo = () => { },
    loading = false,
    nodeRefs = {},
  }) => {
    if (!nodeDatum) {
      return null;
    }

    const childrenCount = nodeDatum.children ? nodeDatum.children.length : 0;

    const handleOpenModal = (e, node) => {
      setReportsTo(node?.attributes?.id);
      setIsModalOpen(true);
    };

    const isRoot =
      (nodeDatum?.attributes?.role &&
        nodeDatum.attributes.role?.toLowerCase() === "root") ||
      false;


    return (
      <>
        <g
          ref={(el) => {
            if (el) nodeRefs.current[nodeDatum?.attributes?.id] = el;
          }}
        >
          <foreignObject width="250" height="120" x="-90" y="-40">
            <div
              id={nodeDatum?.attributes?.id}
              className={`max-w-full bg-white shadow-md rounded-lg p-3 border border-[#d4d4d4] flex items-center gap-x-3 text-center min-w-44 w-full cursor-pointer hover:shadow-lg transition-all duration-300`}
            >
              <div className="w-10 h-10 rounded-full overflow-hidden bg-[#FDF4E7]">
                {loading ? (
                  <Skeleton.Avatar active size="large" />
                ) : nodeDatum?.attributes?.profile_pic ? (
                  <img
                    src={nodeDatum?.attributes?.profile_pic}
                    alt="Profile picture"
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="size-full flex items-center justify-center font-popinsSemiBold text-[#334155]">
                    {nodeDatum.name?.slice(0, 2)}
                  </div>
                )}
              </div>

              <div className="flex justify-start items-start flex-col max-w-44">
                <Typography.Paragraph
                  ellipsis={{ rows: 1 }}
                  title={nodeDatum.name}
                  className="text-base font-poppins text-left font-popinsMedium !mb-0 text-black w-40"
                >
                  {loading ? (
                    <Skeleton
                      title={false}
                      paragraph={{ rows: 1 }}
                      active
                      size="small"
                    />
                  ) : (
                    nodeDatum.name
                  )}
                </Typography.Paragraph>

                <Typography.Paragraph
                  title={nodeDatum.attributes?.email}
                  ellipsis={{ rows: 1 }}
                  className="text-slate-500 !mb-0 text-start font-popinsRegular text-xs w-40"
                >
                  {loading ? (
                    <Skeleton
                      title={false}
                      paragraph={{ rows: 1, width: 150 }}
                      active
                      size="small"
                    />
                  ) : (
                    nodeDatum?.attributes?.email
                  )}
                </Typography.Paragraph>

                {loading ? (
                  <Skeleton
                    title={false}
                    style={{ marginLeft: 20, width: 130 }}
                    paragraph={{ rows: 1 }}
                    active
                    size="small"
                  />
                ) : (
                  nodeDatum?.attributes?.role && (
                    <Badge
                      color={"cyan"}
                      text={
                        <span className="text-slate-600 text-xs font-popinsRegular">
                          {nodeDatum.attributes.role}
                        </span>
                      }
                    />
                  )
                )}

                {loading
                  ? null
                  : childrenCount > 0 && (
                    <div
                      className="mt-2 flex items-center gap-1 text-[11px] text-gray-600 hover:text-gray-900"
                      onClick={toggleNode}
                    >
                      <HiChevronDown
                        title={`Click to ${nodeDatum.__rd3t.collapsed ? "Expand" : "Collapse"
                          }`}
                        className={`h-4 w-4 transition-transform ${!nodeDatum.__rd3t.collapsed ? "rotate-180" : ""
                          }`}
                      />
                      <span>
                        {childrenCount}{" "}
                        {childrenCount === 1
                          ? "Direct Report"
                          : "Direct Reports"}
                      </span>
                    </div>
                  )}
              </div>
            </div>

            {!loading && !isRoot && (
              <Dropdown
                className="absolute right-3 top-2"
                trigger={["click"]}
                menu={{
                  items: [
                    {
                      key: "1",
                      label: "Add New",
                      icon: <PlusOutlined />,
                    },
                  ],
                  onClick: (e) => handleOpenModal(e, nodeDatum),
                }}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <EllipsisOutlined style={{ transform: "rotate(90deg)" }} />
                </a>
              </Dropdown>
            )}
          </foreignObject>
        </g>
      </>
    );
  }
);