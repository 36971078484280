import { PrinterOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Image,
  Result,
  Row,
  Skeleton,
  Spin,
  Table,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import leftPrimary from "../../assets/img/leftArrowPrimary.svg";
import { actionDownloadFile } from "../../store/services/commonService";
import { actionGetSalesInvoiceDetails } from "../../store/services/salesService";

const { Title, Text } = Typography;

const ViewSalesInvoice = () => {
  const { state } = useLocation();
  const { id } = state || "";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { salesInvoiceDetails, getSalesInvoiceDetailLoader } = useSelector(
    (state) => state.sales
  );

  const { currentCompanyUser } = useSelector((state) => state.auth);
  const {
    company,
    country,
    state: currentState,
    street,
    city,
    zip_code,
  } = currentCompanyUser || {};
  const { company_name } = company || {};

  if (!id) {
    return <Result status={"404"} title="Invoice Not Found" />;
  }

  useEffect(() => {
    if (id) {
      dispatch(actionGetSalesInvoiceDetails(id));
    }
  }, [id]);

  const handleDownload = () => {
    salesInvoiceDetails?.invoice_file
      ? dispatch(actionDownloadFile(salesInvoiceDetails?.invoice_file))
      : "";
  };

  const summaryItems = [
    { label: "Base Price", key: "total_base_value" },
    { label: "Discount", key: "total_discount" },
    { label: "Other Charges", key: "total_other_charges" },
    { label: "Taxable Value", key: "taxable_value", isCalculated: true }, // New field
    { label: "IGST", key: "total_igst" },
    { label: "CGST", key: "total_cgst" },
    { label: "SGST", key: "total_sgst" },
    { label: "UGST", key: "total_utgst" },
    { label: "Total Value", key: "total_value", isTotal: true },
    { label: "TCS Value", key: "tcs_value" },
    { label: "Round Off", key: "round_off" },
  ];

  return (
    <>
      <div className="flex items-center justify-between w-11/12 mx-auto gap-x-5 mb-4">
        <div
          className="flex items-center gap-x-3 cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <div className="rounded-[10px] flex items-center">
            <Image
              src={leftPrimary}
              alt="image"
              height={20}
              width={20}
              preview={false}
            />
          </div>
          <h1 className="lg:text-lg text-base text-primary/90">Invoice</h1>
        </div>
        {salesInvoiceDetails?.invoice_file ? (
          <Button
            onClick={handleDownload}
            className="root-btn text-white hover:text-white py-5 flex-center radius"
            icon={<PrinterOutlined style={{ fontSize: 16 }} />}
          >
            Print Invoice
          </Button>
        ) : (
          <Tooltip placement="top" title={"File not available"}>
            <Button
              disabled={true}
              className="root-btn text-white hover:text-white py-5 flex-center radius"
              icon={<PrinterOutlined style={{ fontSize: 16 }} />}
            >
              Print Invoice
            </Button>
          </Tooltip>
        )}
      </div>

      {getSalesInvoiceDetailLoader ? (
        <Skeleton
          active
          paragraph={{ rows: 6 }}
          title={{ width: "60%" }}
          style={{ padding: "20px" }}
        />
      ) : (
        <>
          {!getSalesInvoiceDetailLoader && salesInvoiceDetails && (
            <>
              <div className="border w-11/12  bg-white  mx-auto border-[#d4d4d4] pb-0">
                <div className="">
                  <div className="p-2 pb-0 border-b border-[#d4d4d4]">
                    <div className="flex items-center justify-between ">
                      <div className="flex gap-y-2 mb-2 flex-col">
                        <h2 className="text-xl mb-0 font-popinsSemiBold">
                          {company_name}
                        </h2>

                        {(street ||
                          city ||
                          currentState ||
                          country ||
                          zip_code) && (
                          <div className="text-sm max-w-[300px]">
                            {street && <span>{street}</span>}
                            {city && <span>, {city}</span>}
                            {currentState && (
                              <span>, {currentState?.name}</span>
                            )}
                            {zip_code && <span>, {zip_code}</span>}
                            {country && <span>, {country?.name}</span>}
                          </div>
                        )}
                      </div>

                      <h1 className="text-3xl font-bold text-brand uppercase">
                        Invoice
                      </h1>
                    </div>
                  </div>
                  <div class="">
                    <div class="border-b border-[#d4d4d4] grid grid-cols-2 p-0">
                      <div className="">
                        <InvoiceText
                          label={"Invoice Number"}
                          value={salesInvoiceDetails?.invoice_number || "-"}
                        />

                        <InvoiceText
                          label={"Customer"}
                          value={
                            salesInvoiceDetails?.customer
                              ?.business_partner_name || "-"
                          }
                        />

                        <div
                          className={
                            "md:flex p-1 pl-2.5  items-center gap-x-2 mb-0"
                          }
                        >
                          <p className="text-[15px] text-black font-popinsSemiBold md:w-[60%]">
                            Eway Bill Number :{" "}
                          </p>
                          <p className="text-[15px] text-black font-popinsMedium w-full">
                            {salesInvoiceDetails?.eway_bill_number
                              ? salesInvoiceDetails?.eway_bill_number
                              : "-"}
                          </p>
                        </div>
                      </div>
                      <div className="border-l border-[#d4d4d4] ">
                        <InvoiceText
                          label={"Invoice Date"}
                          value={salesInvoiceDetails?.invoice_date || "-"}
                        />

                        <InvoiceText
                          label={"IRN Number"}
                          value={salesInvoiceDetails.irn_number}
                        />

                        <InvoiceText
                          isFile={salesInvoiceDetails.invoice_file}
                          label={"Invoice File"}
                          value={salesInvoiceDetails.invoice_file}
                        />
                      </div>
                    </div>
                    <div class="grid grid-cols-2 px-2 border-b border-[#d4d4d4] bg-[#ededed]">
                      <div className=" flex items-center gap-x-2 ">
                        <p className="p-1 font-popinsBold">Bill To :</p>
                      </div>
                      <div className="border-l border-[#d4d4d4]  flex items-center gap-x-2">
                        <p className="p-1 pl-3 font-popinsBold">Ship To : </p>
                      </div>
                    </div>
                    <div class="grid grid-cols-2 px-2 border-b border-[#d4d4d4]">
                      <div className="p-1 flex gap-y-1.5 flex-col ">
                        <p className="font-popinsSemiBold text-base">
                          {salesInvoiceDetails?.customer
                            ?.business_partner_name || "-"}
                        </p>
                        {salesInvoiceDetails?.customer?.address_1 && (
                          <p className="">
                            {salesInvoiceDetails?.customer?.address_1 &&
                              salesInvoiceDetails?.customer?.address_1}
                          </p>
                        )}
                      </div>
                      <div className="border-l border-[#d4d4d4] p-1 pl-3 flex gap-y-1.5 flex-col ">
                        {salesInvoiceDetails?.ship_to_party && (
                          <p className="font-popinsSemiBold text-base">
                            {salesInvoiceDetails?.ship_to_party
                              ?.business_partner_name || "-"}
                          </p>
                        )}
                        {salesInvoiceDetails?.ship_to_party?.address_1 && (
                          <p className="">
                            {salesInvoiceDetails?.ship_to_party?.address_1}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="table-container relative">
                    <table class="min-w-full border-collapse border border-[#d4d4d4] border-t-0 border-l-0 border-r-0">
                      <thead>
                        <tr class="bg-brand text-left text-white">
                          <th class="border !border-[#d4d4d4] border-l-0 border-t-0 border-b-0 p-2">
                            Item Number
                          </th>
                          <th class="border-r !border-[#d4d4d4] p-2">
                            Material Code
                          </th>
                          <th class="border-r  !border-[#d4d4d4] p-2">Qty</th>
                          <th class="border-r  !border-[#d4d4d4] p-2">
                            Unit of Measure
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {salesInvoiceDetails &&
                        salesInvoiceDetails.line_items &&
                        salesInvoiceDetails.line_items.length > 0 ? (
                          salesInvoiceDetails.line_items.map((invoice) => (
                            <tr>
                              <td class="border border-[#d4d4d4]  border-l-0 p-2">
                                {invoice.line_item_number}
                              </td>
                              <td class="border border-[#d4d4d4]   p-2">
                                {invoice?.material_code?.material_name}
                              </td>
                              <td class="border  border-[#d4d4d4] p-2">
                                {parseInt(invoice.quantity)}
                              </td>
                              <td class="border border-r-0 border-[#d4d4d4]  p-2">
                                {invoice?.unit_of_measure?.code}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td>No Items Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <div className="flex items-start justify-between">
                      <div class="p-4 flex flex-col gap-y-2 text-black">
                        {salesInvoiceDetails.note && (
                          <p className="text-black font-popinsSemiBold  text-lg">
                            Note :{" "}
                            <span class="text-black w-10/12">
                              {salesInvoiceDetails.note}
                            </span>
                          </p>
                        )}
                      </div>
                      <div className="bg-white border-[0.5px] border-[#d4d4d4] border-b-0 rounded-br-none rounded-bl-none rounded-tl-none rounded-tr-none border-t-0 border-r-0 rounded-lg  p-6 w-full max-w-sm">
                        <h2 className="text-lg mb-2.5 font-bold text-gray-800">
                          Invoice Summary
                        </h2>
                        <div className="space-y-2">
                          {summaryItems.map((item, index) => {
                            const value = item.isCalculated
                              ? (parseFloat(
                                  salesInvoiceDetails?.total_base_value
                                ) || 0) -
                                (parseFloat(
                                  salesInvoiceDetails?.total_discount
                                ) || 0) +
                                (parseFloat(
                                  salesInvoiceDetails?.total_other_charges
                                ) || 0)
                              : salesInvoiceDetails?.[item.key] || "-";


                            const tax =
                              item.isCalculated && typeof value === "number"
                                ? value
                                : "0";

                            return (
                              <div
                                key={index}
                                className={`flex justify-between items-center ${
                                  item.isTotal || item.label === "Taxable Value"
                                    ? "pt-3 mt-3 border-t border-[#d4d4d4]"
                                    : ""
                                }`}
                              >
                                <span className="text-gray-600 text-sm">
                                  {item.label}
                                </span>
                                <span className="font-semibold text-gray-600 text-sm">
                                  {value}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*   <Row justify="start">
          <p className="!text-brand text-[22px] font-popinsSemiBold">
            Invoice Number - {salesInvoiceDetails?.invoice_number}
          </p>
        </Row>

        <Row gutter={16} style={{ marginTop: "20px" }}>
          <Col span={12}>
            <InvoiceText
              label={"Invoice Number"}
              value={salesInvoiceDetails.invoice_number}
            />
            <InvoiceText
              label={"Customer"}
              value={salesInvoiceDetails.customer?.business_partner_name}
            />
            <InvoiceText label={"Note"} value={salesInvoiceDetails.note} />
            <InvoiceText
              label={"Eway Bill Number"}
              value={salesInvoiceDetails.eway_bill_number || "-"}
            />
            <InvoiceText
              label={"Total Base Value"}
              value={salesInvoiceDetails.total_base_value}
            />
            <InvoiceText
              label={"Total Other Charges"}
              value={salesInvoiceDetails.total_other_charges}
            />
            <InvoiceText
              label={"Total SGST"}
              value={salesInvoiceDetails.total_sgst}
            />
            <InvoiceText
              label={"Total UTGST"}
              value={salesInvoiceDetails.total_utgst}
            />
            <InvoiceText
              label={"Total Value"}
              value={salesInvoiceDetails.total_value}
            />
            <InvoiceText
              label={"Round Off"}
              value={salesInvoiceDetails.round_off}
            />
          </Col>

          <Col span={12}>
            <InvoiceText
              label={"Invoice Date"}
              value={salesInvoiceDetails.invoice_date}
            />
            <InvoiceText
              label={"Ship To Party"}
              value={salesInvoiceDetails.ship_to_party?.business_partner_name}
            />
            <InvoiceText
              label={"IRN Number"}
              value={salesInvoiceDetails.irn_number}
            />
            <InvoiceText
              isFile={salesInvoiceDetails.invoice_file ? true : false}
              label={"Invoice File Name"}
              value={salesInvoiceDetails.invoice_file}
            />
            <InvoiceText
              label={"Total Discount"}
              value={salesInvoiceDetails.total_discount}
            />
            <InvoiceText
              label={"Total CGST"}
              value={salesInvoiceDetails.total_cgst || "-"}
            />
            <InvoiceText
              label={"Total IGST"}
              value={salesInvoiceDetails.total_igst}
            />
            <InvoiceText
              label={"Total Net Value"}
              value={salesInvoiceDetails.total_net_value || "-"}
            />
            <InvoiceText
              label={"TCS Value"}
              value={salesInvoiceDetails.tcs_value}
            />
          </Col>
        </Row>

        <Row style={{ marginTop: "20px" }}>
          <Col span={24}>
            <Table
              rowKey="invoice_line_items_id"
              dataSource={salesInvoiceDetails?.line_items}
              columns={columns}
              pagination={false}
              bordered
            />
          </Col>
        </Row> */}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ViewSalesInvoice;

const InvoiceText = ({ label, value, isFile }) => {
  if (isFile) {
    const file = value;
    if (!file || !file.url) return <Text>{label}: -</Text>;

    const isImage = file.mimetype.startsWith("image");
    const isDocument = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ].includes(file.mimetype);

    return (
      <div className="md:flex p-1 pl-2.5 items-center gap-x-3">
        <p className="text-[15px] text-black md:w-[30%]  font-popinsSemiBold">
          {label} :{" "}
        </p>
        <div className="w-full font-popinsMedium">
          {isImage ? (
            <Image
              preview={{ mask: <EyeOutlined /> }}
              className="cursor-pointer"
              src={file.url}
              alt={file.originalname}
              style={{ width: 50, height: 50, objectFit: "contain" }}
            />
          ) : isDocument ? (
            <a
              href={file.url}
              className="text-blue-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              {file.originalname}
            </a>
          ) : (
            <Text>{file.originalname}</Text>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className={
        "md:flex p-1 pl-2.5 border-b border-[#d4d4d4] items-center gap-x-2 mb-1"
      }
    >
      <p className="text-[15px] text-black font-popinsSemiBold md:w-[30%]">
        {label} :{" "}
      </p>
      <p className="text-[15px] text-black font-popinsMedium w-full">
        {value ? value : "-"}
      </p>
    </div>
  );
};
