import React, { useState } from "react";
import { Button, Flex, Image } from "antd";
import plusIcon from "../../assets/img/add-button.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ForbiddenModal from "./ForbiddenModal";
import usePermission from "../../hooks/use-permission";
import { showModal } from "../../store/slices/authSlice";
import { getPermissionMessage } from "../helper/common-function";

const PageTitle = ({
  image,
  icon,
  title,
  buttonText,
  buttonLink,
  rightIcon = true,
  handleClick,
  type = "link",
  resource,
  margin = true,
  action = "create",
}) => {

  const dispatch = useDispatch();
  const { hasPermission } = usePermission();

  const renderButton = () => {
    const buttonProps = {
      className: "root-btn text-white hover:text-white py-5 flex-center radius",
      icon: <Image src={plusIcon} alt="Plus Icon" preview={false} />,
      children: buttonText,
    };

    if (hasPermission(resource, action)) {
      if (type === "btn") {
        return <Button {...buttonProps} onClick={handleClick} />;
      } else {
        return (
          <Link to={buttonLink}>
            <Button {...buttonProps} />
          </Link>
        );
      }
    } else {
      return <Button {...buttonProps} onClick={() => dispatch(showModal(getPermissionMessage(resource, action)))} />;
    }
  };

  return (
    <>
      <Flex gap="middle" justify="space-between" className={margin ? "mb-4" : false}>
        <div className="text-brand flex items-center gap-x-3">
          {icon ? (
            icon
          ) : (
            <Image
              src={image}
              alt="image"
              height={20}
              width={20}
              preview={false}
            />
          )}
          <h1 className="lg:text-lg text-base font-popinsRegular">{title}</h1>
        </div>

        {rightIcon && renderButton()}
      </Flex>

    </>
  );
};

export default PageTitle;
