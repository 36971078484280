import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Row,
  Select,
  Spin,
  Upload,
  Popover,
  Avatar,
  Skeleton,
} from "antd";
import { toast } from "sonner";
import React, { useEffect, useState } from "react";
import CustomIconText from "../../common/CustomIconText";
import opportunity from "../../../assets/img/opportunity.svg";
import editPrimaryWhite from "../../../assets/img/editPrimaryWhite.svg";
import plusSvg from "../../../assets/img/plus.svg";
import attechment from "../../../assets/img/attechment.svg";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL_UPLOAD } from "../../../config/web-config";
import ImgCrop from "antd-img-crop";
const { Dragger } = Upload;
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { actionGetCompanyAllUsers } from "../../../store/services/usersService";
import { useDispatch, useSelector } from "react-redux";
import { actionGetAllLeads } from "../../../store/services/leadsService";
import {
  actionAddLeadSourceMaster,
  actionAddOpportunityStageMaster,
  actionGetBusinessPartnerTypeMaster,
  actionGetLeadSourceMaster,
  actionGetOpportunityStageMaster,
} from "../../../store/services/generalMasterService";
import {
  actionAddOpportunity,
  actionGetOpportunityDetail,
  actionUpdateOpportunity,
} from "../../../store/services/opportunityService";
import { extractFilename, normalizeInput, sanitizeName } from "../../helper/common-function";
import {
  actionAddBusinessPartner,
  actionGetAllBusinessPartner,
} from "../../../store/services/masterDataService";
import SkeletonUpdateCard from "../../common/SkeletonUpdateCard";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const CreateOpportunity = () => {
  const [form] = Form.useForm();
  const [addStageForm] = Form.useForm();
  const [addSourceForm] = Form.useForm();
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchValue, setSearchValue] = useState("")
  const [addBusinessPartnerForm] = Form.useForm();
  const navigate = useNavigate();
  const [addStagePopoverOpen, setAddStagePopoverOpen] = useState(false);
  const [addSourcePopoverOpen, setAddSourcePopoverOpen] = useState(false);
  const [addBusinessPartnerPopoverOpen, setAddBusinessPartnerPopoverOpen] =
    useState(false);
  const [attatchment, setAttatchment] = useState([]);
  const [opportunityLogoPicUrl, setOpportunityLogoPicUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [closeDate, setCloseDate] = useState(null);
  const [uploading, setUploading] = useState(false);
  const { getCompanyAllUserLoader, companyAllUsers } = useSelector(
    (state) => state.users
  );
  const { getAllLeadsLoader, allLeadsData } = useSelector(
    (state) => state.leads
  );
  const {
    addBusinessPartnerLoader,
    allBusinessPartnerData,
    getAllBusinessPartnerLoader,
  } = useSelector((state) => state.masterData);
  const {
    addOpportunityLoader,
    updateOpportunityLoader,
    opportunityDetailData,
    getOpportunityDetailLoader,
  } = useSelector((state) => state.opportunities);
  const {
    opportunityStageData,
    getOpportunityStageLoader,
    addOpportunityStageLoader,
    leadSourceData,
    getLeadSourceLoader,
    addLeadSourceLoader,
    getBusinessPartnerTypeLoader,
    businessPartnerTypeData,
  } = useSelector((state) => state.generalMaster);
  const location = useLocation();
  const { opportunityId } = location.state || {};
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionGetCompanyAllUsers());
    dispatch(actionGetAllLeads());
    dispatch(actionGetOpportunityStageMaster());
    dispatch(actionGetLeadSourceMaster());
    dispatch(actionGetAllBusinessPartner());
    if (opportunityId) {
      dispatch(actionGetOpportunityDetail(opportunityId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (opportunityId && opportunityDetailData) {
      form.setFieldsValue({
        opportunity_name: opportunityDetailData.opportunity_name,
        opportunity_owner_compnay_user_id:
          opportunityDetailData.opportunity_owner?.id,
        lead_id: opportunityDetailData.lead?.lead_id,
        opportunity_stage_master_id:
          opportunityDetailData.opportunity_stage?.opportunity_stage_master_id,
        probability: opportunityDetailData.probability,
        expected_revenue: opportunityDetailData.expected_revenue,
        close_date:
          opportunityDetailData.close_date &&
          dayjs(opportunityDetailData.close_date, "YYYY-MM-DD"),
        business_partner_id:
          opportunityDetailData.business_partner?.business_partner_id,
        contact_person_name: opportunityDetailData.contact_person_name,
        products_services: opportunityDetailData.products_services,
        competitors: opportunityDetailData.competitors,
        notes_description: opportunityDetailData.notes_description,
        next_steps: opportunityDetailData.next_steps,
        opportunity_source_id:
          opportunityDetailData.opportunity_source?.lead_source_master_id,
        opportunity_tags: opportunityDetailData.opportunity_tags,
      });
      setOpportunityLogoPicUrl(opportunityDetailData?.logo);

      const ImagesArray = [];

      opportunityDetailData &&
        opportunityDetailData.opportunity_attachments &&
        opportunityDetailData.opportunity_attachments.map((data, index) => {
          ImagesArray.push({
            id: index,
            url: data,
            name: extractFilename(data),
          });
        });

      setAttatchment(ImagesArray);
    }
  }, [opportunityId, opportunityDetailData, form]);

  const attatchmentProps = {
    name: "file",
    multiple: true,
    // listType: "picture",
    action: BASE_URL_UPLOAD,
    fileList: attatchment, // Pass current attachments to fileList
    showUploadList: true,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("crmWebToken"),
    },
    onChange(info) {
      // const { status, fileList } = info;
      // if (status !== "uploading") {
      //   setAttatchment(fileList);
      // }
      // if (status === "done") {
      //   toast.success(`File uploaded successfully.`);
      // } else if (status === "error") {
      //   toast.error(`File upload failed.`);
      // }
      const { fileList } = info;
      setAttatchment(fileList);

      // Check if any file is uploading
      const isUploading = fileList.some((file) => file.status === "uploading");
      setUploading(isUploading);

      if (info.file.status === "done") {
        toast.success(`File uploaded successfully.`);
      } else if (info.file.status === "error") {
        toast.error(`File upload failed.`);
      }
    },
    onDrop(e) { },
  };

  const handleChangeOpportunityLogo = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setOpportunityLogoPicUrl(info.file.response.url);
        toast.success("Uploaded successfully", 5);
      });
    } else if (info.file.status === "error") {
      setLoading(false);
      toast.error(`${info.file.name} file upload failed.`);
    }
  };

  // add stage
  const handleCanceladdStagePopover = () => {
    setAddStagePopoverOpen(false);
    addStageForm.resetFields();
  };

  const showaddStagePopoverOpen = () => {
    setAddStagePopoverOpen(!addSourcePopoverOpen);
  };

  const addStagePopover = (
    <>
      <div className="min-w-[350px]">
        <Form
          form={addStageForm}
          onFinish={(values) =>
            dispatch(
              actionAddOpportunityStageMaster({
                oppoForm: form,
                values,
                handleCancel: handleCanceladdStagePopover,
              })
            )
          }
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="opportunity_stage"
            label="Stage"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please enter stage!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Stage"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={() => handleCanceladdStagePopover()}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addOpportunityStageLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );

  //add source
  const handleCanceladdSourcePopover = () => {
    setAddSourcePopoverOpen(false);
    addSourceForm.resetFields();
  };
  const showaddSourcePopoverOpen = () => {
    setAddSourcePopoverOpen(!addSourcePopoverOpen);
  };

  const addSourcePopover = (
    <>
      <div className="min-w-[350px]">
        <Form
          form={addSourceForm}
          onFinish={(values) =>
            dispatch(
              actionAddLeadSourceMaster({
                values,
                oppoForm: form,
                handleCancel: handleCanceladdSourcePopover,
              })
            )
          }
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="lead_source"
            label="Source"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please enter source!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Source"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={() => handleCanceladdSourcePopover()}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addLeadSourceLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );

  // add Business partner
  const handleCancelAddBusinessPartnerPopover = () => {
    setAddBusinessPartnerPopoverOpen(false);
    addBusinessPartnerForm.resetFields();
  };
  const showAddBusinessPartnerPopoverOpen = () => {
    setAddBusinessPartnerPopoverOpen(!addBusinessPartnerPopoverOpen);
    dispatch(actionGetBusinessPartnerTypeMaster());
  };

  // business partner data
  const businessPartner =
    allBusinessPartnerData && allBusinessPartnerData.length > 0
      ? allBusinessPartnerData.map((data) => ({
        key: data.business_partner_id,
        label: data.business_partner_name,
        value: data.business_partner_id,
      }))
      : [];

  // business partner type data
  const businessPartnerType =
    businessPartnerTypeData && businessPartnerTypeData.length > 0
      ? businessPartnerTypeData.map((data) => ({
        key: data.business_partner_type_master_id,
        label: data.business_partner_type_name,
        value: data.business_partner_type_master_id,
      }))
      : [];

  const addBusinessPartnerPopover = (
    <>
      <div className="min-w-[350px]">
        <Form
          form={addBusinessPartnerForm}
          onFinish={(values) =>
            dispatch(
              actionAddBusinessPartner({
                req: values,
                form,
                handleCancel: handleCancelAddBusinessPartnerPopover,
              })
            )
          }
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="business_partner_name"
            label="Business Partner Name"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please enter business partner name!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Business Partner Name"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>
          <Form.Item
            name="business_partner_type_master_id"
            label="Business Partner Type"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please select business partner type!",
              },
            ]}
          >
            <Select
              allowClear
              showSearch
              // mode="multiple"
              placeholder="Select Business Partner Type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={businessPartnerType}
              disabled={getBusinessPartnerTypeLoader}
              loading={getBusinessPartnerTypeLoader}
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={() => handleCancelAddBusinessPartnerPopover()}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addBusinessPartnerLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );

  // opportunity owner
  const opportunityOwners =
    companyAllUsers && companyAllUsers.length > 0
      ? companyAllUsers.map((user) => {
        const fullName = `${user.first_name ? user.first_name : ""} ${user.last_name ? user.last_name : ""
          }`.trim();

        return {
          key: user.id,
          label: (
            <>
              <Avatar
                src={user.profile_pic}
                style={{
                  verticalAlign: "middle",
                }}
                className="mr-2"
              />
              {fullName}
            </>
          ),
          value: user.id,
          fullName, // Store the full name as a string
        };
      })
      : [];

  // leads
  const leadsData =
    allLeadsData && allLeadsData.length > 0
      ? allLeadsData.map((lead) => {
        const fullName = `${lead.first_name ? lead.first_name : ""} ${lead.last_name ? lead.last_name : ""
          }`.trim();

        return {
          key: lead.lead_id,
          label: fullName,
          value: lead.lead_id,
          fullName, // Store the full name as a string
        };
      })
      : [];

  // Opportunity Stage
  const opportunityData =
    opportunityStageData && opportunityStageData.length > 0
      ? opportunityStageData.map((opportunity) => ({
        key: opportunity?.opportunity_stage_master_id,
        label: opportunity?.opportunity_stage,
        value: opportunity?.opportunity_stage_master_id,
      }))
      : [];

  // source data
  const sourceData =
    leadSourceData && leadSourceData.length > 0
      ? leadSourceData.map((lead) => ({
        key: lead.lead_source_master_id,
        label: lead.lead_source,
        value: lead.lead_source_master_id,
      }))
      : [];

  const handleChangeTags = (value) => {
    setSelectedItems(value);
    setSearchValue("")
  };

  const onCloseDateChange = (value) => {
    setCloseDate(value);
  };

  const onFinishAddOrUpdateOpportunity = (values) => {
    const imagesArr = [];

    attatchment &&
      attatchment.length > 0 &&
      attatchment?.forEach((d) => {
        if (d.response) {
          const newUrl = d.response.filename;
          imagesArr.push(newUrl);
        } else if (d.url) {
          const parts = extractFilename(d.url);
          imagesArr.push(parts);
        } else {
          imagesArr.push(d.path);
        }
      });

    const data = {
      ...values,
      close_date: values.close_date
        ? dayjs(values.close_date).format("YYYY-MM-DD")
        : "",
      logo: opportunityLogoPicUrl
        ? extractFilename(opportunityLogoPicUrl)
        : null,
      opportunity_attachments: imagesArr,
      business_partner_id: values.business_partner_id
        ? values.business_partner_id
        : null,
      lead_id: values.lead_id ? values.lead_id : null,
      opportunity_owner_compnay_user_id:
        values.opportunity_owner_compnay_user_id
          ? values.opportunity_owner_compnay_user_id
          : null,
      opportunity_source_id: values.opportunity_source_id
        ? values.opportunity_source_id
        : null,
      opportunity_stage_master_id: values.opportunity_stage_master_id
        ? values.opportunity_stage_master_id
        : null,
    };

    const req = {
      ...Object.fromEntries(
        Object.entries(data).filter(
          ([_, v]) =>
            v !== undefined &&
            v !== null &&
            !(typeof v === "string" && v.trim() === "") &&
            (!Array.isArray(v) || v.length > 0)
        )
      ),
    };

    opportunityId
      ? dispatch(
        actionUpdateOpportunity({ id: opportunityId, req: data, navigate })
      )
      : dispatch(actionAddOpportunity({ req: data, navigate }));
  };


  const normalizeInputArr = (value) => {
    console.log({ value }, "Common Function");

    // Check if the value is an array
    if (Array.isArray(value)) {
      return value
        .map((item) => {
          if (typeof item === "string") {
            return item
              .replace(/^\s+/g, "")
              .replace(/\s+/g, " ")
              .replace(/[^a-zA-Z0-9\s]/g, "");
          }
          return item;
        })
        .filter(Boolean);
    }

    return value;
  };


  return (
    // <Spin spinning={getOpportunityDetailLoader}>

    <section className="main-wrapper">
      <CustomIconText
        image={opportunity}
        title={opportunityId ? "Update Opportunity" : "Create Opportunity"}
        backArrow={true}
      />
      <Card className="main-card mt-5 w-full">
        <Form
          scrollToFirstError={true}
          form={form}
          layout="vertical"
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinishAddOrUpdateOpportunity}
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col span={24} align="middle">
              <Form.Item
                className="mb-[12px]"
                size="small"
              >
                {getOpportunityDetailLoader ? (
                  <>
                    {Array.from({ length: 1 }).map((_, index) => (
                      <Skeleton.Avatar
                        key={index}
                        active
                        size={100}
                        shape="square"
                        className="flex-shrink-0"
                        style={{ marginRight: "10px" }}
                      />
                    ))}
                  </>
                ) : (
                  <>
                    {opportunityLogoPicUrl ? (
                      <div className="relative border-brand  h-[102px]  w-[102px] rounded-[6px] mb-2">
                        <img
                          src={opportunityLogoPicUrl}
                          alt="Logo"
                          className="h-full w-full rounded-[6px]"
                        />

                        <ImgCrop
                          rotationSlider
                          aspect={1 / 1}
                          fillColor="white"
                        >
                          <Upload
                            // id=""
                            name="file"
                            listType="picture-card"
                            className="border-0 absolute main-upload bottom-0 right-0 avatar-uploader"
                            showUploadList={false}
                            action={BASE_URL_UPLOAD}
                            openFileDialogOnClick={true}
                            headers={{
                              Authorization:
                                "Bearer " + localStorage.getItem("crmWebToken"),
                            }}
                            onChange={handleChangeOpportunityLogo}
                          >
                            <div className=" px-1.5 py-1 border-0 absolute right-0 bottom-0 bg-brand rounded-tl-[6px] rounded-br-[6px] cursor-pointer flex">
                              <Image
                                width={15}
                                height={15}
                                src={editPrimaryWhite}
                                className="rounded-lg"
                                preview={false}
                              />
                            </div>
                          </Upload>
                        </ImgCrop>
                      </div>
                    ) : (
                      <ImgCrop aspect={1 / 1} rotationSlider fillColor="white">
                        <Upload
                          accept=".png,.jpg,.jpeg,.pdf,.doc"
                          name="file"
                          listType="picture-card"
                          className="avatar-uploader mb-2"
                          showUploadList={false}
                          action={BASE_URL_UPLOAD}
                          openFileDialogOnClick={true}
                          headers={{
                            Authorization:
                              "Bearer " + localStorage.getItem("crmWebToken"),
                          }}
                          onChange={handleChangeOpportunityLogo}
                        >
                          <button
                            style={{
                              border: 0,
                              background: "none",
                            }}
                            type="button"
                          >
                            {loading ? <LoadingOutlined /> : <PlusOutlined />}
                            <div
                              style={{
                                marginTop: 8,
                              }}
                            >
                              Logo
                            </div>
                          </button>
                        </Upload>
                      </ImgCrop>
                    )}
                  </>
                )}
              </Form.Item>
            </Col>

            {getOpportunityDetailLoader ? (
              <SkeletonUpdateCard length={5} />
            ) : (
              <>
                <Col span={12}>
                  <Form.Item
                    label="Opportunity Name"
                    name="opportunity_name"
                    normalize={(value) => {
                      return value.replace(/^\s+/g, "").replace(/\s+/g, " ").replace(/[^a-zA-Z0-9 .]/g, "");
                    }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter opportunity name!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Opportunity Name/Title" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Opportunity Owner"
                    name="opportunity_owner_compnay_user_id"

                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Opportunity Owner"
                      optionFilterProp="children"
                      className=""
                      filterOption={(input, option) =>
                        (option?.fullName ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      loading={getCompanyAllUserLoader}
                      disabled={getCompanyAllUserLoader}
                      options={opportunityOwners}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Lead"
                    name="lead_id"

                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Lead"
                      optionFilterProp="children"
                      className=""
                      filterOption={(input, option) =>
                        (option?.fullName ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      loading={getAllLeadsLoader}
                      options={leadsData}
                      disabled={getAllLeadsLoader}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Popover
                    placement="bottomRight"
                    content={addStagePopover}
                    trigger="click"
                    open={addStagePopoverOpen}
                    onOpenChange={(visible) => {
                      if (!visible) {
                        setAddStagePopoverOpen(false);
                        addStageForm.resetFields();
                      }
                    }}
                  >
                    <div
                      className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                      onClick={showaddStagePopoverOpen}
                    >
                      <Image
                        src={plusSvg}
                        alt="image"
                        height={20}
                        width={20}
                        preview={false}
                      />
                      <h1 className="text-[14px] font-popinsRegular ml-1">
                        Add Stage
                      </h1>
                    </div>
                  </Popover>
                  <Form.Item
                    label="Stage"
                    name="opportunity_stage_master_id"

                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Stage"
                      optionFilterProp="children"
                      className=""
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      loading={getOpportunityStageLoader}
                      options={opportunityData}
                      disabled={getOpportunityStageLoader}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Probability"
                    name="probability"
                    normalize={(value) => {
                      if (!value) return value;
                      return value
                        .replace(/^\s+/g, "")
                        .replace(/\s+/g, " ")
                        .replace(/^[^a-zA-Z0-9]/g, "")
                        .replace(/[^a-zA-Z0-9%\s]/g, "");
                    }}
                  >
                    <Input placeholder="Enter Probability" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Expected Revenue/Value"
                    name="expected_revenue"
                    normalize={normalizeInput}
                  >
                    <Input placeholder="Enter Expected Revenue/Value" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Close Date"
                    name="close_date"

                  >
                    <DatePicker
                      format={"MM/DD/YYYY"}
                      className="w-full"
                      onChange={onCloseDateChange}
                    // minDate={dayjs(new Date())}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Popover
                    placement="bottomRight"
                    content={addBusinessPartnerPopover}
                    trigger="click"
                    open={addBusinessPartnerPopoverOpen}
                    onOpenChange={(visible) => {
                      if (!visible) {
                        setAddBusinessPartnerPopoverOpen(false);
                        addBusinessPartnerForm.resetFields();
                      }
                    }}
                  >
                    <div
                      className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                      onClick={showAddBusinessPartnerPopoverOpen}
                    >
                      <Image
                        src={plusSvg}
                        alt="image"
                        height={20}
                        width={20}
                        preview={false}
                      />
                      <h1 className="text-[14px] font-popinsRegular ml-1">
                        Add Business Partner
                      </h1>
                    </div>
                  </Popover>
                  <Form.Item
                    label="Business Partner"
                    name="business_partner_id"

                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Business Partner"
                      optionFilterProp="children"
                      className=""
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={businessPartner}
                      disabled={getAllBusinessPartnerLoader}
                      loading={getAllBusinessPartnerLoader}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="contact_person_name"
                    label="Contact Person"
                    className="mb-[12px]"
                    size="small"

                    normalize={sanitizeName}
                  >
                    <Input
                      // rows={3}
                      style={{ background: "white" }}
                      placeholder="Enter Contact Name"
                      className="indent-5 begin-input bg-white pt-2 focus:bg-white active:bg-white rounded-[10px]"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Products/Services"
                    name="products_services"
                    normalize={normalizeInput}
                  >
                    <Input placeholder="Enter Products/Services" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Competitors"
                    name="competitors"
                    normalize={normalizeInput}

                  >
                    <Input placeholder="Enter Competitors" />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Popover
                    placement="bottomRight"
                    content={addSourcePopover}
                    trigger="click"
                    open={addSourcePopoverOpen}
                    onOpenChange={(visible) => {
                      if (!visible) {
                        setAddSourcePopoverOpen(false);
                        addSourceForm.resetFields();
                      }
                    }}
                  >
                    <div
                      className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                      onClick={showaddSourcePopoverOpen}
                    >
                      <Image
                        src={plusSvg}
                        alt="image"
                        height={20}
                        width={20}
                        preview={false}
                      />
                      <h1 className="text-[14px] font-popinsRegular ml-1">
                        Add Source
                      </h1>
                    </div>
                  </Popover>
                  <Form.Item
                    label="Source"
                    name="opportunity_source_id"

                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Source"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={sourceData}
                      disabled={getLeadSourceLoader}
                      loading={getLeadSourceLoader}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Notes/Description"
                    name="notes_description"
                  >
                    <Input.TextArea placeholder="Create Notes/Description" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Next Steps/Actions"
                    name="next_steps"

                  >
                    <Input.TextArea placeholder="Enter Next Steps/Actions" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item normalize={normalizeInput}
                    label="Tags" name="opportunity_tags">
                    <Select

                      open={true}
                      className="remove-arrow"
                      popupClassName="opportunity-select"
                      multiple={true}
                      showSearch={false}
                      mode="tags"
                      style={{ width: "100%" }}
                      placeholder="Tags Mode"
                      onSearch={(value) => {
                        setSearchValue(normalizeInput(value));
                      }}
                      value={selectedItems}
                      onChange={handleChangeTags}
                      onSeae
                      notFoundContent={null}
                      searchValue={searchValue}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Attachments/Documents"
                    name="opportunity_attachments"
                  >
                    <Dragger
                      className="dragger-custom show-remove-icon"
                      {...attatchmentProps}
                      accept=".png,.jpg,.jpeg,.pdf,.doc,.docx"
                    >
                      <div className="py-[30px]">
                        <Image src={attechment} alt="image" preview={false} />
                        <p className="text-[16px] font-popinsRegular text-[#7C7C7C]">
                          Facility to Attach Documents
                        </p>
                      </div>
                    </Dragger>
                  </Form.Item>
                </Col>
                <Col
                  span={24}
                  className="flex items-center my-4 justify-center gap-x-5"
                >
                  <Form.Item>
                    <Button
                      onClick={async () => {
                        navigate(-1);
                      }}
                      type="button"
                      className="gray-button text-white hover:text-white  min-h-[45px] min-w-[120px]"
                      htmlType="button"
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      loading={addOpportunityLoader || updateOpportunityLoader}
                      type="primary"
                      className="root-btn min-h-[45px] min-w-[120px]"
                      htmlType="submit"
                      disabled={uploading}
                    >
                      {opportunityId ? "Update" : "Save"}
                    </Button>
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Form>
      </Card>
    </section >
    // </Spin>
  );
};

export default CreateOpportunity;
