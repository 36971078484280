import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Empty,
  Image,
  Input,
  Layout,
  Menu,
  Radio,
  Row,
  Skeleton,
  Switch,
  Tooltip
} from "antd";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import leftArrowPrimary from "../../../assets/img/leftArrowPrimary.svg";
import {
  actionAssignRoleProfilePermission,
  actionGetSingleRoleProfile,
} from "../../../store/services/settingService";
import { formatString } from "../../helper/common-function";

const ModulePermissions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { roleProfileId } = location.state || {};
  const {
    roleProfileDetail,
    getSingleRoleProfileLoader,
    assignRoleProfilePermissionLoader,
  } = useSelector((state) => state.setting);

  const { currentCompanyUser } = useSelector((state) => state.auth);

  const [selectedKey, setSelectedKey] = useState("");
  const [switchStates, setSwitchStates] = useState({});
  const [globalPermissions, setGlobalPermissions] = useState({});
  const [unsavedChanges, setUnsavedChanges] = useState(false); // Track unsaved changes
  const [searchKey, setSearchKey] = useState("");

  const [defaultModules, setDefaultModules] = useState({});

  const memoizedDefaultModules = useMemo(
    () => defaultModules,
    [defaultModules]
  );

  const modules = useMemo(
    () => ({
      EVENTS: [
        {
          resource: "event-type-master",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "event-category-master",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "event-online-platforms-master",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
      ],
      "BUSINESS-PARTNER": [
        {
          resource: "business-partner",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "business-partner-type-master",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "beat-master",
          permissions: {
            create: false,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "address-type-master",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "type-of-company-master",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "industry-master",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
      ],
      CONTACT: [
        {
          resource: "contact",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "business-partner",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "business-partner-type-master",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "lead-source",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "department-master",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "company-users",
          permissions: {
            create: false,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "product-category-master",
          permissions: {
            create: false,
            read: true,
            update: false,
            delete: false,
          },
        },
      ],
      "EVENT-ATTENDEE": [
        {
          resource: "event-attendee",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "events",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "event-attendee-type-master",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "event-attendee-status-master",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "product-category-master",
          permissions: {
            create: false,
            read: true,
            update: false,
            delete: false,
          },
        },
      ],
      "EVENT-EXHIBITOR": [
        {
          resource: "event-exhibitor",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "events",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "event-category-master",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
      ],
      "EVENT-SESSION": [
        {
          resource: "event-session",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "events",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
      ],
      "EVENT-SPEAKER": [
        {
          resource: "event-speaker",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "events",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
      ],
      "EVENT-SPONSOR": [
        {
          resource: "event-sponsor",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "events",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
      ],
      "EVENT-INVENTORY": [
        {
          resource: "event-inventory",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "events",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "event-product-master",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
      ],
      LEAD: [
        {
          resource: "lead",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "industry-master",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "leads-status",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "lead-source",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "lead-rating",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "company-users",
          permissions: {
            create: false,
            read: true,
            update: false,
            delete: false,
          },
        },
      ],
      OPPORTUNITIES: [
        {
          resource: "opportunity-stage",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "business-partner",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "lead-source",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "company-users",
          permissions: {
            create: false,
            read: true,
            update: false,
            delete: false,
          },
        },
      ],
      "REQUEST-FOR-QUOTATION": [
        {
          resource: "request-for-quotation",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "product-category-master",
          permissions: {
            create: false,
            read: true,
            update: false,
            delete: false,
          },
        },
      ],
      "BEAT-PLANNING": [
        {
          resource: "beat-planning",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "role",
          permissions: {
            create: false,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "zone-master",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "beat-grade-master",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "beat-master",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "area-master",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "company-users",
          permissions: {
            create: false,
            read: true,
            update: false,
            delete: false,
          },
        },
      ],
      "COLLECTION-MANAGEMENT": [
        {
          resource: "collection-management",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "business-partner",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "business-partner-type-master",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "payment-method-master",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "deposit-master",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
      ],
      "EXPENSE-VOUCHER": [
        {
          resource: "expense-voucher",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "expense-category-type-master",
          permissions: {
            create: false,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "payment-method-master",
          permissions: {
            create: false,
            read: true,
            update: false,
            delete: false,
          },
        },
      ],
      "SALES-ORDER": [
        {
          resource: "sales-order",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "business-partner",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "business-partner-type-master",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "plant-code-master",
          permissions: {
            create: false,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "material-master",
          permissions: {
            create: false,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "unit-master",
          permissions: {
            create: false,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "custom-fields",
          permissions: {
            create: false,
            read: true,
            update: false,
            delete: false,
          },
        },
      ],
      REMINDER: [
        {
          resource: "reminder",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "company-users",
          permissions: {
            create: false,
            read: true,
            update: false,
            delete: false,
          },
        },
      ],
      TASK: [
        {
          resource: "task",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "task-status-master",
          permissions: {
            create: false,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "company-users",
          permissions: {
            create: false,
            read: true,
            update: false,
            delete: false,
          },
        },
      ],
      "MATERIAL-MASTER": [
        {
          resource: "material-master",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "product-type-master",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "product-category-master",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "product-sub-category-master",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "unit-master",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "custom-fields",
          permissions: {
            create: true,
            read: true,
            update: true,
            delete: true,
          },
        },
        {
          resource: "hsn-code-master",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
      ],
      "PRODUCT-SUB-CATEGORY-MASTER": [
        {
          resource: "product-category-master",
          permissions: {
            create: false,
            read: true,
            update: false,
            delete: false,
          },
        },
      ],
      "BEAT-MASTER": [
        {
          resource: "zone-master",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "beat-grade-master",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "area-master",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
      ],
      "COMPANY-USERS": [
        {
          resource: "company-users",
          permissions: {
            create: true,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "role",
          permissions: {
            create: false,
            read: true,
            update: false,
            delete: false,
          },
        },
        {
          resource: "role-profile",
          permissions: {
            create: false,
            read: true,
            update: false,
            delete: false,
          },
        },
      ],
    }),
    []
  );

  useEffect(() => {
    const newDefaultModules = {};
    if (modules) {
      Object.keys(modules).forEach((moduleName) => {
        let resourcePermissions = [];
        let moduleId = null;

        // Loop through access rights to match resources with the module
        roleProfileDetail.access_rights &&
          roleProfileDetail.access_rights.length > 0 &&
          roleProfileDetail.access_rights.forEach((category) => {
            if (
              category.resources &&
              Array.isArray(category.resources) &&
              category.resources.length > 0
            ) {
              moduleId =
                category.resources.find(
                  (resource) =>
                    resource.resources_name.toLowerCase() ===
                    moduleName.toLowerCase()
                )?.resources_id || null;

              // Iterate over the resources in the category and push matching resource IDs and permissions
              category.resources.forEach((resource) => {
                const moduleResource = modules[moduleName].find(
                  (mod) => mod.resource === resource.resources_name
                );

                if (moduleResource) {
                  // Push resourceId and its associated permissions as a pair
                  resourcePermissions.push({
                    resourceId: resource.resources_id,
                    permissions: moduleResource.permissions || {},
                  });
                }
              });
            }
          });

        if (resourcePermissions.length > 0) {
          newDefaultModules[moduleId] = resourcePermissions;
        }
      });
    }
    setDefaultModules(newDefaultModules);
  }, [roleProfileDetail]);

  useEffect(() => {
    if (roleProfileId) {
      dispatch(actionGetSingleRoleProfile(roleProfileId));
    }
  }, [roleProfileId, dispatch]);

  const SidebarData = useMemo(() => {
    const data = [];
    roleProfileDetail?.access_rights?.forEach((item) => {
      data.push({
        label: formatString(item.resource_category_name),
        key: item.resource_category_id,
      });
    });
    return data;
  }, [roleProfileDetail]);

  useEffect(() => {
    if (roleProfileDetail && roleProfileDetail.access_rights.length > 0) {
      setSelectedKey(roleProfileDetail.access_rights[0].resource_category_id);

      const initialSwitchStates = {};
      const initialGlobalPermissions = {};
      roleProfileDetail.access_rights.forEach((section) => {
        if (section.resources && section.resources.length > 0) {
          section.resources.forEach((data) => {
            initialSwitchStates[data.resources_id] = determineSwitchState(
              data.permissions
            );
            initialGlobalPermissions[data.resources_id] = initializePermissions(
              data.permissions
            );
          });
        }
      });
      setSwitchStates(initialSwitchStates);
      setGlobalPermissions(initialGlobalPermissions);
    }
  }, [roleProfileDetail]);

  const determineSwitchState = (permissions) => {
    return permissions.some((perm) => perm.is_selected);
  };

  const initializePermissions = (permissions) => {
    const initialSelected = {};
    const initialChecked = {};
    permissions.forEach((perm) => {
      if (!initialChecked[perm.actions]) {
        initialChecked[perm.actions] = false;
      }
      if (perm.is_selected) {
        initialChecked[perm.actions] = true;
        initialSelected[perm.actions] = perm.possessions;
      }
    });
    return { selected: initialSelected, checked: initialChecked };
  };

  const handleSwitchChange = useCallback(
    (resourcesId, checked) => {
      setGlobalPermissions((prevPermissions) => {
        const updatedPermissions = { ...prevPermissions };

        const updateResourcePermissions = (
          resourceId,
          isEnabled,
          isConnected = false,
          permissions = []
        ) => {
          const resource = updatedPermissions[resourceId] || {
            checked: {},
            selected: {},
          };

          const actions = isConnected
            ? permissions
            : ["create", "update", "delete", "read"];
          actions.forEach((action) => {
            resource.checked[action] = isEnabled;
            resource.selected[action] = isEnabled ? "own" : "";
          });

          updatedPermissions[resourceId] = resource;
        };

        updateResourcePermissions(resourcesId, checked);

        if (memoizedDefaultModules[resourcesId]) {
          const matchedResources = memoizedDefaultModules[resourcesId];

          if (checked) {
            matchedResources.forEach((resource) => {
              const { resourceId, permissions } = resource;

              const truePermissions = Object.keys(permissions).filter(
                (key) => permissions[key] === true
              );

              updateResourcePermissions(
                resourceId,
                true,
                true,
                truePermissions
              );
            });
          }
        }

        return updatedPermissions;
      });

      setSwitchStates((prevSwitchStates) => {
        const updatedSwitchStates = { ...prevSwitchStates };
        updatedSwitchStates[resourcesId] = checked;

        if (memoizedDefaultModules[resourcesId]) {
          const matchedResources = memoizedDefaultModules[resourcesId];

          if (checked) {
            matchedResources.forEach((resource) => {
              const { resourceId } = resource;
              updatedSwitchStates[resourceId] = true;
            });
          }
        }
        return updatedSwitchStates;
      });

      setUnsavedChanges(true);
    },
    [
      setGlobalPermissions,
      setSwitchStates,
      setUnsavedChanges,
      memoizedDefaultModules,
    ]
  );

  const handlePossessionChange = (resourcesId, action, possession) => {
    setGlobalPermissions((prevState) => ({
      ...prevState,
      [resourcesId]: {
        ...prevState[resourcesId],
        selected: {
          ...prevState[resourcesId]?.selected,
          [action]: possession,
        },
      },
    }));

    setUnsavedChanges(true);
  };

  const handleCheckboxChange = useCallback((resourcesId, action, checked) => {
    setGlobalPermissions((prevState) => {
      const updatedPermissions = {
        ...prevState,
        [resourcesId]: {
          ...prevState[resourcesId],
          checked: {
            ...prevState[resourcesId]?.checked,
            [action]: checked,
          },
          selected: {
            ...prevState[resourcesId]?.selected,
            [action]: checked ? "own" : "",
          },
        },
      };

      // Automatically set read checkbox based on create, update, delete
      if (
        checked &&
        (action === "create" || action === "update" || action === "delete")
      ) {
        updatedPermissions[resourcesId] = {
          ...updatedPermissions[resourcesId],
          checked: {
            ...updatedPermissions[resourcesId]?.checked,
            read: true,
          },
          selected: {
            ...updatedPermissions[resourcesId]?.selected,
            read: "own",
          },
        };
      } else if (!checked && action === "read") {
        updatedPermissions[resourcesId] = {
          ...updatedPermissions[resourcesId],
          checked: {
            create: false,
            update: false,
            delete: false,
            read: false,
          },
          selected: {
            read: "",
            create: "",
            update: "",
            delete: "",
          },
        };
      }

      // Check if all actions are false, then turn off the switch
      const allFalse = Object.keys(
        updatedPermissions[resourcesId]?.checked
      ).every((key) => !updatedPermissions[resourcesId]?.checked[key]);

      setSwitchStates((prevState) => ({
        ...prevState,
        [resourcesId]: !allFalse,
      }));

      return updatedPermissions;
    });

    setUnsavedChanges(true);
  }, []);

  const handleSavePermissions = async () => {
    const updatedRoleProfileDetail = JSON.parse(
      JSON.stringify(roleProfileDetail)
    );
    const permissionsArray = [];

    await updatedRoleProfileDetail.access_rights.forEach((section) => {
      if (section.resources) {
        section.resources.forEach((resource) => {
          const permissions = resource.permissions;
          const globalPermission = globalPermissions[resource.resources_id];

          permissions.forEach((perm) => {
            if (
              globalPermission.checked[perm.actions] &&
              globalPermission.selected[perm.actions] === perm.possessions
            ) {
              perm.is_selected = true;
            } else {
              perm.is_selected = false;
            }

            permissionsArray.push({
              permission_id: perm.permissions_id,
              is_selected: perm.is_selected,
            });
          });
        });
      }
    });

    const req = {
      role_profile_id: roleProfileId,
      permission: permissionsArray,
    };

    // console.log({ req });

    await dispatch(
      actionAssignRoleProfilePermission({
        req,
        setUnsavedChanges,
        user: currentCompanyUser,
      })
    );
  };

  return (
    // <Spin spinning={getSingleRoleProfileLoader}>

    <section className="main-section">
      <div className="flex justify-between">
        <div
          onClick={() => navigate(-1)}
          className="text-brand cursor-pointer flex items-center gap-x-3"
        >
          <Image
            src={leftArrowPrimary}
            alt="image"
            height={20}
            width={20}
            preview={false}
          />
          <h1 className="lg:text-lg text-base font-popinsSemiBold">
            {formatString(roleProfileDetail?.name)}
          </h1>
        </div>
        <div className="flex gap-x-3">
          <div>
            <Input
              placeholder="Search module"
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
              prefix={<SearchOutlined className="text-[#dddae9]" />}
              className="mb-2 !min-h-[36px] !max-h-[36px]"
              allowClear
              disabled={getSingleRoleProfileLoader}
            />
          </div>
          <div>
            <Button
              className="outline-button-brand  min-h-[35px] min-w-[120px]"
              loading={assignRoleProfilePermissionLoader}
              onClick={handleSavePermissions}
              disabled={!unsavedChanges || assignRoleProfilePermissionLoader}
            >
              Save Permissions
            </Button>
          </div>
        </div>
        {/* <CustomIconText title={"Permissions"} image={purplePermission} /> */}
      </div>
      <Card
        className={`mt-2 permission-card ${getSingleRoleProfileLoader ? "p-5" : "p-0"
          }`}
        loading={getSingleRoleProfileLoader}
      >
        <div className="h-full flex">
          <div className="border-r border-bColor xl:w-[25%] w-[20%] 2xl:min-h-[750px] h-auto">
            <h1 className="lg:text-lg text-base font-popinsSemiBold pl-7 pt-7">
              Module Permissions
            </h1>

            <Layout className="inner-permission-layout ">
              <Layout.Sider
                className={
                  "transition-all pb-6 py-3 side-menu duration-1000 fixed overflow-auto ease-in"
                }
                style={{
                  background: "#fff",
                }}
              >
                <Menu
                  onSelect={({ key }) => setSelectedKey(parseInt(key))}
                  className="pb-5 border-0"
                  theme={"light"}
                  mode="inline"
                  selectedKeys={[selectedKey.toString()]}
                  items={SidebarData}
                />
              </Layout.Sider>
            </Layout>
          </div>

          <div className="px-10 xl:w-[75%] w-[80%] pt-7">
            {roleProfileDetail &&
              roleProfileDetail.access_rights.map((section) => (
                <div
                  key={section.resource_category_id}
                  style={{
                    display:
                      selectedKey === section.resource_category_id
                        ? "block"
                        : "none",
                  }}
                >
                  <h1 className="lg:text-lg text-base font-popinsSemiBold">
                    {formatString(section.resource_category_name)} Permissions
                  </h1>


                  {section.resources &&
                    section.resources.length > 0 &&
                    (() => {

                      const escapeRegex = (string) =>
                        string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

                      const escapedSearchKey = escapeRegex(searchKey);
                      const searchRegex = new RegExp(
                        `${escapedSearchKey}`,
                        "i"
                      );

                      const filteredResources = section.resources.filter((data) => {
                        const label = data.label || ""; 
                        return searchRegex.test(label);
                      });

                      if (filteredResources.length === 0) {
                        return <Empty description={"No data found"} />;
                      }

                      return filteredResources.map((data, index) => (
                        <Row
                          justify={"space-between"}
                          gutter={24}
                          key={index}
                          className="flex max-w-full py-7 border-b border-bColor"
                        >
                          <Col span={10} className="flex justify-between">
                            {getSingleRoleProfileLoader ? (
                              <>
                                <div className="gap-x-4">
                                  <Skeleton.Input
                                    active
                                    size="small"
                                    style={{
                                      height: "19px",
                                      marginTop: "3px",
                                    }}
                                  />
                                </div>
                              </>
                            ) : (
                              <div className="flex items-center justify-start mb-auto gap-x-2">
                                <h1 className="xl:text-base lg:text-[15px] text-sm">
                                  {data.label}
                                  {data.description && (
                                    <Tooltip title={data.description}>
                                      <InfoCircleOutlined className="mx-1 text-gray-500 cursor-pointer" />
                                    </Tooltip>
                                  )}
                                </h1>
              
                              </div>
                            )}
                            {getSingleRoleProfileLoader ? (
                              <>
                                <div className="gap-x-4">
                                  <Skeleton.Input
                                    active
                                    size="small"
                                    style={{
                                      height: "19px",
                                      marginTop: "3px",
                                    }}
                                  />
                                </div>
                              </>
                            ) : (
                              <Switch
                                checked={switchStates[data.resources_id]}
                                onChange={(checked) =>
                                  handleSwitchChange(data.resources_id, checked)
                                }
                              />
                            )}
                          </Col>

                          {/* <Col span={1} className="p-0"/> */}

                          {getSingleRoleProfileLoader ? (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                  gap: "12px",
                                }}
                              >
                                {Array.from({ length: 4 }).map((_, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "12px",
                                    }}
                                  >
                                    <Skeleton.Input
                                      active
                                      size="small"
                                      style={{
                                        width: "80px",
                                        height: "16px",
                                      }}
                                    />

                                    <Skeleton.Input
                                      active
                                      size="small"
                                      style={{
                                        width: "80px",
                                        height: "16px",
                                      }}
                                    />
                                  </div>
                                ))}
                              </div>
                            </>
                          ) : (
                            <Col span={12} className="flex justify-between">
                              <PermissionGroup
                                data={data}
                                switchState={switchStates}
                                globalPermissions={globalPermissions}
                                onPossessionChange={(action, possession) =>
                                  handlePossessionChange(
                                    data.resources_id,
                                    action,
                                    possession
                                  )
                                }
                                onCheckboxChange={(action, checked) =>
                                  handleCheckboxChange(
                                    data.resources_id,
                                    action,
                                    checked
                                  )
                                }
                              />
                            </Col>
                          )}
                        </Row>
                      ));
                    })()}
                </div>
              ))}
          </div>
        </div>
      </Card>
    </section>
    // </Spin>
  );
};

const PermissionGroup = React.memo(
  ({
    data,
    switchState,
    globalPermissions,
    onPossessionChange,
    onCheckboxChange,
  }) => {
    const [groupedPermissions, setGroupedPermissions] = useState({});
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
      if (data && Array.isArray(data.permissions)) {
        const grouped = data.permissions.reduce((acc, perm) => {
          if (!acc[perm.actions]) {
            acc[perm.actions] = { any: null, own: null };
          }
          acc[perm.actions][perm.possessions] = perm;
          return acc;
        }, {});
        setGroupedPermissions(grouped);

        const switchOn = switchState[data.resources_id];
        setDisabled(!switchOn);
      }
    }, [data, switchState]);

    return (
      <Row className="mb-2">
        {Object.keys(groupedPermissions).map((action, index) => (
          <Fragment key={index}>
            <Col span={16}>
              <Checkbox
                className="custom-checkbox"
                checked={globalPermissions[data.resources_id]?.checked[action]}
                onChange={(e) => onCheckboxChange(action, e.target.checked)}
                disabled={disabled} // Disable checkbox based on state
              >
                {action.charAt(0).toUpperCase() + action.slice(1)}
              </Checkbox>
            </Col>
            <Col span={8}>
              <Radio.Group
                className="flex justify-between"
                value={globalPermissions[data.resources_id]?.selected[action]}
                onChange={(e) => onPossessionChange(action, e.target.value)}
                disabled={
                  !globalPermissions[data.resources_id]?.checked[action] ||
                  disabled
                } // Disable radio group based on state
              >
                <Radio className="text-base" value="any">
                  Any
                </Radio>
                <Radio className="text-base" value="own">
                  Own
                </Radio>
              </Radio.Group>
            </Col>
          </Fragment>
        ))}
      </Row>
    );
  }
);

export default ModulePermissions;
