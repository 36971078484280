import { EyeOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import PageTitle from "../common/PageTitle";
import * as XLSX from "xlsx";
import invoiceIcon from "../../assets/img/invoiceIcon.svg";
import CustomInputWithButtons from "../common/CustomInputWithButtons ";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, Dropdown, Image, Pagination, Table } from "antd";

import {
  PlusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import SortIcon from "../common/SortIcon";
import dayjs from "dayjs";
import {
  actionClearAllSalesInvoice,
  actionGetAllSalesInvoices,
  actionGetSalesInvoices,
} from "../../store/services/salesService";
import { useNavigate } from "react-router-dom";
import SkeletonTable from "../common/SkeletonTable";
import CommonTable from "../common/CommonTable";
import CommonPagination from "../common/CommonPagination";

const SalesInvoices = () => {
  const navigate = useNavigate();
  const {
    getSalesInvoicesLoader,
    getAllSalesInvoicesLoader,
    salesInvoicesData,
    allSalesInvoicesCount,
    allSalesInvoices,
    salesInvoiceDetails: { },
  } = useSelector((state) => state.sales);
  const dispatch = useDispatch();
  const offsetRef = useRef(0);

  const [search, setSearch] = useState("");
  const [exportAll, setExportAll] = useState(false);
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [lastSearch, setLastSearch] = useState('');
  const [pageSize, setPageSize] = useState(20);

  const getApiCall = (
    offset = 0,
    limit = pageSize,
    search = "",
    date = "",
    orderByColumn = "",
    order = ""
  ) => {
    dispatch(
      actionGetSalesInvoices({
        offset,
        limit,
        orderByColumn,
        order,
        search,
        date,
      })
    );
  };

  useEffect(() => {
    getApiCall(offsetRef.current, pageSize, search);
  }, [dispatch]);

  const handleRefresh = () => {
    offsetRef.current = 0;
    getApiCall(offsetRef.current, pageSize, search);
  };

  useEffect(() => {
    const trimmedSearch = search?.trim();
    if (trimmedSearch !== "" && trimmedSearch !== lastSearch) {
      const timer = setTimeout(() => {
        getApiCall(offsetRef.current, pageSize, trimmedSearch, invoiceDate);
        setLastSearch(trimmedSearch);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }

  }, [search, lastSearch]);

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      getApiCall(offsetRef.current, pageSize, e.target.value, invoiceDate);
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      getApiCall(offsetRef.current, pageSize, e.target.value, invoiceDate);
    }
  };

  const onClickDownloadMenu = () => {
    dispatch(actionGetAllSalesInvoices());
    setExportAll(true);
  };

  const exportAllToExcel = async () => {
    const data =
      (await allSalesInvoices) &&
      allSalesInvoices.length > 0 &&
      allSalesInvoices.map((data) => ({
        "Invoice Number": data.invoice_number ? data.invoice_number : "-",
        "IRN Number": data.irn_number ? data.irn_number : "-",
        "Invoice Date": data.invoice_date
          ? dayjs(data.invoice_date).format("DD/MM/YYYY")
          : "-",
        "Eway Bill Number": data.eway_bill_number ? data.eway_bill_number : "-",
        Customer: data.customer ? data.customer.business_partner_name : "-",
        "Invoice File Name": data.invoice_file_name
          ? data.invoice_file_name.originalname
          : "-",
        "Ship to Party": data.ship_to_party
          ? data.ship_to_party.business_partner_name
          : "-",
        Note: data.note ? data.note : "-",
        "Total Base Value": data.total_base_value ? data.total_base_value : "-",
        "Total Discount": data.total_discount ? data.total_discount : "-",
        "Total Other Charges": data.total_other_charges
          ? data.total_other_charges
          : "-",
        "Total CGST": data.total_cgst ? data.total_cgst : "-",
        "Total SGST": data.total_sgst ? data.total_sgst : "-",
        "Total UTGST": data.total_utgst ? data.total_utgst : "-",
        "Total Net Value": data.total_net_value ? data.total_net_value : "-",
        "Total Value": data.total_value ? data.total_value : "-",
        "TCS Value": data.tcs_value ? data.tcs_value : "-",
        "Round Off": data.round_off ? data.round_off : "-",
      }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Invoices");
    XLSX.writeFile(workbook, "invoices.xlsx");

    dispatch(actionClearAllSalesInvoice({ setExportAll }));
  };

  useEffect(() => {
    if (exportAll && allSalesInvoices && allSalesInvoices.length > 0) {
      exportAllToExcel();
    }
  }, [allSalesInvoices]);

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.columnKey,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
            ? "ASC"
            : "",
    };

    getApiCall(
      offsetRef.current,
      pageSize,
      search,
      invoiceDate,
      sorterParams.field,
      sorterParams.order
    );
  };

  const columns = [
    {
      title: "CH No.",
      key: "ch_no",
      width: 100,
      dataIndex: "invoice_id",
      fixed: "left",
      render: (text) =>
        <span
          onClick={() => {
            navigate("/view-sales-invoice", { state: { id: text } });
          }}
          className="flex text-brand items-center cursor-pointer"
        >
          {text ?? "-"}
        </span>,
    },
    {
      title: "Invoice Number",
      dataIndex: "invoice_number",
      key: "invoice_number",
      width: 200,
      fixed: "left",

      render: (text, data) => (
        <span
          onClick={() => {
            navigate("/view-sales-invoice", { state: { id: data.invoice_id } });
          }}
          className="text-brand cursor-pointer"
        >
          {text ? text : "-"}
        </span>
      ),
    },
    {
      title: "Invoice Date",
      dataIndex: "invoice_date",
      key: "invoice_date",
      width: 150,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (text ? dayjs(text).format("DD-MM-YYYY") : "-"),
    },
    {
      title: "Customer",
      dataIndex: ["customer", "business_partner_name"],
      key: "customer",
      ellipsis: true,
      width: 250,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Ship To Party",
      dataIndex: ["ship_to_party", "business_partner_name"],
      key: "ship_to_party",
      ellipsis: true,
      width: 250,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      ellipsis: true,
      width: 250,

      render: (text) => (text ? text : "-"),
    },
    {
      title: "IRN Number",
      dataIndex: "irn_number",
      key: "irn_number",
      ellipsis: true,
      width: 200,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (text ? text : "-"),
    },

    {
      title: "Eway Bill Number",
      dataIndex: "eway_bill_number",
      key: "eway_bill_number",
      ellipsis: true,
      width: 250,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Invoice File Name",
      dataIndex: "invoice_file_name",
      key: "invoice_file_name",
      ellipsis: true,
      width: 250,
      render: (file) => {
        if (!file || !file.url) return "-";

        const isImage = file.mimetype.startsWith("image");
        const isDocument = [
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ].includes(file.mimetype);

        if (isImage) {
          return (
            <Image
              preview={{ mask: <EyeOutlined /> }}
              className="cursor-pointer"
              src={file.url}
              alt={file.originalname}
              style={{ width: 50, height: 50, objectFit: "contain" }}
            />
          );
        } else if (isDocument) {
          return (
            <a
              href={file.url}
              className="text-blue-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              {file.originalname}
            </a>
          );
        } else {
          return file.originalname;
        }
      },
    },
    {
      title: "Total Base Value",
      dataIndex: "total_base_value",
      key: "total_base_value",
      width: 200,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Total Discount",
      dataIndex: "total_discount",
      key: "total_discount",
      width: 150,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Total Other Charges",
      dataIndex: "total_other_charges",
      key: "total_other_charges",
      width: 200,

      render: (text) => (text ? text : "-"),
    },
    {
      title: "Total CGST",
      dataIndex: "total_cgst",
      key: "total_cgst",
      width: 200,

      render: (text) => (text ? text : "-"),
    },
    {
      title: "Total SGST",
      dataIndex: "total_sgst",
      key: "total_sgst",
      width: 200,

      render: (text) => (text ? text : "-"),
    },
    {
      title: "Total UTGST",
      dataIndex: "total_utgst",
      key: "total_utgst",
      width: 200,

      render: (text) => (text ? text : "-"),
    },
    {
      title: "Total Net Value",
      dataIndex: "total_net_value",
      key: "total_net_value",
      width: 200,

      render: (text) => (text ? text : "-"),
    },
    {
      title: "Total Value",
      dataIndex: "total_value",
      key: "total_value",
      width: 200,

      render: (text) => (text ? text : "-"),
    },
    {
      title: "TCS Value",
      dataIndex: "tcs_value",
      key: "tcs_value",
      width: 150,

      render: (text) => (text ? text : "-"),
    },
    {
      title: "Round Off",
      dataIndex: "round_off",
      key: "round_off",
      width: 150,

      render: (text) => (text ? text : "-"),
    },
  ];

  const handlePaginationChange = (page, pageSize) => {
    offsetRef.current = page - 1;
    setPageSize(pageSize);
    getApiCall(offsetRef.current, pageSize, search, invoiceDate);
  }

  return (
    <section className="main-wrapper main-table-container ">
      <CommonTable
        pageTitle={<PageTitle image={invoiceIcon} margin={false} title="Invoices" rightIcon={false} />
        }
        title="Invoices"
        moduleKey="invoice"
        loading={getSalesInvoicesLoader || getAllSalesInvoicesLoader}
        columns={columns}
        scrollToFirstRowOnChange={true}
        data={(salesInvoicesData || [])}
        rowKey={"invoice_id"}
        onChange={handleSortChange}
        handleRefresh={handleRefresh}
        handleSearch={handleSearch}
        handleBlurAndPressEnter={handleBlurAndPressEnter}
        search={search}
        onClickDownloadMenu={onClickDownloadMenu}
        offset={offsetRef.current}
        total={allSalesInvoicesCount}
        addBtn={false}
        handlePaginationChange={handlePaginationChange}
      />

      <div className="flex justify-end mt-3">
        <CommonPagination
          current={offsetRef.current + 1}
          total={allSalesInvoicesCount}
          onChange={(page, pageSize) => {
            offsetRef.current = page - 1;
            setPageSize(pageSize);
            getApiCall(offsetRef.current, pageSize, search, invoiceDate);
          }}
          loading={getSalesInvoicesLoader}
          pageSize={pageSize}
        />
      </div>
    </section>
  );
};

export default SalesInvoices;
