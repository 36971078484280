import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Image,
  Input,
  Modal,
  Pagination,
  Popover,
  Row,
  Select,
  Skeleton,
  Spin,
  Table,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import inventory from "../../../assets/img/event-inventory.svg";
import plusSvg from "../../../assets/img/plus.svg";
import {
  actionAddEventInventory,
  actionClearAllEventSpeakerData,
  actionDeleteEventInventory,
  actionEventInventoryDetail,
  actionGetAllEventInventory,
  actionGetEventInventory,
  actionUpdateEventInventory,
} from "../../../store/services/eventService";
import {
  actionAddEventProductMaster,
  actionGetEventProductMaster,
} from "../../../store/services/masterDataService";
import CustomInputWithButtons from "../../common/CustomInputWithButtons ";
import PageTitle from "../../common/PageTitle";
import SortIcon from "../../common/SortIcon";
import {
  getEventId,
  getFirstLetterCapital,
} from "../../helper/common-function";
import EventDetailWrapper from "./EventDetailWrapper";
import usePermission from "../../../hooks/use-permission";
import { handleActionWithPermission } from "../../../store/slices/authSlice";
import SkeletonTable from "../../common/SkeletonTable";
import CommonTable from "../../common/CommonTable";
import CommonPagination from "../../common/CommonPagination";

const EventInventory = () => {
  const dispatch = useDispatch();
  const eventId = getEventId();
  const [form] = Form.useForm();
  const [productModalForm] = Form.useForm();
  const {
    getAllEventInventoryLoader,
    allEventInventoryData,
    getEventInventoryLoader,
    addEventInventoryLoader,
    deleteEventInventoryLoader,
    updateEventInventoryLoader,
    eventInventoryData,
    eventInventoryCount,
    getEventInventoryDetail,
    getEventInventoryDetailLoader,
  } = useSelector((state) => state.events);

  const { eventProductData, getEventProductLoader, addEventProductLoader } =
    useSelector((state) => state.masterData);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [exportAll, setExportAll] = useState(false);
  const [search, setSearch] = useState("");
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [record, setRecord] = useState("");
  const [isDeleteModelOpen, setIsDeleteModelOpen] = useState(false);
  const [lastSearch, setLastSearch] = useState('');
  const [pageSize, setPageSize] = useState(20);
  const { hasPermission } = usePermission();

  const offsetRef = useRef(0);

  useEffect(() => {
    if (eventId) {
      dispatch(
        actionGetEventInventory({ offset: offsetRef.current, limit: pageSize, search, eventId })
      );
    }
  }, [dispatch]);

  const columns = [
    {
      title: "Product",
      key: "1",
      fixed: "left",
      dataIndex: "event_inventory_id",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 150,
      render: (text, data) => (
        <div className="flex items-center cursor-pointer">
          <p className="ml-2">
            {getFirstLetterCapital(data?.event_product?.product_name)}
          </p>
        </div>
      ),
    },
    {
      title: "Total qty",
      dataIndex: "qty",
      key: "2",
      width: 80,
      sortIcon: () => <SortIcon />,
      sorter: (a, b) => true,
      render: (text, data) => <span className="ml-2">{text}</span>,
    },
    {
      title: "Available qty",
      key: "3",
      width: 80,
      dataIndex: "available_qty",
      render: (text) => <span className="ml-2">{text}</span>,
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 50,
      align: "center",
      fixed: "right",
      render: (text, data) => (
        <>
          <Dropdown
            id="custom-drop"
            menu={{
              items: [
                {
                  label: <span>Edit</span>,
                  key: "Edit",
                  icon: <EditOutlined />,
                  onClick: () => {
                    dispatch(
                      handleActionWithPermission(
                        "event-inventory",
                        "update",
                        () => {
                          setIsModalOpen(true);
                          setRecord(data);
                          dispatch(actionGetAllEventInventory(eventId));
                        }
                      )
                    );
                  },
                },

                {
                  label: <span style={{ color: "#ff4d4f" }}>Delete</span>,
                  key: "Delete",
                  icon: <DeleteOutlined style={{ color: "#ff4d4f" }} />,
                  onClick: () => {
                    dispatch(
                      handleActionWithPermission(
                        "event-inventory",
                        "delete",
                        () => {
                          setIsDeleteModelOpen(true);
                          setRecord(data);
                        }
                      )
                    );
                  },
                  key: "Delete",
                },
              ].filter(Boolean),
            }}
          >
            <div className="flex justify-center items-center">
              <EllipsisOutlined />
            </div>
          </Dropdown>
        </>
      ),
    },
  ];

  const handleRefresh = () => {
    dispatch(
      actionGetEventInventory({
        offset: offsetRef.current,
        limit: pageSize,
        search: search,
        eventId,
      })
    );
  };

  useEffect(() => {
    const trimmedSearch = search?.trim();
    if (trimmedSearch !== "" && trimmedSearch !== lastSearch) {
      const timer = setTimeout(() => {
        dispatch(
          actionGetEventInventory({ offset: offsetRef.current, limit: pageSize, search: trimmedSearch, eventId })
        );
        setLastSearch(trimmedSearch);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }

  }, [search, lastSearch]);

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      dispatch(
        actionGetEventInventory({
          offset: offsetRef.current,
          limit: pageSize,
          search: e.target.value,
          eventId,
        })
      );
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      dispatch(
        actionGetEventInventory({
          offset: offsetRef.current,
          limit: pageSize,
          search: e.target.value,
          eventId,
        })
      );
    }
  };

  const exportAllToExcel = async () => {
    const data =
      (await allEventInventoryData) &&
      allEventInventoryData.length > 0 &&
      allEventInventoryData.map((data) => ({
        Product: data?.event_product?.product_name
          ? data?.event_product?.product_name
          : "-",
        "Total Qty": data.qty ? data.qty : "-",
        "Available Qty": data.available_qty ? data.available_qty : "-",
      }));
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Event Inventory");
    // Generate the Excel file and initiate the download
    XLSX.writeFile(workbook, "event-inventory.xlsx");
    dispatch(actionClearAllEventSpeakerData({ setExportAll }));
  };

  useEffect(() => {
    if (
      exportAll &&
      allEventInventoryData &&
      allEventInventoryData.length > 0
    ) {
      exportAllToExcel();
    }
  }, [allEventInventoryData]);

  const onClickDownloadMenu = () => {
    dispatch(actionGetAllEventInventory(eventId));
    setExportAll(true);
  };

  useEffect(() => {
    if (record && isModalOpen) {
      dispatch(
        actionEventInventoryDetail({ id: record.event_inventory_id, eventId })
      );
    }
  }, [dispatch, record]);

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
            ? "ASC"
            : "",
    };

    dispatch(
      actionGetEventInventory({
        offset: offsetRef.current,
        limit: pageSize,
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
        eventId,
      })
    );
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsDeleteModelOpen(false);
    setRecord("");
    form.resetFields();
  };

  const showProductFormModal = () => {
    setIsProductModalOpen(true);
  };

  useEffect(() => {
    if (record && getEventInventoryDetail) {
      form.setFieldsValue({
        qty: getEventInventoryDetail?.qty,
        event_product_master_id:
          getEventInventoryDetail?.event_product?.event_product_master_id,
      });
    }
  }, [record, getEventInventoryDetail]);

  const onFinish = (values) => {
    const data = {
      ...values,
      qty: values.qty ? parseInt(values.qty) : values.qty,
      event_id: eventId,
    };

    const req = {
      ...Object.fromEntries(
        Object.entries(data).filter(
          ([_, v]) =>
            v !== undefined &&
            v !== null &&
            !(typeof v === "string" && v.trim() === "") &&
            (!Array.isArray(v) || v.length > 0)
        )
      ),
    };

    record
      ? dispatch(
        actionUpdateEventInventory({
          id: getEventInventoryDetail?.event_inventory_id,
          req,
          handleCancel,
        })
      )
      : dispatch(
        actionAddEventInventory({
          req,
          handleCancel,
        })
      );
  };

  useEffect(() => {
    if (isModalOpen) {
      dispatch(actionGetEventProductMaster());
    }
  }, [isModalOpen, dispatch]);

  const productData =
    eventProductData && eventProductData.length > 0
      ? eventProductData.map((product, index) => ({
        label: product.product_name,
        value: product.event_product_master_id,
        disabled:
          allEventInventoryData &&
          allEventInventoryData.length > 0 &&
          allEventInventoryData.some(
            (inventoryItem) =>
              inventoryItem.event_product.event_product_master_id ===
              product.event_product_master_id
          ),
      }))
      : [];

  const handleCancelProductModal = () => {
    productModalForm.resetFields();
    setIsProductModalOpen(false);
  };

  const addProductPopover = (
    <>
      <div className="min-w-[350px]">
        <Form
          form={productModalForm}
          onFinish={(values) =>
            dispatch(
              actionAddEventProductMaster({
                form,
                values,
                handleCancel: handleCancelProductModal,
              })
            )
          }
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="product_name"
            label="Product Name"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please enter product name!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Product Name"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={handleCancelProductModal}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addEventProductLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );

  return (
    <EventDetailWrapper>
      <div className="event-table-container main-table-container" style={{ height: "calc(100vh - 130px)" }}>
        <CommonTable wrapperClassName="2xl:max-h-[calc(100vh-140px)] xl:max-h-[calc(100vh-120px)] max-h-[calc(100vh-120px)]"
          pageTitle={<PageTitle
            image={inventory}
            title="Event Inventory"
            rightIcon={false}
          />}
          buttonText="Add Event Inventory"
          title="Inventorsy"
          onAddClick={() => {
            setIsModalOpen(true);
            dispatch(actionGetAllEventInventory(eventId));
          }}

          type="btn"
          resource="event-inventory"
          moduleKey="event_inventory"
          total={eventInventoryCount}
          pageSize={pageSize}
          loading={getEventInventoryLoader || getAllEventInventoryLoader}
          columns={columns}
          scrollToFirstRowOnChange={true}
          data={(eventInventoryData || [])}
          rowKey={"event_inventory_id"}
          onChange={handleSortChange}
          handleRefresh={handleRefresh}
          handleSearch={handleSearch}
          handleBlurAndPressEnter={handleBlurAndPressEnter}
          search={search}
          onClickDownloadMenu={onClickDownloadMenu}
          handlePaginationChange={(page, pageSize) => {
            offsetRef.current = page - 1;
            setPageSize(pageSize);
            dispatch(
              actionGetEventInventory({
                eventId,
                offset: offsetRef.current,
                limit: pageSize,
                search,
              })
            );
          }}
          offset={offsetRef.current}
        />

        <Modal
          className="delete-modal"
          centered
          onOk={handleCancel}
          onCancel={handleCancel}
          afterClose={handleCancel}
          footer={false}
          width={600}
          title=""
          open={isDeleteModelOpen}
        >
          <div className="">
            <h1 className="text-[#000000;] text-[20px] font-popinsMedium mt-2">
              Are you sure you want to remove this product from event?
            </h1>
            <p className="text-[15px]  text-[#7A7A7A] pt-3">
              All the associations will be removed for this product.
            </p>
            <div className="flex items-center pt-8 justify-end w-full gap-x-3">
              <Form.Item className="mb-0">
                <Button
                  onClick={handleCancel}
                  type="button"
                  className="gray-button gray-hover text-white hover:text-white  min-h-[45px] min-w-[120px]"
                  htmlType="submit"
                >
                  Cancel
                </Button>
              </Form.Item>

              <Form.Item className="mb-0">
                <Button
                  loading={deleteEventInventoryLoader}
                  type="primary"
                  className="danger-btn  min-h-[45px] min-w-[120px]"
                  htmlType="submit"
                  onClick={() => {
                    dispatch(
                      actionDeleteEventInventory({
                        id: record.event_inventory_id,
                        event_id: eventId,
                        handleCancel,
                      })
                    );
                  }}
                >
                  Delete
                </Button>
              </Form.Item>
            </div>
          </div>
        </Modal>

        <Modal
          centered
          onOk={handleCancel}
          onCancel={handleCancel}
          afterClose={handleCancel}
          footer={false}
          width={500}
          title=""
          open={isModalOpen}
        >
          <div className="text-brand flex items-center gap-x-3">
            <Image
              src={inventory}
              alt="image"
              height={20}
              width={20}
              preview={false}
            />

            <h1 className="lg:text-lg text-base  font-popinsRegular">
              {record ? "Update" : "Add"} Event Inventory
            </h1>
          </div>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            className="mt-[20px] mb-0"
          >
            {/* <Spin spinning={getEventInventoryDetailLoader}> */}
            <Row gutter={[16, 0]}>
              {getEventInventoryDetailLoader ? (
                <Col span={24}>
                  <Skeleton.Input
                    active
                    size="default"
                    className="antd-second-custom-input"
                    style={{ height: "35px", marginBottom: "20px" }}
                  />
                </Col>
              ) : (
                <>
                  <Col span={24}>
                    <Popover
                      placement="bottomRight"
                      content={addProductPopover}
                      trigger="click"
                      open={isProductModalOpen}
                      onOpenChange={(visible) => {
                        if (!visible) {
                          setIsProductModalOpen(false);
                          productModalForm.resetFields();
                        }
                      }}
                    >
                      <div
                        className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                        onClick={showProductFormModal}
                      >
                        <Image
                          src={plusSvg}
                          alt="image"
                          height={20}
                          width={20}
                          preview={false}
                        />
                        <h1 className="text-[14px] font-popinsRegular ml-1">
                          Add Product
                        </h1>
                      </div>
                    </Popover>
                    <Form.Item
                      name="event_product_master_id"
                      label="Product"
                      className="mb-[12px]"
                      size="small"
                      rules={[
                        {
                          required: true,
                          message: "Please select product!",
                        },
                      ]}
                    >
                      <Select
                        options={productData}
                        allowClear
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        showSearch
                        loading={getEventProductLoader}
                        disabled={getEventProductLoader}
                        style={{ background: "white" }}
                        placeholder="Select Product"
                        className="begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                      />
                    </Form.Item>
                  </Col>
                </>
              )}

              {getEventInventoryDetailLoader ? (
                <Col span={24}>
                  <Skeleton.Input
                    active
                    size="default"
                    className="antd-second-custom-input"
                    style={{ height: "35px", marginBottom: "20px" }}
                  />
                </Col>
              ) : (
                <>
                  <Col span={24}>
                    <Form.Item
                      name="qty"
                      label="Total Quantity"
                      className="mb-[12px]"
                      normalize={(value) => value && value.replace(/[^\d]/g, "")}
                      size="small"
                      rules={[
                        {
                          required: true,
                          message: "Please enter total qauntity!",
                        },
                      ]}
                    >
                      <Input
                        style={{ background: "white", width: "100%" }}
                        placeholder="Enter Total Quantity"
                        className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                      />
                    </Form.Item>
                  </Col>
                </>
              )}

              <Col
                span={24}
                align="middle"
                className="flex justify-center mt-[40px]"
              >
                <Form.Item className="mb-0">
                  <Button
                    onClick={handleCancel}
                    type="button"
                    className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
                  >
                    Cancel
                  </Button>
                </Form.Item>

                <div className="ml-4">
                  <Form.Item className="mb-0">
                    <Button
                      loading={
                        addEventInventoryLoader || updateEventInventoryLoader
                      }
                      type="primary"
                      className="root-btn min-h-[45px] min-w-[120px]"
                      htmlType="submit"
                    >
                      {record ? "Save" : "Submit"}
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>
            {/* </Spin> */}
          </Form>
        </Modal>
      </div>
    </EventDetailWrapper>
  );
};

export default EventInventory;
