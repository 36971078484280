import { Image, Skeleton, Tabs, Typography } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import locationImg from "../../../assets/img/location1.svg";
import { getEventId } from "../../helper/common-function";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { actionGetEventOverview } from "../../../store/services/eventService";
import usePermission from "../../../hooks/use-permission";

const EventDetailWrapper = React.memo(({ children }) => {
  const [activeTab, setActiveTab] = useState(null);
  const [indicatorStyle, setIndicatorStyle] = useState({});
  const history = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const eventId = useMemo(() => getEventId(), []);
  const eventIdRef = useRef(null);
  const currentPath = history.pathname;
  const tabsRef = useRef([]);
  const { hasPermission } = usePermission();

  const { eventsOverview } = useSelector((state) => state.events, shallowEqual);

  useEffect(() => {
    if (eventId && !eventsOverview && eventIdRef.current !== eventId) {
      dispatch(actionGetEventOverview(eventId));
      eventIdRef.current = eventId;
    }
  }, [eventId, dispatch]);

  useEffect(() => {
    if (!eventId) {
      navigate("/events");
    }
  }, [navigate]);

  useEffect(() => {
    const routeKeyMapping = {
      "event-overview": "1",
      attendees: "2",
      exhibitors: "3",
      sessions: "4",
      sponsor: "6",
      speakers: "5",
      "event-inventory": "7",
    };
    const pathParts = currentPath && currentPath.split("/");
    const pathKey = routeKeyMapping[pathParts[1]] || "1";
    setActiveTab(pathKey);
  }, [currentPath]);

  const handleChangeTab = (key) => {
    const keyRouteMapping = {
      1: `/event-overview/${eventId}`,
      2: "/attendees",
      3: "/exhibitors",
      4: "/sessions",
      5: "/speakers",
      6: "/sponsor",
      7: "/event-inventory",
    };
    navigate(keyRouteMapping[key]);
  };

  const items = [
    {
      key: "1",
      label: "Overview",
      onClick: () => handleChangeTab("1"),
      visible: true
    },
    {
      key: "2",
      label: "Attendees",
      onClick: () => handleChangeTab("2"),
      visible: hasPermission("event-attendee", "read"),
    },
    {
      key: "3",
      label: "Exhibitors",
      onClick: () => handleChangeTab("3"),
      visible: hasPermission("event-exhibitor", "read"),
    },
    {
      key: "4",
      label: "Sessions",
      onClick: () => handleChangeTab("4"),
      visible: hasPermission("event-session", "read"),
    },
    {
      key: "5",
      label: "Speakers",
      onClick: () => handleChangeTab("5"),
      visible: hasPermission("event-speaker", "read"),
    },
    {
      key: "6",
      label: "Sponsors",
      onClick: () => handleChangeTab("6"),
      visible: hasPermission("event-sponsor", "read"),
    },
    {
      key: "7",
      label: "Event Inventory",
      onClick: () => handleChangeTab("7"),
      visible: hasPermission("event-inventory", "read"),
    },
  ];

  return (
    <>
      <div className="px-6 h-[70px]  justify-between sticky z-50 overflow-x-auto top-0 border-b border-bColor flex items-center bg-white">
        {eventsOverview ? (
          <div className="flex flex-col space-y-2 justify-start items-start xl:w-[30%] w-[30%] 2xl:w-[35%]">
            <Typography.Paragraph
              style={{ marginBottom: 0 }}
              ellipsis={{ rows: 1 }}
              className="text-[15px] mb-0 w-full md:text-[17px] font-popinsSemiBold text-black leading-5"
            >
              {eventsOverview &&
                eventsOverview.event &&
                eventsOverview.event.event_name &&
                eventsOverview.event.event_name.length > 30
                ? `${eventsOverview.event.event_name.slice(0, 30)}...`
                : eventsOverview.event.event_name}
            </Typography.Paragraph>

            {eventsOverview &&
              eventsOverview.event &&
              eventsOverview.event.address && (
                <div className="flex gap-x-1 w-full items-center justify-start">
                  <Image
                    preview={false}
                    src={locationImg}
                    alt="Location Image"
                  />
                  <Typography.Paragraph
                    style={{ marginBottom: 0 }}
                    className="text-[12px] font-popinsRegular text-[#7C7C7C]"
                    ellipsis={{ rows: 1, tooltip: true }}

                  >
                    {eventsOverview.event.address}
                  </Typography.Paragraph>
                </div>
              )}
          </div>
        ) : (
          <div className="flex flex-col space-y-2 justify-start items-center xl:w-[30%] w-[30%] 2xl:w-[35%]">
            <Skeleton active paragraph={{ rows: 1 }} title={false}>
              <Typography.Paragraph
                style={{ marginBottom: 0 }}
                ellipsis
                className="text-[15px] mb-0 w-full md:text-[17px] font-popinsSemiBold text-black leading-5"
              >
                Loading event name...
              </Typography.Paragraph>
            </Skeleton>

            <div className="flex gap-x-1 w-full items-center justify-start">
              <Skeleton.Image style={{ width: 24, height: 24 }} />
              <Skeleton.Input style={{ width: 200 }} active size="small" />
            </div>
          </div>
        )}

        <div className="custom-tabs remove-scrollbar xl:w-[70%] 2xl:w-[65%] w-[70%]">
          {items.filter((item) => item.visible).map((item, index) => (
            <div
              key={item.key}
              ref={(el) => (tabsRef.current[item.key] = el)}
              className={`tab-item flex items-center justify-center ${activeTab === item.key ? "active" : ""
                }`}
              onClick={item.onClick}
            >
              {item.label}
            </div>
          ))}
        </div>
      </div>
      <section className="main-wrapper">{children}</section>
    </>
  );
});

export default EventDetailWrapper;
