import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "sonner";
import { handleErrorMessage } from "../../components/helper/common-function";
import { BASE_URL } from "../../config/web-config";
import { handleDeleteResponse, handleGetResponse } from "./commonService";

export const actionAddCustomField = createAsyncThunk(
  "actionAddCustomField",
  async ({ values, form, setFieldType, handleCancel }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/custom-fields`, values);
      const { status, message: customMsg, data } = response.data;

      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form && form.resetFields();
        setFieldType && setFieldType(null);
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionGetCustomFields = createAsyncThunk(
  "actionGetCustomFields",
  async (
    {
      module_name = "",
      search = "",
      orderByColumn = "sort_order",
      order = "ASC",
    } = {},
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/custom-fields?getall=YES&module_name=${module_name}${search ? "&search=" + search : ""
        }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${order ? `&order=${order}` : ""
        }`
      );
      const { status, message: customMsg, data, total_records } = response.data;

      if (parseInt(status) === 200) {
        return { data, total_records };
      } else if (parseInt(status) === 404) {
        return { data: [], total_records: 0 };
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
      return rejectWithValue(error.message);
    }
  }
);

export const actionGetAllCustomFields = createAsyncThunk(
  "actionGetAllCustomFields",
  async (module_name) => {
    return handleGetResponse(
      `custom-fields?module_name=${module_name}&getall=YES`
    );
  }
);

export const actionGetCustomField = createAsyncThunk(
  "actionGetCustomField",
  async (id) => {
    return handleGetResponse(`custom-fields/${id}`);
  }
);

export const actionEditCustomField = createAsyncThunk(
  "actionEditCustomField",
  async (
    { values, form, setFieldType, handleCancel, editId },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put(
        `${BASE_URL}/custom-fields/${editId}`,
        values
      );
      const { status, message: customMsg, data } = response.data;

      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form && form.resetFields();
        setFieldType && setFieldType(null);
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionDeleteCustomField = createAsyncThunk(
  "actionDeleteCustomField",
  async ({ id, confirmModal }) => {
    try {
      const response = await axios.delete(`${BASE_URL}/custom-fields/${id}`);
      const { status, message: customMsg } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        confirmModal && confirmModal();
        return id;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionChangeOrderCustomField = createAsyncThunk(
  "actionChangeOrderCustomField",
  async ({ req }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/custom-fields/sort-order`,
        req
      );
      const { status, message: customMsg, data } = response.data;

      if (parseInt(status) === 200) {
        // toast.success(customMsg, 5);
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);
