import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Image,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Select,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import plusIcon from "../../../assets/img/add-button.svg";
import deletevent from "../../../assets/img/deleteevent.svg";
import editedevent from "../../../assets/img/editedevent.svg";
import editevent from "../../../assets/img/editevent.svg";
import productcategory from "../../../assets/img/productcategory.svg";
import {
  actionAddProductCategoryMaster,
  actionClearAllProductCategory,
  actionDeleteProductCategoryMaster,
  actionEditProductCategoryMaster,
  actionGetProductCategoryMaster,
  actionGetProductCategoryMasterWithPagination,
  actionGetSingleProductCategoryMaster,
} from "../../../store/services/productMasterService";
import MasterSearchbar from "../../common/MasterSearchbar";
import SortIcon from "../../common/SortIcon";
import { isValidSearchString } from "../../helper/common-function";
import PageTitleForMasterData from "../../common/PageTitleForMasterData";
import { handleActionWithPermission } from "../../../store/slices/authSlice";
import usePermission from "../../../hooks/use-permission";
import SkeletonTable from "../../common/SkeletonTable";
import CommonPagination from "../../common/CommonPagination";
import MasterCommonTable from "../../common/MasterCommonTable";

const ProductCategory = ({
  searchValue,
  icons = true,
  isDeactivate,
  isAdvanceFilter = false,
  items,
  isAllowDownload = true,
  setSelectItem,
}) => {
  const [editableForm] = Form.useForm();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    productCategoryData,
    getSingleProductCategoryData,
    getSingleEventTypeLoader,
    getProductCategoryLoader,
    addProductCategoryLoader,
    deleteProductCategoryLoader,
    updateProductCategoryLoader,
    getProductCategoryWithPaginationLoader,
    productCategoryDataWithPagination,
    allProductCategoryCounts,
  } = useSelector((state) => state.productMaster);
  const offsetRef = useRef(0);
  const [search, setSearch] = useState("");
  const [edited, setEdited] = useState(false);
  const [eventTypeData, setEventTypeData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [exportAll, setExportAll] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [columnKey, setColumnKey] = useState(null);
  const [lastSearch, setLastSearch] = useState('');
  const [pageSize, setPageSize] = useState(20);
  const { hasPermission } = usePermission();

  const isEditing = (record) =>
    record.product_category_master_id === editingKey;

  useEffect(() => {
    dispatch(actionGetProductCategoryMasterWithPagination({
      offset: offsetRef.current,
      limit: pageSize,
      search: search
    }));
  }, [dispatch]);

  const handleAddProductCategory = (values) => {
    dispatch(actionAddProductCategoryMaster({ values, handleCancel }));
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
    editableForm.resetFields();
    setEditingKey("");
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      inputType === "number" ? (
        <Input />
      ) : dataIndex === "status" ? (
        <Select
          className="selected-input"
          allowClear
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={[
            {
              value: "Active",
              label: "Active",
            },
            {
              value: "Archive",
              label: "Archive",
            },
            {
              value: "Inactive",
              label: "Inactive",
            },
          ]}
          // defaultValue="Active"
          style={{ background: "white" }}
        />
      ) : (
        <Input variant="borderless" />
      );

    return (
      <td {...restProps} className="input-height">
        {editing ? (
          <Form.Item
            className="mb-0 editable-order"
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `${title} is required.`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          <Typography.Paragraph
            style={{ marginBottom: 0 }}
            ellipsis={{ rows: 1, expandable: false, tooltip: true }}
            className="px-[11px]"
          >
            {children}
          </Typography.Paragraph>
        )}
      </td>
    );
  };

  const edit = (record) => {
    dispatch(
      actionGetSingleProductCategoryMaster(record.product_category_master_id)
    );
    setEdited(true);
  };

  useEffect(() => {
    if (getSingleProductCategoryData && edited) {
      editableForm.setFieldsValue({
        category_name: getSingleProductCategoryData.category_name,
        status: getSingleProductCategoryData.status,
      });
      setEditingKey(getSingleProductCategoryData.product_category_master_id);
    }
  }, [getSingleProductCategoryData]);

  const save = async (id) => {
    try {
      const row = await editableForm.validateFields();
      const { category_name, status } = row;
      const req = { category_name, status };
      dispatch(
        actionEditProductCategoryMaster({ id, req, setEditingKey, setEdited })
      );
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "SR No.",
      dataIndex: "srno",
      key: "srno",
      width: 70,
      fixed: "left",
      editable: false,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Category Name",
      dataIndex: "category_name",
      key: "category_name",
      width: 350,
      editable: true,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Action",
      key: "action",
      width: 100,
      fixed: "right",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <div className="flex items-center ">
            {editable ? (
              <div className="flex items-center">
                {updateProductCategoryLoader[
                  record.product_category_master_id
                ] ? (
                  <LoadingOutlined />
                ) : (
                  <Typography.Link
                    onClick={() => save(record.product_category_master_id)}
                  >
                    <img src={editedevent} alt="" />
                  </Typography.Link>
                )}
              </div>
            ) : (
              <div className="flex items-center gap-x-2 justify-center">
                <Typography.Link
                  disabled={editingKey !== ""}
                  onClick={() => {
                    dispatch(
                      handleActionWithPermission(
                        "product-category-master",
                        "update",
                        () => edit(record)
                      )
                    );
                  }}
                >
                  <img src={editevent} alt="" />
                </Typography.Link>
              </div>
            )}
            <div className="ml-5">
              {hasPermission("product-category-master", "delete") ? (
                <Popconfirm
                  okButtonProps={{
                    loading:
                      deleteProductCategoryLoader[
                      record.product_category_master_id
                      ],
                  }}
                  description="Are you sure you want to delete?"
                  onConfirm={() =>
                    dispatch(
                      actionDeleteProductCategoryMaster({
                        id: record.product_category_master_id,
                        setEditingKey,
                      })
                    )
                  }
                >
                  <img src={deletevent} alt="" className="cursor-pointer" />
                </Popconfirm>
              ) : (
                <img
                  src={deletevent}
                  alt=""
                  className="cursor-pointer"
                  onClick={() => {
                    dispatch(
                      handleActionWithPermission(
                        "product-category-master",
                        "delete"
                      )
                    );
                  }}
                />
              )}
            </div>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      sortIcon: () => col.sorter ? getProductCategoryWithPaginationLoader && col.key === columnKey ? <LoadingOutlined /> : <SortIcon /> : null,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === "category_name" || col.dataIndex === "status"
            ? "text"
            : "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  useEffect(() => {
    const trimmedSearch = search?.trim();
    if (trimmedSearch !== "" && trimmedSearch !== lastSearch) {
      const timer = setTimeout(() => {
        dispatch(
          actionGetProductCategoryMasterWithPagination({
            offset: offsetRef.current,
            limit: pageSize,
            search: trimmedSearch
          })
        );
        setLastSearch(trimmedSearch);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }

  }, [search, lastSearch]);

  const handleSearch = (e) => {
    if (isValidSearchString(e.target.value)) {
      offsetRef.current = 0;
      setSearch(e.target.value);
      if (e.target.value === "") {
        dispatch(
          actionGetProductCategoryMasterWithPagination({
            offset: offsetRef.current,
            limit: pageSize,
            search: e.target.value,
          })
        );
      }
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search && isValidSearchString(e.target.value)) {
      offsetRef.current = 0;
      dispatch(
        actionGetProductCategoryMasterWithPagination({
          offset: offsetRef.current,
          limit: pageSize,
          search: e.target.value
        })
      );
    }
  };

  const handleSortChange = (pagination, filters, sorter) => {
    setColumnKey(sorter.columnKey)
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
            ? "ASC"
            : "",
    };

    dispatch(
      actionGetProductCategoryMasterWithPagination({
        offset: offsetRef.current,
        limit: pageSize,
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
  };

  const getData = async (data) => {
    const newData =
      (await data) &&
      data.length > 0 &&
      data.map((d) => ({
        "Category Name": d.category_name ? d.category_name : "-",
        // Status: d.status ? d.status : "-",
      }));
    return newData;
  };

  const exportAllToExcel = async () => {
    const data = await getData(productCategoryData);
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Material Categories");
    XLSX.writeFile(workbook, "material-categories.xlsx");
  };

  const exportToExcel = async (items, type = "") => {
    if (!items || (items && items.length === 0)) {
      return;
    }
    const data = await getData(items);
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Material Categories");
    XLSX.writeFile(workbook, "material-categories.xlsx");
    type &&
      type === "all" &&
      dispatch(actionClearAllProductCategory({ setExportAll }));
  };

  const onClickDownloadMenu = ({ key }) => {
    if (key === "1") {
      exportToExcel(productCategoryDataWithPagination);
    } else {
      dispatch(actionGetProductCategoryMaster());
      setExportAll(true);
    }
  };

  useEffect(() => {
    if (exportAll && productCategoryData && productCategoryData.length > 0) {
      exportToExcel(productCategoryData, "all");
    }
  }, [productCategoryData]);

  return (
    <div className="event-input">
      <PageTitleForMasterData
        title="Material Categories"
        buttonText="Add Material Category"
        onButtonClick={showModal}
        resource="product-category-master"
      />
      <Modal
        centered
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={false}
        width={500}
        title=""
        open={isModalOpen}
      >
        <div className="flex">
          <img src={productcategory} alt="" />
          <h1 className="text-[#6883FD] text-[20px] ml-[8px]  mt-[5px] font-medium">
            Material Category
          </h1>
        </div>
        <Form
          form={form}
          initialValues={{
            status: "Active",
          }}
          onFinish={handleAddProductCategory}
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="category_name"
            label="Material Category Name"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please enter material category name!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Material Category Name"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-center mt-[40px]">
            <Form.Item className="mb-0">
              <Button
                onClick={handleCancel}
                type="button"
                className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addProductCategoryLoader}
                  type="primary"
                  className="root-btn min-h-[45px] min-w-[120px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>

      <MasterCommonTable
        moduleKey="material-categories"
        columns={mergedColumns}
        dataSource={productCategoryDataWithPagination}
        rowKey="product_category_master_id"
        isLoading={getProductCategoryWithPaginationLoader}
        EditableCell={EditableCell}
        onTableChange={handleSortChange}
        editableForm={editableForm}
        searchBarProps={{
          handleSearch,
          handleBlurAndPressEnter,
          importRoute: "/master-data/material-category-import",
          search,
          onClickDownloadMenu: onClickDownloadMenu,
          isPagination: true
        }}
        columnKey={columnKey}
      />

      <div className="flex justify-between mb-5">
        <p className="text-[15px] font-popinsMedium">
          Total : {allProductCategoryCounts ? allProductCategoryCounts : 0}
        </p>
        <CommonPagination
          current={offsetRef.current + 1}
          total={allProductCategoryCounts}
          onChange={(page, pageSize) => {
            offsetRef.current = page - 1;
            setPageSize(pageSize);
            dispatch(
              actionGetProductCategoryMasterWithPagination({
                offset: offsetRef.current,
                limit: pageSize,
                search: search,
              })
            );
          }}
          loading={getProductCategoryWithPaginationLoader}
          pageSize={pageSize}
        />
      </div>
    </div>
  );
};

export default ProductCategory;
