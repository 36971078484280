import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "sonner";
import {
  handleErrorMessage,
  startTimer,
} from "../../components/helper/common-function";
import setAuthToken from "../../config/setAuthToken";
import { BASE_URL } from "../../config/web-config";
import { handleGetResponse } from "./commonService";
import { appendCompanyUsers } from "../slices/usersSlice";
import { showHPINModal } from "../slices/authSlice";

// register
export const actionRegister = createAsyncThunk(
  "actionRegister",
  async (data, { rejectWithValue }) => {
    const { navigate, values, phoneNum, remember, startOTPTimer } = data;
    try {
      const res = await axios.post(`${BASE_URL}/auth/register`, values);
      const { status, data: responseData, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        startOTPTimer && startOTPTimer()
        navigate && navigate(`/otp`, { state: "fromRegister" });
        localStorage.setItem(
          "userData",
          JSON.stringify({ ...responseData, phoneNum, password: values.password, remember })
        );
        return responseData;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Login API
export const actionLogin = createAsyncThunk(
  "actionLogin",
  async (data, { rejectWithValue }) => {
    const { navigate, credentials, remember_me } = data;

    try {
      const res = await axios.post(`${BASE_URL}/auth/login`, credentials);
      const { status, data: responseData, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg);
        setAuthToken(responseData.accessToken);
        navigate && navigate("/");
        if (remember_me) {
          localStorage.setItem("crmHarborEmail", credentials.email);
          localStorage.setItem("crmHarborPassowrd", credentials.password);
        } else {
          localStorage.removeItem("crmHarborEmail");
          localStorage.removeItem("crmHarborPassowrd");
        }
        localStorage.setItem(
          "userData",
          JSON.stringify({ ...responseData, is_verified: 1 })
        );
        return responseData;
      } else {
        toast.error(customMsg);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      console.log(error,"error")
      if (error?.code === "ERR_NETWORK") {
        toast.error("Unable to reach the server. Please try again later.");
      } else {
        toast.error(error.message);
      }
      handleErrorMessage(error)
      // return rejectWithValue(error)
    }
  }
);

// Auth me
export const authMe = createAsyncThunk(
  "authMe",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${BASE_URL}/auth/me`);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        return data;
      } else if (parseInt(status) === 401) {
        localStorage.removeItem("crmWebToken");
        localStorage.removeItem("collapsed");
        setAuthToken(false);
        window.location.replace("/login");
        toast.error(customMsg, 5);
      } else {
        toast.error(customMsg, 5);
        // throw new Error(`API Error: ${customMsg}`);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      if (error.response.data.message === "Unauthorized") {
        localStorage.removeItem("crmWebToken");
        setAuthToken(false);
        window.location.replace("/login");
        toast.error(error?.message, 5);
      } else if (error?.code === "ERR_NETWORK") {
        toast.error(
          "Network error: Unable to reach the server. Please try again later."
        );
      }
      return rejectWithValue(error.message);
    }
  }
);

// Sign With Google
export const actionSignInWithGoogle = createAsyncThunk(
  "actionSignUpWithGoogle",
  async (data, { rejectWithValue }) => {
    const { navigate, values } = data;
    try {
      const res = await axios.post(`${BASE_URL}/sign-up-google`, values);
      const { status, data: responseData, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate(`/otp`);
        return responseData;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      toast.error(error.message, 5);
      return rejectWithValue(error.message);
    }
  }
);

// send otp on Email
export const actionVerifyOTPEmail = createAsyncThunk(
  "actionVerifyOTPEmail",
  async ({ req, setVerifed, setEmailOtp }, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${BASE_URL}/auth/verify-email`, req);
      const { status, data: responseData, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        localStorage.setItem("userData", JSON.stringify(responseData));
        setVerifed((prev) => ({
          ...prev,
          is_email_verified: 1,
        }));
        setEmailOtp && setEmailOtp("");
        return responseData;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      toast.error(error.message, 5);
      rejectWithValue(error.message);
    }
  }
);

// send otp on Mobile
export const actionVerifyOTPMobile = createAsyncThunk(
  "actionVerifyOTPMobile",
  async ({ req, setVerifed, navigate, setPhoneOtp }, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${BASE_URL}/auth/verify-phone`, req);
      const { status, data: responseData, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        setVerifed((prev) => ({
          ...prev,
          is_mobile_verified: 1,
        }));
        setPhoneOtp && setPhoneOtp("");
        navigate
          ? localStorage.setItem(
            "userData",
            JSON.stringify({ ...responseData, is_verified: 1 })
          )
          : localStorage.setItem("userData", JSON.stringify(responseData));
        navigate && navigate("/");
        return responseData;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      toast.error(error.message, 5);
      rejectWithValue(error.message);
    }
  }
);

//  resend otp Email
// remove this
export const actionReSendOTPEmail = createAsyncThunk(
  "actionReSendOTPEmail",
  async (data, { rejectWithValue }) => {
    const { user_id, setTimer } = data;
    try {
      const res = await axios.post(`${BASE_URL}/auth/resend-otp-email`, {
        user_id: user_id,
      });
      const { status, data: responseData, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        startTimer(setTimer && setTimer);
        return responseData;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      toast.error(error.message, 5);
      rejectWithValue(error.message);
    }
  }
);

//  resend otp Mobile
export const actionReSendOTPMobile = createAsyncThunk(
  "actionReSendOTPMobile",
  async ({ user_id, setPhoneTimer }, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${BASE_URL}/auth/resend-otp-phone`, {
        user_id: user_id,
      });
      const { status, data: responseData, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        startTimer(setPhoneTimer && setPhoneTimer);
        return responseData;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      toast.error(error.message, 5);
      rejectWithValue(error.message);
    }
  }
);

//  Continue  Login
export const actionContinueToLogin = createAsyncThunk(
  "actionContinueToLogin",
  async ({ navigate, req }, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${BASE_URL}/auth/continue-to-login`, req);
      const { status, data: responseData, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        localStorage.setItem(
          "userData",
          JSON.stringify({ ...responseData, is_verified: 1 })
        );
        navigate && navigate("/");
        setAuthToken(responseData.accessToken);
        return responseData;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      toast.error(error.message, 5);
      rejectWithValue(error.message);
    }
  }
);

//  Login With OTP
export const actionLoginOTP = createAsyncThunk(
  "actionLoginOTP",
  async ({ navigate, req, startTimer, phone }, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${BASE_URL}/auth/login-with-otp`, req);
      const { status, data: responseData, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        localStorage.setItem(
          "userData",
          JSON.stringify({ ...responseData, phoneNum: phone })
        );

        startTimer && startTimer();
        navigate &&
          navigate("/otp", {
            state: {
              type: req.type,
              sendTo: req.send_to,
              iso: req.country_code,
              phoneNum: phone,
            },
          });

        return responseData;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      if (error.response.data.message) {
        toast.error(error.response.data.message, 5);
        rejectWithValue(error.response.data.message);
      } else {
        toast.error(error.message, 5);
        rejectWithValue(error.message);
      }
    }
  }
);

// send otp on Email
export const actionVerifyOtpLogin = createAsyncThunk(
  "actionVerifyOtpLogin",
  async ({ req, navigate, setPhoneOtp }, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/auth/verify-otp-and-login`,
        req
      );
      const { status, data: responseData, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        localStorage.setItem(
          "userData",
          JSON.stringify({ ...responseData, is_verified: 1 })
        );
        setPhoneOtp && setPhoneOtp("");
        setAuthToken(responseData.accessToken);
        navigate && navigate("/");
        return responseData;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      toast.error(error.message, 5);
      rejectWithValue(error.message);
    }
  }
);

//forgot password
export const actionForgotPassword = createAsyncThunk(
  "actionForgotPassword",
  async (data, { rejectWithValue }) => {
    const { values, navigate } = data;
    try {
      const response = await axios.post(
        `${BASE_URL}/auth/forgot-password`,
        values
      );
      const { status, message: customMsg } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate("/login");
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      toast.error(error?.message, 5);
      return rejectWithValue(error?.message);
    }
  }
);

//reset password
export const actionResetPassword = createAsyncThunk(
  "actionResetPassword",
  async (data, { rejectWithValue }) => {
    const { request, navigate } = data;
    try {
      const response = await axios.post(
        `${BASE_URL}/auth/reset-password`,
        request
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate("/login");
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      toast.error(error?.message, 5);
      return rejectWithValue(error?.message);
    }
  }
);

// Change password
export const actionChangePassword = createAsyncThunk(
  "actionChangePassword",
  async (data, { rejectWithValue }) => {
    const { values, form } = data;
    try {
      const response = await axios.post(
        `${BASE_URL}/auth/change-password`,
        values
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form && form.resetFields();
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      toast.error(error?.message, 5);
      return rejectWithValue(error?.message);
    }
  }
);

//change-mobileno
export const actionChangeMobile = createAsyncThunk(
  "actionChangeMobile",
  async (data, { rejectWithValue }) => {
    const { request, navigate, phone } = data;
    try {
      const response = await axios.post(
        `${BASE_URL}/auth/change-phone-number`,
        request
      );
      const { status, message: customMsg, data: responseData } = response.data;
      localStorage.setItem(
        "userData",
        JSON.stringify({ ...responseData, phoneNum: phone })
      );
      if (parseInt(status) == 200) {
        toast.success(customMsg, 5);
        navigate(-1, {
          state: {
            type: "PHONE",
            phoneNum: phone,
            page: true,
          },
        });
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      toast.error(error?.message, 5);
      return rejectWithValue(error?.message);
    }
  }
);

// Check User exist with google login if not then navigate create-account else  loggedin
export const actionContinueWithGoogle = createAsyncThunk(
  "actionContinueWithGoogle",
  async (
    { req, navigate, setCustomLoader, user, isIdToken },
    { rejectWithValue }
  ) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/auth/continue-with-google`,
        req
      );
      const { status, data: responseData, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        localStorage.setItem(
          "userData",
          JSON.stringify({ ...responseData, is_verified: 1 })
        );
        setAuthToken(responseData.accessToken);
        sessionStorage.removeItem("customLoader");
        navigate && navigate("/");
        setCustomLoader && setCustomLoader(false);
        return responseData;
      } else if (parseInt(status) === 404) {
        const req = {
          user_fullname: user?.name,
          user_email: user?.email,
          user_first_Name: user?.given_name,
          user_last_Name: user?.family_name,
          user_token: token,
          user_profile_pic: user?.picture,
        };
        sessionStorage.removeItem("customLoader");
        navigate &&
          navigate("/create-account", {
            state: req,
          });
        setCustomLoader && setCustomLoader(false);
      } else {
        toast.error(customMsg, 5);
        setCustomLoader && setCustomLoader(false);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      toast.error(error.message, 5);
      setCustomLoader && setCustomLoader(false);
      return rejectWithValue(error.message);
    }
  }
);

// Register Company
export const actionRegisterCompany = createAsyncThunk(
  "actionRegisterCompany",
  async (data, { dispatch, rejectWithValue }) => {
    const { request, navigate } = data;
    try {
      const response = await axios.post(
        `${BASE_URL}/auth/register-company`,
        request
      );

      const { status, message: customMsg } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate("/business-hours");
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      toast.error(error?.message, 5);
      return rejectWithValue(error?.message);
    }
  }
);

//actionAddBusinessHours
export const actionAddBusinessHours = createAsyncThunk(
  "actionAddBusinessHours",
  async (data, { dispatch, rejectWithValue }) => {
    const { req, navigate, from } = data;
    try {
      const response = await axios.post(`${BASE_URL}/company-hours`, req);
      const { status, message: customMsg } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        if (from !== "businessHours") {
          await dispatch(authMe());
          navigate && navigate("/");
        }
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      toast.error(error?.message, 5);
      return rejectWithValue(error?.message);
    }
  }
);

// Logout User
export const actionLogout = createAsyncThunk(
  "actionLogout",
  async ({ navigate, setVisible }) => {
    setVisible && setVisible(false);
    localStorage.removeItem("crmWebToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("crm_user_permission_data");
    setAuthToken(false);
    navigate("/login");
  }
);

//create-account
export const actionCreateAccount = createAsyncThunk(
  "actionCreateAccount",
  async (data, { rejectWithValue }) => {
    const { navigate, values, phone, from, iso = "" } = data;
    try {
      const res = await axios.post(`${BASE_URL}/auth/create-account`, values);
      const { status, data: responseData, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate &&
          navigate(`/otp`, {
            state: {
              type: "PHONE",
              sendTo: responseData.phone,
              iso: iso,
              phoneNum: phone,
              page: true,
              from,
            },
          });
        localStorage.setItem(
          "userData",
          JSON.stringify({ ...responseData, phoneNum: phone })
        );
        return responseData;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      if (error.response.data.message) {
        toast.error(error.response.data.message, 5);
        return rejectWithValue(error.response.data.message);
      } else {
        toast.error(error.message, 5);
        return rejectWithValue(error.message);
      }
    }
  }
);

export const actionGetTimezones = createAsyncThunk(
  "actionGetTimezones",
  async () => {
    return handleGetResponse("timezones");
  }
);

// invite user from begin page
export const actionInviteUser = createAsyncThunk(
  "actionInviteUser",
  async (data, { rejectWithValue, dispatch }) => {
    const { req, setIsModalOpen, setResetDataAfterAPI } = data;
    try {
      const response = await axios.post(
        `${BASE_URL}/company-users/send-invitation`,
        { data: req }
      );
      const { status, message: customMsg, data: responseData } = response.data;
      if (parseInt(status) === 200) {
        setResetDataAfterAPI && setResetDataAfterAPI(true);
        if (responseData.errors && responseData.errors.length > 0) {
          toast.error(
            `${responseData.errors.length} invitations failed to send. please check`,
            5
          );
        }
        if (!responseData.errors || responseData.errors.length === 0) {

          if (responseData.successes && responseData.successes.length > 0) {
            responseData.successes.forEach((success) => {
              dispatch(appendCompanyUsers(success.data));
            });

            toast.success(
              `${responseData &&
              responseData.successes &&
              responseData.successes.length
              } invitations sent successfully. please check`,
              5
            );

          }

          setIsModalOpen && setIsModalOpen(false);
        }
        return responseData;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      toast.error(error?.message, 5);
      return rejectWithValue(error?.message);
    }
  }
);

export const actionClearInviteMessages = createAsyncThunk(
  "actionClearInviteMessages",
  async () => {
    return true;
  }
);

//check auth status before accept invitation
export const actionCheckAuthStatus = createAsyncThunk(
  "actionCheckAuthStatus",
  async (data, { rejectWithValue }) => {
    const { token, navigate, senderEmail } = data;
    try {
      const response = await axios.get(
        `${BASE_URL}/auth/status?token=${token}`
      );
      const { status, message: customMsg } = response.data;
      if (parseInt(status) === 200) {
        return response.data;
      } else if (parseInt(status) === 404) {
        const DATA = "USER_NOT_FOUND";
        return DATA;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      toast.error(error?.message, 5);
      return rejectWithValue(error?.message);
    }
  }
);

//accept invitation
export const actionAcceptInvitation = createAsyncThunk(
  "actionAcceptInvitation",
  async (data, { rejectWithValue }) => {
    const { request, navigate, senderEmail } = data;
    try {
      const response = await axios.post(`${BASE_URL}/auth/accept-invitation`, {
        token: request,
      });
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        localStorage.setItem("crmWebToken", data.accessToken);
        navigate && navigate("/");
      } else if (parseInt(status) === 404) {
        navigate &&
          navigate("/accept-invitation-create-account", {
            state: { user_token: request, senderEmail },
          });
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      toast.error(error?.message, 5);
      return rejectWithValue(error?.message);
    }
  }
);

// reject invitation
export const actionRejectInvitation = createAsyncThunk(
  "actionRejectInvitation",
  async (data, { rejectWithValue }) => {
    const { request } = data;
    try {
      const response = await axios.post(`${BASE_URL}/auth/reject-invitation`, {
        token: request,
      });
      const { status, message: customMsg, responseData } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      ``;
      toast.error(error?.message, 5);
      return rejectWithValue(error?.message);
    }
  }
);


export const actionUpdateProfile = createAsyncThunk("actionUpdateProfile", async ({ setType, req }, { dispatch, rejectWithValue }) => {
  try {
    const response = await axios.patch(`${BASE_URL}/auth/update-me`, req);
    const { status, message: customMsg, data } = response.data;
    if (parseInt(status) === 200) {
      toast.success(customMsg, 5);
      setType && setType("VIEW");
      dispatch(authMe())
      return data;
    } else {
      toast.error(customMsg, 5);
      return rejectWithValue(customMsg);
    }
  } catch (error) {
    toast.error(error?.message, 5);
    return rejectWithValue(error?.message);
  }
})


export const actionGetCompanyAccessKeys = createAsyncThunk("actionGetCompanyAccessKeys", () => {
  return handleGetResponse(`company/generate-api-key`)
})

// send hpin otp on email
export const actionSendOTPforHPIN = createAsyncThunk(
  "actionSendOTPforHPIN",
  async (data, { rejectWithValue }) => {
    const { setTimer } = data;
    try {
      const res = await axios.post(`${BASE_URL}/company-users/send-otp-hpin`, {});
      const { status, data: responseData, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        // Start the timer logic
        if (setTimer) {
          setTimer(60);
          const interval = setInterval(() => {
            setTimer((prev) => {
              if (prev <= 1) {
                clearInterval(interval);
                return 0;
              }
              return prev - 1;
            });
          }, 1000);

          return { intervalId: interval, responseData };
        }
        return responseData;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      toast.error(error.message, 5);
      rejectWithValue(error.message);
    }
  }
);

// set hpin 
export const actionSetHPIN = createAsyncThunk(
  "actionSetHPIN",
  async ({ req, handleCancel }, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${BASE_URL}/company-users/set-hpin`, req);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// set hpin 
export const actionVerifyHPIN = createAsyncThunk(
  "actionVerifyHPIN",
  async ({ req, handleCancel, customParam }, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.post(`${BASE_URL}/company-users/verify-hpin`, req);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        if (customParam) {
          dispatch(showHPINModal());
        }
        return data;
      } else {
        toast.error(customMsg, 5);
        rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);