import React from "react";
import { Pagination } from "antd"; // Assuming you're using Ant Design

const CommonPagination = ({
    current,
    total,
    onChange,
    showSizeChanger = true,
    hideOnSinglePage = true,
    loading = false,
    pageSize,
    ...props
}) => {
    return (
        <Pagination
        size="default"
            {...props}
            hideOnSinglePage={hideOnSinglePage}
            responsive
            current={current}
            className="flex items-center custom-pagination"
            showSizeChanger={showSizeChanger}
            defaultPageSize={pageSize}
            total={total}
            onChange={onChange}
            rowClassName={(record, index) => {
                return loading ? "loading-row" : "";
            }}
        />
    );
};

export default CommonPagination;
