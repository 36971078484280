
import React, { useState, useRef, useEffect } from 'react'
import { Modal, Form, Input, Button, message } from 'antd'


const EmailPhoneChangeModal = ({
    contactType = "email",
    setContactType = () => { },
    isModalOpen = false,
    setIsModalOpen = () => { }
}) => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [form] = Form.useForm()
    const [showOtp, setShowOtp] = useState(false)
    const otpInputs = useRef([]);

    useEffect(() => {
        if (showOtp) {
            otpInputs.current[0]?.focus()
        }
    }, [showOtp]);

    const showModal = (type) => {
        setIsModalVisible(true)
        setContactType(type)
        form.resetFields()
        setShowOtp(false)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
        form.resetFields()
        setShowOtp(false)
    }

    const onFinish = (values) => {
        if (!showOtp) {
            setShowOtp(true)
            message.success(`OTP sent to your ${contactType}!`)
        } else {
            message.success('OTP verified successfully!')
            setIsModalVisible(false)
            setShowOtp(false)
        }
    }

    const handleOtpChange = (index, value) => {
        if (value.length === 1 && index < 5) {
            otpInputs.current[index + 1]?.focus()
        }
    }

    const handleOtpKeyDown = (index, e) => {
        if (e.key === 'Backspace' && index > 0 && !e.currentTarget.value) {
            otpInputs.current[index - 1]?.focus()
        }
    }

    return (
        <div className="space-y-4">


            <div className="modal-title text-xl font-popinsMedium tracking-tightg- mb-4">
                {showOtp ? `Enter OTP for ${contactType} verification` : `Enter ${contactType === 'email' ? 'Email' : 'Phone Number'}`}
            </div>

            <Form
                form={form}
                name="contact-otp-form"
                onFinish={onFinish}
                layout="vertical"
                className="mt-4"
            >
                {!showOtp && (
                    <Form.Item
                        name="contact"

                    >
                        <Input className='contact-input'
                            placeholder={contactType === 'email' ? "Enter your email" : "Enter your phone number"}
                        />
                    </Form.Item>
                )}
                {showOtp && (
                    <Form.Item
                        name="otp"
                        rules={[
                            { required: true, message: 'Please input the OTP!' },
                            { len: 6, message: 'OTP must be exactly 6 digits!' }
                        ]}
                    >
                        <div className="space-y-4">
                            <p className="text-sm text-gray-600">
                                We've sent a 6-digit code to your {contactType}. Please enter it below.
                            </p>
                            {/*  <div className="flex justify-between space-x-2">
                                {[...Array(6)].map((_, index) => (
                                    <Input
                                        key={index}
                                        className="w-12 h-12 text-center text-xl font-semibold border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                        maxLength={1}
                                        onChange={(e) => handleOtpChange(index, e.target.value)}
                                        onKeyDown={(e) => handleOtpKeyDown(index, e)}
                                        ref={(el) => (otpInputs.current[index] = el)}
                                    />
                                ))}
                            </div> */}

                            <Input.OTP className='verify-otp-input' onChange={(text) => {
                                console.log('onChange:', text);
                            }} onInput={(value) => {
                                console.log('onInput:', value);
                            }} length={6} />
                        </div>
                    </Form.Item>
                )}
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className={`w-full  text-base  !h-[40px] transition-colors ${contactType === 'email'
                            ? 'bg-blue-500 hover:bg-blue-600 focus:ring-2 focus:ring-blue-300'
                            : 'bg-green-500 hover:bg-green-600 focus:ring-2 focus:ring-green-300'
                            }`}
                    >
                        {showOtp ? "Verify OTP" : "Send OTP"}
                    </Button>
                </Form.Item>
            </Form>

        </div>
    )
}

export default EmailPhoneChangeModal

