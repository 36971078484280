import { createSlice } from "@reduxjs/toolkit";
import {
  actionAddFeedBackMechanism,
  actionClearallFeedBackMechanism,
  actionDeleteFeedBackMechanism,
  actionGetAllFeedBackMechanism,
  actionGetFeedBackMechanism,
  actionGetSingleFeedBackMechanism,
  actionUpdateFeedBackMechanism,
  actionUpdateRequestForQuotationStatus,
  actionUpdateStatus,
} from "../services/feedBackMechanismService";

const feedBackMechanismSlice = createSlice({
  name: "requestForQuotation",
  initialState: {
    getFeedBackMechanismLoader: false,
    getSingleFeedBackMechanismLoader: false,
    addFeedBackMechanismLoader: false,
    deleteFeedBackMechanismLoader: false,
    updateFeedBackMechanismLoader: false,
    allFeedBackMechanismLoader: false,
    feedBackMechanismDataCount: 0,
    getSingleFeedBackMechanismData: "",
    allFeedBackMechanism: [],
    feedBackMechanismData: [],
    updateStatusLoader: false,
  },
  reducers: {},
  extraReducers: (builder) =>
    builder
      //get feedback mechanism
      .addCase(actionGetFeedBackMechanism.pending, (state) => {
        state.getFeedBackMechanismLoader = true;
      })
      .addCase(actionGetFeedBackMechanism.fulfilled, (state, action) => {
        state.getFeedBackMechanismLoader = false;
        state.feedBackMechanismData = action.payload.data;
        state.feedBackMechanismDataCount = action.payload.total_records;
      })

      .addCase(actionGetFeedBackMechanism.rejected, (state) => {
        state.getFeedBackMechanismLoader = false;
      })

      //get all feedback mechanism
      .addCase(actionGetAllFeedBackMechanism.pending, (state) => {
        state.allFeedBackMechanismLoader = true;
      })
      .addCase(actionGetAllFeedBackMechanism.fulfilled, (state, action) => {
        state.allFeedBackMechanismLoader = false;
        state.allFeedBackMechanism = action.payload.data;
      })
      .addCase(actionGetAllFeedBackMechanism.rejected, (state) => {
        state.allFeedBackMechanismLoader = false;
      })

      //single feedback
      // get Single feedback mechanism
      .addCase(actionGetSingleFeedBackMechanism.pending, (state) => {
        state.getSingleFeedBackMechanismLoader = true;
      })
      .addCase(actionGetSingleFeedBackMechanism.fulfilled, (state, action) => {
        state.getSingleFeedBackMechanismLoader = false;
        state.getSingleFeedBackMechanismData = action.payload.data;
      })
      .addCase(actionGetSingleFeedBackMechanism.rejected, (state) => {
        state.getSingleFeedBackMechanismLoader = false;
      })

      //add feedback mechanism
      .addCase(actionAddFeedBackMechanism.pending, (state) => {
        state.addFeedBackMechanismLoader = true;
      })
      .addCase(actionAddFeedBackMechanism.fulfilled, (state, action) => {
        state.addFeedBackMechanismLoader = false;
        if (action.payload) {
          if (
            !state.feedBackMechanismData ||
            !Array.isArray(state.feedBackMechanismData)
          ) {
            state.feedBackMechanismData = [action.payload];
          } else {
            state.feedBackMechanismData.unshift(action.payload);
          }
          state.feedBackMechanismDataCount =
            state.feedBackMechanismDataCount + 1;
        }
      })

      .addCase(actionAddFeedBackMechanism.rejected, (state) => {
        state.addFeedBackMechanismLoader = false;
      })

      //delete feedback mechanism
      .addCase(actionDeleteFeedBackMechanism.pending, (state) => {
        state.deleteFeedBackMechanismLoader = true;
      })

      .addCase(actionDeleteFeedBackMechanism.fulfilled, (state, action) => {
        state.deleteFeedBackMechanismLoader = false;
        state.feedBackMechanismData = state.feedBackMechanismData.filter(
          (data) => data.crm_users_feedback_id !== action.payload
        );
        state.feedBackMechanismDataCount = state.feedBackMechanismDataCount - 1;
      })

      .addCase(actionDeleteFeedBackMechanism.rejected, (state) => {
        state.deleteFeedBackMechanismLoader = false;
      })

      //update feedback mechanism
      .addCase(actionUpdateFeedBackMechanism.pending, (state) => {
        state.updateFeedBackMechanismLoader = true;
      })
      .addCase(actionUpdateFeedBackMechanism.fulfilled, (state, action) => {
        state.updateFeedBackMechanismLoader = false;
        state.feedBackMechanismData = state.feedBackMechanismData.map((item) =>
          item.crm_users_feedback_id === action.payload.crm_users_feedback_id
            ? { ...item, ...action.payload }
            : item
        );
      })
      .addCase(actionUpdateFeedBackMechanism.rejected, (state) => {
        state.updateFeedBackMechanismLoader = false;
      })

      .addCase(actionUpdateStatus.pending, (state) => {
        state.updateStatusLoader = true;
      })
      .addCase(actionUpdateStatus.fulfilled, (state, action) => {
        state.updateStatusLoader = false;
        state.feedBackMechanismData = state.feedBackMechanismData.map((status) =>
          status.crm_users_feedback_id === action.payload.crm_users_feedback_id
            ? { ...status, ...action.payload }
            : status
        );
      })
      .addCase(actionUpdateStatus.rejected, (state) => {
        state.updateStatusLoader = false;
      }),
});

export default feedBackMechanismSlice.reducer;
