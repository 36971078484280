
import { Button, Image } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import plusIcon from "../../assets/img/add-button.svg";
import usePermission from "../../hooks/use-permission";
import { showModal } from "../../store/slices/authSlice";
import { getPermissionMessage } from "../helper/common-function";

const AddButton = ({ buttonText, buttonLink, type = "link", resource, action = "create", onClick, ...props }) => {
  const dispatch = useDispatch();
  const { hasPermission } = usePermission();

  const renderButton = () => {
    const buttonProps = {
      ...props,
      className: "root-btn root-add-btn text-white hover:text-white py-5 flex-center radius ",
      icon: <Image src={plusIcon} height={20} width={20} alt="Plus Icon" preview={false} />,
      children: buttonText,
    };

    if (hasPermission(resource, action)) {
      if (type === "btn") {
        return <Button {...buttonProps} onClick={onClick} />;
      } else {
        return (
          <Link to={buttonLink}>
            <Button {...buttonProps} />
          </Link>
        );
      }
    } else {
      return <Button {...buttonProps} onClick={() => dispatch(showModal(getPermissionMessage(resource, action)))} />;
    }

  };

  return renderButton();
};

export default AddButton;
