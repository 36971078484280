import {
  BellOutlined,
  EditOutlined,
  LockOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Dropdown,
  Input,
  Layout,
  Menu,
  Modal,
  Popover,
  Result,
  Space,
  Tooltip
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { GoDot } from "react-icons/go";
import leftLogo from "../../assets/img/left-logo.svg";
import mainLogo from "../../assets/img/logo.svg";

import beatActive from "../../assets/img/sidebar/beat-active.svg";
import beat from "../../assets/img/sidebar/beat.svg";
import crm from "../../assets/img/sidebar/crm.svg";
import dashboardActive from "../../assets/img/sidebar/dashboard-active.svg";
import dashboard from "../../assets/img/sidebar/dashboard.svg";
import masterDataActive from "../../assets/img/sidebar/master-data-active.svg";
import masterData from "../../assets/img/sidebar/master-data.svg";
import settingActive from "../../assets/img/sidebar/setting-active.svg";
import setting from "../../assets/img/sidebar/setting.svg";
import sideBarActiveMechanism from "../../assets/img/sidebar/sidebar-feedback-active.svg";
import sideBarMechanism from "../../assets/img/sidebar/sidebar-feedback.svg";
import taskActive from "../../assets/img/sidebar/task-active.svg";
import task from "../../assets/img/sidebar/task.svg";

import {
  matchPath,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { actionLogout } from "../../store/services/authService";
import { clearEventId } from "../../store/services/eventService";
import { actionGetNotifications } from "../../store/services/notificationService";
import MobileDownload from '../common/MobileDownload';
import NotificationList from "../dashboard/NotificationList";
import {
  getEventId,
  getFullName
} from "../helper/common-function";
import NotificationListener from "./NotificationListener";
import Wrapper from "./Wrapper";
import HpinModal from "./HpinModal";
import VerifyHpinModal from "./VerifyHpinModal";

const { Header, Sider, Content } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const Root = () => {
  const [collapsed, setCollapsed] = useState(() => {
    const savedState = localStorage.getItem("collapsed");
    return savedState ? JSON.parse(savedState) : false;
  });

  const [visible, setVisible] = useState(false);
  const [key, setKey] = useState("1");
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [offset, setOffset] = useState(0);
  const [unreadCount, setUnreadCount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0)
  const [notifications, setNotifications] = useState([]);
  const history = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const { user, userLoader, currentCompanyUser } = useSelector(
    (state) => state.auth
  );

  const currentPath = history.pathname;

  useMemo(() => {
    const routeKeyMapping = {
      "": "1",
      inventory: "2",
      "purchase-orders": "3",
      "sales-orders": "11",
      "sales-orders-import": "11",
      "sales-invoices": "11",
      "view-sales-invoice": "11",
      "add-sales-order": "11",
      "view-sales-orders": "11",
      "update-sales-order": "11",
      "sales-invoices": "11",
      task: "5",
      delivery: "6",
      "expense-voucher": "11",
      "add-expense-voucher": "11",
      "view-expense-voucher": "11",
      "update-expense-voucher": "11",
      "credit-notes": "7",
      hr: "10",
      "beat-planning": "11",
      "emp-tracking": "11",
      "master-data": "12",
      "event-masters": "12",
      "material-masters": "12",
      "view-business-partner": "14",
      "business-partners": "14",
      "create-business-partner": "14",
      "business-partners-import": "14",
      "update-business-partner": "14",
      "general-masters": "12",
      setting: "13",
      "custom-fields": "13",
      "sfa-settings": "13",
      "attendance-logs": "13",
      "company-settings": "13",
      "update-user": "13",
      "update-role-profile": "13",
      "role-profile": "13",
      organogram: "13",
      "add-role-profile": "13",
      "role-hierarchy": "13",
      "role-hierarchy": "13",
      users: "13",
      "personal-setting": "13",
      "create-user": "13",
      "users-import": "13",
      "module-permissions": "13",
      "event-overview": "14",
      exhibitors: "14",
      events: "14",
      "lead-management": "14",
      "create-lead": "14",
      "create-request-for-quotation": "14",
      "update-request-for-quotation": "14",
      "request-for-quotation": "14",
      speakers: "14",
      "create-event": "14",
      attendees: "14",
      sponsor: "14",
      speakers: "14",
      "event-inventory": "14",
      sessions: "14",
      attendees: "14",
      "opportunity-management": "14",
      "create-opportunity": "14",
      "update-opportunity": "14",
      contacts: "14",
      "create-contact": "14",
      "update-contact": "14",
      "collection-management": "11",
      "add-collection-management": "11",
      "update-collection-management": "11",
      "feedback-mechanism": "15",
      "create-feedback-mechanism": "15",
      "view-feedback-mechanism": "15",
      "update-feedback-mechanism": "15",
      "quick-reminder": "11",
    };
    const pathParts = currentPath && currentPath.split("/");
    const pathKey = routeKeyMapping[pathParts[1]] || "1";
    setKey(pathKey);
  }, [currentPath]);

  useEffect(() => {
    if (currentCompanyUser && currentCompanyUser.id) {
      appendData(true);
    }
  }, [currentCompanyUser]);



  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    // Cleanup on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // role permission
  const hasPermission = (resource, action) => {
    if (
      !currentCompanyUser ||
      !currentCompanyUser.roleprofile ||
      !currentCompanyUser.roleprofile.role_profile ||
      !currentCompanyUser.roleprofile.role_profile.permission_data
    ) {
      return false;
    }
    return currentCompanyUser.roleprofile.role_profile.permission_data.some(
      (p) =>
        p.permissions.resources === resource && p.permissions.actions === action
    );
  };

  // menu items
  const itemData = [
    {
      label: "Dashboard",
      icon: key === "1" ? dashboardActive : dashboard,
      key: "1",
      link: "/",
      disabled: true,
      className: "dashboard",
    },

    {
      label: "CRM",
      icon: key === "14" ? crm : crm,
      key: "14",
      visible:
        hasPermission("business-partner", "read") ||
        hasPermission("contact", "read") ||
        hasPermission("events", "read") ||
        hasPermission("lead", "read") ||
        hasPermission("opportunity", "read") ||
        hasPermission("request-for-quotation", "read"),
      subItems: [
        {
          key: "14.6",
          label: "Business Partners",
          link: "/business-partners",
          visible: hasPermission("business-partner", "read"),
        },
        {
          key: "14.4",
          label: "Contacts",
          link: "/contacts",
          visible: hasPermission("contact", "read"),
        },
        {
          key: "14.3",
          label: "Events",
          link: "/events",
          visible: hasPermission("events", "read"),
        },
        {
          key: "14.1",
          label: "Leads",
          link: "/lead-management",
          visible: hasPermission("lead", "read"),
        },
        {
          key: "14.2",
          label: "Opportunities",
          link: "/opportunity-management",
          visible: hasPermission("opportunity", "read"),
        },
        {
          key: "14.5",
          label: "Request For Quotation",
          link: "/request-for-quotation",
          visible: hasPermission("request-for-quotation", "read"),
        },
      ],
    },

    {
      label: "SFA",
      icon: key === "11" ? beatActive : beat,
      key: "11",
      visible:
        hasPermission("beat-planning", "read") ||
        hasPermission("collection-management", "read") ||
        hasPermission("customer-visit-tracking", "read") ||
        hasPermission("expense-voucher", "read") ||
        hasPermission("sales-order", "read") ||
        hasPermission("invoice", "read") ||
        hasPermission("reminder", "read"),
      subItems: [
        {
          key: "11.2",
          label: "Beat Plans",
          link: "/beat-planning",
          visible: hasPermission("beat-planning", "read"),
        },
        {
          key: "11.5",
          label: "Collection Management",
          link: "/collection-management",
          visible: hasPermission("collection-management", "read"),
        },
        {
          key: "11.3",
          label: "Employee Tracking",
          link: "/emp-tracking",
          visible: hasPermission("customer-visit-tracking", "read"),
        },
        {
          key: "11.4",
          label: "Expense Vouchers",
          link: "/expense-voucher",
          visible: hasPermission("expense-voucher", "read"),
        },
        {
          key: "11.6",
          label: "Sales Orders",
          link: "/sales-orders",
          visible: hasPermission("sales-order", "read"),
        },
        {
          key: "11.7",
          label: "Invoices",
          link: "/sales-invoices",
          visible: hasPermission("invoice", "read"),
        },
        {
          label: "Reminder",
          key: "11.8",
          link: "/quick-reminder",
          visible: hasPermission("reminder", "read"),
        },
      ],
    },
    {
      label: "Tasks",
      icon: key === "5" ? taskActive : task,
      key: "5",
      link: "/task",
      visible: hasPermission("task", "read"),
    },

    {
      label: "Master Data",
      icon: key === "12" ? masterDataActive : masterData,
      key: "12",
      link: "/master-data",
      className: "master-data-menus-items",
      visible:
        hasPermission("address-type-master", "read") ||
        hasPermission("business-partner-type-master", "read") ||
        hasPermission("department-master", "read") ||
        hasPermission("industry-master", "read") ||
        hasPermission("type-of-company-master", "read") ||
        hasPermission("event-attendee-status-master", "read") ||
        hasPermission("event-attendee-type-master", "read") ||
        hasPermission("event-category-master", "read") ||
        hasPermission("event-online-platforms-master", "read") ||
        hasPermission("event-product-master", "read") ||
        hasPermission("event-type-master", "read") ||
        hasPermission("event-exhibitor-category-master", "read") ||
        hasPermission("currency-exchange", "read") ||
        hasPermission("deposit-master", "read") ||
        hasPermission("expense-category-type-master", "read") ||
        hasPermission("payment-method-master", "read") ||
        hasPermission("opportunity-stage", "read") ||
        hasPermission("plant-code-master", "read") ||
        hasPermission("task-status-master", "read") ||
        hasPermission("lead-rating", "read") ||
        hasPermission("lead-source", "read") ||
        hasPermission("leads-status", "read") ||
        hasPermission("hsn-code-master", "read") ||
        hasPermission("material-master", "read") ||
        hasPermission("product-category-master", "read") ||
        hasPermission("product-sub-category-master", "read") ||
        hasPermission("product-type-master", "read") ||
        hasPermission("unit-master", "read") ||
        hasPermission("area-master", "read") ||
        hasPermission("beat-grade-master", "read") ||
        hasPermission("beat-master", "read") ||
        hasPermission("zone-master", "read"),
    },
    {
      label: "Preferences",
      icon: key === "13" ? settingActive : setting,
      key: "13",
      link: "/setting",
      visible:
        hasPermission("company", "read") ||
        hasPermission("role", "read") ||
        hasPermission("role-profile", "read") ||
        hasPermission("users", "read") ||
        hasPermission("custom-fields", "read") ||
        hasPermission("company", "update"),
      subItems: [
        {
          label: "Company Settings",
          key: "13-1",
          link: "/company-settings",
          visible: hasPermission("company", "read"),
        },
        {
          label: "Custom Fields",
          key: "13-7",
          link: "/custom-fields",
          visible: hasPermission("custom-fields", "read"),
        },
        {
          label: "Personal Setting",
          key: "13-2",
          link: "/personal-setting",
          isState: true,
          visible: hasPermission("company-users", "read") || hasPermission("company-users", "update"),
        },
        {
          label: "Roles",
          key: "13-4",
          link: "/role-hierarchy",
          visible: hasPermission("role", "read"),
        },
        {
          label: "Role Profiles",
          key: "13-3",
          link: "/role-profile",
          visible: hasPermission("role-profile", "read"),
        },
        {
          label: "Organogram",
          key: "13-8",
          link: "/organogram",
          // visible: hasPermission("role-profile", "read"),
          visible: true,
        },
        {
          label: "SFA Settings",
          key: "13-5",
          link: "/sfa-settings",
          visible: hasPermission("company", "update"),
        },
        {
          label: "Users",
          key: "13-6",
          link: "/users",
          visible: hasPermission("company-users", "read"),
        },
      ],
    },
    {
      label: "Feedback",
      icon: key === "15" ? sideBarActiveMechanism : sideBarMechanism,
      key: "15",
      link: "/feedback-mechanism",
      visible: true,
    },
  ];

  const [filteredItems, setFilteredItems] = useState(itemData);

  const renderSubItems = (subItem, parentKey) => {
    const itemKey = `${parentKey}.${subItem.key}`;

    if (subItem.subItems && subItem.subItems.length > 0) {
      // If has nested subitems, create a submenu
      return getItem(
        subItem.label,
        itemKey,
        <GoDot color={currentPath === subItem.link ? "#6883FD" : "black"} />,
        subItem.subItems.map((nestedItem) =>
          renderSubItems(nestedItem, itemKey)
        ),
        // Ensure submenu is open if parent key is in openKeys
        { open: openKeys.includes(parentKey) }
      );
    }

    // If no nested subitems, create a regular menu item
    return getItem(
      <NavLink
        className={({ isActive, isPending }) =>
          isPending
            ? "pending"
            : currentPath === subItem.link
              ? "active-link"
              : ""
        }
        to={subItem.link}
      >
        {subItem.label}
      </NavLink>,
      itemKey,
      <GoDot color={currentPath === subItem.link ? "#6883FD" : "black"} />
    );
  };

  const items = [];
  filteredItems
    .filter((data) => data.visible !== false)
    .forEach((data) => {
      const item = getItem(
        data.subItems ? (
          <div
            className={`${key === data.key ? "text-black/90" : "text-black/90"
              }`}
          >
            {data.label}
          </div>
        ) : (
          <NavLink
            state={
              data.isState && {
                accountType: "OWN_ACCOUNT",
                isState: true,
              }
            }
            className={({ isActive, isPending }) =>
              isPending
                ? "pending"
                : isActive
                  ? "sidebar-active"
                  : "!font-normal"
            }
            to={data.link}
          >
            {data.label}
          </NavLink>
        ),
        data.key,
        <img width={20} src={data.icon} alt={data.label} />,

        data.subItems && data.subItems.length > 0
          ? data.subItems
            .filter((item) => item.visible !== false)
            .map((subItem) => renderSubItems(subItem, data.key))
          : null
      );
      items.push(item);
    });

  const showModal = () => {
    setVisible(true);
  };

  const [hide, setHide] = useState(true);

  useEffect(() => {
    if (user && user.company_users && !userLoader) {
      const shouldHide =
        user.company_users.length === 0 ||
        user.company_users.every(
          (companyUser) => companyUser.status !== "ACTIVE"
        );
      setHide(shouldHide);
    } else {
      setHide(true);
    }
  }, [user, userLoader]);

  useEffect(() => {
    if (
      notifications &&
      notifications.length === 0 &&
      currentCompanyUser &&
      currentCompanyUser?.id
    ) {
      dispatch(
        actionGetNotifications({
          notification_company_user_id: [currentCompanyUser?.id],
        })
      );
    }
  }, [dispatch, currentCompanyUser]);

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };


  const menuItems = [
    {
      key: "1",
      label: <p>Edit Profile</p>,
      icon: <EditOutlined />,
      visible: hasPermission("company-users", "read") || hasPermission("company-users", "update"),
      onClick: () =>
        navigate(`/update-profile`, {
          state: {
            userId: currentCompanyUser?.id,
            isCurrentUser: true,
            accountType: "OWN_ACCOUNT",
            updateType: true,
          },
        }),
    },
    {
      key: "2",
      visible: true,
      label: <p>Change Password</p>,
      icon: <LockOutlined />,
      onClick: () => navigate("/change-password"),
    },

    {
      key: "3",
      visible: true,
      label: (
        <div className="flex items-center" onClick={showModal}>
          <LogoutOutlined className="pr-2.5" />
          Logout
        </div>
      ),
    },
  ];

  useEffect(() => {
    localStorage.setItem("collapsed", JSON.stringify(collapsed));
  }, [collapsed]);

  const arr = [
    "/event-overview/:id",
    "/attendees",
    "/exhibitors",
    "/sessions",
    "/speakers",
    "/sponsor",
    "/event-inventory",
  ];

  const isOverview = useMemo(
    () => arr.some((path) => matchPath({ path, end: false }, currentPath)),
    [currentPath]
  );
  const eventId = useMemo(() => getEventId(), []);

  useEffect(() => {
    if (!isOverview) {
      if (eventId) {
        dispatch(clearEventId({ navigate, currentPath, arr }));
      }
    }
  }, [isOverview, dispatch, navigate]);

  const [openKeys, setOpenKeys] = useState([]);

  const filterMenu = (e) => {
    const val = e.target.value.toLowerCase();
    setKeyword(val);

    if (val !== "") {
      const filtered = itemData
        .map((item) => {
          if (!item.visible) return null;

          const labelText = item.label.toLowerCase().includes(val);

          const filterSubItems = (items, parentKey) => {
            if (!items) return [];

            return items
              .map((subItem) => {
                if (!subItem.visible) return null;

                const subLabelMatch = subItem.label.toLowerCase().includes(val);

                const nestedSubItems = filterSubItems(
                  subItem.subItems,
                  subItem.key
                );

                if (subLabelMatch || nestedSubItems.length > 0) {
                  setOpenKeys((prevKeys) => [
                    ...new Set([...prevKeys, parentKey]),
                  ]);
                  return {
                    ...subItem,
                    subItems:
                      nestedSubItems.length > 0 ? nestedSubItems : undefined,
                  };
                }

                return null;
              })
              .filter(Boolean);
          };

          const filteredSubItems = filterSubItems(item.subItems, item.key);

          if (labelText || filteredSubItems.length > 0) {
            return {
              ...item,
              subItems:
                filteredSubItems.length > 0 ? filteredSubItems : item.subItems,
            };
          }

          return null;
        })
        .filter(Boolean);

      setFilteredItems(filtered);
    } else {
      setFilteredItems(itemData);
      setOpenKeys([]);
    }
  };

  const appendData = async (reset = false, is_read = false) => {
    setLoading(true);
    try {
      const currentOffset = reset ? 0 : offset + 1;

      const resultAction = await dispatch(
        actionGetNotifications({
          offset: currentOffset,
          is_read: is_read,
        })
      );

      if (actionGetNotifications.fulfilled.match(resultAction)) {
        const status = resultAction.payload?.status;

        if (parseInt(status) === 200) {
          const { data: newNotifications, total_records, unreadNotificationCount } =
            resultAction.payload || [];

          setUnreadCount(unreadNotificationCount || 0);
          setTotalRecords(total_records)
          setNotifications((prevData) => {
            const updatedData = reset
              ? newNotifications
              : [...prevData, ...newNotifications];


            if (updatedData.length >= total_records) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }

            return updatedData;
          });

          if (!reset) {
            setOffset((prevOffset) => prevOffset + 1);
          }
        } else if (parseInt(status) === 404) {
          console.warn("No products found for the given filters.");
          setHasMore(false);
        }
      } else {
        console.error("Failed to fetch notifications:", resultAction.error);
      }

    } catch (err) {
      console.log({ err });
    } finally {
      setLoading(false);
    }
  };

  const notificationContent = () => (
    <NotificationList
      data={notifications}
      appendData={appendData}
      hasMore={hasMore}
      offset={offset}
      setIsPopoverOpen={setIsPopoverOpen}
      loading={loading}
    />
  );


  return (
    <>
      {
        isMobile ? <MobileDownload /> : <>
          <NotificationListener appendData={appendData} setOffset={setOffset} />
          <Layout className="overflow-hidden  max-h-screen">
            <Sider
              breakpoint="xxl"
              trigger={null}
              width={270}
              collapsible
              collapsed={collapsed}
              onCollapse={(c) => {
                setCollapsed(collapsed);
              }}
              className={`transition-all main-layout-sider ${hide ? "hidden" : "block"
                } pb-6 border-r border-bColor !relative py-3 side-menu duration-1000 max-h-screen overflow-auto ease-in color-scrollbar`}
              style={{
                background: "#fff",
                height: "100vh",
                position: "relative",
              }}
            >
              <div
                className={` py-4 pb-2 mx-auto ${collapsed ? "px-[8px]" : "px-[24px]"
                  }`}
              >
                <div
                  className={`flex items-center gap-x-4 ${collapsed ? "justify-center" : ""}`}
                >
                  <img
                    alt="Logo"
                    className="rounded-xl"
                    src={collapsed ? leftLogo : mainLogo}
                  />
                </div>
              </div>

              {!collapsed && (
                <div className="w-11/12 mt-2 mr-0 mb-0 ml-3 menu-search">
                  <Input
                    allowClear
                    placeholder="Search..."
                    prefix={<SearchOutlined />}
                    value={keyword}
                    maxLength={256}
                    onChange={filterMenu}
                    className="w-full py-2 px-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                  />
                </div>
              )}
              <Menu
                className="pt-7 pb-5"
                theme={"light"}
                mode="inline"
                selectedKeys={[key]}
                openKeys={openKeys}
                onOpenChange={(keys) => setOpenKeys(keys)}
                items={items}
              />
            </Sider>
            <Layout className="layout-main custom-scrollbar max-h-screen">
              <Header
                style={{
                  display: hide ? "none" : "flex",
                }}
                className="p-0 border-b border-bColor flex justify-between items-center px-2 bg-white 2xl:h-[60px] xl:h-[50px] h-[50px] !important"
              >
                <div className="flex items-center">
                  {React.createElement(
                    collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                    {
                      className: "trigger",
                      onClick: () => setCollapsed(!collapsed),
                    }
                  )}
                </div>

                <div className="flex items-center gap-x-4">
                  <Popover
                    open={isPopoverOpen}
                    onOpenChange={(newOpen) => {
                      setIsPopoverOpen(newOpen);
                      if (newOpen && offset === 0) {
                        appendData(true, true);
                      }
                    }}
                    rootClassName="notification-popover"
                    content={notificationContent}
                    title={
                      <p className="font-popinsSemiBold text-base tracking-wide">
                        Notifications
                        <span className="ml-1 text-sm text-gray-500">
                          ({totalRecords})
                        </span>
                      </p>
                    }
                    trigger="click"
                  >
                    <Badge
                      size="small"
                      offset={[5, 0]}
                      count={unreadCount}
                      className="mr-4"
                    >
                      <BellOutlined
                        onClick={() => {
                          setIsPopoverOpen(!isPopoverOpen);

                        }}
                        className="cursor-pointer text-xl  transition-colors duration-300"
                      />
                    </Badge>
                  </Popover>
                  <Dropdown
                    className="pr-4 cursor-pointer flex items-center justify-center"
                    menu={{
                      items: menuItems.filter(item => item.visible),
                    }}
                    trigger={"hover"}
                  >
                    <div className="hover:text-black">
                      <Space>
                        <Tooltip
                          placement="left"
                          title={getFullName(
                            currentCompanyUser?.first_name,
                            currentCompanyUser?.last_name
                          )}
                        >
                          <div className="flex items-center justify-center h-[35px] w-[35px] rounded-full">
                            <img
                              className="rounded-full h-full w-full"
                              src={currentCompanyUser?.profile_pic}
                              alt="User"
                            />
                          </div>
                        </Tooltip>
                      </Space>
                    </div>
                  </Dropdown>
                </div>
              </Header>
              <Wrapper isOverview={isOverview} children={<Outlet />} />
            </Layout>
          </Layout >
          <Modal
            centered
            open={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
          >
            <Result
              className="pb-7"
              status="warning"
              title="Are you sure you want to logout?"
              extra={[
                <Button
                  className="outline-button-border"
                  key="cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>,
                <Button
                  type="primary"
                  className="mx-2 filled-button"
                  key="logout"
                  onClick={() => {
                    dispatch(actionLogout({ navigate, setVisible }));
                  }}
                >
                  Logout
                </Button>,
              ]}
            />
          </Modal>
          <HpinModal />
          <VerifyHpinModal />
        </>
      }

    </>
  );
};
export default Root;
