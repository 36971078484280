import {
  LoadingOutlined,
  SearchOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Button,
  ConfigProvider,
  Form,
  Input,
  Pagination,
  Popconfirm,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import MasterSearchbar from "../../common/MasterSearchbar";
import * as XLSX from "xlsx";
import { useDispatch, useSelector } from "react-redux";
import deletevent from "../../../assets/img/deleteevent.svg";
import editedevent from "../../../assets/img/editedevent.svg";
import editevent from "../../../assets/img/editevent.svg";
import {
  actionSyncExchangeCurrencyMaster,
  actionDeleteExchangeCurrencyMaster,
  actionEditExchangeCurrencyMaster,
  actionGetSingleExchangeCurrencyMaster,
  actionGetExchageCurrencyMaster,
  actionGetAllExchageCurrencyMaster,
  actionClearExchangeCurrencyAllData,
} from "../../../store/services/generalMasterService";
import SortIcon from "../../common/SortIcon";
import { isValidSearchString } from "../../helper/common-function";
import { handleActionWithPermission } from "../../../store/slices/authSlice";
import usePermission from "../../../hooks/use-permission";
import SkeletonTable from "../../common/SkeletonTable";
import { lime } from "@ant-design/colors";
import CommonPagination from "../../common/CommonPagination";
import MasterCommonTable from "../../common/MasterCommonTable";

const ExchangeCurrency = ({ setSelectItem }) => {
  const [editableForm] = Form.useForm();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    exchangeCurrencyMasterData,
    getSingleExchangeCurrencyMasterData,
    getExchangeCurrencyLoader,
    syncExchangeCurrencyMasterLoader,
    deleteExchangeCurrencyMasterLoader,
    exchangeCurrencyMasterDataCount,
    updateExchangeCurrencyMasterLoader,
    allCurrencyMasterData,
    getAllExchangeCurrencyMasterLoader,
  } = useSelector((state) => state.generalMaster);
  const [search, setSearch] = useState("");
  const offsetRef = useRef(0);
  const [edited, setEdited] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [columnKey, setColumnKey] = useState(null)
  const [exportAll, setExportAll] = useState(false);
  const [lastSearch, setLastSearch] = useState('');
  const [pageSize, setPageSize] = useState(20);
  const { hasPermission } = usePermission();

  const isEditing = (record) => record.currency_exchange_id === editingKey;

  useEffect(() => {
    dispatch(actionGetExchageCurrencyMaster({
      offset: offsetRef.current,
      limit: pageSize
    }));
  }, []);

  useEffect(() => {
    const trimmedSearch = search?.trim();
    if (trimmedSearch !== "" && trimmedSearch !== lastSearch) {
      const timer = setTimeout(() => {
        dispatch(
          actionGetExchageCurrencyMaster({
            offset: offsetRef.current,
            limit: pageSize,
            search: trimmedSearch
          })
        );
        setLastSearch(trimmedSearch);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }

  }, [search, lastSearch]);

  const handleSearch = (e) => {
    const value = e.target.value;
    if (isValidSearchString(value)) {
      setSearch(value);
      if (value === "") {
        dispatch(
          actionGetExchageCurrencyMaster({
            offset: offsetRef.current,
            limit: pageSize,
            search: value,
          })
        );
      }
    }
  };

  const handleBlurAndPressEnter = (e) => {
    const value = e.target.value;
    if (search && isValidSearchString(value)) {
      dispatch(
        actionGetExchageCurrencyMaster({
          offset: offsetRef.current,
          limit: pageSize,
          search: value,
        })
      );
    }
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      inputType === "number" ? <Input /> : <Input variant="borderless" />;
    return (
      <td {...restProps} className="input-height">
        {editing ? (
          <Form.Item
            className="mb-0 editable-order"
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: title === "Exchange Rate" ? true : false,
                message: ``,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          <Typography.Paragraph
            style={{ marginBottom: 0 }}
            ellipsis={{ rows: 1, expandable: false, tooltip: true }}
            className="px-[11px]"
          >
            {children}
          </Typography.Paragraph>
        )}
      </td>
    );
  };

  const edit = (record) => {
    dispatch(
      actionGetSingleExchangeCurrencyMaster(record.currency_exchange_id)
    );
    setEdited(!edited);
  };

  useEffect(() => {
    if (getSingleExchangeCurrencyMasterData && edited) {
      editableForm.setFieldsValue({
        exchange_rate: getSingleExchangeCurrencyMasterData.exchange_rate,
        // exchange
      });
      setEditingKey(getSingleExchangeCurrencyMasterData.currency_exchange_id);
    }
  }, [getSingleExchangeCurrencyMasterData]);

  const save = async (id, exchange_currency_id) => {
    try {
      const row = await editableForm.validateFields();
      const { exchange_rate } = row;

      const req = {
        exchange_rate: parseFloat(exchange_rate),
        exchange_currency_id,
      };
      dispatch(
        actionEditExchangeCurrencyMaster({ id, req, setEditingKey, setEdited })
      );
    } catch (errInfo) {
      // console.log("Validate Failed:", errInfo);
      return;
    }
  };

  const columns = [
    {
      title: "SR No.",
      dataIndex: "srno",
      key: "srno",
      width: 70,
      fixed: "left",
      editable: false,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Exchange Currency",
      dataIndex: "exchange_currency",
      key: "exchange_currency",
      width: 250,
      editable: false,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => `${text?.currency} (${text?.name})`,
    },

    {
      title: "Exchange Rate",
      dataIndex: "exchange_rate",
      key: "exchange_rate",
      width: 250,
      editable: true,
      ellipsis: true,
      render: (data) => (data ? data : "-"),
      sorter: true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Action",
      key: "action",
      width: 100,
      fixed: "right",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);

        return (
          <div className="flex items-center">
            {editable ? (
              <div className="flex items-center">
                {updateExchangeCurrencyMasterLoader[
                  record.currency_exchange_id
                ] ? (
                  <LoadingOutlined />
                ) : (
                  <Typography.Link
                    onClick={() =>
                      save(
                        record.currency_exchange_id,
                        record?.exchange_currency?.id
                      )
                    }
                  >
                    <img src={editedevent} alt="" />
                  </Typography.Link>
                )}
              </div>
            ) : (
              <div className="flex items-center gap-x-2 justify-center">
                <Typography.Link
                  disabled={editingKey !== ""}
                  onClick={() => {
                    dispatch(
                      handleActionWithPermission(
                        "currency-exchange",
                        "update",
                        () => edit(record)
                      )
                    );
                  }}
                >
                  <img src={editevent} alt="" />
                </Typography.Link>
              </div>
            )}
            <div className="ml-5">
              {hasPermission("currency-exchange", "delete") ? (
                <Popconfirm
                  okButtonProps={{
                    loading:
                      deleteExchangeCurrencyMasterLoader[
                      record.currency_exchange_id
                      ],
                  }}
                  description="Are you sure you want to delete?"
                  onConfirm={() =>
                    dispatch(
                      actionDeleteExchangeCurrencyMaster({
                        id: record.currency_exchange_id,
                        setEditingKey,
                      })
                    )
                  }
                >
                  <img src={deletevent} alt="" className="cursor-pointer" />
                </Popconfirm>
              ) : (
                <img
                  src={deletevent}
                  alt=""
                  className="cursor-pointer"
                  onClick={() => {
                    dispatch(
                      handleActionWithPermission("currency-exchange", "delete")
                    );
                  }}
                />
              )}
            </div>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      sortIcon: () => col.sorter ? getExchangeCurrencyLoader && col.key === columnKey ? <LoadingOutlined /> : <SortIcon /> : null,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === "srno" ? "currency_exchange_id" : "exchange_rate",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleSortChange = (pagination, filters, sorter) => {
    setColumnKey(sorter.columnKey);
    const sortFieldMapping = {
      "exchange_currency.currency": "currency_exchange_id",
    };

    const orderByColumn = sortFieldMapping[sorter.field];

    const sorterParams = {
      field: orderByColumn,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
            ? "ASC"
            : "",
    };

    dispatch(
      actionGetExchageCurrencyMaster({
        offset: offsetRef.current,
        limit: pageSize,
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
        offset: offsetRef.current,
      })
    );
  };

  const handleSyncClick = async () => {
    try {
      await dispatch(actionSyncExchangeCurrencyMaster());
      await dispatch(actionGetExchageCurrencyMaster({
        offset: offsetRef.current,
        limit: pageSize,
        search: search
      }));
    } catch (error) {
      console.log("error sync");
    }
  };

  const getData = async (data) => {
    const newData =
      (await data) &&
      data.length > 0 &&
      data.map((d) => ({
        "Exchange Currency": d.exchange_currency?.currency
          ? `${d.exchange_currency.currency} (${d.exchange_currency.name})`
          : "-",
        "Exchange Rate": d.exchange_rate || "-",
      }));
    return newData || [];
  };

  const exportToExcel = async (items, type = "") => {
    if (!items || (items && items.length === 0)) {
      return;
    }
    const data = await getData(items);

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Currency Exchange");
    XLSX.writeFile(workbook, "currency-exchange.xlsx");
    type &&
      type === "all" &&
      dispatch(actionClearExchangeCurrencyAllData({ setExportAll }));
  };

  const onClickDownloadMenu = ({ key }) => {
    if (key === "1") {
      exportToExcel(exchangeCurrencyMasterData);
    } else {
      dispatch(actionGetAllExchageCurrencyMaster());
      setExportAll(true);
    }
  };

  useEffect(() => {
    if (
      exportAll &&
      allCurrencyMasterData &&
      allCurrencyMasterData.length > 0
    ) {
      exportToExcel(allCurrencyMasterData, "all");
    }
  }, [allCurrencyMasterData]);

  return (
    <div className="event-input">
      <div className="flex justify-between mt-[15px]">
        <h1 className="text-[#6883FD] text-[20px] font-medium">
          Currency Exchange
        </h1>
        <div>
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  colorBgContainerDisabled: "red",
                },
              },
            }}
          >
            <Button
              className="root-btn sync-disabled text-white hover:text-white py-5 flex-center radius"
              type="primary"
              onClick={handleSyncClick}
              icon={<SyncOutlined spin={syncExchangeCurrencyMasterLoader} />}
              disabled={syncExchangeCurrencyMasterLoader}
            >
              Sync Currency Exchange
            </Button>
          </ConfigProvider>
        </div>
      </div>

      <MasterCommonTable
       moduleKey="currency-exchange"
        columns={mergedColumns}
        dataSource={exchangeCurrencyMasterData}
        rowKey="currency_exchange_id"
        isLoading={getExchangeCurrencyLoader || getAllExchangeCurrencyMasterLoader}
        EditableCell={EditableCell}
        onTableChange={handleSortChange}
        editableForm={editableForm}
        searchBarProps={{
          handleSearch,
          handleBlurAndPressEnter,
          isImportEnabled: false,
          search,
          onClickDownloadMenu: onClickDownloadMenu,
          isPagination: true
        }}
        columnKey={columnKey}
      />
      <div className="flex items-center justify-between mt-3">
        <p className="text-[15px] font-popinsMedium">
          Total : {exchangeCurrencyMasterDataCount}
        </p>

        <CommonPagination
          current={offsetRef.current + 1}
          total={exchangeCurrencyMasterDataCount}
          onChange={(page, pageSize) => {
            offsetRef.current = page - 1;
            setPageSize(pageSize);
            dispatch(
              actionGetExchageCurrencyMaster({
                offset: offsetRef.current,
                limit: pageSize,
                search: search,
              })
            );
          }}
          loading={getExchangeCurrencyLoader}
          pageSize={pageSize}
        />
      </div>
    </div>
  );
};

export default ExchangeCurrency;
