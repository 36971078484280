import { green } from "@ant-design/colors";
import {
  Avatar,
  Button,
  Card,
  DatePicker,
  Divider,
  Dropdown,
  Empty,
  Form,
  Image,
  Input,
  Modal,
  Select,
  Skeleton,
  Spin,
  Table,
  Tag,
  Timeline,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import expensefirstdetail from "../../../assets/img/expensefirstdetail.svg";
import leftArrowPrimary from "../../../assets/img/leftArrowPrimary.svg";
import {
  actionChangeExpenseVoucherStatus,
  actionGetExpenseVoucherDetails,
} from "../../../store/services/financeService";
import SkeletonTable from "../../common/SkeletonTable";

const ViewExpenseVoucher = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [selectItem, setSelectItem] = useState("expanses");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    getExpenseVoucherDetailLoader,
    expenseVoucherDetailData,
    changeStatusLoader,
  } = useSelector((state) => state.finance);
  const { currentCompanyUser } = useSelector((state) => state.auth);

  const status = expenseVoucherDetailData?.status?.toLowerCase() || "";

  if (!id && !getExpenseVoucherDetailLoader) {
    return <Empty description={"No data found"} />;
  }

  useEffect(() => {
    if (id) {
      dispatch(actionGetExpenseVoucherDetails(id));
    }
  }, [id, dispatch]);

  const columns = [
    {
      title: "Receipt",
      dataIndex: "receipt_attachments",
      key: "receipt",
      width: 150,
      fixed: "left",
      ellipsis: true,
      render: (text, data) => {
        return data.receipt_attechments &&
          data.receipt_attechments.length > 0 ? (
          <Image.PreviewGroup
            items={data.receipt_attechments.map((item) => ({
              src: `${item.upload.url}`,
            }))}
          >
            <Image
              width={45}
              height={45}
              className="object-contain"
              src={`${data.receipt_attechments[0].upload.url}`}
              style={{
                objectFit: "cover",
              }}
            />
          </Image.PreviewGroup>
        ) : (
          <span>-</span>
        );
      },
    },
    {
      title: "Category",
      dataIndex: "expense_category_type",
      key: "expense_category_type",
      width: 200,
      ellipsis: true,
      render: (text, data) =>
        data.expense_category_type_master &&
        data.expense_category_type_master?.expense_category_type
          ? data.expense_category_type_master?.expense_category_type
          : "-",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 300,
      ellipsis: true,
      render: (text, data) => {
        const amountInBaseCurr = data?.amount * data.amount_currency_rate;
        const { currency_symbol: userCurrencySymbol, currency: userCurrency } =
          currentCompanyUser?.company?.currency || {};

        const { currency_symbol, currency } = data?.amount_currency || {};
        const displayText =
          currency !== userCurrency
            ? `Amount in ${userCurrency}: ${userCurrencySymbol}${amountInBaseCurr.toFixed(
                2
              )} (1 ${currency} = ${data.amount_currency_rate} ${userCurrency})`
            : "";
        return (
          <>
            <div>
              <span
                style={{
                  fontFamily: "Arial, sans-serif",
                }}
              >
                {currency_symbol !== null ? currency_symbol : ""}
              </span>
              {` ${data.amount || ""}`}
            </div>

            <Typography.Paragraph
              ellipsis={{ rows: 1, tooltip: true }}
              style={{ width: 280, margin: 0 }}
              className="ml-2"
            >
              {displayText}
            </Typography.Paragraph>
          </>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 200,
      ellipsis: true,
      render: (text, data) => (
        <span
          className="ml-2"
          style={{
            display: "block",
            maxWidth: 200,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          <Typography.Paragraph
            ellipsis={{
              tooltip: data.description ? data.description : "-",
            }}
            style={{ margin: 0 }}
            className={"!mb-0"}
          >
            {data.description ? data.description : "-"}
          </Typography.Paragraph>
        </span>
      ),
    },

    {
      title: "Payment Method",
      dataIndex: "payment_method",
      key: "payment_method",
      width: 200,
      ellipsis: true,
      render: (text, data) =>
        data.payment_method_master && data.payment_method_master?.payment_method
          ? data.payment_method_master?.payment_method
          : "-",
    },
    {
      title: "Vendor",
      dataIndex: "supplier_name",
      key: "supplier_name",
      width: 200,
      ellipsis: true,
      render: (text, data) => (
        <span className="ml-2">
          {data.supplier_name ? data.supplier_name : "-"}
        </span>
      ),
    },
    {
      title: "Invoice Number",
      dataIndex: "invoice_number",
      key: "invoice_number",
      width: 200,
      ellipsis: true,
      render: (text, data) => (
        <span className="ml-2">
          {data.invoice_number ? data.invoice_number : "-"}
        </span>
      ),
    },
  ];

  const items = [
    {
      label: "Expenses",
      key: "expanses",
    },
    {
      label: "History",
      key: "history",
    },
  ];

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const excludedStatusMap = {
    approved: ["approved", "unsubmitted", "submitted", "rejected"],
    submitted: ["submitted", "unsubmitted"],
    "pending approval": [
      "unsubmitted",
      "submitted",
      "pending approval",
      "awaiting approval",
    ],
    "awaiting approval": [
      "unsubmitted",
      "submitted",
      "pending approval",
      "awaiting approval",
    ],
    unsubmitted: [
      "unsubmitted",
      "approved",
      "rejected",
      "reimbursed",
      "pending approval",
      "awaiting approval",
    ],
    rejected: ["rejected", "unsubmitted", "submitted", "approved"],
  };

  const expenseVoucherStatusItems = [
    {
      key: "submitted",
      label: "Submitted",
      value: "Submitted",
    },
    {
      key: "approved",
      label: "Approved",
      value: "Approved",
    },
    {
      key: "unsubmitted",
      label: "Unsubmitted",
      value: "Unsubmitted",
    },
    {
      key: "rejected",
      label: "Rejected",
      value: "Rejected",
    },
    {
      key: "reimbursed",
      label: "Reimbursed",
      value: "Reimbursed",
    },
  ];

  const filteredExpenseVoucherStatusItems = expenseVoucherStatusItems.filter(
    (item) => {
      const exclusions = excludedStatusMap[status] || [status];

      const isExcludedStatus = exclusions.includes(item.key);

      const isApprover =
        expenseVoucherDetailData.approve_by &&
        expenseVoucherDetailData.approve_by.id === currentCompanyUser.id;
      const shouldIncludeApprover = status === "submitted" ? isApprover : true;

      return !isExcludedStatus && shouldIncludeApprover;
    }
  );

  const isApprover =
    expenseVoucherDetailData.approve_by &&
    expenseVoucherDetailData.approve_by.id === currentCompanyUser.id;
  const isSubmitter =
    expenseVoucherDetailData.submitter &&
    expenseVoucherDetailData.submitter.id === currentCompanyUser.id;

  const changeExpenseHistoryTab = () => {
    switch (selectItem) {
      case "expanses":
        return (
          <>
            {getExpenseVoucherDetailLoader ? (
              <SkeletonTable length={5} columns={columns} />
            ) : (
              <Table
                className="mt-4 expense-items-table"
                sticky
                rowKey="expense_voucher_items_id"
                scrollToFirstRowOnChange={true}
                sortDirections={["ascend", "descend"]}
                bordered
                // virtual
                scroll={{
                  y: 600,
                }}
                pagination={{
                  hideOnSinglePage: true,
                }}
                columns={columns}
                loading={getExpenseVoucherDetailLoader}
                dataSource={expenseVoucherDetailData.expense_voucher_items}
              />
            )}

            <div className="flex justify-between mt-3 bg-transparent">
              <p className="text-[15px] font-popinsMedium">
                Total Expense Voucher Detail :{" "}
                {expenseVoucherDetailData.expense_voucher_items_count
                  ? expenseVoucherDetailData.expense_voucher_items_count
                  : 0}
              </p>
            </div>
          </>
        );

      case "history":
        return (
          <>
            <div className="mt-8 remove-bg-dot w-[35%]">
              <Timeline
                key="expense_voucher_log_id"
                className="remove-bg-dot"
                items={
                  expenseVoucherDetailData.expense_voucher_log &&
                  expenseVoucherDetailData.expense_voucher_log.length > 0
                    ? expenseVoucherDetailData.expense_voucher_log.map(
                        (logItem) => ({
                          key: logItem.expense_voucher_log_id,
                          dot: getExpenseVoucherDetailLoader ? (
                            <Skeleton.Avatar
                              active
                              size="small"
                              shape="circle"
                              className="flex-shrink-0"
                              style={{ marginRight: "10px" }}
                            />
                          ) : (
                            <Avatar
                              src={logItem.created_by.profile_pic}
                              alt="history expense"
                              className="border-[2px] border-[#6883fd4f]"
                            />
                          ),
                          children: getExpenseVoucherDetailLoader ? (
                            <>
                              {/* Shimmer Effect for Name and Date */}
                              <div className="text-[#7C7C7C] text-[15px] ml-2">
                                <Skeleton.Input
                                  active
                                  style={{
                                    width: "100%",
                                    height: "30px",
                                  }}
                                />
                              </div>

                              {/* Shimmer Effect for Log Message */}
                              <Skeleton.Input
                                active
                                style={{
                                  width: "100%",
                                  height: "20px",

                                  marginTop: "8px",
                                }}
                              />
                            </>
                          ) : (
                            <>
                              {/* Actual Data for Name and Date */}
                              <div className="text-[#7C7C7C] text-[15px] ml-2">
                                <span className="font-popinsSemiBold">
                                  {logItem.created_by.full_name ?? "-"}
                                </span>{" "}
                                <span>
                                  {dayjs(logItem.created_at).format(
                                    "DD-MM-YYYY"
                                  )}
                                </span>
                              </div>

                              {/* Actual Data for Log */}
                              <span className="text-[15px] ml-2 text-center">
                                {logItem.log ?? "-"}
                              </span>
                            </>
                          ),
                        })
                      )
                    : [
                        {
                          children: (
                            <p className="text-[#7C7C7C] text-[15px] text-center">
                              No Logs Found
                            </p>
                          ),
                        },
                      ]
                }
              />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const statusColors = {
    submitted: "blue",
    approved: "green",
    unsubmitted: "default",
    rejected: "red",
    reimbursed: green[7],
    "pending approval": "orange",
    "awaiting approval": "magenta",
    default: "default",
  };

  const getStatusTextAndColor = (status, data) => {
    if (status.toLowerCase() === "submitted") {
      return data.approve_by?.id === currentCompanyUser?.id
        ? { text: "PENDING APPROVAL", color: statusColors["pending approval"] }
        : {
            text: "AWAITING APPROVAL",
            color: statusColors["awaiting approval"],
          };
    }

    return {
      text: status.toUpperCase(),
      color: statusColors[status.toLowerCase()] || statusColors.default,
    };
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 15 },
    },
  };

  const handleChangeStatusReimbush = (values) => {
    const req = {
      status: "Reimbursed",
      expense_voucher_id: [id],
      expense_voucher_reimbursed: {
        reimbursed_date: dayjs(values.reimbursed_date).format("YYYY-MM-DD"),
        paid_via: values.paid_via,
        paid_via_remark: values.paid_via_remark,
        reference: values.reference,
        note: values.note,
      },
    };

    dispatch(
      actionChangeExpenseVoucherStatus({
        values: req,
        setIsModalOpen,
        viewPage: true,
      })
    );
  };

  const totalAmount =
    expenseVoucherDetailData.expense_voucher_items_amount_count || 0;

  const handleChangeStatus = (key, id) => {
    const selected = expenseVoucherStatusItems.find(
      (item) => item.key === key.key
    );

    const values = {
      status: selected.value,
      expense_voucher_id: [id],
    };

    if (selected.value.toLowerCase() === "reimbursed") {
      setIsModalOpen(true);
    } else {
      dispatch(actionChangeExpenseVoucherStatus({ values }));
    }
  };
  /* 
  if (getExpenseVoucherDetailLoader) {
    return (
      <div className="h-[500px] flex items-center justify-center">
        <Spin />
      </div>
    );
  } */
  return (
    <>
      <section className="main-section px-4 custom-color" id="overview">
        <div className="flex items-center gap-x-5">
          <div
            className="flex items-center gap-x-3 cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <div className="rounded-[10px] flex items-center">
              <Image
                src={leftArrowPrimary}
                alt="image"
                height={20}
                width={20}
                preview={false}
              />
            </div>
            <h1 className="lg:text-lg text-base text-primary/90">
              Expense Voucher
            </h1>
          </div>

          {changeStatusLoader ? (
            <Spin size="small" />
          ) : status === "reimbursed" ||
            status.toLowerCase() === "rejected" ||
            (status.toLowerCase() === "awaiting approval" && !isApprover) ||
            (status === "unsubmitted" && !isSubmitter) ? (
            <Tag
              color={
                getStatusTextAndColor(status, expenseVoucherDetailData).color
              }
            >
              {getStatusTextAndColor(status, expenseVoucherDetailData).text}
            </Tag>
          ) : (
            <Dropdown
              menu={{
                items: filteredExpenseVoucherStatusItems,
                onClick: (key) =>
                  handleChangeStatus(
                    key,
                    expenseVoucherDetailData.expense_voucher_id
                  ),
              }}
              placement="bottom"
              arrow={{
                pointAtCenter: true,
              }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Tag
                  color={
                    getStatusTextAndColor(status, expenseVoucherDetailData)
                      .color
                  }
                  // className="py-1 px-2.5 text-sm font-popinsRegular"
                >
                  {getStatusTextAndColor(status, expenseVoucherDetailData).text}
                </Tag>
              </a>
            </Dropdown>
          )}
        </div>

        {expenseVoucherDetailData &&
          expenseVoucherDetailData.status === "Reimbursed" && (
            <div className="mt-4  ant-card-custom">
              <Card
                className="rounded-lg"
                loading={getExpenseVoucherDetailLoader}
              >
                <div className="bg-[#E9FBF8] rounded-t-lg">
                  <div className="flex items-center px-4 py-3 border-b border-b-[#dddae9] border-gray-200">
                    <img
                      src={expensefirstdetail}
                      alt="Reimbursement Icon"
                      className="w-6 h-6"
                    />
                    <p className="text-[#0299C8] ml-3 text-[15px] font-medium">
                      Reimbursement Details
                    </p>
                  </div>
                </div>
                <div className="px-0 py-4">
                  <div className="flex bg-white items-center justify-between">
                    <div className="flex justify-between pr-9 pl-7 py-3 items-center">
                      <div className="flex flex-col  text-center">
                        <p className="text-base font-popinsMedium text-black">
                          Reimbursed Date:
                        </p>
                        <p className="text-[14px] mt-2 text-[#7C7C7C] text-start">
                          {expenseVoucherDetailData.expense_voucher_reimbusred
                            .reimbursed_date ?? "-"}
                        </p>
                      </div>
                    </div>
                    <div className="border-l border-[#DDDAE9] h-12"></div>
                    <div className=" flex justify-between px-9 py-3 items-center">
                      <div className="flex flex-col  text-center">
                        <p className="text-base font-popinsMedium text-black text-align: start">
                          Reference#:
                        </p>
                        <p className="text-[14px] mt-2 text-[#7C7C7C] text-start">
                          {expenseVoucherDetailData.expense_voucher_reimbusred
                            .reference ?? "-"}
                        </p>
                      </div>
                    </div>
                    <div className="border-l border-[#DDDAE9] h-12"></div>
                    <div className="flex justify-between px-9 py-3 items-center">
                      <div className="flex flex-col  text-center">
                        <p className="text-base font-popinsMedium text-black">
                          Paid Through:
                        </p>
                        <p className="text-[14px] mt-2 text-[#7C7C7C] text-start">
                          {expenseVoucherDetailData.expense_voucher_reimbusred
                            .paid_via ?? "-"}
                        </p>
                      </div>
                    </div>
                    <div className="border-l border-[#DDDAE9] h-12"></div>
                    <div className="flex justify-between px-9 py-3 items-center">
                      <div className="flex flex-col  text-center">
                        <p className="text-base font-popinsMedium text-black">
                          Remark:
                        </p>
                        <p className="text-[14px] mt-2 text-[#7C7C7C] text-start">
                          {expenseVoucherDetailData.expense_voucher_reimbusred
                            .remark ?? "-"}
                        </p>
                      </div>
                    </div>
                    <div className="border-l border-[#DDDAE9] h-12"></div>{" "}
                    <div className="flex-1 flex px-9 py-3 items-center">
                      <div className=" flex   py-3 items-center">
                        <div className="flex flex-col">
                          <p className="text-base font-popinsMedium text-black">
                            Note:
                          </p>
                          <div className="text-[14px] mt-2 text-[#7C7C7C] text-start">
                            <span
                              className=""
                              style={{
                                display: "block",
                                maxWidth: 100,
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              <Typography.Paragraph
                                ellipsis={{
                                  tooltip: expenseVoucherDetailData
                                    .expense_voucher_reimbusred.note
                                    ? expenseVoucherDetailData
                                        .expense_voucher_reimbusred.note
                                    : "-",
                                }}
                                style={{ margin: 0 }}
                              >
                                {expenseVoucherDetailData
                                  .expense_voucher_reimbusred.note
                                  ? expenseVoucherDetailData
                                      .expense_voucher_reimbusred.note
                                  : "-"}
                              </Typography.Paragraph>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          )}

        <div className="mt-4">
          <Card className="" loading={getExpenseVoucherDetailLoader}>
            <div className="flex justify-between items-center">
              <div>
                <div className="flex gap-x-5 bg-white items-center">
                  <div className="text-base font-popinsMedium text-black flex">
                    <p> Voucher No:</p>
                    <span className="text-[#7C7C7C] ml-2">
                      {expenseVoucherDetailData.voucher_no ?? "-"}
                    </span>
                  </div>
                  <div className="border-l  border-[#DDDAE9] h-5" />
                  <div className=" flex  px-5 py-1 items-center">
                    <div className="text-base font-popinsMedium text-black flex">
                      <p>Date:</p>
                      <span className="text-[#7C7C7C] ml-2">
                        {dayjs(expenseVoucherDetailData.voucher_date).format(
                          "DD-MM-YYYY"
                        ) ?? "-"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex gap-x-5 bg-white items-center">
                  <div className="text-base font-popinsMedium text-black flex">
                    <p>Approver:</p>
                    <div className="text-base font-popinsMedium text-black flex">
                      <div className="h-[24px] w-[24px] ml-2 inline-block">
                        <img
                          src={
                            expenseVoucherDetailData.approve_by &&
                            expenseVoucherDetailData.approve_by?.profile_pic
                              ? expenseVoucherDetailData.approve_by?.profile_pic
                              : "-"
                          }
                          className="rounded-full w-full h-full"
                        />
                      </div>
                      <span className="text-[#7C7C7C] ml-2">
                        {expenseVoucherDetailData.approve_by &&
                        expenseVoucherDetailData.approve_by?.full_name
                          ? expenseVoucherDetailData.approve_by?.full_name
                          : "-"}
                      </span>
                    </div>
                  </div>
                  <div className="border-l  border-[#DDDAE9] h-5" />
                  <div className=" flex  px-5 py-1 items-center">
                    <div className="text-base font-popinsMedium text-black flex">
                      <p>City:</p>
                      <span className="text-[#7C7C7C] ml-2">
                        {expenseVoucherDetailData.city &&
                        expenseVoucherDetailData.city.trim() !== ""
                          ? expenseVoucherDetailData.city
                          : "-"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="text-base font-popinsMedium text-black flex justify-end">
                  Total
                </p>
                <span className="text-[#6883FD] font-popinsSemiBold ml-2 text-[20px]">
                  <span
                    style={{
                      fontFamily: "Arial, sans-serif",
                    }}
                  >
                    {currentCompanyUser.company?.currency &&
                    currentCompanyUser.company?.currency !== null
                      ? currentCompanyUser.company.currency.currency_symbol
                      : ""}
                  </span>
                  {` ${
                    expenseVoucherDetailData?.expense_voucher_items_amount_count ||
                    ""
                  }`}
                </span>
              </div>
            </div>
          </Card>
        </div>

        <div className="mt-4">
          <div className="flex flex-col border border-[#DDDAE9] rounded-[10px] bg-white mt-5 w-[35%] px-6">
            <div className="flex items-center justify-center mt-2">
              {items.map(({ key, label }) => (
                <div
                  key={key}
                  className={`flex-1 text-center text-[15px] font-medium cursor-pointer  ${
                    selectItem === key
                      ? "text-blue-500 border-b-2 border-blue-500"
                      : "text-black"
                  }`}
                  onClick={() => setSelectItem(key)}
                >
                  <h1 className="mb-[7px]">{label}</h1>
                </div>
              ))}
            </div>
          </div>

          <div>{changeExpenseHistoryTab()}</div>
        </div>

        <div className="mt-4 flex items-center">
          <p className="text-base font-popinsMedium text-black text-[15px]">
            Note:
          </p>
          <span className="text-[#7C7C7C] ml-2 text-[15px]">
            {getExpenseVoucherDetailLoader ? (
              <Skeleton active paragraph={{ rows: 3 }} />
            ) : expenseVoucherDetailData.notes &&
              expenseVoucherDetailData.notes.trim() !== "" ? (
              expenseVoucherDetailData.notes
            ) : (
              "-"
            )}
          </span>
        </div>

        <Modal
          centered
          onOk={handleCancel}
          onCancel={handleCancel}
          footer={false}
          width={600}
          title=""
          open={isModalOpen}
        >
          <div className="flex items-center">
            <img src={expensefirstdetail} alt="Beat Master" />
            <h1 className="text-[#6883FD] text-[20px] ml-[8px] mt-[5px] font-medium">
              Record Reimbursement
            </h1>
          </div>
          <Form
            labelAlign="left"
            {...formItemLayout}
            form={form}
            onFinish={handleChangeStatusReimbush}
            layout="horizontal"
            className="mt-[20px] mb-0"
          >
            <Form.Item
              // name="beat_name"
              label="Reimbursement Amount"
              className="mb-[12px] text-[17px]"
            >
              <div className="ml-3 text-[25px]">
                <span>
                  {`${
                    currentCompanyUser.company?.currency !== null
                      ? currentCompanyUser.company?.currency.currency_symbol
                      : ""
                  } `}
                </span>{" "}
                {totalAmount}
              </div>
            </Form.Item>
            <Divider />
            <Form.Item
              name="reimbursed_date"
              label="Reimbursed on"
              className="text-[17px] font-popinsMedium"
              rules={[
                {
                  required: true,
                  message: "Please enter reimbursed on!",
                },
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Enter reimbursed on"
                className="rounded-[10px]"
              />
            </Form.Item>

            <Form.Item
              name="paid_via"
              label="Paid Via"
              className="text-[17px] font-popinsMedium"
              rules={[
                {
                  required: true,
                  message: "Please enter paid via!",
                },
              ]}
            >
              <Select
                // onChange={(val) => setPaymentOption(val)}
                allowClear
                placeholder="Select Paid Via"
                options={[
                  { label: "Cash", value: "Cash" },
                  {
                    label: "Bank Transfer",
                    value: "Bank Transfer",
                  },
                  { label: "Credit Card", value: "Credit Card" },
                  { label: "Debit Card", value: "Debit Card" },
                  { label: "Check", value: "Check" },
                  {
                    label: "Mobile Payment",
                    value: "Mobile Payment",
                  },
                  {
                    label: "Direct Deposit",
                    value: "Direct Deposit",
                  },
                  {
                    label: "Company Account",
                    value: "Company Account",
                  },
                  { label: "Gift Card", value: "Gift Card" },
                  { label: "Other", value: "Other" },
                ]}
                style={{ width: "100%" }}
              />
            </Form.Item>

            <Form.Item
              name="reference"
              label="Reference#"
              className="text-[17px] font-popinsMedium"
              rules={[
                {
                  required: true,
                  message: "Please enter reference!",
                },
              ]}
            >
              <Input placeholder="Enter Reference" className="rounded-[10px]" />
            </Form.Item>

            <Form.Item
              name="note"
              label="Note"
              className="text-[17px] font-popinsMedium"
            >
              <Input.TextArea
                placeholder="Enter Note"
                className="rounded-[10px]"
              />
            </Form.Item>

            <Form.Item
              name="paid_via_remark"
              label="Remark"
              className="text-[17px] font-popinsMedium"
            >
              <Input.TextArea
                placeholder="Enter remark"
                className="rounded-[10px]"
              />
            </Form.Item>
            <div className="flex justify-center mt-[40px]">
              <Form.Item className="mb-0">
                <Button
                  type="button"
                  className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Form.Item>
              <div className="ml-4">
                <Form.Item className="mb-0">
                  <Button
                    type="primary"
                    className="root-btn min-h-[45px] min-w-[120px]"
                    loading={changeStatusLoader}
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </Modal>
      </section>
    </>
  );
};

export default ViewExpenseVoucher;
