import { createBrowserRouter, Navigate } from "react-router-dom";
import ChangeMobile from "../components/auth/ChangeMobile";
import CreateAccount from "../components/auth/CreateAccount";
import ErrorPage from "../components/auth/ErrorPage";
import ForgotPassword from "../components/auth/ForgotPassword";
import Login from "../components/auth/Login";
import LoginOTP from "../components/auth/LoginOTP";
import MainScreen from "../components/auth/MainScreen";
import OtpVerfication from "../components/auth/OtpVerfication";
import Register from "../components/auth/Register";
import ResetPassword from "../components/auth/ResetPassword";
import Begin from "../components/begin/Begin";
import BusinessHoursConfig from "../components/business/BusinessHoursConfig";
import NotFound from "../components/common/NotFound";
import Root from "../components/common/Root";
import Contacts from "../components/crm/contacts/Contacts";
import CreateContact from "../components/crm/contacts/CreateContact";
import Attendees from "../components/crm/events/Attendees";
import CreateEvent from "../components/crm/events/CreateEvent";
import EventInventory from "../components/crm/events/EventInventory";
import EventOverView from "../components/crm/events/EventOverView";
import Events from "../components/crm/events/Events";
import Exhibitors from "../components/crm/events/Exhibitors";
import Sessions from "../components/crm/events/Sessions";
import Speakers from "../components/crm/events/Speakers";
import Sponsor from "../components/crm/events/Sponsor";
import CreateLead from "../components/crm/leads/CreateLead";
import Leads from "../components/crm/leads/Leads";
import CreateOpportunity from "../components/crm/opportunities/CreateOpportunity";
import Opportunities from "../components/crm/opportunities/Opportunities";
import { PrivateRoute, PublicRoute } from "../components/helper/RouteHoc";
import Inventory from "../components/inventory/Inventory";
import AcceptInvitation from "../components/invitation/AcceptInvitation";
import AcceptInvitationCreateAccount from "../components/invitation/AcceptInvitationCreateAccount";
import BusinessPartners from "../components/master-data/business-partner/BusinessPartners";
import CreateBusinessPartner from "../components/master-data/business-partner/CreateBusinessPartner";
import ViewBusinessPartner from "../components/master-data/business-partner/ViewBusinessPartner";
import GeneralMasters from "../components/master-data/general-master/GeneralMasters";
import BeatPlanning from "../components/sales-force/BeatPlanning";
// import MySalesOrder from "../components/sales-orders/MySalesOrder";
import { useSelector } from "react-redux";
import ChangePassword from "../components/auth/ChangePassword";
import AddCollectionManagement from "../components/collection-management/AddCollectionManagement";
import CollectionManagement from "../components/collection-management/CollectionManagement";
import CustomImportComp from "../components/common/CustomImportComp";
import Demo from "../components/crm/leads/Demo";
import CreateRequstForQuotation from "../components/crm/requestForQuotation/CreateRequstForQuotation";
import RequstForQuotation from "../components/crm/requestForQuotation/RequstForQuotation";
import CreateFeedbcakMechanism from "../components/feedback-mechanism/CreateFeedbcakMechanism";
import FeedBackMechanism from "../components/feedback-mechanism/FeedBackMechanism";
import ViewFeedBackMechanism from "../components/feedback-mechanism/ViewFeedBackMechanism";
import AddExpenseVoucher from "../components/finance/expense-voucher/AddExpenseVoucher";
import ExpenseVoucher from "../components/finance/expense-voucher/ExpenseVoucher";
import ViewExpenseVoucher from "../components/finance/expense-voucher/ViewExpenseVoucher";
import MasterData from "../components/master-data/MasterData";
import AttendeeStatus from "../components/master-data/event-master/AttendeeStatus";
import AttendeeType from "../components/master-data/event-master/AttendeeType";
import EventCategory from "../components/master-data/event-master/EventCategory";
import EventOnlinePlatforms from "../components/master-data/event-master/EventOnlinePlatforms";
import EvenetProducts from "../components/master-data/event-master/EventProducts";
import EventType from "../components/master-data/event-master/EventType";
import ExhibitionCategory from "../components/master-data/event-master/ExhibitionCategory";
import AddressType from "../components/master-data/general-master/AddressType";
import Area from "../components/master-data/general-master/Area";
import BeatGrade from "../components/master-data/general-master/BeatGrade";
import BeatMaster from "../components/master-data/general-master/BeatMaster";
import BusinessPartnerType from "../components/master-data/general-master/BusinessPartnerType";
import DepartmentMaster from "../components/master-data/general-master/DepartmentMaster";
import DepositeMaster from "../components/master-data/general-master/DepositeMaster";
import ExchangeCurrency from "../components/master-data/general-master/ExchangeCurrency";
import ExpenseCategory from "../components/master-data/general-master/ExpenseCategory";
import IndustryMaster from "../components/master-data/general-master/IndustryMaster";
import LeadRating from "../components/master-data/general-master/LeadRating";
import LeadSource from "../components/master-data/general-master/LeadSource";
import LeadStatus from "../components/master-data/general-master/LeadStatus";
import MaterialGroup from "../components/master-data/general-master/MaterialGroup";
import OpportunityStage from "../components/master-data/general-master/OpportunityStage";
import PaymentMethod from "../components/master-data/general-master/PaymentMethod";
import PlantCode from "../components/master-data/general-master/PlantCode";
import TaskStatus from "../components/master-data/general-master/TaskStatus";
import TypeOfCompany from "../components/master-data/general-master/TypeOfCompany";
import UnitConversion from "../components/master-data/general-master/UnitConversion";
import Zone from "../components/master-data/general-master/Zone";
import HsnSacCode from "../components/master-data/product-master/HsnSacCode";
import Material from "../components/master-data/product-master/Product";
import ProductCategory from "../components/master-data/product-master/ProductCategory";
import ProductSubCategory from "../components/master-data/product-master/ProductSubCategory";
import ProductType from "../components/master-data/product-master/ProductType";
import Unit from "../components/master-data/product-master/Unit";
import QuickReminder from "../components/quick-reminder/QuickReminder";
import EmpTracking from "../components/sales-force/EmpTracking";
import AddSalesOrder from "../components/sales-orders/AddSalesOrder";
import SalesInvoices from "../components/sales-orders/SalesInvoices";
import SalesOrders from "../components/sales-orders/SalesOrders";
import ViewSalesInvoice from "../components/sales-orders/ViewSalesInvoice";
import ViewSalesOrder from "../components/sales-orders/ViewSalesOrder";
import AttendanceLogs from "../components/setting/AttendanceLogs";
import Permissions from "../components/setting/Permissions";
import SFASetting from "../components/setting/SFASetting";
import Setting from "../components/setting/Setting";
import CompanySettingWrapper from "../components/setting/company-setting/CompanySettingWrapper";
import CustomFields from "../components/setting/custom-fields/CustomFields";
import AddRoleProfile from "../components/setting/roles/AddRoleProfile";
import Hierarchy from "../components/setting/roles/Hierarchy";
import ModulePermissions from "../components/setting/roles/ModulePermissions";
import Organogram from "../components/setting/roles/Organogram";
import RoleProfile from "../components/setting/roles/RoleProfile";
import Task from "../components/task/Task";
import CreateUser from "../components/user/CreateUser";
import UpdateUser from "../components/user/UpdateUser";
import Users from "../components/user/Users";
import { selectHasPermission } from "../store/slices/authSlice";

const ProtectedRoute = ({ element, requiredPermission }) => {
  const { userLoader } = useSelector((state) => state.auth);
  const hasPermission = useSelector(selectHasPermission);

  if (userLoader) {
    return null;
  }

  if (!requiredPermission) {
    return element;
  }

  return hasPermission(
    requiredPermission.resource,
    requiredPermission.action
  ) ? (
    element
  ) : (
    <Navigate to="/" replace />
  );
};

export const router = createBrowserRouter([
  {
    path: "/",
    element: <PrivateRoute component={<Root />} />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <MainScreen />,
        errorElement: <ErrorPage />,
        index: true,
      },
      {
        path: "/inventory",
        element: <Inventory />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/update-profile",
        element: <UpdateUser isCurrentUser={true} />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/create-user",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<CreateUser />}
            requiredPermission={{
              resource: "company-users",
              action: "create",
            }}
          />
        ),
      },
      {
        path: "/update-user/:userId",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<UpdateUser />}
            requiredPermission={{
              resource: "company-users",
              action: "update",
            }}
          />
        ),
      },
      {
        path: "/company-settings",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<CompanySettingWrapper />}
            requiredPermission={{ resource: "company", action: "read" }}
          />
        ),
      },
      {
        path: "/personal-setting",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<UpdateUser isCurrentUser={true} />}
            requiredPermission={{
              resource: "company-users",
              action: "update",
            }}
          />
        ),
      },
      {
        path: "/sales-orders",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<SalesOrders />}
            requiredPermission={{ resource: "sales-order", action: "read" }}
          />
        ),
      },
      {
        path: "/sales-orders-import",
        errorElement: <ErrorPage />,
        element: (
          <CustomImportComp
            text={"Sales-Orders"}
            navigate={true}
            backText="/sales-orders"
            hasItems={true}
            downloadUrl="sales-order/import-excel-format"
            uploadUrl="sales-order/import"
          />
        ),
      },
      {
        path: "/view-sales-orders",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<ViewSalesOrder />}
            requiredPermission={{ resource: "sales-order", action: "read" }}
          />
        ),
      },
      {
        path: "/add-sales-order",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<AddSalesOrder />}
            requiredPermission={{ resource: "sales-order", action: "create" }}
          />
        ),
      },
      {
        path: "/update-sales-order",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<AddSalesOrder />}
            requiredPermission={{ resource: "sales-order", action: "update" }}
          />
        ),
      },
      {
        path: "/sales-invoices",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<SalesInvoices />}
            requiredPermission={{ resource: "invoice", action: "read" }}
          />
        ),
      },
      {
        path: "/view-sales-invoice",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<ViewSalesInvoice />}
            requiredPermission={{ resource: "invoice", action: "read" }}
          />
        ),
      },
      {
        path: "/purchase-orders",
        element: <h1>purchase-orders</h1>,
      },
      {
        path: "/sales-orders",
        element: <h1>sales-orders</h1>,
      },
      // {
      //   path: "/add-role",
      //   errorElement: <ErrorPage />,
      //   element: <AddRole />,
      // },
      {
        path: "/permissions",
        errorElement: <ErrorPage />,
        element: <Permissions />,
      },
      {
        path: "/",
        element: <Setting />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: "sfa-settings",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<SFASetting />}
                requiredPermission={{ resource: "company", action: "update" }}
              />
            ),
          },
          {
            path: "role-hierarchy",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<Hierarchy />}
                requiredPermission={{ resource: "role", action: "read" }}
              />
            ),
          },
          {
            path: "role-profile",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<RoleProfile />}
                requiredPermission={{
                  resource: "role-profile",
                  action: "read",
                }}
              />
            ),
          },
          {
            path: "add-role-profile",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<AddRoleProfile />}
                requiredPermission={{
                  resource: "role-profile",
                  action: "create",
                }}
              />
            ),
          },
          {
            path: "update-role-profile",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<AddRoleProfile />}
                requiredPermission={{
                  resource: "role-profile",
                  action: "update",
                }}
              />
            ),
          },
          {
            path: "users",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<Users />}
                requiredPermission={{
                  resource: "company-users",
                  action: "read",
                }}
              />
            ),
          },
        
          {
            path: "users-import",
            errorElement: <ErrorPage />,
            element: (
              <CustomImportComp
                text={"Users"}
                navigate={true}
                downloadUrl="company-users/import-excel-format"
                uploadUrl="company-users/import"
              />
            ),
          },
        ],
      },
      {
        path: "/events",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<Events />}
            requiredPermission={{ resource: "events", action: "read" }}
          />
        ),
      },
      {
        path: "/create-event",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<CreateEvent />}
            requiredPermission={{ resource: "events", action: "create" }}
          />
        ),
      },
      {
        path: "/update-event",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<CreateEvent />}
            requiredPermission={{ resource: "events", action: "update" }}
          />
        ),
      },
      {
        path: "/event-overview/:id",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<EventOverView />}
            requiredPermission={{ resource: "events", action: "read" }}
          />
        ),
      },
      {
        path: "/attendees",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<Attendees />}
            requiredPermission={{ resource: "event-attendee", action: "read" }}
          />
        ),
      },
      {
        path: "/exhibitors",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<Exhibitors />}
            requiredPermission={{ resource: "event-exhibitor", action: "read" }}
          />
        ),
      },
      {
        path: "/sessions",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<Sessions />}
            requiredPermission={{ resource: "event-session", action: "read" }}
          />
        ),
      },
      {
        path: "/speakers",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<Speakers />}
            requiredPermission={{ resource: "event-speaker", action: "read" }}
          />
        ),
      },
      {
        path: "/sponsor",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<Sponsor />}
            requiredPermission={{ resource: "event-sponsor", action: "read" }}
          />
        ),
      },
      {
        path: "/event-inventory",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<EventInventory />}
            requiredPermission={{ resource: "event-inventory", action: "read" }}
          />
        ),
      },
      {
        path: "/module-permissions",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<ModulePermissions />}
            requiredPermission={{ resource: "role-profile", action: "read" }}
          />
        ),
      },
      {
        path: "/lead-management",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<Leads />}
            requiredPermission={{ resource: "lead", action: "read" }}
          />
        ),
      },
      {
        path: "/demo",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<Demo />}
            requiredPermission={{ resource: "lead", action: "read" }}
          />
        ),
      },
      {
        path: "/create-lead",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<CreateLead />}
            requiredPermission={{ resource: "lead", action: "create" }}
          />
        ),
      },
      {
        path: "/update-lead",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<CreateLead />}
            requiredPermission={{ resource: "lead", action: "update" }}
          />
        ),
      },
      {
        path: "/beat-planning",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<BeatPlanning />}
            requiredPermission={{ resource: "beat-planning", action: "read" }}
          />
        ),
      },
      {
        path: "/emp-tracking",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<EmpTracking />}
            requiredPermission={{
              resource: "customer-visit-tracking",
              action: "read",
            }}
          />
        ),
      },
      {
        path: "/hr",
        element: <h1>HR</h1>,
      },
      {
        path: "/task",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<Task />}
            requiredPermission={{ resource: "task", action: "read" }}
          />
        ),
      },
      {
        path: "/beat",
        element: <h1>Beat</h1>,
      },
      {
        path: "/credit-notes",
        element: <h1>Credit notes</h1>,
      },
      {
        path: "/expense-voucher",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<ExpenseVoucher />}
            requiredPermission={{ resource: "expense-voucher", action: "read" }}
          />
        ),
      },
      {
        path: "/add-expense-voucher",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<AddExpenseVoucher />}
            requiredPermission={{
              resource: "expense-voucher",
              action: "create",
            }}
          />
        ),
      },
      {
        path: "/update-expense-voucher",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<AddExpenseVoucher />}
            requiredPermission={{
              resource: "expense-voucher",
              action: "update",
            }}
          />
        ),
      },
      {
        path: "/view-expense-voucher/:id",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<ViewExpenseVoucher />}
            requiredPermission={{ resource: "expense-voucher", action: "read" }}
          />
        ),
      },
      {
        path: "/collection-management",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<CollectionManagement />}
            requiredPermission={{
              resource: "collection-management",
              action: "read",
            }}
          />
        ),
      },
      {
        path: "/add-collection-management",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<AddCollectionManagement />}
            requiredPermission={{
              resource: "collection-management",
              action: "create",
            }}
          />
        ),
      },
      {
        path: "/update-collection-management",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<AddCollectionManagement />}
            requiredPermission={{
              resource: "collection-management",
              action: "update",
            }}
          />
        ),
      },
      {
        path: "/delivery",
        element: <h1>Delivery</h1>,
      },
      {
        path: "/general-masters",
        errorElement: <ErrorPage />,
        element: <GeneralMasters />,
      },
      {
        path: "/attendance-logs",
        errorElement: <ErrorPage />,
        element: <AttendanceLogs />,
      },
      {
        path: "/master-data/",
        element: <MasterData />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: "event-type",
            element: (
              <ProtectedRoute
                element={<EventType />}
                requiredPermission={{
                  resource: "event-type-master",
                  action: "read",
                }}
              />
            ),
          },
          {
            path: "event-category",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<EventCategory />}
                requiredPermission={{
                  resource: "event-category-master",
                  action: "read",
                }}
              />
            ),
          },
          {
            path: "event-online-platforms",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<EventOnlinePlatforms />}
                requiredPermission={{
                  resource: "event-online-platforms-master",
                  action: "read",
                }}
              />
            ),
          },
          {
            path: "attendee-status",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<AttendeeStatus />}
                requiredPermission={{
                  resource: "event-attendee-status-master",
                  action: "read",
                }}
              />
            ),
          },
          {
            errorElement: <ErrorPage />,
            path: "attendee-type",
            element: (
              <ProtectedRoute
                element={<AttendeeType />}
                requiredPermission={{
                  resource: "event-attendee-type-master",
                  action: "read",
                }}
              />
            ),
          },
          {
            path: "exhibitor-category",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<ExhibitionCategory />}
                requiredPermission={{
                  resource: "event-exhibitor-category-master",
                  action: "read",
                }}
              />
            ),
          },
          {
            path: "event-products",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<EvenetProducts />}
                requiredPermission={{
                  resource: "event-product-master",
                  action: "read",
                }}
              />
            ),
          },
          {
            path: "address-type",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<AddressType />}
                requiredPermission={{
                  resource: "address-type-master",
                  action: "read",
                }}
              />
            ),
          },
          {
            path: "address-type-import",
            errorElement: <ErrorPage />,
            element: (
              <CustomImportComp
                text={"Address Types"}
                navigate={true}
                backText="/general-masters/address-type"
                downloadUrl="address-type-master/import-excel-format"
                uploadUrl="address-type-master/import"
              />
            ),
          },

          {
            path: "material",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<Material />}
                requiredPermission={{
                  resource: "material-master",
                  action: "read",
                }}
              />
            ),
          },
          {
            path: "material-type",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<ProductType />}
                requiredPermission={{
                  resource: "product-type-master",
                  action: "read",
                }}
              />
            ),
          },
          {
            path: "hsn-sac-code",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<HsnSacCode />}
                requiredPermission={{
                  resource: "hsn-code-master",
                  action: "read",
                }}
              />
            ),
          },
          {
            path: "material-category",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<ProductCategory />}
                requiredPermission={{
                  resource: "product-category-master",
                  action: "read",
                }}
              />
            ),
          },
          {
            path: "material-sub-category",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<ProductSubCategory />}
                requiredPermission={{
                  resource: "product-sub-category-master",
                  action: "read",
                }}
              />
            ),
          },
          {
            path: "unit",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<Unit />}
                requiredPermission={{ resource: "unit-master", action: "read" }}
              />
            ),
          },
          {
            path: "material-import",
            errorElement: <ErrorPage />,
            element: (
              <CustomImportComp
                navigate={true}
                text="Materials"
                backText="/material-masters/material"
              />
            ),
          },
          {
            path: "material-type-import",
            errorElement: <ErrorPage />,
            element: (
              <CustomImportComp
                text={"Material Types"}
                navigate={true}
                backText="/material-masters/material-type"
                downloadUrl="product-type-master/import-excel-format"
                uploadUrl="product-type-master/import"
              />
            ),
          },
          {
            path: "hsn-sac-code-import",
            errorElement: <ErrorPage />,
            element: (
              <CustomImportComp
                text={"HSN/SAC Codes"}
                navigate={true}
                backText="/material-masters/hsn-sac-code"
                downloadUrl="hsn-code-master/import-excel-format"
                uploadUrl="hsn-code-master/import"
              />
            ),
          },
          {
            path: "material-category-import",
            errorElement: <ErrorPage />,
            element: (
              <CustomImportComp
                text={"Material Categories"}
                navigate={true}
                backText="/material-masters/material-category"
                downloadUrl="product-category-master/import-excel-format"
                uploadUrl="product-category-master/import"
              />
            ),
          },
          {
            path: "material-sub-category-import",
            errorElement: <ErrorPage />,
            element: (
              <CustomImportComp
                text={"Material Sub Categories"}
                navigate={true}
                backText="/material-masters/material-sub-category"
                downloadUrl="product-sub-category-master/import-excel-format"
                uploadUrl="product-sub-category-master/import"
              />
            ),
          },
          {
            path: "unit-import",
            errorElement: <ErrorPage />,
            element: (
              <CustomImportComp
                text={"Units"}
                navigate={true}
                backText="/material-masters/unit"
                downloadUrl="unit-master/import-excel-format"
                uploadUrl="unit-master/import"
              />
            ),
          },
          {
            path: "lead-status",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<LeadStatus />}
                requiredPermission={{
                  resource: "leads-status",
                  action: "read",
                }}
              />
            ),
          },
          {
            path: "lead-status-import",
            errorElement: <ErrorPage />,
            element: (
              <CustomImportComp
                text={"Lead Statuses"}
                navigate={true}
                backText="/general-masters/lead-status"
                downloadUrl="leads-status/import-excel-format"
                uploadUrl="leads-status/import"
              />
            ),
          },
          {
            path: "lead-source",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<LeadSource />}
                requiredPermission={{ resource: "lead-source", action: "read" }}
              />
            ),
          },
          {
            path: "lead-source-import",
            errorElement: <ErrorPage />,
            element: (
              <CustomImportComp
                text={"Lead Sources"}
                navigate={true}
                backText="/general-masters/lead-source"
                downloadUrl="lead-source/import-excel-format"
                uploadUrl="lead-source/import"
              />
            ),
          },
          {
            path: "lead-rating",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<LeadRating />}
                requiredPermission={{ resource: "lead-rating", action: "read" }}
              />
            ),
          },
          {
            path: "lead-rating-import",
            errorElement: <ErrorPage />,
            element: (
              <CustomImportComp
                text={"Lead Ratings"}
                navigate={true}
                backText="/general-masters/lead-rating"
                downloadUrl="lead-rating/import-excel-format"
                uploadUrl="lead-rating/import"
              />
            ),
          },
          {
            path: "industry-master",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<IndustryMaster />}
                requiredPermission={{
                  resource: "industry-master",
                  action: "read",
                }}
              />
            ),
          },
          {
            errorElement: <ErrorPage />,
            path: "industry-master-import",
            element: (
              <CustomImportComp
                text={"Industries"}
                navigate={true}
                backText="/general-masters/industry-master"
                downloadUrl="industry-master/import-excel-format"
                uploadUrl="industry-master/import"
              />
            ),
          },
          {
            path: "opportunity-stage",
            element: (
              <ProtectedRoute
                element={<OpportunityStage />}
                requiredPermission={{
                  resource: "opportunity-stage",
                  action: "read",
                }}
              />
            ),
          },
          {
            path: "opportunity-stage-import",
            element: (
              <CustomImportComp
                text={"Opportunity Stages"}
                navigate={true}
                backText="/general-masters/opportunity-stage"
                downloadUrl="opportunity-stage/import-excel-format"
                uploadUrl="opportunity-stage/import"
              />
            ),
          },
          {
            path: "beat-grade",
            element: (
              <ProtectedRoute
                element={<BeatGrade />}
                requiredPermission={{
                  resource: "beat-grade-master",
                  action: "read",
                }}
              />
            ),
          },
          {
            path: "beat-grade-import",
            errorElement: <ErrorPage />,
            element: (
              <CustomImportComp
                text={"Beat Grades"}
                navigate={true}
                backText="/general-masters/beat-grade"
                downloadUrl="beat-grade-master/import-excel-format"
                uploadUrl="beat-grade-master/import"
              />
            ),
          },
          {
            path: "business-partner-type",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<BusinessPartnerType />}
                requiredPermission={{
                  resource: "business-partner-type-master",
                  action: "read",
                }}
              />
            ),
          },
          {
            path: "business-partner-type-import",
            errorElement: <ErrorPage />,
            element: (
              <CustomImportComp
                text={"Business Partner Types"}
                navigate={true}
                backText="/general-masters/business-partner-type"
                downloadUrl="business-partner-type-master/import-excel-format"
                uploadUrl="business-partner-type-master/import"
              />
            ),
          },
          {
            path: "beat-master",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<BeatMaster />}
                requiredPermission={{ resource: "beat-master", action: "read" }}
              />
            ),
          },
          {
            path: "beat-master-import",
            errorElement: <ErrorPage />,
            element: (
              <CustomImportComp
                text={"Beats"}
                navigate={true}
                backText="/general-masters/beat-master"
                downloadUrl="beat-master/import-excel-format"
                uploadUrl="beat-master/import"
              />
            ),
          },
          {
            path: "department",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<DepartmentMaster />}
                requiredPermission={{
                  resource: "department-master",
                  action: "read",
                }}
              />
            ),
          },
          {
            path: "department-import",
            errorElement: <ErrorPage />,
            element: (
              <CustomImportComp
                text={"Departments"}
                navigate={true}
                backText="/general-masters/department"
                downloadUrl="department-master/import-excel-format"
                uploadUrl="department-master/import"
              />
            ),
          },
          {
            path: "type-of-company",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<TypeOfCompany />}
                requiredPermission={{
                  resource: "type-of-company-master",
                  action: "read",
                }}
              />
            ),
          },
          {
            path: "type-of-company-import",
            errorElement: <ErrorPage />,
            element: (
              <CustomImportComp
                text={"Company Types"}
                navigate={true}
                backText="/general-masters/type-of-company"
                downloadUrl="type-of-company-master/import-excel-format"
                uploadUrl="type-of-company-master/import"
              />
            ),
          },
          {
            path: "zone",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<Zone />}
                requiredPermission={{ resource: "zone-master", action: "read" }}
              />
            ),
          },
          {
            path: "zone-import",
            errorElement: <ErrorPage />,
            element: (
              <CustomImportComp
                text={"Zones"}
                navigate={true}
                backText="/general-masters/zone"
                downloadUrl="zone-master/import-excel-format"
                uploadUrl="zone-master/import"
              />
            ),
          },
          {
            path: "area",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<Area />}
                requiredPermission={{ resource: "area-master", action: "read" }}
              />
            ),
          },
          {
            path: "area-import",
            errorElement: <ErrorPage />,
            element: (
              <CustomImportComp
                text={"Areas"}
                navigate={true}
                backText="/general-masters/area"
                downloadUrl="area-master/import-excel-format"
                uploadUrl="area-master/import"
              />
            ),
          },
          {
            path: "task-status",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<TaskStatus />}
                requiredPermission={{
                  resource: "task-status-master",
                  action: "read",
                }}
              />
            ),
          },
          {
            path: "task-status-import",
            errorElement: <ErrorPage />,
            element: (
              <CustomImportComp
                text={"Task Statuses"}
                navigate={true}
                backText="/general-masters/task-status"
                downloadUrl="task-status-master/import-excel-format"
                uploadUrl="task-status-master/import"
              />
            ),
          },
          {
            path: "unit-conversion",
            errorElement: <ErrorPage />,
            element: <UnitConversion />,
          },
          {
            path: "unit-conversion-import",
            errorElement: <ErrorPage />,
            element: (
              <CustomImportComp
                text={"Unit Conversion"}
                navigate={true}
                backText="/general-masters/unit-conversion"
                downloadUrl="unit-conversion-master/import-excel-format"
                uploadUrl="unit-conversion-master/import"
              />
            ),
          },
          {
            path: "material-group",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<MaterialGroup />}
                requiredPermission={{
                  resource: "material-group-master",
                  action: "read",
                }}
              />
            ),
          },
          {
            path: "material-group-import",
            errorElement: <ErrorPage />,
            element: (
              <CustomImportComp
                text={"Material Group"}
                navigate={true}
                backText="/general-masters/material-group"
                downloadUrl="material-group-master/import-excel-format"
                uploadUrl="material-group-master/import"
              />
            ),
          },
          {
            path: "expense-category",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<ExpenseCategory />}
                requiredPermission={{
                  resource: "expense-category-type-master",
                  action: "read",
                }}
              />
            ),
          },
          {
            path: "expense-category-import",
            errorElement: <ErrorPage />,
            element: (
              <CustomImportComp
                text={"Expense Categories"}
                navigate={true}
                backText="/general-masters/expense-category"
                downloadUrl="expense-category-type-master/import-excel-format"
                uploadUrl="expense-category-type-master/import"
              />
            ),
          },
          {
            path: "payment-method",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<PaymentMethod />}
                requiredPermission={{
                  resource: "payment-method-master",
                  action: "read",
                }}
              />
            ),
          },
          {
            path: "payment-method-import",
            errorElement: <ErrorPage />,
            element: (
              <CustomImportComp
                text={"Payment Methods"}
                navigate={true}
                backText="/general-masters/payment-method"
                downloadUrl="payment-method-master/import-excel-format"
                uploadUrl="payment-method-master/import"
              />
            ),
          },
          {
            path: "address-type",
            errorElement: <ErrorPage />,
            element: <AddressType />,
            element: (
              <ProtectedRoute
                element={<AddressType />}
                requiredPermission={{
                  resource: "address-type-master",
                  action: "read",
                }}
              />
            ),
          },
          {
            path: "address-type-import",
            errorElement: <ErrorPage />,
            element: (
              <CustomImportComp
                text={"Address Types"}
                navigate={true}
                backText="/general-masters/address-type"
                downloadUrl="address-type-master/import-excel-format"
                uploadUrl="address-type-master/import"
              />
            ),
          },
          {
            path: "currency-exchange",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<ExchangeCurrency />}
                requiredPermission={{
                  resource: "currency-exchange",
                  action: "read",
                }}
              />
            ),
          },
          {
            path: "currency-exchange-import",
            errorElement: <ErrorPage />,
            element: (
              <CustomImportComp
                text={"Currency Exchange"}
                navigate={true}
                backText="/general-masters/currency-exchange"
                downloadUrl="exchange-currency-master/import-excel-format"
              />
            ),
          },
          {
            path: "deposit-master",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<DepositeMaster />}
                requiredPermission={{
                  resource: "deposit-master",
                  action: "read",
                }}
              />
            ),
          },
          {
            path: "deposit-master-import",
            errorElement: <ErrorPage />,
            element: (
              <CustomImportComp
                text={"Deposits"}
                navigate={true}
                backText="/general-masters/deposit-master"
                downloadUrl="deposit-master/import-excel-format"
                uploadUrl="deposit-master/import"
              />
            ),
          },
          {
            path: "plant-code",
            errorElement: <ErrorPage />,
            element: (
              <ProtectedRoute
                element={<PlantCode />}
                requiredPermission={{
                  resource: "plant-code-master",
                  action: "read",
                }}
              />
            ),
          },
          {
            path: "plant-code-import",
            errorElement: <ErrorPage />,
            element: (
              <CustomImportComp
                text={"Plant Codes"}
                navigate={true}
                backText="/general-masters/plant-code"
                downloadUrl="plant-code-master/import-excel-format"
                uploadUrl="plant-code-master/import"
              />
            ),
          },
        ],
      },

      {
        path: "/begin",
        errorElement: <ErrorPage />,
        element: <Begin />,
      },
      {
        path: "/opportunity-management",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<Opportunities />}
            requiredPermission={{ resource: "opportunity", action: "read" }}
          />
        ),
      },
      {
        path: "/create-opportunity",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<CreateOpportunity />}
            requiredPermission={{ resource: "opportunity", action: "create" }}
          />
        ),
      },
      {
        path: "/update-opportunity",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<CreateOpportunity />}
            requiredPermission={{ resource: "opportunity", action: "update" }}
          />
        ),
      },
      {
        path: "/business-partners",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<BusinessPartners />}
            requiredPermission={{
              resource: "business-partner",
              action: "read",
            }}
          />
        ),
      },
      {
        path: "/business-partners-import",
        errorElement: <ErrorPage />,
        element: (
          <CustomImportComp
            text={"Business Partners"}
            navigate={true}
            backText="/business-partners"
            downloadUrl="business-partner/import-excel-format"
            uploadUrl="business-partner/import"
          />
        ),
      },
      {
        path: "/create-business-partner",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<CreateBusinessPartner />}
            requiredPermission={{
              resource: "business-partner",
              action: "create",
            }}
          />
        ),
      },
      {
        path: "/update-business-partner",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<CreateBusinessPartner />}
            requiredPermission={{
              resource: "business-partner",
              action: "update",
            }}
          />
        ),
      },
      {
        path: "/view-business-partner",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<ViewBusinessPartner />}
            requiredPermission={{
              resource: "business-partner",
              action: "read",
            }}
          />
        ),
      },
      {
        path: "/contacts",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<Contacts />}
            requiredPermission={{ resource: "contact", action: "read" }}
          />
        ),
      },
      {
        path: "/create-contact",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<CreateContact />}
            requiredPermission={{ resource: "contact", action: "create" }}
          />
        ),
      },
      {
        path: "/update-contact",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<CreateContact />}
            requiredPermission={{ resource: "contact", action: "update" }}
          />
        ),
      },
      {
        path: "/request-for-quotation",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<RequstForQuotation />}
            requiredPermission={{
              resource: "request-for-quotation",
              action: "read",
            }}
          />
        ),
      },
      {
        path: "/create-request-for-quotation",
        element: <CreateRequstForQuotation />,
        element: (
          <ProtectedRoute
            element={<CreateRequstForQuotation />}
            requiredPermission={{
              resource: "request-for-quotation",
              action: "create",
            }}
          />
        ),
      },
      {
        path: "/update-request-for-quotation",
        element: (
          <ProtectedRoute
            element={<CreateRequstForQuotation />}
            requiredPermission={{
              resource: "request-for-quotation",
              action: "update",
            }}
          />
        ),
      },
      {
        path: "/change-password",
        element: <ChangePassword />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/custom-fields",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<CustomFields />}
            requiredPermission={{
              resource: "custom-fields",
              action: "read",
            }}
          />
        ),
      },
      {
        path: "/feedback-mechanism",
        errorElement: <ErrorPage />,
        element: <FeedBackMechanism />,
      },
      {
        path: "/create-feedback-mechanism",
        element: <CreateFeedbcakMechanism />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/view-feedback-mechanism/:id",
        element: <ViewFeedBackMechanism />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/update-feedback-mechanism",
        element: <CreateFeedbcakMechanism />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/organogram",
        element: <Organogram />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/quick-reminder",
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute
            element={<QuickReminder />}
            requiredPermission={{
              resource: "reminder",
              action: "read",
            }}
          />
        ),
      },
    ],
  },
  {
    path: "/error-page",
    element: (
      <>
        <ErrorPage />,
      </>
    ),
  },

  {
    path: "/change-mobile",
    element: <ChangeMobile />,
  },
  {
    path: "/privacy-policy",
    element: (
      <div className="text-center py-5">
        <h1 className="font-popinsSemiBold">Privacy Policy</h1>
      </div>
    ),
  },
  {
    path: "/terms-and-condition",
    element: (
      <div className="text-center py-5">
        <h1 className="font-popinsSemiBold">Terms and Condition</h1>
      </div>
    ),
  },
  {
    path: "/business-hours",
    element: <BusinessHoursConfig />,
  },
  {
    path: "/accept-invitation",
    element: <AcceptInvitation />,
    errorElement: <ErrorPage />
  },

  {
    path: "/accept-invitation-create-account",
    element: <AcceptInvitationCreateAccount />,
  },

  {
    element: <PublicRoute />,
    children: [
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/sign-otp",
        element: <LoginOTP />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/reset-password",
        element: <ResetPassword />,
      },
      {
        path: "/login",
        element: <Login />,
      },

      {
        path: "/otp",
        element: <OtpVerfication />,
      },
      {
        path: "/create-account",
        element: <CreateAccount />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);
