import { Button, Form, Image, Input, Radio } from "antd";
import React, { useEffect, useState } from "react";
import { MdMailOutline } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import back from "../../assets/img/back.svg";
import { getStoredUserData, startTimer } from "../helper/common-function";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { actionLoginOTP } from "../../store/services/authService";
import PhoneInputComponent from "../common/PhoneInputComponent";
import { useAuthContext } from "../../context/AuthContext";

const LoginOTP = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const data = getStoredUserData();
  const { loginOtpLoader } = useSelector((state) => state.auth);
  const [isValid, setIsValid] = useState(true);
  const [form] = Form.useForm();
  const [type, setType] = useState("EMAIL");
  const [value, setValue] = useState({
    fullPhoneNumber: "",
    iso: "in",
    phone: "",
  });


  const { setEmailTimer, setPhoneTimer, } = useAuthContext();


  const onChange = (e) => {
    setType(e.target.value);
    form.resetFields();
  };



  const startOTPTimer = () => {
    setPhoneTimer && startTimer(setPhoneTimer);
  }


  useEffect(() => {
    if (state && state.wrongDetails && data) {
      form.setFieldsValue({
        email: data.email,
      });
      setValue({
        ...value,
        fullPhoneNumber: data.phone_country_code + data.phone,
      });
    }
  }, [state && state.wrongDetails]);

  const handleChange = (valid) => {
    setIsValid(valid);
  };

  const onFinish = (values) => {
    const req = {
      type: type,
      send_to: values.email,
      country_code: "",
    };
    dispatch(actionLoginOTP({ req, navigate, startTimer: startOTPTimer }));
  };

  const onFinishMobile = (e) => {
    e.preventDefault();
    const valid =
      value && value.phone && value.phone.length < 15 ? true : false;

    if (valid) {
      const req = {
        type: type,
        send_to: value.phone,
        country_code: value.iso,
      };
      dispatch(actionLoginOTP({ req, navigate, phone: value.fullPhoneNumber, startTimer: startOTPTimer }));
    } else {
      setIsValid(false);
    }
  };

  return (
    <div className="register-container relative">
      <div className="title mb-4 pt-20 flex flex-col justify-center items-center gap-y-2.5">
        <p className="xl:text-[30px]  lg:text-[26px] md:text-2xl text-xl font-semibold text-secondary font-popins">
          Sign in with OTP
        </p>
        <p className="lg:text-[20px] text-center text-base text-gray-600">
          Verify with OTP sent to your mobile or email
        </p>
      </div>
      <br />
      <Radio.Group className="radio-wrapper" onChange={onChange} value={type}>
        <Radio value={"EMAIL"}>Email</Radio>
        <Radio value={"PHONE"}>Mobile</Radio>
      </Radio.Group>
      {type === "EMAIL" ? (
        <Form
          form={form}
          name="normal_login"
          className="login-form w-full pt-5"
          onFinish={onFinish}
        >
          <Form.Item
            className="mb-8"
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter your email!",
              },
              {
                pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: "Please enter a valid email!",
              },
            ]}
          >
            <Input
              placeholder="Email"
              className="indent-5"
              prefix={
                <MdMailOutline
                  color="#4567ff"
                  className="h-[18px] w-[18px] site-form-item-icon font-bold"
                />
              }
            />
          </Form.Item>

          <Form.Item>
            <Button
              loading={loginOtpLoader}
              type="primary"
              className="filled-s-button mt-3 md:text-base text-sm  border-none rounded-[10px] font-popinsMedium tracking-wider min-h-[45px]"
              block
              htmlType="submit"
            >
              Send OTP
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <form className="login-form w-full pt-5" onSubmit={onFinishMobile}>
          <div>
            <PhoneInputComponent
              value={value.fullPhoneNumber}
              onChange={handleChange}
              setIsValid={setIsValid}
              isValid={isValid}
              setValue={setValue}
            />
          </div>

          <Form.Item>
            <Button
              loading={loginOtpLoader}
              type="primary"
              className="filled-s-button mt-3 md:text-base text-sm  border-none rounded-[10px] font-popinsMedium tracking-wider min-h-[45px]"
              block
              htmlType="submit"
            >
              Send OTP
            </Button>
          </Form.Item>
        </form>
      )}
      <Form.Item className=" text-center">
        <Link to="/login" className="flex items-center justify-center gap-x-2 ">
          <Image
            src={back}
            alt="back"
            preview={false}
            className="text-primary/90 "
          />
          <p
            to="/login"
            className="text-primary hover:text-primary/90 md:text-[18px] text-[15px]"
          >
            Back to sign in
          </p>
        </Link>
      </Form.Item>
    </div>
  );
};

export default LoginOTP;
