import {
  DeleteOutlined,
  EllipsisOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Dropdown,
  Empty,
  Form,
  Image,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Radio,
  Row,
  Select,
  Spin,
  Table,
  Typography,
} from "antd";
import axios from "axios";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import * as XLSX from "xlsx";
import convertSmallIcon from "../../../assets/img/convertSmallIcon.svg";
import deletevent from "../../../assets/img/deleteevent.svg";
import editevent from "../../../assets/img/editevent.svg";
import gallery from "../../../assets/img/gallery.svg";
import materialConversionIcon from "../../../assets/img/materialConversionIcon.svg";
import productImage from "../../../assets/img/product-img.svg";
import { BASE_URL_UPLOAD } from "../../../config/web-config";
import usePermission from "../../../hooks/use-permission";
import {
  actionGetCustomFields,
  actionGetAllCustomFields,
} from "../../../store/services/customFieldService";
import {
  actionAddHsnSacCodeMaster,
  actionAddMaterialConversion,
  actionAddProductCategoryMaster,
  actionAddProductMaster,
  actionAddProductSubCategoryMaster,
  actionAddProductTypeMaster,
  actionAddUnitMaster,
  actionClearProductsAllData,
  actionDeleteMaterialConversion,
  actionDeleteProductMaster,
  actionGetAllHsnSacMaster,
  actionGetAllMaterialConversions,
  actionGetAllProductsMaster,
  actionGetAllProductSubCategoryMaster,
  actionGetProductCategoryMaster,
  actionGetProductMaster,
  actionGetProductsMaster,
  actionGetProductTypeMaster,
  actionGetSingleMaterialConversion,
  actionGetUnitMaster,
  actionUpdateMaterialConversion,
  actionUpdateProductMaster,
} from "../../../store/services/productMasterService";
import { handleActionWithPermission } from "../../../store/slices/authSlice";
import AddPopover from "../../common/AddPopover";
import CustomFormFieldsComp from "../../common/CustomFormFieldsComp";
import MasterSearchbar from "../../common/MasterSearchbar";
import PageTitleForMasterData from "../../common/PageTitleForMasterData";
import SortIcon from "../../common/SortIcon";
import {
  isNullOrEmpty,
  transformToCustomFields,
} from "../../helper/common-function";
import { FieldType } from "../../helper/constants";
import SkeletonTable from "../../common/SkeletonTable";
import CommonTable from "../../common/CommonTable";
import CommonPagination from "../../common/CommonPagination";
const { Panel } = Collapse;

const Material = ({ setSelectItem }) => {
  const [filterForm] = Form.useForm();
  const [addForm] = Form.useForm();
  const [conversionForm] = Form.useForm();
  const [baseUnitForm] = Form.useForm();
  const [hsnSacForm] = Form.useForm();
  const [materialGroupForm] = Form.useForm();
  const [categoryForm] = Form.useForm();
  const [subCategoryForm] = Form.useForm();
  const [materialTypeForm] = Form.useForm();
  const [standardWeightUnitForm] = Form.useForm();
  const dispatch = useDispatch();
  const offsetRef = useRef(0);
  const {
    productTypeData,
    addProductLoader,
    productData,
    allProductSubCategoryData,
    getAllProductSubCategoryLoader,
    getProductTypeLoader,
    getProductCategoryLoader,
    productCategoryData,
    getUnitMasterLoader,
    unitMasterData,
    getAllHsnSacLoader,
    addUnitLoader,
    addHsnSacCodeLoader,
    addProductCategoryLoader,
    addProductSubCategoryLoader,
    addProductTypeLoader,
    getSingleProductLoader,
    productDataCount,
    getSingleProductData,
    updateProductLoader,
    deleteProductLoader,
    productAllData,
    getAllProductsLoader,
    getProductsLoader,
    allHsnSacCodeData,
    getAllMaterialConversionsLoader,
    materialConversionsAllData,
    addMaterialConversionLoader,
    getSingleMaterialConversionLoader,
    getSingleMaterialConversionData,
    updateMaterialConversionLoader,
    deleteMaterialConversionLoader,
  } = useSelector((state) => state.productMaster);

  const { getCustomFieldsLoader, customFields, allCustomFields } = useSelector(
    (state) => state.customField
  );


  const [search, setSearch] = useState("");
  const [exportAll, setExportAll] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [materialType, setMaterialType] = useState(null);
  const [materialImages, setMaterialImages] = useState([]);
  const [isBaseUnitPopoverOpen, setIsBaseUnitPopoverOpen] = useState(false);
  const [isHsnSacPopoverOpen, setIsHsnSacPopoverOpen] = useState(false);
  const [isStandardWeightUnitPopoverOpen, setIsStandardWeightUnitPopoverOpen] =
    useState(false);
  const [isMaterialGroupPopoverOpen, setIsMaterialGroupPopoverOpen] =
    useState(false);
  const [isCategoryPopoverOpen, setIsCategoryPopoverOpen] = useState(false);
  const [isSubCategoryPopoverOpen, setIsSubCategoryPopoverOpen] =
    useState(false);
  const [isMaterialTypePopoverOpen, setIsMaterialTypePopoverOpen] =
    useState(false);
  const [uploading, setUploading] = useState(false);
  const [materialId, setMaterialId] = useState(null);
  const [conversionModal, setConversionModal] = useState(false);
  const [materialConversionId, setMaterialConversionId] = useState(null);
  const [materialBaseUnit, setMaterialBaseUnit] = useState(null);
  const [tagItems, setTagItems] = useState([""]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [dot, setDot] = useState(false);
  const { hasPermission } = usePermission();
  const [filterValues, setFilterValues] = useState(0);
  const [activeKey, setActiveKey] = useState([]); // State to control the active panels
  const [lastSearch, setLastSearch] = useState('');
  const [pageSize, setPageSize] = useState(20);
  useEffect(() => {
    dispatch(actionGetProductsMaster({
      offset: offsetRef.current,
      limit: pageSize
    }));
  }, [dispatch]);

  const showModal = () => {
    setIsModalOpen(true);
    dispatch(actionGetProductTypeMaster());
    dispatch(
      actionGetCustomFields({
        module_name: "Material",
        order: "ASC",
        orderByColumn: "sort_order",
      })
    );
    dispatch(
      actionGetProductCategoryMaster({
        orderByColumn: "category_name",
        order: "ASC",
      })
    );
    dispatch(actionGetUnitMaster());
    dispatch(
      actionGetAllProductSubCategoryMaster({
        orderByColumn: "sub_category_name",
        order: "ASC",
      })
    );
    dispatch(actionGetAllHsnSacMaster());
  };

  useEffect(() => {
    if (getSingleProductData && materialId) {
      setMaterialType(
        getSingleProductData.material_type?.product_type_master_id
      );
      if (
        getSingleProductData.material_attachments &&
        getSingleProductData.material_attachments.length > 0
      ) {
        const attachments = getSingleProductData.material_attachments.map(
          (attachment) => ({
            url: attachment.upload?.url,
            upload_id: attachment.upload?.upload_id,
            file_name: attachment.upload?.file_name,
            size: attachment.upload?.size,
            file_type: attachment.upload?.file_type,
          })
        );
        setMaterialImages(attachments);
      }

      addForm.setFieldsValue({
        material_name: getSingleProductData.material_name,
        material_code: getSingleProductData.material_code,
        material_description: getSingleProductData.material_description,
        base_unit_master_id: getSingleProductData.base_unit_of_measure
          ? getSingleProductData.base_unit_of_measure?.unit_master_id
          : null,
        material_group_master_id: getSingleProductData.material_group
          ? getSingleProductData.material_group?.material_group_master_id
          : null,
        material_category_id: getSingleProductData.material_category
          ? getSingleProductData.material_category?.product_category_master_id
          : null,
        material_sub_category_id: getSingleProductData.material_sub_category
          ? getSingleProductData.material_sub_category
            ?.product_sub_category_master_id
          : null,
        standard_weight_unit_master_id:
          getSingleProductData.standard_weight_unit
            ? getSingleProductData.standard_weight_unit?.unit_master_id
            : null,
        hsn_code_master_id: getSingleProductData.hsn_code
          ? getSingleProductData.hsn_code?.hsn_code_master_id
          : null,
        min_stock_level: getSingleProductData.min_stock_level,
        max_stock_level: getSingleProductData.max_stock_level,
        standard_weight: getSingleProductData.standard_weight,
        batch_required: getSingleProductData.batch_required,
        shelf_life: getSingleProductData.shelf_life,
        shelf_life_period: getSingleProductData.shelf_life_period,
        erp_code: getSingleProductData.erp_code,
        price: getSingleProductData.price,
        material_description: getSingleProductData.material_description,
      });

      if (
        getSingleProductData.custom_field_values &&
        getSingleProductData.custom_field_values.length > 0
      ) {
        addForm.setFieldValue(
          "custom_fields",
          getSingleProductData.custom_field_values.reduce((acc, item) => {
            if (item.custom_field && item.value) {
              const { field_name, field_type } = item.custom_field;
              acc[field_name] = {
                value:
                  field_type === FieldType.NUMBER
                    ? parseInt(item.value)
                    : item.value,
                field_type,
              };
            }
            return acc;
          }, {})
        );
      } else {
        addForm.setFieldValue("custom_fields", []);
      }

      setTagItems(
        Array.isArray(getSingleProductData?.tags) &&
          getSingleProductData.tags.length
          ? getSingleProductData.tags
          : [""]
      );
    }
  }, [dispatch, getSingleProductData, materialId, addForm]);

  const subCategoryOptions = useMemo(() => {
    return allProductSubCategoryData && allProductSubCategoryData.length > 0
      ? allProductSubCategoryData.map((data) => ({
        label: data.sub_category_name,
        value: data.product_sub_category_master_id,
      }))
      : [];
  }, [allProductSubCategoryData]);

  const categoryOptions = useMemo(() => {
    return productCategoryData && productCategoryData.length > 0
      ? productCategoryData.map((data) => ({
        label: data.category_name,
        value: data.product_category_master_id,
      }))
      : [];
  }, [productCategoryData]);

  const baseUnitptions = useMemo(() => {
    return unitMasterData && unitMasterData.length > 0
      ? unitMasterData.map((data) => ({
        label: data.code,
        value: data.unit_master_id,
      }))
      : [];
  }, [unitMasterData]);

  const hsnSacOptions = useMemo(() => {
    return allHsnSacCodeData && allHsnSacCodeData.length > 0
      ? allHsnSacCodeData.map((data) => ({
        label: data.hsn_code,
        value: data.hsn_code_master_id,
      }))
      : [];
  }, [allHsnSacCodeData]);

  //material
  const materialTypeOptions = useMemo(() => {
    return productTypeData && productTypeData.length > 0
      ? productTypeData.map((data) => ({
        label: data.product_type,
        value: data.product_type_master_id,
      }))
      : [];
  }, [productTypeData]);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const onFilterModalClose = () => {
    setIsFilterModalOpen(false);
    // filterForm.resetFields();
  };

  const handleChangeTags = (value) => {
    setTagItems(value);
  };

  const columns = [
    {
      title: "SR No.",
      dataIndex: "srno",
      key: "srno",
      width: 100,
      editable: false,
      fixed: "left",
      render: (text, record, index) => {
        const pageSize = 10;
        return offsetRef.current * pageSize + index + 1;
      },
    },
    {
      title: "Material Image",
      dataIndex: "material_attachments",
      key: "2",
      width: 200,
      fixed: "left",
      ellipsis: true,
      render: (text) =>
        text && text.length > 0 ? (
          <Image.PreviewGroup
            items={text.map((img) => ({ src: img?.upload.url }))}
          >
            <Image
              width={50}
              height={50}
              className="object-contain"
              src={text[0].upload?.url}
            />
          </Image.PreviewGroup>
        ) : (
          <span>-</span>
        ),
    },
    {
      title: "Material Name",
      dataIndex: "material_name",
      key: "3",
      width: 200,
      fixed: "left",
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text, record) => (
        <span
          className="ml-2 text-brand cursor-pointer"
          onClick={() => {
            dispatch(
              handleActionWithPermission("material-master", "update", () => {
                showModal(),
                  setMaterialId(record?.material_master_id),
                  dispatch(actionGetProductMaster(record.material_master_id));
              })
            );
          }}
        >
          {isNullOrEmpty(text)}
        </span>
      ),
    },
    {
      title: "Material Type",
      dataIndex: "material_type",
      key: "4",
      width: 200,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">{isNullOrEmpty(text?.product_type)}</span>
      ),
    },
    {
      title: "Material Description",
      dataIndex: "material_description",
      key: "5",
      width: 300,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{isNullOrEmpty(text)}</span>,
    },
    {
      title: "Base Unit of Measure",
      dataIndex: "base_unit_of_measure",
      key: "6",
      width: 200,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">{isNullOrEmpty(text?.code)}</span>
      ),
    },

    // {
    //   title: "Material Group",
    //   dataIndex: "material_group",
    //   key: "7",
    //   width: 200,
    //   ellipsis: true,
    //   sorter: true,
    //   sortIcon: () => <SortIcon />,
    //   render: (text) => (
    //     <span className="ml-2">{isNullOrEmpty(text?.name)}</span>
    //   ),
    // },

    {
      title: "Material Category",
      dataIndex: "material_category",
      key: "7",
      width: 200,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">{isNullOrEmpty(text?.category_name)}</span>
      ),
    },

    {
      title: "Material Sub Category",
      dataIndex: "material_sub_category",
      key: "8",
      width: 200,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">{isNullOrEmpty(text?.sub_category_name)}</span>
      ),
    },
    {
      title: "Standard Weight Unit",
      dataIndex: "standard_weight_unit",
      key: "9",
      width: 200,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">{isNullOrEmpty(text?.code)}</span>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: 100,
      align: "center",
      fixed: "right",
      dataIndex: "action",
      render: (_, record) => (
        <Dropdown
          trigger={["hover"]}
          id="custom-drop"
          menu={{
            items: [
              {
                label: <span className="text-[#7C7C7C]">Conversion</span>,
                key: "",
                icon: <img src={convertSmallIcon} alt="icon" />,
                onClick: () => {
                  dispatch(
                    handleActionWithPermission(
                      "material-conversion",
                      "read",
                      () => {
                        setMaterialBaseUnit(
                          record?.base_unit_of_measure?.unit_master_id
                        ),
                          setMaterialId(record.material_master_id),
                          handleShowConversionModal(record.material_master_id);
                      }
                    )
                  );
                },
              },
              {
                label: hasPermission("material-master", "delete") ? (
                  <Popconfirm
                    okButtonProps={{
                      loading: deleteProductLoader,
                    }}
                    description="Are you sure you want to delete?"
                    onConfirm={() =>
                      dispatch(
                        actionDeleteProductMaster(record.material_master_id)
                      )
                    }
                    className="flex items-center"
                  >
                    <span style={{ color: "#ff4d4f" }}>Delete</span>
                  </Popconfirm>
                ) : (
                  <span
                    onClick={() => {
                      dispatch(
                        handleActionWithPermission("material-master", "delete")
                      );
                    }}
                    style={{ color: "#ff4d4f" }}
                  >
                    Delete
                  </span>
                ),
                key: "Delete",
                icon: <DeleteOutlined style={{ color: "#ff4d4f" }} />,
              },
            ].filter(Boolean),
          }}
        >
          <EllipsisOutlined className="cursor-pointer" />
        </Dropdown>
      ),
    },
  ];

  useEffect(() => {
    if (materialBaseUnit) {
      conversionForm.setFieldsValue({
        to_unit_id: materialBaseUnit,
      });
    }
  }, [materialBaseUnit, conversionForm]);

  useEffect(() => {
    const trimmedSearch = search?.trim();
    if (trimmedSearch !== "" && trimmedSearch !== lastSearch) {
      const timer = setTimeout(() => {
        dispatch(
          actionGetProductsMaster({
            offset: offsetRef.current,
            limit: pageSize,
            search: trimmedSearch
          })
        );
        setLastSearch(trimmedSearch);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }

  }, [search, lastSearch]);

  const handleSearch = (e) => {
    offsetRef.current = 0;

    setSearch(e.target.value);
    if (e.target.value === "") {
      dispatch(
        actionGetProductsMaster({
          offset: offsetRef.current,
          limit: pageSize,
          search: e.target.value,
        })
      );
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;

      dispatch(
        actionGetProductsMaster({
          offset: offsetRef.current,
          limit: pageSize,
          search: e.target.value,
        })
      );
    }
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
            ? "ASC"
            : "",
    };

    dispatch(
      actionGetProductsMaster({
        offset: offsetRef.current,
        limit: pageSize,
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
  };

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: false,
    initialSlide: 0,
    draggable: true,

    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: () => setIsDragging(true),
    afterChange: () => setIsDragging(false),
  };

  const typeSettings = {
    dots: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: false,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: () => setIsDragging(true),
    afterChange: () => setIsDragging(false),
  };

  const handleUpload = async (files) => {
    const formData = new FormData();
    files.forEach((file) => formData.append("files", file));

    try {
      setUploading(true);
      const response = await axios.post(
        `${BASE_URL_UPLOAD}/upload-multiple`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("crmWebToken"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const newFiles = response.data.data.map((file) => ({
        url: file.url,
        upload_id: file.upload_id,
        file_name: file.originalname,
        size: file.size,
        file_type: file.mimetype,
      }));
      setMaterialImages((prevFiles) => [...prevFiles, ...newFiles]);
    } catch (error) {
      console.error("Upload failed:", error);
    } finally {
      setUploading(false);
    }
  };

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      await handleUpload(files);
    }
  };

  const handleRemoveMaterial = (id) => {
    const updatedMaterialImages =
      materialImages &&
      materialImages.length > 0 &&
      materialImages.filter((img) => img.upload_id !== id);
    setMaterialImages(updatedMaterialImages);
  };

  const onFinish = (req) => {
    const custom_fields = req.custom_fields
      ? transformToCustomFields(customFields, req.custom_fields)
      : [];

    const values = {
      ...req,
      material_type_id: materialType ? materialType : null,
      min_stock_level: req.min_stock_level
        ? parseInt(req.min_stock_level)
        : null,
      max_stock_level: req.max_stock_level
        ? parseInt(req.max_stock_level)
        : null,
      material_attachments:
        materialImages && materialImages.length > 0
          ? materialImages.map((att) => att.upload_id)
          : [],
      tags: tagItems.filter((tag) => tag.trim() !== ""),
      standard_weight: req.standard_weight
        ? parseInt(req.standard_weight)
        : null,
      shelf_life: req.shelf_life ? parseInt(req.shelf_life) : null,
      custom_fields:
        custom_fields.map((field) => ({
          field_name: field.field_name,
          value: field.value,
        })) || [],
    };
    materialId
      ? dispatch(
        actionUpdateProductMaster({
          materialId,
          values,
          handleCancel: handleClose,
        })
      )
      : dispatch(actionAddProductMaster({ values, handleCancel: handleClose }));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy";
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const files = Array.from(e.dataTransfer.files);
    if (files && files.length > 0) {
      handleUpload(files);
    }
  };

  // Popover and close
  const closeBaseUnitPopover = () => {
    setIsBaseUnitPopoverOpen(false);
    baseUnitForm.resetFields();
  };

  const closeStandardWeightUnitPopover = () => {
    setIsStandardWeightUnitPopoverOpen(false);
    baseUnitForm.resetFields();
  };

  const closeHsnSacPopover = () => {
    setIsHsnSacPopoverOpen(false);
    hsnSacForm.resetFields();
  };

  const closeCategoryPopover = () => {
    setIsCategoryPopoverOpen(false);
    categoryForm.resetFields();
  };

  const closeSubCategoryPopover = () => {
    setIsSubCategoryPopoverOpen(false);
    subCategoryForm.resetFields();
  };

  const closeMaterialTypePopover = () => {
    setIsMaterialTypePopoverOpen(false);
    materialTypeForm.resetFields();
  };

  // Popover content start here
  const showBaseUnitContent = (field) => {
    return (
      <Form
        form={baseUnitForm}
        onFinish={(values) =>
          dispatch(
            actionAddUnitMaster({
              values,
              handleCancel:
                field === "base_unit_master_id"
                  ? closeBaseUnitPopover
                  : closeStandardWeightUnitPopover,
              form: addForm,
              field: field,
            })
          )
        }
        layout="vertical"
        className="mt-[20px] mb-0"
      >
        <Form.Item
          name="code"
          label="Unit Code"
          className="mb-[12px]"
          size="small"
          rules={[
            {
              required: true,
              message: "Please enter unit code!",
            },
          ]}
        >
          <Input
            style={{ background: "white" }}
            placeholder="Enter Unit"
            className="!indent-1 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
          />
        </Form.Item>
        <Form.Item
          name="description"
          label="Unit Description"
          className="mb-[12px]"
          size="small"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input.TextArea
            rows={3}
            showCount
            style={{ background: "white", resize: "none" }}
            maxLength={255}
            placeholder="Enter Description"
            className="!indent-1 !pt-1 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
          />
        </Form.Item>
        <div className="flex justify-center mt-[40px]">
          <Form.Item className="mb-0">
            <Button
              onClick={
                field === "base_unit_master_id"
                  ? closeBaseUnitPopover
                  : closeStandardWeightUnitPopover
              }
              type="button"
              className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
            >
              Cancel
            </Button>
          </Form.Item>

          <div className="ml-4">
            <Form.Item className="mb-0">
              <Button
                loading={addUnitLoader}
                type="primary"
                className="root-btn min-h-[45px] min-w-[120px]"
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    );
  };

  const showHsnSacContent = () => {
    return (
      <Form
        form={hsnSacForm}
        onFinish={(values) =>
          dispatch(
            actionAddHsnSacCodeMaster({
              values,
              handleCancel: closeHsnSacPopover,
              form: addForm,
            })
          )
        }
        layout="vertical"
        className="mt-[20px] mb-0"
      >
        <Form.Item
          name="hsn_code"
          label="HSN/SAC Code"
          className="mb-[12px]"
          size="small"
          normalize={(value) => value && value.replace(/[^+\d]/g, "")}
          rules={[
            {
              required: true,
              message: "Please enter hsn/sac code!",
            },
          ]}
        >
          <Input
            style={{ background: "white" }}
            placeholder="Enter HSN/SAC Code"
            className="!indent-1 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
          />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          className="mb-[12px]"
          size="small"
        >
          <Input.TextArea
            rows={3}
            showCount
            style={{ background: "white", resize: "none" }}
            maxLength={255}
            placeholder="Enter Description"
            className="!indent-1 !pt-1 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
          />
        </Form.Item>

        <div className="flex justify-center mt-[40px]">
          <Form.Item className="mb-0">
            <Button
              onClick={closeHsnSacPopover}
              type="button"
              className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
            >
              Cancel
            </Button>
          </Form.Item>

          <div className="ml-4">
            <Form.Item className="mb-0">
              <Button
                loading={addHsnSacCodeLoader}
                type="primary"
                className="root-btn min-h-[45px] min-w-[120px]"
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    );
  };

  const showCategoryContent = () => {
    return (
      <Form
        form={categoryForm}
        onFinish={(values) =>
          dispatch(
            actionAddProductCategoryMaster({
              values,
              handleCancel: closeCategoryPopover,
              form: addForm,
            })
          )
        }
        layout="vertical"
        className="mt-[20px] mb-0"
      >
        <Form.Item
          name="category_name"
          label="Material Category Name"
          className="mb-[12px]"
          size="small"
          rules={[
            {
              required: true,
              message: "Please enter material Category Name!",
            },
          ]}
        >
          <Input
            style={{ background: "white" }}
            placeholder="Enter Material Category Name"
            className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
          />
        </Form.Item>

        <div className="flex justify-center mt-[40px]">
          <Form.Item className="mb-0">
            <Button
              onClick={closeCategoryPopover}
              type="button"
              className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
            >
              Cancel
            </Button>
          </Form.Item>

          <div className="ml-4">
            <Form.Item className="mb-0">
              <Button
                loading={addProductCategoryLoader}
                type="primary"
                className="root-btn min-h-[45px] min-w-[120px]"
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    );
  };

  const showSubCategoryContent = () => {
    return (
      <Form
        form={subCategoryForm}
        onFinish={(values) =>
          dispatch(
            actionAddProductSubCategoryMaster({
              values,
              handleCancel: closeSubCategoryPopover,
              form: addForm,
            })
          )
        }
        layout="vertical"
        className="mt-[20px] mb-0"
      >
        <Form.Item
          name="product_category_id"
          label="Product Category"
          className="mb-[12px]"
          size="small"
          rules={[
            {
              required: true,
              message: "Please select product category!",
            },
          ]}
        >
          <Select
            options={categoryOptions}
            allowClear
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            loading={getProductCategoryLoader}
            disabled={getProductCategoryLoader}
            style={{ background: "white" }}
            placeholder="Select Material Category"
            className="begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
          />
        </Form.Item>
        <Form.Item
          name="sub_category_name"
          label="Product Sub Category Name"
          className="mb-[12px]"
          size="small"
          rules={[
            {
              required: true,
              message: "Please enter product sub category name!",
            },
          ]}
        >
          <Input
            style={{ background: "white" }}
            placeholder="Enter Material Sub Category Name"
            className="!indent-1 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
          />
        </Form.Item>

        <div className="flex justify-center mt-[40px]">
          <Form.Item className="mb-0">
            <Button
              onClick={closeSubCategoryPopover}
              type="button"
              className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
            >
              Cancel
            </Button>
          </Form.Item>

          <div className="ml-4">
            <Form.Item className="mb-0">
              <Button
                loading={addProductSubCategoryLoader}
                type="primary"
                className="root-btn min-h-[45px] min-w-[120px]"
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    );
  };

  const showMaterialTypeContent = () => {
    return (
      <Form
        form={materialTypeForm}
        onFinish={(values) =>
          dispatch(
            actionAddProductTypeMaster({
              values,
              handleCancel: closeMaterialTypePopover,
              setMaterialType,
            })
          )
        }
        layout="vertical"
        className="mt-[20px] mb-0"
      >
        <Form.Item
          name="product_type"
          label="Material Type"
          className="mb-[12px]"
          size="small"
          rules={[
            {
              required: true,
              message: "Please enter material type!",
            },
          ]}
        >
          <Input
            style={{ background: "white" }}
            placeholder="Enter Material Type"
            className="!indent-1 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
          />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          className="mb-[12px]"
          size="small"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input.TextArea
            rows={3}
            showCount
            style={{ background: "white", resize: "none" }}
            maxLength={255}
            placeholder="Enter Description"
            className="!indent-1 !pt-1 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
          />
        </Form.Item>

        <div className="flex justify-center mt-[40px]">
          <Form.Item className="mb-0">
            <Button
              onClick={closeMaterialTypePopover}
              type="button"
              className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
            >
              Cancel
            </Button>
          </Form.Item>

          <div className="ml-4">
            <Form.Item className="mb-0">
              <Button
                loading={addProductTypeLoader}
                type="primary"
                className="root-btn min-h-[45px] min-w-[120px]"
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    );
  };

  const getData = async (data) => {
    const newData =
      (await data) &&
      data.length > 0 &&
      data.map((d) => ({
        "Material Name": d.material_name ? d.material_name : "-",
        "Material Type": d.material_type ? d.material_type : "-",
        "Material Description": d.material_description
          ? d.material_description
          : "-",
        "Base Unit of Measure": d?.base_unit_of_measure?.code
          ? d?.base_unit_of_measure?.code
          : "-",
        // "Material Group": d?.material_group?.name
        //   ? d?.material_group?.name
        //   : "-",
        "Material Category": d?.material_category?.category_name
          ? d?.material_category?.category_name
          : "-",
        "Material Sub Category": d?.material_category?.sub_category_name
          ? d?.material_category?.sub_category_name
          : "-",
        "Standard Weight Unit": d?.standard_weight_unit?.code
          ? d?.standard_weight_unit?.code
          : "-",
        "Material Alias": Array.isArray(d.tags) ? d.tags.join(", ") : "-",
      }));
    return newData;
  };

  const exportCurrentToExcel = async () => {
    if (!productData || (productData && productData.length === 0)) {
      return;
    }
    const data = await getData(productData);
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Materials");
    XLSX.writeFile(workbook, "materials.xlsx");
  };

  const exportAllToExcel = async () => {
    const data = await getData(productAllData);
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Materials");
    XLSX.writeFile(workbook, "materials.xlsx");
    dispatch(actionClearProductsAllData({ setExportAll }));
  };

  const onClickDownloadMenu = ({ key }) => {
    if (key === "1") {
      exportCurrentToExcel();
    } else {
      dispatch(actionGetAllProductsMaster());
      setExportAll(true);
    }
  };

  useEffect(() => {
    if (exportAll && productAllData && productAllData.length > 0) {
      exportAllToExcel();
    }
  }, [productAllData]);

  // Material Conversion start
  const handleShowConversionModal = (mID) => {
    dispatch(actionGetUnitMaster());
    dispatch(actionGetAllMaterialConversions(mID));
    setConversionModal(true);
  };

  const handleCloseConversionModal = () => {
    setConversionModal(false);
    setMaterialId(null);
    setMaterialConversionId(null);
    setMaterialBaseUnit(null);
    conversionForm.resetFields();
  };

  const fromUnit =
    unitMasterData &&
    unitMasterData.length > 0 &&
    unitMasterData.map((data) => ({
      label: data.code,
      value: data.unit_master_id,
    }));

  useEffect(() => {
    if (getSingleMaterialConversionData && materialConversionId) {
      conversionForm.setFieldsValue({
        from_unit_id:
          getSingleMaterialConversionData.from_unit_id.unit_master_id,
        to_unit_id: getSingleMaterialConversionData.to_unit_id.unit_master_id,
        conversion_rate: getSingleMaterialConversionData.conversion_rate,
        description: getSingleMaterialConversionData.description,
      });
    }
  }, [getSingleMaterialConversionData]);

  const handleAddandUpdateConversion = (req) => {
    const values = {
      ...req,
      conversion_rate: req.conversion_rate
        ? req.conversion_rate.toString()
        : null,
      material_id: materialId,
    };

    materialConversionId
      ? dispatch(
        actionUpdateMaterialConversion({
          materialConversionId,
          values,
          form: conversionForm,
          setMaterialConversionId,
        })
      )
      : dispatch(actionAddMaterialConversion({ values, form: conversionForm }));
  };

  const conversionsColumns = [
    {
      title: "Source Unit",
      dataIndex: "from_unit_id",
      key: "1",
      width: "20%",
      ellipsis: true,
      render: (text) => (
        <span className="ml-2">{isNullOrEmpty(text?.code)}</span>
      ),
    },
    {
      title: "Destination Unit",
      dataIndex: "to_unit_id",
      key: "2",
      width: "20%",
      ellipsis: true,
      render: (text) => (
        <span className="ml-2">{isNullOrEmpty(text?.code)}</span>
      ),
    },
    {
      title: "Conversion Rate",
      dataIndex: "conversion_rate",
      key: "3",
      width: "20%",
      ellipsis: true,
      render: (text) => <span className="ml-2 p-0">{isNullOrEmpty(text)}</span>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "3",
      width: "25%",

      ellipsis: true,
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          style={{ width: 200 }}
          className="ml-2"
        >
          {text || "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Action",
      key: "4",
      width: "15%",
      dataIndex: "operation",
      fixed: "right",
      render: (_, record) => {
        return (
          <div className="flex items-center ">
            <div className="flex items-center justify-center">
              <Typography.Link
                disabled={materialConversionId}
                onClick={() => {
                  dispatch(
                    handleActionWithPermission(
                      "material-conversion",
                      "update",
                      () => {
                        setMaterialConversionId(record.material_conversion_id);
                        dispatch(
                          actionGetSingleMaterialConversion({
                            materialId,
                            id: record.material_conversion_id,
                          })
                        );
                      }
                    )
                  );
                }}
              >
                <img src={editevent} alt="" />
              </Typography.Link>
            </div>
            <div className="ml-3">
              {hasPermission("material-conversion", "delete") ? (
                <Popconfirm
                  okButtonProps={{
                    loading:
                      deleteMaterialConversionLoader[
                      record.material_conversion_id
                      ],
                  }}
                  description="Are you sure you want to delete?"
                  onConfirm={() =>
                    dispatch(
                      actionDeleteMaterialConversion({
                        materialId,
                        id: record.material_conversion_id,
                      })
                    )
                  }
                >
                  <Typography.Link
                    disabled={materialConversionId}
                    onClick={(e) => {
                      if (materialConversionId) e.preventDefault();
                    }}
                  >
                    <img
                      src={deletevent}
                      alt=""
                      className={`cursor-pointer ${materialConversionId ? " pointer-events-none" : ""
                        }`}
                    />
                  </Typography.Link>
                </Popconfirm>
              ) : (
                <img
                  src={deletevent}
                  alt=""
                  className="cursor-pointer"
                  onClick={() => {
                    dispatch(
                      handleActionWithPermission(
                        "material-conversion",
                        "delete"
                      )
                    );
                  }}
                />
              )}
            </div>
          </div>
        );
      },
    },
  ];
  // Material Conversion end

  // material alias start

  const handleInputChange = (index, value) => {
    const updatedFields = [...tagItems];
    updatedFields[index] = value;
    setTagItems(updatedFields);
  };

  const handleAddField = () => {
    const lastInput = tagItems[tagItems.length - 1];
    if (lastInput.trim()) {
      setTagItems([...tagItems, ""]);
    }
  };

  const handleRemoveField = (index) => {
    const updatedFields = [...tagItems];
    updatedFields.splice(index, 1);
    setTagItems(updatedFields);
  };

  const handleApplyFilters = (values) => {
    const customFieldNameMapping = customFields.reduce((acc, field) => {
      acc[field.custom_field_id] = field.field_name;
      return acc;
    }, {});

    const customFieldParams = customFields
      .filter((field) =>
        ["Checkbox", "Dropdown", "MultiSelect"].includes(field.field_type)
      )
      .reduce((acc, field) => {
        const fieldName = customFieldNameMapping[field.custom_field_id];
        const fieldValue = values[field.custom_field_id] || [];

        if (fieldValue.length > 0) {
          acc[fieldName] = fieldValue;
        }
        return acc;
      }, {});


    const appliedFilters = {
      ...values,
    };
    const appliedFilterCount = Object.values(appliedFilters).filter((value) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value;
    }).length;

    setFilterValues(appliedFilterCount);

    dispatch(
      actionGetProductsMaster({
        offset: offsetRef.current,
        limit: pageSize,
        search: values.search || "",
        material_category_id: values.material_category_id,
        material_sub_category_id: values.material_sub_category_id,
        material_type_id: values.material_type_id,
        customFieldParams,
        // onFilterModalClose,
        // form: filterForm,
      })
    );
    setIsFilterModalOpen(false);
  };

  const handleOpenFilterModal = () => {
    setIsFilterModalOpen(true);
    setActiveKey([]);

    if (productCategoryData && productCategoryData.length === 0) {
      dispatch(actionGetProductCategoryMaster());
    }
    if (allProductSubCategoryData && allProductSubCategoryData.length === 0) {
      dispatch(actionGetAllProductSubCategoryMaster());
    }
    if (productTypeData && productTypeData.length === 0) {
      dispatch(actionGetProductTypeMaster());
    }
    if (customFields && customFields.length === 0) {
      dispatch(
        actionGetCustomFields({
          module_name: "Material",
        })
      );
    }
  };

  const handleReset = () => {
    filterForm.resetFields();
    setIsFilterModalOpen(false);
    dispatch(actionGetProductsMaster({
      offset: offsetRef.current,
      limit: pageSize,
    }));
    setFilterValues("");
  };
  const handlePaginationChange = (page, pageSize) => {
    offsetRef.current = page - 1;
    setPageSize(pageSize);
    dispatch(
      actionGetProductsMaster({
        offset: offsetRef.current,
        limit: pageSize,
        search: search,
      })
    );
  };
  return (
    <>
      <div className="event-input ">
        <Modal
          loading={getSingleProductLoader || getCustomFieldsLoader}
          centered
          destroyOnClose={handleClose}
          afterClose={() => {
            addForm.resetFields();
            setMaterialImages([]);
            setMaterialType(null);
            setMaterialId(null);
            setTagItems([""]);
          }}
          onOk={handleClose}
          onCancel={handleClose}
          footer={false}
          width={1200}
          title=""
          open={isModalOpen}
        >
          <div className="flex">
            <img src={productImage} alt="" />
            <h1 className="text-[#6883FD] text-[20px]  ml-[8px] mt-[5px] font-medium">
              Add Material
            </h1>
          </div>
          <Form
            form={addForm}
            onFinish={onFinish}
            scrollToFirstError={true}
            layout="vertical"
            className="mt-[20px] px-1 mb-0"
          >
            <Row gutter={16}>
              <Col span={9}>
                <Card
                  className=" my-5"
                  title={
                    <h1 className="font-popinsSemiBold">Material Image</h1>
                  }
                >
                  <div className="slider-container">
                    <Slider {...settings} className="material-slider">
                      <div
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        className="mr-3 rounded-xl min-w-40 max-w-40 h-[150px] cursor-pointer border border-dashed flex items-center justify-center border-[#6883FD]"
                      >
                        {uploading ? (
                          <div className="flex gap-x-2 items-center justify-center h-full w-full">
                            <LoadingOutlined
                              height={18}
                              width={18}
                              color="#7C7C7C"
                            />
                            <p className="text-sm font-popinsRegular text-[#000]">
                              Uploading...
                            </p>
                          </div>
                        ) : (
                          <label
                            htmlFor="file-upload"
                            className="h-full w-full"
                          >
                            <div className="flex items-center  cursor-pointer h-full gap-x-5 px-[13px] justify-center">
                              <Image
                                src={gallery}
                                preview={false}
                                width={50}
                                height={50}
                                alt="Gallery"
                              />
                              <p className="text-left text-[11px]">
                                Drop your file here or{" "}
                                <span className="text-brand font-semibold">
                                  Click to Browse
                                </span>
                              </p>
                            </div>
                          </label>
                        )}
                      </div>
                      <input
                        id="file-upload"
                        type="file"
                        hidden
                        multiple
                        onChange={handleFileChange}
                        className="!hidden"
                        style={{ display: "none" }}
                        accept=".jpeg,.jpg,.png,.gif,.pdf,.doc"
                      />

                      {materialImages &&
                        materialImages.length > 0 &&
                        materialImages.map((data, index) => (
                          <div
                            key={index}
                            className={`mr-3 group rounded-xl relative border border-gray min-w-40 max-w-40 h-[150px] cursor-pointer`}
                            onClick={() => (isDragging ? "" : "")}
                            style={{
                              cursor: isDragging ? "grabbing" : "grab",
                            }}
                          >
                            <img
                              className="rounded-xl object-cover h-full w-full"
                              src={data.url}
                              alt="material-image"
                            />
                            <div
                              onClick={() =>
                                handleRemoveMaterial(data.upload_id)
                              }
                              className="bg-red-500 cursor-pointer absolute top-0 right-0 w-[30px] rounded-tr-[9px] rounded-bl-xl h-[30px] flex items-center justify-center"
                            >
                              <IoMdClose color="white" size={20} />
                            </div>
                          </div>
                        ))}
                    </Slider>
                  </div>
                </Card>
              </Col>
              <Col span={15}>
                <Card
                  className="my-5 min-h-[260px]"
                  title={
                    <div className="flex items-center justify-between">
                      <h1 className="">Material Type</h1>
                      <AddPopover
                        content={showMaterialTypeContent}
                        isOpen={isMaterialTypePopoverOpen}
                        onOpenChange={(visible) => {
                          if (!visible) {
                            setIsMaterialTypePopoverOpen(false);
                            materialTypeForm.resetFields();
                          }
                        }}
                        text="Add Type"
                        showModal={() =>
                          setIsMaterialTypePopoverOpen(
                            !isMaterialTypePopoverOpen
                          )
                        }
                      />
                    </div>
                  }
                >
                  <div className="slider-container">
                    {productTypeData && productTypeData.length > 0 ? (
                      <Slider {...typeSettings}>
                        {productTypeData.map((data, index) => (
                          <div key={index} className="py-3">
                            <div
                              className={`p-3 mr-3 rounded-xl border ${materialType === data.product_type_master_id
                                ? "border-[#6883FD] bg-custom-gradient"
                                : "border-[#DDDAE9] bg-[#FCFBFD]"
                                } min-w-90 max-w-90 min-h-24 cursor-pointer`}
                              onClick={() =>
                                isDragging
                                  ? ""
                                  : setMaterialType((prev) =>
                                    prev === data.product_type_master_id
                                      ? null
                                      : data.product_type_master_id
                                  )
                              }
                              style={{
                                cursor: isDragging ? "grabbing" : "grab",
                              }}
                            >
                              <h3 className="font-popinsMedium">
                                {data.product_type}
                              </h3>
                              <Typography.Paragraph
                                ellipsis={{ rows: 2 }}
                                className="font-popinsRegular text-xs mt-1"
                              >
                                {data.description}
                              </Typography.Paragraph>
                            </div>
                          </div>
                        ))}
                      </Slider>
                    ) : (
                      <Empty description="No Material Type found" />
                    )}
                  </div>
                </Card>
              </Col>

              <Col span={24}>
                <Card
                  title={
                    <h1 className="font-popinsSemiBold">
                      Material Information
                    </h1>
                  }
                >
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please enter material name!",
                          },
                        ]}
                        label="Material Name"
                        name="material_name"
                      >
                        <Input placeholder="Enter Material Name" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="ERP Code" name="erp_code">
                        <Input placeholder="Enter ERP Code" />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item label="Material Code" name="material_code">
                        <Input placeholder="Enter Material Code" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        normalize={(value) =>
                          value && value.replace(/[^+\d]/g, "")
                        }
                        label="Price"
                        name="price"
                      >
                        <Input placeholder="Enter Price" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        normalize={(value) =>
                          value && value.replace(/[^+\d]/g, "")
                        }
                        label="Min Stock Level"
                        name="min_stock_level"
                      >
                        <Input
                          className="suffix-css"
                          placeholder="Enter Min Stock Level"
                          addonAfter={"Base Unit"}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        normalize={(value) =>
                          value && value.replace(/[^+\d]/g, "")
                        }
                        label="Max Stock Level"
                        name="max_stock_level"
                      >
                        <Input
                          placeholder="Enter Max Stock Level"
                          className="suffix-css"
                          addonAfter={"Base Unit"}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <AddPopover
                        content={showCategoryContent}
                        isOpen={isCategoryPopoverOpen}
                        onOpenChange={(visible) => {
                          if (!visible) {
                            setIsCategoryPopoverOpen(false);
                            categoryForm.resetFields();
                          }
                        }}
                        text="Add Category"
                        showModal={() =>
                          setIsCategoryPopoverOpen(!isCategoryPopoverOpen)
                        }
                      />
                      <Form.Item label="Category" name="material_category_id">
                        <Select
                          allowClear
                          showSearch
                          loading={getProductCategoryLoader}
                          disabled={getProductCategoryLoader}
                          placeholder="Select Category"
                          optionFilterProp="children"
                          className=""
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={categoryOptions}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <AddPopover
                        content={showSubCategoryContent}
                        isOpen={isSubCategoryPopoverOpen}
                        onOpenChange={(visible) => {
                          if (!visible) {
                            setIsSubCategoryPopoverOpen(false);
                            subCategoryForm.resetFields();
                          }
                        }}
                        text="Add Sub Category"
                        showModal={() =>
                          setIsSubCategoryPopoverOpen(!isSubCategoryPopoverOpen)
                        }
                      />

                      <Form.Item
                        label="Sub Category"
                        name="material_sub_category_id"
                      >
                        <Select
                          allowClear
                          showSearch
                          loading={getAllProductSubCategoryLoader}
                          disabled={getAllProductSubCategoryLoader}
                          placeholder="Select Sub Category"
                          optionFilterProp="children"
                          className=""
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={subCategoryOptions}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <AddPopover
                        content={() =>
                          showBaseUnitContent("base_unit_master_id")
                        }
                        isOpen={isBaseUnitPopoverOpen}
                        onOpenChange={(visible) => {
                          if (!visible) {
                            setIsBaseUnitPopoverOpen(false);
                          }
                        }}
                        showModal={() =>
                          setIsBaseUnitPopoverOpen(!isBaseUnitPopoverOpen)
                        }
                        text="Add Base Unit"
                      />

                      <Form.Item
                        label="Base Unit of Measure"
                        name="base_unit_master_id"
                      >
                        <Select
                          allowClear
                          showSearch
                          loading={getUnitMasterLoader}
                          disabled={getUnitMasterLoader}
                          placeholder="Select Base Unit"
                          optionFilterProp="children"
                          className=""
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={baseUnitptions}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Standard Weight" name="standard_weight">
                        <Input placeholder="Standard Weight" />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <AddPopover
                        content={() =>
                          showBaseUnitContent("standard_weight_unit_master_id")
                        }
                        isOpen={isStandardWeightUnitPopoverOpen}
                        onOpenChange={(visible) => {
                          if (!visible) {
                            setIsStandardWeightUnitPopoverOpen(false);
                            hsnSacForm.resetFields();
                          }
                        }}
                        showModal={() =>
                          setIsStandardWeightUnitPopoverOpen((prev) => !prev)
                        }
                        text="Add Standard Weight Unit"
                      />

                      <Form.Item
                        label="Standard Weight Unit"
                        name="standard_weight_unit_master_id"
                      >
                        <Select
                          allowClear
                          showSearch
                          loading={getUnitMasterLoader}
                          disabled={getUnitMasterLoader}
                          placeholder="Select Standard Weight Unit"
                          optionFilterProp="children"
                          className=""
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={baseUnitptions}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <AddPopover
                        content={showHsnSacContent}
                        isOpen={isHsnSacPopoverOpen}
                        onOpenChange={(visible) => {
                          if (!visible) {
                            setIsHsnSacPopoverOpen(false);
                            hsnSacForm.resetFields();
                          }
                        }}
                        showModal={() =>
                          setIsHsnSacPopoverOpen((prev) => !prev)
                        }
                        text="Add HSN/SAC Code"
                      />

                      <Form.Item label="HSN/SAC Code" name="hsn_code_master_id">
                        <Select
                          allowClear
                          showSearch
                          loading={getAllHsnSacLoader}
                          disabled={getAllHsnSacLoader}
                          placeholder="Select HSN/SAC Code"
                          optionFilterProp="children"
                          className=""
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={hsnSacOptions}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item label="Batch required" name="batch_required">
                        <Radio.Group className="border rounded-[10px] justify-between  border-[#DDDAE9] w-full flex">
                          <Radio
                            value="Yes"
                            className=" w-1/2  py-3 border-r m-0 border-[#DDDAE9]  flex items-center justify-center"
                          >
                            Yes
                          </Radio>
                          <Radio
                            value="No"
                            className=" w-1/2 py-3 m-0  flex items-center justify-center"
                          >
                            No
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Shelf Life" name="shelf_life">
                        <Input
                          style={{ width: "100%" }}
                          placeholder="Enter Shelf Life"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        label="Shelf Life period indicator"
                        name="shelf_life_period"
                      >
                        <Radio.Group size="large" buttonStyle="solid">
                          <Radio.Button value="Days">Days</Radio.Button>
                          <Radio.Button value="Weeks">Weeks</Radio.Button>
                          <Radio.Button value="Months">Months</Radio.Button>
                          <Radio.Button value="Years">Years</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        label="Description"
                        name="material_description"
                      >
                        {/*  <Input.TextArea
                              rows={5}
                              placeholder="Enter Description"
                            /> */}

                        <Input.TextArea
                          rows={3}
                          showCount
                          style={{ background: "white", resize: "none" }}
                          maxLength={255}
                          placeholder="Enter Description"
                          className="!indent-1 !pt-1 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={
                          <p>
                            Material Alias{" "}
                            <span className="text-red-500">(Upto Five)</span>
                          </p>
                        }
                        name="tags"
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                          }}
                        >
                          {tagItems.map((field, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                                marginBottom: "8px",
                              }}
                            >
                              <Input
                                value={field}
                                onChange={(e) =>
                                  handleInputChange(index, e.target.value)
                                }
                                placeholder="Enter Material Alias"
                                style={{ flex: 1 }}
                              />
                              {field.trim() && tagItems.length > 1 && (
                                <div onClick={() => handleRemoveField(index)}>
                                  <MinusCircleOutlined
                                    className="cursor-pointer"
                                    style={{
                                      fontSize: "20px",
                                      color: "red",
                                    }}
                                  />
                                </div>
                              )}
                              {index === tagItems.length - 1 &&
                                tagItems.length < 5 &&
                                field.trim() && (
                                  <div
                                    onClick={handleAddField}
                                    className="cursor-pointer"
                                  >
                                    <PlusCircleOutlined
                                      style={{
                                        fontSize: "20px",
                                        color: "green",
                                      }}
                                    />
                                  </div>
                                )}
                            </div>
                          ))}
                        </div>
                      </Form.Item>
                    </Col>
                    <CustomFormFieldsComp
                      data={customFields}
                      id={materialId}
                      form={addForm}
                    />
                  </Row>
                </Card>
              </Col>
            </Row>
            <div className="flex justify-end  mt-[40px]">
              <Button
                onClick={handleClose}
                type="button"
                className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
              >
                Cancel
              </Button>
              <div className="ml-4">
                <Form.Item className="mb-0">
                  <Button
                    loading={addProductLoader || updateProductLoader}
                    type="primary"
                    className="root-btn min-h-[45px] min-w-[120px]"
                    htmlType="submit"
                  >
                    {materialId ? "Update" : "Save"}
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </Modal>

        <section className="main-wrapper  main-table-container ">
            <div className="pt-[25px] pb-[20px]">
              <CommonTable
                moduleKey="material-master"
                title="Materials"
                total={productDataCount}
                pageSize={pageSize}
                pageTitle={<h1 className="text-[#6883FD] text-[20px] font-medium">Materials</h1>}
                buttonText="Add Material"
                onAddClick={showModal}
                type="btn"
                resource="material-master"
                loading={getProductsLoader || getAllProductsLoader}
                columns={columns}
                scrollToFirstRowOnChange={true}
                data={(productData || [])}
                rowKey={"material_master_id"}
                onChange={handleSortChange}
                isAdvanceFilter={true}
                filterValues={filterValues}
                handleSearch={handleSearch}
                onClickFilter={handleOpenFilterModal}
                handleBlurAndPressEnter={handleBlurAndPressEnter}
                search={search}
                importRoute="/master-data/material-import"
                isImportEnabled={true}
                onClickDownloadMenu={onClickDownloadMenu}
                handlePaginationChange={handlePaginationChange}
              />
            </div>
        </section>
      </div>
      <Modal
        centered
        onOk={() => handleCloseConversionModal()}
        onCancel={() => handleCloseConversionModal()}
        footer={false}
        width={800}
        title=""
        open={conversionModal}
      >
        <div className="flex">
          <img src={materialConversionIcon} alt="" />
          <h1 className="text-[#6883FD] text-[20px] ml-[8px] mt-[5px] font-medium">
          Material Conversions
          </h1>
        </div>
        <Spin spinning={getSingleMaterialConversionLoader}>
          <Form
            form={conversionForm}
            onFinish={handleAddandUpdateConversion}
            layout="vertical"
            className="mt-[20px] mb-0"
            initialValues={{
              to_unit_id: materialBaseUnit ? materialBaseUnit : null,
            }}
          >
            <Row gutter={10}>
              <Col span={8}>
                <Form.Item
                  name="from_unit_id"
                  label="From Unit"
                  className="mb-[12px]"
                  size="small"
                  rules={[
                    {
                      required: true,
                      message: "Please select from unit!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select From Unit"
                    optionFilterProp="children"
                    className=""
                    loading={getUnitMasterLoader}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={fromUnit}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="to_unit_id"
                  label="To Unit"
                  className="mb-[12px]"
                  size="small"
                  rules={[
                    {
                      required: true,
                      message: "Please select to unit!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select To Unit"
                    optionFilterProp="children"
                    className=""
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    disabled={materialBaseUnit ? true : false}
                    loading={getUnitMasterLoader}
                    options={fromUnit}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="conversion_rate"
                  label="Conversion Rate"
                  className="mb-[12px]"
                  size="small"
                  normalize={(value) =>
                    value &&
                    value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1")
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please enter conversion rate!",
                    },
                  ]}
                >
                  <Input
                    style={{ background: "white" }}
                    placeholder="Enter Conversion Rate"
                    className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col span={12}>
                <Form.Item
                  name="description"
                  label="Description"
                  className="mb-[12px]"
                  size="small"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input.TextArea
                    rows={1}
                    placeholder="Enter Description"
                    className="!indent-1 begin-input !bg-white focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>
              <Col span={24} className="flex justify-end gap-x-2">
                {materialConversionId && (
                  <Form.Item>
                    <Button
                      onClick={() => {
                        conversionForm.resetFields();
                        setMaterialConversionId(null);
                      }}
                      type="button"
                      className="gray-button text-white hover:text-white min-h-[45px] min-w-[100px]"
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                )}

                <Form.Item>
                  <Button
                    loading={
                      addMaterialConversionLoader ||
                      updateMaterialConversionLoader
                    }
                    disabled={
                      addMaterialConversionLoader ||
                      updateMaterialConversionLoader
                    }
                    type="primary"
                    className="root-btn min-h-[45px] min-w-[100px]"
                    htmlType="submit"
                  >
                    {materialConversionId ? "Update" : "Add"}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
        <div className="flex">
          <img src={materialConversionIcon} alt="" />
          <h1 className="text-[#6883FD] text-[20px] ml-[8px] mt-[5px] font-medium">
            Conversions
          </h1>
        </div>
        <Table
          className="mt-2 material-conversion-table"
          scroll={{ y: 500, x: "max-content" }}
          pagination={false}
          loading={getAllMaterialConversionsLoader}
          bordered
          dataSource={materialConversionsAllData}
          rowKey="material_conversion_id"
          columns={conversionsColumns}
          rowClassName="editable-row"
        />
      </Modal>
      {/* Material Conversion end */}

      <Modal
        className="ant-custom-scroll"
        centered
        onOk={onFilterModalClose}
        title={
          <h1 className="text-[#6883FD] text-[20px] mt-[5px] font-medium">
            Filters
          </h1>
        }
        onCancel={onFilterModalClose}
        footer={
          <div className="flex justify-center mt-[40px]">
            <Form.Item className="mb-0">
              <Button
                type="button"
                onClick={onFilterModalClose}
                className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  type="primary"
                  // loading={getProductsLoader}
                  className="root-btn min-h-[45px] min-w-[120px]"
                  onClick={() => filterForm.submit()}
                >
                  Apply
                </Button>
              </Form.Item>
            </div>
            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  type="default"
                  className="root-btn min-h-[45px] min-w-[120px]"
                  /*  onClick={() => {
                    filterForm.resetFields();
                    setFilterValues(0);
                  }} */

                  onClick={handleReset}
                >
                  Reset
                </Button>
              </Form.Item>
            </div>
          </div>
        }
        width={500}
        open={isFilterModalOpen}
        loading={
          getCustomFieldsLoader ||
          getProductCategoryLoader ||
          getAllProductSubCategoryLoader ||
          getProductTypeLoader
        }
      >
        <div className="custom-scroll-barr">
          <Form
            form={filterForm}
            onFinish={handleApplyFilters}
            layout="vertical"
            className="mt-[20px] mb-0 product-container-form"
          >
            <Collapse
              ghost
              className="collaps-padding"
              activeKey={activeKey}
              onChange={(key) => setActiveKey(key)}
            >
              <Collapse.Panel header="Material Category" key="1">
                <Form.Item name="material_category_id">
                  <Checkbox.Group
                    options={categoryOptions}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px 0 ",
                    }}
                  />
                </Form.Item>
              </Collapse.Panel>

              <Collapse.Panel header="Material Sub Category" key="2">
                <Form.Item name="material_sub_category_id">
                  <Checkbox.Group
                    options={subCategoryOptions}
                    style={{ display: "flex", flexDirection: "column" }}
                  />
                </Form.Item>
              </Collapse.Panel>

              {/* Material Type */}
              <Collapse.Panel header="Material Type" key="3">
                <Form.Item name="material_type_id">
                  <Checkbox.Group
                    options={materialTypeOptions}
                    style={{ display: "flex", flexDirection: "column" }}
                  />
                </Form.Item>
              </Collapse.Panel>

              {/* Custom Fields */}
              {Array.isArray(customFields) &&
                customFields.some(
                  (item) =>
                    item.field_type === "Checkbox" ||
                    item.field_type === "Dropdown" ||
                    item.field_type === "MultiSelect"
                ) &&
                customFields.map(
                  (item) =>
                    (item.field_type === "Checkbox" ||
                      item.field_type === "Dropdown" ||
                      item.field_type === "MultiSelect") && (
                      <Collapse.Panel
                        header={item.label || item.field_name}
                        key={item.custom_field_id}
                      >
                        <Form.Item name={item.custom_field_id}>
                          <Checkbox.Group
                            options={item.field_options?.map((option) => ({
                              label: option,
                              value: option,
                            }))}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          />
                        </Form.Item>
                      </Collapse.Panel>
                    )
                )}
            </Collapse>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default Material;
