import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Grid,
  Input,
  Radio,
  Select,
  Skeleton,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import { HiOutlineLockClosed } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import {
  actionAddCustomField,
  actionEditCustomField,
  actionGetCustomField,
} from "../../../store/services/customFieldService";
import PhoneInputComponent from "../../common/PhoneInputComponent";
// import { actionChangePassword } from "../../store/services/authService";

const { useBreakpoint } = Grid;

const fieldTypeOptions = [
  "Text",
  "TextArea",
  "Phone",
  "Number",
  "Date",
  "Checkbox",
  "URL",
  "Email",
  "Dropdown",
  "MultiSelect",
];

const renderFieldInput = (type, options = []) => {
  if (typeof options === "string") {
    options = options.split(",");
  } else if (Array.isArray(options)) {
    options = options.join(",");
  } else if (typeof options === "number") {
    options = options.toString();
  } else {
    options = "";
  }

  switch (type) {
    case "Text":
      return (
        <Input
          readOnly
          placeholder="Enter text"
          value={""}
          className="rounded-md"
        />
      );
    case "Phone":
      return (
        <PhoneInputComponent
          disabled={true}
          value={""}
          onChange={() => {}}
          setIsValid={() => {}}
          isValid={true}
          setValue={() => {}}
        />
      );
    case "URL":
      return (
        <Input
          value={""}
          readOnly
          placeholder="Enter URL"
          className="rounded-md"
        />
      );
    case "Email":
      return (
        <Input
          value={""}
          readOnly
          placeholder="Enter email"
          className="rounded-md"
        />
      );
    case "Number":
      return (
        <Input
          value={0}
          readOnly
          type="number"
          placeholder="Enter number"
          className="rounded-md"
        />
      );
    case "TextArea":
      return (
        <Input.TextArea
          value={""}
          readOnly
          rows={4}
          placeholder="Enter detailed text"
          className="rounded-md"
        />
      );
    case "Date":
      return (
        <DatePicker
          value={""}
          readOnly
          placeholder="Select date"
          className="w-full rounded-md"
        />
      );
    case "Checkbox":
      return options && options.length > 0 ? (
        <Checkbox.Group className="w-full">
          {options.map((option, index) => (
            <Checkbox key={index} value={option}>
              {option}
            </Checkbox>
          ))}
        </Checkbox.Group>
      ) : (
        <Checkbox>Checkbox</Checkbox>
      );

    case "Dropdown":
      return (
        <Select allowClear className="w-full" placeholder="Select an option">
          {options && options.length > 0
            ? options.map((option, index) => (
                <Select.Option key={index} value={option}>
                  {option}
                </Select.Option>
              ))
            : "No Options Found"}
        </Select>
      );
    case "MultiSelect":
      return (
        <Select
          allowClear
          className="w-full"
          mode="multiple"
          placeholder="Select multiple options"
        >
          {options &&
            options.length > 0 &&
            options.map((option, index) => (
              <Select.Option key={index} value={option}>
                {option}
              </Select.Option>
            ))}
        </Select>
      );
    default:
      return null;
  }
};

function AddCustomField({ isModalOpen, editId, handleCancel, form, itemKey }) {
  const { lg } = useBreakpoint();

  const dispatch = useDispatch();
  const [fieldType, setFieldType] = useState(null);

  const {
    addCustomFieldLoader,
    getCustomFieldDetailLoader,
    getCustomFieldDetails,
    editCustomFieldLoader,
  } = useSelector((state) => state.customField);
  const labelField = Form.useWatch(
    (values) => `${values.label || "Label"}`,
    form
  );

  const optionsField = Form.useWatch(
    (values) => `${values.field_options || []}`,
    form
  );

  useEffect(() => {
    if (!isModalOpen) {
      form.resetFields();
      handleCancel();
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (editId) {
      dispatch(actionGetCustomField(editId));
    }
  }, [editId]);

  useEffect(() => {
    if (getCustomFieldDetails && editId) {
      form.setFieldsValue({
        module_name: getCustomFieldDetails.module_name,
        label: getCustomFieldDetails.label,
        field_type: getCustomFieldDetails.field_type,
        help_text: getCustomFieldDetails.help_text,
        field_options: getCustomFieldDetails.field_options,
        is_required: getCustomFieldDetails.is_required,
      });
      setFieldType(getCustomFieldDetails.field_type);
    }
  }, [getCustomFieldDetails]);

  const onFinish = (values) => {
    if (["Dropdown", "Checkbox", "MultiSelect"].includes(values.field_type)) {
      const options = values.field_options ?? [];

      const filteredOptions = options.filter((option) => option?.trim());

      if (filteredOptions.length === 0) {
        toast.error("Please enter at least one valid option!");
        return false;
      }

      values.field_options = filteredOptions;
    }

    const req = {
      ...values,
      module_name: itemKey,
    };

    
    editId
      ? dispatch(
          actionEditCustomField({
            editId,
            values: req,
            form,
            setFieldType,
            handleCancel,
          })
        )
      : dispatch(
          actionAddCustomField({
            values: req,
            form,
            setFieldType,
            handleCancel,
          })
        );
  };

  useEffect(() => {
    if (
      fieldType === "Dropdown" ||
      fieldType === "MultiSelect" ||
      fieldType === "Checkbox"
    ) {
      const currentOptions = form.getFieldValue("field_options") || [];
      if (currentOptions.length === 0) {
        form.setFieldsValue({ field_options: [""] });
      }
    } else {
      form.setFieldsValue({ field_options: [] });
    }
  }, [fieldType]);

  return (
    <Skeleton paragraph={6} loading={getCustomFieldDetailLoader}>
      <div className="custom-fields-container">
        <div className="title mb-4 pt-4 flex flex-col justify-center items-center gap-y-2.5">
          <p className="xl:text-[25px] text-[20px]  text-zinc-700 font-popinsSemiBold">
            New Custom Field
          </p>
        </div>
        <Form
          labelCol={{
            span: 7,
          }}
          labelAlign="left"
          form={form}
          layout={lg ? "horizontal" : "vertical"}
          onFinish={onFinish}
          className="space-y-6 px-4 pt-5"
        >
          <Form.Item
            label="Label"
            name="label"
            rules={[{ required: true, message: "Please enter the label!" }]}
            normalize={(value) => {
              if (value && !/^[a-zA-Z]/.test(value)) {
                return value.replace(/^[^a-zA-Z]+/, "");
              }
              return value;
            }}
          >
            <Input placeholder="Enter Label" />
          </Form.Item>

          <Form.Item
            label="Field Type"
            name="field_type"
            normalize={(value) => {
              if (value && !/^[a-zA-Z]/.test(value)) {
                return value.replace(/^[^a-zA-Z]+/, "");
              }
              return value;
            }}
            rules={[
              { required: true, message: "Please select the field type!" },
            ]}
          >
            <Select
              optionFilterProp="children"
              showSearch
              placeholder="Select Field Type"
              onChange={(value) => setFieldType(value)}
            >
              {fieldTypeOptions.map((option) => (
                <Select.Option key={option} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {(fieldType === "Dropdown" ||
            fieldType === "MultiSelect" ||
            fieldType === "Checkbox") && (
            <Form.Item
              rules={[
                {
                  required: true,
                  validator: async (_, options) => {
                    const nonEmptyOptions = options.filter(
                      (option) => option && option.trim()
                    );

                    if (nonEmptyOptions.length === 0) {
                      return Promise.reject(
                        new Error("Please enter at least one option.")
                      );
                    }
                  },
                },
              ]}
              label="Options"
              className="ml-2 remove-scrollbar custom-options-label"
            >
              <Form.List name="field_options">
                {(fields, { add, remove }) => (
                  <>
                    <Card
                      title={`Options Count :  ${fields?.length}`}
                      className=" remove-scrollbar options-card border border-neutralGray overflow-y-auto overflow-x-hidden rounded-md"
                    >
                      {fields.map((field, index) => (
                        <Space
                          key={field.key}
                          style={{ width: "280px" }}
                          align="baseline"
                          className={`${
                            index === fields.length - 1 ? "mb-0" : "mb-3"
                          } flex items-center `}
                        >
                          <Form.Item
                            className="!mb-0"
                            {...field}
                            validateTrigger={["onChange", "onBlur"]}
                            noStyle
                            rules={[
                              {
                                validator: (_, value) => {
                                  // Collect all current field values from the form
                                  const currentValues = fields.map((f) =>
                                    form.getFieldValue([
                                      "field_options",
                                      f.name,
                                    ])
                                  );

                                  // Check if the value is a duplicate in the list
                                  const isDuplicate =
                                    value &&
                                    currentValues.filter((v) => v === value)
                                      .length > 1;

                                  if (isDuplicate) {
                                    return Promise.reject(
                                      new Error("Options must be unique!")
                                    );
                                  }

                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <Input
                              placeholder="Enter option "
                              style={{ width: "250px", minHeight: 35 }}
                            />
                          </Form.Item>
                          {fields.length > 1 && (
                            <MinusCircleOutlined
                              className="text-red-500 cursor-pointer"
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          )}
                        </Space>
                      ))}
                    </Card>
                    <Button
                      className="mt-5 !rounded-sm"
                      type="dashed"
                      htmlType="button"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Add option
                    </Button>
                  </>
                )}
              </Form.List>
            </Form.Item>
          )}

          <Form.Item
            tooltip="Enter some text to help users understand the purpose of this custom field.
  "
            label="Help Text"
            name="help_text"
          >
            <Input placeholder="Enter Help Text" />
          </Form.Item>

          <Form.Item
            label="Is Mandatory"
            name="is_required"
            initialValue={true}
          >
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          {fieldType && (
            <>
              <Divider className="font-popinsSemiBold">Field Preview</Divider>

              <div className="border border-neutralGray p-4 flex items-center  rounded-md bg-gray-50">
                <Form.Item
                  className="!mb-0 w-full"
                  label={labelField}
                  labelCol={{
                    span: 7,
                  }}
                  name="preview_field"
                  tooltip={form.getFieldValue("help_text")}
                >
                  {renderFieldInput(fieldType, optionsField)}
                </Form.Item>
              </div>
            </>
          )}
        </Form>

        <div className="flex justify-start space-x-4 pl-4 pt-4">
          <Form.Item>
            <Button
              onClick={() => form.submit()}
              loading={addCustomFieldLoader || editCustomFieldLoader}
              type="primary"
              className="root-btn min-h-[45px] min-w-[120px]"
              htmlType="button"
            >
              Save
            </Button>
          </Form.Item>
        </div>
      </div>
    </Skeleton>
  );
}

export default AddCustomField;
