import React from "react";
import { getFirstLetterCapital } from "../helper/common-function";
import { Image, Skeleton } from "antd";

const SkeletonViewModal = ({ length = 12 }) => {
  const avatarProps = {
    active: true,
    size: 100,
    shape: "square",
    className: "rounded-[10px]",
    preview: true,
  };

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex items-center pt-5 w-full  gap-x-4">
        {/* Show Skeleton if loading, else show Image */}

        <Skeleton.Avatar className="rounded-md" {...avatarProps} />

        <div className="flex justify-start flex-col gap-y-1">
          <Skeleton
            paragraph={{ rows: 1 }}
            active
            size="small"
            style={{ width: 400 }}
          />
        </div>
      </div>
      {Array.from({ length: length }).map((_, index) => (
        <div key={index} className="flex items-center gap-x-4 w-full skeleten-div">
          <Skeleton.Input
            active
            size="small"
            className="skeleton-first-input"
            style={{ height: "19px" }}
          />
          <Skeleton.Input
            active
            size="small"
            className="skeleton-last-input"
            style={{ height: "19px", }}
          />
        </div>
      ))}
    </div>
  );
};

export default SkeletonViewModal;
