import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { closeHPINModal } from "../../store/slices/authSlice";
import otpIcon from "../../assets/img/otpIcon.svg";
import { Button, Form, Input, Modal } from 'antd';
import { actionSendOTPforHPIN, actionSetHPIN } from '../../store/services/authService';
import OtpInputForSixDigit from './OtpInputForSixDigit';

const HpinModal = () => {

    const [setHPINForm] = Form.useForm();
    const { isHPINModalVisible, setHPINLoader } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [timer, setTimer] = useState(0);
    const [otp, setOtp] = useState(null);
    const [hpin, setHpin] = useState(null);
    const [reEnterHpin, setReEnterHpin] = useState(null);
    const [otpcode, setOtpCode] = useState(["", "", "", "", "", ""]);
    const [hpincode, sethpinCode] = useState(["", "", "", "", "", ""]);
    const [reHpincode, setReHpinCode] = useState(["", "", "", "", "", ""]);
    const [errors, setErrors] = useState({
        otp: "",
        hpin: "",
        reEnterHpin: ""
    })

    useEffect(() => {
        if (isHPINModalVisible) {
            dispatch(actionSendOTPforHPIN({ setTimer }));
        }
    }, [isHPINModalVisible, dispatch]);

    const handleCloseSetHPINModal = () => {
        dispatch(closeHPINModal());
        setHPINForm.resetFields();
        setTimer(0);
        setOtp(null);
        setHpin(null);
        setReEnterHpin(null);
        setOtpCode(["", "", "", "", "", ""]);
        sethpinCode(["", "", "", "", "", ""]);
        setReHpinCode(["", "", "", "", "", ""]);
        setErrors({
            otp: "",
            hpin: "",
            reEnterHpin: ""
        });
    }

    const handleSetOtp = () => {
        // Initialize an empty object to hold errors
        let newErrors = {};
        // OTP Validation
        if (!otp) {
            newErrors.otp = "Please enter OTP!";
        } else if (otp.length !== 6) {
            newErrors.otp = "OTP must be exactly 6 digits!";
        }

        // HPIN Validation
        if (!hpin) {
            newErrors.hpin = "Please enter HPIN!";
        } else if (hpin.length !== 6) {
            newErrors.hpin = "HPIN must be exactly 6 digits!";
        }

        // Re-enter HPIN Validation
        if (!reEnterHpin) {
            newErrors.reEnterHpin = "Please re-enter HPIN!";
        } else if (reEnterHpin !== hpin) {
            newErrors.reEnterHpin = "Entered HPIN and Re-entered HPIN must be same!";
        }

        // If there are any errors, update the state, otherwise proceed
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            // Proceed if all validations pass
            const req = {
                email_otp_hpin: otp,
                hpin: hpin,
            };
            dispatch(actionSetHPIN({
                req,
                handleCancel: handleCloseSetHPINModal,
            }));
        }
    };



    return (
        <Modal
            centered
            open={isHPINModalVisible}
            onOk={handleCloseSetHPINModal}
            onCancel={handleCloseSetHPINModal}
            afterClose={handleCloseSetHPINModal}
            footer={false}
            width={420}
            title=""
            className="hpin-modal"
        >
            <div>
                <div className="flex">
                    <img src={otpIcon} alt="" />
                    <h1 className="text-[#6883FD] text-[20px] ml-[8px] mt-[5px] font-medium">
                        Set HPIN
                    </h1>
                </div>
                <p className="text-sm mt-2 text-[#7c7c7c]">
                    Enter the OTP sent to your email.
                </p>
                <Form
                    form={setHPINForm}
                    layout="vertical"
                    className="mt-[20px] mb-0"
                >
                    <Form.Item
                        label={<>
                            <span className="text-[#FB2E2E] text-sm mr-0.5">
                                *
                            </span> <br />
                            <p>OTP</p>
                        </>}
                        className='mb-2'
                    >
                        <OtpInputForSixDigit onOtpChange={(otp) => setOtp(otp)} code={otpcode} setCode={setOtpCode} />
                        <span className="ant-form-item-explain-error text-error">
                            {errors.otp}
                        </span>
                    </Form.Item>
                    <p
                        onClick={() => {
                            if (timer === 0) {
                                dispatch(
                                    actionSendOTPforHPIN({ setTimer })
                                );
                                setOtp(null);
                            }
                        }}
                        className={`mr-5 2xl:text-base text-sm text-end ${timer === 0
                            ? "cursor-pointer text-primary hover:text-primary"
                            : "text-primary"
                            }`}
                    >
                        {timer > 0
                            ? `Resend in ${timer}s`
                            : "Resend OTP"}
                    </p>
                    <Form.Item
                        label={<>
                            <span className="text-[#FB2E2E] text-sm mr-0.5">
                                *
                            </span> <br />
                            <p>Enter HPIN</p>
                        </>}
                        className="mb-3"
                    >
                        <OtpInputForSixDigit onOtpChange={(otp) => setHpin(otp)} code={hpincode} setCode={sethpinCode}/>
                        <span className="ant-form-item-explain-error text-error">
                            {errors.hpin}
                        </span>
                    </Form.Item>
                    <Form.Item
                        label={<>
                            <span className="text-[#FB2E2E] text-sm mr-0.5">
                                *
                            </span> <br />
                            <p>Re - Enter HPIN</p>
                        </>}
                        className="mb-2"
                    >
                        <OtpInputForSixDigit onOtpChange={(otp) => setReEnterHpin(otp)} code={reHpincode} setCode={setReHpinCode} />
                        <span className="ant-form-item-explain-error text-error">
                            {errors.reEnterHpin}
                        </span>
                    </Form.Item>
                    <div className="flex justify-end mt-7">
                        <Form.Item className="mb-0 pb-0">
                            <Button
                                loading={setHPINLoader}
                                type="primary"
                                className="root-btn min-h-[40px] min-w-[100px]"
                                onClick={handleSetOtp}
                            >
                                Verify & Save
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </Modal>
    )
}

export default HpinModal