import { useCallback, useState } from "react";

export const useCenteredTree = (defaultTranslate = { x: 0, y: 0 }) => {
  const [translate, setTranslate] = useState(defaultTranslate);
  const [dimensions, setDimensions] = useState();
  const containerRef = useCallback((containerElem) => {
    if (containerElem !== null) {
      const { width, height } = containerElem.getBoundingClientRect();
      setDimensions({ width, height });
      setTranslate({ x: width / 2, y: 200 });
    }
  }, []);
  return [dimensions, translate, containerRef];
};


export const data = [
  {
    name: "Company X",
    attributes: {
      email: "companyx@domain.com",
      profile_pic: "https://dummyimage.com/100x100/000/fff",
      role: "Root",
    },
    children: [
      {
        name: "John Doe",
        attributes: {
          id: 1,
          email: "john.doe@companyx.com",
          profile_pic: "https://dummyimage.com/100x100/000/fff",
          role: "CEO",
        },
        children: [
          {
            name: "Jane Smith",
            attributes: {
              id: 2,
              email: "jane.smith@companyx.com",
              profile_pic: "https://dummyimage.com/100x100/000/fff",
              role: "HR Manager",
            },
            children: [
              {
                name: "Mark Lee",
                attributes: {
                  id: 3,
                  email: "mark.lee@companyx.com",
                  profile_pic: "https://dummyimage.com/100x100/000/fff",
                  role: "HR Specialist",
                },
                children: [],
              },
              {
                name: "Ella Wang",
                attributes: {
                  id: 4,
                  email: "ella.wang@companyx.com",
                  profile_pic: "https://dummyimage.com/100x100/000/fff",
                  role: "HR Coordinator",
                },
                children: [],
              },
            ],
          },
          {
            name: "Sam Taylor",
            attributes: {
              id: 5,
              email: "sam.taylor@companyx.com",
              profile_pic: "https://dummyimage.com/100x100/000/fff",
              role: "Sales Lead",
            },
            children: [
              {
                name: "Tom Green",
                attributes: {
                  id: 6,
                  email: "tom.green@companyx.com",
                  profile_pic: "https://dummyimage.com/100x100/000/fff",
                  role: "Sales Executive",
                },
                children: [],
              },
              {
                name: "Lily Brown",
                attributes: {
                  id: 7,
                  email: "lily.brown@companyx.com",
                  profile_pic: "https://dummyimage.com/100x100/000/fff",
                  role: "Sales Support",
                },
                children: [],
              },
            ],
          },
          {
            name: "Paul Adams",
            attributes: {
              id: 8,
              email: "paul.adams@companyx.com",
              profile_pic: "https://dummyimage.com/100x100/000/fff",
              role: "Marketing Director",
            },
            children: [
              {
                name: "Anna White",
                attributes: {
                  id: 9,
                  email: "anna.white@companyx.com",
                  profile_pic: "https://dummyimage.com/100x100/000/fff",
                  role: "Content Manager",
                },
                children: [],
              },
              {
                name: "Chris Black",
                attributes: {
                  id: 10,
                  email: "chris.black@companyx.com",
                  profile_pic: "https://dummyimage.com/100x100/000/fff",
                  role: "SEO Specialist",
                },
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
];