import { createSlice } from "@reduxjs/toolkit";
import {
  actionAddRequestForQuotation,
  actionClearAllRequestForQuotation,
  actionDeleteRequestForQuotation,
  actionGetAllRequestForQuotation,
  actionGetRequestForQuotation,
  actionGetSingleRequestForQuotation,
  actionUpdateRequestForQuotation,
  actionUpdateRequestForQuotationStatus,
} from "../services/requestForQuotationService";

const requestForQuotationSlice = createSlice({
  name: "requestForQuotation",
  initialState: {
    getRequestForQuotationLoader: false,
    getSingleRequestForQuotationLoader: false,
    addRequestForQuotationLoader: false,
    deleteRequestForQuotationLoader: false,
    updateRequestForQuotationLoader: false,
    updateRequestForQuotationStatusLoader: {},
    allRequestForQuotationLoader: false,
    requestForQuotationDataCount: 0,
    getSingleRequestForQuotationData: "",
    allRequestForQuotation: [],
    requestForQuotationData: [],
  },
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(actionGetRequestForQuotation.pending, (state) => {
        state.getRequestForQuotationLoader = true;
      })
      .addCase(actionGetRequestForQuotation.fulfilled, (state, action) => {
        state.getRequestForQuotationLoader = false;
        state.requestForQuotationData = action.payload.data;
        state.requestForQuotationDataCount = action.payload.total_records;
      })

      .addCase(actionGetRequestForQuotation.rejected, (state) => {
        state.getRequestForQuotationLoader = false;
      })

      // get Single request for quotation
      .addCase(actionGetSingleRequestForQuotation.pending, (state) => {
        state.getSingleRequestForQuotationLoader = true;
      })
      .addCase(
        actionGetSingleRequestForQuotation.fulfilled,
        (state, action) => {
          state.getSingleRequestForQuotationLoader = false;
          state.getSingleRequestForQuotationData = action.payload.data;
        }
      )
      .addCase(actionGetSingleRequestForQuotation.rejected, (state) => {
        state.getSingleRequestForQuotationLoader = false;
      })

      //add request for quotation
      .addCase(actionAddRequestForQuotation.pending, (state) => {
        state.addRequestForQuotationLoader = true;
      })
      .addCase(actionAddRequestForQuotation.fulfilled, (state, action) => {
        state.addRequestForQuotationLoader = false;
        if (action.payload) {
          if (
            !state.requestForQuotationData ||
            !Array.isArray(state.requestForQuotationData)
          ) {
            state.requestForQuotationData = [action.payload];
          } else {
            state.requestForQuotationData.unshift(action.payload);
          }
          state.requestForQuotationDataCount =
            state.requestForQuotationDataCount + 1;
        }
      })

      .addCase(actionAddRequestForQuotation.rejected, (state) => {
        state.addRequestForQuotationLoader = false;
      })

      //delete event exhibitor
      .addCase(actionDeleteRequestForQuotation.pending, (state) => {
        state.deleteRequestForQuotationLoader = true;
      })

      .addCase(actionDeleteRequestForQuotation.fulfilled, (state, action) => {
        state.deleteRequestForQuotationLoader = false;
        state.requestForQuotationData = state.requestForQuotationData.filter(
          (data) => data.request_for_quotation_id !== action.payload
        );
        state.requestForQuotationDataCount =
          state.requestForQuotationDataCount - 1;
      })

      .addCase(actionDeleteRequestForQuotation.rejected, (state) => {
        state.deleteRequestForQuotationLoader = false;
      })

      //update request for quotation
      .addCase(actionUpdateRequestForQuotation.pending, (state) => {
        state.updateRequestForQuotationLoader = true;
      })
      .addCase(actionUpdateRequestForQuotation.fulfilled, (state, action) => {
        state.updateRequestForQuotationLoader = false;
        state.requestForQuotationData = state.requestForQuotationData.map(
          (item) =>
            item.request_for_quotation_id ===
              action.payload.request_for_quotation_id
              ? { ...item, ...action.payload }
              : item
        );
      })
      .addCase(actionUpdateRequestForQuotation.rejected, (state) => {
        state.updateRequestForQuotationLoader = false;
      })

      //clear to all request for quotation
      .addCase(actionClearAllRequestForQuotation.pending, (state, action) => {
        state.allRequestForQuotation = action.payload;
      })

      //get all request for quotation
      .addCase(actionGetAllRequestForQuotation.pending, (state) => {
        state.allRequestForQuotationLoader = true;
      })
      .addCase(actionGetAllRequestForQuotation.fulfilled, (state, action) => {
        state.allRequestForQuotationLoader = false;
        state.allRequestForQuotation = action.payload.data;
      })
      .addCase(actionGetAllRequestForQuotation.rejected, (state) => {
        state.allRequestForQuotationLoader = false;
      })

      //update request for quotation status
      .addCase(actionUpdateRequestForQuotationStatus.pending, (state, action) => {
        const id = action.meta.arg.id || undefined;
        state.updateRequestForQuotationStatusLoader[id] = true;
      })
      .addCase(
        actionUpdateRequestForQuotationStatus.fulfilled,
        (state, action) => {
          const id = action.meta.arg.id || undefined;
          state.updateRequestForQuotationStatusLoader[id] = false;
          state.requestForQuotationData = state.requestForQuotationData.map(
            (item) =>
              item.request_for_quotation_id ===
                action.payload.request_for_quotation_id
                ? { ...item, status: action.payload.status }
                : item
          );
        }
      )
      .addCase(actionUpdateRequestForQuotationStatus.rejected, (state, action) => {
        const id = action.meta.arg.id || undefined;
        state.updateRequestForQuotationStatusLoader[id] = false;
      }),
});

export default requestForQuotationSlice.reducer;
