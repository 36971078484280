export const STRING_CONDITIONS = [
    { label: "is", value: "is", requiresInput: true },
    { label: "is not", value: "is_not", requiresInput: true },
    { label: "contains", value: "contains", requiresInput: true },
    { label: "does not contain", value: "not_contains", requiresInput: true },
    { label: "starts with", value: "starts_with", requiresInput: true },
    { label: "ends with", value: "ends_with", requiresInput: true },
    { label: "is empty", value: "is_empty", requiresInputcmta: false },
    { label: "is not empty", value: "is_not_empty", requiresInput: false },
]

export const NUMBER_CONDITIONS = [
    { label: "=", value: "equals", requiresInput: true },
    { label: "!=", value: "not_equals", requiresInput: true },
    { label: "<", value: "less_than", requiresInput: true },
    { label: "<=", value: "less_or_equal", requiresInput: true },
    { label: ">", value: "greater_than", requiresInput: true },
    { label: ">=", value: "greater_or_equal", requiresInput: true },
    { label: "between", value: "between", requiresInput: true, requiresSecondInput: true },
    { label: "not between", value: "not_between", requiresInput: true, requiresSecondInput: true },
    { label: "is empty", value: "is_empty", requiresInput: false },
    { label: "is not empty", value: "is_not_empty", requiresInput: false },
]

export const DROPDOWN_CONDITIONS = [
    { label: "is", value: "is", requiresInput: true },
    { label: "is not", value: "is_not", requiresInput: true },
    { label: "is empty", value: "is_empty", requiresInput: false },
    { label: "is not empty", value: "is_not_empty", requiresInput: false },
]

export const DATE_CONDITIONS = [
    { label: "in the last", value: "in_last", requiresInput: true, requiresUnit: true },
    { label: "due in", value: "due_in", requiresInput: true, requiresUnit: true },
    { label: "on", value: "on", requiresInput: true },
    { label: "after", value: "after", requiresInput: true },
    { label: "between", value: "between", requiresInput: true, requiresSecondInput: true },
    { label: "not between", value: "not_between", requiresInput: true, requiresSecondInput: true },
    { label: "Today", value: "today", requiresInput: false },
    { label: "Tomorrow", value: "tomorrow", requiresInput: false },
    { label: "Yesterday", value: "yesterday", requiresInput: false },
    { label: "This Week", value: "this_week", requiresInput: false },
    { label: "This Month", value: "this_month", requiresInput: false },
    { label: "Last Week", value: "last_week", requiresInput: false },
    { label: "Last Month", value: "last_month", requiresInput: false },
    { label: "This Year", value: "this_year", requiresInput: false },
    { label: "Current FY", value: "current_FY", requiresInput: false },
    { label: "Current FQ", value: "current_FQ", requiresInput: false },
    { label: "Last Year", value: "last_year", requiresInput: false },
    { label: "Previous FY", value: "previous_FY", requiresInput: false },
    { label: "Previous FQ", value: "previous_FQ", requiresInput: false },
    { label: "Next Year", value: "next_year", requiresInput: false },
    { label: "Next FY", value: "next_FY", requiresInput: false },
    { label: "Next FQ", value: "next_FQ", requiresInput: false },
    { label: "is empty", value: "is_empty", requiresInput: false },
    { label: "is not empty", value: "is_not_empty", requiresInput: false },
]