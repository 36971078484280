import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleDeleteResponse, handleGetResponse } from "./commonService";
import axios from "axios";
import { BASE_URL } from "../../config/web-config";
import { toast } from "sonner";
import { generateQueryString, handleErrorMessage } from "../../components/helper/common-function";

//TODO: Eventy type Action Start
// Action to get event types
export const actionGetEventTypeMaster = createAsyncThunk(
  "actionGetEventTypeMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/event-type-master?getall=YES${search ? `&search=${search}` : ""
        }${orderByColumn && order ? `&orderByColumn=${orderByColumn}` : ""}${order ? `&order=${order}` : ""
        }`
      );
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        return data;
      } else if (parseInt(status) === 404) {
        return [];
      } else {
        toast.error(customMsg, 5);
        // throw new Error(`API Error: ${customMsg}`);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//get single event type
export const actionGetSingleEventTypeMaster = createAsyncThunk(
  "actionGetSingleEventTypeMaster",
  (id) => {
    return handleGetResponse(`event-type-master/${id}`);
  }
);

// add event type
export const actionAddEventTypeMaster = createAsyncThunk(
  "actionAddEventTypeMaster",
  async ({ values, handleCancel }, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.post(`${BASE_URL}/event-type-master`, values);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        // await dispatch(actionGetEventTypeMaster());
        return data;
      } else {
        toast.error(customMsg, 5);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// delete event type
export const actionDeleteEventTypeMaster = createAsyncThunk(
  "actionDeleteEventTypeMaster",
  async ({ id, setEditingKey }, { dispatch }) => {
    return handleDeleteResponse(`event-type-master/${id}`, id, setEditingKey, dispatch);
  }
);

// update event type
export const actionEditEventTypeMaster = createAsyncThunk(
  "actionEditEventTypeMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/event-type-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//TODO: Eventy type Action Ends

//TODO: Eventy Category Action Start
export const actionGetEventCategoryMaster = createAsyncThunk(
  "actionGetEventCategoryMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `event-category-master?getall=YES${search ? `&search=${search}` : ""}${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

export const actionGetSingleEventCategoryMaster = createAsyncThunk(
  "actionGetSingleEventCategoryMaster",
  (id) => {
    return handleGetResponse(`event-category-master/${id}`);
  }
);

// add event category
export const actionAddEventCategoryMaster = createAsyncThunk(
  "actionAddEventCategoryMaster",
  async ({ values, setIsModalOpen }, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.post(`${BASE_URL}/event-category-master`, values);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        // await dispatch(actionGetEventCategoryMaster());
        setIsModalOpen && setIsModalOpen(false);
        // await dispatch(actionGetEventCategoryMaster());
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// delete event category
export const actionDeleteEventCategoryMaster = createAsyncThunk(
  "actionDeleteEventCategoryMaster",
  async ({ id, setEditingKey }, { dispatch }) => {
    return handleDeleteResponse(
      `event-category-master/${id}`,
      id,
      setEditingKey,
      dispatch
    );
  }
);

// updata event category
export const actionEditEventCategoryMaster = createAsyncThunk(
  "actionEditEventCategoryMaster",
  async ({ id, req, setEditingKey }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/event-category-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//TODO: Eventy Category Action Ends

//TODO: Event Online Platform Action start
//action get event online platform
export const actionGetEventOnlinePlatformMaster = createAsyncThunk(
  "actionGetEventOnlinePlatformMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `event-online-platforms-master?getall=YES${search ? `&search=${search}` : ""
      }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${order ? `&order=${order}` : ""
      }`
    );
  }
);

// get single onlineplatform
export const actionGetSingleEventPlatformMaster = createAsyncThunk(
  "actionGetSingleEventPlatformMaster",
  (id) => {
    return handleGetResponse(`event-online-platforms-master/${id}`);
  }
);

// add event onlineplatform
export const actionAddEventOnlinePlatformMaster = createAsyncThunk(
  "actionAddEventOnlinePlatformMaster",
  async ({ values, handleCancel }, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/event-online-platforms-master`,
        values
      );
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        // await dispatch(actionGetEventOnlinePlatformMaster());
        return data;
      } else {
        toast.error(customMsg, 5);
        // throw new Error(`API Error: ${customMsg}`);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// delete event online plateform
export const actionDeleteEventOnlinePlatformMaster = createAsyncThunk(
  "actionDeleteEventOnlinePlatformMaster",
  async ({ id, setEditingKey }, { dispatch }) => {
    return handleDeleteResponse(
      `event-online-platforms-master/${id}`,
      id,
      setEditingKey,
      dispatch
    );
  }
);

// update event online platform
export const actionEditEventOnlinePlatformMaster = createAsyncThunk(
  "actionEditEventOnlinePlatformMaster",
  async ({ id, req, setEditingKey }) => {
    // return handlePatchResponse(`event-category-master/${id}`, req);
    try {
      const response = await axios.patch(
        `${BASE_URL}/event-online-platforms-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//TODO: Event attendee status
//action get attendee status
export const actionGetAttendeeStatusMaster = createAsyncThunk(
  "actionGetAttendeeStatusMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/event-attendee-status-master?getall=YES${search ? `&search=${search}` : ""
        }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${order ? `&order=${order}` : ""
        }`
      );
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        return data;
      } else if (parseInt(status) === 404) {
        return [];
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//get single attendee status
export const actionGetSingleAttendeeStatusMaster = createAsyncThunk(
  "actionGetSingleAttendeeStatusMaster",
  (id) => {
    return handleGetResponse(`event-attendee-status-master/${id}`);
  }
);

// add attendee status
export const actionAddAttendeeStatusMaster = createAsyncThunk(
  "actionAddAttendeeStatusMaster",
  async ({ values, handleCancel, form }, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/event-attendee-status-master`,
        values
      );
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        // await dispatch(actionGetAttendeeStatusMaster());
        form &&
          form.setFieldsValue({
            attendee_status: data?.event_attendee_status_master_id,
          });
        return data;
      } else {
        toast.error(customMsg, 5);
        // throw new Error(`API Error: ${customMsg}`);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// delete attendee status
export const actionDeleteAttendeeStatusMaster = createAsyncThunk(
  "actionDeleteAttendeeStatusMaster",
  async ({ id, setEditingKey }, { dispatch }) => {
    return handleDeleteResponse(
      `event-attendee-status-master/${id}`,
      id,
      setEditingKey,
      dispatch
    );
  }
);

// update Atttendee staus
export const actionEditAttendeeStatusMaster = createAsyncThunk(
  "actionEditAttendeeStatusMaster",
  async ({ id, req, setEditingKey }) => {
    // return handlePatchResponse(`event-category-master/${id}`, req);
    try {
      const response = await axios.patch(
        `${BASE_URL}/event-attendee-status-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//TODO: Event attendee Type
//action get attendee Type
export const actionGetAttendeeTypeMaster = createAsyncThunk(
  "actionGetAttendeeTypeMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/event-attendee-type-master?getall=YES${search ? `&search=${search}` : ""
        }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${order ? `&order=${order}` : ""
        }`
      );
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        return data;
      } else if (parseInt(status) === 404) {
        return [];
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//get single attendee type
export const actionGetSingleAttendeeTypeMaster = createAsyncThunk(
  "actionGetSingleAttendeeTypeMaster",
  (id) => {
    return handleGetResponse(`event-attendee-type-master/${id}`);
  }
);

// add attendee status
export const actionAddAttendeeTypeMaster = createAsyncThunk(
  "actionAddAttendeeTypeMaster",
  async ({ values, handleCancel, form }, { dispatch }) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/event-attendee-type-master`,
        values
      );
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        // await dispatch(actionGetAttendeeTypeMaster());
        form &&
          form.setFieldsValue({
            attendee_type: data?.event_attendee_type_master_id,
          });
        return data;
      } else {
        toast.error(customMsg, 5);
        // throw new Error(`API Error: ${customMsg}`);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// delete attendee Type
export const actionDeleteAttendeeTypeMaster = createAsyncThunk(
  "actionDeleteAttendeeTypeMaster",
  async ({ id, setEditingKey }, { dispatch }) => {
    return handleDeleteResponse(
      `event-attendee-type-master/${id}`,
      id,
      setEditingKey,
      dispatch
    );
  }
);

// update Atttendee Type
export const actionEditAttendeeTypeMaster = createAsyncThunk(
  "actionEditAttendeeTypeMaster",
  async ({ id, req, setEditingKey }) => {
    // return handlePatchResponse(`event-category-master/${id}`, req);
    try {
      const response = await axios.patch(
        `${BASE_URL}/event-attendee-type-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//TODO:exihibition category
// action get exihibition category
export const actionGetExhibitionCategoryMaster = createAsyncThunk(
  "actionGetExhibitionCategoryMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/event-exhibitor-category-master?getall=YES${search ? `&search=${search}` : ""
        }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${order ? `&order=${order}` : ""
        }`
      );
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        return data;
      } else if (parseInt(status) === 404) {
        return [];
      } else {
        toast.error(customMsg, 5);
        // throw new Error(`API Error: ${customMsg}`);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//get single exhibitors category
export const actionGetSingleExhibitionCategoryMaster = createAsyncThunk(
  "actionGetSingleAttendeeTypeMasterMaster",
  (id) => {
    return handleGetResponse(`event-exhibitor-category-master/${id}`);
  }
);

// add exhibitors category
export const actionAddExhibitionCategoryMaster = createAsyncThunk(
  "actionAddExhibitionCategoryMaster",
  async ({ values, handleCancel, form }, { dispatch }) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/event-exhibitor-category-master`,
        values
      );
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        // await dispatch(actionGetExhibitionCategoryMaster());
        form &&
          form.setFieldsValue({
            exhibitor_category_id: data?.event_exhibitor_category_master_id,
          });
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// delete exhibitors category
export const actionDeleteExhibitionCategoryMaster = createAsyncThunk(
  "actionDeleteExhibitionCategoryMaster",
  async ({ id, setEditingKey }, { dispatch }) => {
    return handleDeleteResponse(
      `event-exhibitor-category-master/${id}`,
      id,
      setEditingKey,
      dispatch
    );
  }
);

// update exhibitors category
export const actionEditExhibitionCategoryMaster = createAsyncThunk(
  "actionEditExhibitionCategoryMaster",
  async ({ id, req, setEditingKey }) => {
    // return handlePatchResponse(`event-category-master/${id}`, req);
    try {
      const response = await axios.patch(
        `${BASE_URL}/event-exhibitor-category-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//TODO:Event products
// action get event product
export const actionGetEventProductMaster = createAsyncThunk(
  "actionGetEventProductMaster",
  async ({ offset = 0, limit, search = "", orderByColumn = "", order = "" }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/event-product-master?limit=${limit}&offset=${offset}${search ? `&search=${search}` : ""
        }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${order ? `&order=${order}` : ""
        }`
      );
      const { status, message: customMsg, data, total_records } = res.data;
      if (parseInt(status) === 200) {
        return { data, total_records };
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionGetAllEventProductMaster = createAsyncThunk(
  "actionGetAllEventProductMaster",
  async () => {
    return handleGetResponse(`event-product-master?getall=YES`);
  }
);

export const actionClearEventProductAllData = createAsyncThunk(
  "actionClearEventProductAllData",
  async ({ setExportAll }) => {
    setExportAll && setExportAll(false);
    return "";
  }
);

//get single event product
export const actionGetSingleEventProductMaster = createAsyncThunk(
  "actionGetSingleEventProductMaster",
  (id) => {
    return handleGetResponse(`event-product-master/${id}`);
  }
);

//add event product
export const actionAddEventProductMaster = createAsyncThunk(
  "actionAddEventProductMaster",
  async ({ values, form, handleCancel }, { dispatch, rejectWithValue }) => {
    const req = { ...values, product_image: "" };
    try {
      const res = await axios.post(`${BASE_URL}/event-product-master`, req);
      const { status, message: customMsg, data } = res.data;

      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        form &&
          form.setFieldsValue({
            event_product_master_id: data?.event_product_master_id,
          });
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// delete event product
export const actionDeleteEventProductMaster = createAsyncThunk(
  "actionDeleteEventProductMaster",
  async ({ id, setEditingKey }, { dispatch }) => {
    return handleDeleteResponse(
      `event-product-master/${id}`,
      id,
      setEditingKey,
      dispatch
    );
  }
);

// update event product
export const actionEditEventProductMaster = createAsyncThunk(
  "actionEditEventProductMaster",
  async ({ id, req, setEditingKey }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/event-product-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//TODO:Beat Partner
// action get beat partner
export const actionGetBusinessPartner = createAsyncThunk(
  "actionGetBusinessPartner",
  async ({
    offset = 0,
    limit,
    search = "",
    orderByColumn = "",
    order = "",
    businessPartnerTypeIDs,
    countryID,
    stateID,
    city,
    pincode,
    beatIDs,
    erpCode,
    gstNumber,
    panNumber,
    msmeNumber,
    fssai,
    cinNumber,
    typeOfCompanyID,
    industryID,
  } = {}) => {
    // const businessPartner = generateQueryString(
    //   businessPartnerTypeIDs,
    //   "business_partner_type_id"
    // );
    const beat = generateQueryString(
      beatIDs,
      "beat_id"
    );
    // const typeOfCompany = generateQueryString(
    //   typeOfCompanyID,
    //   "type_of_company_master_id"
    // );
    // const industryMaster = generateQueryString(
    //   industryID,
    //   "industry_master_id"
    // );
    try {
      const res = await axios.get(
        `${BASE_URL}/business-partner?limit=${limit}&offset=${offset}${search ? `&search=${search}` : ""
        }${orderByColumn && order ? `&orderByColumn=${orderByColumn}` : ""}${order ? `&order=${order}` : ""
        }${businessPartnerTypeIDs ? `&business_partner_type_id=${businessPartnerTypeIDs}` : ""
        }${countryID ? `&country_id=${countryID}` : ""
        }${stateID ? `&state_id=${stateID}` : ""
        }${city ? `&city=${city}` : ""
        }${pincode ? `&zip_code=${pincode}` : ""
        }${beat}${erpCode ? `&erp_code=${erpCode}` : ""
        }${gstNumber ? `&gst_number=${gstNumber}` : ""
        }${panNumber ? `&pan_card_no=${panNumber}` : ""
        }${msmeNumber ? `&msme_number=${msmeNumber}` : ""
        }${fssai ? `&fssai=${fssai}` : ""
        }${cinNumber ? `&cin_no=${cinNumber}` : ""
        }${typeOfCompanyID ? `&type_of_company_master_id=${typeOfCompanyID}` : ""
        }${industryID ? `&industry_master_id=${industryID}` : ""
        }`
      );
      const { status, message: customMsg, data, total_records } = res.data;
      if (parseInt(status) === 200) {
        return { data, total_records };
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
        throw new Error(`API Error: ${customMsg}`);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//get single business partner
export const actionGetSingleBusinessPartner = createAsyncThunk(
  "actionGetSingleBusinessPartner",
  (id) => {
    return handleGetResponse(`business-partner/${id}`);
  }
);

//get all business partner
export const actionGetAllBusinessPartner = createAsyncThunk(
  "actionGetAllBusinessPartner",
  () => {
    return handleGetResponse(`business-partner?getall=YES`);
  }
);
//clear all  business partner
export const actionClearAllBusinessPartner = createAsyncThunk(
  "actionClearAllBusinessPartner",
  ({ setExportAll }) => {
    setExportAll && setExportAll(false);
    return "";
  }
);

//add bussiness partner product
export const actionAddBusinessPartner = createAsyncThunk(
  "actionAddBusinessPartner",
  async ({ req, navigate, handleCancel, form, field = "" }, { dispatch }) => {
    try {
      const res = await axios.post(`${BASE_URL}/business-partner`, req);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        if (field === "ship_to_party_business_partner_id") {
          form.setFieldsValue({
            ship_to_party_business_partner_id: data?.business_partner_id,
          });
        } else if (field === "customer_business_partner_id") {
          form &&
            form.setFieldsValue({
              customer_business_partner_id: data?.business_partner_id,
            });
        } else {
          form &&
            form.setFieldsValue({
              business_partner_id: data?.business_partner_id,
            });
        }
        handleCancel && handleCancel();
        navigate && navigate(-1);
        return data;
      } else {
        toast.error(customMsg, 5);
        // throw new Error(`API Error: ${customMsg}`);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// delete bussiness partner product
export const actionDeleteBusinessPartner = createAsyncThunk(
  "actionDeleteBusinessPartner",
  async ({ id, handleCloseDeleteModal }) => {
    try {
      const response = await axios.delete(`${BASE_URL}/business-partner/${id}`);
      const { status, message: customMsg } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCloseDeleteModal && handleCloseDeleteModal();
        return id;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// update bussiness partner product
export const actionUpdateBusinessPartner = createAsyncThunk(
  "actionUpdateBusinessPartner",
  async ({ id, req, navigate }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/business-partner/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate(-1);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionUpdateBulkBusinessPartner = createAsyncThunk(
  "actionUpdateBulkBusinessPartner",
  async ({ req, handleClose }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/business-partner/bulk-update`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleClose && handleClose();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//sales order businesspartner
export const actionGetSalesOrderByBusinessPartner = createAsyncThunk(
  "actionGetSalesOrderByBusinessPartner",
  (id) => {
    return handleGetResponse(
      `sales-order?limit=10&offset=0&customer_business_partner_id=${id}`
    );
  }
);

export const actionUpdateBusinessPartnerLatLng = createAsyncThunk(
  "actionUpdateBusinessPartnerLatLng",
  async ({ id, req, handleClose }, { rejectWithValue }) => {
    try {
      const res = await axios.put(`${BASE_URL}/business-partner/${id}`, req);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleClose && handleClose();
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//invoice business partner
export const actionGetInvoiceByBusinessPartner = createAsyncThunk(
  "actionGetInvoiceByBusinessPartner",
  (id) => {
    return handleGetResponse(
      `invoice?limit=10&offset=0&customer_business_partner_id=${id}`
    );
  }
);
