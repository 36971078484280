import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config/web-config";
import { toast } from "sonner";
import {
  formatDate,
  handleErrorMessage,
} from "../../components/helper/common-function";
import { handleGetResponse } from "./commonService";
import { showVerifyHPINModal } from "../slices/authSlice";

export const actionGetBeatPlanning = createAsyncThunk(
  "actionGetBeatPlanning",
  async (
    { offset = 0, limit, search = "", orderByColumn = "", order = "" } = {},
    { rejectWithValue }
  ) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/beat-planning?limit=${limit}&offset=${offset}${search ? `&search=${search}` : ""
        }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${order ? `&order=${order}` : ""
        }`
      );
      const { status, message: customMsg, data, total_records } = res.data;
      if (parseInt(status) === 200) {
        return { data, total_records };
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
        throw new Error(`API Error: ${customMsg}`);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionCreateBeatPlanning = createAsyncThunk(
  "actionCreateBeatPlanning",
  async ({ req, setIsModalOpen }, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${BASE_URL}/beat-planning`, req);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg);
        setIsModalOpen && setIsModalOpen(false);
        return data;
      } else {
        toast.error(customMsg);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionUpdateBeatPlanning = createAsyncThunk(
  "actionUpdateBeatPlanning",
  async ({ id, req, setIsModalOpen }, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.patch(`${BASE_URL}/beat-planning/${id}`, req);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg);
        setIsModalOpen && setIsModalOpen();

        dispatch(actionGetBeatPlanning());
        return data;
      } else {
        toast.error(customMsg);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionGetAllBeatPlanning = createAsyncThunk(
  "actionGetAllBeatPlanning",
  async () => {
    return handleGetResponse(`beat-planning?getall=YES`);
  }
);
export const actionGetBeatPlanningDetail = createAsyncThunk(
  "actionGetBeatPlanningDetail",
  async (id) => {
    return handleGetResponse(`beat-planning/${id}`);
  }
);

// Clear all Event Attendee Data
export const actionClearAllBeatPlanning = createAsyncThunk(
  "actionClearAllBeatPlanning",
  async ({ setExportAll }) => {
    setExportAll && setExportAll(false);
    return "";
  }
);

export const actionDeleteBeatPlanning = createAsyncThunk(
  "actionDeleteBeatPlanning",
  async ({ id, handleCloseDeleteModal }, { rejectWithValue }) => {
    try {
      const res = await axios.delete(`${BASE_URL}/beat-planning/${id}`);
      const { status, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCloseDeleteModal && handleCloseDeleteModal();
        return id;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);
export const actionChangeBeatPlanningStatus = createAsyncThunk(
  "actionChangeBeatPlanningStatus",
  async ({ id, status: newStatus, setVisible }, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${BASE_URL}/beat-planning/status/${id}`, {
        status: newStatus,
      });
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setVisible && setVisible(false);
        return { id, newStatus };
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Sales Orders Start Here

export const actionGetSalesOrders = createAsyncThunk(
  "actionGetSalesOrders",
  ({
    offset = 0,
    limit,
    getall = "",
    orderByColumn = "",
    order = "",
    search = "",
    startDate,
    endDate,
    status,
    businessPartner = "",
    companyUser = "",
  } = {}) => {
    return handleGetResponse(
      `sales-order?limit=${limit}&offset=${offset}${getall ? "&getall=" + getall : ""
      }${orderByColumn ? "&orderByColumn=" + orderByColumn : ""}${order ? "&order=" + order : ""
      }${search ? "&search=" + search : ""}${startDate ? "&start_date=" + startDate : ""
      }${endDate ? "&end_date=" + endDate : ""}${status ? "&so_status=" + status : ""
      }${businessPartner
        ? "&customer_business_partner_id=" + businessPartner
        : ""
      }${companyUser ? "&created_by_compnay_user_id=" + companyUser : ""}
      `
    );
  }
);

export const actionGetAllSalesOrders = createAsyncThunk(
  "actionGetAllSalesOrders",
  () => {
    return handleGetResponse(`sales-order?getall=YES`);
  }
);

export const actionGetAllPlantCodes = createAsyncThunk(
  "actionGetAllPlantCodes",
  () => {
    return handleGetResponse(`plant-code-master?getall=YES`);
  }
);

export const actionClearAllSalesOrders = createAsyncThunk(
  "actionClearAllSalesOrders",
  async ({ setExportAll }) => {
    setExportAll && setExportAll(false);
    return "";
  }
);

export const actionAddSalesOrder = createAsyncThunk(
  "actionAddSalesOrder",
  async ({ req, navigate }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/sales-order`, req);
      const { data, message: customMsg, status } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg);
        navigate && navigate("/sales-orders");
        return data;
      } else {
        toast.error(customMsg);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionDeleteSalesOrder = createAsyncThunk(
  "actionDeleteSalesOrder",
  async ({ id, setIsDeleteModalOpen, navigate }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.delete(`${BASE_URL}/sales-order/${id}`);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setIsDeleteModalOpen && setIsDeleteModalOpen(false);
        navigate && navigate("/sales-orders");
        return id;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        if (data?.status == 440) {
          dispatch(showVerifyHPINModal());
        }
      }
      handleErrorMessage(error);
    }
  }
);

export const actionGetSalesOrderDetails = createAsyncThunk(
  "actionGetSalesOrderDetails",
  async (id) => {
    return handleGetResponse(`sales-order/${id}`);
  }
);

export const actionUpdateSalesOrder = createAsyncThunk(
  "actionUpdateSalesOrder",
  async ({ id, req, navigate }, { rejectWithValue }) => {
    try {
      const res = await axios.put(`${BASE_URL}/sales-order/${id}`, req);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate(-1);
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionChangeSalesOrderStatus = createAsyncThunk(
  "actionChangeSalesOrderStatus",
  async ({ id, values, handleClose }, { rejectWithValue }) => {
    try {
      const res = await axios.patch(`${BASE_URL}/sales-order/${id}`, values);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleClose && handleClose();
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Sales Orders Ends Here

export const actionGetSalesInvoices = createAsyncThunk(
  "actionGetSalesInvoices",
  ({
    offset = 0,
    limit,
    getall = "",
    orderByColumn = "",
    order = "",
    search = "",
    startDate,
    endDate,
    status,
  } = {}) => {
    return handleGetResponse(
      `invoice?limit=${limit}&offset=${offset}${getall ? "&getall=" + getall : ""}${orderByColumn ? "&orderByColumn=" + orderByColumn : ""
      }${order ? "&order=" + order : ""}${search ? "&search=" + search : ""}${startDate ? "&start_date=" + startDate : ""
      }${endDate ? "&end_date=" + endDate : ""}${status ? "&status=" + status : ""
      }`
    );
  }
);

export const actionGetAllSalesInvoices = createAsyncThunk(
  "actionGetAllSalesInvoices",
  ({ search = "" } = {}) => {
    return handleGetResponse(
      `invoice?getall=YES${search ? "&search=" + search : ""}`
    );
  }
);

export const actionClearAllSalesInvoice = createAsyncThunk(
  "actionClearAllSalesInvoice",
  async ({ setExportAll }) => {
    setExportAll && setExportAll(false);
    return "";
  }
);

/* export const actionAddSalesInvoice = createAsyncThunk(
  "actionAddSalesInvoice",
  async ({ req, navigate }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/invoice`, req);
      const { data, message: customMsg, status } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg);
        navigate && navigate("/sales-orders");
        return data;
      } else {
        toast.error(customMsg);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionDeleteSalesInvoice = createAsyncThunk(
  "actionDeleteSalesInvoice",
  async ({ id, setIsDeleteModalOpen }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${BASE_URL}/invoice/${id}`);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setIsDeleteModalOpen && setIsDeleteModalOpen(false);
        return id;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionUpdateSalesInvoice = createAsyncThunk(
  "actionUpdateSalesInvoice",
  async ({ id, req, navigate }, { rejectWithValue }) => {
    try {
      const res = await axios.put(`${BASE_URL}/invoice/${id}`, req);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate(-1);
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
); */

export const actionGetSalesInvoiceDetails = createAsyncThunk(
  "actionGetSalesInvoiceDetails",
  async (id) => {
    return handleGetResponse(`invoice/${id}`);
  }
);

// export const

export const actionGetAllERPTrackingData = createAsyncThunk(
  "actionGetAllERPTrackingData",
  ({ date, userId, order }) => {
    const formattedDate = date
      ? date.format("YYYY-MM-DD")
      : formatDate(new Date(), "YYYY-MM-DD");
    return handleGetResponse(
      `customer-visit-tracking?getall=YES${userId ? "&user_id=" + userId : ""}${order ? "&order=" + order : ""
      }${formattedDate ? "&date=" + formattedDate : ""}`
    );
  }
);

export const actionGetUserLocationTracking = createAsyncThunk(
  "actionGetUserLocationTracking",
  ({ date, userId, order }) => {
    const formattedDate = date
      ? date.format("YYYY-MM-DD")
      : formatDate(new Date(), "YYYY-MM-DD");
    return handleGetResponse(
      `user-location-tracking?getall=YES${userId ? "&user_id=" + userId : ""}${order ? "&order=" + order : ""
      }${formattedDate ? "&date=" + formattedDate : ""}`
    );
  }
);

export const actionGetEmpTrackingDetails = createAsyncThunk(
  "actionGetEmpTrackingDetails",
  async (id) => {
    return handleGetResponse(`customer-visit-tracking/${id}`);
  }
);
