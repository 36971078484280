import { createSelector, createSlice } from "@reduxjs/toolkit";
import {
  actionContinueToLogin,
  actionContinueWithGoogle,
  actionLogin,
  actionLoginOTP,
  actionRegister,
  actionVerifyOTPEmail,
  actionVerifyOTPMobile,
  actionVerifyOtpLogin,
  actionForgotPassword,
  actionResetPassword,
  actionRegisterCompany,
  authMe,
  actionAddBusinessHours,
  actionChangeMobile,
  actionCreateAccount,
  actionGetTimezones,
  actionInviteUser,
  actionClearInviteMessages,
  actionAcceptInvitation,
  actionCheckAuthStatus,
  actionRejectInvitation,
  actionChangePassword,
  actionGetCompanyAccessKeys,
  actionSetHPIN,
  actionVerifyHPIN,
} from "../services/authService";
import { getPermissionMessage } from "../../components/helper/common-function";

const findActiveUser = (data) => {
  const user =
    data && data.length > 0 ? data.find((d) => d.status === "ACTIVE") : null;
  return user;
};

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    timezones: "",
    myCurrency: {},
    isModalVisible: false,
    modalMessage: "",
    isHPINModalVisible: false,
    verifyHPINParam: null,
    isVerifyHPINModalVisible: false,
    userLoader: false,
    registerLoader: false,
    loginLoader: false,
    loginOtpLoader: false,
    verifyEmailOtpLoader: false,
    verifyPhoneOtpLoader: false,
    verifyLoginLoader: false,
    forgotPasswordLoader: false,
    continueLoader: false,
    googleLoader: false,
    registerCompanyLoader: false,
    addCompanyLoader: false,
    checkAuthStatusError: "",
    resetPasswordLoader: false,
    changeMobileLoader: false,
    createAccountLoader: false,
    timezoneLoader: false,
    changePasswordLoader: false,
    inviteSuccesses: [],
    InviteErrors: [],
    acceptInvitationLoader: false,
    rejectInvitationLoader: false,
    checkAuthStatusLoader: false,
    currentCompanyUser: {},
    getApiKeysLoader: false,
    apiKeys: {},
    setHPINLoader: false,
    verifyHPINLoader: false
  },
  reducers: {
    showModal: (state, action) => {
      state.isModalVisible = true;
      state.modalMessage = action.payload;
    },
    closeModal: (state) => {
      state.isModalVisible = false;
      state.modalMessage = "";
    },
    showHPINModal: (state, action) => {
      state.isHPINModalVisible = true;
      state.isVerifyHPINModalVisible = false;
    },
    closeHPINModal: (state) => {
      state.isHPINModalVisible = false;
    },
    showVerifyHPINModal: (state, action) => {
      state.isVerifyHPINModalVisible = true;
      state.verifyHPINParam = action.payload?.customParam || null;
    },
    closeVerifyHPINModal: (state) => {
      state.isVerifyHPINModalVisible = false;
      state.verifyHPINParam = null;
    }
  },
  extraReducers: (buider) => {
    buider
      // Register State
      .addCase(actionRegister.pending, (state) => {
        state.registerLoader = true;
      })
      .addCase(actionRegister.fulfilled, (state, action) => {
        // state.user = action.payload;
        state.registerLoader = false;
      })
      .addCase(actionRegister.rejected, (state) => {
        state.registerLoader = false;
      })

      // Auth me State
      .addCase(authMe.pending, (state) => {
        state.userLoader = true;
      })
      .addCase(authMe.fulfilled, (state, action) => {
        state.user = action.payload;
        state.userLoader = false;

        state.currentCompanyUser = findActiveUser(action.payload.company_users);
        state.myCurrency = state.currentCompanyUser?.company?.currency;
        // Save permission_data directly to localStorage
        const permissionData =
          state.currentCompanyUser?.roleprofile?.role_profile?.permission_data;
        if (permissionData) {
          localStorage.setItem(
            "crm_user_permission_data",
            JSON.stringify(permissionData)
          );
        }
      })
      .addCase(authMe.rejected, (state) => {
        state.userLoader = false;
      })

      // Login State
      .addCase(actionLogin.pending, (state) => {
        state.loginLoader = true;
      })
      .addCase(actionLogin.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loginLoader = false;
      })
      .addCase(actionLogin.rejected, (state) => {
        state.loginLoader = false;
      })

      // Login with OTP State
      .addCase(actionLoginOTP.pending, (state) => {
        state.loginOtpLoader = true;
      })
      .addCase(actionLoginOTP.fulfilled, (state, action) => {
        state.loginOtpLoader = false;
      })
      .addCase(actionLoginOTP.rejected, (state) => {
        state.loginOtpLoader = false;
      })

      // Login OTP Via Email State
      .addCase(actionVerifyOTPEmail.pending, (state) => {
        state.verifyEmailOtpLoader = true;
      })
      .addCase(actionVerifyOTPEmail.fulfilled, (state, action) => {
        state.verifyEmailOtpLoader = false;
      })
      .addCase(actionVerifyOTPEmail.rejected, (state) => {
        state.verifyEmailOtpLoader = false;
      })

      // Login OTP Mobile State
      .addCase(actionVerifyOTPMobile.pending, (state) => {
        state.verifyPhoneOtpLoader = true;
      })
      .addCase(actionVerifyOTPMobile.fulfilled, (state, action) => {
        state.verifyPhoneOtpLoader = false;
      })
      .addCase(actionVerifyOTPMobile.rejected, (state) => {
        state.verifyPhoneOtpLoader = false;
      })
      // Verify  OTP via Login  State
      .addCase(actionVerifyOtpLogin.pending, (state) => {
        state.verifyLoginLoader = true;
      })
      .addCase(actionVerifyOtpLogin.fulfilled, (state, action) => {
        state.verifyLoginLoader = false;
      })
      .addCase(actionVerifyOtpLogin.rejected, (state) => {
        state.verifyLoginLoader = false;
      })

      // Continue To Api State
      .addCase(actionContinueToLogin.pending, (state) => {
        state.continueLoader = true;
      })
      .addCase(actionContinueToLogin.fulfilled, (state, action) => {
        state.continueLoader = false;
      })
      .addCase(actionContinueToLogin.rejected, (state) => {
        state.continueLoader = false;
      })

      //forgot password
      .addCase(actionForgotPassword.pending, (state) => {
        state.forgotPasswordLoader = true;
      })
      .addCase(actionForgotPassword.fulfilled, (state, action) => {
        state.forgotPasswordLoader = false;
      })
      .addCase(actionForgotPassword.rejected, (state) => {
        state.forgotPasswordLoader = false;
      })

      //resetpassword
      .addCase(actionResetPassword.pending, (state) => {
        state.resetPasswordLoader = true;
      })
      .addCase(actionResetPassword.fulfilled, (state, action) => {
        state.resetPasswordLoader = false;
      })
      .addCase(actionResetPassword.rejected, (state) => {
        state.resetPasswordLoader = false;
      })

      .addCase(actionChangePassword.pending, (state) => {
        state.changePasswordLoader = true;
      })
      .addCase(actionChangePassword.fulfilled, (state) => {
        state.changePasswordLoader = false;
      })
      .addCase(actionChangePassword.rejected, (state) => {
        state.changePasswordLoader = false;
      })

      //change-mobile
      .addCase(actionChangeMobile.pending, (state) => {
        state.changeMobileLoader = true;
      })
      .addCase(actionChangeMobile.fulfilled, (state, action) => {
        state.changeMobileLoader = false;
      })
      .addCase(actionChangeMobile.rejected, (state) => {
        state.changeMobileLoader = false;
      })

      // Continue To Google Api State
      .addCase(actionContinueWithGoogle.pending, (state) => {
        state.googleLoader = true;
      })
      .addCase(actionContinueWithGoogle.fulfilled, (state, action) => {
        state.googleLoader = false;
      })
      .addCase(actionContinueWithGoogle.rejected, (state) => {
        state.googleLoader = false;
      })

      // Continue To Google Api State
      .addCase(actionRegisterCompany.pending, (state) => {
        state.registerCompanyLoader = true;
      })
      .addCase(actionRegisterCompany.fulfilled, (state, action) => {
        state.registerCompanyLoader = false;
      })
      .addCase(actionRegisterCompany.rejected, (state) => {
        state.registerCompanyLoader = false;
      })

      // Add Company
      .addCase(actionAddBusinessHours.pending, (state) => {
        state.addCompanyLoader = true;
      })
      .addCase(actionAddBusinessHours.fulfilled, (state, action) => {
        state.addCompanyLoader = false;
      })
      .addCase(actionAddBusinessHours.rejected, (state) => {
        state.addCompanyLoader = false;
      })
      // Create Account State
      .addCase(actionCreateAccount.pending, (state) => {
        state.createAccountLoader = true;
      })
      .addCase(actionCreateAccount.fulfilled, (state, action) => {
        state.createAccountLoader = false;
      })
      .addCase(actionCreateAccount.rejected, (state) => {
        state.createAccountLoader = false;
      })

      .addCase(actionGetTimezones.pending, (state) => {
        state.timezoneLoader = true;
      })
      .addCase(actionGetTimezones.fulfilled, (state, action) => {
        state.timezoneLoader = false;
        state.timezones = action.payload.data;
      })
      .addCase(actionGetTimezones.rejected, (state) => {
        state.timezoneLoader = false;
      })

      // Invite User
      .addCase(actionInviteUser.pending, (state) => {
        state.inviteUserLoader = true;
      })
      .addCase(actionInviteUser.fulfilled, (state, action) => {
        state.inviteUserLoader = false;
        state.inviteSuccesses = action.payload.successes || [];
        state.InviteErrors = action.payload.errors || [];
      })
      .addCase(actionInviteUser.rejected, (state, action) => {
        state.inviteUserLoader = false;
      })
      .addCase(actionClearInviteMessages.fulfilled, (state, action) => {
        state.inviteSuccesses = [];
        state.InviteErrors = [];
      })

      //check auth status before accept invitation
      .addCase(actionCheckAuthStatus.pending, (state, action) => {
        state.checkAuthStatusLoader = true;
      })
      .addCase(actionCheckAuthStatus.fulfilled, (state, action) => {
        state.checkAuthStatusLoader = false;
        state.checkAuthStatusError = action.payload;
      })
      .addCase(actionCheckAuthStatus.rejected, (state, action) => {
        state.checkAuthStatusLoader = false;
        state.checkAuthStatusError = action.payload;
      })

      //accept invitation
      .addCase(actionAcceptInvitation.pending, (state) => {
        state.acceptInvitationLoader = true;
      })
      .addCase(actionAcceptInvitation.fulfilled, (state, action) => {
        state.acceptInvitationLoader = false;
      })
      .addCase(actionAcceptInvitation.rejected, (state) => {
        state.acceptInvitationLoader = false;
      })

      //reject invitation
      .addCase(actionRejectInvitation.pending, (state) => {
        state.rejectInvitationLoader = true;
      })
      .addCase(actionRejectInvitation.fulfilled, (state, action) => {
        state.rejectInvitationLoader = false;
      })
      .addCase(actionRejectInvitation.rejected, (state) => {
        state.rejectInvitationLoader = false;
      })

      //get api key
      .addCase(actionGetCompanyAccessKeys.pending, (state) => {
        state.getApiKeysLoader = true;
      })
      .addCase(actionGetCompanyAccessKeys.fulfilled, (state, action) => {
        state.getApiKeysLoader = false;
        state.apiKeys = action.payload.data || {}
      })
      .addCase(actionGetCompanyAccessKeys.rejected, (state) => {
        state.getApiKeysLoader = false;
      })

      // set hpin State
      .addCase(actionSetHPIN.pending, (state) => {
        state.setHPINLoader = true;
      })
      .addCase(actionSetHPIN.fulfilled, (state, action) => {
        state.setHPINLoader = false;
        if (state.currentCompanyUser) {
          state.currentCompanyUser = {
            ...state.currentCompanyUser,
            is_hpin_available: true,
          };
        }
      })
      .addCase(actionSetHPIN.rejected, (state) => {
        state.setHPINLoader = false;
      })

      // verify hpin State
      .addCase(actionVerifyHPIN.pending, (state) => {
        state.verifyHPINLoader = true;
      })
      .addCase(actionVerifyHPIN.fulfilled, (state, action) => {
        state.verifyHPINLoader = false;
      })
      .addCase(actionVerifyHPIN.rejected, (state) => {
        state.verifyHPINLoader = false;
      })
  },
});

export const { showModal, closeModal, showHPINModal, closeHPINModal, showVerifyHPINModal, closeVerifyHPINModal } = authSlice.actions;

export const selectHasPermission = createSelector(
  () => {
    const permissionData =
      JSON.parse(localStorage.getItem("crm_user_permission_data")) || [];
    return permissionData;
  },
  (permissionData) => (resource, action) => {
    return permissionData.some(
      (permission) =>
        permission?.permissions?.resources === resource &&
        permission?.permissions?.actions === action
    );
  }
);

export const handleActionWithPermission =
  (resource, action, onSuccess) => (dispatch, getState) => {
    const state = getState();
    const hasPermission = selectHasPermission(state);

    if (!hasPermission(resource, action)) {
      const permissionMessage = getPermissionMessage(resource, action);
      dispatch(showModal(permissionMessage));
      return false;
    }
    if (onSuccess) {
      onSuccess();
    }
    return true;
  };

export default authSlice.reducer;
