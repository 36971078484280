import { Empty, List, Skeleton, Typography } from "antd";
import VirtualList from "rc-virtual-list";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ModulePaths } from "../helper/constants";
import { formatString } from "../helper/common-function";

const ContainerHeight = 400;

const NotificationList = ({
  data = [],
  loading = false,
  hasMore = false,
  appendData = () => { },
  setIsPopoverOpen = () => { },

}) => {
  const navigate = useNavigate();
  const onScroll = (e) => {
    if (
      Math.abs(
        e.currentTarget.scrollHeight -
        e.currentTarget.scrollTop -
        ContainerHeight
      ) <= 1
    ) {
      if (!loading && hasMore) {
        appendData(false, true);
      }
    }
  };

  return (
    <div className="max-h-[600px] overflow-y-auto">
      {
        loading && data && data.length === 0 ? <Loading />
          : data && data.length === 0 && !loading ? (
            <div className="h-[300px] flex items-center justify-center">  <Empty description="No Data Available" /></div>
          ) :
            <>

              <VirtualList
                width="100%"
                data={loading && data && data.length === 0 ? ["", "", "", ""] : data}
                height={loading ? ContainerHeight : ContainerHeight}
                itemHeight={40}
                itemKey="notification_id"
                onScroll={onScroll}
              >
                {(item, index) => (
                  <List.Item style={{ cursor: "pointer" }}
                    key={item.notification_id}
                    onClick={() => {
                      const modulePath = ModulePaths[item.module_name?.toUpperCase()];
                      if (modulePath) {
                        let detailPagePath;
                        if (modulePath.includes(":id")) {
                          detailPagePath = modulePath.replace(
                            ":id",
                            item.ref_module_id
                          );
                        } else {
                          detailPagePath = `${modulePath}${item.ref_module_id}`;
                        }
                        setIsPopoverOpen(false);
                        if (item.module_name && item.ref_module_id) {
                          navigate(detailPagePath);
                        }
                      }
                    }}
                    className={`notification-item py-[12px] px-4 ${index === data.length - 1 ? "!border-none" : "border-b"
                      } ${item &&
                        item.notification_users &&
                        item.notification_users[0]?.is_read
                        ? "read"
                        : "unread"
                      }`}
                  >

                    <List.Item.Meta
                      className="flex gap-x-4"
                      avatar={<div className="blue-dot" />}
                      title={
                        <Typography.Text
                          className="text-sm title mb-1 tracking-[0.5px]"
                          strong={false}
                        >
                          {item.title}
                        </Typography.Text>
                      }
                      description={
                        <div>
                          <Typography.Text className="description text-xs text-[#595959]">
                            {item.description}
                          </Typography.Text>
                          <br />
                          <Typography.Text className="notification-module tracking-wide text-brand text-[11px]">
                            {/*  {item?.module_name?.charAt(0).toUpperCase() +
                              item?.module_name?.slice(1).toLowerCase()} */}
                            {formatString(item?.module_name)}
                          </Typography.Text>
                        </div>
                      }
                    />

                  </List.Item>
                )}
              </VirtualList>
              {loading && hasMore && data.length > 0 && (
                <Skeleton
                  avatar={false}
                  paragraph={false}
                  title={{ style: { width: "100%" } }}
                />
              )}
            </>}
    </div>
  );
};

export default NotificationList;



const Loading = () => {
  return (
    <List
      itemLayout="horizontal"
      dataSource={
        [
          {
            title: 'Ant Design Title 1',
          },
          {
            title: 'Ant Design Title 2',
          },
          {
            title: 'Ant Design Title 3',
          },
          {
            title: 'Ant Design Title 4',
          },
        ]}
      renderItem={(item, index) => (
        <List.Item>
          <List.Item.Meta
            avatar={<Skeleton.Avatar active size={10} shape="circle" />}
            title={
              <Skeleton
                paragraph={false}
                active
                size="small"
                style={{ width: 300 }}
              />
            }
            description={
              <>
                <Skeleton
                  title={false}
                  paragraph={{ rows: 1, width: "100%" }}
                  active
                  size="small"
                  style={{ width: 250, marginBottom: 4 }}
                />
                <Skeleton
                  title={false}
                  paragraph={{ rows: 1, width: "100%" }}
                  active
                  size="small"
                  style={{ width: 200, marginBottom: 4 }}
                />{" "}
              </>
            }
          />
        </List.Item>
      )}
    />
  );
}