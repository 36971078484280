import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  CloseOutlined
} from "@ant-design/icons";
import { LuFilter } from "react-icons/lu";
import {
  Autocomplete,
  GoogleMap,
  LoadScript,
  Marker
} from "@react-google-maps/api";
import {
  Button,
  Col,
  ConfigProvider,
  Drawer,
  Dropdown,
  Flex,
  Form,
  Image,
  Input,
  Modal,
  Pagination,
  Row,
  Select,
  Skeleton,
  Tooltip,
  Typography
} from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SiGooglemaps } from "react-icons/si";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import plusIcon from "../../../assets/img/add-button.svg";
import bulkUpdate from "../../../assets/img/bulk-update-icon.svg";
import businessPartner from "../../../assets/img/businessPartner.svg";
import { GOOGLE_MAP_KEY } from "../../../config/web-config";
import {
  actionGetAllBeatMaster,
  actionGetBusinessPartnerTypeMaster,
  actionGetIndustryMaster,
  actionGetTypeOfCompanyMaster,
} from "../../../store/services/generalMasterService";
import {
  actionClearAllBusinessPartner,
  actionDeleteBusinessPartner,
  actionGetAllBusinessPartner,
  actionGetBusinessPartner,
  actionGetSingleBusinessPartner,
  actionUpdateBulkBusinessPartner,
  actionUpdateBusinessPartnerLatLng,
} from "../../../store/services/masterDataService";
import { handleActionWithPermission } from "../../../store/slices/authSlice";
import CommonTable from "../../common/CommonTable";
import SkeletonTable from "../../common/SkeletonTable";
import SortIcon from "../../common/SortIcon";
import TooltipWrapper from "../../common/TooltipWrapper";
import CustomIconText from "../../common/CustomIconText";
import { actionGetCountries, actionGetStates } from "../../../store/services/locationService";
import CommonPagination from "../../common/CommonPagination";
import PageTitle from "../../common/PageTitle";

const BusinessPartners = () => {
  const [search, setSearch] = useState("");
  const [exportAll, setExportAll] = useState(false);
  const [viewBusinessPartnerModalOpen, setViewBusinessPartnerModalOpen] =
    useState(false);
  const [businessPartnerId, setBusinessPartnerId] = useState("");
  const [deleteBusinessPartnerModalOpen, setDeleteBusinessPartnerModalOpen] =
    useState(false);
  const [locationInput, setLocationInput] = useState("");
  const [lastSearch, setLastSearch] = useState('');
  const [filterModal, setFilterModal] = useState(false);
  const [filterValues, setFilterValues] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [isFilterFormChanged, setIsFilterFormChanged] = useState(false)
  const [businessPartnerTypeIDs, setBusinessPartnerTypeIDs] = useState(null);
  const [countryID, setCountryID] = useState(null);
  const [stateID, setStateID] = useState(null);
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [beatIDs, setBeatIDs] = useState([]);
  const [erpCode, setErpCode] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [msmeNumber, setMsmeNumber] = useState("");
  const [fssai, setFssai] = useState("");
  const [cinNumber, setCinNumber] = useState("");
  const [typeOfCompanyID, setTypeOfCompanyID] = useState(null);
  const [industryID, setIndustryID] = useState(null);
  const [isReset, setIsReset] = useState(false);
  const [filteredFields, setFilteredFields] = useState([]);
  const [countrySelected, setCountrySelected] = useState(false);
  const [form] = Form.useForm();
  const [filterForm] = Form.useForm();
  const offsetRef = useRef(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    getBusinessPartnerLoader,
    businessPartnerData,
    getAllBusinessPartnerLoader,
    allBusinessPartnerData,
    businessPartnersCount,
    deleteBusinessPartnerLoader,
    getBusinessPartnerDetailLoader,
    businessPartnerDetail,
    updateBusinessPartnerLatlngLoader,
    updateBulkBusinessPartnerLoader,
  } = useSelector((state) => state.masterData);

  const { businessPartnerTypeData, getBusinessPartnerTypeLoader, getIndustryLoader, industryData, typeOfCompanyData, getTypeOfCompanyLoader } = useSelector(
    (state) => state.generalMaster
  );
  const { allBeatMasterData, getAllBeatMasterLoader } = useSelector(
    (state) => state.generalMaster
  );
  const { countries, states, getCountryLoader, statesLoader } = useSelector(
    (state) => state.location
  );

  const [selectLocationModalOpen, setSelectLocationModalOpen] = useState(false);

  const defaultCenter = { lat: 40.748817, lng: -73.985428 };
  const [center, setCenter] = useState(defaultCenter);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    dispatch(
      actionGetBusinessPartner({
        offset: offsetRef.current,
        limit: pageSize,
      })
    );
  }, [pageSize, dispatch]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const setLocationFromCoordinates = async (lat, lng) => {
    const placeName = await reverseGeocode(lat, lng);
    if (placeName) {
      setLocationInput(placeName);
    } else {
      setLocationInput("");
    }
  };

  useEffect(() => {
    if (selectLocationModalOpen && businessPartnerDetail) {
      setSelectedLocation({
        lat: parseFloat(businessPartnerDetail.latitude),
        lng: parseFloat(businessPartnerDetail.longitude),
      });
    }
  }, [selectLocationModalOpen, businessPartnerDetail]);

  useEffect(() => {
    if (businessPartnerDetail && businessPartnerId) {
      const newLocation = {
        lat: businessPartnerDetail.latitude
          ? parseFloat(businessPartnerDetail.latitude)
          : defaultCenter.lat,
        lng: businessPartnerDetail.longitude
          ? parseFloat(businessPartnerDetail.longitude)
          : defaultCenter.lng,
      };
      setSelectedLocation(newLocation);
      setCenter(newLocation);
      setLocationFromCoordinates(newLocation.lat, newLocation.lng);
    }
  }, [selectLocationModalOpen, businessPartnerDetail]);

  useEffect(() => {
    if (businessPartnerId && viewBusinessPartnerModalOpen) {
      dispatch(actionGetSingleBusinessPartner(businessPartnerId));
    }
  }, [viewBusinessPartnerModalOpen, businessPartnerId]);

  const initialColumns = [
    {
      title: "CH No.",
      key: "ch_no",
      width: 100,
      dataIndex: "business_partner_id",
      fixed: "left",
      render: (text) => <div
        className="text-brand cursor-pointer"
        onClick={() => {
          dispatch(
            handleActionWithPermission("business-partner", "read", () =>
              navigate("/view-business-partner", {
                state: { id: text },
              })
            )
          );
        }}
      >{text}
      </div>,
    },
    {
      title: "Business Partner Name",
      key: "1",
      width: 200,
      ellipsis: true,
      dataIndex: "business_partner_name",
      sorter: true,
      sortIcon: () => <SortIcon />,
      fixed: "left",
      render: (text, data) => (
        <div
          onClick={() => {
            dispatch(
              handleActionWithPermission("business-partner", "read", () =>
                navigate("/view-business-partner", {
                  state: { id: data.business_partner_id },
                })
              )
            );
          }}
          className="flex text-brand items-center cursor-pointer"
        >
          {text ?? "-"}
        </div>
      ),
    },
    {
      title: "Business Partner Type",
      key: "2",
      width: 200,
      ellipsis: true,
      dataIndex: "business_partner_type",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">{text?.business_partner_type_name ?? "-"}</span>
      ),
    },
    {
      title: "Email",
      key: "3",
      width: 200,
      ellipsis: true,
      dataIndex: "email",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text ? text : "-"}</span>,
    },
    {
      title: "Mobile",
      key: "4",
      width: 200,
      ellipsis: true,
      dataIndex: "mobile",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text ? text : "-"}</span>,
    },
    {
      title: "Address 1",
      key: "5",
      width: 200,
      dataIndex: "address_1",
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          style={{ width: 200 }}
          className="ml-2 !mb-0"
        >
          {text || "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Address 2",
      key: "6",
      width: 200,
      dataIndex: "address_2",
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          style={{ width: 200 }}
          className="ml-2 !mb-0"
        >
          {text || "-"}
        </Typography.Paragraph>
      ),
    },

    {
      title: "Country",
      key: "7",
      ellipsis: true,
      width: 200,
      dataIndex: "country",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text?.name || "-"}</span>,
    },
    {
      title: "State",
      key: "8",
      ellipsis: true,
      width: 200,
      dataIndex: "state",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text?.name || "-"}</span>,
    },
    {
      title: "City",
      key: "9",
      // width: "15%",
      width: 200,
      ellipsis: true,
      dataIndex: "city",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text ?? "-"}</span>,
    },
    {
      title: "Pin Code",
      key: "10",
      width: 200,
      ellipsis: true,
      dataIndex: "zip_code",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },

    {
      title: "View on Map",
      key: "11",
      width: 200,
      ellipsis: true,
      dataIndex: "zip_code",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => {
        const handleNavigate = () => {
          const latitude = data.latitude;
          const longitude = data.longitude;

          if (latitude && longitude) {
            const googleMapsUrl = `https://www.google.com/maps?q=${encodeURIComponent(
              latitude
            )},${encodeURIComponent(longitude)}`;
            window.open(googleMapsUrl, "_blank");
          } else {
            console.error("Latitude or Longitude not available");
          }
        };

        if (!data.latitude || !data.longitude) {
          return <span>-</span>;
        }

        return (
          <span onClick={handleNavigate} className="cursor-pointer text-brand">
            View on Map
          </span>
        );
      },
    },

    {
      title: "Beat",
      key: "12",
      ellipsis: true,
      width: 200,
      dataIndex: "beat_master",
      // sorter: (a, b) => true,
      // sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text?.beat_name || "-"}</span>,
    },
    {
      title: "ERP Code",
      key: "13",
      ellipsis: true,
      width: 200,
      dataIndex: "erp_code",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "GST Number",
      key: "14",
      ellipsis: true,
      width: 200,
      dataIndex: "gst_number",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "PAN Card No",
      key: "15",
      ellipsis: true,
      width: 200,
      dataIndex: "pan_card_no",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "MSME Number",
      key: "16",
      ellipsis: true,
      width: 200,
      dataIndex: "msme_number",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "FSSAI",
      key: "17",
      ellipsis: true,
      width: 200,
      dataIndex: "fssai",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "CIN No",
      key: "18",
      ellipsis: true,
      width: 200,
      dataIndex: "cin_no",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Company Type",
      key: "19",
      ellipsis: true,
      width: 200,
      dataIndex: "type_of_company",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">
          {text?.company_type !== null ? text?.company_type : "-"}
        </span>
      ),
    },
    {
      title: "Industry",
      key: "20",
      ellipsis: true,
      width: 200,
      dataIndex: "industry",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text?.industry || "-"}</span>,
    },
    {
      title: "Company Size",
      key: "21",
      ellipsis: true,
      width: 200,
      dataIndex: "company_size",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Revenue",
      key: "22",
      ellipsis: true,
      width: 200,
      dataIndex: "revenue",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Sales Volume",
      key: "23",
      ellipsis: true,
      width: 200,
      dataIndex: "sales_volume",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Agreement Duration",
      key: "24",
      ellipsis: true,
      width: 200,
      dataIndex: "agreement_duration",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Commission Structure",
      key: "25",
      ellipsis: true,
      width: 200,
      dataIndex: "commission_structure",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Terms and Conditions",
      key: "26",
      ellipsis: true,
      width: 200,
      dataIndex: "terms_and_conditions",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          style={{ width: 200 }}
          className="ml-2 !mb-0"
        >
          {text || "-"}
        </Typography.Paragraph>
      ) /*  <span className="ml-2">{text || "-"}</span> */,
    },
    {
      title: "Feedback Mechanism",
      key: "27",
      ellipsis: true,
      width: 200,
      dataIndex: "feedback_mechanism",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          style={{ width: 200 }}
          className="ml-2 !mb-0"
        >
          {text || "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Renewal Process",
      key: "28",
      ellipsis: true,
      width: 200,
      dataIndex: "renewal_process",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          style={{ width: 200 }}
          className="ml-2 !mb-0"
        >
          {text || "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Termination Conditions",
      key: "29",
      ellipsis: true,
      width: 200,
      dataIndex: "termination_conditions",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          style={{ width: 200 }}
          className="ml-2 !mb-0"
        >
          {text || "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Bank Name",
      key: "30",
      ellipsis: true,
      width: 200,
      dataIndex: "bank_name",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Branch Name",
      key: "31",
      ellipsis: true,
      width: 200,
      dataIndex: "branch_name",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Account Type",
      key: "32",
      ellipsis: true,
      width: 200,
      dataIndex: "account_type",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "IFSC",
      key: "33",
      ellipsis: true,
      width: 200,
      dataIndex: "ifsc",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Account Number",
      key: "34",
      ellipsis: true,
      width: 200,
      dataIndex: "account_number",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Primary Contact Name",
      key: "35",
      ellipsis: true,
      width: 200,
      dataIndex: "primary_contact_name",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Primary Contact Email",
      key: "36",
      ellipsis: true,
      width: 200,
      dataIndex: "primary_contact_email",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Primary Contact Phone",
      key: "37",
      ellipsis: true,
      width: 200,
      dataIndex: "primary_contact_phone",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Secondary Contact Name",
      key: "38",
      ellipsis: true,
      width: 200,
      dataIndex: "secondary_contact_name",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Secondary Contact Email",
      key: "39",
      ellipsis: true,
      width: 200,
      dataIndex: "secondary_contact_email",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Secondary Contact Phone",
      key: "40",
      ellipsis: true,
      width: 200,
      dataIndex: "secondary_contact_phone",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      key: "action",
      width: 100,
      fixed: "right",
      render: (text, data) => (
        <>
          <Dropdown
            id="custom-drop"
            menu={{
              items: [
                {
                  label: <span>Edit</span>,
                  key: "Edit",
                  icon: <EditOutlined />,
                  onClick: () => {
                    dispatch(
                      handleActionWithPermission(
                        "business-partner",
                        "update",
                        () =>
                          navigate("/update-business-partner", {
                            state: {
                              businessPartnerId: data.business_partner_id,
                            },
                          })
                      )
                    );
                  },
                },
                {
                  label: <span>Configure Location</span>,
                  key: "config_map",
                  icon: <SiGooglemaps color="gray" />,
                  onClick: () => {
                    setSelectLocationModalOpen(true);
                    setBusinessPartnerId(data.business_partner_id);
                    dispatch(
                      actionGetSingleBusinessPartner(data.business_partner_id)
                    );
                  },
                },
                {
                  label: <span style={{ color: "#ff4d4f" }}>Delete</span>,
                  key: "Delete",
                  icon: (
                    <DeleteOutlined
                      style={{ fontSize: 12, color: "#ff4d4f" }}
                    />
                  ),
                  onClick: () =>
                    dispatch(
                      handleActionWithPermission(
                        "business-partner",
                        "delete",
                        () => {
                          setDeleteBusinessPartnerModalOpen(true);
                          setBusinessPartnerId(data.business_partner_id);
                        }
                      )
                    ),
                },
              ].filter(Boolean),
            }}
          >
            <div className="flex justify-center items-center">
              <EllipsisOutlined />
            </div>
          </Dropdown>
        </>
      ),
    },
  ];

  const [columns, setColumns] = useState(initialColumns);
  const defaultCheckedList = columns.map((item) => item.key);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const beatOptions = [];

  allBeatMasterData &&
    allBeatMasterData.length > 0 &&
    allBeatMasterData.map((data) =>
      beatOptions.push({
        label: data.beat_name,
        value: data.beat_master_id,
      }));
  const businessPartnerTypeOptions = useMemo(() => {
    return businessPartnerTypeData && businessPartnerTypeData.length > 0
      ? businessPartnerTypeData.map((data) => ({
        key: data.business_partner_type_master_id,
        label: data.business_partner_type_name,
        value: data.business_partner_type_master_id,
      }))
      : [];
  });

  const handleRefresh = () => {
    const params = {
      offset: offsetRef.current,
      limit: pageSize,
      search: search,
      orderByColumn: "",
      order: "",
      businessPartnerTypeIDs,
      countryID,
      stateID,
      city,
      pincode,
      beatIDs,
      erpCode,
      gstNumber,
      panNumber,
      msmeNumber,
      fssai,
      cinNumber,
      typeOfCompanyID,
      industryID,
    };

    dispatch(actionGetBusinessPartner(params));
  };

  useEffect(() => {
    const trimmedSearch = search?.trim();
    if (trimmedSearch !== "" && trimmedSearch !== lastSearch) {
      const timer = setTimeout(() => {
        const params = {
          offset: offsetRef.current,
          limit: pageSize,
          search: trimmedSearch,
          orderByColumn: "",
          order: "",
          businessPartnerTypeIDs,
          countryID,
          stateID,
          city,
          pincode,
          beatIDs,
          erpCode,
          gstNumber,
          panNumber,
          msmeNumber,
          fssai,
          cinNumber,
          typeOfCompanyID,
          industryID,
        };

        dispatch(actionGetBusinessPartner(params));
        setLastSearch(trimmedSearch);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }

  }, [search, lastSearch]);

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      const params = {
        offset: offsetRef.current,
        limit: pageSize,
        search: "",
        orderByColumn: "",
        order: "",
        businessPartnerTypeIDs,
        countryID,
        stateID,
        city,
        pincode,
        beatIDs,
        erpCode,
        gstNumber,
        panNumber,
        msmeNumber,
        fssai,
        cinNumber,
        typeOfCompanyID,
        industryID,
      };

      dispatch(actionGetBusinessPartner(params));
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      const params = {
        offset: offsetRef.current,
        limit: pageSize,
        search: search,
        orderByColumn: "",
        order: "",
        businessPartnerTypeIDs,
        countryID,
        stateID,
        city,
        pincode,
        beatIDs,
        erpCode,
        gstNumber,
        panNumber,
        msmeNumber,
        fssai,
        cinNumber,
        typeOfCompanyID,
        industryID,
      };

      dispatch(actionGetBusinessPartner(params));
    }
  };

  const exportAllToExcel = async () => {
    const data =
      (await allBusinessPartnerData) &&
      allBusinessPartnerData.length > 0 &&
      allBusinessPartnerData.map((item) => ({
        "Business Partner Name": item.business_partner_name ?? "-",
        "Business Partner Type":
          item.business_partner_type?.business_partner_type_name ?? "-",
        Email: item.email ?? "-",
        Mobile: item.mobile ?? "-",
        "Address 1": item.address_1 ?? "-",
        "Address 2": item.address_2 ?? "-",
        Country: item.country?.name ?? "-",
        State: item.state?.name ?? "-",
        City: item.city ?? "-",
        "Pin Code": item.zip_code ?? "-",
        Beat: item.beat_master ?? "-",
        "ERP Code": item.erp_code ?? "-",
        "GST Number": item.gst_number ?? "-",
        "PAN Card No": item.pan_card_no ?? "-",
        "MSME Number": item.msme_number ?? "-",
        FSSAI: item.fssai ?? "-",
        "CIN No": item.cin_no ?? "-",
        "Company Type":
          item &&
            item.type_of_company != null &&
            item.type_of_company.company_type
            ? item.type_of_company.company_type
            : "-",
        Industry: item.industry?.industry ?? "-",
        "Company Size": item.company_size ?? "-",
        Revenue: item.revenue ?? "-",
        "Sales Volume": item.sales_volume ?? "-",
        "Agreement Duration": item.agreement_duration ?? "-",
        "Commission Structure": item.commission_structure ?? "-",
        "Terms and Conditions": item.terms_and_conditions ?? "-",
        "Feedback Mechanism": item.feedback_mechanism ?? "-",
        "Renewal Process": item.renewal_process ?? "-",
        "Termination Conditions": item.termination_conditions ?? "-",
        "Bank Name": item.bank_name ?? "-",
        "Branch Name": item.branch_name ?? "-",
        "Account Type": item.account_type ?? "-",
        IFSC: item.ifsc ?? "-",
        "Account Number": item.account_number ?? "-",
        "Primary Contact Name": item.primary_contact_name ?? "-",
        "Primary Contact Email": item.primary_contact_email ?? "-",
        "Primary Contact Phone": item.primary_contact_phone ?? "-",
        "Secondary Contact Name": item.secondary_contact_name ?? "-",
        "Secondary Contact Email": item.secondary_contact_email ?? "-",
        "Secondary Contact Phone": item.secondary_contact_phone ?? "-",
      }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Business Partners");
    // Generate the Excel file and initiate the download
    XLSX.writeFile(workbook, "business-partners.xlsx");
    dispatch(actionClearAllBusinessPartner({ setExportAll }));
  };

  useEffect(() => {
    if (
      exportAll &&
      allBusinessPartnerData &&
      allBusinessPartnerData.length > 0
    ) {
      exportAllToExcel();
    }
  }, [allBusinessPartnerData]);

  const onClickDownloadMenu = () => {
    dispatch(actionGetAllBusinessPartner());
    setExportAll(true);
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
            ? "ASC"
            : "",
    };
    const params = {
      offset: offsetRef.current,
      limit: pageSize,
      search: search,
      orderByColumn: sorterParams.field,
      order: sorterParams.order,
      businessPartnerTypeIDs,
      countryID,
      stateID,
      city,
      pincode,
      beatIDs,
      erpCode,
      gstNumber,
      panNumber,
      msmeNumber,
      fssai,
      cinNumber,
      typeOfCompanyID,
      industryID,
    };

    dispatch(actionGetBusinessPartner(params));
  };

  const handleCloseDeleteModal = () => {
    setBusinessPartnerId("");
    setDeleteBusinessPartnerModalOpen(false);
  };

  const onLoad = (autoC) => setAutocomplete(autoC);

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();

      if (place && place.geometry) {
        setSelectedLocation({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
        setLocationInput(place.formatted_address);
      } else {
        setSelectedLocation(null);
        setLocationInput("");
      }
    }
  };

  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setSelectedLocation({ lat, lng });
  };

  const reverseGeocode = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAP_KEY}` // Replace GOOGLE_MAP_KEY with your actual API key
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.status === "OK" && data.results.length > 0) {
        return data.results[0].formatted_address;
      } else {
        console.error("Geocoding failed:", data);
        return null;
      }
    } catch (error) {
      console.error("Error fetching geocode:", error);
      return null;
    }
  };

  const handleClose = () => {
    setLocationInput("");
    setSelectedLocation(null);
    setSelectLocationModalOpen(false);
    setBusinessPartnerId("");
  };

  const handleMarkerDragEnd = useCallback(async (event) => {
    const newLocation = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };

    setSelectedLocation(newLocation);

    const placeName = await reverseGeocode(newLocation.lat, newLocation.lng);

    if (placeName) {
      setLocationInput(placeName);
    } else {
      setLocationInput("Unknown location");
    }
  }, []);

  const handleFinish = () => {
    if (selectedLocation.lat && selectedLocation.lng) {
      const req = {
        latitude: selectedLocation.lat ? selectedLocation.lat.toString() : null,
        longitude: selectedLocation.lng
          ? selectedLocation.lng.toString()
          : null,
      };
      dispatch(
        actionUpdateBusinessPartnerLatLng({
          id: businessPartnerId,
          req,
          handleClose,
        })
      );
    }
  };

  const rowSelection = {
    selectedRowKeys,
    columnWidth: 70,
    onChange: onSelectChange,
  };

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  //filter modal start

  const handleOpenFilterModal = () => {
    setFilterModal(true);
    if (businessPartnerTypeOptions && businessPartnerTypeOptions.length === 0) {
      dispatch(actionGetBusinessPartnerTypeMaster());
    }
    if (contryOption && contryOption.length === 0) {
      dispatch(actionGetCountries());
    }
    if (beatOptions && beatOptions.length === 0) {
      dispatch(actionGetAllBeatMaster());
    }
    if (typeofCompanyOption && typeofCompanyOption.length === 0) {
      dispatch(actionGetTypeOfCompanyMaster());
    }
    if (industryOption && industryOption.length === 0) {
      dispatch(actionGetIndustryMaster());
    }
  };

  const contryOption = [];
  const stateOption = [];
  const typeofCompanyOption = [];
  const industryOption = [];

  countries &&
    countries.map((data) =>
      contryOption.push({
        label: data.name,
        value: data.id,
      })
    );

  states &&
    states.map((data) =>
      stateOption.push({
        label: data.name,
        value: data.id,
      })
    );

  typeOfCompanyData &&
    typeOfCompanyData.length > 0 &&
    typeOfCompanyData.map((data) =>
      typeofCompanyOption.push({
        label: data.company_type,
        value: data.type_of_company_id,
      }));

  industryData &&
    industryData.length > 0 &&
    industryData.map((data) =>
      industryOption.push({
        label: data.industry,
        value: data.industry_master_id,
      }));

  const handleApplyFilter = (values) => {
    setBusinessPartnerTypeIDs(values.business_partner_type_master_id)
    setCountryID(values.country_id)
    setStateID(values.state_id)
    setCity(values.city)
    setBeatIDs(values.beat_master_id)
    setPincode(values.zip_code)
    setErpCode(values.erp_code)
    setGstNumber(values.gst_number)
    setPanNumber(values.pan_card_no)
    setMsmeNumber(values.msme_number)
    setFssai(values.fssai)
    setCinNumber(values.cin_no)
    setTypeOfCompanyID(values.type_of_company_id)
    setIndustryID(values.industry_master_id)

    const fieldsWithValues = Object.keys(values)
      .filter((field) => {
        const value = values[field];
        return (
          value !== undefined &&
          value !== null &&
          value !== "" &&
          (!Array.isArray(value) || value.length > 0) // Check for empty arrays
        );
      })
      .map((field) => field);

    setFilteredFields(fieldsWithValues);

    const hasValidFilterValues = Object.values(values).some(
      (value) =>
        value !== undefined && // Not undefined
        (Array.isArray(value) ? value.length > 0 : value !== "") // Non-empty array or non-empty string/number
    );
    // setFilterValues(hasValidFilterValues);
    if (fieldsWithValues && fieldsWithValues.length > 0) {
      setFilterValues(fieldsWithValues);
    } else {
      setFilterValues(0);
    }
    const params = {
      offset: offsetRef.current,
      limit: pageSize,
      search: search,
      orderByColumn: "",
      order: "",
      businessPartnerTypeIDs: values.business_partner_type_master_id,
      countryID: values.country_id,
      stateID: values.state_id,
      city: values.city,
      pincode: values.zip_code,
      beatIDs: values.beat_master_id,
      erpCode: values.erp_code,
      gstNumber: values.gst_number,
      panNumber: values.pan_card_no,
      msmeNumber: values.msme_number,
      fssai: values.fssai,
      cinNumber: values.cin_no,
      typeOfCompanyID: values.type_of_company_id,
      industryID: values.industry_master_id,
    };

    dispatch(actionGetBusinessPartner(params));
    setFilterModal(false);
  };

  const handleCloseFilterModal = () => {
    setFilterModal(false);
    if (!filterValues) {
      filterForm.resetFields();
      setFilterValues(0);
      setBusinessPartnerTypeIDs(null);
      setCountryID(null);
      setStateID(null);
      setCity("");
      setPincode("");
      setBeatIDs([]);
      setErpCode("");
      setGstNumber("");
      setPanNumber("");
      setMsmeNumber("");
      setFssai("");
      setCinNumber("");
      setTypeOfCompanyID(null);
      setIndustryID(null);
      setCountrySelected(false);
    }
    setIsFilterFormChanged(false)
  };

  const handleResetFilterModal = () => {
    filterForm.resetFields();
    setFilterValues(0);
    setBusinessPartnerTypeIDs(null);
    setCountryID(null);
    setStateID(null);
    setCity("");
    setPincode("");
    setBeatIDs([]);
    setErpCode("");
    setGstNumber("");
    setPanNumber("");
    setMsmeNumber("");
    setFssai("");
    setCinNumber("");
    setTypeOfCompanyID(null);
    setIndustryID(null);
    setIsReset(true);
    setFilteredFields([]);
    setCountrySelected(false);
    if (filterValues) {
      const params = {
        offset: offsetRef.current,
        limit: pageSize,
        search: search,
      };

      dispatch(actionGetBusinessPartner(params));
    }
  };

  const getFieldLabel = (fieldWithValue) => {
    switch (fieldWithValue) {
      case "business_partner_type_master_id":
        return "Business Partner Type";
      case "country_id":
        return "Country";
      case "state_id":
        return "State";
      case "city":
        return "City";
      case "zip_code":
        return "Pin Code";
      case "beat_master_id":
        return "Beat";
      case "erp_code":
        return "ERP Code";
      case "gst_number":
        return "GST Number";
      case "pan_card_no":
        return "PAN Card Number";
      case "msme_number":
        return "MSME Number";
      case "fssai":
        return "FSSAI";
      case "cin_no":
        return "CIN Number";
      case "type_of_company_id":
        return "Company Type";
      case "industry_master_id":
        return "Industry";
      default:
        return fieldWithValue; // Default to field name if no case matches
    }
  };

  const handleGetState = (val, data) => {
    dispatch(actionGetStates(data.value));
    setCountrySelected(true);
    filterForm.setFieldsValue({
      state_id: null,
    });
  };

  const handleClearState = () => {
    setCountrySelected(false);
    filterForm.setFieldsValue({
      state_id: null,
    });
  };

  useEffect(() => {
    if (filterModal) {
      filterForm.setFieldsValue({
        business_partner_type_master_id: businessPartnerTypeIDs ? businessPartnerTypeIDs : null,
        country_id: countryID ? countryID : null,
        state_id: stateID ? stateID : null,
        city: city ? city : null,
        beat_master_id: beatIDs ? beatIDs : null,
        zip_code: pincode ? pincode : null,
        erp_code: erpCode ? erpCode : null,
        gst_number: gstNumber ? gstNumber : null,
        pan_card_no: panNumber ? panNumber : null,
        msme_number: msmeNumber ? msmeNumber : null,
        fssai: fssai ? fssai : null,
        cin_no: cinNumber ? cinNumber : null,
        type_of_company_id: typeOfCompanyID ? typeOfCompanyID : null,
        industry_master_id: industryID ? industryID : null,
      })
      if (countryID) {
        setCountrySelected(true);
        dispatch(actionGetStates(countryID))
      }
    }
  }, [filterModal, filterForm])

  //filter modal end

  const handlePaginationChange = (page, pageSize) => {
    offsetRef.current = page - 1;
    setPageSize(pageSize);
    const params = {
      offset: offsetRef.current,
      limit: pageSize,
      search: search,
      orderByColumn: "",
      order: "",
      businessPartnerTypeIDs,
      countryID,
      stateID,
      city,
      pincode,
      beatIDs,
      erpCode,
      gstNumber,
      panNumber,
      msmeNumber,
      fssai,
      cinNumber,
      typeOfCompanyID,
      industryID,
    };
    dispatch(actionGetBusinessPartner(params));
  }

  return (
    <>
      <section className="main-wrapper main-table-container ">
        <CommonTable
          moduleKey="business-partner"
          title="Business Partners"
          total={businessPartnersCount}
          pageSize={pageSize}
          pageTitle={<PageTitle
            margin={false}
            image={businessPartner}
            title="Business Partners"
            rightIcon={false}
          />}
          buttonText="Create Business Partner"
          buttonLink="/create-business-partner"
          resource="business-partner"
          loading={getBusinessPartnerLoader || getAllBusinessPartnerLoader}
          isCheckbox={true}
          columns={columns}
          scrollToFirstRowOnChange={true}
          data={businessPartnerData}
          rowKey={"business_partner_id"}
          rowSelection={rowSelection}
          onChange={handleSortChange}
          handleRefresh={handleRefresh}
          handleSearch={handleSearch}
          handleBlurAndPressEnter={handleBlurAndPressEnter}
          search={search}
          importRoute="/business-partners-import"
          isImportEnabled={true}
          onClickDownloadMenu={onClickDownloadMenu}
          filterValues={filterValues}
          isAdvanceFilter={true}
          onClickFilter={handleOpenFilterModal}
          scroll={{ y: false }}
          content={
            <>
              {selectedRowKeys.length > 0 && (
                <TooltipWrapper title={"Bulk Edit"}>
                  <Button
                    style={{ backgroundColor: "white" }}
                    onClick={() => {
                      setIsModalOpen(true);
                      dispatch(actionGetAllBeatMaster());
                      dispatch(actionGetBusinessPartnerTypeMaster());
                    }}
                    className="h-full  default-button bg-white  icon-btn"
                  >
                    <Image
                      preview={false}
                      height={17}
                      width={17}
                      src={bulkUpdate}
                      alt="bulk Edit"
                    />
                  </Button>
                </TooltipWrapper>
              )}
            </>
          }
          filteredFields={filteredFields}
          getFieldLabel={getFieldLabel}
          handleResetFilter={handleResetFilterModal}
          handlePaginationChange={handlePaginationChange}
        />
        <Modal
          className="max-h-[100vh]"
          centered={true}
          footer={false}
          destroyOnClose={handleCloseDeleteModal}
          open={deleteBusinessPartnerModalOpen}
          title=""
          onCancel={handleCloseDeleteModal}
          onOk={handleCloseDeleteModal}
        >
          <p className="text-[19px] text-black font-popinsMedium mt-2">
            Are you sure you want to remove this business p artner ?
          </p>
          <div className=" flex justify-end mt-[30px]">
            <Button
              onClick={handleCloseDeleteModal}
              type="button"
              className="gray-button text-white hover:text-white min-h-[45px] min-w-[110px]"
            >
              Cancel
            </Button>

            <div className="ml-4">
              <Button
                onClick={() =>
                  dispatch(
                    actionDeleteBusinessPartner({
                      id: businessPartnerId,
                      handleCloseDeleteModal,
                    })
                  )
                }
                loading={deleteBusinessPartnerLoader}
                className="delete-btn min-h-[45px] min-w-[110px]"
              >
                Delete
              </Button>
            </div>
          </div>
        </Modal>



        <Modal
          title="Configure Location"
          open={selectLocationModalOpen}
          width={700}
          onOk={handleClose}
          onCancel={handleClose}
          loading={getBusinessPartnerDetailLoader}
          footer={false}
        >
          <LoadScript
            // loadingElement={<Spin />}
            libraries={["places"]}
            googleMapsApiKey={GOOGLE_MAP_KEY}
          >
            <div>
              <Form layout="vertical">
                <Form.Item>
                  <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                    <Input
                      placeholder="Enter location"
                      value={locationInput}
                      onChange={(e) => setLocationInput(e.target.value)} // Update state
                    />
                  </Autocomplete>
                </Form.Item>
              </Form>

              <GoogleMap
                streetView={false}
                options={{
                  fullscreenControl: false,
                  streetViewControl: false,
                  zoomControl: false,
                }}
                mapContainerStyle={{ width: "100%", height: "400px" }}
                center={selectedLocation || center}
                zoom={12}
                onClick={handleMapClick}
              >
                {selectedLocation && (
                  <Marker
                    draggable={true}
                    animation={true}
                    onDragEnd={handleMarkerDragEnd}
                    position={selectedLocation}
                  />
                )}
              </GoogleMap>
            </div>
          </LoadScript>

          <div className="flex mt-6 justify-end  items-center gap-x-4">
            <Button
              style={{ borderRadius: 5 }}
              type="button"
              className="gray-button max-h-[32px] text-white hover:text-white min-h-[40px] min-w-[100px]"
              onClick={handleClose}
            >
              Cancel
            </Button>

            <Button
              loading={updateBusinessPartnerLatlngLoader}
              style={{ borderRadius: 5 }}
              type="primary"
              className="root-btn min-h-[40px] min-w-[100px]"
              onClick={handleFinish}
            >
              Apply
            </Button>
          </div>
        </Modal>

        <Modal
          title={null}
          open={isModalOpen}
          width={550}
          onOk={onModalClose}
          onCancel={onModalClose}
          loading={false}
          footer={false}
          afterClose={() => {
            form.resetFields();
          }}
        >
          <div className="flex items-center gap-x-2">
            <Image
              preview={false}
              height={20}
              width={20}
              src={bulkUpdate}
              alt="bulk Edit"
            />{" "}
            <h1 className="text-[#6883FD] text-[20px]  font-medium">
              Bulk Edit
            </h1>
          </div>
          <Form
            form={form}
            onFinish={(values) => {
              const req = {
                business_partner_id: selectedRowKeys,
                business_partner_type_master_id:
                  values.business_partner_type_master_id
                    ? values.business_partner_type_master_id
                    : null,
                beat_master_id: values.beat_master_id
                  ? values.beat_master_id
                  : null,
              };

              dispatch(
                actionUpdateBulkBusinessPartner({
                  req,
                  handleClose: onModalClose,
                })
              );
            }}
            layout="vertical"
            className="mt-[20px] mb-0"
          >
            <Form.Item
              name="business_partner_type_master_id"
              label="Business Partner Type"
              className="mb-[12px]"
              size="small"
            >
              <Select
                allowClear
                showSearch
                className="mb-5 w-full sales-order-select"
                placeholder="Select Business Partner Type"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                loading={getBusinessPartnerTypeLoader}
                options={businessPartnerTypeOptions}
              // disabled={getBusinessPartnerTypeLoader}
              />
            </Form.Item>
            <Form.Item
              name="beat_master_id"
              label="Beat"
              className="mb-[12px]"
              size="small"
            >
              <Select
                allowClear
                showSearch
                className="mb-5 w-full sales-order-select"
                placeholder="Select Beat"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                loading={getAllBeatMasterLoader}
                options={beatOptions}
              // disabled={getBusinessPartnerTypeLoader}
              />
            </Form.Item>

            <div className="flex items-center my-4 justify-center gap-x-5">
              <Form.Item className="mb-0">
                <Button
                  onClick={async () => {
                    setIsModalOpen(false);
                  }}
                  type="button"
                  className="gray-button text-white hover:text-white  min-h-[45px] min-w-[120px]"
                  htmlType="button"
                >
                  Cancel
                </Button>
              </Form.Item>
              <Form.Item className="mb-0">
                <Button
                  loading={updateBulkBusinessPartnerLoader}
                  type="primary"
                  className="root-btn min-h-[45px] min-w-[120px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Modal>

        {/* Filter Drawer code start  */}
        <Drawer
         closable
         destroyOnClose
          className="business-filter-drawer"
          title={
            <div className="z-10 flex items-center justify-between">
              <div>
                <CustomIconText
                  icon={<LuFilter size={25} color="#6883fd" />}
                  title="Business Partner Filter"
                />
              </div>
              {/* <CloseOutlined onClick={handleCloseFilterModal} /> */}
            </div>
          }
          placement="right"
          open={filterModal}
          width={500}
          footer={false}
          onClose={handleCloseFilterModal}
        >
          <section className="main-section pr-2 drawer-scroll">
            <Form
              form={filterForm}
              layout="vertical"
              name="basic"
              onValuesChange={() => setIsFilterFormChanged(true)}
              onFinish={handleApplyFilter}
              autoComplete="off"
            >
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Business Partner Type" name="business_partner_type_master_id" className="mb-3">
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Business Partner Type"
                      optionFilterProp="children"
                      className=""
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={businessPartnerTypeOptions}
                      loading={getBusinessPartnerTypeLoader}
                      disabled={getBusinessPartnerTypeLoader}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Country" name="country_id" className="mb-3">
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Country"
                      optionFilterProp="children"
                      className=""
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      // onChange={(value) => {
                      //   setCountryID(value),
                      //     dispatch(actionGetStates(value));
                      //   filterForm.setFieldsValue({ state_id: undefined });
                      //   setStateID(null);
                      // }}
                      onClear={handleClearState}
                      onSelect={handleGetState}
                      options={contryOption}
                      loading={getCountryLoader}
                      disabled={getCountryLoader}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="State" name="state_id" className="mb-3">
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select State"
                      optionFilterProp="children"
                      className=""
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={stateOption}
                      loading={statesLoader}
                      disabled={!countrySelected || statesLoader}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="City" name="city" className="mb-3"
                    normalize={(value) =>
                      value ? value.replace(/[^a-zA-Z0-9\s-]/g, "") : "" // Allow letters, numbers, spaces, and hyphens
                    }
                  >
                    <Input
                      placeholder="Enter City"
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Beat" name="beat_master_id" className="mb-3">
                    <Select
                      allowClear
                      showSearch
                      mode="multiple"
                      maxTagCount='responsive'
                      placeholder="Select Beat"
                      optionFilterProp="children"
                      className=""
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={beatOptions}
                      loading={getAllBeatMasterLoader}
                      disabled={getAllBeatMasterLoader}
                      maxTagPlaceholder={(omittedValues) => (
                        <Tooltip
                          overlayStyle={{
                            pointerEvents: 'none',
                          }}
                          title={omittedValues.map(({ label }) => label).join(', ')}
                        >
                          <span>{`+${omittedValues.length} more`}</span>
                        </Tooltip>
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Pin Code" name="zip_code" className="mb-3"
                    normalize={(value) =>
                      value ? value.replace(/[^0-9]/g, "") : ""
                    }
                  >
                    <Input
                      placeholder="Enter Pin Code"
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="ERP Code" name="erp_code" className="mb-3">
                    <Input
                      placeholder="Enter ERP Code"
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="GST Number"
                    name="gst_number"
                    className="mb-3"
                    rules={[
                      {
                        required: false,
                        pattern:
                          /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}[Z]{1}[A-Z0-9]{1}$/,
                        message: "Please enter valid gst number!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Gst Number"
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="PAN Card Number"
                    name="pan_card_no"
                    className="mb-3"
                    rules={[
                      {
                        required: false,
                        pattern: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                        message: "Please enter valid pan card number!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Pan Card Number"
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="MSME Number"
                    name="msme_number"
                    className="mb-3"
                  >
                    <Input
                      placeholder="Enter Msme Number"
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="FSSAI"
                    name="fssai"
                    className="mb-3"
                    rules={[
                      {
                        required: false,
                        message: "Please enter fssai!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Fssai"
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="CIN Number"
                    name="cin_no"
                    className="mb-3"
                  >
                    <Input
                      placeholder="Enter Cin Number"
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Company Type" name="type_of_company_id" className="mb-3">
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Company Type"
                      optionFilterProp="children"
                      className=""
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={typeofCompanyOption}
                      loading={getTypeOfCompanyLoader}
                      disabled={getTypeOfCompanyLoader}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Industry" name="industry_master_id" className="mb-3">
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Industry"
                      optionFilterProp="children"
                      className=""
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={industryOption}
                      loading={getIndustryLoader}
                      disabled={getIndustryLoader}
                    />
                  </Form.Item>
                </Col>
                <div className="fixed bottom-4 right-24">
                  <Col span={24}
                    className="flex items-center justify-center gap-x-6 mt-3"
                  >
                    <Form.Item className="mb-0">
                      <Button
                        type="button"
                        className="gray-button text-white hover:text-white  min-h-[45px] min-w-[120px]"
                        onClick={handleResetFilterModal}
                      >
                        Reset
                      </Button>
                    </Form.Item>
                    <Form.Item className="mb-0">
                      <Button
                        disabled={!isFilterFormChanged}
                        loading={isReset ? "" : getBusinessPartnerLoader}
                        type="primary"
                        className="root-btn min-h-[45px] min-w-[120px]"
                        htmlType="submit"
                      >
                        Apply
                      </Button>
                    </Form.Item>
                  </Col>
                </div>
              </Row>
            </Form>
          </section>
        </Drawer>
        {/* Filter Drawer code end  */}
      </section>
    </>
  );
};

export default BusinessPartners;
