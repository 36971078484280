import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Form,
  Image,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Popover,
  Row,
  Select,
  Skeleton,
  Spin,
  Table,
  Typography,
  Upload,
} from "antd";
import ImgCrop from "antd-img-crop";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import * as XLSX from "xlsx";
import editPrimary from "../../../assets/img/editPrimary.svg";
import exibhitors from "../../../assets/img/exibhitors.svg";
import plusSvg from "../../../assets/img/plus.svg";
import { BASE_URL_UPLOAD } from "../../../config/web-config";
import {
  actionAddEventExhibitor,
  actionClearAllEventExhibitorsData,
  actionDeleteEventExhibitor,
  actionEventExhibitorDetail,
  actionGetAllEventExhibitors,
  actionGetEventExhibitors,
  actionUpdateEventExhibitor,
} from "../../../store/services/eventService";
import {
  actionAddExhibitionCategoryMaster,
  actionGetExhibitionCategoryMaster,
} from "../../../store/services/masterDataService";
import CommonModal from "../../common/CommonModal";
import CustomInputWithButtons from "../../common/CustomInputWithButtons ";
import PageTitle from "../../common/PageTitle";
import SortIcon from "../../common/SortIcon";
import {
  extractFilename,
  formatUrl,
  getEventId,
  sanitizeName,
} from "../../helper/common-function";
import EventDetailWrapper from "./EventDetailWrapper";
import usePermission from "../../../hooks/use-permission";
import { handleActionWithPermission } from "../../../store/slices/authSlice";
import SkeletonTable from "../../common/SkeletonTable";
import SkeletonViewModal from "../../common/SkeletonViewModal";
import SkeletonUpdateCard from "../../common/SkeletonUpdateCard";
import CommonTable from "../../common/CommonTable";
import CommonPagination from "../../common/CommonPagination";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const Exhibitors = () => {
  const dispatch = useDispatch();
  const eventId = getEventId();
  const [search, setSearch] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [profilePicUrl, setProfilePicUrl] = useState("");
  const [exhibitorData, setExhibitorData] = useState(null);
  const [isExhibitorCategoryOpen, setIsExhibitorCategoryModalOpen] =
    useState(false);
  const [exportAll, setExportAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lastSearch, setLastSearch] = useState('');
  const [pageSize, setPageSize] = useState(20);
  const [form] = Form.useForm();
  const [exhibitorCategoryModalForm] = Form.useForm();
  const offsetRef = useRef(0);
  const { hasPermission } = usePermission();

  const {
    eventExhibitorsData,
    getExhibitorsLoader,
    exhibitorsDataCount,
    getExhibitorDetail,
    getExhibitorDetailLoader,
    deleteExhibitorLoader,
    allExhibitorsData,
    updateExhibitorLoader,
    addExhibitorLoader,
    getAllExhibitorsLoader,
  } = useSelector((state) => state.events);
  const {
    exhibitionCategoryData,
    getExhibitionCategoryLoader,
    addExhibitionCategoryLoader,
  } = useSelector((state) => state.masterData);

  useEffect(() => {
    if (eventId) {
      dispatch(
        actionGetEventExhibitors({ offset: offsetRef.current, limit: pageSize, search, eventId })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (exhibitorData) {
      dispatch(
        actionEventExhibitorDetail({
          id: exhibitorData.event_exhibitor_id,
          eventId,
        })
      );
    }
  }, [dispatch, exhibitorData]);

  useEffect(() => {
    if (exhibitorData && getExhibitorDetail) {
      form.setFieldsValue({
        exhibitor_name: getExhibitorDetail.exhibitor_name,
        exhibitor_contact_person: getExhibitorDetail.exhibitor_contact_person,
        exhibitor_contact_person_email:
          getExhibitorDetail.exhibitor_contact_person_email,
        exhibitor_contact_person_phone:
          getExhibitorDetail.exhibitor_contact_person_phone,
        booth_number: getExhibitorDetail.booth_number,
        exhibitor_category_id:
          getExhibitorDetail.exhibitor_category
            ?.event_exhibitor_category_master_id,
        exhibitor_website: getExhibitorDetail.exhibitor_website,
      });
      setProfilePicUrl(getExhibitorDetail.exhibitor_image);
    }
  }, [getExhibitorDetail]);

  const columns = [
    {
      title: "CH No.",
      key: "ch_no",
      width: 100,
      dataIndex: "event_exhibitor_id",
      fixed: "left",
      render: (text, data) =>
        <div
          onClick={() => {
            dispatch(
              handleActionWithPermission("event-exhibitor", "read", () => {
                setExhibitorData(data);
                setViewModalOpen(true);
              })
            );
          }}
          className="flex text-brand items-center cursor-pointer"
        >
          {text ?? "-"}

        </div>,
    },
    {
      title: "Exhibitor Name & Image",
      key: "1",
      dataIndex: "exhibitor_name",
      fixed: "left",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 250,
      render: (text, data) => (
        <div
          onClick={() => {
            dispatch(
              handleActionWithPermission("event-exhibitor", "read", () => {
                setExhibitorData(data);
                setViewModalOpen(true);
              })
            );
          }}
          className="flex items-center cursor-pointer"
        >
          <Avatar
            style={{ verticalAlign: "middle" }}
            src={data.exhibitor_image}
            size={30}
          />
          <p className="ml-2 text-brand">
            {data.exhibitor_name.charAt(0).toUpperCase() +
              data.exhibitor_name.slice(1)}
          </p>
        </div>
      ),
    },
    {
      title: "Contact Person Name",
      dataIndex: "exhibitor_contact_person",
      key: "2",
      width: 250,
      sorter: (a, b) => a.exhibitor_contact_person - b.exhibitor_contact_person,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span className="ml-2">
          {data.exhibitor_contact_person ? data.exhibitor_contact_person : "-"}
        </span>
      ),
    },
    {
      title: "Contact Person Email",
      key: "3",
      width: 250,
      dataIndex: "exhibitor_contact_person_email",
      sorter: (a, b) =>
        a.exhibitor_contact_person_email - b.exhibitor_contact_person_email,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span className="ml-2">
          {data.exhibitor_contact_person_email
            ? data.exhibitor_contact_person_email
            : "-"}
        </span>
      ),
    },
    {
      title: "Contact Person Phone",
      dataIndex: "exhibitor_contact_person_phone",
      key: "4",
      width: 250,
      sorter: (a, b) =>
        a.exhibitor_contact_person_phone - b.exhibitor_contact_person_phone,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span className="ml-2">
          {data.exhibitor_contact_person_phone
            ? data.exhibitor_contact_person_phone
            : "-"}
        </span>
      ),
    },
    {
      title: "Booth Number",
      dataIndex: "booth_number",
      key: "5",
      width: 180,
      sorter: (a, b) => a.booth_number - b.booth_number,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span className="ml-2">
          {data.booth_number ? data.booth_number : "-"}
        </span>
      ),
    },
    {
      title: "Exhibitor Category",
      dataIndex: "exhibitor_category",
      key: "6",
      width: 200,
      render: (text, data) => (
        <span className="ml-2">
          {data.exhibitor_category &&
            data.exhibitor_category &&
            data.exhibitor_category?.exhibitor_category
            ? data.exhibitor_category?.exhibitor_category
            : "-"}
        </span>
      ),
    },
    {
      title: "Exhibitor Website",
      dataIndex: "exhibitor_website",
      key: "7",
      width: 200,
      sorter: (a, b) => a.exhibitor_website - b.exhibitor_website,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: true }}
          style={{ width: 280, margin: 0 }}
          className="ml-2 !mb-0"
        >
          {/* <a href={text} target="_blank">
            {text || "-"}
          </a> */}
          <a
            target="_blank"
            href={formatUrl(
              data &&
              data.exhibitor_website &&
              data.exhibitor_website != null &&
              data.exhibitor_website
            )}
            className="ml-2 text-blue-500"
          >
            {data.exhibitor_website ? data.exhibitor_website : "-"}
          </a>
        </Typography.Paragraph>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 100,
      fixed: "right",
      render: (text, data) => (
        <>
          <Dropdown
            id="custom-drop"
            menu={{
              items: [
                {
                  label: <span>Edit</span>,
                  key: "Edit",
                  icon: <EditOutlined />,
                  onClick: () => {
                    dispatch(
                      handleActionWithPermission(
                        "event-exhibitor",
                        "update",
                        () => {
                          setIsModalOpen(true);
                          setExhibitorData(data);
                          dispatch(actionGetExhibitionCategoryMaster());
                        }
                      )
                    );
                  },
                },
                // {
                //   label: <span>View</span>,
                //   key: "View",
                //   icon: <EyeOutlined />,
                //   onClick: () =>
                //     navigate("/setting/update-user", { state: { userId: data.id } }),
                // },
                {
                  label: hasPermission("event-exhibitor", "delete") ? (
                    <Popconfirm
                      okButtonProps={{
                        loading: deleteExhibitorLoader,
                      }}
                      placement="topLeft"
                      title={`Are you sure to delete this exhibitor?`}
                      onConfirm={() => {
                        // handleOk
                        dispatch(
                          actionDeleteEventExhibitor({
                            id: data.event_exhibitor_id,
                            event_id: eventId,
                          })
                        );
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <div className="flex items-center">
                        <DeleteOutlined
                          style={{ fontSize: 12, color: "#ff4d4f" }}
                        />
                        <span style={{ marginLeft: 8, color: "#ff4d4f" }}>
                          Delete
                        </span>
                      </div>
                    </Popconfirm>
                  ) : (
                    <div
                      className="flex items-center"
                      onClick={() => {
                        dispatch(
                          handleActionWithPermission(
                            "event-exhibitor",
                            "delete"
                          )
                        );
                      }}
                    >
                      <DeleteOutlined
                        style={{ fontSize: 12, color: "#ff4d4f" }}
                      />
                      <span
                        style={{
                          marginLeft: 8,
                          color: "#ff4d4f",
                        }}
                      >
                        Delete
                      </span>
                    </div>
                  ),
                  key: "Delete",
                },
              ].filter(Boolean),
            }}
          >
            <div className="flex justify-center items-center">
              <EllipsisOutlined />
            </div>
          </Dropdown>
        </>
      ),
    },
  ];

  const onFinish = (values) => {
    const fileUrl = profilePicUrl
      ? extractFilename(profilePicUrl && profilePicUrl)
      : "";

    const data = {
      ...values,
      exhibitor_image: fileUrl,
      event_id: eventId,
    };

    const req = {
      ...Object.fromEntries(
        Object.entries(data).filter(
          ([_, v]) =>
            v !== undefined &&
            v !== null &&
            !(typeof v === "string" && v.trim() === "") &&
            (!Array.isArray(v) || v.length > 0)
        )
      ),
    };

    exhibitorData
      ? dispatch(
        actionUpdateEventExhibitor({
          id: getExhibitorDetail.event_exhibitor_id,
          req,
          handleCancel,
        })
      )
      : dispatch(
        actionAddEventExhibitor({
          req,
          handleCancel,
        })
      );
  };

  const handleChangeUserPic = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);

        setProfilePicUrl(info.file.response.url);
        toast.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      setLoading(false);
      toast.error(`${info.file.name} file upload failed.`);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setExhibitorData(false);
    form.resetFields();
    setProfilePicUrl("");
  };

  const handleRefresh = () => {
    dispatch(
      actionGetEventExhibitors({
        offset: offsetRef.current,
        limit: pageSize,
        search: search,
        eventId,
      })
    );
  };

  useEffect(() => {
    const trimmedSearch = search?.trim();
    if (trimmedSearch !== "" && trimmedSearch !== lastSearch) {
      const timer = setTimeout(() => {
        dispatch(
          actionGetEventExhibitors({ offset: offsetRef.current, limit: pageSize, search: trimmedSearch, eventId })
        );
        setLastSearch(trimmedSearch);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }

  }, [search, lastSearch]);

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      dispatch(
        actionGetEventExhibitors({
          offset: offsetRef.current,
          limit: pageSize,
          search: e.target.value,
          eventId,
        })
      );
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      dispatch(
        actionGetEventExhibitors({
          offset: offsetRef.current,
          limit: pageSize,
          search: e.target.value,
          eventId,
        })
      );
    }
  };

  const onClickDownloadMenu = () => {
    dispatch(actionGetAllEventExhibitors({ eventId }));
    setExportAll(true);
  };

  const handleCancelExhibitorCategoryModal = () => {
    setIsExhibitorCategoryModalOpen(false);
    exhibitorCategoryModalForm.resetFields();
  };

  const showExhibitorCategoryOpen = () => {
    setIsExhibitorCategoryModalOpen(true);
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
            ? "ASC"
            : "",
    };

    dispatch(
      actionGetEventExhibitors({
        offset: offsetRef.current,
        limit: pageSize,
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
        eventId,
      })
    );
  };

  const exportAllToExcel = async () => {
    const data =
      (await allExhibitorsData) &&
      allExhibitorsData.length > 0 &&
      allExhibitorsData.map((data) => ({
        "Exhibitor Name": data.exhibitor_name ? data.exhibitor_name : "-",

        "Contact Person Name": data.exhibitor_contact_person
          ? data.exhibitor_contact_person
          : "-",
        "Contact Person Email": data.exhibitor_contact_person_email
          ? data.exhibitor_contact_person_email
          : "-",
        "Contact Person Phone": data.exhibitor_contact_person_phone
          ? data.exhibitor_contact_person_phone
          : "-",
        "Booth Number": data.booth_number ? data.booth_number : "-",
        "Exhibitor Category": data.exhibitor_category?.exhibitor_category
          ? data.exhibitor_category?.exhibitor_category
          : "-",
        "Exhibitor Website": data.exhibitor_website
          ? data.exhibitor_website
          : "-",
      }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Exhibitors");
    // Generate the Excel file and initiate the download
    XLSX.writeFile(workbook, "exhibitors.xlsx");
    dispatch(actionClearAllEventExhibitorsData({ setExportAll }));
  };

  useEffect(() => {
    if (exportAll && allExhibitorsData && allExhibitorsData.length > 0) {
      exportAllToExcel();
    }
  }, [allExhibitorsData]);

  const cateData =
    exhibitionCategoryData &&
    exhibitionCategoryData.length > 0 &&
    exhibitionCategoryData.map((data) => ({
      label: data.exhibitor_category,
      value: data.event_exhibitor_category_master_id,
    }));

  const exhibitoreCategoryPopover = (
    <>
      <div className="min-w-[350px]">
        <Form
          name=""
          form={exhibitorCategoryModalForm}
          onFinish={(values) =>
            dispatch(
              actionAddExhibitionCategoryMaster({
                form,
                values,
                handleCancel: handleCancelExhibitorCategoryModal,
              })
            )
          }
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="exhibitor_category"
            label="Exhibitor Category"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please enter exhibitor category!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Exhibitor Category"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={() => handleCancelExhibitorCategoryModal()}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addExhibitionCategoryLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );

  return (
    <EventDetailWrapper>
      <div className="event-table-container main-table-container" style={{ height: "calc(100vh - 130px)" }}>

        <CommonTable
          pageTitle={<PageTitle
            image={exibhitors}
            title="Exhibitors"
            rightIcon={false}
          />}
          buttonText="Add Exibhitor"
          onAddClick={() => {
            setIsModalOpen(true);
            dispatch(actionGetExhibitionCategoryMaster());
          }}
          wrapperClassName="2xl:max-h-[calc(100vh-140px)] xl:max-h-[calc(100vh-120px)] max-h-[calc(100vh-120px)]"
          title="Exhibitors"
          offset={offsetRef.current}
          pageSize={pageSize}
          handlePaginationChange={(page, pageSize) => {
            offsetRef.current = page - 1;
            setPageSize(pageSize);
            dispatch(
              actionGetEventExhibitors({
                offset: offsetRef.current,
                limit: pageSize,
                search,
                eventId,
              })
            );
          }}
          total={exhibitorsDataCount}
          type="btn"
          resource="event-exhibitor"
          moduleKey="event_exhibitor"
          loading={getExhibitorsLoader || getAllExhibitorsLoader}
          columns={columns}
          scrollToFirstRowOnChange={true}
          data={(eventExhibitorsData || [])}
          rowKey={"event_exhibitor_id"}
          onChange={handleSortChange}
          handleRefresh={handleRefresh}
          handleSearch={handleSearch}
          handleBlurAndPressEnter={handleBlurAndPressEnter}
          search={search}
          onClickDownloadMenu={onClickDownloadMenu}
        />



        <Modal
          centered
          onOk={handleCancel}
          onCancel={handleCancel}
          afterClose={handleCancel}
          footer={false}
          width={800}
          title=""
          open={isModalOpen}
        >
          <div className="text-brand flex items-center gap-x-3">
            <Image
              src={exibhitors}
              alt="image"
              height={20}
              width={20}
              preview={false}
            />
            <h1 className="lg:text-lg text-base  font-popinsRegular">
              {exhibitorData ? "Update" : "Add"} Exhibitor
            </h1>
          </div>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            className="mt-[20px] mb-0"
          >
            {/* <Spin> */}
            {/* <Spin spinning={getExhibitorDetailLoader}> */}
            <Row gutter={[16, 0]}>
              <Col span={24} align="middle">
                <Form.Item
                  className="mb-[12px]"
                  size="small"
                  rules={[
                    {
                      required: false,
                      message: "Please Enter exhibitor Image!",
                    },
                  ]}
                >
                  {getExhibitorDetailLoader ? (
                    <>
                      {Array.from({ length: 1 }).map((_, index) => (
                        <Skeleton.Avatar
                          key={index}
                          active
                          size={100}
                          shape="square"
                          className="flex-shrink-0"
                          style={{ marginRight: "10px" }}
                        />
                      ))}
                    </>
                  ) : (
                    <>
                      {profilePicUrl ? (
                        <div className="relative border border-dashed border-brand h-[102px] w-[102px] rounded-[6px]">
                          <img
                            src={profilePicUrl}
                            alt="avatar"
                            className="h-full w-full rounded-[6px]"
                          />

                          <ImgCrop
                            rotationSlider
                            aspect={1 / 1}
                            fillColor="white"
                          >
                            <Upload
                              name="file"
                              listType="picture-card"
                              className="avatar-uploader main-upload"
                              showUploadList={false}
                              action={BASE_URL_UPLOAD}
                              openFileDialogOnClick={true}
                              headers={{
                                Authorization:
                                  "Bearer " + localStorage.getItem("crmWebToken"),
                              }}
                              onChange={handleChangeUserPic}
                            >
                              <div className="absolute bottom-0 right-0 px-1.5 py-1 bg-brand main-upload  rounded-tl-xl rounded-br-[6px] cursor-pointer flex">
                                <Image
                                  width={15}
                                  height={15}
                                  src={editPrimary}
                                  className="rounded-lg"
                                  preview={false}
                                />
                              </div>
                            </Upload>
                          </ImgCrop>
                        </div>
                      ) : (
                        <ImgCrop aspect={1 / 1} rotationSlider fillColor="white">
                          <Upload
                            name="file"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action={BASE_URL_UPLOAD}
                            openFileDialogOnClick={true}
                            headers={{
                              Authorization:
                                "Bearer " + localStorage.getItem("crmWebToken"),
                            }}
                            onChange={handleChangeUserPic}
                          >
                            <button
                              style={{
                                border: 0,
                                background: "none",
                              }}
                              type="button"
                            >
                              {loading ? <LoadingOutlined /> : <PlusOutlined />}
                              <div
                                style={{
                                  marginTop: 8,
                                }}
                              >
                                Upload
                              </div>
                            </button>
                          </Upload>
                        </ImgCrop>
                      )}
                    </>
                  )}
                </Form.Item>
              </Col>

              {getExhibitorDetailLoader ? (
                <SkeletonUpdateCard length={5} />
              ) : (
                <>
                  <Col span={12}>
                    <Form.Item
                      name="exhibitor_name"
                      label="Exhibitor Name"
                      className="mb-[12px]"
                      size="small"
                      rules={[
                        {
                          required: true,
                          message: "Please enter exhibitor Name!",
                        },
                      ]}
                      normalize={sanitizeName}
                    >
                      <Input
                        style={{ background: "white" }}
                        placeholder="Enter Exhibitor Name"
                        className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    {" "}
                    <Popover
                      placement="bottomRight"
                      content={exhibitoreCategoryPopover}
                      trigger="click"
                      open={isExhibitorCategoryOpen}
                      onOpenChange={(visible) => {
                        if (!visible) {
                          setIsExhibitorCategoryModalOpen(false);
                          exhibitorCategoryModalForm.resetFields();
                        }
                      }}
                    >
                      <div
                        className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                        onClick={showExhibitorCategoryOpen}
                      >
                        <Image
                          src={plusSvg}
                          alt="image"
                          height={20}
                          width={20}
                          preview={false}
                        />
                        <h1 className="text-[14px] font-popinsRegular ml-1">
                          Add Exhibitor Category
                        </h1>
                      </div>
                    </Popover>
                    <Form.Item
                      name="exhibitor_category_id"
                      label="Exhibitor Category"
                      className="mb-[12px]"
                      size="small"
                      rules={[
                        {
                          required: false,
                          message: "Please enter exhibitor category!",
                        },
                      ]}
                    >
                      <Select
                        options={cateData}
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        loading={getExhibitionCategoryLoader}
                        disabled={getExhibitionCategoryLoader}
                        style={{ background: "white" }}
                        placeholder="Select Event Category"
                        className="begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="exhibitor_contact_person"
                      label="Contact Person Name"
                      className="mb-[12px]"
                      size="small"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Contact Person!",
                        },
                      ]}
                      normalize={sanitizeName}
                    >
                      <Input
                        aria-controls="false"
                        style={{ background: "white" }}
                        placeholder="Enter Contact Person Name"
                        className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="exhibitor_contact_person_email"
                      label="Contact Person Email"
                      className="mb-[12px]"
                      size="small"
                      rules={[
                        {
                                          pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,

                          message: "Please enter a valid email!",
                        },
                      ]}
                    >
                      <Input
                        style={{ background: "white" }}
                        placeholder="Enter Contact Person Email"
                        className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="exhibitor_contact_person_phone"
                      label="Contact Person Phone"
                      normalize={(value) => value && value.replace(/[^+\d]/g, "")}
                      className="mb-[12px]"
                      size="small"
                      rules={[
                        {
                          max: 15,
                          message: "phone number cannot be longer than 15 digits",
                        },
                      ]}
                    >
                      <Input
                        // rows={3}
                        style={{ background: "white" }}
                        placeholder="Enter Contact Person Phone"
                        className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="booth_number"
                      label="Booth Number"
                      className="mb-[12px]"
                      normalize={(value) => value && value.replace(/[^+\d]/g, "")}
                      size="small"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Booth Number!",
                        },
                      ]}
                    >
                      <Input
                        // rows={3}
                        style={{ background: "white" }}
                        placeholder="Enter Booth Number"
                        className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="exhibitor_website"
                      label="Exhibitor Website"
                      className="mb-[12px]"
                      size="small"
                      rules={[
                        {
                          type: "url",
                          message: "Please enter a valid URL!",
                        },
                      ]}
                    >
                      <Input
                        // rows={3}
                        style={{ background: "white" }}
                        placeholder="Enter Exhibitor Website"
                        className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    span={24}
                    align="middle"
                    className="flex justify-center mt-[40px]"
                  >
                    <Form.Item className="mb-0">
                      <Button
                        onClick={handleCancel}
                        type="button"
                        className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
                      >
                        Cancel
                      </Button>
                    </Form.Item>

                    <div className="ml-4">
                      <Form.Item className="mb-0">
                        <Button
                          loading={addExhibitorLoader || updateExhibitorLoader}
                          type="primary"
                          className="root-btn min-h-[45px] min-w-[120px]"
                          htmlType="submit"
                        >
                          {exhibitorData ? "Save" : "Submit"}
                        </Button>
                      </Form.Item>
                    </div>
                  </Col>
                </>
              )}
            </Row>
            {/* </Spin> */}
            {/* </Spin> */}
          </Form>
        </Modal>

        <CommonModal
          className=""
          centered
          open={viewModalOpen}
          title=""
          onCancel={() => {
            setViewModalOpen(false);
            setExhibitorData(null);
            handleCancel();
          }}
          handleReset={() => {
            setViewModalOpen(false);
            handleCancel();
            setExhibitorData(null);
          }}
          onOk={() => {
            setViewModalOpen(false);
            setExhibitorData(null);
            handleCancel();
          }}
        >
          {/* <Spin> */}
          {/* <Spin spinning={getExhibitorDetailLoader}> */}
          {getExhibitorDetailLoader ? (
            <SkeletonViewModal length={5} />
          ) : (
            getExhibitorDetail && (
              <div>
                <div className="text-brand flex items-center gap-x-3">
                  <Image
                    src={exibhitors}
                    alt="image"
                    height={20}
                    width={20}
                    preview={false}
                  />
                  <h1 className="lg:text-lg text-base font-popinsRegular">
                    View Exhibitor
                  </h1>
                </div>
                <div className="flex flex-col gap-y-4">
                  <div className="flex items-center pt-5 gap-x-4">
                    <Image
                      preview={false}
                      height={100}
                      width={100}
                      className="rounded-[10px]"
                      src={getExhibitorDetail.exhibitor_image}
                      alt="Profile Pic"
                    />
                    <div className="flex justify-start flex-col gap-y-1">
                      <p className="text-sm font-popinsMedium">
                        {getExhibitorDetail.exhibitor_name}
                      </p>
                      <p className="text-sm text-[#818181]">
                        {getExhibitorDetail.exhibitor_contact_person_email ?? ""}
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-x-6 items-center gap-y-1">
                    <p className="text-xs text-[#818181] w-[30%]">
                      Contact Person Name:
                    </p>
                    <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                      {getExhibitorDetail.exhibitor_contact_person ?? "-"}
                    </p>
                  </div>
                  <div className="flex gap-x-6 items-center gap-y-1">
                    <p className="text-xs text-[#818181] w-[30%]">
                      Contact Person Phone:
                    </p>
                    <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                      {getExhibitorDetail.exhibitor_contact_person_phone ?? "-"}
                    </p>
                  </div>
                  <div className="flex gap-x-6 items-center gap-y-1">
                    <p className="text-xs text-[#818181] w-[30%]">
                      Booth Number:
                    </p>
                    <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                      {getExhibitorDetail.booth_number ?? "-"}
                    </p>
                  </div>
                  <div className="flex gap-x-6 items-center gap-y-1">
                    <p className="text-xs text-[#818181] w-[30%]">
                      Exhibitor Category:
                    </p>
                    <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                      {getExhibitorDetail.exhibitor_category &&
                        getExhibitorDetail.exhibitor_category?.exhibitor_category
                        ? getExhibitorDetail.exhibitor_category
                          ?.exhibitor_category
                        : "-"}
                    </p>
                  </div>
                  <div className="flex gap-x-6 items-center gap-y-1">
                    <p className="text-xs text-[#818181] w-[30%]">
                      Exhibitor Website:
                    </p>
                    <a
                      href={formatUrl(
                        getExhibitorDetail &&
                        getExhibitorDetail.exhibitor_website !== null &&
                        getExhibitorDetail.exhibitor_website
                      )}
                      className="text-sm text-start w-[70%] text-blue-500 font-popinsMedium"
                      target="_blank"
                    >
                      {getExhibitorDetail.exhibitor_website ?? "-"}
                    </a>
                  </div>
                </div>
              </div>
            )
          )}

          {/* </Spin> */}
          {/* </Spin> */}
        </CommonModal>
      </div>
    </EventDetailWrapper>
  );
};

export default Exhibitors;
