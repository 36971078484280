import {
  Button,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Table,
  Typography,
  Select,
} from "antd";
import * as XLSX from "xlsx";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import plusIcon from "../../../assets/img/add-button.svg";
import deletevent from "../../../assets/img/deleteevent.svg";
import generalmaster from "../../../assets/img/generalmaster.svg";
import editedevent from "../../../assets/img/editedevent.svg";
import leadstatus from "../../../assets/img/leadstatus.svg";
import editevent from "../../../assets/img/editevent.svg";
import {
  actionAddLeadStatusMaster,
  actionDeleteLeadStatusMaster,
  actionEditLeadStatusMaster,
  actionGetLeadStatusMaster,
  actionGetSingleLeadStatusMaster,
} from "../../../store/services/generalMasterService";
import SortIcon from "../../common/SortIcon";
import { isValidSearchString } from "../../helper/common-function";
import MasterSearchbar from "../../common/MasterSearchbar";
import PageTitleForMasterData from "../../common/PageTitleForMasterData";
import usePermission from "../../../hooks/use-permission";
import { handleActionWithPermission } from "../../../store/slices/authSlice";
import SkeletonTable from "../../common/SkeletonTable";
import MasterCommonTable from "../../common/MasterCommonTable";

const LeadStatus = ({ setSelectItem }) => {
  const [editableForm] = Form.useForm();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    leadStatusData,
    getLeadStatusLoader,
    getSingleLeadStatusData,
    getSingleEventTypeLoader,
    addLeadStatusLoader,
    deleteLeadStatusLoader,
    updateLeadStatusLoader,
  } = useSelector((state) => state.generalMaster);

  const [search, setSearch] = useState("");
  const [edited, setEdited] = useState(false);
  const [eventTypeData, setEventTypeData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [columnKey, setColumnKey] = useState(null);
  const [lastSearch, setLastSearch] = useState('');

  const { hasPermission } = usePermission();

  const isEditing = (record) => record?.lead_status_master_id === editingKey;

  useEffect(() => {
    dispatch(actionGetLeadStatusMaster({ search }));
  }, [dispatch]);

  const handleAddLeadStatus = (values) => {
    dispatch(actionAddLeadStatusMaster({ values, handleCancel }));
  };

  const edit = (record) => {
    dispatch(actionGetSingleLeadStatusMaster(record.lead_status_master_id));
    setEdited(!edited);
  };

  useEffect(() => {
    if (getSingleLeadStatusData && edited) {
      editableForm.setFieldsValue({
        lead_status: getSingleLeadStatusData.lead_status,
      });
      setEditingKey(getSingleLeadStatusData.lead_status_master_id);
    }
  }, [getSingleLeadStatusData]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = <Input variant="borderless" />;
    return (
      <td {...restProps} className="input-height">
        {editing ? (
          <Form.Item
            className="mb-0 editable-order"
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: title === "Status" ? true : false,
                message: `${title} is required.`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          <Typography.Paragraph
            style={{ marginBottom: 0 }}
            ellipsis={{ rows: 1, expandable: false, tooltip: true }}
            className="px-[11px]"
          >
            {children}
          </Typography.Paragraph>
        )}
      </td>
    );
  };

  const save = async (id) => {
    try {
      const row = await editableForm.validateFields();
      const { lead_status } = row;
      const req = { lead_status };
      dispatch(
        actionEditLeadStatusMaster({ id, req, setEditingKey, setEdited })
      );
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "SR No.",
      dataIndex: "srno",
      key: "srno",
      width: 70,
      fixed: "left",
      editable: false,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Status",
      dataIndex: "lead_status",
      key: "lead_status",
      width: 250,
      editable: true,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Action",
      key: "action",
      width: 100,
      fixed: "right",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <div className="flex items-center ">
            {editable ? (
              <div className="flex items-center">
                {updateLeadStatusLoader[record?.lead_status_master_id] ? (
                  <LoadingOutlined />
                ) : (
                  <Typography.Link
                    onClick={() => save(record?.lead_status_master_id)}
                  >
                    <img src={editedevent} alt="" />
                  </Typography.Link>
                )}
              </div>
            ) : (
              <div className="flex items-center gap-x-2 justify-center">
                <Typography.Link
                  disabled={editingKey !== ""}
                  onClick={() => {
                    dispatch(
                      handleActionWithPermission("leads-status", "update", () =>
                        edit(record)
                      )
                    );
                  }}
                >
                  <img src={editevent} alt="" />
                </Typography.Link>
              </div>
            )}
            <div className="ml-5">
              {hasPermission("leads-status", "delete") ? (
                <Popconfirm
                  okButtonProps={{
                    loading:
                      deleteLeadStatusLoader[record.lead_status_master_id],
                  }}
                  description="Are you sure you want to delete?"
                  onConfirm={() =>
                    dispatch(
                      actionDeleteLeadStatusMaster({
                        id: record.lead_status_master_id,
                        setEditingKey,
                      })
                    )
                  }
                >
                  <img src={deletevent} alt="" className="cursor-pointer" />
                </Popconfirm>
              ) : (
                <img
                  src={deletevent}
                  alt=""
                  className="cursor-pointer"
                  onClick={() => {
                    dispatch(
                      handleActionWithPermission("leads-status", "delete")
                    );
                  }}
                />
              )}
            </div>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      sortIcon: () => col.sorter ? getLeadStatusLoader && col.key === columnKey ? <LoadingOutlined /> : <SortIcon /> : null,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "lead_status" ? "text" : "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  useEffect(() => {
    const trimmedSearch = search?.trim();
    if (trimmedSearch !== "" && trimmedSearch !== lastSearch) {
      const timer = setTimeout(() => {
        dispatch(
          actionGetLeadStatusMaster({ search: trimmedSearch })
        );
        setLastSearch(trimmedSearch);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }

  }, [search, lastSearch]);

  const handleSearch = (e) => {
    if (isValidSearchString(e.target.value)) {
      setSearch(e.target.value);
      if (e.target.value === "") {
        dispatch(
          actionGetLeadStatusMaster({
            search: e.target.value,
          })
        );
      }
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search && isValidSearchString(e.target.value)) {
      dispatch(
        actionGetLeadStatusMaster({
          search: e.target.value,
        })
      );
    }
  };

  const handleSortChange = (pagination, filters, sorter) => {
    setColumnKey(sorter.columnKey)

    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
            ? "ASC"
            : "",
    };

    dispatch(
      actionGetLeadStatusMaster({
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
  };

  const getData = async (data) => {
    const newData =
      (await data) &&
      data.length > 0 &&
      data.map((d) => ({
        "Lead Status": d.lead_status ? d.lead_status : "-",
      }));
    return newData;
  };

  const exportAllToExcel = async () => {
    if (!leadStatusData || (leadStatusData && leadStatusData.length === 0)) {
      return;
    }
    const data = await getData(leadStatusData);
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Lead Statuses");
    XLSX.writeFile(workbook, "lead-statuses.xlsx");
  };

  return (
    <div className="event-input overflow-auto">
      <PageTitleForMasterData
        title="Lead Statuses"
        buttonText="Add Lead Status"
        onButtonClick={showModal}
        resource="leads-status"
      />
      <Modal
        centered
        onOk={() => handleCancel()}
        onCancel={() => handleCancel()}
        footer={false}
        width={500}
        title=""
        open={isModalOpen}
      >
        <div className="flex">
          <img src={leadstatus} alt="" />
          <h1 className="text-[#6883FD] text-[20px] ml-[8px] mt-[5px] font-medium">
            Lead Status
          </h1>
        </div>
        <Form
          form={form}
          onFinish={handleAddLeadStatus}
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="lead_status"
            label="Lead Status"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please enter lead status!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Lead Status "
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-center mt-[40px]">
            <Form.Item className="mb-0">
              <Button
                onClick={handleCancel}
                type="button"
                className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addLeadStatusLoader}
                  type="primary"
                  className="root-btn min-h-[45px] min-w-[120px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>

      <MasterCommonTable
        moduleKey="lead-statuses"
        columns={mergedColumns}
        dataSource={leadStatusData}
        rowKey="lead_status_master_id"
        isLoading={getLeadStatusLoader}
        EditableCell={EditableCell}
        onTableChange={handleSortChange}
        editableForm={editableForm}
        searchBarProps={{
          handleSearch,
          handleBlurAndPressEnter,
          importRoute: "/master-data/lead-status-import",
          search,
          onClickDownloadMenu: exportAllToExcel,
        }}
        columnKey={columnKey}
      />
    </div>
  );
};

export default LeadStatus;
