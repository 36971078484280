import * as XLSX from "xlsx";
import React, { useState } from "react";
import plusIcon from "../../assets/img/add-button.svg";
import leftArrow from "../../assets/img/leftArrowPrimary.svg";
import excelIcon from "../../assets/img/excel-icon.svg";
import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  AlertOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import xlsxIcon from "../../assets/img/xlsx-icon.svg";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Table,
  Divider,
  Image,
  Row,
  Statistic,
  Typography,
  Alert,
  ConfigProvider,
  Tooltip,
} from "antd";

import CustomUploadDragger from "./CustomUploadDragger";
import { toast } from "sonner";
import axios from "axios";
import { BASE_URL } from "../../config/web-config";
import { formatString, formatTitle } from "../helper/common-function";
import { useNavigate } from "react-router-dom";

const CustomImportComp = ({
  setSelectItem = null,
  backText = "product",
  text = "file",
  downloadUrl = "material-master/import-excel-format",
  uploadUrl = "material-master/import",
  hasItems = false,
  gif = true,
  ...props
}) => {
  const fullUploadUrl = `${BASE_URL}/${uploadUrl}`;
  const fullDownloadUrl = `${BASE_URL}/${downloadUrl}`;
  const navigate = useNavigate();
  const [isDownloading, setIsDownloading] = useState(false);
  const [data, setData] = useState({});
  const [isUploading, setUploading] = useState(false);

  const formatForDownload = (text) => {
    return `${text
      .toLowerCase() // Convert to lowercase
      .replace(/\s+/g, "-")}-data-format.xlsx`;
  };
  // Handle file format download
  const handleDownload = async () => {
    setIsDownloading(true);
    try {
      const res = await axios.get(fullDownloadUrl, {
        responseType: "blob",
      });

      if (res.status === 200) {
        const blob = res.data;

        const downloadUrl = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = formatForDownload(text);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
      } else if (res.status === 404) {
        toast.error("File not found");
      } else {
        toast.error("Failed to download file");
        throw new Error(`API Error: ${res.statusText}`);
      }
    } catch (error) {
      console.error("Error downloading:", error);
      toast.error("Failed to download");
    } finally {
      setIsDownloading(false);
    }
  };

  const handleUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      setUploading(true);
      const response = await axios.post(fullUploadUrl, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("crmWebToken")}`,
        },
        timeout: 0,
      });
      const { data, importedData, validationErrors } = response.data;

      const successfulCount = importedData.length;
      const failedCount = validationErrors.length;

      if (failedCount > 0) {
        toast.error(
          `${failedCount} item(s) could not be imported. Please check the details and try again.`
        );
      } else {
        toast.success(`Successfully imported ${successfulCount} entries.`);
      }

      setData(response.data);
    } catch (error) {
      console.log({ error })
      console.error("Upload failed:", error.toJSON ? error.toJSON() : error);
      toast.error(
        error?.response?.data?.message || error.message || "Network Error"
      );
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="event-input overflow-y-auto overflow-x-hidden">
      <div className="flex justify-between my-[15px]">
        <div
          onClick={() => {
            navigate && navigate(-1);
          }}
          className="flex items-center gap-x-2 cursor-pointer"
        >
          <Image
            src={leftArrow}
            alt="image"
            height={18}
            className="cursor-pointer mr-2"
            width={18}
            preview={false}
          />
          <h1 className="text-[#6883FD] text-[20px] font-medium">
            Import{" "}
            {text === "hsn-sac-code"
              ? "HSN/SAC Code"
              : text
                .replace(/-/g, " ")
                .replace(/\b\w/g, (char) => char.toUpperCase())}
          </h1>
        </div>
        <div className="flex">
          <Tooltip title="Warning: Do not modify the header row in the Excel file, as it may cause errors during the import process. Ensure the structure remains exactly as provided.">
            <InfoCircleOutlined
              style={{ fontSize: "16px", color: "#6883FD" }}
            />
          </Tooltip>
          <Button
            onClick={handleDownload}
            loading={isDownloading}
            className="root-btn text-white hover:text-white py-5 flex-center radius ml-2"
            icon={<Image src={plusIcon} alt="Plus Icon" preview={false} />}
          >
            Download Excel Format
          </Button>
        </div>
      </div>
      <CustomUploadDragger
        gif={gif}
        gapY={2}
        image={xlsxIcon}
        title={
          <div className="flex-col flex items-center justify-center gap-y-1">
            <p className="text-[#7C7C7C] font-popinsSemiBold text-xl">
              Upload Your file here
            </p>
            <p className="text-[#7C7C7C] font-popinsRegular text-xs">
              or Drag and Drop it here{" "}
            </p>
          </div>
        }
        containerHeight={300}
        imageHW={50}
        accept=".xlsx,.xls,.csv"
        onDrop={(e) => {
          e.preventDefault();
          e.stopPropagation();

          const files = e.dataTransfer.files;

          handleUpload(files[0]);
        }}
        onChange={async (e) => {
          const file = e.target.files[0];
          if (file) {
            await handleUpload(file);
          }
        }}
        loading={isUploading}
      />

      {data && Object.keys(data).length > 0 && (
        <ImportResults hasItems={hasItems} text={text} importResult={data} />
      )}
    </div>
  );
};

export default CustomImportComp;

// Component for displaying import results
function ImportResults({ importResult, text, hasItems }) {
  const {
    data = [],
    importedData = [],
    validationErrors = [],
  } = importResult || {};

  const [loading, setLoading] = useState(false)


  const combinedData = data.map((item, index) => ({
    ...item,
    index: index + 1,
    validationErrors: validationErrors
      .filter((err) => err.index === index)
      .map((item, idx) => ({ ...item, index: item.index + 1 })),
  }));


  const exportDynamicDataToExcel = (data, validationErrors, fileName = "users") => {
    const dataHeaders = data[0]
      ? Object.keys(data[0])
        .filter((key) => key !== "items")
        .map((item) => formatString(item))
      : [];


    // Initialize errorHeaders to map errors to columns
    const errorHeaders = [];
    validationErrors.forEach((error, index) => {
      if (error.errors) {
        // If there are multiple errors, create a header for each
        error.errors.forEach((_, i) => {
          errorHeaders.push(`Error ${index + 1} ${i + 1}`);
        });
      } else {
        errorHeaders.push(`Error ${index + 1}`);
      }
    });

    const headers = [
      ...dataHeaders,
      ...errorHeaders,
      "Validation Result"
    ];

    const rows = data.map((item, index) => {
      const row = Object.keys(item)
        .filter((key) => key !== "items")
        .map((key) => item[key] || "");  // Map normal data fields

      const rowErrors = validationErrors.filter((error) => error.index === index && !error.itemIndex);
      const rowErrorMessages = rowErrors.length > 0
        ? rowErrors.flatMap(error => error.errors || [error.error])
        : new Array(errorHeaders.length).fill('');  // Fill empty error cells if no errors

      row.push(...rowErrorMessages);  // Add error messages for this row
      row.push(rowErrors.length > 0 ? 'Failed' : 'Passed');  // Validation result column
      return row;
    });

    const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, fileName);

    // Prepare item-specific data without errors and validation result
    const itemsData = data.flatMap((order, orderIndex) =>
      order.items ? order.items.map((item, itemIndex) => ({ ...item, orderIndex, itemIndex })) : []
    );

    if (itemsData.length > 0) {
      const itemsHeaders = Object.keys(itemsData[0]).filter(key => key !== 'orderIndex' && key !== 'itemIndex');
      // Removed errorHeaders and validation result columns from items sheet

      const itemsRows = itemsData.map((itemData) => {
        const { orderIndex, itemIndex, ...item } = itemData;

        const itemRow = itemsHeaders.map(header => item[header] || '');  // Item data

        return itemRow;
      });
      const itemsSheet = XLSX.utils.aoa_to_sheet([itemsHeaders, ...itemsRows]);
      XLSX.utils.book_append_sheet(wb, itemsSheet, 'Items');
    }

    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };


  const expandable = {
    expandedRowRender: (record) => {
      const { validationErrors } = record;

      if (Array.isArray(validationErrors) && validationErrors.length > 0) {
        // Map through all error groups
        return validationErrors.map((errorGroup, groupIndex) => {
          // Check if errorGroup has multiple errors
          if (
            Array.isArray(errorGroup.errors) &&
            errorGroup.errors.length > 0
          ) {
            const errorRows = errorGroup.errors.map(
              (errorMessage, errorIndex) => ({
                index: `${groupIndex}-${errorIndex}`,
                error: errorMessage,
              })
            );

            // Render the errors in a table
            return (
              <Table
                key={groupIndex}
                bordered
                columns={[
                  {
                    title: "Error",
                    dataIndex: "error",
                    key: "error",
                    render: (text) => <Alert message={text} type="error" />,
                  },
                ]}
                dataSource={errorRows}
                pagination={false}
                rowKey="index"
              />
            );
          } else {
            return (
              <Alert
                className="mb-3"
                key={groupIndex}
                type="error"
                message={errorGroup.error || "Unknown error"}
              />
            );
          }
        });
      }

      return (
        <Alert
          className="my-2"
          type="error"
          message="No validation errors found."
        />
      );
    },
    rowExpandable: (record) => record.validationErrors.length > 0,
    columnWidth: "2%",
    defaultExpandAllRows: true,

    showExpandColumn: true,
  };

  const customFields = data[0]?.custom_fields || [];

  const customFieldColumns = customFields.map((field) => ({
    title: formatTitle(field.field_name),
    dataIndex: field.field_name,
    key: field.field_name,
    width: 200,
    ellipsis: true,
    render: (text, record) => {
      const customField = record.custom_fields.find(
        (cf) => cf.field_name === field.field_name
      );
      return customField ? customField.value : "-";
    },
  }));

  const generalColumns = Object.keys(data[0])
    .filter((key) => key !== "custom_fields")
    .map((key) => ({
      title: formatTitle(key),
      dataIndex: key,
      key: key,
      width: 200,
      ellipsis: true,
      render: (text, record) => {
        if (key === "items" && Array.isArray(record.items)) {
          return <span>{record.items.length} items</span>;
        }
        return text || "-";
      },
    }));

  const columns = [
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
      width: 100,
      render: (text, record, index) => index + 1,
    },
    ...generalColumns,
    ...customFieldColumns,
  ];

  return (
    <div style={{ padding: "24px 0" }}>
      {data && data.length > 0 && <div className="ml-auto pb-5 text-right">
        <Button loading={loading} onClick={() => exportDynamicDataToExcel(data, validationErrors, text + " logs")}
          className="rounded-sm excel-download-btn min-h-[35px] ml-auto" icon={<Image preview={false} src={excelIcon} alt="excel icon" />}>{loading ? "Downloading..." : "Download"} Logs</Button>
      </div>}
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={8}>
          <Card>
            <Statistic
              title="Total Records"
              value={data.length}
              prefix={<InfoCircleOutlined style={{ color: "#1890ff" }} />}
            />
          </Card>
        </Col>
        <Col xs={24} sm={8}>
          <div
            style={{ cursor: "pointer" }}
          >
            <Card className="flex import-card">
              <Statistic
                title="Imported Records"
                value={importedData.length}
                prefix={<CheckCircleOutlined style={{ color: "#52c41a" }} />}
              />
            </Card>
          </div>
        </Col>
        <Col xs={24} sm={8}>
          <div
            style={{ cursor: "pointer" }}
          >
            <Card>
              <Statistic
                title="Validation Errors"
                value={validationErrors.length}
                prefix={<AlertOutlined style={{ color: "#f5222d" }} />}
              />
            </Card>
          </div>
        </Col>
      </Row>

      <Card style={{ marginTop: "16px" }} id="imported-data">
        <Typography.Title level={4}>{text} Details</Typography.Title>
        <Typography.Text type="secondary">
          Details of the {text}
        </Typography.Text>
        <Divider />

        <ConfigProvider
          theme={{
            components: {
              Table: {
                cellPaddingBlock: 8,
                algorithm: true,
              },
              Alert: {
                defaultPadding: "4px 12px",
              },
            },
          }}
        >
          {combinedData && combinedData.length > 0 ? (
            <Table
              scroll={{
                x: "max-content",
                y: 400,
              }}
              dataSource={combinedData}
              columns={columns}
              rowKey={"index"}
              pagination={false}
              bordered
              expandable={expandable}
              rowClassName={(record) =>
                record.validationErrors.length > 0 ? "error-row" : ""
              }
            />
          ) : (
            <h1 className="text-center text-base font-popinsSemiBold">
              No imported Data Found
            </h1>
          )}
        </ConfigProvider>
      </Card>
    </div>
  );
}
