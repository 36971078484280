import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "sonner";
import { handleErrorMessage } from "../../components/helper/common-function";
import { BASE_URL } from "../../config/web-config";

export const actionGetNotifications = createAsyncThunk(
  "actionGetNotifications",
  async (
    {
      offset = 0,
      module_name = "",
      is_active = false,
      is_read = false,
      search = "",
      order = "DESC",
      orderByColumn = "",
    } = {},
    { rejectWithValue }
  ) => {
    const isRead = is_read ? "TRUE" : "FALSE";
    try {
      const response = await axios.get(
        `${BASE_URL}/notifications?limit=20&offset=${offset}&is_read=${isRead}${search ? "&search=" + search : ""
        }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${order ? `&order=${order}` : ""
        }${is_active ? `&is_active=${is_active}` : ""}${module_name ? `&module_name=${module_name}` : ""
        }`
      );
      const { status, message: customMsg, data, total_records } = response.data;

      if (parseInt(status) === 200) {
        return { status, data: data.data, total_records, unreadNotificationCount: data.total_unread_notifications };
      } else if (parseInt(status) === 404) {
        return { status, data: [], total_records: 0 };
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
      return rejectWithValue(error.message);
    }
  }
);
