export const BASE_URL =
  process.env.REACT_APP_ENV_MODE == "DEV"
    ? process.env.REACT_APP_DEV_BASE_URL
    : process.env.REACT_APP_PROD_BASE_URL;

export const BASE_URL_UPLOAD =
  process.env.REACT_APP_ENV_MODE == "DEV"
    ? process.env.REACT_APP_DEV_UPLOAD_BASE_URL
    : process.env.REACT_APP_PROD_UPLOAD_BASE_URL;

export const GOOGLE_MAP_KEY =
  process.env.REACT_APP_ENV_MODE == "DEV"
    ? process.env.REACT_APP_DEV_GOOGLE_MAP_KEY
    : process.env.REACT_APP_PROD_GOOGLE_MAP_KEY;

/* export const CIPHER_KEY =
  process.env.REACT_APP_ENV_MODE == "DEV"
    ? process.env.REACT_APP_DEV_SEND_INVITATION_CIPHER_KEY
    : process.env.REACT_APP_PROD_SEND_INVITATION_CIPHER_KEY;
 */

export const CIPHER_KEY =
  process.env.REACT_APP_ENV_MODE == "DEV"
    ? "lu6^S-l(U!e=6$ho)1^x7gFcoD2C%^6(H)Ke8nwn0.uzGcNhDev~Z"
    : "lu6^S-l(U!e=6$ho)1^x7gFcoD2C%^6(H)Ke8nwn0.uzGcNh~Z";
