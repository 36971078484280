import { Tooltip } from "antd";
import React, { memo, useRef } from "react";

const TooltipWrapper = memo(({ title, children }) => {
  const ref = useRef(null);

  return (
    <Tooltip title={title} overlayRef={ref}>
      {children}
    </Tooltip>
  );
});

export default TooltipWrapper;
