import React, { useState, useEffect, useMemo } from "react";
import { message, Select, Spin } from "antd";
import debounce from "lodash/debounce";
import { useDispatch, useSelector } from "react-redux";
import {
  actionGetAllProductsMaster,
  actionGetFilteredProduts,
  actionGetProductMaster,
} from "../../store/services/productMasterService";
import { toast } from "sonner";

const DebounceSelect = ({
  debounceTimeout = 800,
  rowId = null,
  options = [],
  // setOptions = () => {},
  ...props
}) => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState([]);

  const {
    getAllProductsLoader,
    productAllData,
    getSingleProductData,
    getSingleProductLoader,
    filteredProductsData,
  } = useSelector((state) => state.productMaster);
  /* 
  useEffect(() => {
    if (rowId) {
      dispatch(actionGetProductMaster(rowId));
    }
  }, [rowId]); */

  const debounceFetcher = useMemo(() => {
    const loadOptions = async (value) => {
      if (!value) return;

      setFetching(true);

      const resultAction = await dispatch(
        actionGetFilteredProduts({
          search: value,
          getall: "YES",
        })
      );

      if (actionGetFilteredProduts.fulfilled.match(resultAction)) {
        if (resultAction.payload) {
          const {
            data: newProducts,
            message,
            status,
          } = resultAction.payload || [];

          if (parseInt(status) === 200) {
            setData(newProducts);
            setFetching(false);
          } else if (parseInt(status) === 404) {
            setData([]);
            setFetching(false);
          } else {
            toast.error(message);
            setFetching(false);
          }
        } else {
          setData([]);
          setFetching(false);
        }
      } else if (actionGetFilteredProduts.rejected.match(resultAction)) {
        // Handle rejection case, e.g., show error toast or reset data
        toast.error("Failed to fetch products. Please try again later.");
        setFetching(false);
        setData([]);
      }
    };

    return debounce(loadOptions, debounceTimeout);
  }, [debounceTimeout, dispatch]);

  useEffect(() => {
    if (options && options?.length) {
      setData(options);
    } else {
      setData([]);
    }

    setFetching(false);
  }, [options]);

  const loading = getAllProductsLoader || fetching;

  return (
    <Select
      className="grow"
      filterOption={false}
      onSearch={(value) => debounceFetcher(value)}
      notFoundContent={loading ? <Spin size="small" /> : null}
      {...props}
      value={props.value}
      onChange={(value) => {
        props.onChange(value);
      }}
      options={
        data.map((item) => ({
          label: item.material_name,
          value: item.material_master_id,
          base_unit_of_measure: item?.base_unit_of_measure,
        })) || []
      }
    />
  );
};

export default DebounceSelect;
