import { Image, Modal } from "antd";
import React, { useState } from "react";
import icon from "../../assets/img/forbidden-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../store/slices/authSlice";

const ForbiddenModal = () => {
  const { isModalVisible, modalMessage } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModal());
  };

  return (
    <Modal
      footer={false}
      title=""
      width={500}
      open={isModalVisible}
      onOk={handleClose}
      onCancel={handleClose}
    >
      <div className="flex items-center justify-center flex-col gap-y-3 py-10">
        <Image preview={false} src={icon} alt="forbidden Icon" />
        <h1 className="text-black mb-0 text-xl font-popinsSemiBold">
          Access Denied
        </h1>
        <p className="text-[#ACACAC] text-base">{modalMessage || "you don't have permission to access."}</p>
      </div>
    </Modal>
  );
};

export default ForbiddenModal;
