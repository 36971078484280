import { LoadingOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Image, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import success from "../../assets/img/complete.svg";
import otpImage from "../../assets/img/otp.svg";
import rightArrow from "../../assets/img/right-arrow-primary.svg";
import useDeviceInfo, { DeviceType } from "../../hooks/use-device-info";
import {
  actionContinueToLogin,
  actionLoginOTP,
  actionReSendOTPEmail,
  actionReSendOTPMobile,
  actionVerifyOTPEmail,
  actionVerifyOTPMobile,
  actionVerifyOtpLogin,
} from "../../store/services/authService";
import OtpInput from "../common/OtpInput";
import { useAuthContext } from '../../context/AuthContext';
import { startTimer } from '../helper/common-function';

const OtpVerfication = () => {
  const dispatch = useDispatch();
  const {
    continueLoader,
    verifyEmailOtpLoader,
    verifyLoginLoader,
    verifyPhoneOtpLoader,
  } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  let isFromSignUp = state === "fromRegister" ? true : false;

  const [PhoneOtp, setPhoneOtp] = useState("");
  const [EmailOtp, setEmailOtp] = useState("");

  const d = localStorage.getItem("userData");
  const data = JSON.parse(d);

  const { verified, setVerifed, emailTimer, phoneTimer, setEmailTimer, setPhoneTimer, clearContextState } = useAuthContext();


  useEffect(() => {
    if (!data) {
      navigate("/login");
    }
  }, [data, navigate]);

  const {
    osName,
    osVersion,
    deviceName,
    deviceType,
    batteryPercentage,
    latitude,
    browserName,
    longitude,
  } = useDeviceInfo();

  const company_user_logs = {
    device_name: deviceName,
    os_name: osName,
    os_version: osVersion,
    device_type: deviceType,
    is_tablet: deviceType === DeviceType.TABLET,
    battery_parcentage: batteryPercentage,
    latitude,
    longitude,
    user_log_from: "LOG_IN",
  };

  const onFinishPhone = (e) => {
    e.preventDefault();
    const req = { auth_key_phone: PhoneOtp, user_id: data.id };
    dispatch(actionVerifyOTPMobile({ req, setVerifed, setPhoneOtp }));
  };

  const onFinishEmail = (e) => {
    e.preventDefault();
    const req = { auth_key_email: EmailOtp, user_id: data.id };
    dispatch(actionVerifyOTPEmail({ req, setVerifed, setEmailOtp }));
  };

  const onFinishLogin = (e) => {
    e.preventDefault();

    const fcm_token = localStorage.getItem("firebase_token");

    const req = {
      auth_key: PhoneOtp,
      user_id: data.id,
      fcm_token,
      company_user_logs,
    };
    dispatch(actionVerifyOtpLogin({ req, navigate, setPhoneOtp }));
  };

  function hideAndAdd(phoneNumber) {

    phoneNumber = phoneNumber.toString().trim();

    let countryCode = "";
    let numberPart = "";

    if (phoneNumber.startsWith("+")) {
      countryCode = phoneNumber.substring(0, 3);
      numberPart = phoneNumber.substring(3).trim();
    } else {
      countryCode = phoneNumber.substring(0, 2);
      numberPart = phoneNumber.substring(2).trim();
    }

    // Perform masking
    let maskedNumber = "";

    if (numberPart) {
      // Ensure there are at least 5 digits to mask
      if (numberPart.length >= 5) {
        const startMaskIndex = Math.floor((numberPart.length - 5) / 2);
        maskedNumber =
          numberPart.substring(0, startMaskIndex) +
          "*****" +
          numberPart.substring(startMaskIndex + 5);
      } else {
        maskedNumber = numberPart;
      }
    }

    const maskedPhoneNumber = countryCode + " " + maskedNumber;
    return maskedPhoneNumber;
  }


  const newSendTo =
    state && state.type === "PHONE"
      ? data.phoneNum
        ? hideAndAdd(data.phoneNum)
        : state.sendTo.slice(0, 2) +
        state.sendTo.slice(2).replace(/.(?=...)/g, "*")
      : state && state.sendTo;

  const newPhone =
    data &&
    data.phone &&
    data.phone.slice(0, 2) + data.phone.slice(2).replace(/.(?=.{3})/g, "*");

  const startOTPTimer = () => {
    setPhoneTimer && startTimer(setPhoneTimer)
  }

  const handleResendOtp = () => {
    const req = {
      type: state.type,
      send_to: state.sendTo,
      country_code: state.type === "PHONE" ? state.iso : "",
    };
    dispatch(
      actionLoginOTP({
        req,
        navigate,
        startTimer: startOTPTimer,
        phone: state.type === "PHONE" ? state.phoneNum : "",
      })
    );
  };
  useEffect(() => {
    if ((isFromSignUp && verified.is_email_verified && verified.is_mobile_verified) ||
      (!isFromSignUp && verified.is_mobile_verified)) {
      dispatch(actionContinueToLogin({ req: { user_id: data.id }, navigate }));
    }
  }, [isFromSignUp, verified.is_email_verified, verified.is_mobile_verified]);


  return (
    <Spin spinning={continueLoader}>
      <div className="register-container   relative">
        <div className="title mb-4 pt-20 flex flex-col justify-center items-center gap-y-2 ">
          <p className="xl:text-[30px]  text-secondary font-popinsSemiBold  lg:text-[26px]  md:text-2xl text-xl">
            OTP Verification
          </p>

          {isFromSignUp ? (
            <>
              {/* Mobile OTP */}
              <div
                style={{ minHeight: 180, height: 180, flexGrow: 1 }}
                className="border flex items-center justify-center rounded-lg h-full border-[#E8E8E8]  w-full"
              >
                <div className="w-[20%] grow h-full rounded-r-none rounded-[10px] sm:flex hidden items-center justify-center  box-bg">
                  <Image preview={false} src={otpImage} alt="Otp Image" />
                </div>

                <form
                  name="normal_login"
                  className="login-form px-3 sm:w-[80%] sm:px-7 py-4 "
                  onSubmit={onFinishPhone}
                >
                  <p className="lg:text-[16px] text-sm pb-4 sm:pl-0 pl-3">
                    Enter the OTP sent to&nbsp;+
                    {data && data.phone_country_code} &nbsp;
                    {data && newPhone}
                  </p>
                  <div className="flex items-center justify-start w-full gap-x-3  otp-input-con ">
                    <div className=" flex items-center justify-between w-9/12">
                      <OtpInput onOtpChange={(otp) => setPhoneOtp(otp)} />
                    </div>

                    <div className="w-[25%]">
                      {!verified.is_mobile_verified ? (
                        <Button
                          disabled={
                            PhoneOtp.length !== 4 || verified.is_mobile_verified
                          }
                          loading={verifyPhoneOtpLoader}
                          type="primary"
                          className="filled-s-button text-sm  border-none rounded-[10px] font-popinsMedium tracking-wider min-h-[45px]"
                          // block
                          htmlType="submit"
                        >
                          Verify
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          className="success-btn flex-center gap-x-2 px-3 text-sm  border-none rounded-[10px] font-popinsMedium tracking-wider min-h-[45px]"
                          block
                          htmlType="button"
                        >
                          <img src={success} height={20} alt="Verified Icon" />{" "}
                          Verified
                        </Button>
                      )}
                    </div>
                  </div>

                  <div className="text-center flex items-center justify-center pt-4 gap-2">
                    <p className="2xl:text-base text-sm  ">
                      Didn’t receive the OTP?
                    </p>

                    <p
                      onClick={() => {
                        if (!verified.is_mobile_verified && phoneTimer === 0) {
                          dispatch(
                            actionReSendOTPMobile({
                              user_id: data.id,
                              setPhoneTimer,
                            })
                          );
                        }
                      }}
                      className={`2xl:text-base text-sm ${verified.is_mobile_verified
                        ? "cursor-not-allowed text-gray"
                        : phoneTimer === 0
                          ? "cursor-pointer text-primary hover:text-primary"
                          : "text-primary"
                        }`}
                    >
                      {verified.is_mobile_verified
                        ? "Resend OTP"
                        : phoneTimer > 0
                          ? `Resend in ${phoneTimer}s`
                          : "Resend OTP"}
                    </p>
                  </div>
                </form>
              </div>

              {/* Email OTP */}
              <div
                style={{ minHeight: 180, height: 180, flexGrow: 1 }}
                className="border flex items-center justify-center rounded-lg h-full border-[#E8E8E8]  w-full"
              >
                <div className="w-[20%] grow h-full rounded-r-none rounded-[10px] sm:flex hidden items-center justify-center  box-bg">
                  <Image preview={false} src={otpImage} alt="Otp Image" />
                </div>

                <form
                  name="email_form"
                  className="login-form px-3 sm:w-[80%] sm:px-7 py-4 "
                  onSubmit={onFinishEmail}
                >
                  <p className="lg:text-[16px] text-sm pb-4 sm:pl-0 pl-3">
                    Enter the OTP sent to&nbsp;{data && data.email}
                  </p>
                  <div className="flex items-center justify-start gap-x-3 w-full  otp-input-con">
                    <div className=" flex items-center justify-between w-9/12">
                      <OtpInput onOtpChange={(otp) => setEmailOtp(otp)} />
                    </div>

                    <div className="w-[25%]">
                      {!verified.is_email_verified ? (
                        <Button
                          disabled={
                            EmailOtp.length !== 4 || verified.is_email_verified
                          }
                          loading={verifyEmailOtpLoader}
                          type="primary"
                          className="filled-s-button text-sm  border-none rounded-[10px] font-popinsMedium tracking-wider min-h-[45px]"
                          // block
                          htmlType="submit"
                        >
                          Verify
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          className="success-btn flex-center gap-x-2 text-sm  border-none rounded-[10px] font-popinsMedium tracking-wider min-h-[45px]"
                          block
                          htmlType="button"
                        >
                          <img alt="Success" src={success} /> Verified
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className="text-center flex items-center justify-center pt-4 gap-2">
                    <p className="2xl:text-base text-sm  ">
                      Didn’t receive the OTP?
                    </p>

                    <p
                      onClick={() => {
                        if (!verified.is_email_verified && emailTimer === 0) {
                          dispatch(
                            actionReSendOTPEmail({
                              user_id: data.id,
                              setTimer: setEmailTimer,
                            })
                          );
                        }
                      }}
                      className={`2xl:text-base text-sm ${verified.is_email_verified
                        ? "cursor-not-allowed text-gray"
                        : emailTimer === 0
                          ? "cursor-pointer text-primary hover:text-primary"
                          : "text-primary"
                        }`}
                    >
                      {verified.is_email_verified
                        ? "Resend OTP"
                        : emailTimer > 0
                          ? `Resend in ${emailTimer}s`
                          : "Resend OTP"}
                    </p>
                  </div>
                </form>
              </div>
            </>
          ) : (
            <div
              style={{ minHeight: 180, height: 180, flexGrow: 1 }}
              className="border flex items-center justify-center rounded-lg h-full border-[#E8E8E8]  w-full"
            >
              <div className="w-[20%] grow h-full rounded-r-none rounded-[10px] sm:flex hidden items-center justify-center  box-bg">
                <Image preview={false} src={otpImage} alt="Otp Image" />
              </div>

              <form
                name="email_form"
                className="login-form w-full px-3 sm:w-[80%] sm:px-7 py-4 "
                onSubmit={
                  state && state.page
                    ? (e) => {
                      e.preventDefault();
                      const req = {
                        auth_key_phone: PhoneOtp,
                        user_id: data.id,
                      };
                      dispatch(
                        actionVerifyOTPMobile({ req, setVerifed, setPhoneOtp })
                      );
                    }
                    : onFinishLogin
                }
              >
                <p className="lg:text-[16px] text-sm pb-4 sm:pl-0 pl-3">
                  Enter the OTP sent to&nbsp; {newSendTo}
                </p>
                <div className="flex items-center justify-start gap-x-3 w-full  otp-input-con">
                  <div className=" flex items-center justify-between w-9/12">
                    <OtpInput onOtpChange={(otp) => setPhoneOtp(otp)} />
                  </div>

                  <div className="w-[25%]">
                    {!verified.is_mobile_verified ? (
                      <Button
                        disabled={PhoneOtp.length !== 4}
                        loading={verifyLoginLoader}
                        type="primary"
                        className="filled-s-button sm:text-sm text-xs  border-none rounded-[10px] font-popinsMedium tracking-wider min-h-[45px]"
                        htmlType="submit"
                      >
                        Verify
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        className="success-btn flex-center gap-x-2 text-sm  border-none rounded-[10px] font-popinsMedium tracking-wider min-h-[45px]"
                        block
                        htmlType="button"
                      >
                        <img alt="Success" src={success} /> Verified
                      </Button>
                    )}
                  </div>
                </div>
                <div className="text-center flex items-center justify-center pt-4 gap-1">
                  <p className="2xl:text-base text-sm  ">
                    Didn’t receive the OTP?
                  </p>
                  {phoneTimer === 0 ? (
                    <p
                      onClick={() =>
                        state.page
                          ? dispatch(
                            actionReSendOTPMobile({
                              user_id: data.id,
                              setPhoneTimer,
                            })
                          ) : !(
                            verified.is_mobile_verified ||
                            verified.is_email_verified
                          ) && handleResendOtp()


                      }
                      className={`2xl:text-base
                      ${verified.is_mobile_verified ||
                          verified.is_email_verified
                          ? "cursor-not-allowed"
                          : "cursor-pointer"
                        }
                     text-sm   ${verified.is_mobile_verified || verified.is_email_verified
                          ? "text-gray"
                          : "text-primary hover:text-primary"
                        }`}
                    >
                      Resend OTP
                    </p>
                  ) : (
                    <p className="2xl:text-base w-[120px] text-sm ml-[3px] text-left text-primary">
                      Resend in {phoneTimer}s
                    </p>
                  )}
                </div>
              </form>
            </div>
          )}

          <div
            className={`flex continue-typo items-center  w-full`}
          >
            {!(verified.is_mobile_verified && verified.is_email_verified) &&
              state &&
              !state.page && (
                <p
                  onClick={() =>
                    navigate(
                      isFromSignUp
                        ? "/register"
                        : state && state.page
                          ? "/change-mobile"
                          : "/sign-otp",
                      {
                        state: { wrongDetails: true },
                      }
                    )
                  }
                  className="cursor-pointer 2xl:text-lg xl:text-base text-sm text-primary hover:text-primary"
                >
                  Entered wrong details?
                </p>
              )}

            {!verified.is_mobile_verified && state && state.page && (
              <p
                onClick={() => navigate("/change-mobile")}
                className="cursor-pointer 2xl:text-lg flex-center gap-x-3 xl:text-base text-sm text-primary hover:text-primary"
              >
                Change Mobile No
                <Image preview={false} src={rightArrow} alt="Right Image" />
              </p>
            )}


          </div>
        </div>
      </div >
    </Spin >
  );
};

export default OtpVerfication;
