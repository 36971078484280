import React from "react";
import { Table, Form } from "antd";
import MasterSearchbar from "./MasterSearchbar";
import SkeletonTable from "./SkeletonTable";
 
const MasterCommonTable = ({
    columns,
    dataSource,
    rowKey = "id",
    isLoading = false,
    EditableCell,
    onTableChange,
    scroll = { y: 500 },
    pagination = false,
    editableForm,
    searchBarProps,
    columnKey,
    ...props
}) => {
    const {
        handleSearch,
        handleBlurAndPressEnter,
        importRoute,
        isImportEnabled = true,
        search,
        onClickDownloadMenu,
        isPagination
    } = searchBarProps || {};



    return (
        <div>
            <div className="pt-[25px]">
                <MasterSearchbar
                    handleSearch={handleSearch}
                    handleBlurAndPressEnter={handleBlurAndPressEnter}
                    importRoute={importRoute}
                    isImportEnabled={isImportEnabled}
                    search={search}
                    onClickDownloadMenu={onClickDownloadMenu}
                    isPagination={isPagination}
                />
            </div>
 
            {isLoading && !columnKey ? (
                <SkeletonTable isMaster={true} columns={columns} />
            ) : (
                <div className="pt-5 pb-[75px]">
                    <Form form={editableForm} component={false}>
                        <Table {...props}
                            onChange={onTableChange}
                            scroll={scroll}
                            pagination={pagination}
                            components={{
                                body: {
                                    cell: EditableCell,
                                },
                            }}
                            bordered
                            dataSource={dataSource}
                            rowKey={rowKey}
                            columns={columns}
                            rowClassName="editable-row"
                        />
                    </Form>
                </div>
            )}
        </div>
    );
};
 
export default MasterCommonTable;