import { createSlice } from "@reduxjs/toolkit";
import {
  actionAddCustomField,
  actionDeleteCustomField,
  actionEditCustomField,
  actionGetAllCustomFields,
  actionGetCustomField,
  actionGetCustomFields,
} from "../services/customFieldService";

const customFieldSlice = createSlice({
  name: "customField",
  initialState: {
    addCustomFieldLoader: false,
    getCustomFieldsLoader: false,
    getAllCustomFieldsLoader: false,
    customFieldsCount: 0,
    getCustomFieldDetailLoader: false,
    deleteCustomFieldLoader: false,
    editCustomFieldLoader: false,
    getCustomFieldDetails: {},
    customFields: [],
    allCustomFields: [],
  },
  reducers: {},
  extraReducers: (buider) => {
    buider
      // add custom fields State
      .addCase(actionAddCustomField.pending, (state) => {
        state.addCustomFieldLoader = true;
      })
      .addCase(actionAddCustomField.fulfilled, (state, action) => {
        state.addCustomFieldLoader = false;
        if (action.payload) {
          if (!state.customFields || !Array.isArray(state.customFields)) {
            state.customFields = [action.payload];
          } else {
            state.customFields.unshift(action.payload);
          }
        }
      })
      .addCase(actionAddCustomField.rejected, (state) => {
        state.addCustomFieldLoader = false;
      })

      // Handle Get Custom Fields State
      .addCase(actionGetCustomFields.pending, (state) => {
        state.getCustomFieldsLoader = true;
      })
      .addCase(actionGetCustomFields.fulfilled, (state, action) => {
        state.getCustomFieldsLoader = false;
        state.customFields = action.payload.data;
        state.customFieldsCount = action.payload.total_records;
      })
      .addCase(actionGetCustomFields.rejected, (state, action) => {
        state.getCustomFieldsLoader = false;
      })

      // Handle Get all Custom Fields State
      .addCase(actionGetAllCustomFields.pending, (state) => {
        state.getAllCustomFieldsLoader = true;
      })
      .addCase(actionGetAllCustomFields.fulfilled, (state, action) => {
        state.getAllCustomFieldsLoader = false;
        state.allCustomFields = action.payload?.data;
      })
      .addCase(actionGetAllCustomFields.rejected, (state, action) => {
        state.getAllCustomFieldsLoader = false;
      })

      // Handle Get Single Custom Fields State
      .addCase(actionGetCustomField.pending, (state) => {
        state.getCustomFieldDetailLoader = true;
      })
      .addCase(actionGetCustomField.fulfilled, (state, action) => {
        state.getCustomFieldDetailLoader = false;
        state.getCustomFieldDetails = action.payload?.data;
      })
      .addCase(actionGetCustomField.rejected, (state, action) => {
        state.getCustomFieldDetailLoader = false;
      })

      // edit custom fields State
      .addCase(actionEditCustomField.pending, (state) => {
        state.editCustomFieldLoader = true;
      })
      .addCase(actionEditCustomField.fulfilled, (state, action) => {
        state.editCustomFieldLoader = false;
        state.customFields = state.customFields.map((field) =>
          field.custom_field_id === action.payload.custom_field_id
            ? { ...field, ...action.payload }
            : field
        );
      })
      .addCase(actionEditCustomField.rejected, (state) => {
        state.editCustomFieldLoader = false;
      })

      // delete custom fields State
      .addCase(actionDeleteCustomField.pending, (state) => {
        state.deleteCustomFieldLoader = true;
      })
      .addCase(actionDeleteCustomField.fulfilled, (state, action) => {
        state.deleteCustomFieldLoader = false;
        state.customFields = state.customFields.filter(
          (field) => field.custom_field_id !== action.payload
        );
      })
      .addCase(actionDeleteCustomField.rejected, (state) => {
        state.deleteCustomFieldLoader = false;
      });
  },
});
export default customFieldSlice.reducer;
