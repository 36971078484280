import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Input,
  Form,
  Popconfirm,
  Typography,
  Table,
  Modal,
  Image,
  Pagination,
} from "antd";
import {
  actionGetEventProductMaster,
  actionGetSingleEventProductMaster,
  actionDeleteEventProductMaster,
  actionAddEventProductMaster,
  actionGetAllEventProductMaster,
  actionEditEventProductMaster,
  actionClearEventProductAllData,
} from "../../../store/services/masterDataService";
import SortIcon from "../../common/SortIcon";
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import editevent from "../../../assets/img/editevent.svg";
import deletevent from "../../../assets/img/deleteevent.svg";
import editedevent from "../../../assets/img/editedevent.svg";
import plusIcon from "../../../assets/img/add-button.svg";
import { useDispatch, useSelector } from "react-redux";
import { isValidSearchString } from "../../helper/common-function";
import MasterSearchbar from "../../common/MasterSearchbar";
import * as XLSX from "xlsx";
import PageTitleForMasterData from "../../common/PageTitleForMasterData";
import usePermission from "../../../hooks/use-permission";
import { handleActionWithPermission } from "../../../store/slices/authSlice";
import SkeletonTable from "../../common/SkeletonTable";
import CommonPagination from "../../common/CommonPagination";
import MasterCommonTable from "../../common/MasterCommonTable";

const EvenetProducts = () => {
  const [editableForm] = Form.useForm();
  const offsetRef = useRef(0);
  const [form] = Form.useForm();
  const {
    eventProductData,
    getEventProductLoader,
    getSingleEventProductLoader,
    addEventProductLoader,
    deleteEventProductLoader,
    getSingleEventProduct,
    updateEventProductLoader,
    eventProductDataCount,
    eventAllProductMasterData,
  } = useSelector((state) => state.masterData);

  const originData = [];
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [exportAll, setExportAll] = useState(false);
  const [data, setData] = useState(originData);
  const [editingKey, setEditingKey] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [edited, setEdited] = useState(false);
  const [columnKey, setColumnKey] = useState(null);
  const [lastSearch, setLastSearch] = useState('');
  const [pageSize, setPageSize] = useState(20);
  const { hasPermission } = usePermission();

  useEffect(() => {
    dispatch(
      actionGetEventProductMaster({ offset: offsetRef.current, limit: pageSize, search })
    );
  }, []);

  useEffect(() => {
    const trimmedSearch = search?.trim();
    if (trimmedSearch !== "" && trimmedSearch !== lastSearch) {
      const timer = setTimeout(() => {
        dispatch(
          actionGetEventProductMaster({ offset: offsetRef.current, limit: pageSize, search })
        );
        setLastSearch(trimmedSearch);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }

  }, [search, lastSearch]);

  const handleSearch = (e) => {
    if (isValidSearchString(e.target.value)) {
      setSearch(e.target.value);
      if (e.target.value === "") {
        dispatch(
          actionGetEventProductMaster({
            offset: offsetRef.current,
            limit: pageSize,
            search: e.target.value,
          })
        );
      }
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search && isValidSearchString(e.target.value)) {
      dispatch(
        actionGetEventProductMaster({
          offset: offsetRef.current,
          limit: pageSize,
          search: e.target.value,
        })
      );
    }
  };
  const isEditing = (record) => record.event_product_master_id === editingKey;

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === "number" ? <Input /> : <Input />;
    return (
      <td {...restProps} className="input-height">
        {editing ? (
          <Form.Item
            className="mb-0 editable-order"
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: ``,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          <Typography.Paragraph
            style={{ marginBottom: 0 }}
            ellipsis={{ rows: 1, expandable: false, tooltip: true }}
            className="px-[11px]"
          >
            {children}
          </Typography.Paragraph>
        )}
      </td>
    );
  };

  useEffect(() => {
    if (!editingKey) {
      setEdited(false);
    }
  }, [editingKey]);

  const edit = (record) => {
    dispatch(actionGetSingleEventProductMaster(record.event_product_master_id));
    setEdited(!edited);
  };

  useEffect(() => {
    if (getSingleEventProduct && edited) {
      editableForm.setFieldsValue({
        product_name: getSingleEventProduct.product_name,
      });
      setEditingKey(getSingleEventProduct.event_product_master_id);
    }
  }, [getSingleEventProduct]);

  const save = async (id) => {
    try {
      const row = await editableForm.validateFields();
      const { product_name } = row;
      const req = { product_name, product_image: "" };
      dispatch(actionEditEventProductMaster({ id, req, setEditingKey }));
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const confirm = (id) => {
    dispatch(actionDeleteEventProductMaster({ id, setEditingKey }));
  };

  const columns = [
    {
      title: "SR No.",
      dataIndex: "srno",
      key: "srno",
      width: 70,
      fixed: "left",
      editable: false,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Event Product",
      dataIndex: "product_name",
      key: "product_name",
      width: 400,
      editable: true,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Action",
      key: "action",
      width: 100,
      fixed: "right",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <div className="flex items-center ">
            {editable ? (
              <div className="flex items-center">
                {updateEventProductLoader[record.event_product_master_id] ? (
                  <LoadingOutlined />
                ) : (
                  <Typography.Link
                    onClick={() => save(record.event_product_master_id)}
                  >
                    <img src={editedevent} alt="" />
                  </Typography.Link>
                )}
              </div>
            ) : (
              <div className="flex items-center gap-x-2 justify-center">
                <Typography.Link
                  disabled={editingKey !== ""}
                  onClick={() => {
                    dispatch(
                      handleActionWithPermission(
                        "event-product-master",
                        "update",
                        () => edit(record)
                      )
                    );
                  }}
                >
                  <img src={editevent} alt="" />
                </Typography.Link>
              </div>
            )}
            <div className="ml-5">
              {hasPermission("event-product-master", "delete") ? (
                <Popconfirm
                  okButtonProps={{
                    loading:
                      deleteEventProductLoader[record.event_product_master_id],
                  }}
                  title="Are you want to sure delete this?"
                  onConfirm={() => confirm(record.event_product_master_id)}
                  onOpenChange={() => console.log("open change")}
                >
                  <img src={deletevent} alt="" className="cursor-pointer" />
                </Popconfirm>
              ) : (
                <img
                  src={deletevent}
                  alt=""
                  className="cursor-pointer"
                  onClick={() => {
                    dispatch(
                      handleActionWithPermission(
                        "event-product-master",
                        "delete"
                      )
                    );
                  }}
                />
              )}
            </div>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      sortIcon: () => col.sorter ? getEventProductLoader && col.key === columnKey ? <LoadingOutlined /> : <SortIcon /> : null,
      onCell: (record) => ({
        record,

        inputType: col.dataIndex === "srno" ? " product_name" : "",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleEventProduct = (values) => {
    dispatch(actionAddEventProductMaster({ values, handleCancel }));
  };

  const handleSortChange = (pagination, filters, sorter) => {
    setColumnKey(sorter.columnKey)
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
            ? "ASC"
            : "",
    };

    dispatch(
      actionGetEventProductMaster({
        offset: offsetRef.current,
        limit: pageSize,
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
  };

  const getData = async (data) => {
    const newData =
      (await data) &&
      data.length > 0 &&
      data.map((d) => ({
        "Event Product": d.product_name ? d.product_name : "-",
      }));
    return newData || [];
  };

  const exportToExcel = async (items, type = "") => {
    if (!items || (items && items.length === 0)) {
      return;
    }
    const data = await getData(items);
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Event Products");
    XLSX.writeFile(workbook, "event-products.xlsx");
    type &&
      type === "all" &&
      dispatch(actionClearEventProductAllData({ setExportAll }));
  };

  const onClickDownloadMenu = ({ key }) => {
    console.log({ key });
    if (key === "1") {
      exportToExcel(eventProductData);
    } else {
      dispatch(actionGetAllEventProductMaster());
      setExportAll(true);
    }
  };

  useEffect(() => {
    if (
      exportAll &&
      eventAllProductMasterData &&
      eventAllProductMasterData.length > 0
    ) {
      exportToExcel(eventAllProductMasterData, "all");
    }
  }, [eventAllProductMasterData]);

  return (
    <div className="event-input">
      <PageTitleForMasterData
        title="Event Products"
        buttonText="Add Event Product"
        onButtonClick={showModal}
        resource="event-product-master"
      />
      <Modal
        centered
        onOk={() => handleCancel()}
        onCancel={() => handleCancel()}
        footer={false}
        width={500}
        title=""
        open={isModalOpen}
      >
        <h1 className="text-[#6883FD] text-[20px]  mt-[5px] font-medium">
          Event Products
        </h1>
        <Form
          form={form}
          onFinish={handleEventProduct}
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="product_name"
            label="Event Products"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please enter event products!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Event Products"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-center mt-[40px]">
            <Form.Item className="mb-0">
              <Button
                onClick={handleCancel}
                type="button"
                className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  type="primary"
                  loading={addEventProductLoader}
                  className="root-btn min-h-[45px] min-w-[120px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
      <MasterCommonTable
        moduleKey="event-products"
        columns={mergedColumns}
        dataSource={eventProductData}
        rowKey="event_product_master_id"
        isLoading={getEventProductLoader}
        EditableCell={EditableCell}
        onTableChange={handleSortChange}
        editableForm={editableForm}
        searchBarProps={{
          handleSearch,
          handleBlurAndPressEnter,
          isImportEnabled: false,
          search,
          onClickDownloadMenu: onClickDownloadMenu,
          isPagination: true
        }}
        columnKey={columnKey}
      />

      <div className="flex justify-between mt-3 mb-4">
        <p className="text-[15px] font-popinsMedium">
          Total : {eventProductDataCount ? eventProductDataCount : 0}
        </p>

        <CommonPagination
          current={offsetRef.current + 1}
          total={eventProductDataCount}
          onChange={(page, pageSize) => {
            offsetRef.current = page - 1;
            setPageSize(pageSize);
            dispatch(
              actionGetEventProductMaster({
                offset: offsetRef.current,
                limit: pageSize,
                search: search,
              })
            );
          }}
          loading={getEventProductLoader}
          pageSize={pageSize}
        />
      </div>
    </div>
  );
};

export default EvenetProducts;
