import {
  ClockCircleOutlined,
  EditOutlined,
  EllipsisOutlined,
  EyeOutlined,
  SolutionOutlined,
  StopOutlined,
  CopyOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Form,
  Image,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Radio,
  Row,
  Select,
  Skeleton,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
const { Paragraph } = Typography;
import React, { useEffect, useMemo, useRef, useState } from "react";
import { LuFilter } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import bulkUpdate from "../../assets/img/bulk-update-icon.svg";
import excelIcon from "../../assets/img/excel-icon.svg";
import User from "../../assets/img/user.svg";
import {
  actionGetCountries,
  actionGetStates,
} from "../../store/services/locationService";
import { actionGetProductCategoryMaster } from "../../store/services/productMasterService";
import {
  actionGetAllRoleProfile,
  actionGetRoles,
} from "../../store/services/settingService";
import {
  actionAddUser,
  actionClearUsersAllData,
  actionDeactivateUser,
  actionDeleteInvitedUser,
  actionGetCompanyAllUsers,
  actionGetCompanyUsers,
  actionUpdateBulkUsers,
} from "../../store/services/usersService";
import { handleActionWithPermission } from "../../store/slices/authSlice";
import CommonTable from "../common/CommonTable";
import CustomIconText from "../common/CustomIconText";
import PageTitle from "../common/PageTitle";
import SortIcon from "../common/SortIcon";
import TooltipWrapper from "../common/TooltipWrapper";
import { debounce, values } from "lodash";
import { actionGetAllZoneMaster } from "../../store/services/generalMasterService";
import CommonPagination from "../common/CommonPagination";
import Wrapper from "../common/Wrapper";

const Users = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const offsetRef = useRef(0);
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();

  const {
    companyUsers,
    getCompanyUserLoader,
    companyUsersCount,
    companyAllUsers,
    getCompanyAllUserLoader,
    deactivateUsersLoader,
    updateBulkUsersLoader,
    deleteInvitedUsers,
  } = useSelector((state) => state.users);

  const { user, currentCompanyUser } = useSelector((state) => state.auth);
  const { getRolesLoader, roles, getAllRoleProfileLoader, roleAllProfile } =
    useSelector((state) => state.setting);

  const { countries, states } = useSelector((state) => state.location);

  const { productCategoryData, getProductCategoryLoader } = useSelector(
    (state) => state.productMaster
  );

  const { getAllZoneMasterLoader, allZoneMasterData } = useSelector(
    (state) => state.generalMaster
  );

  const [search, setSearch] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [isFilterFormChanged, setIsFilterFormChanged] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [filteredFields, setFilteredFields] = useState([]);

  const getApiCall = (
    offset = 0,
    limit = pageSize,
    search = "",
    orderByColumn = "",
    order = "",
    role_id = null,
    role_profile_id = null,
    reports_to = null,
    zone_id = null,
    material_category_ids = null,
    user_status = null,
    biomatric_face_id = false
  ) => {
    dispatch(
      actionGetCompanyUsers({
        offset,
        limit,
        orderByColumn,
        order,
        search,
        role_id,
        role_profile_id,
        reports_to,
        zone_id,
        material_category_ids,
        user_status,
        biomatric_face_id,
      })
    );
  };

  useEffect(() => {
    getApiCall();
  }, [dispatch]);

  const handleInvitation = (data) => {
    const req = [
      {
        email: data.email,
        role_id: data?.role.id,
        role_profile_id: data?.roleprofile?.role_profile?.id,
        reports_to_id: data?.reports_to?.id,
        is_resend: true,
      },
    ];

    dispatch(actionAddUser({ req }));
  };

  const initialColumns = [
    {
      title: "CH No.",
      key: "ch_no",
      width: 100,
      dataIndex: "id",
      fixed: "left",
      render: (text) => <div
        className="text-brand cursor-pointer"
        onClick={() => {
          dispatch(
            handleActionWithPermission("company-users", "read", () =>
              navigate(`/update-user/${text}`, {
                state: { userId: text,
                  isCurrentUser:
                  currentCompanyUser?.id === text,
                  accountType:
                    currentCompanyUser?.id === text
                      ? "OWN_ACCOUNT"
                      : "",
                 },
              })
            )
          );
        }}
      >{text}
      </div>,
    },
    {
      title: "First Name",
      key: "1",
      dataIndex: "first_name",
      fixed: "left",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 150,
      render: (text, data) => (
        <div
          className="flex items-center cursor-pointer min-w-[150px] w-full h-full flex-nowrap"
          onClick={() => {
            dispatch(
              handleActionWithPermission("company-users", "read", () =>
                navigate(`/update-user/${data.id}`, {
                  state: { userId: data.id,
                    isCurrentUser:
                    currentCompanyUser?.id === data.id,
                    accountType:
                      currentCompanyUser?.id === data.id
                        ? "OWN_ACCOUNT"
                        : "",
                   },
                })
              )
            );
          }}
        >
          <Image
            style={{
              verticalAlign: "middle",
            }}
            src={data.profile_pic}
            height={25}
            width={25}
            className="object-fill rounded-full"
            preview={false}
          />
          <p
            className="ml-2 text-brand"
          >
            {data.first_name.charAt(0).toUpperCase() + data.first_name.slice(1)}
          </p>
        </div>
      ),
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "2",
      width: 150,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span className="ml-2">{data.last_name ? data.last_name : "-"}</span>
      ),
    },

    {
      title: "Email",
      key: "4",
      width: 300,
      dataIndex: "email",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <div
          className="group flex items-center" 
        >
          {text ? (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="!mb-0 flex-grow"
              copyable={{
                icon: [
                  <CopyOutlined
                    key="copy-icon"
                    className="text-[#6883fd] ml-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                    style={{
                      position: "absolute",
                      right: 0,
                      zIndex: 1,
                    }}
                  />,
                  <CopyOutlined
                    key="copied-icon"
                    className="text-[#676768] ml-1"
                  />,
                ],
              }}
            >
              <div
                style={{
                  flexGrow: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {text}
              </div>
            </Typography.Paragraph>
          ) : (
            <span>-</span>
          )}
        </div>
      ),

    },
    {
      title: "Role",
      key: "5",
      width: 200,
      dataIndex: "role",
      render: (text) => (text ? text?.name : "-"),
    },
    {
      title: "Role Profile",
      key: "6",
      width: 200,
      dataIndex: "roleprofile",
      render: (text) => (text?.role_profile ? text.role_profile?.name : "-"),
    },
    {
      title: "Report To",
      dataIndex: "report_to",
      key: "7",
      width: 200,
      render: (text, data) => (
        <span className="ml-2">
          {data.reports_to || data.reports_to !== null ? (
            <>
              {data.reports_to.first_name}
              {data.reports_to.last_name ? ` ${data.reports_to.last_name}` : ""}
            </>
          ) : (
            "-"
          )}
        </span>
      ),
    },
    {
      title: "Phone",
      key: "8",
      width: 200,
      dataIndex: "phone",
      render: (text) => (
        <div className="group flex items-center">
          {text ? (
            <Paragraph
              className="!mb-0 flex items-center"
              copyable={{
                icon: [
                  <CopyOutlined
                    key="copy-icon"
                    className="text-[#6883fd] ml-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                  />,
                  <CopyOutlined
                    key="copied-icon"
                    className="text-[#676768] ml-1"
                  />,
                ],
              }}
            >
              {text}
            </Paragraph>
          ) : (
            <span>-</span>
          )}
        </div>
      ),
    },
    {
      title: "Designation",
      key: "9",
      width: 200,
      dataIndex: "designation",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Emp Code",
      key: "10",
      width: 200,
      dataIndex: "emp_code",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Zone",
      key: "11",
      width: 200,
      dataIndex: "zone",
      render: (text) => (text && text.zone_name ? text.zone_name : "-"),
    },
    {
      title: "Aadhar Card No.",
      key: "12",
      width: 150,
      dataIndex: "aadhar_card_number",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Pan Card No.",
      key: "13",
      width: 150,
      dataIndex: "pan_card_number",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Country",
      key: "14",
      width: 150,
      dataIndex: "country",
      render: (text) => (text && text.name ? text.name : "-"),
    },
    {
      title: "State",
      key: "15",
      width: 150,
      dataIndex: "state",
      render: (text) => (text && text.name ? text.name : "-"),
    },
    {
      title: "City",
      key: "16",
      width: 150,
      dataIndex: "city",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Zip Code",
      key: "17",
      width: 120,
      dataIndex: "zip_code",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "18",
      width: 150,
      render: (text, data) => (
        <Tag
          color={
            data.status === "INVITED"
              ? "blue"
              : data.status === "ACTIVE"
                ? "green"
                : data.status === "DEACTIVE"
                  ? "orange"
                  : data.status === "REJECT_INVITATION"
                    ? "red"
                    : "default"
          }
        // className="py-1 px-2.5 text-sm font-popinsRegular"
        >
          {data.status == "REJECT_INVITATION"
            ? "REJECT"
            : data.status
              ? data.status
              : "-"}
        </Tag>
      ),
    },
    {
      title: "Face ID",
      key: "biomatric_face_id_image",
      key: "19",
      width: 100,
      dataIndex: "biomatric_face_id_image",
      render: (text) =>
        text ? (
          <Image
            className="rounded-full"
            preview={{ mask: <EyeOutlined /> }}
            style={{
              verticalAlign: "middle",
            }}
            src={text}
            width={30}
            height={30}
          />
        ) : (
          "-"
        ),
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      key: "action",
      width: 100,
      fixed: "right",
      render: (text, data) => {
        return (
          <>
            <Dropdown
              id="custom-drop"
              menu={{
                items: [
                  (data?.status === "ACTIVE" || data?.status === "DEACTIVE") &&
                  currentCompanyUser?.id !== data?.id && {
                    label: (
                      <Popconfirm
                        onPopupClick={() => console.log("Hello")}
                        okButtonProps={{
                          loading: deactivateUsersLoader,
                        }}
                        placement="topLeft"
                        title={
                          data.status === "ACTIVE" ? "Deactivate" : "Activate"
                        }
                        description={`Are you sure to ${data.status === "ACTIVE" ? "deactivate" : "activate"
                          } this user?`}
                        onConfirm={() => {
                          const req = {
                            users: [data.id],
                          };
                          data.status === "ACTIVE" &&
                            dispatch(actionDeactivateUser({ req }));
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <div>
                          <StopOutlined
                            style={{
                              color:
                                data.status === "ACTIVE"
                                  ? "orange"
                                  : "#22c55e",
                            }}
                          />
                          <span
                            style={{
                              marginLeft: 8,
                              color:
                                data.status === "ACTIVE"
                                  ? "orange"
                                  : "#22c55e",
                            }}
                          >
                            {data.status === "ACTIVE"
                              ? "Deactivate"
                              : "Activate"}
                          </span>
                        </div>
                      </Popconfirm>
                    ),
                    key: "Deactivate",
                  },
                  {
                    label: <span>Edit</span>,
                    key: "Edit",
                    icon: <EditOutlined />,
                    onClick: () => {
                      dispatch(
                        handleActionWithPermission(
                          "company-users",
                          "update",
                          () =>
                            navigate(`/update-user/${data?.id}`, {
                              state: {
                                userId: data.id,
                                updateType: true,
                                isCurrentUser:
                                currentCompanyUser?.id === data.id,
                                accountType:
                                  currentCompanyUser?.id === data.id
                                    ? "OWN_ACCOUNT"
                                    : "",
                              },
                            })
                        )
                      );
                    },
                  },
                  {
                    label: <span>View</span>,
                    key: "View",
                    icon: <EyeOutlined />,
                    onClick: () =>
                      dispatch(
                        handleActionWithPermission(
                          "company-users",
                          "read",
                          () => {
                            navigate(`/update-user/${data.id}`, {
                              state: {
                                userId: data.id,
                                isCurrentUser:
                                currentCompanyUser?.id === data.id,
                                accountType:
                                  currentCompanyUser?.id === data.id
                                    ? "OWN_ACCOUNT"
                                    : "",
                              },
                            });
                          }
                        )
                      ),
                  },
                  {
                    label: <span>Attendance Logs</span>,
                    key: "Attendance Logs",
                    icon: <SolutionOutlined />,
                    onClick: () =>
                      navigate("/attendance-logs", {
                        state: {
                          userId: data.id,
                        },
                      }),
                  },

                  data.status !== "ACTIVE"
                    ? {
                      label: <span>Resend Invitation</span>,
                      key: "Resend Invitation",
                      icon: <ClockCircleOutlined />,
                      onClick: () => handleInvitation(data),
                    }
                    : "",

                  /*    {
                       label: <Popconfirm
                         okButtonProps={{
                           // loading: deactivateUsersLoader,
                         }}
                         placement="topLeft"
                         title={"Delete User"
                         }
                         description={`Are you sure to ${data.status === "ACTIVE" ? "deactivate" : "activate"
                           } this user?`}
                         onConfirm={() => {
                           dispatch(actionDeleteInvitedUser(data.id));
                         }}
                         okText="Yes"
                         cancelText="No"
                       >
                         <div>
                           <DeleteOutlined
                             style={{
                               color:
                                 "red",
                             }}
                           />
                           <span
                             style={{
                               marginLeft: 8,
                               color:
                                 "red",
                             }}
                           >
                             Delete
                           </span>
                         </div>
                       </Popconfirm>,
                       key: "Delete",
   
                     } */

                  data.status === "INVITED" && {
                    label: (
                      <Popconfirm
                        okButtonProps={{
                          loading: deleteInvitedUsers,
                        }}
                        placement="topLeft"
                        title={"Delete User"}
                        description={`Are you sure you want to delete this user?`}
                        onConfirm={() => {
                          dispatch(actionDeleteInvitedUser(data.id));
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <div
                          className="flex items-center"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <DeleteOutlined
                            style={{ fontSize: 12, color: "#ff4d4f" }}
                          />
                          <span
                            style={{
                              marginLeft: 8,
                              color: "#ff4d4f",
                            }}
                          >
                            Delete
                          </span>
                        </div>
                      </Popconfirm>
                    ),
                    key: "Delete",
                  },
                ].filter(Boolean),
              }}
            >
              <div className="flex justify-center items-center">
                <div className="flex justify-center items-center">
                  <EllipsisOutlined />
                </div>
              </div>
            </Dropdown>
          </>
        );
      },
    },
  ];

  const transferOptions = [];
  companyAllUsers &&
    companyAllUsers.map((data) =>
      transferOptions.push({
        label: (
          <span>
            <Avatar size={30} src={data?.profile_pic} className="mr-2" />
            {`${data.first_name ? data.first_name : ""} ${data.last_name ? data.last_name : ""
              }`}
          </span>
        ),
        value: data.id,
      })
    );

  const [deleteData, setDeleteData] = useState(false);
  const [radioButtonvalue, setRadioButtonValue] = useState(1);
  const [transferValue, setTransferValue] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filterModal, setFilterModal] = useState(false);
  const [filterValues, setFilterValues] = useState(0);
  const [exportAll, setExportAll] = useState(false);
  const [lastSearch, setLastSearch] = useState("");
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [roleID, setRoleID] = useState([]);
  const [roleProfileID, setRoleProfileID] = useState([]);
  const [reprotToID, setReprotToID] = useState([]);
  const [zoneID, setZoneID] = useState([]);
  const [productCategoryIDs, setProductCategoryIDs] = useState([]);
  const [status, setStatus] = useState([]);
  const [isFaceIdAvailable, setIsFaceIdAvailable] = useState();

  const handleOpenFilterModal = () => {
    setFilterModal(true);
    if (roles && roles.length === 0) {
      dispatch(actionGetRoles());
    }
    if (roleAllProfile && roleAllProfile.length === 0) {
      dispatch(actionGetAllRoleProfile());
    }
    if (companyAllUsers && companyAllUsers.length === 0) {
      dispatch(actionGetCompanyAllUsers());
    }
    if (productCategoryData && productCategoryData.length === 0) {
      dispatch(actionGetProductCategoryMaster());
    }
    if (allZoneMasterData && allZoneMasterData.length === 0) {
      dispatch(actionGetAllZoneMaster());
    }
  };

  const handleCloseFilterModal = () => {
    setFilterModal(false);
    if (!filterValues) {
      form.resetFields();
      setFilterValues(0);
      setRoleID([]);
      setRoleProfileID([]);
      setReprotToID([]);
      setZoneID([]);
      setProductCategoryIDs([]);
      setStatus([]);
      setIsFaceIdAvailable(false);
    }
    setIsFilterFormChanged(false);
  };

  const handleResetFilterModal = () => {
    form.resetFields();
    setFilterValues(0);
    setRoleID([]);
    setRoleProfileID([]);
    setReprotToID([]);
    setZoneID([]);
    setProductCategoryIDs([]);
    setStatus([]);
    setIsFaceIdAvailable(false);
    setIsReset(true);
    setFilteredFields([]);
    if (filterValues) {
      getApiCall(offsetRef.current, pageSize, search);
    }
  };

  const handleApplyFilter = (values) => {
    setRoleID(values.role_id);
    setRoleProfileID(values.role_profile_id);
    setReprotToID(values.reports_to);
    setZoneID(values.zone_id);
    setProductCategoryIDs(values.material_category_ids);
    setStatus(values.status);
    setIsFaceIdAvailable(values.face_id);

    const fieldsWithValues = Object.keys(values)
      .filter((field) => {
        const value = values[field];
        return (
          value !== undefined &&
          value !== null &&
          value !== "" &&
          (!Array.isArray(value) || value.length > 0) // Check for empty arrays
        );
      })
      .map((field) => field);

    setFilteredFields(fieldsWithValues);

    const hasValidFilterValues = Object.values(values).some(
      (value) =>
        value !== undefined && // Not undefined
        (Array.isArray(value) ? value.length > 0 : value !== "") // Non-empty array or non-empty string/number
    );
    // setFilterValues(hasValidFilterValues);
    if (fieldsWithValues && fieldsWithValues.length > 0) {
      setFilterValues(fieldsWithValues);
    } else {
      setFilterValues(0);
    }
    getApiCall(
      offsetRef.current,
      pageSize,
      search,
      "",
      "",
      values.role_id,
      values.role_profile_id,
      values.reports_to,
      values.zone_id,
      values.material_category_ids,
      values.status,
      values.face_id
    );
    setFilterModal(false);
  };

  useEffect(() => {
    if (filterModal) {
      form.setFieldsValue({
        role_id: roleID ? roleID : null,
        role_profile_id: roleProfileID ? roleProfileID : null,
        reports_to: reprotToID ? reprotToID : null,
        zone_id: zoneID ? zoneID : null,
        material_category_ids: productCategoryIDs ? productCategoryIDs : null,
        status: status ? status : null,
        face_id: isFaceIdAvailable ? isFaceIdAvailable : null,
      });
    }
  }, [filterModal, form]);

  const getFieldLabel = (fieldWithValue) => {
    switch (fieldWithValue) {
      case "role_id":
        return "Role";
      case "role_profile_id":
        return "Role Profile";
      case "reports_to":
        return "Report To";
      case "zone_id":
        return "Zone";
      case "material_category_ids":
        return "Material Access";
      case "status":
        return "Status";
      case "face_id":
        return "Face ID";
      default:
        return fieldWithValue;
    }
  };

  useEffect(() => {
    const trimmedSearch = search?.trim();
    if (trimmedSearch !== "" && trimmedSearch !== lastSearch) {
      const timer = setTimeout(() => {
        getApiCall(
          offsetRef.current,
          pageSize,
          trimmedSearch,
          "",
          "",
          roleID,
          roleProfileID,
          reprotToID,
          zoneID,
          productCategoryIDs,
          status,
          isFaceIdAvailable
        );
        setLastSearch(trimmedSearch);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [search, lastSearch]);

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      getApiCall(
        offsetRef.current,
        pageSize,
        "",
        "",
        "",
        roleID,
        roleProfileID,
        reprotToID,
        zoneID,
        productCategoryIDs,
        status,
        isFaceIdAvailable
      );
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      getApiCall(
        offsetRef.current,
        pageSize,
        search,
        "",
        "",
        roleID,
        roleProfileID,
        reprotToID,
        zoneID,
        productCategoryIDs,
        status,
        isFaceIdAvailable
      );
    }
  };

  const handleRefresh = () => {
    getApiCall(
      offsetRef.current,
      pageSize,
      search,
      "",
      "",
      roleID,
      roleProfileID,
      reprotToID,
      zoneID,
      productCategoryIDs,
      status,
      isFaceIdAvailable
    );
  };

  const onClickDeactivate = () => {
    const req = {
      users:
        selectedRowKeys &&
        selectedRowKeys.length > 0 &&
        selectedRowKeys.filter((row) => {
          return (
            row !== user.company_user &&
            !user.company_users.some((company) => company?.id === row)
          );
        }),
    };
    dispatch(
      actionDeactivateUser({
        req,
        setSelectedRowKeys,
      })
    );
  };

  const handleDownload = () => {
    console.log("Download button clicked!");
  };

  const onChangeRadioValue = (e) => {
    setRadioButtonValue(e.target.value);
  };

  const onSelect = (value) => {
    setTransferValue(value);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: 50,
  };

  const exportCurrentPageToExcel = () => {
    const data =
      companyUsers &&
      companyUsers.map((user) => ({
        "First Name": user.first_name ? user.first_name : "-",
        "Last Name": user.last_name ? user.last_name : "-",
        Email: user.email ? user.email : "-",
        "Report To": user.reports_to
          ? user.reports_to.first_name && user.reports_to.last_name
            ? user.reports_to.first_name + " " + user.reports_to.last_name
            : user.reports_to.last_name
          : "-",
        Status: user.status ? user.status : "-",
      }));
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet2");
    XLSX.writeFile(workbook, "Users.xlsx");
  };

  const exportAllToExcel = async () => {
    const data =
      (await companyAllUsers) &&
      companyAllUsers.length > 0 &&
      companyAllUsers.map((user) => ({
        "First Name": user.first_name ? user.first_name : "-",
        "Last Name": user.last_name ? user.last_name : "-",
        Email: user.email ? user.email : "-",
        "Report To": user.reports_to
          ? user.reports_to?.first_name && user.reports_to?.last_name
            ? user.reports_to?.first_name + " " + user.reports_to?.last_name
            : user.reports_to.first_name
          : "-",
        Status: user.status ? user.status : "-",
      }));
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
    // Generate the Excel file and initiate the download
    XLSX.writeFile(workbook, "users.xlsx");
    dispatch(actionClearUsersAllData({ setExportAll }));
  };

  useEffect(() => {
    if (exportAll && companyAllUsers && companyAllUsers.length > 0) {
      exportAllToExcel();
    }
  }, [companyAllUsers]);

  const onClickDownloadMenu = ({ key }) => {
    if (key === "1") {
      exportCurrentPageToExcel();
    } else {
      dispatch(actionGetCompanyAllUsers());
      setExportAll(true);
    }
  };

  const items = [
    {
      label: "Export This Page",
      key: "1",
      icon: (
        <Image
          src={excelIcon}
          height={20}
          width={20}
          preview={false}
          alt="Excel Icon"
        />
      ),
    },
    {
      label: "Export All",
      key: "2",
      icon: (
        <Image
          src={excelIcon}
          height={20}
          width={20}
          preview={false}
          alt="Excel Icon"
        />
      ),
    },
  ];

  const roleData = [];
  const roleProfileData = [];
  const reportsToData = [];
  const countriesData = [];
  const stateData = [];
  const materialCategoryData = [];
  const zoneData = [];

  roles &&
    roles.map((data) =>
      roleData.push({
        label: data.name,
        value: data.id,
      })
    );

  roleAllProfile &&
    roleAllProfile.map((data) =>
      roleProfileData.push({
        label: data.name,
        value: data.id,
      })
    );

  companyAllUsers &&
    companyAllUsers.map((data) =>
      reportsToData.push({
        label: (
          <span>
            <Avatar size={20} src={data?.profile_pic} className="mr-2" />
            {`${data.first_name ? data.first_name : ""} ${data.last_name ? data.last_name : ""
              }`}
          </span>
        ),
        value: data.id,
        search: `${data.first_name || ""} ${data.last_name || ""}`,
      })
    );

  countries &&
    countries.map((data) =>
      countriesData.push({
        label: data.name,
        value: data.id,
      })
    );

  states &&
    states.map((data) =>
      stateData.push({
        label: data.name,
        value: data.id,
      })
    );

  productCategoryData &&
    productCategoryData.map((data) =>
      materialCategoryData.push({
        label: data.category_name,
        value: data.product_category_master_id,
      })
    );

  allZoneMasterData &&
    allZoneMasterData.map((data) =>
      zoneData.push({
        label: data.zone_name,
        value: data.zone_master_id,
      })
    );

  const zoneOptions = useMemo(
    () =>
      allZoneMasterData?.length > 0
        ? allZoneMasterData.map((zone) => ({
          label: zone.zone_name,
          value: zone.zone_master_id,
        }))
        : [],
    [allZoneMasterData]
  );

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
            ? "ASC"
            : "",
    };

    getApiCall(
      offsetRef.current,
      pageSize,
      search,
      sorterParams.field,
      sorterParams.order,
      roleID,
      roleProfileID,
      reprotToID,
      zoneID,
      productCategoryIDs,
      status,
      isFaceIdAvailable
    );
  };

  const onOpenBulkUpdateModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (isModalOpen) {
      if (roles && roles.length === 0) {
        dispatch(actionGetRoles());
      }
      if (roleAllProfile && roleAllProfile.length === 0) {
        dispatch(actionGetAllRoleProfile());
      }
      if (countries && countries.length === 0) {
        dispatch(actionGetCountries());
      }
      if (companyAllUsers && companyAllUsers.length === 0) {
        dispatch(actionGetCompanyAllUsers());
      }
      if (productCategoryData && productCategoryData.length === 0) {
        dispatch(actionGetProductCategoryMaster());
      }
      if (allZoneMasterData && allZoneMasterData.length === 0) {
        dispatch(actionGetAllZoneMaster());
      }
    }
  }, [isModalOpen]);

  const onCloseBulkUpdateModal = () => {
    setIsModalOpen(false);
    updateForm.resetFields();
    setIsFormChanged(false);
  };

  const handlePaginationChange = (page, pageSize) => {
    offsetRef.current = page - 1;
    setPageSize(pageSize);
    getApiCall(
      offsetRef.current,
      pageSize,
      search,
      "",
      "",
      roleID,
      roleProfileID,
      reprotToID,
      zoneID,
      productCategoryIDs,
      status,
      isFaceIdAvailable
    );
  };

  return (
    <section className="main-wrapper main-table-container ">
      <CommonTable
        moduleKey="users"
        title="Users"
        total={companyUsersCount}
        pageSize={pageSize}
        pageTitle={<PageTitle
          margin={false} image={User} title="Users" rightIcon={false} />}
        buttonText="Add User"
        buttonLink="/create-user"
        resource="company-users"
        loading={
          getCompanyUserLoader ||
          (exportAll === true && getCompanyAllUserLoader)
        }
        columns={initialColumns}
        isCheckbox={true}
        data={companyUsers}
        rowKey={"id"}
        rowSelection={rowSelection}
        onChange={handleSortChange}
        handleRefresh={handleRefresh}
        handleDownload={handleDownload}
        filterValues={filterValues}
        isAdvanceFilter={true}
        handleSearch={handleSearch}
        onClickFilter={handleOpenFilterModal}
        handleBlurAndPressEnter={handleBlurAndPressEnter}
        search={search}
        importRoute="/users-import"
        isImportEnabled={true}
        isDeactivate={selectedRowKeys && selectedRowKeys.length > 1}
        onClickDeactivate={onClickDeactivate}
        items={items}
        onClickDownloadMenu={onClickDownloadMenu}
        offset={offsetRef.current}
        scroll={{ y: false }}
        content={
          <>
            {selectedRowKeys.length > 0 && (
              <TooltipWrapper title={"Bulk Edit"}>
                <Button
                  style={{ backgroundColor: "white" }}
                  onClick={onOpenBulkUpdateModal}
                  className="h-full  default-button bg-white  icon-btn"
                >
                  <Image
                    preview={false}
                    height={17}
                    width={17}
                    src={bulkUpdate}
                    alt="bulk Edit"
                  />
                </Button>
              </TooltipWrapper>
            )}
          </>
        }
        filteredFields={filteredFields}
        handlePaginationChange={handlePaginationChange}
        getFieldLabel={getFieldLabel}
        handleResetFilter={handleResetFilterModal}
      />

      {/* DELETE MODAL START */}
      <Modal
        title={
          <p className="font-popinsMedium text-lg">{`Delete ${deleteData.first_name ? deleteData.first_name : ""
            } ${deleteData.last_name ? deleteData.last_name : ""}`}</p>
        }
        centered
        open={deleteData}
        width={700}
        onOk={() => setDeleteData(false)}
        onCancel={() => setDeleteData(false)}
        footer={[
          <Button key="back" onClick={() => setDeleteData(false)}>
            Cancle
          </Button>,
          <Button
            key="submit"
            type="primary"
            danger
          //loading={loading}
          //onClick={handleOk}
          >
            Delete
          </Button>,
        ]}
      >
        <p className="font-popinsMedium mt-2">Select a deletion option</p>
        <Radio.Group
          onChange={onChangeRadioValue}
          value={radioButtonvalue}
          className="mt-4"
        >
          <Space direction="vertical">
            <Radio value={1}>Delete without transfer</Radio>
            <Radio value={2}>Transfer now</Radio>
          </Space>
        </Radio.Group>
        {radioButtonvalue == 2 && (
          <div className="mt-3 ml-6 flex items-center">
            <p className="font-popinsRegular">Transfer To</p>
            <Select
              className="ml-2 modal-select"
              showSearch
              placeholder="Transfer to"
              allowClear
              onClear={() => setTransferValue("")}
              onChange={onSelect}
              filterOption={(input, option) =>
                (option?.value ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={transferOptions}
              disabled={getCompanyAllUserLoader}
            />
          </div>
        )}
        <div className="mt-5 border border-red-300 rounded-xl bg-red-50 p-5">
          <p className="font-popinsMedium text-base">Note</p>
          <p className="font-popinsRegular ml-1 mt-2 text-[#505050] text-[13]">
            • If the deleted user has integrated any additional applications
            with this CRM account, those integrations will be disabled
          </p>
          {radioButtonvalue == 1 && (
            <>
              <p className="font-popinsRegular ml-1 mt-2 text-[#505050] text-[13]">
                • All the associates data will be removed permanently and cannot
                be recovered anymore.
              </p>
            </>
          )}
          {radioButtonvalue == 2 && (
            <>
              <p className="font-popinsRegular text-base mt-2">
                Record ownership
              </p>
              <p className="font-popinsRegular ml-1 mt-1 text-[#505050] text-[13]">
                • All open records associated with the deleted user will be
                transferred to the new user
              </p>
            </>
          )}
        </div>
      </Modal>
      {/* DELETE MODAL ENDS */}

      {/* Filter Modal  */}
      <Modal
        width={500}
        centered
        open={filterModal}
        onOk={handleCloseFilterModal}
        onCancel={handleCloseFilterModal}
        afterClose={handleCloseFilterModal}
        footer={false}
      //loading={getRolesLoader || getAllRoleProfileLoader || getAllZoneMasterLoader || getProductCategoryLoader || getCompanyAllUserLoader}
      >
        <section className="main-section">
          <div className="mb-4">
            <CustomIconText
              icon={<LuFilter size={25} color="#6883fd" />}
              title="User Filter"
            />
          </div>
          <Form
            form={form}
            layout="vertical"
            name="basic"
            onValuesChange={() => setIsFilterFormChanged(true)}
            onFinish={handleApplyFilter}
            autoComplete="off"
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Role" name="role_id" className="mb-3">
                  <Select
                    allowClear
                    showSearch
                    mode="multiple"
                    maxTagCount="responsive"
                    placeholder="Select Role"
                    optionFilterProp="children"
                    className=""
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={roleData}
                    disabled={getRolesLoader}
                    loading={getRolesLoader}
                    maxTagPlaceholder={(omittedValues) => (
                      <Tooltip
                        overlayStyle={{
                          pointerEvents: "none",
                        }}
                        title={omittedValues
                          .map(({ label }) => label)
                          .join(", ")}
                      >
                        <span>{`+${omittedValues.length} more`}</span>
                      </Tooltip>
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Role Profile"
                  name="role_profile_id"
                  className="mb-3"
                >
                  <Select
                    allowClear
                    showSearch
                    mode="multiple"
                    maxTagCount="responsive"
                    placeholder="Select Role Profile"
                    optionFilterProp="children"
                    className=""
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={roleProfileData}
                    disabled={getAllRoleProfileLoader}
                    loading={getAllRoleProfileLoader}
                    maxTagPlaceholder={(omittedValues) => (
                      <Tooltip
                        overlayStyle={{
                          pointerEvents: "none",
                        }}
                        title={omittedValues
                          .map(({ label }) => label)
                          .join(", ")}
                      >
                        <span>{`+${omittedValues.length} more`}</span>
                      </Tooltip>
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Report To" name="reports_to" className="mb-3">
                  <Select
                    allowClear
                    showSearch
                    mode="multiple"
                    maxTagCount="responsive"
                    placeholder="Select Report To"
                    optionFilterProp="children"
                    className=""
                    filterOption={(input, option) =>
                      (option?.search ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={reportsToData}
                    disabled={getCompanyAllUserLoader}
                    loading={getCompanyAllUserLoader}
                    maxTagPlaceholder={(omittedValues) => (
                      <Tooltip
                        overlayStyle={{
                          pointerEvents: "none",
                        }}
                        title={
                          <div>
                            {omittedValues.map(({ value }) => {
                              const user = companyAllUsers.find(
                                (user) => user.id === value
                              );
                              return (
                                <div
                                  key={value}
                                  className="flex items-center mb-1"
                                >
                                  <Avatar
                                    size={20}
                                    src={user?.profile_pic}
                                    className="mr-1"
                                  />
                                  <span>{`${user?.first_name || ""} ${user?.last_name || ""
                                    }`}</span>
                                </div>
                              );
                            })}
                          </div>
                        }
                      >
                        <span>{`+${omittedValues.length} more`}</span>
                      </Tooltip>
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Zone" name="zone_id" className="mb-3">
                  <Select
                    allowClear
                    showSearch
                    mode="multiple"
                    maxTagCount="responsive"
                    placeholder="Select Zone"
                    optionFilterProp="children"
                    className=""
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={zoneData}
                    disabled={getAllZoneMasterLoader}
                    loading={getAllZoneMasterLoader}
                    maxTagPlaceholder={(omittedValues) => (
                      <Tooltip
                        overlayStyle={{
                          pointerEvents: "none",
                        }}
                        title={omittedValues
                          .map(({ label }) => label)
                          .join(", ")}
                      >
                        <span>{`+${omittedValues.length} more`}</span>
                      </Tooltip>
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Material Access"
                  name="material_category_ids"
                  className="mb-3"
                >
                  <Select
                    allowClear
                    showSearch
                    mode="multiple"
                    maxTagCount="responsive"
                    placeholder="Select Material Category"
                    optionFilterProp="children"
                    className=""
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    loading={getProductCategoryLoader}
                    options={materialCategoryData}
                    disabled={getProductCategoryLoader}
                    maxTagPlaceholder={(omittedValues) => (
                      <Tooltip
                        overlayStyle={{
                          pointerEvents: "none",
                        }}
                        title={omittedValues
                          .map(({ label }) => label)
                          .join(", ")}
                      >
                        <span>{`+${omittedValues.length} more`}</span>
                      </Tooltip>
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Status" name="status" className="mb-3">
                  <Select
                    allowClear
                    showSearch
                    mode="multiple"
                    maxTagCount="responsive"
                    placeholder="Select Status"
                    optionFilterProp="children"
                    className=""
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[
                      {
                        value: "INVITED",
                        label: "Invited",
                      },
                      {
                        value: "ACTIVE",
                        label: "Active",
                      },
                      {
                        value: "DEACTIVE",
                        label: "Deactive",
                      },
                      {
                        value: "REJECT_INVITATION",
                        label: "Reject Invitation",
                      },
                    ]}
                    maxTagPlaceholder={(omittedValues) => (
                      <Tooltip
                        overlayStyle={{
                          pointerEvents: "none",
                        }}
                        title={omittedValues
                          .map(({ label }) => label)
                          .join(", ")}
                      >
                        <span>{`+${omittedValues.length} more`}</span>
                      </Tooltip>
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Face ID" name="face_id" className="mb-3">
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select Configured or Non Configured"
                    optionFilterProp="children"
                    className=""
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[
                      {
                        value: "True",
                        label: "Configured",
                      },
                      {
                        value: "False",
                        label: "Non Configured",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col
                span={24}
                className="flex items-center justify-center gap-x-6 mt-3"
              >
                <Form.Item className="mb-0">
                  <Button
                    type="button"
                    className="gray-button text-white hover:text-white  min-h-[45px] min-w-[120px]"
                    onClick={handleResetFilterModal}
                  >
                    Reset
                  </Button>
                </Form.Item>
                <Form.Item className="mb-0">
                  <Button
                    disabled={!isFilterFormChanged}
                    loading={isReset ? "" : getCompanyUserLoader}
                    type="primary"
                    className="root-btn  min-h-[45px] min-w-[120px]"
                    htmlType="submit"
                  >
                    Apply
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </section>
      </Modal>
      {/* Filter Modal  */}

      {/* Bulk Update Modal */}

      <Modal
        title={null}
        open={isModalOpen}
        style={{ top: 20 }}
        width={700}
        onOk={onCloseBulkUpdateModal}
        onCancel={onCloseBulkUpdateModal}
        loading={false}
        footer={false}
        afterClose={() => {
          updateForm.resetFields();
        }}
      >
        <div className="flex items-center gap-x-2">
          <Image
            preview={false}
            height={20}
            width={20}
            src={bulkUpdate}
            alt="bulk Edit"
          />{" "}
          <h1 className="text-[#6883FD] text-[20px]  font-medium">Bulk Edit</h1>
        </div>
        <Form
          onValuesChange={() => setIsFormChanged(true)}
          form={updateForm}
          onFinish={(values) => {
            const req = {
              zip_code: values.zip_code ? values.zip_code : null,
              designation: values.designation ? values.designation : null,
              company_user_id: selectedRowKeys,
              role_id: values.role_id ? values.role_id : null,
              role_profile_id: values.role_profile_id
                ? values.role_profile_id
                : null,
              reports_to_id: values.reports_to_id ? values.reports_to_id : null,
              zone_id: values.zone_id ? values.zone_id : null,
              material_category_ids:
                values.material_category_ids &&
                  values.material_category_ids.length > 0
                  ? values.material_category_ids
                  : null,
            };

            dispatch(
              actionUpdateBulkUsers({
                req,
                handleClose: onCloseBulkUpdateModal,
                setIsFormChanged: setIsFormChanged,
              })
            );
          }}
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Role" name="role_id">
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Role"
                  optionFilterProp="children"
                  className=""
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={roleData}
                  disabled={getRolesLoader}
                  loading={getRolesLoader}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Role Profile" name="role_profile_id">
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Role Profile"
                  optionFilterProp="children"
                  className=""
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={roleProfileData}
                  disabled={getAllRoleProfileLoader}
                  loading={getAllRoleProfileLoader}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Report To" name="reports_to_id">
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Report To"
                  optionFilterProp="children"
                  className=""
                  filterOption={(input, option) =>
                    (option?.search ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={reportsToData}
                  disabled={getCompanyAllUserLoader}
                  loading={getCompanyAllUserLoader}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Designation" name="designation">
                <Input
                  className="!indent-0.5"
                  placeholder="Enter Designation"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Material Access" name="material_category_ids">
                <Select
                  allowClear
                  showSearch
                  mode="multiple"
                  placeholder="Select Material"
                  optionFilterProp="children"
                  className=""
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={materialCategoryData}
                  loading={getProductCategoryLoader}
                  disabled={getProductCategoryLoader}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Zip Code"
                name="zip_code"
                normalize={(value) => value.replace(/[^a-zA-Z0-9]/g, "")}
              >
                <Input maxLength={8} placeholder="Enter Zip Code" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Zone" name="zone_id">
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Zone"
                  optionFilterProp="children"
                  className=""
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={zoneOptions}
                  disabled={getAllZoneMasterLoader}
                  loading={getAllZoneMasterLoader}
                />
              </Form.Item>
            </Col>
            <Col
              span={24}
              className="flex items-center mt-4 justify-center gap-x-6 bulk-update-buttons"
            >
              <Form.Item>
                <Button
                  type="button"
                  disabled={updateBulkUsersLoader}
                  className="gray-button text-white hover:text-white  min-h-[45px] min-w-[120px]"
                  onClick={() => {
                    updateForm.resetFields();
                    setIsModalOpen(false);
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  loading={updateBulkUsersLoader}
                  disabled={!isFormChanged}
                  type="primary"
                  className="root-btn !disabled:bg-brand/90 min-h-[45px] min-w-[120px]"
                  htmlType="submit"
                >
                  Update
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      {/* Bulk Update Modal */}
    </section>
  );
};

export default Users;
