import { useGoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";
import { Button, Checkbox, Divider, Form, Image, Input, Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { HiOutlineLockClosed } from "react-icons/hi";
import { MdMailOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import google from "../../assets/img/google-logo.svg";
import {
  actionContinueWithGoogle,
  actionRegister,
} from "../../store/services/authService";
import PhoneInputComponent from "../common/PhoneInputComponent";
import { getStoredUserData, startTimer } from "../helper/common-function";
import { jwtDecode } from "jwt-decode";
import useDeviceInfo, { DeviceType } from "../../hooks/use-device-info";
import Password from "antd/es/input/Password";
import { useAuthContext } from "../../context/AuthContext";

const Register = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { state } = useLocation();
  const data = getStoredUserData();
  const [customLoader, setCustomLoader] = useState(false);

  const [value, setValue] = useState({
    fullPhoneNumber: "",
    iso: "in",
    phone: "",
  });


  const { setEmailTimer = null, setPhoneTimer = null } = useAuthContext();

  useEffect(() => {
    if (state && state.wrongDetails && data) {
      form.setFieldsValue({
        email: data?.email,
        password: data?.password,
        remember: data?.remember

      });
      setValue({
        ...value,
        fullPhoneNumber: data?.phone_country_code + data?.phone,
      });
    }
  }, [state && state.wrongDetails]);

  const [isValid, setIsValid] = useState(true);
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const { registerLoader } = useSelector((state) => state.auth);

  const handleChange = (valid) => {
    setIsValid(valid);
  };


  const startOTPTimer = () => {
    startTimer(setEmailTimer);
    startTimer(setPhoneTimer);
  }

  const onFinish = (values) => {
    const { remember, ...data } = values;
    const valid =
      value && value.phone && value.phone.length < 15 ? true : false;

    setError("");

    if (valid) {
      setIsValid(true);
      const req = {
        ...data,

        phone: value.phone,
        country_code: value.iso,
      };
      dispatch(
        actionRegister({
          values: req,
          navigate,
          remember,
          phoneNum: value.fullPhoneNumber,
          startOTPTimer
        })
      );
    } else {
      setIsValid(false);
    }
  };

  const onFinishFailed = () => {
    const valid =
      value && value.phone && value.phone.length < 15 ? true : false;
    setError("");

    if (valid) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const {
    osName,
    osVersion,
    deviceName,
    deviceType,
    batteryPercentage,
    latitude,
    browserName,
    longitude,
  } = useDeviceInfo();

  const company_user_logs = {
    device_name: deviceName,
    os_name: osName,
    os_version: osVersion,
    device_type: deviceType,
    is_tablet: deviceType === DeviceType.TABLET,
    battery_parcentage: batteryPercentage,
    latitude,
    longitude,
    user_log_from: "LOG_IN",
  };

  // Sign in with google new approch
  const googleLogin = useGoogleLogin({
    cancel_on_tap_outside: false,
    onSuccess: async (tokenResponse) => {
      const fcm_token = localStorage.getItem("firebase_token");

      const userInfo = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } }
      );

      const req = {
        token: tokenResponse.access_token,
        company_user_logs,
        fcm_token,
        token_type: "Access Token",
      };

      dispatch(
        actionContinueWithGoogle({
          req,
          navigate,
          setCustomLoader,
          user: userInfo.data,
        })
      );
    },
    onError: () => {
      setCustomLoader(false);
    },
    onNonOAuthError: () => {
      setCustomLoader(false);
    },
  });

  return (
    <Spin spinning={customLoader}>
      <div className="register-container relative">
        <Form
          form={form}
          name="normal_login"
          className="login-form w-full pt-5"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{ remember: false }}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter your email!",
              },
              {
                                pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,

                message: "Please enter a valid email!",
              },
            ]}
          >
            <Input
              placeholder="Email"
              className="indent-5"
              prefix={
                <MdMailOutline
                  color="#4567ff"
                  className="h-[18px] w-[18px] site-form-item-icon font-bold"
                />
              }
            />
          </Form.Item>
          <Form.Item
            name="password"
            normalize={(value) =>
              value ? value.replace(/[^a-zA-Z0-9!@#$%^&*()_+=[\]{}|;:'",.<>/?\\/-]/g, '') : ''
            }
            rules={[
              {
                required: true,
                message: "Please enter your password!",
              },
              () => ({
                validator(_, value) {
                  const normalizedValue = value ? value.replace(/[^a-zA-Z0-9!@#$%^&*()_+=[\]{}|;:'",.<>/?\\/-]/g, '') : '';

                  if (
                    (value && value.length < 8) ||
                    (value && value.length > 16)
                  ) {
                    return Promise.reject(
                      "Password must be between 8 and 16 characters."
                    );
                  }
                  if (/\s/.test(normalizedValue)) {
                    return Promise.reject("Password cannot contain spaces.");
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.Password
              placeholder="Password"
              className="indent-5"
              prefix={
                <HiOutlineLockClosed
                  color="#4567ff"
                  className="h-[18px] w-[18px] site-form-item-icon font-bold"
                />
              }
              type={"text"}
            />
          </Form.Item>

          <PhoneInputComponent
            value={value.fullPhoneNumber}
            onChange={handleChange}
            setIsValid={setIsValid}
            isValid={isValid}
            setValue={setValue}
          />

          <Form.Item
            name="remember"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) => {
                  if (value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "You must agree to the terms of service and privacy policy."
                    )
                  );
                },
              },
            ]}
          >
            <Checkbox className="text-sm font-medium">
              I agree to the
              <>
                <Link
                  to="/terms-and-condition"
                  className="ml-[5px] text-primary hover:text-primary/90"
                  target="_blank"
                >
                  Terms of Service&nbsp;
                </Link>
                and
                <Link
                  to="/privacy-policy"
                  className="ml-1 text-primary hover:text-primary/90"
                  target="_blank"
                >
                  Privacy Policy
                </Link>
                .
              </>
            </Checkbox>
          </Form.Item>

          <Form.Item>
            <Button
              loading={registerLoader}
              type="primary"
              className="filled-s-button mt-3 md:text-base text-sm  border-none rounded-[10px]  min-h-[45px]"
              block
              htmlType="submit"
            >
              Sign up
            </Button>
          </Form.Item>
        </Form>

        <p className="text-center">
          Already have an account?{" "}
          <Link to="/login" className="text-primary">
            Sign in
          </Link>
        </p>

        <Divider className="text-[#fff]">or</Divider>

        <Button
          loading={customLoader}
          onClick={() => {
            setCustomLoader(true);
            googleLogin();
          }}
          type="primary"
          className="google-btn md:text-base text-sm bg-white hover:bg-white text-black border-none rounded-[10px] font-popinsMedium tracking-wider min-h-[45px] h-full flex items-center justify-center"
          block
          htmlType="submit"
          icon={
            <Image
              preview={false}
              height={30}
              width={30}
              src={google}
              alt="Google Logo"
            />
          }
        >
          Sign in with Google
        </Button>
      </div>
    </Spin>
  );
};

export default Register;
