import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Form, Image, Modal, Tree } from "antd";
import React, { useEffect, useState } from "react";
import { TbHierarchy } from "react-icons/tb";
import AddRole from "../../setting/roles/AddRole";
import PageTitle from "../../common/PageTitle";
import DeleteRoleForm from "./DeleteRoleForm";
import { actionGetRoleHierachy } from "../../../store/services/settingService";
import { useDispatch, useSelector } from "react-redux";
import { getAllKeys } from "../../helper/common-function";

import { handleActionWithPermission } from "../../../store/slices/authSlice";

const Hierarchy = () => {
  const dispatch = useDispatch();
  const { roles, roleHierachy, getRolesHierachyLoader } = useSelector(
    (state) => state.setting
  );
  const [hierarchyForm] = Form.useForm();
  const [hovered, setHovered] = useState(null);
  const [modalForm, setModalForm] = useState(false);
  const [deleteModalForm, setDeleteModalForm] = useState(false);
  const [deleteData, setDeleteData] = useState("");
  const [editId, setEditId] = useState("");
  const [firstChild, setFirstChild] = useState(false);
  const [treeData, setTreeData] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState();
  const [selectesParentRole, setSelectesParentRole] = useState(null);

  useEffect(() => {
    dispatch(actionGetRoleHierachy());
  }, [dispatch]);

  const transformData = (data, isRoot = true, isRootChild = false) => {
    // initial root will become disabled
    return data.map((item, index) => ({
      title: item.name,
      key: item.id,
      children: item.children
        ? transformData(
          item.children,
          false,
          isRoot && index === 0 // This line ensures that the first child of the root node is marked as a root child
        )
        : [],
      disabled: isRoot,
      isFirstChild: isRootChild, // This line sets the `isFirstChild` property to true if the node is the first child of the root
    }));
  };

  useEffect(() => {
    if (roleHierachy && roleHierachy.length > 0) {
      const transformedData = transformData(roleHierachy);
      setTreeData(transformedData);
      setExpandedKeys(getAllKeys(transformedData));
    }
  }, [roleHierachy]);

  const handleExpandAll = () => {
    setExpandedKeys(getAllKeys(treeData));
  };

  const handleCollapseAll = () => {
    setExpandedKeys([]);
  };

  const handleModalClose = () => {
    setModalForm(false);
    setSelectesParentRole(null)
    setEditId("");
    hierarchyForm.resetFields();
  };

  const handleDeleteModalClose = () => {
    setDeleteModalForm(false);
    setDeleteData("");
  };
  const handleAddRoleModal = (nodeData) => {
    setModalForm(true);
    setFirstChild("");
    setEditId("");
    console.log("Called", nodeData)
    setSelectesParentRole({ ...nodeData });
  };



  return (
    <section className="main-section">
      <PageTitle
        buttonText={"New Role"}
        handleClick={() => handleAddRoleModal(treeData[0]?.children[0])}
        icon={<TbHierarchy color="#6883FD" size={20} />}
        title="Hierarchy"
        type="btn"
        resource="role"
      />

      <Card className="hierarchy-card main-card w-full">
        <Tree
          expandedKeys={expandedKeys}
          showLine
          showIcon={false}
          onExpand={(keys) => setExpandedKeys(keys)}
          treeData={treeData}
          titleRender={(nodeData) => {
            return (
              <div
                onMouseEnter={() =>
                  !nodeData.disabled && setHovered(nodeData.key)
                }
                onMouseLeave={() => setHovered(null)}
                style={{
                  display: "flex",
                  cursor: !nodeData.disabled ? "pointer" : "auto",
                  color: nodeData.disabled ? "grey" : "inherit",
                }}
              >
                <div
                  style={{
                    color: nodeData.key === hovered ? "#6883FD" : "inherit",
                  }}
                >
                  {nodeData.title}
                </div>
                {nodeData.key === hovered && !nodeData.disabled && (
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      background: "white",
                      borderRadius: "4px",
                      zIndex: 1,
                      marginLeft: 20,
                    }}
                  >
                    <Button
                      type="primary"
                      size="small"
                      shape="circle"
                      className="add-outline-btn group/add"
                      icon={
                        <PlusOutlined className="text-gray group-hover/add:text-brand text-xs" />
                      }
                      onClick={() => {
                        dispatch(
                          handleActionWithPermission("role", "create", () =>
                            handleAddRoleModal(nodeData)
                          )
                        );
                      }}
                    />
                    <Button
                      className="edit-outline-btn group/edit"
                      size="small"
                      shape="circle"
                      icon={
                        <EditOutlined className="text-gray group-hover/edit:text-[#379FFF] text-xs" />
                      }
                      onClick={() => {
                        dispatch(
                          handleActionWithPermission("role", "update", () =>
                            setModalForm(true),
                            setEditId(nodeData.key),
                            setFirstChild(nodeData.isFirstChild),
                            setSelectesParentRole(null)
                          )
                        );
                      }}
                    />
                    {!nodeData.isFirstChild && (
                      <Button
                        size="small"
                        className="danger-outline-btn group/delete"
                        shape="circle"
                        icon={
                          <DeleteOutlined className="text-gray group-hover/delete:text-[#FD6868] text-xs" />
                        }
                        onClick={() => {
                          dispatch(
                            handleActionWithPermission("role", "delete", () =>
                              setDeleteModalForm(true),
                              setDeleteData(nodeData)
                            )
                          );
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
            );
          }}
        />
        <div className="absolute top-5 right-5">
          <span
            className="font-popinsRegular text-brand cursor-pointer"
            onClick={handleExpandAll}
          >
            Expand All
          </span>
          <Divider type="vertical" />
          <span
            className="font-popinsRegular text-brand cursor-pointer"
            onClick={handleCollapseAll}
          >
            Collapse All
          </span>
        </div>
        <Modal afterClose={handleModalClose}
          width={1000}
          centered
          open={modalForm}
          onOk={() => handleModalClose()}
          onCancel={() => handleModalClose()}
          footer={false}
        >
          <AddRole
            selectesParentRole={selectesParentRole}
            editId={editId}
            data={treeData}
            firstChild={firstChild}
            hierarchyForm={hierarchyForm}
            handleCancel={() => handleModalClose()}
          />
        </Modal>
        <Modal
          afterClose={handleDeleteModalClose}
          width={700}
          centered
          open={deleteModalForm}
          onOk={handleDeleteModalClose}
          onCancel={handleDeleteModalClose}
          footer={false}
        >
          <DeleteRoleForm
            deleteData={deleteData}
            handleCancel={handleDeleteModalClose}
          />
        </Modal>
      </Card>
    </section>
  );
};

export default Hierarchy;
