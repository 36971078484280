import { Col, Row, Skeleton } from "antd";
import React from "react";

const SkeletonUpdateCard = ({ length = 12 }) => {
  return (
    <>
      <Row gutter={24} className="mt-4">
        {Array.from({ length: length }).map((_, index) => (
          <React.Fragment key={index}>
            <Col span={12}>
              <Skeleton.Input
                active
                size="default"
                className="antd-second-custom-input"
                style={{ height: "35px", marginBottom: "20px" }}
              />
            </Col>
            <Col span={12}>
              <Skeleton.Input
                active
                size="default"
                className="antd-second-custom-input"
                style={{ height: "35px", marginBottom: "20px" }}
              />
            </Col>
          </React.Fragment>
        ))}
      </Row>
    </>
  );
};

export default SkeletonUpdateCard;
