import { createSlice } from "@reduxjs/toolkit";
import { actionClearAllReminders, actionCreateReminder, actionDeleteReminder, actionGetAllReminders, actionGetReminders, actionGetSingleReminderDetail, actionUpdateReminder } from "../services/quickRemiderService";

const quickReminderSlice = createSlice({
    name: "reminder",
    initialState: {
        addReminderLoader: false,
        remindersCounts: 0,
        getRemindersLoader: false,
        remindersData: [],
        getAllRemindersLoader: false,
        allRemindersData: [],
        deleteReminderLoader: false,
        singleReminderDetail: [],
        getSingleReminderDetailLoader: false,
        updateReminderLoader: false
    },
    reducers: {},
    extraReducers: (buider) => {
        buider
            // add reminder state
            .addCase(actionCreateReminder.pending, (state) => {
                state.addReminderLoader = true;
            })
            .addCase(actionCreateReminder.fulfilled, (state, action) => {
                state.addReminderLoader = false;
                if (action.payload) {
                    if (!state.remindersData || !Array.isArray(state.remindersData)) {
                        state.remindersData = [action.payload];
                    } else {
                        state.remindersData.unshift(action.payload);
                    }
                    state.remindersCounts =
                        (isNaN(parseInt(state.remindersCounts))
                            ? 0
                            : parseInt(state.remindersCounts)) + 1;
                }
            })
            .addCase(actionCreateReminder.rejected, (state) => {
                state.addReminderLoader = false;
            })

            // get reminder State
            .addCase(actionGetReminders.pending, (state) => {
                state.getRemindersLoader = true;
            })
            .addCase(actionGetReminders.fulfilled, (state, action) => {
                state.getRemindersLoader = false;
                state.remindersData = action.payload.data;
                state.remindersCounts = action.payload.total_records;
            })
            .addCase(actionGetReminders.rejected, (state) => {
                state.getRemindersLoader = false;
            })

            // get all reminders
            .addCase(actionGetAllReminders.pending, (state) => {
                state.getAllRemindersLoader = true;
            })
            .addCase(actionGetAllReminders.fulfilled, (state, action) => {
                state.getAllRemindersLoader = false;
                state.allRemindersData = action.payload.data;
            })
            .addCase(actionGetAllReminders.rejected, (state) => {
                state.getAllRemindersLoader = false;
            })

            // Clear All reminders Data
            .addCase(actionClearAllReminders.fulfilled, (state, action) => {
                state.allRemindersData = action.payload;
            })

            //delete reminder
            .addCase(actionDeleteReminder.pending, (state) => {
                state.deleteReminderLoader = true;
            })

            .addCase(actionDeleteReminder.fulfilled, (state, action) => {
                state.deleteReminderLoader = false;
                state.remindersData = state.remindersData.filter(
                    (data) => data.reminder_id !== action.payload
                );
                state.remindersCounts = state.remindersCounts - 1;
            })

            .addCase(actionDeleteReminder.rejected, (state) => {
                state.deleteReminderLoader = false;
            })

            //get single teminder detail
            .addCase(actionGetSingleReminderDetail.pending, (state) => {
                state.getSingleReminderDetailLoader = true;
            })
            .addCase(actionGetSingleReminderDetail.fulfilled, (state, action) => {
                state.getSingleReminderDetailLoader = false;
                state.singleReminderDetail = action.payload.data;
            })
            .addCase(actionGetSingleReminderDetail.rejected, (state) => {
                state.getSingleReminderDetailLoader = false;
            })

            //update reminder
            .addCase(actionUpdateReminder.pending, (state) => {
                state.updateReminderLoader = true;
            })
            .addCase(actionUpdateReminder.fulfilled, (state, action) => {
                state.updateReminderLoader = false;
            })
            .addCase(actionUpdateReminder.rejected, (state) => {
                state.updateReminderLoader = false;
            })
    },
});

export default quickReminderSlice.reducer;
