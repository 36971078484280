export const TASK_PRIORITY = Object.freeze({
  HIGHEST: "Highest",
  HIGH: "High",
  NORMAL: "Normal",
  LOW: "Low",
  LOWEST: "Lowest",
});

export const TASK_STATUS = Object.freeze({
  NOT_STARTED: "Not Started",
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed",
  DEFERRED: "Deferred",
  WAITING_FOR_INPUT: "Waiting For Input",
});

export const TASK_REPEAT = Object.freeze({
  NONE: "None",
  DAILY: "Daily",
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
  YEARLY: "Yearly",
});

export const gridSettings = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 12,
  xl: 12,
};

export const FieldType = Object.freeze({
  TEXT: "Text",
  EMAIL: "Email",
  NUMBER: "Number",
  TEXTAREA: "TextArea",
  DATE: "Date",
  CHECKBOX: "Checkbox",
  DROPDOWN: "Dropdown",
  MULTI_SELECT: "MultiSelect",
  PHONE: "Phone",
  URL: "URL",
});



// if change then need to notify backend for notification route & also change in firebase-messanging-sw.js
export const ModulePaths = Object.freeze({
  EVENT: "event-overview/:id",
  TASK: "task?task_id=",
  LEAD: "lead-management?id=",
  OPPORTUNITY: "opportunity-management?id=",
  COLLECTION_MANAGEMENT: "collection-management?id=",
  EXPENSE_VOUCHER: "view-expense-voucher/:id",
  FEEDBACK: "view-feedback-mechanism/:id",
  REMINDER: "quick-reminder?id=",
  REMINDER: "quick-reminder?id=",
  COMPANY_USERS: "update-user/:id"
});



export const tableRoutes = [
  "/users",
  "/business-partners",
  "/contacts",
  "/events",
  "/attendees",
  "/exhibitors",
  "/sessions",
  "/speakers",
  "/event-inventory",
  "/sponsor",
  "/lead-management",
  "/opportunity-management",
  "/request-for-quotation",
  "/beat-planning",
  "/collection-management",
  "/expense-voucher",
  "/sales-orders",
  "/sales-invoices",
  "/quick-reminder",
  "/task",
  "/role-profile",
  "/feedback-mechanism",
]


export const ADVANCED_FILTER_FIELD_TYPES = {
  NUMBER: 'input_number',
  STRING: 'input_string',
  DROPDOWN: "dropdown",
  DATE: "date",
}