/*  */
import { useState } from "react";
import axios from "axios";
import { BASE_URL_UPLOAD } from "../config/web-config";
import { toast } from "sonner";

const useFileUpload = ({ size = 104857600 } = {}) => {
  const [isUploading, setUploading] = useState(false);

  // Check file size before upload
  const validateFileSize = (files) => {
    let totalSize = 0;
    for (let file of files) {
      totalSize += file.size;
      if (file.size > size) {
        toast.error(
          `${file.name} exceeds the size limit of ${size / (1024 * 1024)} MB.`
        );
        return false;
      }
    }

    if (totalSize > size) {
      toast.error(
        `Total file size exceeds the limit of ${size / (1024 * 1024)} MB.`
      );
      return false;
    }

    return true;
  };

  const handleUpload = async (files, setState) => {
    if (!validateFileSize(files)) {
      return;
    }

    const formData = new FormData();

    files?.forEach((file) => formData.append("files", file));

    try {
      setUploading(true);
      const response = await axios.post(
        `${BASE_URL_UPLOAD}/upload-multiple`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("crmWebToken"),
            "Content-Type": "multipart/form-data",
          },

        }
      );
      const newFiles = response.data.data;
      setState((prevFiles) => [
        ...(Array.isArray(prevFiles) ? prevFiles : []),
        ...(Array.isArray(newFiles) ? newFiles : []).map((file) => ({
          ...file,
          file: files.find((f) => f.name === file.originalname),
        })),
      ]);
    } catch (error) {
      console.error("Upload failed:", error);
      toast.error(error?.response?.data?.message);
    } finally {
      setUploading(false);
    }
  };

  return { handleUpload, isUploading };
};

export default useFileUpload;
