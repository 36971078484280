import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Form,
  Popconfirm,
  Typography,
  Table,
  Modal,
  Image,
} from "antd";
import SortIcon from "../../common/SortIcon";
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import editevent from "../../../assets/img/editevent.svg";
import deletevent from "../../../assets/img/deleteevent.svg";
import editedevent from "../../../assets/img/editedevent.svg";
import plusIcon from "../../../assets/img/add-button.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  actionDeleteEventOnlinePlatformMaster,
  actionGetEventOnlinePlatformMaster,
  actionGetSingleEventPlatformMaster,
  actionEditEventOnlinePlatformMaster,
  actionAddEventOnlinePlatformMaster,
} from "../../../store/services/masterDataService";
import { isValidSearchString } from "../../helper/common-function";
import MasterSearchbar from "../../common/MasterSearchbar";
import * as XLSX from "xlsx";
import PageTitleForMasterData from "../../common/PageTitleForMasterData";
import usePermission from "../../../hooks/use-permission";
import { handleActionWithPermission } from "../../../store/slices/authSlice";
import SkeletonTable from "../../common/SkeletonTable";
import MasterCommonTable from "../../common/MasterCommonTable";

const EventOnlinePlatforms = () => {
  const [editableForm] = Form.useForm();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    eventOnlinePlatformData,
    getSingleEventOnlinePlatformData,
    getSingleEventOnlinePlatformLoader,
    getEventOnlinePlatformLoader,
    addEventOnlinePlatformLoader,
    deleteEventOnlinePlatformLoader,
    updateEventOnlinePlatformLoader,
    // deleteEventTypeLoader,
    // updateEventTypeLoader,
  } = useSelector((state) => state.masterData);

  const originData = [];
  const [search, setSearch] = useState("");
  const [editingKey, setEditingKey] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [edited, setEdited] = useState(false);
  const [columnKey, setColumnKey] = useState(null);
  const [lastSearch, setLastSearch] = useState('');
  const { hasPermission } = usePermission();

  const isEditing = (record) =>
    record.event_online_platform_master_id === editingKey;

  useEffect(() => {
    dispatch(actionGetEventOnlinePlatformMaster({ search }));
  }, []);

  useEffect(() => {
    const trimmedSearch = search?.trim();
    if (trimmedSearch !== "" && trimmedSearch !== lastSearch) {
      const timer = setTimeout(() => {
        dispatch(
          actionGetEventOnlinePlatformMaster({ search: trimmedSearch })
        );
        setLastSearch(trimmedSearch);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }

  }, [search, lastSearch]);

  const handleSearch = (e) => {
    if (isValidSearchString(e.target.value)) {
      setSearch(e.target.value);
      if (e.target.value === "") {
        dispatch(
          actionGetEventOnlinePlatformMaster({
            search: e.target.value,
          })
        );
      }
    }
  };

  const handleBlurAndPressEnter = (e) => {
    const value = e.target.value;
    if (search && isValidSearchString(value)) {
      dispatch(
        actionGetEventOnlinePlatformMaster({
          search: e.target.value,
        })
      );
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === "number" ? <Input /> : <Input />;
    return (
      <td {...restProps} className="input-height">
        {editing ? (
          <Form.Item
            className="mb-0 editable-order"
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: ``,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          <Typography.Paragraph
            style={{ marginBottom: 0 }}
            ellipsis={{ rows: 1, expandable: false, tooltip: true }}
            className="px-[11px]"
          >
            {children}
          </Typography.Paragraph>
        )}
      </td>
    );
  };

  useEffect(() => {
    if (!editingKey) {
      setEdited(false);
    }
  }, [editingKey]);

  const edit = (record) => {
    dispatch(
      actionGetSingleEventPlatformMaster(record.event_online_platform_master_id)
    );
    setEdited(!edited);
  };

  useEffect(() => {
    if (getSingleEventOnlinePlatformData && edited) {
      editableForm.setFieldsValue({
        platform_name: getSingleEventOnlinePlatformData.platform_name,
      });
      setEditingKey(
        getSingleEventOnlinePlatformData.event_online_platform_master_id
      );
    }
  }, [getSingleEventOnlinePlatformData]);

  const confirm = (id) => {
    dispatch(actionDeleteEventOnlinePlatformMaster({ id, setEditingKey }));
  };

  const save = async (id) => {
    try {
      const row = await editableForm.validateFields();
      const { platform_name } = row;
      const req = { platform_name };
      dispatch(actionEditEventOnlinePlatformMaster({ id, req, setEditingKey }));
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const getData = async (data) => {
    const newData =
      (await data) &&
      data.length > 0 &&
      data.map((d) => ({
        "Event Online Platform": d.platform_name ? d.platform_name : "-",
      }));
    return newData;
  };

  const exportAllToExcel = async () => {
    if (
      !eventOnlinePlatformData ||
      (eventOnlinePlatformData && eventOnlinePlatformData.length === 0)
    ) {
      return;
    }
    const data = await getData(eventOnlinePlatformData);
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Event Online Platforms");
    XLSX.writeFile(workbook, "event-online-platforms.xlsx");
  };

  const columns = [
    {
      title: "SR No.",
      dataIndex: "srno",
      key: "srno",
      width: 70,
      fixed: "left",
      editable: false,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Event Online Platform",
      dataIndex: "platform_name",
      key: "platform_name",
      width: 400,
      editable: true,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Action",
      dataIndex: "operation",
      key: "action",
      fixed: "right",
      width: 100,
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <div className="flex items-center ">
            {editable ? (
              <div className="flex items-center">
                {updateEventOnlinePlatformLoader[
                  record.event_online_platform_master_id
                ] ? (
                  <LoadingOutlined />
                ) : (
                  <Typography.Link
                    onClick={() => save(record.event_online_platform_master_id)}
                  >
                    <img src={editedevent} alt="" />
                  </Typography.Link>
                )}
              </div>
            ) : (
              <div className="flex items-center gap-x-2 justify-center">
                <Typography.Link
                  disabled={editingKey !== ""}
                  onClick={() => {
                    dispatch(
                      handleActionWithPermission(
                        "event-online-platforms-master",
                        "update",
                        () => edit(record)
                      )
                    );
                  }}
                >
                  <img src={editevent} alt="" />
                </Typography.Link>
              </div>
            )}
            <div className="ml-5">
              {hasPermission("event-online-platforms-master", "delete") ? (
                <Popconfirm
                  okButtonProps={{
                    loading:
                      deleteEventOnlinePlatformLoader[
                      record.event_online_platform_master_id
                      ],
                  }}
                  title="Are you want to sure delete this?"
                  // onConfirm={cancel}
                  onConfirm={() =>
                    confirm(record.event_online_platform_master_id)
                  }
                  onOpenChange={() => console.log("open change")}
                >
                  <img src={deletevent} alt="" className="cursor-pointer" />
                </Popconfirm>
              ) : (
                <img
                  src={deletevent}
                  alt=""
                  className="cursor-pointer"
                  onClick={() => {
                    dispatch(
                      handleActionWithPermission(
                        "event-online-platforms-master",
                        "delete"
                      )
                    );
                  }}
                />
              )}
            </div>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      sortIcon: () => col.sorter ? getEventOnlinePlatformLoader && col.key === columnKey ? <LoadingOutlined /> : <SortIcon /> : null,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "srno" ? "platform_name" : "",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleAddEventOnlinePlatform = (values) => {
    dispatch(actionAddEventOnlinePlatformMaster({ values, handleCancel }));
  };

  const handleSortChange = (pagination, filters, sorter) => {
    console.log(sorter, 'sorter')
    setColumnKey(sorter.columnKey)

    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
            ? "ASC"
            : "",
    };

    dispatch(
      actionGetEventOnlinePlatformMaster({
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
  };

  useEffect(() => {
    console.log(columnKey)
  }, [columnKey])

  return (
    <div className="event-input">
      <PageTitleForMasterData
        title="Event Online Platforms"
        buttonText="Add Event Platform"
        onButtonClick={showModal}
        resource="event-online-platforms-master"
      />
      <Modal
        centered
        onOk={() => handleCancel()}
        onCancel={() => handleCancel()}
        // afterClose={() => form.resetFields()}
        footer={false}
        // className="-model"
        width={500}
        title=""
        open={isModalOpen}
      >
        <h1 className="text-[#6883FD] text-[20px]  mt-[5px] font-medium">
          Event Online Platform
        </h1>
        <Form
          // onFinish={onFinish}
          form={form}
          onFinish={handleAddEventOnlinePlatform}
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="platform_name"
            label="Enter Online Platform"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please enter event online platform!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Event Online Platform"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-center mt-[40px]">
            <Form.Item className="mb-0">
              <Button
                onClick={handleCancel}
                type="button"
                className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  type="primary"
                  loading={addEventOnlinePlatformLoader}
                  className="root-btn min-h-[45px] min-w-[120px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
      <MasterCommonTable
       moduleKey="event-online-platforms"
        columns={mergedColumns}
        dataSource={eventOnlinePlatformData}
        rowKey="event_online_platform_master_id"
        isLoading={getEventOnlinePlatformLoader}
        EditableCell={EditableCell}
        onTableChange={handleSortChange}
        editableForm={editableForm}
        searchBarProps={{
          handleSearch,
          handleBlurAndPressEnter,
          isImportEnabled: false,
          search,
          onClickDownloadMenu: exportAllToExcel,
        }}
        columnKey={columnKey}
      />
    </div>
  );
};

export default EventOnlinePlatforms;
