import { Result } from "antd";
import React from "react";
import { useRouteError } from "react-router-dom";

const ErrorPage = () => {
  const error = useRouteError();
  const errorMessage =
    error?.statusText || error?.message || "An error occurred";

  return <Result status="500" title="500" subTitle={errorMessage} />;
};

export default ErrorPage;
