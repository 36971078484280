import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Drawer,
  Dropdown,
  Image,
  List,
  Modal,
  Typography
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import contact from "../../../assets/img/contact.svg";
import downloadIcon from "../../../assets/img/download.svg";
import { actionDownloadFile } from "../../../store/services/commonService";
import {
  actionClearAllContacts,
  actionDeleteContact,
  actionGetAllContacts,
  actionGetContactDetail,
  actionGetContacts,
} from "../../../store/services/contactService";
import { handleActionWithPermission } from "../../../store/slices/authSlice";
import AdvancedFilter from "../../common/AdvancedFilter";
import CommonTable from "../../common/CommonTable";
import PageTitle from "../../common/PageTitle";
import SkeletonViewModal from "../../common/SkeletonViewModal";
import SortIcon from "../../common/SortIcon";
import TooltipWrapper from "../../common/TooltipWrapper";
import {
  extractFilename,
  getFirstLetterCapital,
  renderFileIcon,
} from "../../helper/common-function";





const Contacts = () => {
  const [search, setSearch] = useState("");
  const [exportAll, setExportAll] = useState(false);
  const [contactData, setContactData] = useState(false);
  const [viewContactModalOpen, setViewContactModalOpen] = useState(false);
  const [contactId, setContactId] = useState("");
  const [deleteContactModalOpen, setDeleteContactModalOpen] = useState(false);
  const [lastSearch, setLastSearch] = useState('');
  const [pageSize, setPageSize] = useState(20);
  const [open, setOpen] = useState(false)
  const offsetRef = useRef(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    getAllContactsLoader,
    allContactsData,
    getContactsLoader,
    contactsData,
    contactsDataCount,
    getContactDetailLoader,
    contactDetailData,
    deleteContactLoader,
  } = useSelector((state) => state.contact);
  const { downloadFileLoader } = useSelector((state) => state.common);

  useEffect(() => {
    dispatch(
      actionGetContacts({
        offset: offsetRef.current,
        limit: pageSize,
      })
    );
  }, [dispatch]);

  const contactsDataColumn = [
    {
      title: "CH No.",
      key: "ch_no",
      width: 100,
      dataIndex: "contact_id",
      fixed: "left",
      render: (text) =>
        <div
          onClick={() => {
            dispatch(
              handleActionWithPermission("contact", "read", () => {
                setContactId(text);
                setViewContactModalOpen(true);
              })
            );
          }}
          className="flex text-brand custom-ellipsis-wrapper items-center cursor-pointer"
        >
          {text ?? "-"}
        </div>,
    },
    {
      title: "Name",
      key: "first_name",
      width: 250,
      ellipsis: true,
      dataIndex: "first_name",
      sorter: true,
      sortIcon: () => <SortIcon />,
      fixed: "left",
      render: (text, data) => (
        <div
          className="flex custom-ellipsis-wrapper items-center cursor-pointer"
          onClick={() => {
            dispatch(
              handleActionWithPermission("contact", "read", () => {
                setContactId(data.contact_id);
                setViewContactModalOpen(true);
              })
            );
          }}
        >
          <Avatar size={25}
            style={{
              verticalAlign: "middle",
              flexShrink: 0,
            }}
            src={data?.profile_pic}
            height={25}
            width={25} className="rounded-full"
          />
          <Typography.Paragraph

            style={{
              flexShrink: 1, // Allow the text to shrink if needed
              overflow: "hidden", // Prevent text from overflowing the container
              whiteSpace: "nowrap", // Ensure text does not wrap
              textOverflow: "ellipsis", // Show ellipsis when text overflows
              margin: 0, // Remove margin for better alignment
            }}
            className="ml-2 text-brand">
            {`
            ${getFirstLetterCapital(data?.first_name)} 
            ${getFirstLetterCapital(data?.last_name)}
          `}
          </Typography.Paragraph>
        </div>
      ),
    },

    {
      title: "Email",
      key: "email",
      width: 200,
      dataIndex: "email",
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Secondary Email",
      key: "secondary_email",
      ellipsis: true,
      width: 200,
      fixed: true,
      dataIndex: "secondary_email",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Phone",
      key: "phone",
      width: 200,
      dataIndex: "phone",
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Secondary Phone",
      key: "secondary_phone",
      width: 200,
      ellipsis: true,
      dataIndex: "secondary_phone",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Alternative Phone",
      key: "alternative_phone",
      width: 200,
      ellipsis: true,
      dataIndex: "alternative_phone",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Assistant",
      key: "assistant",
      ellipsis: true,
      width: 200,
      dataIndex: "assistant",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Assistant Phone",
      key: "asst_phone",
      ellipsis: true,
      width: 200,
      dataIndex: "asst_phone",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Company Name",
      key: "company_name",
      width: 200,
      ellipsis: true,
      dataIndex: "company_name",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Job Title / Designation",
      key: "job_title",
      ellipsis: true,
      width: 200,
      dataIndex: "job_title",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Website",
      key: "website",
      ellipsis: true,
      width: 200,
      sorter: true,
      sortIcon: () => <SortIcon />,
      dataIndex: "website",
      render: (text) => (
        <a href={text} target="_blank" className="ml-2" rel="noreferrer">
          {text || "-"}
        </a>
      ),
    },
    {
      title: "Contact Owner",
      key: "contact_owner",
      width: 200,
      ellipsis: true,
      dataIndex: "contact_owner",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">{text.full_name ? text.full_name : "-"}</span>
      ),
    },
    {
      title: "Business Partner",
      key: "business_partner",
      width: 200,
      ellipsis: true,
      dataIndex: "business_partner",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">{text?.business_partner_name || "-"}</span>
      ),
    },
    {
      title: "Lead Source",
      key: "lead_source",
      ellipsis: true,
      // width: "10%",
      width: 200,
      dataIndex: "lead_source",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">{text?.lead_source || "-"}</span>
      ),
    },
    {
      title: "Department",
      key: "department",
      ellipsis: true,
      // width: "10%",
      width: 200,
      dataIndex: "department",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">{text?.department_name || "-"}</span>
      ),
    },
    {
      title: "Fax",
      key: "fax",
      ellipsis: true,
      // width: "10%",
      width: 200,
      dataIndex: "fax",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Date of Birth",
      key: "date_of_birth",
      ellipsis: true,
      // width: "10%",
      width: 170,
      dataIndex: "date_of_birth",
      render: (text) => (
        <span className="ml-2">
          {text ? dayjs(text).format("DD-MM-YYYY") : "-"}
        </span>
      ),
    },
    {
      title: "Skype ID",
      key: "skype_id",
      ellipsis: true,
      // width: "10%",
      width: 200,
      dataIndex: "skype_id",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Twitter",
      key: "twitter",
      ellipsis: true,
      // width: "10%",
      width: 200,
      dataIndex: "twitter",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Address",
      key: "address",
      ellipsis: true,
      width: 300,
      render: (record) => {
        const { street, city, state, zip_code, country } = record;

        const addressParts = [];

        // Add parts to the address array if they exist
        if (street) addressParts.push(street);
        if (city) addressParts.push(city);
        if (state?.name) addressParts.push(state.name);

        // Add zip code with or without hyphen based on the number of address parts
        if (zip_code) {
          if (addressParts.length > 0) {
            addressParts[addressParts.length - 1] += ` - ${zip_code}`;
          } else {
            addressParts.push(zip_code);
          }
        }

        if (country?.name) addressParts.push(country.name);

        // Join the parts with a comma and space
        const address = addressParts.filter(Boolean).join(", ");

        return (
          <Typography.Paragraph
            ellipsis={{ rows: 1, tooltip: false }}
            style={{ width: 300 }}
            className="ml-2"
          >
            {address || "-"}
          </Typography.Paragraph>
        );
      },
    },

    {
      title: "Description",
      key: "description",
      ellipsis: true,
      // width: "10%",
      width: 200,
      sorter: true,
      sortIcon: () => <SortIcon />,
      dataIndex: "description",
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          style={{ width: 200 }}
          className="ml-2 !mb-0"
        >
          {text || "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Remark",
      key: "remark",
      ellipsis: true,
      width: 200,
      fixed: true,
      dataIndex: "remark",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          style={{ width: 200 }}
          className="ml-2 !mb-0"
        >
          {text || "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Inquiry For",
      key: "product_category",
      ellipsis: true,
      // width: "10%",
      width: 200,
      dataIndex: "product_category",
      render: (categories) => {
        if (Array.isArray(categories)) {
          const categoryNames = categories.map(
            (cat) => cat.product_category.category_name
          );
          return (
            <Typography.Paragraph
              ellipsis={{ rows: 1, tooltip: false }}
              style={{ width: 200 }}
              className="ml-2 !mb-0"
            >
              {categoryNames.join(", ") || "-"}
            </Typography.Paragraph>
          );
        }
      },
    },
    {
      title: "Created By",
      key: "created_by",
      dataIndex: "created_by",
      ellipsis: true,
      width: 200,
      fixed: "right",
      // sortIcon: () => <SortIcon />,
      // sorter: true,
      render: (text, data) => {
        return (
          <div className="flex items-center cursor-pointer">
            <Avatar
              style={{
                verticalAlign: "middle",
              }}
              src={text.profile_pic}
              size="30"
            />
            <p className="ml-2 text-brand">
              {getFirstLetterCapital(text?.full_name)}
            </p>
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 100,
      align: "center",
      fixed: "right",
      render: (text, data) => (
        <>
          <Dropdown
            id="custom-drop"
            menu={{
              items: [
                {
                  label: <span>Edit</span>,
                  key: "Edit",
                  icon: <EditOutlined />,
                  onClick: () => {
                    dispatch(
                      handleActionWithPermission("contact", "update", () =>
                        navigate("/update-contact", {
                          state: { contactId: data.contact_id },
                        })
                      )
                    );
                  },
                },
                {
                  label: <span style={{ color: "#ff4d4f" }}>Delete</span>,
                  key: "Delete",
                  icon: (
                    <DeleteOutlined
                      style={{ fontSize: 12, color: "#ff4d4f" }}
                    />
                  ),
                  onClick: () =>
                    dispatch(
                      handleActionWithPermission("contact", "delete", () => {
                        setDeleteContactModalOpen(true);
                        setContactId(data.contact_id);
                      })
                    ),
                },
              ].filter(Boolean),
            }}
          >
            <div className="flex justify-center items-center">
              <EllipsisOutlined />
            </div>
          </Dropdown>
        </>
      ),
    },
  ];

  const handleRefresh = () => {
    dispatch(
      actionGetContacts({
        offset: offsetRef.current,
        limit: pageSize,
        search: search
      })
    );
  };

  useEffect(() => {
    const trimmedSearch = search?.trim();
    if (trimmedSearch !== "" && trimmedSearch !== lastSearch) {
      const timer = setTimeout(() => {
        dispatch(
          actionGetContacts({ offset: offsetRef.current, limit: pageSize, search: trimmedSearch })
        );
        setLastSearch(trimmedSearch);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }

  }, [search, lastSearch]);

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      dispatch(
        actionGetContacts({
          offset: offsetRef.current,
          limit: pageSize,
          search: e.target.value,
        })
      );
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      dispatch(
        actionGetContacts({
          offset: offsetRef.current,
          limit: pageSize,
          search: e.target.value,
        })
      );
    }
  };

  const exportAllToExcel = async () => {
    const data =
      (await allContactsData) &&
      allContactsData.length > 0 &&
      allContactsData.map((data) => ({
        "First Name": data.first_name ? data.first_name : "-",
        "Last Name": data.last_name ? data.last_name : "-",
        Email: data.email ? data.email : "-",
        "Secondary Email": data.secondary_email ? data.secondary_email : "-",
        Phone: data.phone ? data.phone : "-",
        "Secondary Phone": data.secondary_phone ? data.secondary_phone : "-",
        "Alternative Phone": data.alternative_phone
          ? data.alternative_phone
          : "-",
        Assistant: data.assistant ? data.assistant : "-",
        "Assistant Phone": data.asst_phone ? data.asst_phone : "-",
        "Company Name": data.company_name ? data.company_name : "-",
        "Job Title/Designation": data.job_title ? data.job_title : "-",
        Website: data.website ? data.website : "-",
        "Contact Owner":
          data.contact_owner && Object.keys(data.contact_owner).length > 0
            ? data.contact_owner.full_name
            : "-",
        "Business Partner": data.business_partner
          ? data.business_partner.business_partner_name
          : "-",
        "Lead Source":
          data.lead_source && Object.keys(data.lead_source).length > 0
            ? data.lead_source.lead_source
            : "-",
        Department:
          data.department && Object.keys(data.department).length > 0
            ? data.department.department_name
            : "-",
        Fax: data.fax ? data.fax : "-",
        "Date of Birth": data.date_of_birth
          ? dayjs(data.date_of_birth).format("D MMM YYYY")
          : "-",
        "Skype ID": data.skype_id ? data.skype_id : "-",
        Twitter: data.twitter ? data.twitter : "-",
        // "Reporting To":
        //   data.reporting_to && Object.keys(data.reporting_to).length > 0
        //     ? data.reporting_to.full_name
        //     : "-",
        Street: data.street ? data.street : "-",
        City: data.city ? data.city : "-",
        State:
          data.state && Object.keys(data.state).length > 0
            ? data.state.name
            : "-",
        Zip: data.zip_code ? data.zip_code : "-",
        Country:
          data.country && Object.keys(data.country).length > 0
            ? data.country.name
            : "-",
        Description: data.description ? data.description : "-",
        Remark: data.remark ? data.remark : "-",
        "Inquiry For":
          Array.isArray(data.product_category) &&
            data.product_category.length > 0
            ? data.product_category
              .map((item) => item.product_category.category_name)
              .join(", ")
            : "-",

        "Created By": data.created_by ? data.created_by?.full_name : "-",
      }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Contacts");
    // Generate the Excel file and initiate the download
    XLSX.writeFile(workbook, "contacts.xlsx");
    dispatch(actionClearAllContacts({ setExportAll }));
  };

  useEffect(() => {
    if (exportAll && allContactsData && allContactsData.length > 0) {
      exportAllToExcel();
    }
  }, [allContactsData]);

  const onClickDownloadMenu = () => {
    dispatch(actionGetAllContacts());
    setExportAll(true);
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
            ? "ASC"
            : "",
    };

    dispatch(
      actionGetContacts({
        offset: offsetRef.current,
        limit: pageSize,
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
  };

  useEffect(() => {
    if (contactId && viewContactModalOpen) {
      dispatch(actionGetContactDetail(contactId));
    }
  }, [viewContactModalOpen, contactId]);

  const handleCancelViewModal = () => {
    setViewContactModalOpen(false);
    setContactId("");
  };

  const handleCloseDeleteModal = () => {
    setContactId("");
    setDeleteContactModalOpen(false);
  };

  //download button of all business card
  const handleDownloadSelectedFiles = () => {
    contactDetailData?.business_card &&
      contactDetailData?.business_card.length > 0 &&
      contactDetailData?.business_card.forEach((file) => {
        dispatch(actionDownloadFile(file));
      });
  };

  const handlePaginationChange = (page, pageSize) => {
    offsetRef.current = page - 1;
    setPageSize(pageSize);
    dispatch(
      actionGetContacts({ offset: offsetRef.current, limit: pageSize, search: search })
    );
  }

  return (
    <>
      <section className="main-wrapper main-table-container ">
        <CommonTable
          moduleKey="contact"
          title="Contacts"
          total={contactsDataCount}
          pageSize={pageSize}
          isAdvanceFilter={true}
          onClickFilter={() => setOpen(true)}
          pageTitle={<PageTitle
            margin={false}
            image={contact}
            title="Contacts"
            rightIcon={false}
          />}
          buttonText="Create Contact"
          buttonLink="/create-contact"
          resource="contact"
          loading={getContactsLoader || getAllContactsLoader}
          columns={contactsDataColumn}
          scrollToFirstRowOnChange={true}
          data={(contactsData || [])}
          rowKey={"contact_id"}
          onChange={handleSortChange}
          handleRefresh={handleRefresh}
          handleSearch={handleSearch}
          handleBlurAndPressEnter={handleBlurAndPressEnter}
          search={search}
          scroll={{ y: false }}
          onClickDownloadMenu={onClickDownloadMenu}
          handlePaginationChange={handlePaginationChange}
        />

        <Modal
          centered={true}
          footer={false}
          handleReset={() => {
            setViewContactModalOpen(false);
            handleCancelViewModal();
          }}
          width={600}
          open={viewContactModalOpen}
          title=""
          onCancel={() => {
            setViewContactModalOpen(false);
            setContactData(false);
            handleCancelViewModal();
          }}
          onOk={() => {
            setViewContactModalOpen(false);
            setContactData(false);
            handleCancelViewModal();
          }}
        >
          {getContactDetailLoader ? (
            <SkeletonViewModal length={23} />
          ) : (
            contactDetailData && (
              <div>
                <div className="text-brand flex items-center gap-x-3">
                  <Image
                    src={contact}
                    alt="image"
                    height={20}
                    width={20}
                    preview={false}
                  />
                  <h1 className="lg:text-lg text-base font-popinsRegular">
                    View Contact Information
                  </h1>
                </div>
                <div className="flex flex-col gap-y-4">
                  <div className="flex items-center pt-5 gap-x-4">
                    {
                      <div className="flex items-center pt-5 gap-x-4">
                        <Image
                          preview={true}
                          height={100}
                          width={100}
                          className="rounded-[10px]"
                          src={contactDetailData.profile_pic}
                          alt="Profile Pic"
                        />
                        <div className="flex justify-start flex-col gap-y-1">
                          <p className="text-sm font-popinsMedium">
                            {`${getFirstLetterCapital(
                              contactDetailData.first_name
                            )} ${getFirstLetterCapital(
                              contactDetailData.last_name
                            )}`}
                          </p>
                          <p className="text-sm text-[#818181]">
                            {contactDetailData.email}
                          </p>
                        </div>
                      </div>
                    }
                  </div>

                  <>
                    <div className="flex items-start gap-y-1">
                      <p className="text-xs text-[#818181] w-[40%]">
                        Secondary Email:
                      </p>
                      <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                        {contactDetailData?.secondary_email || "-"}
                      </p>
                    </div>
                    <div className="flex items-start gap-y-1">
                      <p className="text-xs text-[#818181] w-[40%]">Phone:</p>
                      <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                        {contactDetailData?.phone || "-"}
                      </p>
                    </div>
                    <div className="flex items-start gap-y-1">
                      <p className="text-xs text-[#818181] w-[40%]">
                        Secondary Phone:
                      </p>
                      <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                        {contactDetailData?.secondary_phone || "-"}
                      </p>
                    </div>
                    <div className="flex items-start gap-y-1">
                      <p className="text-xs text-[#818181] w-[40%]">
                        Alternative Phone:
                      </p>
                      <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                        {contactDetailData?.alternative_phone || "-"}
                      </p>
                    </div>
                    <div className="flex items-start gap-y-1">
                      <p className="text-xs text-[#818181] w-[40%]">
                        Assistant:
                      </p>
                      <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                        {contactDetailData?.assistant || "-"}
                      </p>
                    </div>
                    <div className="flex items-start gap-y-1">
                      <p className="text-xs text-[#818181] w-[40%]">
                        Assistant Phone:
                      </p>
                      <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                        {contactDetailData?.asst_phone || "-"}
                      </p>
                    </div>
                    <div className="flex items-start gap-y-1">
                      <p className="text-xs text-[#818181] w-[40%]">
                        Company Name:
                      </p>
                      <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                        {contactDetailData?.company_name || "-"}
                      </p>
                    </div>
                    <div className="flex items-start gap-y-1">
                      <p className="text-xs text-[#818181] w-[40%]">
                        Job Title / Designation:
                      </p>
                      <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                        {contactDetailData?.job_title || "-"}
                      </p>
                    </div>
                    <div className="flex items-start gap-y-1">
                      <p className="text-xs text-[#818181] w-[40%]">Website:</p>
                      <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                        {contactDetailData?.website ? (
                          <a
                            href={contactDetailData.website}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {contactDetailData.website}
                          </a>
                        ) : (
                          "-"
                        )}
                      </p>
                    </div>
                    <div className="flex items-start gap-y-1">
                      <p className="text-xs text-[#818181] w-[40%]">
                        Contact Owner:
                      </p>
                      <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                        {contactDetailData?.contact_owner.full_name || "-"}
                      </p>
                    </div>
                    <div className="flex items-start gap-y-1">
                      <p className="text-xs text-[#818181] w-[40%]">
                        Business Partner:
                      </p>
                      <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                        {contactDetailData?.business_partner
                          ?.business_partner_name || "-"}
                      </p>
                    </div>
                    <div className="flex items-start gap-y-1">
                      <p className="text-xs text-[#818181] w-[40%]">
                        Lead Source:
                      </p>
                      <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                        {contactDetailData?.lead_source?.lead_source || "-"}
                      </p>
                    </div>
                    <div className="flex items-start gap-y-1">
                      <p className="text-xs text-[#818181] w-[40%]">
                        Department:
                      </p>
                      <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                        {contactDetailData?.department?.department_name || "-"}
                      </p>
                    </div>
                    <div className="flex items-start gap-y-1">
                      <p className="text-xs text-[#818181] w-[40%]">Fax:</p>
                      <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                        {contactDetailData?.fax || "-"}
                      </p>
                    </div>
                    <div className="flex items-start gap-y-1">
                      <p className="text-xs text-[#818181] w-[40%]">
                        Date of Birth:
                      </p>
                      <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                        {contactDetailData?.date_of_birth
                          ? dayjs(contactDetailData.date_of_birth).format(
                            "DD-MM-YYYY"
                          )
                          : "-"}
                      </p>
                    </div>
                    <div className="flex items-start gap-y-1">
                      <p className="text-xs text-[#818181] w-[40%]">
                        Skype ID:
                      </p>
                      <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                        {contactDetailData?.skype_id || "-"}
                      </p>
                    </div>
                    <div className="flex items-start gap-y-1">
                      <p className="text-xs text-[#818181] w-[40%]">Twitter:</p>
                      <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                        {contactDetailData?.twitter || "-"}
                      </p>
                    </div>
                    <div className="flex items-start gap-y-1">
                      <p className="text-xs text-[#818181] w-[40%]">Address:</p>
                      <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                        {(() => {
                          const { street, city, state, zip_code, country } =
                            contactDetailData || {};

                          // Initialize an empty array to hold the address parts
                          const addressParts = [];

                          // Add parts to the address array if they exist
                          if (street) addressParts.push(street);
                          if (city) addressParts.push(city);
                          if (state?.name) addressParts.push(state.name);

                          // Add zip code with or without hyphen based on the number of address parts
                          if (zip_code) {
                            if (addressParts.length > 0) {
                              addressParts[
                                addressParts.length - 1
                              ] += ` - ${zip_code}`;
                            } else {
                              addressParts.push(zip_code);
                            }
                          }

                          if (country?.name) addressParts.push(country.name);

                          // Join the parts with a comma and space
                          const address = addressParts
                            .filter(Boolean)
                            .join(", ");

                          return address || "-";
                        })()}
                      </p>
                    </div>
                    <div className="flex items-start gap-y-1">
                      <p className="text-xs text-[#818181] w-[40%]">
                        Description:
                      </p>
                      <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                        {contactDetailData?.description || "-"}
                      </p>
                    </div>
                    <div className="flex items-start gap-y-1">
                      <p className="text-xs text-[#818181] w-[40%]">Remark:</p>
                      <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                        {contactDetailData?.remark || "-"}
                      </p>
                    </div>
                    <div className="flex items-start gap-y-1">
                      <p className="text-xs text-[#818181] w-[40%]">
                        Inquiry For:
                      </p>
                      <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                        {Array.isArray(contactDetailData?.product_category) &&
                          contactDetailData.product_category.length > 0
                          ? contactDetailData.product_category
                            .map(
                              (item) => item.product_category.category_name
                            )
                            .join(", ")
                          : "-"}
                      </p>
                    </div>{" "}
                    <div className="flex items-start gap-y-1">
                      <p className="text-xs text-[#818181] w-[40%]">
                        Created By:
                      </p>
                      <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                        {contactDetailData?.created_by?.full_name || "-"}
                      </p>
                    </div>
                    {contactDetailData &&
                      contactDetailData?.business_card &&
                      contactDetailData?.business_card?.length > 0 ? (
                      <List
                        size="large"
                        header={
                          <div className="flex items-center justify-between">
                            <h2 className="text-primary">Business Cards</h2>
                            {contactDetailData?.business_card &&
                              contactDetailData?.business_card.length > 1 && (
                                <TooltipWrapper title={"click to download all"}>
                                  <Button
                                    loading={
                                      downloadFileLoader[
                                      contactDetailData?.business_card.map(
                                        (card) => card
                                      )
                                      ]
                                    }
                                    type="primary"
                                    onClick={handleDownloadSelectedFiles}
                                    className=" text-white download-btn p-2 text-xs rounded"
                                  >
                                    Download All
                                  </Button>
                                </TooltipWrapper>
                              )}
                          </div>
                        }
                        bordered
                        dataSource={contactDetailData?.business_card}
                        renderItem={(item, index) => {
                          return (
                            <List.Item>
                              <List.Item.Meta
                                avatar={renderFileIcon(item)}
                                title={
                                  <div className="flex items-center">
                                    <Typography.Paragraph
                                      ellipsis={{ rows: 1 }}
                                      className="w-[90%] text"
                                      style={{ marginBottom: 0 }}
                                    >
                                      {extractFilename(item)}
                                    </Typography.Paragraph>
                                    <span className="w-[5%] ml-4">
                                      <Image
                                        preview={false}
                                        onClick={() =>
                                          dispatch(actionDownloadFile(item))
                                        }
                                        className="cursor-pointer"
                                        src={downloadIcon}
                                        alt="download"
                                        height={18}
                                        width={18}
                                      />
                                    </span>
                                  </div>
                                }
                              />
                            </List.Item>
                          );
                        }}
                      />
                    ) : (
                      <div className="flex items-start gap-y-1">
                        <p className="text-xs text-[#818181] w-[40%]">
                          Business Cards:
                        </p>
                        <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                          No business cards added
                        </p>
                      </div>
                    )}
                  </>

                </div>
              </div>
            )
          )}
        </Modal>
        <Modal
          className="max-h-[100vh]"
          centered={true}
          footer={false}
          handleReset={handleCloseDeleteModal}
          afterClose={handleCloseDeleteModal}
          open={deleteContactModalOpen}
          title=""
          onCancel={handleCloseDeleteModal}
          onOk={handleCloseDeleteModal}
        >
          <p className="text-[19px] text-black font-popinsMedium mt-2">
            Are you sure you want to remove this Contact ?
          </p>
          <div className=" flex justify-end mt-[30px]">
            <Button
              onClick={handleCloseDeleteModal}
              type="button"
              className="gray-button text-white hover:text-white min-h-[45px] min-w-[110px]"
            >
              Cancel
            </Button>

            <div className="ml-4">
              <Button
                loading={deleteContactLoader}
                onClick={() => {
                  dispatch(
                    actionDeleteContact({
                      id: contactId,
                      handleCloseDeleteModal,
                    })
                  );
                }}
                className="delete-btn min-h-[45px] min-w-[110px]"
              >
                Delete
              </Button>
            </div>
          </div>
        </Modal>

        <Drawer className="filter-drawer"
          closable
          destroyOnClose
          title={<p className="tracking-wide ">Fields Filters</p>}
          placement="right"
          open={open}
          loading={false}
          onClose={() => setOpen(false)}
        >
          <AdvancedFilter module="contact" onApplyFilter={(values) => console.log(values, "values")} />
        </Drawer>

      </section>
    </>
  );
};

export default Contacts;
