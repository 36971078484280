import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config/web-config";
import { toast } from "sonner";
import {
    formatDate,
    handleErrorMessage,
} from "../../components/helper/common-function";
import { handleGetResponse } from "./commonService";

// create reminder
export const actionCreateReminder = createAsyncThunk(
    "actionCreateReminder",
    async ({ req, handleCloseCreateModal }, { rejectWithValue }) => {
        try {
            const res = await axios.post(`${BASE_URL}/reminder`, req);
            const { status, message: customMsg, data } = res.data;
            if (parseInt(status) === 200) {
                toast.success(customMsg);
                handleCloseCreateModal && handleCloseCreateModal();
                return data;
            } else {
                toast.error(customMsg);
                return rejectWithValue(customMsg);
            }
        } catch (error) {
            handleErrorMessage(error);
        }
    }
);

// get reminders as per pagination
export const actionGetReminders = createAsyncThunk(
    "actionGetReminders",
    async (
        { offset = 0, limit, search = "", orderByColumn = "", order = "" } = {},
        { rejectWithValue }
    ) => {
        try {
            const res = await axios.get(
                `${BASE_URL}/reminder?limit=${limit}&offset=${offset}${search ? `&search=${search}` : ""
                }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${order ? `&order=${order}` : ""
                }`
            );
            const { status, message: customMsg, data, total_records } = res.data;
            if (parseInt(status) === 200) {
                return { data, total_records };
            } else if (parseInt(status) === 404) {
                return "";
            } else {
                toast.error(customMsg, 5);
                throw new Error(`API Error: ${customMsg}`);
            }
        } catch (error) {
            handleErrorMessage(error);
        }
    }
);

// get all reminders for excel
export const actionGetAllReminders = createAsyncThunk(
    "actionGetAllReminders",
    async () => {
        return handleGetResponse(`reminder?getall=YES`);
    }
);

// clear all reminders
export const actionClearAllReminders = createAsyncThunk(
    "actionClearAllReminders",
    async ({ setExportAll }) => {
        setExportAll && setExportAll(false);
        return "";
    }
);

//delete feedback
export const actionDeleteReminder = createAsyncThunk(
    "actionDeleteReminder",
    async (
        { reminderId, handleCloseDeleteModal },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.delete(
                `${BASE_URL}/reminder/${reminderId}`
            );
            const { status, message: customMsg } = response.data;
            if (parseInt(status) === 200) {
                toast.success(customMsg, 5);
                handleCloseDeleteModal && handleCloseDeleteModal();
                return reminderId;
            } else {
                toast.error(customMsg, 5);
                return rejectWithValue(customMsg);
            }
        } catch (error) {
            handleErrorMessage(error);
        }
    }
);

// get single reminder
export const actionGetSingleReminderDetail = createAsyncThunk(
    "actionGetSingleReminderDetail",
    async (id) => {
      return handleGetResponse(`reminder/${id}`);
    }
  );

// update reminder
export const actionUpdateReminder = createAsyncThunk(
    "actionUpdateReminder",
    async ({ id, req, handleCloseCreateModal }, { dispatch, rejectWithValue }) => {
      try {
        const res = await axios.patch(`${BASE_URL}/reminder/${id}`, req);
        const { status, message: customMsg, data } = res.data;
        if (parseInt(status) === 200) {
          toast.success(customMsg);
          handleCloseCreateModal && handleCloseCreateModal();
  
          dispatch(actionGetReminders());
          return data;
        } else {
          toast.error(customMsg);
          return rejectWithValue(customMsg);
        }
      } catch (error) {
        handleErrorMessage(error);
      }
    }
  );