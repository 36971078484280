import { Button, Card, Col, Form, Input, Row, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import CustomIconText from "../../common/CustomIconText";
import requestForQuotation from "../../../assets/img/request-for-quotation.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { actionGetProductCategoryMaster } from "../../../store/services/productMasterService";
import {
  actionAddRequestForQuotation,
  actionUpdateRequestForQuotation,
  actionGetSingleRequestForQuotation,
} from "../../../store/services/requestForQuotationService";
import { useDispatch, useSelector } from "react-redux";
import SkeletonUpdateCard from "../../common/SkeletonUpdateCard";
import { sanitizeName } from "../../helper/common-function";

const CreateRequstForQuotation = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { productCategoryData } = useSelector((state) => state.productMaster);
  const {
    addRequestForQuotationLoader,
    getSingleRequestForQuotationData,
    getSingleRequestForQuotationLoader,
    updateRequestForQuotationLoader,
  } = useSelector((state) => state.requestForQuotation);
  const { requestForQuotationId } = location.state || {};

  useEffect(() => {
    if (requestForQuotationId) {
      dispatch(
        actionGetSingleRequestForQuotation({ id: requestForQuotationId })
      );
    }
  }, [requestForQuotationId, dispatch]);

  useEffect(() => {
    if (getSingleRequestForQuotationData && requestForQuotationId) {
      const {
        category,
        grade,
        thickness,
        custom_thickness,
        custom_made_size,
        sku,
        status,
        remark,
      } = getSingleRequestForQuotationData;

      form.setFieldsValue({
        product_category_id: category?.product_category_master_id || null,
        grade: grade || null,
        thickness: thickness || null,
        custom_thickness: custom_thickness || null,
        custom_made_size: custom_made_size || null,
        sku: sku || null,
        status: status || null,
        remark: remark || null,
      });
    }
  }, [requestForQuotationId, getSingleRequestForQuotationData, form]);

  const onFinish = (values) => {
    requestForQuotationId
      ? dispatch(
        actionUpdateRequestForQuotation({
          id: requestForQuotationId,
          req: values,
          navigate,
        })
      )
      : dispatch(
        actionAddRequestForQuotation({
          req: values,
          navigate,
        })
      );
  };

  const handleCancel = () => {
    navigate("/request-for-quotation");
  };

  useEffect(() => {
    dispatch(actionGetProductCategoryMaster());
  }, [dispatch]);

  const cateData =
    productCategoryData &&
    productCategoryData.length > 0 &&
    productCategoryData
      // .filter((cate) => cate.status === "Active")
      .map((data) => ({
        label: data.category_name,
        value: data.product_category_master_id,
      }));

  return (
    <section className="main-wrapper">
      <CustomIconText
        image={requestForQuotation}
        title={
          requestForQuotationId
            ? "Update Request For Quotation"
            : "Create Request For Quotation"
        }
        backArrow={true}
      />
      <Card className="main-card mt-5 w-full">
        <Form
          scrollToFirstError={true}
          form={form}
          layout="vertical"
          initialValues={{
            status: "DRAFT",
          }}
          name="basic"
          onFinish={onFinish}
          autoComplete="off"
        >
          {getSingleRequestForQuotationLoader ? (
            <SkeletonUpdateCard length={4} />
          ) : (
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  className="mb-[12px]"
                  label="Category"
                  name="product_category_id"
                  size="small"
                  rules={[
                    {
                      required: true,
                      message: "Please select category!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    options={cateData}
                    placeholder="Select Category"
                    className="begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>
              <Col span={12} align="middle">
                <Form.Item
                  className="mb-[12px]"
                  label="Grade"
                  name="grade"
                  normalize={(value = "") => {
                    return value.replace(/^\s+/, "").replace(/\s+/g, " ");
                  }}
                  size="small"
                  rules={[
                    {
                      required: false,
                      message: "Please Enter grade!",
                    },
                  ]}
                >
                  <Input
                    style={{ background: "white" }}
                    placeholder="Enter Grade"
                    className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>
              <Col span={12} align="middle">
                <Form.Item
                  className="mb-[12px] text-left"
                  label="Thickness"
                  name="thickness"
                  size="small"
                  normalize={(value = "") => value.replace(/^\s+/, "")}>
                  <Input
                    style={{ background: "white" }}
                    placeholder="Enter Thickness"
                    className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>
              <Col span={12} align="middle">
                <Form.Item
                  className="mb-[12px] text-left"
                  label="Size If (Custom Made)"
                  name="custom_made_size"
                  size="small"
                  normalize={(value = "") => value.replace(/^\s+/, "")}
                >
                  <Input
                    style={{ background: "white" }}
                    placeholder="Enter Size If Custom-made"
                    className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>
              <Col span={12} align="middle">
                <Form.Item
                  className="mb-[12px] text-left"
                  label="SKU"
                  name="sku"
                  size="small"
                  normalize={(value = "") => value.replace(/^\s+/, "")}>
                  <Input
                    style={{ background: "white" }}
                    placeholder="Enter SKU"
                    className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>
              <Col span={12} align="middle">
                <Form.Item
                  className="mb-[12px] text-left"
                  label="Custom Thickness"
                  name="custom_thickness"
                  size="small"
                  normalize={(value = "") => value.replace(/^\s+/, "")}
                >
                  <Input
                    style={{ background: "white" }}
                    placeholder="Enter Custom Thickness"
                    className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>

              <Col span={12} align="middle">
                <Form.Item
                  className="mb-[12px]"
                  label="Remark"
                  name="remark"
                  size="small"
                  normalize={sanitizeName}
                >
                  <Input
                    style={{ background: "white" }}
                    placeholder="Enter Remark"
                    className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>
              <Col
                span={24}
                align="middle"
                className="flex justify-center mt-[40px]"
              >
                <Form.Item className="mb-0">
                  <Button
                    onClick={handleCancel}
                    type="button"
                    className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
                  >
                    Cancel
                  </Button>
                </Form.Item>

                <div className="ml-4">
                  <Form.Item className="mb-0">
                    <Button
                      loading={
                        addRequestForQuotationLoader ||
                        updateRequestForQuotationLoader
                      }
                      type="primary"
                      className="root-btn min-h-[45px] min-w-[120px]"
                      htmlType="submit"
                    >
                      {requestForQuotationId ? "Save" : "Submit"}
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          )}
        </Form>
      </Card>
    </section>
  );
};

export default CreateRequstForQuotation;
