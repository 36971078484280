import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleDeleteResponse, handleGetResponse } from "./commonService";
import axios from "axios";
import { BASE_URL } from "../../config/web-config";
import { toast } from "sonner";
import { handleErrorMessage } from "../../components/helper/common-function";

export const actionGetTasks = createAsyncThunk(
  "actionGetTasks",
  ({
    offset,
    limit,
    getall,
    orderByColumn,
    order,
    search,
    task_assignee_user,
    created_by,
  }) => {
    const createdByParam =
      created_by && created_by.length > 0
        ? created_by.map((id) => `&created_by=${id}`).join("")
        : "";

    const taskAssigneeUserParam =
      task_assignee_user && task_assignee_user.length > 0
        ? task_assignee_user.map((id) => `&task_assignee_user=${id}`).join("")
        : "";

    return handleGetResponse(
      `task?limit=${limit}&offset=${offset}${getall ? "&getall=" + getall : ""}${
        orderByColumn ? "&orderByColumn=" + orderByColumn : ""
      }${order ? "&order=" + order : ""}${
        search ? "&search=" + search : ""
      }${createdByParam}${taskAssigneeUserParam}`
    );
  }
);

export const actionGetAllTasks = createAsyncThunk("actionGetAllTasks", () => {
  return handleGetResponse(`task?getall=YES`);
});

export const actionClearAllTasks = createAsyncThunk(
  "actionClearAllTasks",
  async ({ setExportAll }) => {
    setExportAll && setExportAll(false);
    return "";
  }
);

export const actionDeleteTasks = createAsyncThunk(
  "actionDeleteTasks",
  async (id) => {
    return handleDeleteResponse(`task/${id}`, id);
  }
);

export const actionGetTaskDetail = createAsyncThunk(
  "actionGetTaskDetail",
  (id) => {
    return handleGetResponse(`task/${id}`);
  }
);

export const actionAddTask = createAsyncThunk(
  "actionAddTask",
  async ({ req, setIsModalOpen }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/task`, req);
      const { data, message: customMsg, status } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg);
        setIsModalOpen && setIsModalOpen(false);
        return data;
      } else {
        toast.error(customMsg);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionUpdateTask = createAsyncThunk(
  "actionUpdateTask",
  async ({ req, setUpdateId, id, setIsModalOpen }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`${BASE_URL}/task/${id}`, req);
      const { data, message: customMsg, status } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg);
        setUpdateId && setUpdateId("");
        setIsModalOpen && setIsModalOpen(false);
        return data;
      } else {
        toast.error(customMsg);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);
