import { LoadingOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Col, Form, Input, Row, Select } from "antd";
import axios from "axios";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import addUser from "../../assets/img/user-add.svg";
import { BASE_URL } from "../../config/web-config";
import {
  actionGetAllRoleProfile,
  actionGetRoles,
} from "../../store/services/settingService";
import {
  actionAddUser,
  actionClearUsersAllData,
  actionGetCompanyAllUsers,
} from "../../store/services/usersService";
import CustomIconText from "../common/CustomIconText";
import { getFullName } from "../helper/common-function";

const CreateUser = ({
  isHierchy = false,
  isModalOpen = false,
  setIsModalOpen = () => { },
  reportsTo = null,
}) => {
  const [form] = Form.useForm();

  const [isSaveAndAddNew, setIsSaveAndAddNew] = useState(false);
  const [loading, setLoading] = useState(false);
  const timeoutRef = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { getRolesLoader, roles, getAllRoleProfileLoader, roleAllProfile } =
    useSelector((state) => state.setting);
  const { addUserLoader, getCompanyAllUserLoader, companyAllUsers } =
    useSelector((state) => state.users);

  useEffect(() => {
    if (
      isModalOpen &&
      reportsTo &&
      companyAllUsers &&
      companyAllUsers.length > 0
    ) {
      form.setFieldValue("reports_to_id", reportsTo);
    }
  }, [form, reportsTo, getCompanyAllUserLoader]);

  useEffect(() => {
    dispatch(actionGetRoles());
    dispatch(actionGetAllRoleProfile());
    dispatch(actionGetCompanyAllUsers());
  }, [dispatch]);



  const validateEmail = (value) => {
    return new Promise((resolve, reject) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      // Delay API call by 500ms
      timeoutRef.current = setTimeout(() => {
        setLoading(true);
        axios
          .get(`${BASE_URL}/company-users/check-email-or-phone?email=${value}`)
          .then((response) => {
            if (parseInt(response.data.status, 10) === 200) {
              reject(new Error("This email has already been invited!"));
            } else {
              resolve();
            }
          })
          .catch((error) => {
            console.log({ error })
          })
          .finally(() => {
            setLoading(false);
          });
      }, 500);
    });
  };


  const onFinish = (values) => {
    const req = [
      {
        email: values.email,
        role_id: values.role_id,
        role_profile_id: values.role_profile_id,
        reports_to_id: values.reports_to_id,
      },
    ];

    if (isSaveAndAddNew) {
      dispatch(actionAddUser({ req, form }));
    } else {
      dispatch(
        actionAddUser({ req, navigate, form, isHierchy, setIsModalOpen })
      );
    }
  };

  const roleData = [];
  const roleProfileData = [];


  roles &&
    roles.map((data) =>
      roleData.push({
        label: data.name,
        value: data.id,
      })
    );
  roleAllProfile &&
    roleAllProfile.map((data) =>
      roleProfileData.push({
        label: data.name,
        value: data.id,
      })
    );




  const reportsToData = useMemo(() => {
    return companyAllUsers?.map((data) => ({
      label: (
        <span>
          <Avatar
            style={{
              verticalAlign: "middle",
            }}
            className="mr-2"
            src={data.profile_pic}
          />
          {getFullName(data.first_name, data.last_name) + (data.emp_code ? ` (${data.emp_code})` : "")}
        </span>
      ),
      value: data.id,
    }));
  }, [companyAllUsers]);

  return (
    <section className="main-section">
      {!isHierchy && (
        <div className="mb-4">
          <CustomIconText image={addUser} title="Add User" backArrow={true} />
        </div>
      )}
      <Card className="main-card mt-5 w-full">
        <Form
          form={form}
          layout="vertical"
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter your email!",
                  },
                  {
                    validator(_, value) {
                      if (!value) {
                        return Promise.resolve();
                      }

                      const emailRegex = /\S+@\S+\.\S+/;
                      if (!emailRegex.test(value)) {
                        return Promise.reject(new Error("Please enter a valid email!"));
                      }

                      return validateEmail(value);
                    },
                  },
                ]}
              >
                <Input placeholder="Enter your email" suffix={loading ? <LoadingOutlined className="ml-auto text-brand" size="small" /> : null}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Role"
                name="role_id"
                rules={[
                  {
                    required: true,
                    message: "Please select role!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Role"
                  optionFilterProp="children"
                  className=""
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={roleData}
                  loading={getRolesLoader}
                  disabled={getRolesLoader}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Role Profile"
                name="role_profile_id"
                rules={[
                  {
                    required: true,
                    message: "Please select role profile!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Role Profile"
                  optionFilterProp="children"
                  className=""
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={roleProfileData}
                  disabled={getAllRoleProfileLoader}
                  loading={getAllRoleProfileLoader}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Report To"
                name="reports_to_id"
                rules={[
                  {
                    required: true,
                    message: "Please select report to!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Report To"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.props?.children[1] ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={reportsToData}
                  disabled={getCompanyAllUserLoader}
                  loading={getCompanyAllUserLoader}
                />
              </Form.Item>
            </Col>


            <Col
              span={24}
              className="flex items-center my-4 justify-center gap-x-5"
            >
              <Form.Item>
                <Button
                  onClick={async () => {
                    await dispatch(actionClearUsersAllData());
                    navigate(-1);
                  }}
                  type="button"
                  className="gray-button text-white hover:text-white  min-h-[45px] min-w-[120px]"
                  htmlType="button"
                >
                  Cancel
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  loading={addUserLoader && !isSaveAndAddNew}
                  type="primary"
                  className="root-btn min-h-[45px] min-w-[120px]"
                  htmlType="submit"
                  onClick={() => setIsSaveAndAddNew(false)}
                  disabled={addUserLoader && !isSaveAndAddNew}
                >
                  Save
                </Button>
              </Form.Item>
              {!isHierchy && (
                <Form.Item>
                  <Button
                    loading={addUserLoader && isSaveAndAddNew}
                    type="primary"
                    className="outline-button-brand  min-h-[45px] min-w-[120px]"
                    htmlType="submit"
                    onClick={() => setIsSaveAndAddNew(true)}
                    disabled={addUserLoader && isSaveAndAddNew}
                  >
                    Save & Add New
                  </Button>
                </Form.Item>
              )}
            </Col>
          </Row>
        </Form>
      </Card>
    </section>
  );
};

export default CreateUser;
