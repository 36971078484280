

import { DragOutlined, LoadingOutlined, MenuOutlined, SearchOutlined, StopOutlined } from "@ant-design/icons";
import { closestCenter, DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Badge, Button, Checkbox, ConfigProvider, Dropdown, Image, Input, Popconfirm, Popover, Skeleton, Space, Spin, Switch, Table, Tag, Tooltip } from "antd";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useAntdColumnResize } from "react-antd-column-resize";
import { CiFilter } from "react-icons/ci";
import { IoIosRefresh } from "react-icons/io";
import { RiDownloadLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import importIcon from "../../assets/img/import-icon.svg";
import { getCheckedList, setCheckedListStorage } from "../helper/tableUtils";
import AddButton from "./AddButton";
import CommonPagination from "./CommonPagination";
import SkeletonTable from "./SkeletonTable";
import SortIcon from "./SortIcon";
import TooltipWrapper from "./TooltipWrapper";

const CommonTable = ({
  onChange = () => { },
  data = [],
  rowSelection = null,
  columns: initialColumns = [],
  rowKey = "key",
  pagination = false,
  handleRefresh = () => { },
  handleSearch = () => { },
  handleBlurAndPressEnter = () => { },
  onClickDownloadMenu = () => { },
  content = "",
  search = "",
  moduleKey = "",
  items = [],
  importRoute = "",
  onClickDeactivate = () => { },
  isDeactivate = false,
  isImportEnabled = false,
  filterValues = false,
  icons = true,
  isAdvanceFilter = false,
  onClickFilter = () => { },
  isAllowDownload = true,
  importIconHeight = 17,
  isCheckbox = false,
  loading = false,
  allDataLoading = false,
  offset = 0,
  isHideShow = true,
  addBtn = true,
  onAddClick = () => { },
  bordered = true,
  scroll = {
    x: "max-content",
    y: window.innerWidth < 1500 ? "49vh" : "61vh",
  },
  handlePaginationChange = () => { },
  filteredFields = [],
  getFieldLabel,
  handleResetFilter,
  label = "Filter By",
  total = 0,
  pageTitle = null,
  title = "",
  pageSize = 50,
  showTotal = true,
  resource = "",
  buttonText = "Add New",
  buttonLink = "",
  type = "link",
  searchWidthClass = "min-w-[70%]",
  wrapperClassName = "2xl:max-h-[calc(100vh-175px)] xl:max-h-[calc(100vh-165px)] max-h-[calc(100vh-165px)]",
  ...props
}) => {


  const checkedList = getCheckedList(moduleKey);

  const [tableLoading, setTableLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState(null);
  const [sortedColumnKey, setSortedColumnKey] = useState(null);
  const regex = /^[a-zA-Z0-9 @.-]*$/;
  const navigate = useNavigate();
  const [isSelected, setIsSelected] = useState(false);


  const [columns, setColumns] = useState(() => {
    if (checkedList && checkedList.length > 0) {
      return initialColumns.map((col) => {
        const savedColumn = checkedList.find((saved) => saved.key === col.key);
        return savedColumn
          ? {
            ...col,
            fixed: col.key === "action" ? "right" : col.key === "ch_no" ? "left" : savedColumn?.sticky ? "left" : "",
            hidden: savedColumn.key === "action" && savedColumn.key === "ch_no" ? false : savedColumn.hidden,
            order: savedColumn.order || col.order,
            sticky: col.key === "ch_no" ? true : savedColumn.sticky,
            ...savedColumn,
            ellipsis: true
          }
          : col;
      }).sort((a, b) => (a.order || 0) - (b.order || 0));
    }


    return initialColumns.map((item, index) => ({
      ...item,
      sticky: item.fixed === "left" || index === 0,
      hidden: false,
      fixed: item.key === "ch_no" ? "left" : item.fixed,
      order: index + 1,
      width: item.width || 150,
      ellipsis: true
    }));
  });


  useEffect(() => {
    const nonFixedColumns = columns.filter(
      (col) => col.key !== "action" && col.key !== "ch_no" && col.fixed !== "left"
    );
    const allColumnsHidden = nonFixedColumns.every((col) => col.hidden);
    setIsSelected(!allColumnsHidden);
    setCheckedListStorage(moduleKey, columns);
  }, [columns, moduleKey])


  const handleToggleStickyColumn = (key) => {
    const newCols = columns.map((col) => {
      if (col.key === "action" && col.key === "ch_no") {
        return col;
      }

      if (col.key === key) {
        return {
          ...col,
          sticky: !col.sticky,
          fixed: !col.sticky ? "left" : null,
        };
      }

      return {
        ...col,
        sticky: col.key === "ch_no" ? true : false,
        // fixed: null,
        fixed: col.key === "ch_no" ? "left" : col.key === "action" ? "right" : null,
      };
    });

    setColumns(newCols);
  }

  useEffect(() => {
    setColumns((cols) =>
      cols.map((item) => ({
        ...item,
        sortIcon: () =>
          item.sorter
            ? loading && !tableLoading && item.key === sortedColumnKey
              ? <LoadingOutlined />
              : item.key === sortedColumnKey ? <SortIcon sortOrder={sortOrder} /> : <SortIcon />
            : null,
        sorter: item.sorter,
      }))
    );
  }, [loading, tableLoading, sortedColumnKey]);


  const handleSearchChange = (e) => {
    const value = e.target.value;
    if (regex.test(value)) {
      handleSearch(e);
    }
  };

  const handleBlurAndEnter = (e) => {
    const value = e.target.value.trim();
    if (regex.test(value)) {
      handleBlurAndPressEnter(e);
    }
  };

  const handleSelectDeselectAll = () => {
    const nonFixedColumns = columns.filter((col) => col.fixed !== "left" && col.key !== "action");

    const allVisible = nonFixedColumns.every((col) => !col.hidden);

    const fixedLeftKeys = columns
      .filter((col) => col.fixed === "left" || col.key === "action")
      .map((col) => col.key);

    const updatedCheckedList = allVisible
      ? fixedLeftKeys
      : [...fixedLeftKeys, ...nonFixedColumns.map((col) => col.key)];

    const updatedColumns = columns.map((col) => {
      return {
        ...col,
        hidden: col.key === "action" || col.fixed === "left" ? false : !updatedCheckedList.includes(col.key),
      };
    });

    setColumns(updatedColumns);

    setIsSelected(!allVisible);
  }

  useEffect(() => {
    setSortOrder(null);
  }, [offset]);

  const handleTableChange = (pagination, filters, sorter, extra) => {
    const { action } = extra || {};
    console.log({ sorter })
    if (action === "sort") {
      setSortOrder(sorter.order);
      setTableLoading(false);
      setSortedColumnKey(sorter.columnKey || null);
    } else {
      setSortOrder(null);
      setTableLoading(true);
      setSortedColumnKey(null);
    }

    onChange(pagination, filters, sorter);
  };

  useEffect(() => {
    if (sortOrder) {
      setTableLoading(false);
    } else {
      setTableLoading(loading);
    }
  }, [loading, sortOrder]);

  useEffect(() => {
    if (data.length === 0 && !loading && !sortOrder) {
      setTableLoading(false);
    }
  }, [data, loading, sortOrder]);

  const count = useMemo(() => {
    return columns?.filter(item => item.key !== "action" && !item.hidden)?.length || 0;
  }, [columns]);



  const { resizableColumns, components } = useAntdColumnResize({
    columns,
    minWidth: 100,
    maxWidth: 500
  });


  useEffect(() => {
    setColumns(resizableColumns);
  }, [resizableColumns]);


  const memoizedColumns = useMemo(() => columns, [columns]);


  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center w-full px-3">
        <div className="min-w-[20%] flex items-center ">{pageTitle}</div>
        <section className={`main-section shrink-0  ml-auto flex-1`}>
          <Space.Compact className="items-center gap-x-2 custom-input-sec w-full relative flex my-[5px]">
            <Input
              value={search}
              onChange={handleSearchChange}
              onPressEnter={handleBlurAndEnter}
              size="large"
              style={{ borderRight: "none" }}
              className="hover:border-gray-400 bg-transparent"
              id="none-border"
              allowClear
              prefix={<SearchOutlined className="text-[#7C7C7C]" />}
              addonAfter={
                <div className="flex items-center h-full">
                  {isHideShow && (
                    <Popover
                      className="table-popover"
                      rootClassName="table-popover"
                      content={() => (
                        <div
                          className="master-menu px-2 mr-2"
                          style={{ maxHeight: "300px", overflowY: "auto" }}
                        >
                          <SortableContainer
                            items={columns}
                            moduleKey={moduleKey}
                            setItems={setColumns}
                            checkedList={checkedList}
                            onSwitchChange={handleToggleStickyColumn}
                          />
                        </div>
                      )}
                      title={
                        <div className="flex items-center justify-between px-2">
                          <p>Columns</p>
                          <p
                            className="text-brand cursor-pointer font-popinsRegular text-sm tracking-wide"
                            onClick={handleSelectDeselectAll}
                          >
                            {" "}
                            {isSelected ? "Deselect All" : "Select All"}
                          </p>
                        </div>
                      }
                      trigger="click"
                    >
                      <div className="h-full cursor-pointer flex items-center icon-btn justify-center">
                        <TooltipWrapper title={"Show Columns"}>
                          <Tag
                            color="#6883FD"
                            className="bg-transparent mx-3 text-primary"
                          >
                            {count || 0}
                          </Tag>
                        </TooltipWrapper>
                      </div>
                    </Popover>
                  )}
                  {icons ? (
                    <div className="flex h-full items-center ">
                      {content && content}

                      {isDeactivate && (
                        <TooltipWrapper title={"Deactivate"}>
                          <Popconfirm
                            placement="topLeft"
                            title="Deactivate"
                            description="Are you sure to deactivate All users?"
                            onConfirm={onClickDeactivate}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              style={{ backgroundColor: "white" }}
                              className="h-full default-button bg-white icon-btn"
                            >
                              <div>
                                <StopOutlined
                                  size={17}
                                  style={{ color: "red" }}
                                />
                              </div>
                            </Button>
                          </Popconfirm>
                        </TooltipWrapper>
                      )}
                      {isAdvanceFilter && (
                        <TooltipWrapper title={"Filter"}>
                          <Button
                            style={{ backgroundColor: "white" }}
                            onClick={onClickFilter}
                            className="h-full default-button bg-white icon-btn"
                          >
                            <Badge dot={filterValues} overflowCount={9}>
                              <CiFilter
                                style={{ fontWeight: "bold" }}
                                strokeWidth={0.6}
                                size={17}
                                color="#6883fd"
                              />
                            </Badge>
                          </Button>
                        </TooltipWrapper>
                      )}

                      <TooltipWrapper title="Refresh">
                        <Button
                          style={{ backgroundColor: "white" }}
                          className="h-full default-button icon-btn"
                          onClick={handleRefresh}
                        >
                          <IoIosRefresh size={17} color="#6883fd" />
                        </Button>
                      </TooltipWrapper>
                      {items && items.length > 0 ? (
                        <TooltipWrapper title="Download">
                          <Dropdown
                            menu={{
                              items,
                              onClick: onClickDownloadMenu,
                            }}
                          >
                            <Button
                              style={{ backgroundColor: "white" }}
                              className="h-full default-button icon-btn"
                            >
                              {allDataLoading ? (
                                <Spin
                                  indicator={<LoadingOutlined />}
                                  size="small"
                                  style={{ color: "#6883fd" }}
                                />
                              ) : (
                                <RiDownloadLine size={17} color="#6883fd" />
                              )}
                            </Button>
                          </Dropdown>
                        </TooltipWrapper>
                      ) : (
                        isAllowDownload && (
                          <TooltipWrapper title="Download" className="h-full">
                            <Button /* loading={allDataLoading} */
                              disabled={!isAllowDownload}
                              style={{ backgroundColor: "white" }}
                              onClick={onClickDownloadMenu}
                              className="h-full default-button icon-btn"
                            >
                              {allDataLoading ? (
                                <Spin style={{ color: "#6883fd" }} />
                              ) : (
                                <RiDownloadLine size={17} color="#6883fd" />
                              )}
                            </Button>
                          </TooltipWrapper>
                        )
                      )}

                      {isImportEnabled && (
                        <TooltipWrapper title="Import">
                          <Button
                            onClick={() => navigate(importRoute)}
                            disabled={false}
                            style={{ backgroundColor: "white" }}
                            className="h-full default-button px-5 !w-[50px] icon-btn"
                            icon={
                              <Image
                                height={importIconHeight}
                                width={importIconHeight}
                                src={importIcon}
                                alt="import-icon"
                                preview={false}
                              />
                            }
                          />
                        </TooltipWrapper>
                      )}
                    </div>
                  ) : null}
                </div>
              }
              placeholder="Search here..."
            />

            {addBtn && <AddButton
              type={type}
              buttonLink={buttonLink}
              onClick={onAddClick}
              resource={resource}
              buttonText={buttonText}
            />

            }            </Space.Compact>

          {loading && filteredFields.length > 0 ? (
            <Skeleton.Button
              block
              active
              size="small"
              style={{
                width: "100%",
                height: "40px",
                marginBottom: "10px",
                borderRadius: "6px",
              }}
            />
          ) : filteredFields.length > 0 ? (
            <div className="mb-2 py-2 px-3 rounded-[10px] gap-x-2 bg-white border-t border-[#E8E6F0] flex items-center justify-between">
              <div className="space-x-2">
                <span className="lg:text-lg text-base font-popinsRegular text-brand">
                  {label}
                </span>
                <span className="text-base text-black font-popinsRegular">
                  {filteredFields
                    .map((fieldWithValue) => getFieldLabel(fieldWithValue))
                    .join(", ")}
                </span>
              </div>
              <div
                className="pr-2 border-l border-[#E8E6F0] cursor-pointer text-nowrap"
                onClick={handleResetFilter}
              >
                <span className="pl-3 lg:text-lg text-base font-popinsRegular text-red-600">
                  Clear Filter
                </span>
              </div>
            </div>
          ) : null}
        </section>
      </div>
      <div>
        {props.paragraph ? (
          <p className="text-sm text-[#7c7c7c] mb-2 px-3">{props.paragraph}</p>
        ) : (
          ""
        )}
      </div>

      <div className={`flex-grow scroll-container overflow-y-auto ${wrapperClassName} `}>
        {tableLoading ? <SkeletonTable isCheckbox={isCheckbox} columns={columns} length={20} /> :
          <ConfigProvider theme={{
            components: {
              Table: {
                borderRadius: 0,
                algorithm: true
              }
            }
          }}>

            <Table
              {...props}
              // sortDirections={["ascend", "descend"]}
              onChange={handleTableChange}
              bordered={bordered}
              rowSelection={rowSelection}
              rowKey={rowKey}
              scroll={scroll}
              components={components}
              pagination={pagination}
              columns={memoizedColumns}
              dataSource={data}
              className="common-table"

            />
          </ConfigProvider>
        }
      </div>

      <div
        className={`flex  pt-[5px] items-center border-t border-t-[#ededed] px-4 h-[55px] ${showTotal ? "justify-between" : "justify-end"
          }`}
      >
        <p className="text-[15px] font-popinsMedium">
          Total {title} : {total ? total : 0}
        </p>

        <CommonPagination
          current={offset + 1}
          total={total}
          onChange={handlePaginationChange}
          loading={loading}
          pageSize={pageSize}
        />
      </div>
    </div>
  );
};

export default CommonTable;



export const SortableItem = React.memo(({ id, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } =
    useSortable({ id });

  console.log(transform, "transform")

  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : "none",
    transition,
  };


  return (
    <div ref={setNodeRef} style={style} {...attributes} className="flex items-center gap-x-2" >
      <MenuOutlined {...listeners} style={{ marginRight: "8px", cursor: "grab" }} />
      {children}
    </div>
  );
});

export const SortableContainer = React.memo(({ items, setItems, onSwitchChange }) => {
  const [activeId, setActiveId] = useState(null)
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleCheckboxChange = useCallback((checkedKeys) => {
    const updatedCheckedList = items?.map((col) => {
      const isSelected = checkedKeys.includes(col.key);

      if (col.key === "action" || col.fixed === "left") {
        return {
          ...col,
          hidden: false,
        };
      }

      return {
        ...col,
        hidden: !isSelected,
      };
    }) || [];


    setItems(updatedCheckedList);
  }, [items, setItems]);
  const handleDragEnd = useCallback((event) => {
    const { active, over } = event;
    setActiveId(null);

    if (!over || active.id === over.id) {
      return;
    }

    const oldIndex = items.findIndex((item) => item.key === active.id);
    const newIndex = items.findIndex((item) => item.key === over.id);

    const reorderedItems = arrayMove(items, oldIndex, newIndex).map((item, index) => ({
      ...item,
      order: index + 1,
    }));

    setItems(reorderedItems);
  }, [items, setItems]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Checkbox: {
            colorPrimary: "#6883FD",
            algorithm: true,
            colorPrimaryHover: "#6883FD",
            colorPrimaryBorder: "#d9d9d9",
          },
        }

      }}>

      <DndContext 
        autoScroll sensors={sensors} collisionDetection={closestCenter} modifiers={[restrictToVerticalAxis]} onDragEnd={handleDragEnd}>
        <SortableContext items={items.map(item => item.key)}>
          <Checkbox.Group className="flex flex-col gap-y-2 px-2"
            value={items.filter((col) => !col.hidden && col.key !== "ch_no").map((col) => col.key)}

            onChange={handleCheckboxChange}>
            {items.filter(item => item.key !== "action" && item.key !== "ch_no").map((item) => (
              <div key={item.key} className="flex items-center justify-between">

                <SortableItem id={item.key}>
                  <Checkbox disabled={item.key === "ch_no" || item.fixed === "left"} value={item.key}>{item.title}</Checkbox>
                </SortableItem>
                <Tooltip title="Toggle to sticky">
                  <Switch
                    disabled={item.key === "ch_no" || item.fixed === "left"}
                    size="small"
                    checked={item.sticky}
                    onChange={(val) => onSwitchChange(item.key)}

                  />
                </Tooltip>
              </div>
            ))}
          </Checkbox.Group>
        </SortableContext>

      </DndContext>
    </ConfigProvider >
  );
});