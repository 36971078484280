import {
  LoadingOutlined,
  SearchOutlined,
  StopOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Dropdown,
  Input,
  Image,
  Popconfirm,
  Space,
  Tag,
  Popover,
  Checkbox,
  ConfigProvider,
  Spin,
} from "antd";
import React, { useState, useEffect, useRef } from "react";
import { IoIosRefresh } from "react-icons/io";
import { CiFilter } from "react-icons/ci";
import importIcon from "../../assets/img/import-icon.svg";
import { RiDownloadLine } from "react-icons/ri";
import TooltipWrapper from "./TooltipWrapper";
import { useNavigate } from "react-router-dom";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToHorizontalAxis,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import { debounce, isEqual } from "lodash";

export const SortableItem = ({ id, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  );
};

export const SortableContainer = ({
  items,
  setItems,
  checkedList,
  setCheckedList,
  moduleKey,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleCheckboxChange = (checkedValues) => {
    setCheckedList(checkedValues);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Checkbox: {
            colorPrimary: "#6883FD",
            algorithm: true,
            colorPrimaryHover: "#6883FD",
            colorPrimaryBorder: "#d9d9d9",
          },
        },
      }}
    >
      <SortableContext items={items.map((item) => item.key)}>
        <Checkbox.Group
          className="flex flex-col gap-y-2"
          value={checkedList}
          onChange={handleCheckboxChange}
        >
          {items
            .filter((item) => item.key !== "action")
            .map((item) => (
              <SortableItem key={item.key} id={item.key}>
                <Checkbox disabled={item.fixed === "left"} value={item.key}>
                  {item.title}
                </Checkbox>
              </SortableItem>
            ))}
        </Checkbox.Group>
      </SortableContext>
      {/* </DndContext> */}
    </ConfigProvider>
  );
};

const CustomInputWithButtons = ({
  icons = true,
  handleRefresh,
  handleDownload,
  handleSearch,
  handleBlurAndPressEnter,
  searchValue,
  isDeactivate,
  onClickDeactivate,
  items = [],
  filterValues = false,
  isAdvanceFilter = false,
  onClickDownloadMenu,
  onClickFilter = () => { },
  isImportEnabled = false,
  isAllowDownload = true,
  importRoute = "",
  importIconHeight = 17,
  content = "",
  columns: initialColumns = [],
  setColumns = null,
  setCheckedList = null,
  checkedList = null,
  moduleKey = null,
  allDataLoading = false,
  isHideShow = true,
  onAddClick = () => { },
  addBtn = true,
}) => {
  const regex = /^[a-zA-Z0-9 @.-]*$/;
  const navigate = useNavigate();

  const prevCheckedList = useRef(checkedList);
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (setColumns && !isEqual(prevCheckedList.current, checkedList)) {
      setColumns((prevColumns) =>
        prevColumns.map((col) => ({
          ...col,
          hidden: !checkedList.includes(col.key),
        }))
      );
      prevCheckedList.current = checkedList;
    }
  }, [checkedList, setColumns]);

  useEffect(() => {
    if (initialColumns && initialColumns.length > 0) {
      const nonFixedColumns = initialColumns.filter(
        (col) => col.fixed !== "left" && col.key !== "action"
      );

      const allHidden = nonFixedColumns.every((column) => column.hidden);
      const allVisible = nonFixedColumns.every((column) => !column.hidden);

      if (allVisible) {
        setIsSelected(true);
      } else {
        setIsSelected(false);
      }
    }
  }, [initialColumns]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    if (regex.test(value)) {
      handleSearch(e);
    }
  };

  const handleBlurAndEnter = (e) => {
    const value = e.target.value.trim();
    if (regex.test(value)) {
      handleBlurAndPressEnter(e);
    }
  };

  const handleSelectDeselectAll = () => {
    const nonFixedColumns = initialColumns.filter(
      (col) => col.fixed !== "left" && col.key !== "action"
    );
    const allVisible = nonFixedColumns.every((col) => !col.hidden);

    const fixedLeftKeys = initialColumns
      .filter((col) => col.fixed === "left" || col.key === "action")
      .map((col) => col.key);

    const updatedCheckedList = !allVisible
      ? [...fixedLeftKeys, ...nonFixedColumns.map((col) => col.key)]
      : fixedLeftKeys;

    setCheckedList(updatedCheckedList);
  };

  return (
    <section className="main-section">
      <Space.Compact className="w-full custom-input-sec relative flex mb-5">
        <Input
          value={searchValue}
          onChange={handleSearchChange}
          /*  onBlur={handleBlurAndEnter} */
          onPressEnter={handleBlurAndEnter}
          size="large"
          style={{ borderRight: "none" }}
          className="hover:border-gray-400 bg-transparent"
          id="none-border"
          allowClear
          prefix={<SearchOutlined className="text-[#7C7C7C]" />}
          addonAfter={
            <div className="flex items-center h-full">
              {isHideShow && (
                <Popover
                  content={() => (
                    <div
                      className="master-menu"
                      style={{ maxHeight: "300px", overflowY: "auto" }}
                    >
                      <SortableContainer
                        items={initialColumns}
                        setItems={setColumns}
                        moduleKey={moduleKey}
                        checkedList={checkedList}
                        setCheckedList={setCheckedList}
                      />
                    </div>
                  )}
                  title={
                    <div className="flex items-center justify-between">
                      <p>Columns</p>
                      <p
                        className="text-brand cursor-pointer font-popinsRegular text-sm tracking-wide"
                        onClick={handleSelectDeselectAll}
                      >
                        {" "}
                        {isSelected ? "Deselect All" : "Select All"}
                      </p>
                    </div>
                  }
                  trigger="click"
                >
                  <div className="h-full cursor-pointer flex items-center icon-btn justify-center">
                    <TooltipWrapper title={"Show Columns"}>
                      <Tag
                        color="#6883FD"
                        className="bg-transparent mx-3 text-primary"
                      >
                        {(checkedList && checkedList.length) || 0}
                      </Tag>
                    </TooltipWrapper>
                  </div>
                </Popover>
              )}
              {icons ? (
                <div className="flex h-full items-center ">
                  {content && content}

                  {isDeactivate && (
                    <TooltipWrapper title={"Deactivate"}>
                      <Popconfirm
                        placement="topLeft"
                        title="Deactivate"
                        description="Are you sure to deactivate All users?"
                        onConfirm={onClickDeactivate}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          style={{ backgroundColor: "white" }}
                          className="h-full default-button bg-white icon-btn"
                        >
                          <div>
                            <StopOutlined size={17} style={{ color: "red" }} />
                          </div>
                        </Button>
                      </Popconfirm>
                    </TooltipWrapper>
                  )}
                  {isAdvanceFilter && (
                    <TooltipWrapper title={"Filter"}>
                      <Button
                        style={{ backgroundColor: "white" }}
                        onClick={onClickFilter}
                        className="h-full default-button bg-white icon-btn"
                      >
                        <Badge dot={filterValues} overflowCount={9}>
                          <CiFilter
                            style={{ fontWeight: "bold" }}
                            strokeWidth={0.6}
                            size={17}
                            color="#6883fd"
                          />
                        </Badge>
                      </Button>
                    </TooltipWrapper>
                  )}

                  <TooltipWrapper title="Refresh">
                    <Button
                      style={{ backgroundColor: "white" }}
                      className="h-full default-button icon-btn"
                      onClick={handleRefresh}
                    >
                      <IoIosRefresh size={17} color="#6883fd" />
                    </Button>
                  </TooltipWrapper>
                  {items && items.length > 0 ? (
                    <TooltipWrapper title="Download">
                      <Dropdown
                        menu={{
                          items,
                          onClick: onClickDownloadMenu,
                        }}
                      >
                        <Button
                          // loading={allDataLoading}
                          style={{ backgroundColor: "white" }}
                          onClick={handleDownload}
                          className="h-full default-button icon-btn"
                        >
                          {allDataLoading ? (
                            <Spin
                              indicator={<LoadingOutlined />}
                              size="small"
                              style={{ color: "#6883fd" }}
                            />
                          ) : (
                            <RiDownloadLine size={17} color="#6883fd" />
                          )}
                        </Button>
                      </Dropdown>
                    </TooltipWrapper>
                  ) : (
                    isAllowDownload && (
                      <TooltipWrapper title="Download" className="h-full">
                        <Button /* loading={allDataLoading} */
                          disabled={!isAllowDownload}
                          style={{ backgroundColor: "white" }}
                          onClick={onClickDownloadMenu}
                          className="h-full default-button icon-btn"
                        >
                          {allDataLoading ? (
                            <Spin style={{ color: "#6883fd" }} />
                          ) : (
                            <RiDownloadLine size={17} color="#6883fd" />
                          )}
                        </Button>
                      </TooltipWrapper>
                    )
                  )}

                  {isImportEnabled && (
                    <TooltipWrapper title="Import">
                      <Button
                        onClick={() => navigate(importRoute)}
                        disabled={false}
                        style={{ backgroundColor: "white" }}
                        className="h-full default-button px-5 !w-[50px] icon-btn"
                        icon={
                          <Image
                            height={importIconHeight}
                            width={importIconHeight}
                            src={importIcon}
                            alt="import-icon"
                            preview={false}
                          />
                        }
                      />
                    </TooltipWrapper>
                  )}

                  {addBtn && <Button
                    onClick={onAddClick}
                    disabled={false}
                    style={{ backgroundColor: "white" }}
                    className="h-full default-button px-5 !w-[50px] icon-btn"
                    icon={
                      <Image
                        height={importIconHeight}
                        width={importIconHeight}
                        src={importIcon}
                        alt="import-icon"
                        preview={false}
                      />
                    }
                  />}
                </div>
              ) : null}
            </div>
          }
          placeholder="Search here..."
        />
      </Space.Compact>
    </section>
  );
};

export default CustomInputWithButtons;
