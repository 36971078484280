import * as XLSX from "xlsx";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  LoadingOutlined,
  PlusOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Form,
  Image,
  Input,
  Menu,
  Modal,
  Pagination,
  Popconfirm,
  Row,
  Skeleton,
  Spin,
  Table,
  Typography,
  Upload,
} from "antd";
import ImgCrop from "antd-img-crop";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import editPrimary from "../../../assets/img/editPrimary.svg";
import speaker from "../../../assets/img/speaker.svg";
import { BASE_URL_UPLOAD } from "../../../config/web-config";
import {
  actionAddEventSpeaker,
  actionClearAllEventSpeakerData,
  actionDeleteEventSpeaker,
  actionEventSpeakerDetail,
  actionGetAllEventSpeakers,
  actionGetEventSpeakers,
  actionUpdateEventSpeaker,
} from "../../../store/services/eventService";
import CommonModal from "../../common/CommonModal";
import CustomInputWithButtons from "../../common/CustomInputWithButtons ";
import PageTitle from "../../common/PageTitle";
import SortIcon from "../../common/SortIcon";
import {
  extractFilename,
  getEventId,
  getFirstLetterCapital,
  sanitizeName,
} from "../../helper/common-function";
import EventDetailWrapper from "./EventDetailWrapper";
import usePermission from "../../../hooks/use-permission";
import { handleActionWithPermission } from "../../../store/slices/authSlice";
import SkeletonTable from "../../common/SkeletonTable";
import SkeletonViewModal from "../../common/SkeletonViewModal";
import SkeletonUpdateCard from "../../common/SkeletonUpdateCard";
import CommonTable from "../../common/CommonTable";
import CommonPagination from "../../common/CommonPagination";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const Speakers = () => {
  const dispatch = useDispatch();
  const eventId = getEventId();
  const [form] = Form.useForm();
  const {
    eventSpeakersData,
    getEventSpeakersLoader,
    eventSpeakersDataCount,
    addEventSpeakerLoader,
    updateEventSpeakerLoader,
    getEventSpeakerDetailLoader,
    getEventSpeakerDetail,
    deleteEventSpeakerLoader,
    allEventSpeakersData,
    getAllEventSpeakersLoader,
  } = useSelector((state) => state.events);
  const [loading, setLoading] = useState(false);
  const [profilePicUrl, setProfilePicUrl] = useState("");
  const [search, setSearch] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [speakerData, setSpeakerData] = useState(false);
  const [exportAll, setExportAll] = useState(false);
  const [lastSearch, setLastSearch] = useState('');
  const [pageSize, setPageSize] = useState(20);
  const offsetRef = useRef(0);
  const { hasPermission } = usePermission();

  const columns = [
    {
      title: "CH No.",
      key: "ch_no",
      width: 100,
      dataIndex: "event_speaker_id",
      fixed: "left",
      render: (text, data) =>
        <div
          onClick={() => {
            dispatch(
              handleActionWithPermission("event-speaker", "read", () => {
                setSpeakerData(data);
                setViewModalOpen(true);
              })
            );
          }}
          className="flex text-brand items-center cursor-pointer"
        >
          {text ?? "-"}

        </div>,
    },
    {
      title: "Speaker Name",
      key: "1",
      fixed: "left",
      dataIndex: "speaker_name",
      sorter: (a, b) => a.speaker_name - b.speaker_name,
      sortIcon: () => <SortIcon />,
      width: 200,
      render: (text, data) => (
        <div
          onClick={() => {
            dispatch(
              handleActionWithPermission("event-speaker", "read", () => {
                setSpeakerData(data);
                setViewModalOpen(true);
              })
            );
          }}
          className="flex items-center cursor-pointer"
        >
          <Avatar
            style={{
              verticalAlign: "middle",
            }}
            src={data.speaker_image}
            size={30}
          />
          <p className="ml-2 text-brand">
            {getFirstLetterCapital(data.speaker_name)}
          </p>
        </div>
      ),
    },

    {
      title: "Speaker Email",
      key: "3",
      width: 300,

      dataIndex: "speaker_email",
      sorter: (a, b) => a.speaker_email - b.speaker_email,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Contact Number",
      dataIndex: "speaker_contact",
      key: "4",
      width: 200,
      sorter: (a, b) => a.speaker_contact - b.speaker_contact,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Speaker Company",
      dataIndex: "speaker_company",
      key: "5",
      width: 200,
      sorter: (a, b) => a.speaker_company - b.speaker_company,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Speaker Bio",
      dataIndex: "speaker_bio",
      key: "2",
      width: 200,
      ellipsis: true,
      sortIcon: () => <SortIcon />,
      sorter: (a, b) => a.speaker_bio - b.speaker_bio,
      render: (text, data) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          style={{ width: 300 }}
          className="ml-2 !mb-0"
        >
          {text ?? "-"}
        </Typography.Paragraph>
      ),
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 80,
      align: "center",
      fixed: "right",
      render: (text, data) => (
        <>
          <Dropdown
            id="custom-drop"
            menu={{
              items: [
                {
                  label: <span>Edit</span>,
                  key: "Edit",
                  icon: <EditOutlined />,
                  onClick: () => {
                    dispatch(
                      handleActionWithPermission(
                        "event-speaker",
                        "update",
                        () => {
                          setIsModalOpen(true);
                          setSpeakerData(data);
                        }
                      )
                    );
                  },
                },

                {
                  label: hasPermission("event-speaker", "delete") ? (
                    <Popconfirm
                      okButtonProps={{
                        loading: deleteEventSpeakerLoader,
                      }}
                      placement="topLeft"
                      description={`Are you sure to delete this speaker?`}
                      onConfirm={() => {
                        dispatch(
                          actionDeleteEventSpeaker({
                            id: data.event_speaker_id,
                            event_id: eventId,
                          })
                        );
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <div
                        className="flex items-center"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <DeleteOutlined
                          style={{ fontSize: 12, color: "#ff4d4f" }}
                        />
                        <span
                          style={{
                            marginLeft: 8,
                            color: "#ff4d4f",
                          }}
                        >
                          Delete
                        </span>
                      </div>
                    </Popconfirm>
                  ) : (
                    <div
                      className="flex items-center"
                      onClick={() => {
                        dispatch(
                          handleActionWithPermission("event-speaker", "delete")
                        );
                      }}
                    >
                      <DeleteOutlined
                        style={{ fontSize: 12, color: "#ff4d4f" }}
                      />
                      <span
                        style={{
                          marginLeft: 8,
                          color: "#ff4d4f",
                        }}
                      >
                        Delete
                      </span>
                    </div>
                  ),
                  key: "Delete",
                },
              ].filter(Boolean),
            }}
          >
            <div className="flex justify-center items-center">
              <EllipsisOutlined />
            </div>
          </Dropdown>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (eventId) {
      dispatch(
        actionGetEventSpeakers({ offset: offsetRef.current, limit: pageSize, search, eventId })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (speakerData) {
      dispatch(
        actionEventSpeakerDetail({ id: speakerData.event_speaker_id, eventId })
      );
    }
  }, [dispatch, speakerData]);

  useEffect(() => {
    if (speakerData && getEventSpeakerDetail) {
      form.setFieldsValue({
        speaker_name: getEventSpeakerDetail.speaker_name,
        speaker_email: getEventSpeakerDetail.speaker_email,
        speaker_contact: getEventSpeakerDetail.speaker_contact,
        speaker_bio: getEventSpeakerDetail.speaker_bio,
        speaker_company: getEventSpeakerDetail.speaker_company,
      });
      setProfilePicUrl(getEventSpeakerDetail.speaker_image);
    }
  }, [getEventSpeakerDetail]);

  const handleRefresh = () => {
    dispatch(
      actionGetEventSpeakers({
        offset: offsetRef.current,
        limit: pageSize,
        search: search,
        eventId,
      })
    );
  };

  useEffect(() => {
    const trimmedSearch = search?.trim();
    if (trimmedSearch !== "" && trimmedSearch !== lastSearch) {
      const timer = setTimeout(() => {
        dispatch(
          actionGetEventSpeakers({ offset: offsetRef.current, limit: pageSize, search: trimmedSearch, eventId })
        );
        setLastSearch(trimmedSearch);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }

  }, [search, lastSearch]);

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      dispatch(
        actionGetEventSpeakers({
          offset: offsetRef.current,
          limit: pageSize,
          search: e.target.value,
          eventId,
        })
      );
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      dispatch(
        actionGetEventSpeakers({
          offset: offsetRef.current,
          limit: pageSize,
          search: e.target.value,
          eventId,
        })
      );
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSpeakerData(false);
    form.resetFields();
    setProfilePicUrl("");
  };

  const handleChangeUserPic = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setProfilePicUrl(info.file.response.url);
        toast.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      setLoading(false);
      toast.error(`${info.file.name} file upload failed.`);
    }
  };

  const onFinish = (values) => {
    const fileUrl = profilePicUrl
      ? extractFilename(profilePicUrl && profilePicUrl)
      : "";

    const data = {
      ...values,
      speaker_image: fileUrl,
      event_id: eventId,
    };

    const req = {
      ...Object.fromEntries(
        Object.entries(data).filter(
          ([_, v]) =>
            v !== undefined &&
            v !== null &&
            !(typeof v === "string" && v.trim() === "") &&
            (!Array.isArray(v) || v.length > 0)
        )
      ),
    };

    speakerData
      ? dispatch(
        actionUpdateEventSpeaker({
          id: getEventSpeakerDetail.event_speaker_id,
          req,
          handleCancel,
        })
      )
      : dispatch(
        actionAddEventSpeaker({
          req,
          handleCancel,
        })
      );
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
            ? "ASC"
            : "",
    };

    dispatch(
      actionGetEventSpeakers({
        offset: offsetRef.current,
        limit: pageSize,
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
        eventId,
      })
    );
  };

  const exportAllToExcel = async () => {
    const data =
      (await allEventSpeakersData) &&
      allEventSpeakersData.length > 0 &&
      allEventSpeakersData.map((data) => ({
        "Speaker Name": data.speaker_name ? data.speaker_name : "-",
        "Spiker Bio": data.speaker_bio ? data.speaker_bio : "-",
        "Speaker Email": data.speaker_email ? data.speaker_email : "-",
        "Contact Number": data.speaker_contact ? data.speaker_contact : "-",
        "Speaker Company": data.speaker_company ? data.speaker_company : "-",
      }));
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Speakers");
    // Generate the Excel file and initiate the download
    XLSX.writeFile(workbook, "speakers.xlsx");
    dispatch(actionClearAllEventSpeakerData({ setExportAll }));
  };

  useEffect(() => {
    if (exportAll && allEventSpeakersData && allEventSpeakersData.length > 0) {
      exportAllToExcel();
    }
  }, [allEventSpeakersData]);

  const onClickDownloadMenu = () => {
    dispatch(actionGetAllEventSpeakers({ eventId }));
    setExportAll(true);
  };

  return (
    <>
      <EventDetailWrapper>
        <div className="event-table-container main-table-container" style={{ height: "calc(100vh - 130px)" }}>

          <CommonTable
            pageTitle={
              <PageTitle
                image={speaker}
                title="Speakers"
                rightIcon={false}
              />
            }
            wrapperClassName="2xl:max-h-[calc(100vh-140px)] xl:max-h-[calc(100vh-120px)] max-h-[calc(100vh-120px)]"
            buttonText="Add Speaker"
            onAddClick={() => setIsModalOpen(true)}
            type="btn"
            title="Speakers"
            offset={offsetRef.current}
            resource="event-speaker"
            total={eventSpeakersDataCount}
            pageSize={pageSize}
            moduleKey="event_speaker"
            loading={getEventSpeakersLoader || getAllEventSpeakersLoader}
            columns={columns}
            scrollToFirstRowOnChange={true}
            data={(eventSpeakersData || [])}
            rowKey={"event_speaker_id"}
            onChange={handleSortChange}
            handleRefresh={handleRefresh}
            handleSearch={handleSearch}
            handleBlurAndPressEnter={handleBlurAndPressEnter}
            search={search}
            onClickDownloadMenu={onClickDownloadMenu}
            handlePaginationChange={(page, pageSize) => {
              offsetRef.current = page - 1;
              setPageSize(pageSize);
              dispatch(
                actionGetEventSpeakers({
                  eventId,
                  offset: offsetRef.current,
                  limit: pageSize,
                  search,
                })
              );
            }}
          />

          {/*  Form Modal */}
          <Modal
            centered
            onOk={handleCancel}
            onCancel={handleCancel}
            afterClose={handleCancel}
            footer={false}
            width={800}
            title=""
            open={isModalOpen}
          >
            <div className="text-brand flex items-center gap-x-3">
              <Image
                src={speaker}
                alt="image"
                height={20}
                width={20}
                preview={false}
              />

              <h1 className="lg:text-lg text-base  font-popinsRegular">
                {speakerData ? "Update" : "Add"} Speaker
              </h1>
            </div>
            <Form
              form={form}
              onFinish={onFinish}
              layout="vertical"
              className="mt-[20px] mb-0"
            >
              {/* <Spin spinning={getEventSpeakerDetailLoader}> */}
              <Row gutter={[16, 0]}>
                <Col span={24} align="middle">
                  <Form.Item
                    className="mb-[12px]"
                    size="small"
                    rules={[
                      {
                        required: false,
                        message: "Please Select Speaker Image!",
                      },
                    ]}
                  >
                    {getEventSpeakerDetailLoader ? (
                      <>
                        {Array.from({ length: 1 }).map((_, index) => (
                          <Skeleton.Avatar
                            key={index}
                            active
                            size={100}
                            shape="square"
                            className="flex-shrink-0"
                            style={{ marginRight: "10px" }}
                          />
                        ))}
                      </>
                    ) : (
                      <>
                        {profilePicUrl ? (
                          <div className="relative border border-dashed border-brand  h-[102px]  w-[102px] rounded-[6px]">
                            <img
                              src={profilePicUrl}
                              alt="avatar"
                              className="h-full w-full rounded-[7px]"
                            />

                            <ImgCrop
                              rotationSlider
                              aspect={1 / 1}
                              fillColor="white"
                            >
                              <Upload
                                name="file"
                                listType="picture-card"
                                className="avatar-uploader main-upload"
                                showUploadList={false}
                                action={BASE_URL_UPLOAD}
                                openFileDialogOnClick={true}
                                headers={{
                                  Authorization:
                                    "Bearer " +
                                    localStorage.getItem("crmWebToken"),
                                }}
                                onChange={handleChangeUserPic}
                              >
                                <div className="absolute bottom-0 right-0 px-1.5 py-1 bg-brand  rounded-tl-xl rounded-br-[6px] cursor-pointer flex">
                                  <Image
                                    width={15}
                                    height={15}
                                    src={editPrimary}
                                    className="rounded-lg"
                                    preview={false}
                                  />
                                </div>
                              </Upload>
                            </ImgCrop>
                          </div>
                        ) : (
                          <ImgCrop
                            aspect={1 / 1}
                            rotationSlider
                            fillColor="white"
                          >
                            <Upload
                              name="file"
                              listType="picture-card"
                              className="avatar-uploader"
                              showUploadList={false}
                              action={BASE_URL_UPLOAD}
                              openFileDialogOnClick={true}
                              headers={{
                                Authorization:
                                  "Bearer " + localStorage.getItem("crmWebToken"),
                              }}
                              onChange={handleChangeUserPic}
                            >
                              <button
                                style={{
                                  border: 0,
                                  background: "none",
                                }}
                                type="button"
                              >
                                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                                <div
                                  style={{
                                    marginTop: 8,
                                  }}
                                >
                                  Upload
                                </div>
                              </button>
                            </Upload>
                          </ImgCrop>
                        )}
                      </>
                    )}
                  </Form.Item>
                </Col>

                {getEventSpeakerDetailLoader ? (
                  <SkeletonUpdateCard length={3} />
                ) : (
                  <>
                    <Col span={12}>
                      <Form.Item
                        name="speaker_name"
                        label="Speaker Name"
                        className="mb-[12px]"
                        size="small"
                        rules={[
                          {
                            required: true,
                            message: "Please enter speaker name!",
                          },
                        ]}
                        normalize={sanitizeName}
                      >
                        <Input
                          style={{ background: "white" }}
                          placeholder="Enter Speaker Name"
                          className="!indent-0 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="speaker_email"
                        label="Speaker Email"
                        className="mb-[12px]"
                        size="small"
                        rules={[
                          {
                            required: false,
                            message: "Please enter speaker email!",
                          },
                          {
                                            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,

                            message: "Please enter a valid email!",
                          },
                        ]}
                      >
                        <Input
                          style={{ background: "white" }}
                          placeholder="Enter Speaker Email"
                          className="!indent-0 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="speaker_contact"
                        label="Contact Number"
                        className="mb-[12px]"
                        normalize={(value) =>
                          value && value.replace(/[^+\d]/g, "")
                        }
                        size="small"
                        /*  rules={[
                      {
                        required: false,
                        message: "Please enter speaker contact!",
                      },
                    ]} */
                        rules={[
                          {
                            max: 15,
                            message:
                              "contact number cannot be longer than 15 digits",
                          },
                        ]}
                      >
                        <Input
                          aria-controls="false"
                          style={{ background: "white" }}
                          placeholder="Enter Contact Number"
                          className="!indent-0 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="speaker_company"
                        label="Speaker Company"
                        className="mb-[12px]"
                        size="small"
                        rules={[
                          {
                            required: false,
                            message: "Please enter speaker company!",
                          },
                        ]}
                      >
                        <Input
                          style={{ background: "white" }}
                          placeholder="Enter Speaker Company"
                          className="!indent-0 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="speaker_bio"
                        label="Speaker Bio"
                        className="mb-[12px]"
                        size="small"
                        rules={[
                          {
                            required: false,
                            message: "Please enter speaker bio!",
                          },
                        ]}
                      >
                        <Input.TextArea
                          rows={3}
                          style={{ background: "white" }}
                          placeholder="Enter Speaker Bio"
                          className="!indent-0 begin-input bg-white pt-2 focus:bg-white active:bg-white rounded-[10px]"
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      span={24}
                      align="middle"
                      className="flex justify-center mt-[40px]"
                    >
                      <Form.Item className="mb-0">
                        <Button
                          onClick={handleCancel}
                          type="button"
                          className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
                        >
                          Cancel
                        </Button>
                      </Form.Item>

                      <div className="ml-4">
                        <Form.Item className="mb-0">
                          <Button
                            loading={
                              addEventSpeakerLoader || updateEventSpeakerLoader
                            }
                            type="primary"
                            className="root-btn min-h-[45px] min-w-[120px]"
                            htmlType="submit"
                          >
                            {speakerData ? "Save" : "Submit"}
                          </Button>
                        </Form.Item>
                      </div>
                    </Col>
                  </>
                )}
              </Row>
              {/* </Spin> */}
            </Form>
          </Modal>
          <CommonModal
            centered={true}
            // loading={getEventSpeakerDetailLoader}
            open={viewModalOpen}
            title=""
            handleReset={() => {
              setViewModalOpen(false);
              handleCancel();
            }}
            onCancel={() => {
              setViewModalOpen(false);
              handleCancel();
            }}
            onOk={() => {
              setViewModalOpen(false);
              handleCancel();
            }}
          >
            {/* <Spin spinning={getEventSpeakerDetailLoader}> */}

            {getEventSpeakerDetailLoader ? (
              <SkeletonViewModal length={3} />
            ) : (
              getEventSpeakerDetail && (
                <>
                  <div className="text-brand flex items-center gap-x-3">
                    <Image
                      src={speaker}
                      alt="image"
                      height={20}
                      width={20}
                      preview={false}
                    />

                    <h1 className="lg:text-lg text-base  font-popinsRegular">
                      View Speaker
                    </h1>
                  </div>
                  <div className="flex flex-col gap-y-4">
                    <div className="flex items-center pt-5 gap-x-4">
                      <Image
                        preview={false}
                        height={100}
                        width={100}
                        className="rounded-[10px]"
                        src={getEventSpeakerDetail.speaker_image}
                        alt="Profile Pic"
                      />
                      <div className="flex justify-start flex-col gap-y-1">
                        <p className="text-sm font-popinsMedium">
                          {getEventSpeakerDetail.speaker_name}
                        </p>
                        <p className="text-sm  text-[#818181]">
                          {getEventSpeakerDetail.speaker_email ?? "-"}
                        </p>
                      </div>
                    </div>
                    <div className="flex  gap-x-10 items-center gap-y-1">
                      <p className="text-xs text-[#818181] w-[25%]">
                        Speaker Bio:
                      </p>
                      <p className="text-sm text-start w-[75%] text-black font-popinsMedium">
                        {getEventSpeakerDetail.speaker_bio ?? "-"}
                      </p>
                    </div>
                    <div className="flex  gap-x-10 items-center w-full gap-y-1">
                      <p className="text-xs text-[#818181] w-[25%]">
                        Contact Number:
                      </p>
                      <p className="text-sm text-start w-[75%] text-black font-popinsMedium">
                        {/* +91 8586291612 */}
                        {getEventSpeakerDetail.speaker_contact ?? "-"}
                      </p>
                    </div>
                    <div className="flex  gap-x-10 items-center gap-y-1">
                      <p className="text-xs text-[#818181] w-[25%]">
                        Speaker Company:
                      </p>
                      <p className="text-sm text-start w-[75%] text-black font-popinsMedium">
                        {getEventSpeakerDetail.speaker_company ?? "-"}
                      </p>
                    </div>
                  </div>
                </>
              )
            )}

            {/* </Spin> */}
          </CommonModal>
        </div>
      </EventDetailWrapper>
    </>
  );
};

export default Speakers;
