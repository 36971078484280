import { useState, useEffect } from "react";

export const DeviceType = {
  UNKNOWN: 0,
  PHONE: 1,
  TABLET: 2,
  DESKTOP: 3,
  TV: 4,
};

const useDeviceInfo = () => {
  const [deviceInfo, setDeviceInfo] = useState({
    deviceType: DeviceType.UNKNOWN,
    osName: "Unknown OS",
    osVersion: "Unknown Version",
    browserName: "Unknown Browser",
    deviceName: "Unknown Device",
    batteryPercentage: "Unknown",
    latitude: "Unknown",
    longitude: "Unknown",
  });

  useEffect(() => {
    const userAgent = navigator.userAgent;
    let deviceType = DeviceType.UNKNOWN; // Default to UNKNOWN
    let osName = "Unknown OS";
    let osVersion = "Unknown Version";
    let browserName = "Unknown Browser";
    let deviceName = "Unknown Device";

    // Device Type Detection (Mobile, Tablet, TV, Desktop)
    if (/Mobi|Android/i.test(userAgent)) {
      deviceType = DeviceType.PHONE;
      deviceName = "Mobile Device";
    } else if (/iPad|Tablet/i.test(userAgent)) {
      deviceType = DeviceType.TABLET;
      deviceName = "Tablet Device";
    } else if (/SmartTV|WebOS|Tizen/i.test(userAgent)) {
      deviceType = DeviceType.TV;
      deviceName = "Smart TV";
    } else if (/Macintosh/i.test(userAgent)) {
      deviceType = DeviceType.DESKTOP;
      deviceName = /MacBook/i.test(userAgent) ? "MacBook" : "Mac Desktop";
    } else if (/Windows NT/i.test(userAgent)) {
      if (/Windows Phone/i.test(userAgent)) {
        deviceType = DeviceType.PHONE;
        deviceName = "Windows Phone";
      } else if (navigator.maxTouchPoints > 0) {
        deviceType = DeviceType.DESKTOP;
        deviceName = "Windows Touch Device";
      } else {
        deviceType = DeviceType.DESKTOP;
        deviceName = "Windows Desktop";
      }
    } else {
      deviceType = DeviceType.DESKTOP;
      deviceName = "Desktop Device";
    }

    // OS Name and Version Detection
    if (/Windows NT/i.test(userAgent)) {
      osName = "Windows";
      osVersion = userAgent.match(/Windows NT (\d+\.\d+)/)?.[1] || "Unknown";
    } else if (/Android/i.test(userAgent)) {
      osName = "Android";
      osVersion = userAgent.match(/Android (\d+\.\d+)/)?.[1] || "Unknown";
    } else if (/iPhone|iPad/i.test(userAgent)) {
      osName = "iOS";
      osVersion =
        userAgent.match(/OS (\d+_\d+)/)?.[1]?.replace("_", ".") || "Unknown";
    }

    // Browser Name Detection
    if (/Chrome/i.test(userAgent)) {
      browserName = "Chrome";
    } else if (/Firefox/i.test(userAgent)) {
      browserName = "Firefox";
    } else if (/Safari/i.test(userAgent)) {
      browserName = "Safari";
    } else if (/Edge/i.test(userAgent)) {
      browserName = "Edge";
    }

    // Battery Info
    if ("getBattery" in navigator) {
      navigator.getBattery().then((battery) => {
        setDeviceInfo((prevState) => ({
          ...prevState,
          batteryPercentage: `${(battery.level * 100).toFixed(0)}%`,
        }));
      });
    }

    // Geolocation Info
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          setDeviceInfo((prevState) => ({
            ...prevState,
            latitude,
            longitude,
          }));
        },
        () => {
          setDeviceInfo((prevState) => ({
            ...prevState,
            latitude: "Error fetching location",
            longitude: "Error fetching location",
          }));
        }
      );
    }

    // Set initial device info
    setDeviceInfo({
      ...deviceInfo,
      deviceType,
      osName,
      osVersion,
      browserName,
      deviceName,
      batteryPercentage: "Fetching...",
    });
  }, []);

  return deviceInfo;
};

export default useDeviceInfo;





