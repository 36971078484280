import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  ConfigProvider,
  Dropdown,
  Image,
  Modal,
  Pagination,
  Popconfirm,
  Skeleton,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography,
  Input,
  Button,
  Checkbox,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import closeIcon from "../../assets/img/close-icon.svg";
import taskIcon from "../../assets/img/task-icon.svg";
import {
  actionClearAllTasks,
  actionDeleteTasks,
  actionGetAllTasks,
  actionGetTaskDetail,
  actionGetTasks,
  actionUpdateTask,
} from "../../store/services/taskService";
import CustomInputWithButtons from "../common/CustomInputWithButtons ";
import PageTitle from "../common/PageTitle";
import SortIcon from "../common/SortIcon";
import CreateTask from "./CreateTask";
import { actionGetTaskStatusMaster } from "../../store/services/generalMasterService";
import { getPriorityColor, getTaskStatusColor } from "../helper/statusUtils";
import { actionGetCompanyAllUsers } from "../../store/services/usersService";
import { getFirstLetterCapital, getFullName } from "../helper/common-function";
import { handleActionWithPermission } from "../../store/slices/authSlice";
import usePermission from "../../hooks/use-permission";
import SkeletonTable from "../common/SkeletonTable";
import { useSearchParams } from "react-router-dom";
import CommonTable from "../common/CommonTable";
import CommonPagination from "../common/CommonPagination";

const htmlToPlainText = (html) => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = html;

  let plainText = tempElement.textContent || tempElement.innerText;

  const entities = {
    "&lt;": "<",
    "&gt;": ">",
    "&amp;": "&",
    "&quot;": '"',
    "&apos;": "'",
    "&nbsp;": " ",
  };

  for (const entity in entities) {
    plainText = plainText.replace(new RegExp(entity, "g"), entities[entity]);
  }

  plainText = plainText.trim();

  return plainText;
};

const Task = () => {
  const offsetRef = useRef(0);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [exportAll, setExportAll] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [createdBy, setCreatedBy] = useState([]);
  const [lastSearch, setLastSearch] = useState("");
  const [pageSize, setPageSize] = useState(20);
  const { hasPermission } = usePermission();
  const [searchParams, setSearchParams] = useSearchParams({ task_id: "" });

  const {
    tasks,
    getTasksLoader,
    getAllTasksLoader,
    allTasks,
    alltasksCount,
    getTaskDetailLoader,
    deleteTaskLoader,
    updateTaskLoader,
  } = useSelector((state) => state.tasks);

  const { companyAllUsers, getCompanyAllUserLoader } = useSelector(
    (state) => state.users
  );

  const { getTaskStatusMasterLoader, taskStatusMasterData } = useSelector(
    (state) => state.generalMaster
  );

  const getApiCall = (
    offset = 0,
    limit = pageSize,
    search = "",
    orderByColumn = "",
    order = "",
    task_assignee_user = [],
    created_by = []
  ) => {
    dispatch(
      actionGetTasks({
        offset,
        limit,
        orderByColumn,
        order,
        search,
        task_assignee_user,
        created_by,
      })
    );
  };

  const taskId = searchParams.get("task_id");

  useEffect(() => {
    if (taskId) {
      dispatch(actionGetTaskDetail(taskId));
      setIsModalOpen(true);
      setUpdateId(taskId);
    }
  }, [location.search, dispatch]);

  useEffect(() => {
    dispatch(actionGetTaskStatusMaster());
    dispatch(actionGetCompanyAllUsers());
  }, []);

  useEffect(() => {
    getApiCall();
  }, [dispatch]);

  const handleChangeStatus = (key, id) => {
    const selected = taskStatusItems.find(
      (item) => item.value === parseInt(key.key)
    );

    const req = {
      task_status_id: selected.value,
    };

    dispatch(
      actionUpdateTask({
        id,
        req,
        type: "status",
      })
    );
  };

  const priorityItems = [
    {
      key: "1",
      label: "Low",
      value: "Low",
    },
    {
      key: "2",
      label: "Normal",
      value: "Normal",
    },
    {
      key: "3",
      label: "High",
      value: "High",
    },
    {
      key: "4",
      label: "Highest",
      value: "Highest",
    },
    {
      key: "5",
      label: "Lowest",
      value: "Lowest",
    },
  ];

  // const users =
  //   companyAllUsers && companyAllUsers.length > 0
  //     ? companyAllUsers.map((data) => ({
  //       text: (
  //         <span>
  //           <Avatar
  //             src={data.profile_pic}
  //             style={{
  //               verticalAlign: "middle",
  //             }}
  //             className=" mr-2"
  //           />

  //           {getFullName(data.first_name, data.last_name)}
  //         </span>
  //       ),
  //       value: data.id,
  //     }))
  //     : [];
  const users =
    companyAllUsers && companyAllUsers.length > 0
      ? companyAllUsers.map((data) => ({
        key: data.id,
        text: getFullName(data.first_name, data.last_name), // Plain text for searching
        jsx: (
          <span>
            <Avatar
              src={data.profile_pic}
              style={{
                verticalAlign: "middle",
              }}
              className="mr-2"
            />
            {getFullName(data.first_name, data.last_name)}
          </span>
        ),
      }))
      : [];

  const columns = [
    {
      title: "CH No.",
      key: "ch_no",
      width: 100,
      dataIndex: "task_id",
      fixed: "left",
      render: (text) => <div
        className="text-brand cursor-pointer"
        onClick={() => {
          dispatch(
            handleActionWithPermission("task", "update", () => {
              setUpdateId(text),
                setIsModalOpen(true),
                dispatch(actionGetTaskDetail(text));
            })
          );
        }}
      >{text}
      </div>,
    }, {
      title: "Title",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 200,
      render: (text, data) => (
        <Typography.Paragraph
          title={text}
          ellipsis={{ rows: 1 }}
          style={{
            width: 200,
          }}
          onClick={() => {
            dispatch(
              handleActionWithPermission("task", "update", () => {
                setUpdateId(data.task_id),
                  setIsModalOpen(true),
                  dispatch(actionGetTaskDetail(data.task_id));
              })
            );
          }}
          className="text-brand cursor-pointer !mb-0"
        >
          {text ? text : "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Due Date & Time",
      dataIndex: "due_date",
      key: "due_date",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 200,
      render: (text) => (text ? dayjs(text).format("DD-MM-YYYY HH:mm A") : "-"),
    },
    {
      title: "Status",
      dataIndex: "task_status_master",
      key: "task_status",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 200,

      render: (text, data) => {
        const task_status_master = data.task_status_master || {};
        const status = task_status_master.status || "";
        const isStatusLoading = updateTaskLoader[data.task_id]?.status || false;

        return status ? (
          <div id="dropw">
            {isStatusLoading ? (
              <Spin size="small" loading={isStatusLoading} />
            ) : (
              <Dropdown
                menu={{
                  items: taskStatusItems.filter(
                    (item) => item.label.toLowerCase() !== status.toLowerCase()
                  ),
                  onClick: (key) => handleChangeStatus(key, data.task_id),
                }}
                placement="bottom"
                overlayClassName="status-dropdown"
                arrow={{
                  pointAtCenter: true,
                }}
                dropdownRender={(menu) => (
                  <div className="status-dropdown-container">{menu}</div>
                )}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Tag color={getTaskStatusColor(text?.status)}>
                    {text?.status?.charAt(0).toUpperCase() +
                      text?.status.slice(1).toLowerCase()}
                  </Tag>
                </a>
              </Dropdown>
            )}
          </div>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Priority",
      dataIndex: "task_priority",
      key: "task_priority",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 100,
      render: (text, data) => {
        // const isPriorityLoading = updateTaskLoader[data.task_id];
        const isPriorityLoading =
          updateTaskLoader[data.task_id]?.priority || false;

        return text ? (
          <>
            {isPriorityLoading ? (
              <Spin size="small" loading={isPriorityLoading} />
            ) : (
              <Dropdown
                menu={{
                  items: priorityItems.filter(
                    (item) =>
                      item.value.toLowerCase() !==
                      data.task_priority.toLowerCase()
                  ),
                  onClick: (key) => {
                    const selected = priorityItems.find(
                      (item) => item.key === key.key
                    );
                    const req = {
                      task_priority: selected.value,
                    };

                    dispatch(
                      actionUpdateTask({
                        id: data.task_id,
                        type: "priority",
                        req,
                      })
                    );
                  },
                }}
                placement="bottom"
                arrow={{
                  pointAtCenter: true,
                }}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Tag
                    color={getPriorityColor(text)}
                  // className="py-1 px-2.5 text-sm font-popinsRegular"
                  >
                    {text}
                  </Tag>
                </a>
              </Dropdown>
            )}
          </>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Reminder",
      dataIndex: "is_reminder",
      key: "is_reminder",
      width: 120,
      render: (text) => (
        <span className={`${text ? "text-green-600" : "text-red-600"}`}>
          {text ? "ON" : "OFF"}
        </span>
      ),
    },
    {
      title: "Reminder On",
      dataIndex: "reminder_on_date",
      key: "reminder_on_date",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 200,
      render: (text, data) =>
        data.is_reminder
          ? `${dayjs(data.reminder_on_date).format("DD-MM-YYYY")} ${dayjs(
            data.reminder_on_time,
            "HH:mm"
          ).format("h:mm A")}`
          : "-",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: true,
      sortIcon: () => <SortIcon />,
      // ellipsis: true,
      width: 200,
      render: (text, data) =>
        text ? (
          <Typography.Paragraph
            title={htmlToPlainText(text)}
            ellipsis={{ rows: 1 }}
            style={{ width: 200 }}
            className="!mb-0"
          >
            {htmlToPlainText(text)}
          </Typography.Paragraph>
        ) : (
          "-"
        ),
    },

    {
      title: "Assigned Users",
      dataIndex: "task_assignee_user",
      key: "task_assignee_user",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        close,
      }) => {
        const [searchKey, setSearchKey] = useState("");
        const filteredUsers = users.filter((user) =>
          user.text.toLowerCase().includes(searchKey.toLowerCase())
        );

        console.log(filteredUsers, "filteredUsers")
        console.log(users, "users")
        return (
          <div className="p-[8px] w-[300px]">
            <Input
              size="small"
              placeholder="Search user"
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
              onPressEnter={confirm}
              prefix={<SearchOutlined className="text-[#dddae9]" />}
              className="mb-2 !min-h-[35px] !max-h-[35px] !rounded-[6px]"
              allowClear
            />
            <div className="master-menu max-h-[200px] overflow-auto">
              {filteredUsers.map((user) => (
                <div key={user.key} className="flex items-center p-[5px]">
                  <Checkbox
                    checked={selectedKeys.includes(user.key)}
                    onChange={(e) => {
                      const updatedSelected = e.target.checked
                        ? [...selectedKeys, user.key]
                        : selectedKeys.filter((key) => key !== user.key);
                      setSelectedKeys(updatedSelected);
                    }}
                  />
                  {user.jsx}
                </div>
              ))}
            </div>
            <div className="flex justify-between mt-2">
              <button
                className={`!rounded-md text-[12px] font-popinsRegular !border !border-[#dddae9] w-[60px] 
                ${selectedKeys.length === 0 ? "cursor-not-allowed" : ""}`}
                disabled={selectedKeys.length === 0}
                onClick={() => {
                  if (selectedUsers?.length === 0) {
                    close();
                  } else {
                    clearFilters();
                    setSelectedKeys([]);
                    confirm();
                  }
                }}
              >
                Reset
              </button>
              <Button
                className="!rounded-md text-[12px] font-popinsRegular w-[60px]"
                type="primary"
                onClick={confirm}
                size="small"
              >
                Apply
              </Button>
            </div>
          </div>
        );
      },
      width: 250,
      render: (assignees) => (
        <Avatar.Group
          size={30}
          max={{
            count: 3,
            style: {
              color: "white",
              backgroundColor: "#6883FD",
              cursor: "pointer",
              fontSize: 15,
            },
            popover: { trigger: "click" },
          }}
        >
          {assignees && assignees.length > 0
            ? assignees.map((item, index) => (
              <Tooltip
                key={item.assigned_comany_users?.id}
                title={item.assigned_comany_users?.full_name}
              >
                <Avatar
                  key={index}
                  src={item.assigned_comany_users?.profile_pic}
                />
              </Tooltip>
            ))
            : "-"}
        </Avatar.Group>
      ),
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
      // filters: users,
      filteredvalue: createdBy,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        close,
      }) => {
        const [searchKey, setSearchKey] = useState(""); // Separate state for search
        const filteredUsers = users.filter((user) =>
          user.text.toLowerCase().includes(searchKey.toLowerCase())
        );
        return (
          <div className="p-[8px] w-[300px]">
            <Input
              size="small"
              placeholder="Search user"
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
              onPressEnter={confirm}
              prefix={<SearchOutlined className="text-[#dddae9]" />}
              className="mb-2 !min-h-[35px] !max-h-[35px] !rounded-[6px]"
              allowClear
            />
            <div className="master-menu max-h-[200px] overflow-auto">
              {filteredUsers.map((user) => (
                <div key={user.key} className="flex items-center p-[5px]">
                  <Checkbox
                    checked={selectedKeys.includes(user.key)}
                    onChange={(e) => {
                      const updatedSelected = e.target.checked
                        ? [...selectedKeys, user.key]
                        : selectedKeys.filter((key) => key !== user.key);
                      setSelectedKeys(updatedSelected);
                    }}
                  />
                  {user.jsx}
                </div>
              ))}
            </div>
            <div className="flex justify-between mt-2">
              <button
                className={`!rounded-md text-[12px] font-popinsRegular !border !border-[#dddae9] w-[60px] 
                  ${selectedKeys.length === 0 ? "cursor-not-allowed" : ""}`}
                disabled={selectedKeys.length === 0}
                onClick={() => {
                  if (createdBy?.length === 0) {
                    close();
                  } else {
                    clearFilters();
                    setSelectedKeys([]);
                    confirm();
                  }
                }}
              >
                Reset
              </button>
              <Button
                className="!rounded-md text-[12px] font-popinsRegular w-[60px]"
                type="primary"
                onClick={confirm}
                size="small"
              >
                Apply
              </Button>
            </div>
          </div>
        );
      },
      width: 200,
      ellipsis: true,
      render: (text) => (
        <div className="flex items-center">
          <Avatar
            style={{
              verticalAlign: "middle",
            }}
            src={text.profile_pic}
            size={30}
          />
          <p className="ml-2">{getFirstLetterCapital(text?.full_name)}</p>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 100,
      fixed: "right",
      render: (text, data) => (
        <>
          <Dropdown
            id="custom-drop"
            menu={{
              items: [
                {
                  label: <span>Edit</span>,
                  key: "Edit",
                  icon: <EditOutlined />,
                  onClick: () => {
                    dispatch(
                      handleActionWithPermission("task", "update", () => {
                        setUpdateId(data.task_id),
                          setIsModalOpen(true),
                          dispatch(actionGetTaskDetail(data.task_id));
                      })
                    );
                  },
                },
                {
                  label: hasPermission("task", "delete") ? (
                    <Popconfirm
                      okButtonProps={{
                        loading: deleteTaskLoader,
                      }}
                      placement="topLeft"
                      title="Delete"
                      description={`Are you sure to delete this Task?`}
                      onConfirm={() => {
                        dispatch(actionDeleteTasks(data.task_id));
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <div
                        className="flex items-center"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <DeleteOutlined
                          style={{ fontSize: 12, color: "#ff4d4f" }}
                        />
                        <span
                          style={{
                            marginLeft: 8,
                            color: "#ff4d4f",
                          }}
                        >
                          Delete
                        </span>
                      </div>
                    </Popconfirm>
                  ) : (
                    <div
                      className="flex items-center"
                      onClick={() => {
                        dispatch(handleActionWithPermission("task", "delete"));
                      }}
                    >
                      <DeleteOutlined
                        style={{ fontSize: 12, color: "#ff4d4f" }}
                      />
                      <span
                        style={{
                          marginLeft: 8,
                          color: "#ff4d4f",
                        }}
                      >
                        Delete
                      </span>
                    </div>
                  ),
                  key: "Delete",
                  onClick: () => { },
                },
              ],
            }}
          >
            <div className="flex justify-center items-center">
              <EllipsisOutlined />
            </div>
          </Dropdown>
        </>
      ),
    },
  ];

  const taskStatusItems = useMemo(() => {
    return taskStatusMasterData && taskStatusMasterData.length > 0
      ? taskStatusMasterData.map((data) => ({
        key: data.task_status_id,
        label: data.status,
        value: data.task_status_id,
      }))
      : [];
  }, [taskStatusMasterData]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
    searchParams.delete("task_id");
    setSearchParams(searchParams);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setUpdateId("");
  };

  const handleRefresh = () => {
    getApiCall(
      offsetRef.current,
      pageSize,
      search,
      "",
      "",
      selectedUsers,
      createdBy
    );
  };

  useEffect(() => {
    const trimmedSearch = search?.trim();
    if (trimmedSearch !== "" && trimmedSearch !== lastSearch) {
      const timer = setTimeout(() => {
        getApiCall(
          offsetRef.current,
          pageSize,
          trimmedSearch,
          "",
          "",
          selectedUsers,
          createdBy
        );
        setLastSearch(trimmedSearch);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [search, lastSearch]);

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      // Call Action
      getApiCall(
        offsetRef.current,
        pageSize,
        e.target.value,
        "",
        "",
        selectedUsers,
        createdBy
      );
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      getApiCall(
        offsetRef.current,
        pageSize,
        e.target.value,
        "",
        "",
        selectedUsers,
        createdBy
      );
    }
  };

  const exportAllToExcel = async () => {
    const data =
      (await allTasks) &&
      allTasks.length > 0 &&
      allTasks.map((data) => ({
        "Task Owner": data?.created_by?.full_name
          ? data?.created_by?.full_name
          : "-",
        Title: data.name ? data.name : "-",
        "Due Date & Time": data.due_date ? data.due_date : "-",
        Status:
          data.task_status_master && data.task_status_master?.status
            ? data.task_status_master?.status
            : "-",
        Priority: data.task_priority ? data.task_priority : "-",
        "Assigned Users":
          data.task_assignee_user && data.task_assignee_user.length > 0
            ? data.task_assignee_user
              .map(
                (com) =>
                  com.assigned_comany_users &&
                  com.assigned_comany_users?.full_name
              )
              .join(", ")
            : "-",
        "Reminder On": data.is_reminder
          ? data.reminder_on_date &&
          data.reminder_on_time &&
          `${data.reminder_on_date} ${data.reminder_on_time}`
          : "-",
      }));
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Tasks");
    // Generate the Excel file and initiate the download
    XLSX.writeFile(workbook, "tasks.xlsx");
    dispatch(actionClearAllTasks({ setExportAll }));
  };

  useEffect(() => {
    if (exportAll && allTasks && allTasks.length > 0) {
      exportAllToExcel();
    }
  }, [allTasks]);

  const onClickDownloadMenu = () => {
    dispatch(actionGetAllTasks());
    setExportAll(true);
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.columnKey,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
            ? "ASC"
            : "",
    };

    setSelectedUsers(filters.task_assignee_user);
    setCreatedBy(filters.created_by || []);

    getApiCall(
      offsetRef.current,
      pageSize,
      search,
      sorterParams.field,
      sorterParams.order,
      filters.task_assignee_user,
      filters.created_by
    );
  };

  const handlePaginationChange = (page, pageSize) => {
    offsetRef.current = page - 1;
    setPageSize(pageSize);
    getApiCall(
      offsetRef.current,
      pageSize,
      search,
      "",
      "",
      selectedUsers,
      createdBy
    );
  };

  return (
    <>
      <section className="main-wrapper  main-table-container ">

        <ConfigProvider
          theme={{
            components: {
              Checkbox: {
                colorPrimary: "#6883FD",
                colorPrimaryHover: "#6883FD",
              },
            },
          }}
        >
          <CommonTable
            moduleKey="task"
            title="Task"
            total={alltasksCount}
            pageSize={pageSize}
            pageTitle={
              <PageTitle
                image={taskIcon}
                title="Tasks"
                rightIcon={false}
                margin={false}
              />
            }
            offset={offsetRef.current}
            buttonText="Add Task"
            resource="task"
            onAddClick={handleOpenModal}
            type="btn"
            loading={getTasksLoader || getAllTasksLoader}
            columns={columns}
            scrollToFirstRowOnChange={true}
            data={tasks || []}
            scroll={{ y: false }}
            rowKey={"task_id"}
            onChange={handleSortChange}
            handleRefresh={handleRefresh}
            handleSearch={handleSearch}
            handleBlurAndPressEnter={handleBlurAndPressEnter}
            search={search}
            onClickDownloadMenu={onClickDownloadMenu}
            handlePaginationChange={handlePaginationChange}
          />
        </ConfigProvider>

      </section>

      <Modal
        width={1200}
        height={600}
        className={`mt-2 create-task-modal  ${getTaskDetailLoader ? "p-5" : "p-0"
          }`}
        loading={getTaskDetailLoader}
        closeIcon={<Image preview={false} src={closeIcon} alt="close-icon" />}
        title={null}
        open={isModalOpen}
        style={{ overflowX: "none", top: 20 }}
        footer={false}
        onOk={handleCloseModal}
        onCancel={handleCloseModal}
        afterClose={handleCloseModal}
        destroyOnClose={handleCloseModal}
      >
        <CreateTask
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          updateId={updateId || taskId}
          setUpdateId={setUpdateId}
          handleCloseModal={handleCloseModal}
        />
      </Modal>
    </>
  );
};

export default Task;
