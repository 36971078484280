import React from "react";
import { Outlet } from "react-router-dom";

const Setting = () => {
  return (
    <>
      <Outlet />

    </>
  );
};

export default Setting;
