import {
  Card,
  Col,
  Image,
  Popconfirm,
  Row,
  Skeleton,
  Spin,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import calendarImg from "../../../assets/img/calendar.svg";
import attendeeImg from "../../../assets/img/event-attendee.svg";
import speakerImg from "../../../assets/img/speaker.svg";
import sessionImg from "../../../assets/img/event-session.svg";
import user from "../../../assets/img/event-user.svg";
import exhibitorImg from "../../../assets/img/exhibitor.svg";
import laptop from "../../../assets/img/laptop-mobile.svg";
import leftArrow from "../../../assets/img/leftArrowPrimary.svg";
import locationImg from "../../../assets/img/location1.svg";
import callSvg from "../../../assets/img/phone-call.svg";
import preview from "../../../assets/img/preview.svg";
import publish from "../../../assets/img/publish.svg";
import tag from "../../../assets/img/tags.svg";
import { actionCancleEvent } from "../../../store/services/eventService";
import { formatUrl, getEventId } from "../../helper/common-function";
import EventDetailWrapper from "./EventDetailWrapper";

const EventOverView = () => {
  const eventId = getEventId();
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { eventsOverviewLoader, eventsOverview, cancleEventLoader } =
    useSelector((state) => state.events);
  const { getSingleEventCategoryLoader } = useSelector(
    (state) => state.masterData
  );

  const combinedStartDateTime = `${eventsOverview?.event?.start_date} ${eventsOverview?.event?.start_time}`;
  const combinedEndDateTime = `${eventsOverview?.event?.end_date} ${eventsOverview?.event?.end_time}`;

  const getStatusColor = (status) => {
    switch (status) {
      case "Running":
        return "text-green-500 border-green-500";
      case "Upcoming":
        return "text-blue-500 border-blue-500";
      case "Past":
        return "text-slate-500 border-slate-500";
      case "Cancelled":
        return "text-red-500 border-red-500";

      default:
        return "text-black border-black";
    }
  };

  const getStatusDotColor = (status) => {
    switch (status) {
      case "Running":
        return "bg-green-500";
      case "Upcoming":
        return "bg-blue-500";
      case "Past":
        return "bg-slate-500";
      case "Cancelled":
        return "bg-red-500";
      default:
        return "bg-black";
    }
  };

  const { event, attendees, exhibitors, sessions, speakers } =
    eventsOverview || {};
  const {
    event_name,
    address,
    event_organizer,
    virtual_event,
    organizer_contact,
    event_status,
    event_category,
    event_website,
    event_description,
  } = event || {};

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const renderContent = () => {
    if (!event_description) return;

    const truncatedText =
      event_description.length > 900
        ? `${event_description.slice(0, 900)}...`
        : event_description;

    return (
      <div>
        {expanded ? (
          <span
            dangerouslySetInnerHTML={{
              __html: expanded ? event_description : truncatedText,
            }}
            style={{ marginBottom: "10px" }}
          />
        ) : (
          <span dangerouslySetInnerHTML={{ __html: truncatedText }} />
        )}
        {event_description && event_description.length > 900 && (
          <span
            className="read-more text-brand"
            onClick={toggleExpand}
            style={{ marginLeft: "5px", cursor: "pointer" }}
          >
            {!expanded ? "Read more" : "Read less"}
          </span>
        )}
      </div>
    );
  };

  const handleCancelEvent = () => {
    dispatch(
      actionCancleEvent({
        id: eventId,
        eventStatus: "Cancelled",
      })
    );
  };

  return (
    <>
      <EventDetailWrapper>
        <div className="flex px-5 py-2  pt-4 relative flex-grow overflow-auto flex-col gap-y-3 ">
          <div
            className="flex items-center gap-x-3 cursor-pointer"
            onClick={() => navigate("/events")}
          >
            <Image
              src={leftArrow}
              alt="image"
              height={20}
              width={20}
              preview={false}
            />
            <h1 className="lg:text-lg text-base font-popinsRegular text-brand">
              Back
            </h1>
          </div>
          <div className="flex flex-col gap-y-5 absolute right-10 justify-end">
            {event_status !== "Cancelled" ? (
              <Popconfirm
                trigger={"click"}
                okButtonProps={{
                  loading: cancleEventLoader,
                }}
                placement="topLeft"
                title="Cancel Event"
                description={`Are you sure to cancel this event?`}
                onConfirm={handleCancelEvent}
                okText="Yes"
                cancelText="No"
              >
                <a onClick={(e) => e.preventDefault()}>
                  <div
                    className={`flex rounded-full justify-center border ml-auto ${getStatusColor(
                      event_status
                    )} items-center gap-x-3 py-2 px-5 max-w-max`}
                  >
                    {/* Shimmer Effect: Show skeleton loader while cancleEventLoader is true */}
                    {cancleEventLoader ? (
                      <Skeleton.Input
                        active
                        style={{
                          width: "100%",
                          height: "30px",
                          borderRadius: "50px",
                        }}
                      />
                    ) : (
                      <>
                        <span
                          style={{
                            padding: 1.5,
                          }}
                          className={`inline-block h-1.5 w-1.5 ${getStatusDotColor(
                            event_status
                          )} rounded-full `}
                        ></span>
                        <p className={`text-[15px] text-center`}>
                          {event_status}
                        </p>
                      </>
                    )}
                  </div>
                </a>
              </Popconfirm>
            ) : (
              <a className="cursor-text" onClick={(e) => e.preventDefault()}>
                <div
                  className={`flex rounded-full justify-center border max-w-max ml-auto border-red-500 items-center gap-x-3 py-2 px-5`}
                >
                  <span
                    className={`h-1.5 w-1.5 rounded-full bg-red-500 inline-block border border-red-500 p-0.5`}
                  ></span>
                  <p className={`text-[15px] text-center text-red-500`}>
                    {event_status}
                  </p>
                </div>
              </a>
            )}

            {getSingleEventCategoryLoader ? (
              <Skeleton.Input
                active
                style={{
                  width: "100%",
                  height: "30px",
                  borderRadius: "50px",
                }}
              />
            ) : (
              event_category?.event_category && (
                <div className="flex rounded-full justify-center border border-[#9A9A9A] items-center gap-x-3 py-2 px-5 text-[#9A9A9A]">
                  <img src={tag} alt="Tag" />
                  <p className="text-[15px] text-center">
                    {event_category?.event_category}
                  </p>
                </div>
              )
            )}
          </div>
          {/* Buttons */}

          {eventsOverviewLoader ? (
            <>
              {/* Skeleton for Event Name */}
              <Skeleton.Input
                active
                size="default"
                style={{ height: "19px", width: "50%" }}
              />

              {/* Skeleton for Address */}
              <Skeleton.Input
                active
                size="default"
                style={{ height: "19px", width: "50%" }}
              />

              {/* Skeleton for Date */}
              <div className="flex gap-x-5 justify-start w-full items-center">
                <Skeleton.Input
                  active
                  size="default"
                  style={{ height: "19px", width: "50%" }}
                />
                <Skeleton.Input
                  active
                  size="default"
                  style={{ height: "19px", width: "50%" }}
                />
                {virtual_event && (
                  <Skeleton.Input
                    active
                    size="default"
                    style={{ width: "20%" }}
                  />
                )}
              </div>
            </>
          ) : (
            <>
              <h2 className="text-black font-popinsSemiBold lg:text-xl md:text-lg text-base">
                {event_name}
              </h2>

              {address && (
                <div className="flex gap-x-2 w-full items-center justify-start">
                  <Image
                    src={locationImg}
                    preview={false}
                    alt="Location Image"
                  />
                  <p className=" text-[15px] font-popinsRegular">{address}</p>
                </div>
              )}

              <div className="flex gap-x-5 justify-start w-full items-center">
                <div className="parent-container">
                  <img
                    src={calendarImg}
                    className="size-[15px]"
                    alt="Location"
                  />
                  <p className="child-text">
                    {dayjs(combinedStartDateTime).format("DD-MM-YYYY HH:mm:ss")}
                  </p>
                </div>
                <div className="parent-container">
                  <img
                    src={calendarImg}
                    className="size-[15px]"
                    alt="Location"
                  />
                  <p className="child-text">
                    {dayjs(combinedEndDateTime).format("DD-MM-YYYY HH:mm:ss")}
                  </p>
                </div>
                {virtual_event && (
                  <div className="parent-container">
                    <img src={laptop} className="size-[15px]" alt="Location" />
                    <p className="child-text">Virtual Event on Zoom</p>
                  </div>
                )}
              </div>
            </>
          )}

          <Card loading={eventsOverviewLoader}>
            <div className="flex gap-4">
              <div className="flex-1 flex justify-between items-center px-4 py-2 border-r border-[#DDDAE9]">
                <div className="flex flex-col gap-y-1">
                  <p className="text-base font-popinsMedium text-black">
                    Attendees
                  </p>
                  <p className="text-2xl font-popinsSemiBold text-black">
                    {attendees}
                  </p>
                </div>
                <img src={attendeeImg} alt="attendee" className="w-8 h-8" />
              </div>

              <div className="flex-1 flex justify-between items-center px-4 py-2 border-r border-[#DDDAE9]">
                <div className="flex flex-col gap-y-1">
                  <p className="text-base font-popinsMedium text-black">
                    Exhibitors
                  </p>
                  <p className="text-2xl font-popinsSemiBold text-black">
                    {exhibitors}
                  </p>
                </div>
                <img src={exhibitorImg} alt="exhibitor" className="w-8 h-8" />
              </div>

              <div className="flex-1 flex justify-between items-center px-4 py-2 border-r border-[#DDDAE9]">
                <div className="flex flex-col gap-y-1">
                  <p className="text-base font-popinsMedium text-black">
                    Sessions
                  </p>
                  <p className="text-2xl font-popinsSemiBold text-black">
                    {sessions}
                  </p>
                </div>
                <img src={sessionImg} alt="session" className="w-8 h-8" />
              </div>

              <div className="flex-1 flex justify-between items-center px-4 py-2">
                <div className="flex flex-col gap-y-1">
                  <p className="text-base font-popinsMedium text-black">
                    Speakers
                  </p>
                  <p className="text-2xl font-popinsSemiBold text-black">
                    {speakers}
                  </p>
                </div>
                <img src={speakerImg} alt="speaker" className="w-8 h-8" />
              </div>
            </div>
          </Card>

          <Row gutter={[0, 16]}>
            <Col
              style={{
                display:
                  event_website && event_website !== null ? "block" : "none",
              }}
              xl={
                (organizer_contact && organizer_contact !== null) ||
                (event_organizer && event_organizer !== null)
                  ? 16
                  : 24
              }
              xxl={
                (organizer_contact && organizer_contact !== null) ||
                (event_organizer && event_organizer !== null)
                  ? 18
                  : 24
              }
              span={24}
            >
              <div
                className={`flex relative border py-7 justify-between items-center gap-y-3 px-6 border-bColor radius 2xl:w-[98%] xl:w-[98%] w-full bg-white`}
              >
                <div className="flex flex-col">
                  <p className="2xl:text-xl text-lg pb-2 font-popinsSemiBold">
                    Event Website
                  </p>

                  {/* Skeleton loading effect */}
                  {!event_website ? (
                    <Skeleton.Input
                      active
                      paragraph={{ rows: 1 }}
                      style={{ height: "19px", width: "50%" }}
                    />
                  ) : (
                    <div className="flex flex-col gap-y-2 w-full items-start justify-center">
                      <div className="flex items-center 2xl:text-xl text-lg gap-x-1">
                        <p className="font-popinsMedium">Live URL : </p>
                        <Typography.Paragraph
                          className="text-brand mb-0 2xl:text-xl text-lg"
                          style={{ marginBottom: 0 }}
                          copyable
                        >
                          {event_website}
                        </Typography.Paragraph>
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex flex-col gap-y-5 justify-end">
                  {!event_website ? (
                    <Skeleton.Input
                      active
                      style={{
                        width: "100%",
                        height: "30px",
                      }}
                    />
                  ) : (
                    <a
                      href={formatUrl(event_website)}
                      target="_blank"
                      className="cursor-pointer"
                    >
                      <div
                        className={`flex rounded-[5px] justify-center border min-w-[150px] ml-auto border-brand items-center gap-x-3 py-2 px-5 text-brand`}
                      >
                        <Image preview={false} src={preview} alt="Preview" />
                        <p
                          className={`text-[15px] text-brand font-popinsMedium text-center`}
                        >
                          Preview
                        </p>
                      </div>
                    </a>
                  )}
                </div>
              </div>
            </Col>
            <Col
              style={{
                display:
                  event_organizer !== null || organizer_contact !== null
                    ? "flex"
                    : "none",
              }}
              xl={event_website && event_website !== null ? 8 : 24}
              xxl={event_website && event_website !== null ? 6 : 24}
              span={24}
              className="flex border py-5 flex-col gap-y-3 px-6 border-bColor radius bg-white"
            >
              <p className="text-xl font-popinsSemiBold">Event Organize</p>

              {/* Skeleton Loading Effect */}
              {/* {event_organizer === null && organizer_contact === null ? ( */}
              {eventsOverviewLoader ? (
                <Skeleton active paragraph={{ rows: 1 }} />
              ) : (
                <>
                  {event_organizer != null && (
                    <div className="flex gap-x-2 w-full items-center justify-start">
                      <Image preview={false} src={user} alt="User Image" />
                      <p className="text-[18px] font-popinsRegular text-[#898989]">
                        {event_organizer !== null ? event_organizer : "-"}
                      </p>
                    </div>
                  )}

                  {organizer_contact !== null && (
                    <div className="flex gap-x-2 w-full items-center justify-start">
                      <Image preview={false} src={callSvg} alt="Call Image" />
                      <p className="text-[18px] font-popinsRegular text-[#898989]">
                        {organizer_contact !== null ? organizer_contact : "-"}
                      </p>
                    </div>
                  )}
                </>
              )}
            </Col>
          </Row>
          {/* <div className="py-4">{renderContent()}</div> */}
          <div className="py-4">
            {eventsOverviewLoader ? (
              <Skeleton active paragraph={{ rows: 2 }} />
            ) : (
              renderContent()
            )}
          </div>
        </div>
        {/* </Spin> */}
      </EventDetailWrapper>
    </>
  );
};

export default EventOverView;
