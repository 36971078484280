const STORAGE_KEY = "columnSettings";

export const getCheckedList = (moduleKey) => {
    const storedData = localStorage.getItem(STORAGE_KEY);
    if (storedData) {
        const parsedData = JSON.parse(storedData);
        return parsedData[moduleKey] || [];
    }
    return [];
};

export const setCheckedListStorage = (moduleKey, checkedList) => {
    const storedData = localStorage.getItem(STORAGE_KEY);
    const parsedData = storedData ? JSON.parse(storedData) : {};

    parsedData[moduleKey] = checkedList.map((col) => ({
        key: col.key,
        sticky: col.sticky,
        hidden: col.hidden,
        width: col.width,
        order: col.order,
    }));

    localStorage.setItem(STORAGE_KEY, JSON.stringify(parsedData));
};

export const regex = /^[a-zA-Z0-9 @.-]*$/;
