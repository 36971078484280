import { Button, Image } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import plusIcon from "../../assets/img/add-button.svg";
import { handleActionWithPermission } from "../../store/slices/authSlice";

const PageTitleForMasterData = ({ title, buttonText, onButtonClick, resource, action = 'create' }) => {
    const dispatch = useDispatch();

    return (
        <>
            <div className="flex justify-between mt-[15px]">
                <h1 className="text-[#6883FD] text-[20px] font-medium">{title}</h1>
                <Button
                    onClick={() => dispatch(handleActionWithPermission(resource, action, () => onButtonClick()))}
                    className="root-btn text-white hover:text-white py-5 flex-center radius"
                    icon={<Image src={plusIcon} alt="Plus Icon" preview={false} />}
                >
                    {buttonText}
                </Button>
            </div>

        </>
    );
};

export default PageTitleForMasterData;
