import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  ConfigProvider,
  Empty,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Skeleton,
  Spin,
  Table,
  List,
  Typography,
  Avatar,
  Divider,
} from "antd";
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import VirtualList from "rc-virtual-list";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import addButtonWhite from "../../assets/img/add-button-white.svg";
import addSoIcon from "../../assets/img/create-so-icon.svg";
import delteIcon from "../../assets/img/deleteevent.svg";
import itemsIcon from "../../assets/img/items-icon.svg";
import { debounce } from "lodash";
import { actionGetBusinessPartnerTypeMaster } from "../../store/services/generalMasterService";
import {
  actionAddBusinessPartner,
  actionGetAllBusinessPartner,
} from "../../store/services/masterDataService";
import {
  actionClearProductsAllData,
  actionGetAllProductsMaster,
  actionGetAllProductSubCategoryMaster,
  actionGetFilteredProduts,
  actionGetProductCategoryMaster,
  actionGetProductTypeMaster,
  actionGetUnitMaster,
} from "../../store/services/productMasterService";
import {
  actionAddSalesOrder,
  actionGetAllPlantCodes,
  actionGetSalesOrderDetails,
  actionUpdateSalesOrder,
} from "../../store/services/salesService";
import AddPopover from "../common/AddPopover";
import CustomIconText from "../common/CustomIconText";
import { gridSettings } from "../helper/constants";
import DebounceSelect from "../common/DebounceSelect";
import { CiFilter } from "react-icons/ci";
import { actionGetCustomFields } from "../../store/services/customFieldService";
import { clearFilteredData } from "../../store/slices/productMasterSlice";
import { generateQueryString } from "../helper/common-function";
import SkeletonUpdateCard from "../common/SkeletonUpdateCard";

const ContainerHeight = 500;

const AddSalesOrder1 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const offsetRef = useRef(0);
  const [form] = Form.useForm();
  const [materialForm] = Form.useForm();
  const location = useLocation();

  const { isDuplicate, salesOrderId } = location.state || {};

  const [businessPartnerForm] = Form.useForm();
  const [shipToPartyForm] = Form.useForm();
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [rowIndex, setRowIndex] = useState(null);
  const [rowId, setRowId] = useState(null);

  const [search, setSearch] = useState("");
  const [isBusinessParterPopoverOpen, setIsBusinessParterPopoverOpen] =
    useState(false);
  const [isShipPopoverOpen, setIsShipPopoverOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [offset, setOffset] = useState(0);

  const {
    addSalesOrderLoader,
    updateSalesOrderLoader,
    getSalesOrderDetailLoader,
    salesOrderDetails,
    getAllPlantCodesLoader,
    allPlantCodes,
  } = useSelector((state) => state.sales);

  const { getCustomFieldsLoader, customFields, allCustomFields } = useSelector(
    (state) => state.customField
  );

  const { getBusinessPartnerTypeLoader, businessPartnerTypeData } = useSelector(
    (state) => state.generalMaster
  );
  const {
    unitMasterData,
    getUnitMasterLoader,
    getFilteredProductsLoader,
    filteredProductsData,
  } = useSelector((state) => state.productMaster);

  const {
    allBusinessPartnerData,
    getAllBusinessPartnerLoader,
    addBusinessPartnerLoader,
  } = useSelector((state) => state.masterData);

  const { productTypeData, allProductSubCategoryData, productCategoryData } =
    useSelector((state) => state.productMaster);

  const { getAllProductsLoader, productAllData } = useSelector(
    (state) => state.productMaster
  );

  const customFieldNameMapping =
    customFields.reduce((acc, field) => {
      acc[field.custom_field_id] = field.field_name;
      return acc;
    }, {}) || [];

  const limit = 20;

  const appendData = async (reset = true, showMessage = true) => {
    const values = materialForm.getFieldsValue() || {};
    const {
      material_category_id,
      material_sub_category_id,
      material_type_id,
      search,
    } = values;

    const customFieldParams = customFields
      .filter((field) =>
        ["Checkbox", "Dropdown", "MultiSelect"].includes(field.field_type)
      )
      .reduce((acc, field) => {
        const fieldName = customFieldNameMapping[field.custom_field_id];
        const fieldValue = values[field.custom_field_id] || [];

        if (fieldValue.length > 0) {
          acc[fieldName] = fieldValue;
        }

        return acc;
      }, {});

    setLoading(true);

    try {
      const currentOffset = reset ? 0 : offset + 1;

      const resultAction = await dispatch(
        actionGetFilteredProduts({
          material_category_id,
          material_sub_category_id,
          material_type_id,
          search,
          customFieldParams,
          offset: currentOffset,
          limit,
        })
      );

      if (actionGetFilteredProduts.fulfilled.match(resultAction)) {
        const status = resultAction.payload?.status;

        if (parseInt(status) === 200) {
          const { data: newProducts, total_records } =
            resultAction.payload || [];

          setProducts((prevData) => {
            const updatedData = reset
              ? newProducts
              : [...prevData, ...newProducts];

            if (updatedData.length >= total_records) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }

            return updatedData;
          });

          if (!reset) {
            setOffset((prevOffset) => prevOffset + 1);
          }
        } else if (parseInt(status) === 404) {
          console.warn("No products found for the given filters.");
          setHasMore(false);
        }
      } else {
        console.error("Failed to fetch products:", resultAction.error);
      }
    } catch (err) {
      console.log({ err });
    } finally {
      setLoading(false);
    }
  };

  const onScroll = (e) => {
    if (
      Math.abs(
        e.currentTarget.scrollHeight -
          e.currentTarget.scrollTop -
          ContainerHeight
      ) <= 1
    ) {
      if (!loading && hasMore) {
        appendData(false);
      }
    }
  };

  useEffect(() => {
    dispatch(actionGetAllBusinessPartner());
    dispatch(actionGetUnitMaster());
    dispatch(actionGetAllPlantCodes());
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearFilteredData());
  }, [location.pathname, dispatch]);

  useEffect(() => {
    if (salesOrderId) {
      dispatch(actionGetSalesOrderDetails(salesOrderId));
    }
  }, [salesOrderId]);

  useEffect(() => {
    if (salesOrderDetails && salesOrderId) {
      form.setFieldsValue({
        customer_business_partner_id:
          salesOrderDetails?.customer?.business_partner_id,
        ship_to_party_business_partner_id:
          salesOrderDetails?.ship_to_party?.business_partner_id,
        plant_code_master_id:
          salesOrderDetails?.plant_code?.plant_code_master_id,
        note: salesOrderDetails?.note,
        items: Array.isArray(salesOrderDetails.items)
          ? salesOrderDetails?.items.map((item, index) => ({
              quantity: item.quantity,
              material_master_id: {
                label: item.material_code?.material_name,
                value: item.material_code?.material_master_id,
                base_unit_of_measure: item?.unit_of_measure,
              },
              unit_of_measure_id: item.unit_of_measure?.unit_master_id,
            }))
          : [],
      });
    }
  }, [salesOrderDetails, form, salesOrderId]);

  const addNewClick = () => {
    dispatch(actionGetBusinessPartnerTypeMaster());
  };

  // business partner data
  const businessPartnerOptions = useMemo(() => {
    return allBusinessPartnerData && allBusinessPartnerData.length > 0
      ? allBusinessPartnerData.map((data) => ({
          key: data.business_partner_id,
          label: data.business_partner_name,
          value: data.business_partner_id,
        }))
      : [];
  }, [allBusinessPartnerData]);

  const plantCodeOptions = useMemo(() => {
    return allPlantCodes && allPlantCodes.length > 0
      ? allPlantCodes.map((data) => ({
          key: data.plant_code_master_id,
          label: data.plant_code,
          value: data.plant_code_master_id,
        }))
      : [];
  }, [allPlantCodes]);

  const unitOptions = useMemo(() => {
    return unitMasterData && unitMasterData.length > 0
      ? unitMasterData.map((data) => ({
          key: data.unit_master_id,
          label: data.code,
          value: data.unit_master_id,
        }))
      : [];
  }, [unitMasterData]);

  // business partner type data
  const businessPartnerTypesOptions = useMemo(() =>
    businessPartnerTypeData && businessPartnerTypeData.length > 0
      ? businessPartnerTypeData.map((data) => ({
          key: data.business_partner_type_master_id,
          label: data.business_partner_type_name,
          value: data.business_partner_type_master_id,
        }))
      : []
  );

  const materialTypeOptions = useMemo(() => {
    return productTypeData && productTypeData.length > 0
      ? productTypeData.map((data) => ({
          label: data.product_type,
          value: data.product_type_master_id,
        }))
      : [];
  }, [productTypeData]);

  const subCategoryOptions = useMemo(() => {
    return allProductSubCategoryData && allProductSubCategoryData.length > 0
      ? allProductSubCategoryData.map((data) => ({
          label: data.sub_category_name,
          value: data.product_sub_category_master_id,
        }))
      : [];
  }, [allProductSubCategoryData]);

  const categoryOptions = useMemo(() => {
    return productCategoryData && productCategoryData.length > 0
      ? productCategoryData.map((data) => ({
          label: data.category_name,
          value: data.product_category_master_id,
        }))
      : [];
  }, [productCategoryData]);

  const handleSelectMaterial = (otherOptions, index) => {
    form.setFieldsValue({
      items: form.getFieldsValue().items.map((item, i) =>
        i === index
          ? {
              ...item,
              unit_of_measure_id:
                otherOptions?.base_unit_of_measure?.unit_master_id,
            }
          : item
      ),
    });

    dispatch(actionClearProductsAllData({ setExportAll: false }));
  };

  const onOpenFilterModal = (index) => {
    setRowIndex(index);
    setRowId(null);
    setIsFilterModalOpen(true);
    dispatch(clearFilteredData());
    if (productCategoryData.length === 0) {
      dispatch(actionGetProductCategoryMaster());
    }
    if (allProductSubCategoryData.length === 0) {
      dispatch(actionGetAllProductSubCategoryMaster());
    }
    if (productTypeData.length === 0) {
      dispatch(actionGetProductTypeMaster());
    }
    if (customFields.length === 0) {
      dispatch(
        actionGetCustomFields({
          module_name: "Material",
        })
      );
    }

    materialForm.resetFields();
  };

  const columns = (fields, remove) => [
    {
      title: <span className="text-[14px]">Material</span>,
      dataIndex: "material_master_id",
      align: "middle",
      width: "33%",
      render: (_, record, index) => (
        <Form.Item
          className="!mb-0"
          rules={[{ required: true, message: "Please select material" }]}
          name={[index, "material_master_id"]}
        >
          <div className="flex items-center gap-x-2">
            <DebounceSelect
              options={products}
              allowClear
              showSearch
              value={form.getFieldValue(["items", index, "material_master_id"])}
              onChange={(value) => {
                const currentItems = form.getFieldValue("items") || [];
                form.setFieldsValue({
                  items: currentItems.map((item, i) =>
                    i === index ? { ...item, material_master_id: value } : item
                  ),
                });
              }}
              rowId={rowId}
              placeholder="Select Material"
              onSelect={(params, option) => {
                handleSelectMaterial(option, index);
              }}
            />

            <div
              onClick={() => onOpenFilterModal(index)}
              className="border w-12 border-[#d4d4d4] cursor-pointer rounded-[5px] flex items-center justify-center min-h-9 bg-white"
            >
              <CiFilter className="size-5 text-[#959595]" />
            </div>
          </div>
        </Form.Item>
      ),
    },
    {
      title: <span className="text-[14px]">Qty</span>,
      dataIndex: "quantity",
      width: "28%",
      render: (_, record, index) => (
        <Form.Item
          className="!mb-0"
          rules={[{ required: true, message: "Please enter quantity" }]}
          name={[index, "quantity"]}
        >
          <Input allowClear placeholder="Quantity" />
        </Form.Item>
      ),
    },
    {
      title: <span className="text-[14px]">Unit</span>,
      dataIndex: "unit_of_measure_id",
      align: "middle",
      width: "28%",
      render: (_, record, index) => (
        <Form.Item
          className="!mb-0"
          rules={[{ required: true, message: "Please select unit" }]}
          name={[index, "unit_of_measure_id"]}
        >
          <Select
            allowClear
            showSearch
            loading={getUnitMasterLoader}
            disabled={getUnitMasterLoader}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={unitOptions}
            placeholder="Select Unit"
            style={{ width: "100%" }}
          />
        </Form.Item>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      fixed: "right",
      width: "10%",
      align: "middle",
      render: (_, record, index) => (
        <div
          onClick={() => remove(record.name)} // Remove the row
          className={`flex justify-center items-center gap-2 cursor-pointer ${
            fields.length === 1 ? "opacity-50 pointer-events-none" : ""
          }`}
        >
          <img src={delteIcon} alt="Delete" className="cursor-pointer" />
        </div>
      ),
    },
  ];

  const onFinish = (values) => {
    const req = {
      ...values,
      items: values.items.map((item) => ({
        ...item,
        quantity: parseFloat(item.quantity),
        material_master_id:
          typeof item.material_master_id == "object"
            ? item?.material_master_id?.value
            : item.material_master_id,
      })),
    };

    if (isDuplicate) {
      dispatch(actionAddSalesOrder({ req, navigate }));
    } else if (salesOrderId && !isDuplicate) {
      dispatch(actionUpdateSalesOrder({ req, navigate, id: salesOrderId }));
    } else {
      dispatch(actionAddSalesOrder({ req, navigate }));
    }
  };

  const closeBusinessPartnerPopover = () => {
    setIsBusinessParterPopoverOpen(false);
    businessPartnerForm.resetFields();
  };

  const closeShipToPartyPopover = () => {
    setIsShipPopoverOpen(false);
    shipToPartyForm.resetFields();
  };

  const showBusinessPartnerContent = () => (
    <>
      <div className="min-w-[350px]">
        <Form
          form={businessPartnerForm}
          onFinish={(values) =>
            dispatch(
              actionAddBusinessPartner({
                req: values,
                form,
                handleCancel: closeBusinessPartnerPopover,
                field: "customer_business_partner_id",
              })
            )
          }
          layout="vertical"
          className="mb-0"
        >
          <Form.Item
            name="business_partner_name"
            label="Business Partner Name"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please enter business partner name!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Business Partner Name"
              className="begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>
          <Form.Item
            name="business_partner_type_master_id"
            label="Business Partner Type"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please select business partner type!",
              },
            ]}
          >
            <Select
              allowClear
              showSearch
              placeholder="Select Business Partner Type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={businessPartnerTypesOptions}
              disabled={getBusinessPartnerTypeLoader}
              loading={getBusinessPartnerTypeLoader}
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={() => closeBusinessPartnerPopover()}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addBusinessPartnerLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );

  const showShipToPartyContent = () => (
    <>
      <div className="min-w-[350px]">
        <Form
          form={shipToPartyForm}
          onFinish={(values) =>
            dispatch(
              actionAddBusinessPartner({
                req: values,
                form,
                handleCancel: closeShipToPartyPopover,
                field: "ship_to_party_business_partner_id",
              })
            )
          }
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="business_partner_name"
            label="Business Partner Name"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please enter business partner name!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Business Partner Name"
              className="begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>
          <Form.Item
            name="business_partner_type_master_id"
            label="Business Partner Type"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please select business partner type!",
              },
            ]}
          >
            <Select
              allowClear
              showSearch
              placeholder="Select Business Partner Type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={businessPartnerTypesOptions}
              disabled={getBusinessPartnerTypeLoader}
              loading={getBusinessPartnerTypeLoader}
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={closeShipToPartyPopover}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addBusinessPartnerLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );

  // You can check if any filter values are set when the form values change
  const handleValuesChange = (changedValues, allValues) => {
    const {
      material_category_id,
      material_sub_category_id,
      material_type_id,
      search,
    } = allValues || {};

    const customFieldParams = (customFields || [])
      .filter((field) =>
        ["Checkbox", "Dropdown", "MultiSelect"].includes(field.field_type)
      )
      .reduce((acc, field) => {
        const fieldName = customFieldNameMapping[field.custom_field_id];
        const fieldValue = allValues[field.custom_field_id] || [];
        if (fieldValue.length > 0) {
          acc[fieldName] = fieldValue;
        }
        return acc;
      }, {});

    const noFiltersApplied =
      ![material_sub_category_id, material_category_id, material_type_id].some(
        (arr) => Array.isArray(arr) && arr.length > 0
      ) &&
      !search &&
      Object.keys(customFieldParams || {}).length === 0;

    setButtonDisabled(noFiltersApplied);
  };

  const handleApplyFilters = (values) => {
    const {
      material_category_id,
      material_sub_category_id,
      material_type_id,
      search,
    } = values || {};

    // Prepare custom fields
    const customFieldParams = customFields
      .filter((field) =>
        ["Checkbox", "Dropdown", "MultiSelect"].includes(field.field_type)
      )
      .reduce((acc, field) => {
        const fieldName = customFieldNameMapping[field.custom_field_id];
        const fieldValue = values[field.custom_field_id] || [];

        if (fieldValue.length > 0) {
          acc[fieldName] = fieldValue;
        }

        return acc;
      }, {});

    const noFiltersApplied =
      ![material_sub_category_id, material_category_id, material_type_id].some(
        (arr) => Array.isArray(arr) && arr.length > 0
      ) &&
      !search &&
      Object.keys(customFieldParams || {}).length === 0;

    if (!noFiltersApplied) {
      setProducts([]);
      offsetRef.current = 0;
      setHasMore(true);
      appendData(true);
    }
  };

  const onFilterModalClose = () => {
    setIsFilterModalOpen(false);
    setRowIndex(null);
    setProducts([]);
    setHasMore(false);
    setLoading(false);
    setOffset(0);
  };

  const onSelectMaterial = async () => {
    const currentItems = (await form.getFieldValue("items")) || [];

    const selectedProduct =
      (await products.find((data) => data.material_master_id === rowId)) || {};

    if (!selectedProduct) {
      console.error("No product found for the given rowId:", rowId);
      return;
    }

    form.setFieldsValue({
      items: form.getFieldsValue().items.map((item, i) =>
        i === rowIndex
          ? {
              ...item,
              material_master_id: selectedProduct.material_master_id,
              unit_of_measure_id:
                selectedProduct?.base_unit_of_measure?.unit_master_id,
            }
          : item
      ),
    });

    onFilterModalClose();
  };

  return (
    <section
      className={`main-wrapper ${
        getSalesOrderDetailLoader ? "pointer-events-none opacity-75" : ""
      }`}
    >
      <div className="mb-4">
        <CustomIconText backArrow={true} />
      </div>
      <Form
        className={`${false ? "pointer-events-none opacity-70" : ""}`}
        scrollToFirstError
        form={form}
        layout="vertical"
        name="basic"
        initialValues={{
          items: [{}],
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Card
          title={
            <div className="flex items-center gap-x-3">
              <Image height={22} width={22} src={addSoIcon} preview={false} />{" "}
              <p
                className="text-brand text-[18px] !font-popinsRegular"
                style={{
                  fontWeight: 400,
                }}
              >
                {isDuplicate && salesOrderId
                  ? "Create"
                  : salesOrderId
                  ? "Update"
                  : "Create"}{" "}
                Sales Order
              </p>
            </div>
          }
          // loading={getSalesOrderDetailLoader}
          className="main-card mt-5"
        >
          {getSalesOrderDetailLoader ? (
            <SkeletonUpdateCard length={3} />
          ) : (
            <Row gutter={24}>
              <Col {...gridSettings}>
                <AddPopover
                  content={showBusinessPartnerContent}
                  isOpen={isBusinessParterPopoverOpen}
                  onOpenChange={(visible) => {
                    if (!visible) {
                      setIsBusinessParterPopoverOpen(false);
                      businessPartnerForm.resetFields();
                    }
                  }}
                  text="Add New"
                  showModal={() => {
                    setIsBusinessParterPopoverOpen(
                      !isBusinessParterPopoverOpen
                    );
                    addNewClick();
                  }}
                />
                <Form.Item
                  label="Business Partner / Customer"
                  name="customer_business_partner_id"
                  rules={[
                    {
                      required: true,
                      message: "Please select business partner!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select Business Partner"
                    optionFilterProp="children"
                    className=""
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={businessPartnerOptions}
                    disabled={getAllBusinessPartnerLoader}
                    loading={getAllBusinessPartnerLoader}
                  />
                </Form.Item>
              </Col>
              <Col {...gridSettings}>
                <AddPopover
                  content={showShipToPartyContent}
                  isOpen={isShipPopoverOpen}
                  onOpenChange={(visible) => {
                    if (!visible) {
                      setIsShipPopoverOpen(false);
                      shipToPartyForm.resetFields();
                    }
                  }}
                  text="Add New"
                  showModal={() => {
                    setIsShipPopoverOpen(!isShipPopoverOpen);
                    addNewClick();
                  }}
                />
                <Form.Item
                  label="Ship To Party"
                  name="ship_to_party_business_partner_id"
                  rules={[
                    {
                      required: true,
                      message: "Please select ship to party!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select Ship To Party"
                    optionFilterProp="children"
                    className=""
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={businessPartnerOptions}
                    disabled={getAllBusinessPartnerLoader}
                    loading={getAllBusinessPartnerLoader}
                  />
                </Form.Item>
              </Col>
              <Col {...gridSettings}>
                <Form.Item
                  label="Plant Code"
                  name="plant_code_master_id"
                  rules={[
                    {
                      required: true,
                      message: "Please select plant code!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select Plant Code"
                    optionFilterProp="children"
                    className=""
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={plantCodeOptions}
                    disabled={getAllPlantCodesLoader}
                    loading={getAllPlantCodesLoader}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Note" name="note">
                  <Input.TextArea rows={2} placeholder="Enter Note" />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Card>
        <Card loading={getSalesOrderDetailLoader} className="main-card mt-5 ">
          <div className="flex items-center gap-x-2">
            <Image
              height={18}
              width={18}
              src={itemsIcon}
              preview={false}
              alt="Items Icon"
            />
            <p className="text-[#2B2526] text-lg">
              Items <span className="text-red-500 ml-0">*</span>
            </p>
          </div>

          <ConfigProvider
            theme={{
              components: {
                Table: {
                  cellPaddingBlock: 10,
                },
                Select: { controlHeight: 30, algorithm: true },
              },
            }}
          >
            <Form.List name="items">
              {(fields, { add, remove }) => (
                <>
                  <Table
                    columns={columns(fields, remove)}
                    dataSource={fields}
                    pagination={false}
                    bordered
                    className="mt-4 sales-orders-table"
                    scroll={{ x: "max-content", y: 400 }}
                  />

                  <div
                    onClick={() => add()}
                    className={`inline-flex mt-5 cursor-pointer  items-center gap-x-2 ${
                      false || false ? "opacity-50 pointer-events-none" : ""
                    }`}
                  >
                    <Image
                      preview={false}
                      src={addButtonWhite}
                      height={20}
                      width={20}
                      alt="add-button-white"
                    />
                    <span className="text-brand text-base">Add New</span>
                  </div>
                </>
              )}
            </Form.List>
          </ConfigProvider>

          <div className="flex items-center gap-x-5 py-3 justify-end">
            <Button
              onClick={() => navigate(-1)}
              type="button"
              className="gray-button text-white hover:text-white min-h-[40px] min-w-[120px]"
            >
              Cancel
            </Button>
            <Form.Item className="mb-0">
              <Button
                loading={addSalesOrderLoader || updateSalesOrderLoader}
                type="primary"
                className="root-btn min-h-[40px] min-w-[120px]"
                htmlType="submit"
              >
                {salesOrderId && !isDuplicate ? "Update" : "Save"}
              </Button>
            </Form.Item>
          </div>
        </Card>
      </Form>

      <Modal
        className="ant-custom-scroll"
        centered
        maskClosable={false}
        onOk={onFilterModalClose}
        onCancel={onFilterModalClose}
        footer={null}
        width={1000}
        open={isFilterModalOpen}
        afterClose={onFilterModalClose}
      >
        <Form
          form={materialForm}
          onValuesChange={handleValuesChange}
          onFinish={handleApplyFilters}
          className="mb-0"
        >
          <div className="flex gap-x-2 sales-orders-filter-container ">
            <aside className="w-72 border py-1 min-h-[600px] overflow-hidden px-2 rounded-lg flex pb-2 flex-col border-[#d4d4d4]">
              <div className="flex items-center justify-between">
                <h1 className="text-[#6883FD] text-[20px] my-[5px] px-[5px] font-medium">
                  Filters
                </h1>
                <p
                  onClick={() => {
                    materialForm.resetFields();
                    setButtonDisabled(true);
                  }}
                  className={`text-brand text-sm ${
                    buttonDisabled ? "cursor-not-allowed" : "cursor-pointer"
                  }`}
                >
                  Clear all
                </p>
              </div>
              <div className="custom-scroll-barr flex-1 overflow-y-auto">
                <Spin spinning={getCustomFieldsLoader}>
                  <Collapse ghost className="sales-order-filters">
                    <Collapse.Panel header="Material Category" key="1">
                      <Form.Item name="material_category_id">
                        <Checkbox.Group
                          options={categoryOptions}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px 0 ",
                          }}
                        />
                      </Form.Item>
                    </Collapse.Panel>
                    <Collapse.Panel header="Material Sub Category" key="2">
                      <Form.Item name="material_sub_category_id">
                        <Checkbox.Group
                          options={subCategoryOptions}
                          style={{ display: "flex", flexDirection: "column" }}
                        />
                      </Form.Item>
                    </Collapse.Panel>
                    {/* material type */}
                    <Collapse.Panel header="Material Type" key="3">
                      <Form.Item name="material_type_id">
                        <Checkbox.Group
                          options={materialTypeOptions}
                          style={{ display: "flex", flexDirection: "column" }}
                        />
                      </Form.Item>
                    </Collapse.Panel>
                    {Array.isArray(customFields) &&
                      customFields.some(
                        (item) =>
                          item.field_type === "Checkbox" ||
                          item.field_type === "Dropdown" ||
                          item.field_type === "MultiSelect"
                      ) &&
                      customFields.map(
                        (item, index) =>
                          (item.field_type === "Checkbox" ||
                            item.field_type === "Dropdown" ||
                            item.field_type === "MultiSelect") && (
                            <Collapse.Panel
                              header={item.label || item.field_name}
                              key={item.custom_field_id}
                            >
                              <Form.Item name={item.custom_field_id}>
                                <Checkbox.Group
                                  options={item.field_options?.map(
                                    (option) => ({
                                      label: option,
                                      value: option,
                                    })
                                  )}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                />
                              </Form.Item>
                            </Collapse.Panel>
                          )
                      )}
                  </Collapse>
                </Spin>
              </div>

              <div className="flex justify-start   px-2 min-h-[55px] items-center bg-white mt-auto z-50">
                <div className="ml-0">
                  <Button
                    disabled={buttonDisabled}
                    className="root-btn min-h-[40px] min-w-[120px]"
                    htmlType="submit"
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </aside>

            <main className="flex-1 px-4  flex flex-col ">
              <h4 className="text-[18px] text-brand font-popinsMedium">
                Select Material
              </h4>
              <Form.Item name="search" className="mb-0">
                <Input
                  onChange={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value.trim() === "") {
                      materialForm.submit();
                    }
                  }}
                  onPressEnter={() => {
                    if (search) {
                      materialForm.submit();
                    }
                  }}
                  placeholder="Search and Press Enter"
                  className="min-h-[35px] bg-white material-search-input"
                />
              </Form.Item>

              <List className="sales-order-list max-h-[600px] overflow-hidden">
                {products && products.length === 0 && !loading ? (
                  <Empty description="No Data Available" />
                ) : loading && products.length === 0 ? (
                  Array.from({ length: 10 }).map((_, index) => (
                    <Skeleton.Button
                      key={index}
                      className="my-1"
                      title={false}
                      active
                      block
                    />
                  ))
                ) : (
                  <>
                    <VirtualList
                      data={products}
                      height={loading ? ContainerHeight : ContainerHeight}
                      itemHeight={40}
                      itemKey="material_master_id"
                      onScroll={onScroll}
                    >
                      {(item) => (
                        <List.Item key={item.material_master_id}>
                          <div
                            onClick={() => setRowId(item.material_master_id)}
                            key={item.material_master_id}
                            className={`border w-full flex items-center min-h-[40px] rounded-[5px] px-3 cursor-pointer ${
                              rowId === item.material_master_id
                                ? "border-brand border-[1.5px]"
                                : "border-[#DDDAE9]"
                            }`}
                          >
                            <Typography.Paragraph
                              ellipsis={{ rows: 1 }}
                              className="text-black text-sm !mb-0"
                            >
                              {item.material_name}
                            </Typography.Paragraph>
                          </div>{" "}
                        </List.Item>
                      )}
                    </VirtualList>
                    {loading && hasMore && products.length > 0 && (
                      <div
                        style={{
                          maxHeight: 50,
                          marginTop: 10,
                        }}
                      >
                        <Skeleton.Button active block />
                      </div>
                    )}
                  </>
                )}

                {!hasMore && products.length > 0 && (
                  <Divider plain>No more items available at this time.</Divider>
                )}
              </List>
              <div className="flex  ml-auto  min-h-[55px] items-end bg-white mt-auto z-50">
                <Button
                  type="button"
                  onClick={onFilterModalClose}
                  className="gray-button text-white  hover:text-white min-h-[40px] min-w-[120px]"
                >
                  Cancel
                </Button>

                <div className="ml-4">
                  <Button
                    className="root-btn !disabled:bg-slate-500 min-h-[40px] min-w-[120px]"
                    disabled={!rowId}
                    onClick={onSelectMaterial}
                  >
                    Select
                  </Button>
                </div>
              </div>
            </main>
          </div>
        </Form>
      </Modal>
    </section>
  );
};

export default AddSalesOrder1;
