import { Component, useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Image } from "antd";
import rightAngle from "../../assets/img/right-angle.svg";
import leftAngle from "../../assets/img/left-angle.svg";
import logo from "../../assets/img/logo.svg";
import { currentYear } from "./common-function";
import { useSelector } from "react-redux";
import { AuthProvider } from "../../context/AuthContext";

export const PrivateRoute = ({ component }) => {
  const token = localStorage.getItem("crmWebToken");
  const { user, userLoader } = useSelector((state) => state.auth);

  if (!token || (!userLoader && !user)) {
    localStorage.removeItem("crmWebToken");
    return <Navigate to="/login" replace={true} />;
  }

  return component;
};

export const PublicRoute = () => {
  const token = localStorage.getItem("crmWebToken");
  const { user } = useSelector((state) => state.auth);

  const location = useLocation();

  const { from } = location.state || {};


  if (token && user && !from) {
    return <Navigate to={"/"} replace={true} />;
  }

  const width =
    location.pathname === "/otp"
      ? "2xl:max-w-2xl  max-w-xl w-full"
      : location.pathname === "/create-account"
        ? "2xl:max-w-[49rem] lg:max-w-[100%]  w-full"
        : "2xl:max-w-xl  max-w-lg w-full";

  return (
    <AuthProvider>
      <div className="min-h-screen relative pt-3 flex justify-between flex-col w-full h-full">
        <img
          src={rightAngle}
          className="absolute z-0 right-0 bottom-0 md:block hidden"
          alt="right-angle"
        />
        <img
          src={leftAngle}
          className="absolute z-0 left-0 top-0 md:block hidden"
          alt="left-angle"
        />

        <div className="flex  lg:pt-10 pt-3  flex-col items-center justify-center">
          <Image
            className="text-center mx-auto "
            preview={false}
            height={50}
            src={logo}
            alt="Logo"
          />
          <p className="lg:text-2xl md:text-xl text-base lg:py-2 md:py-2 py-2 text-[#676767]">
            Begin your free account today.
          </p>
        </div>

        {/* Content Start Here */}
        <section
          className={`flex-1 relative lg:py-12 md:py-10 py-5  px-4 ${width} mx-auto`}
        >
          <Outlet />
        </section>
        {/* Content ends Here */}

        <footer className="lg:text-base  h-[30%] py-10 pb-5  text-center md:text-sm text-sm whitespace-nowrap  ">
          © {currentYear}, CRM Harbor Pvt. Ltd. All Rights Reserved.
        </footer>
      </div>
    </AuthProvider>
  );
};
