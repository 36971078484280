import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { Dropdown, Image, Modal, Pagination, Skeleton, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { RiDownloadLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import attatchmentIcon from "../../assets/img/attatchment-icon.svg";
import CollectionManagementIcon from "../../assets/img/collection-management-primary.svg";
import receipt from "../../assets/img/payment-receipt.svg";
import { actionDownloadFile } from "../../store/services/commonService";
import {
  actionClearAllCollectionManagement,
  actionDeleteCollectionManagement,
  actionGetAllCollectionManagement,
  actionGetCollectionManagement,
  actionGetCollectionManagementDetails,
} from "../../store/services/financeService";
import CustomInputWithButtons from "../common/CustomInputWithButtons ";
import DeleteModal from "../common/DeleteModal";
import PageTitle from "../common/PageTitle";
import SortIcon from "../common/SortIcon";
import {
  getFirstLetterCapital,
  renderFileIcon,
} from "../helper/common-function";
import dayjs from "dayjs";
import { handleActionWithPermission } from "../../store/slices/authSlice";
import SkeletonTable from "../common/SkeletonTable";
import CommonTable from "../common/CommonTable";
import CommonPagination from "../common/CommonPagination";

const CollectionManagement = () => {
  const offsetRef = useRef(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [exportAll, setExportAll] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [collectionId, setCollectionId] = useState("");
  const [lastSearch, setLastSearch] = useState("");
  const [pageSize, setPageSize] = useState(20);
  const { myCurrency } = useSelector((state) => state.auth);

  const {
    collectionManagementData,
    getCollectionManagementLoader,
    getAllCollectionManagementLoader,
    allCollectionManagements,
    allCollectionManagementCount,
    deleteCollectionManagementLoader,
    getCollectionManagementDetailLoader,
    collectionManagementDetails,
  } = useSelector((state) => state.finance);

  const getApiCall = (
    offset = 0,
    limit = pageSize,
    search = "",
    orderByColumn = "",
    order = ""
  ) => {
    dispatch(
      actionGetCollectionManagement({
        offset,
        limit,
        orderByColumn,
        order,
        search,
      })
    );
  };

  useEffect(() => {
    getApiCall();
  }, [dispatch]);

  const urlParams = new URLSearchParams(location.search);
  const id = urlParams.get("id");

  useEffect(() => {
    if (id) {
      dispatch(actionGetCollectionManagementDetails(id));
      setIsModalOpen(true);
      setCollectionId(id);
    }
  }, [id, dispatch]);

  const columns = [
    {
      title: "CH No.",
      key: "ch_no",
      width: 100,
      dataIndex: "collection_management_id",
      fixed: "left",
      render: (text) => <div
        className="text-brand cursor-pointer"
        onClick={() => {
          dispatch(
            handleActionWithPermission(
              "collection-management",
              "read",
              () => {
                setIsModalOpen(!isModalOpen);
                setCollectionId(text);
                dispatch(
                  actionGetCollectionManagementDetails(
                    text
                  )
                );
              }
            )
          );
        }}
      >{text}
      </div>,
    },
    {
      title: "Payment Receipt No",
      dataIndex: "payment_number",
      key: "payment_number",
      width: 250,
      fixed: "left",
      render: (text, data) => (
        <span
          onClick={() => {
            dispatch(
              handleActionWithPermission(
                "collection-management",
                "read",
                () => {
                  setIsModalOpen(!isModalOpen);
                  setCollectionId(data?.collection_management_id);
                  dispatch(
                    actionGetCollectionManagementDetails(
                      data.collection_management_id
                    )
                  );
                }
              )
            );
          }}
          className="text-primary cursor-pointer"
        >
          {text}
        </span>
      ),
    },
    {
      title: "Date",
      sorter: true,
      sortIcon: () => <SortIcon />,
      dataIndex: "payment_date",
      key: "payment_date",
      width: 200,
      render: (text, data) => (
        <span>
          {data.payment_date
            ? dayjs(data.payment_date).format("DD-MM-YYYY")
            : "-"}
        </span>
      ),
    },
    {
      title: "Business Partner",
      dataIndex: ["business_partner", "business_partner_name"],
      sorter: true,
      sortIcon: () => <SortIcon />,
      key: "business_partner",
      width: 250,
      ellipsis: true,
    },
    {
      title: myCurrency ? `Amount (${myCurrency?.currency})` : "Amount",
      dataIndex: "amount_received",
      key: "amount_received",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 200,
      render: (text) =>
        myCurrency ? `${myCurrency?.currency_symbol}${text}` : text,
    },
    {
      title: `Bank Charges`,
      dataIndex: "bank_charges",
      key: "bank_charges",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 200,
      render: (text) => (text ? myCurrency?.currency_symbol + text : "-"),
    },
    {
      title: "Deposited Via",
      dataIndex: ["payment_mode", "payment_method"],
      sorter: true,
      sortIcon: () => <SortIcon />,
      key: "payment_mode",
      width: 200,
      ellipsis: true,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Deposited In",
      dataIndex: ["deposit_to", "name"],
      sorter: true,
      sortIcon: () => <SortIcon />,
      key: "deposit_to",
      width: 200,
      ellipsis: true,
      render: (text) => (text ? getFirstLetterCapital(text) : "-"),
    },
    {
      title: "Payment Reference No",
      dataIndex: "reference",
      key: "reference",
      width: 200,
      ellipsis: true,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Notes",
      dataIndex: "notes",
      width: 200,
      ellipsis: true,
      key: "notes",
      render: (text) => (text ? text : "-"),
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 100,
      align: "center",
      fixed: "right",
      render: (text, data) => (
        <>
          <Dropdown
            id="custom-drop"
            menu={{
              items: [
                {
                  label: <span>Edit</span>,
                  key: "Edit",
                  icon: <EditOutlined />,
                  onClick: () => {
                    dispatch(
                      handleActionWithPermission(
                        "collection-management",
                        "update",
                        () =>
                          navigate("/update-collection-management", {
                            state: {
                              collectionId: data.collection_management_id,
                            },
                          })
                      )
                    );
                  },
                },
                {
                  label: "Delete",
                  icon: <DeleteOutlined />,
                  danger: true,
                  key: "Delete",
                  onClick: () =>
                    dispatch(
                      handleActionWithPermission(
                        "collection-management",
                        "delete",
                        () => {
                          setIsDeleteModalOpen((prev) => !prev);
                          setCollectionId(data?.collection_management_id);
                        }
                      )
                    ),
                },
              ],
            }}
          >
            <div className=" p-2 w-[35px] ">
              <div className="flex justify-center items-center">
                <EllipsisOutlined />
              </div>{" "}
            </div>
          </Dropdown>
        </>
      ),
    },
  ];

  const handleRefresh = () => {
    offsetRef.current = 0;
    getApiCall(offsetRef.current, pageSize, search);
  };

  useEffect(() => {
    const trimmedSearch = search?.trim();
    if (trimmedSearch !== "" && trimmedSearch !== lastSearch) {
      const timer = setTimeout(() => {
        getApiCall(offsetRef.current, pageSize, trimmedSearch, "", "");
        setLastSearch(trimmedSearch);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [search, lastSearch]);

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      // Call Action
      getApiCall(offsetRef.current, pageSize, e.target.value, "", "");
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      getApiCall(offsetRef.current, pageSize, e.target.value, "", "");
    }
  };

  const onClickDownloadMenu = () => {
    dispatch(actionGetAllCollectionManagement());
    setExportAll(true);
  };

  const exportAllToExcel = async () => {
    const data =
      (await allCollectionManagements) &&
      allCollectionManagements.length > 0 &&
      allCollectionManagements.map((data) => ({
        "Payment Receipt No.": data?.payment_number
          ? data?.payment_number
          : "-",
        "Payment Date": data.payment_date ? data.payment_date : "-",
        "Business Partner": data.business_partner
          ? data.business_partner?.business_partner_name
          : "-",
        Amount: data.amount_received ? data.amount_received : "-",
        "Deposited Via": data.payment_mode ? data.payment_mode?.name : "-",
        "Deposited In": data.deposit_to ? data.deposit_to?.payment_method : "-",
        Reference: data.reference ? data.reference : "-",
        Notes: data.notes ? data.notes : "-",
      }));
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Collection Management");
    // Generate the Excel file and initiate the download
    XLSX.writeFile(workbook, "collection-management.xlsx");
    dispatch(actionClearAllCollectionManagement({ setExportAll }));
  };

  useEffect(() => {
    if (
      exportAll &&
      allCollectionManagements &&
      allCollectionManagements.length > 0
    ) {
      exportAllToExcel();
    }
  }, [allCollectionManagements]);

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.columnKey,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
            ? "ASC"
            : "",
    };

    getApiCall(
      offsetRef.current,
      pageSize,
      search,
      sorterParams.field,
      sorterParams.order
    );
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setCollectionId("");
  };

  function formatAmountWithCommas(amount) {
    // Ensure the input is a number and format it with commas
    return parseFloat(amount).toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  const handlePaginationChange = (page, pageSize) => {
    offsetRef.current = page - 1;
    setPageSize(pageSize);
    getApiCall(offsetRef.current, pageSize, search);
  };

  return (
    <>
      <section className="main-wrapper main-table-container ">
        <CommonTable
          moduleKey="collection-management"
          title="Users"
          total={allCollectionManagementCount}
          pageSize={pageSize}
          pageTitle={
            <PageTitle
              image={CollectionManagementIcon}
              title="Collection Management"
              rightIcon={false}
              margin={false}
            />
          }
          buttonText="Record Payment"
          buttonLink="/add-collection-management"
          resource="collection-management"
          loading={
            getCollectionManagementLoader || getAllCollectionManagementLoader
          }
          columns={columns}
          scrollToFirstRowOnChange={true}
          data={collectionManagementData || []}
          rowKey={"collection_management_id"}
          onChange={handleSortChange}
          handleRefresh={handleRefresh}
          handleSearch={handleSearch}
          handleBlurAndPressEnter={handleBlurAndPressEnter}
          search={search}
          onClickDownloadMenu={onClickDownloadMenu}
          handlePaginationChange={handlePaginationChange}
        />
      </section>
      <Modal
        centered
        onOk={handleClose}
        afterClose={handleClose}
        onCancel={handleClose}
        footer={false}
        width={1000}
        title={
          <div className="text-brand flex items-center gap-x-3">
            <Image
              src={receipt}
              alt="image"
              height={20}
              width={20}
              preview={false}
            />

            <h1
              className=" text-base  font-popinsRegular"
              style={{ fontWeight: 500 }}
            >
              Payment Receipt
            </h1>
          </div>
        }
        open={isModalOpen}
      >
        <div className="grid lg:grid-cols-12 grid-cols-1 pt-4  gap-4">
          <div className="flex  flex-col  gap-y-3 py-2 justify-center lg:col-span-8 h-full">
            {getCollectionManagementDetailLoader ? (
              <>
                {Array.from({ length: 5 }).map((item) => (
                  <div className="flex items-center gap-x-4 w-full skeleten-div-div">
                    <Skeleton.Input
                      active
                      size="small"
                      // className="flex-1"
                      // width="30%"
                      className="skeleton-input"
                      style={{ height: "19px", paddingBottom: "3px" }}
                    />
                    <Skeleton.Input
                      active
                      size="small"
                      className="skeleton-input-sec"
                      // width="70%"
                      //className="flex-1"
                      style={{
                        height: "19px",
                        marginTop: "3px" /*  width: "70%" */,
                      }}
                    />
                  </div>
                ))}
              </>
            ) : (
              <>
                <div className="flex justify-between w-full lg:w-[75%] items-center">
                  <p className="">Payment Receipt No</p>
                  <p className="">
                    {collectionManagementDetails?.payment_number}
                  </p>
                </div>
                <div className="flex justify-between w-full lg:w-[75%] items-center">
                  <p className="">Payment Date</p>
                  <p className="">
                    {dayjs(collectionManagementDetails?.payment_date).format(
                      "DD-MM-YYYY"
                    )}
                  </p>
                </div>
                <div className="flex justify-between w-full lg:w-[75%] items-center">
                  {" "}
                  <p>Payment Reference Number</p>
                  <p>
                    {collectionManagementDetails?.reference
                      ? collectionManagementDetails?.reference
                      : "-"}
                  </p>
                </div>
                <div className="flex justify-between w-full lg:w-[75%] items-center">
                  {" "}
                  <p>Deposited Via</p>
                  <p>
                    {collectionManagementDetails?.payment_mode != null
                      ? collectionManagementDetails?.payment_mode
                        ?.payment_method
                      : "-"}
                  </p>
                </div>
                <div className="flex justify-between w-full lg:w-[75%] items-center">
                  {" "}
                  <p>Amount Received In Words</p>
                  <p>{collectionManagementDetails?.amount_received_in_words}</p>
                </div>
              </>
            )}

            <div className="pt-5">
              <div className="flex  items-center gap-x-2">
                <Image
                  src={attatchmentIcon}
                  className="text-[18px]"
                  alt="Icon"
                />

                <h1 className="text-lg text-primary underline">Attachments</h1>
              </div>
              <div className="flex items-start gap-y-3  flex-col  mt-3 max-h-[300px] overflow-x-hidden overflow-y-auto gray-scrollbar">
                {collectionManagementDetails.attachments &&
                  collectionManagementDetails.attachments.length > 0 ? (
                  collectionManagementDetails.attachments.map((att, index) => {
                    const sizeInBytes = att.upload.size;
                    const sizeInKB = sizeInBytes / 1024;
                    const sizeInMB = sizeInBytes / (1024 * 1024);
                    const displaySize =
                      sizeInMB >= 1
                        ? `${sizeInMB.toFixed(2)} MB`
                        : `${sizeInKB.toFixed(2)} KB`;
                    return (
                      <div
                        key={index}
                        className="flex items-center w-full gap-x-3"
                      >
                        {/* <Image
                          width={20}
                          height={20}
                          src={att?.upload.url}
                          alt="attatchment icon"
                        /> */}
                        {getCollectionManagementDetailLoader ? (
                          // Show skeleton when loading
                          <div
                            key={index}
                            className="flex items-center w-full gap-x-3"
                          >
                            {/* Skeleton for File Icon */}
                            <Skeleton.Avatar
                              active
                              size="small"
                              shape="square"
                              className="flex-shrink-0"
                              style={{ marginRight: "10px" }}
                            />

                            {/* Skeleton for Filename and File Size */}
                            <div className="flex flex-col gap-y-0 max-w-[87%]">
                              <Skeleton.Input
                                active
                                size="small"
                                className="text-primary text-[13px] truncate"
                                style={{ width: "100%", height: "20px" }}
                              />
                              <Skeleton.Input
                                active
                                size="small"
                                className="text-[11px] text-[#7C7C7C]"
                                style={{
                                  width: "80px",
                                  height: "16px",
                                  marginTop: "5px",
                                }}
                              />
                            </div>

                            {/* Skeleton for Download Icon */}
                            <Skeleton.Button
                              active
                              size="small"
                              style={{
                                width: "24px",
                                height: "24px",
                                marginLeft: "auto",
                              }}
                            />
                          </div>
                        ) : (
                          // Show actual content once loaded
                          <div
                            key={index}
                            className="flex items-center w-full gap-x-3"
                          >
                            {/* Render File Icon */}
                            {renderFileIcon(att?.upload.url)}

                            {/* Display Filename and File Size */}
                            <div className="flex flex-col gap-y-0 max-w-[87%]">
                              <a
                                href={att?.upload.url}
                                target="_blank"
                                className="text-primary text-[13px] truncate"
                              >
                                {att?.upload?.filename}
                              </a>
                              <span className="text-[11px] text-[#7C7C7C]">
                                File Size: {displaySize}
                              </span>
                            </div>

                            {/* Download Icon */}
                            <RiDownloadLine
                              onClick={() =>
                                dispatch(actionDownloadFile(att?.upload))
                              }
                              className="ml-auto cursor-pointer"
                              size={18}
                              color="#6883fd"
                            />
                          </div>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <p>No Attachments Found</p>
                )}
              </div>
            </div>
          </div>

          {getCollectionManagementDetailLoader ? (
            <Skeleton.Button
              active
              size="large"
              style={{
                height: "80px",
                width: "270px",
                borderRadius: "8px",
                marginLeft: "12px",
              }}
            />
          ) : (
            <div className="flex flex-col items-center justify-center lg:col-span-4 max-h-[100px] p-3 rounded-[10px] bg-[#15A02B] text-white">
              <p className="text-[15px]">Amount Received</p>
              <p className="text-xl font-popinsMedium">
                {`${myCurrency?.currency_symbol}${formatAmountWithCommas(
                  collectionManagementDetails?.amount_received
                )}`}
              </p>
            </div>
          )}
        </div>
      </Modal>

      <DeleteModal
        title="Are you sure you want to remove this ?"
        description="All the associations will be removed for this Collection"
        loading={deleteCollectionManagementLoader}
        onCancel={() => {
          setIsDeleteModalOpen(false);
          setCollectionId("");
        }}
        onConfirm={() =>
          dispatch(
            actionDeleteCollectionManagement({
              collectionId,
              setIsDeleteModalOpen,
            })
          )
        }
        isVisible={isDeleteModalOpen}
      />
    </>
  );
};

export default CollectionManagement;
