import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import plusIcon from "../../../assets/img/add-button.svg";
import deletevent from "../../../assets/img/deleteevent.svg";
import editedevent from "../../../assets/img/editedevent.svg";
import editevent from "../../../assets/img/editevent.svg";
import {
  actionAddEventCategoryMaster,
  actionDeleteEventCategoryMaster,
  actionEditEventCategoryMaster,
  actionGetEventCategoryMaster,
  actionGetSingleEventCategoryMaster,
} from "../../../store/services/masterDataService";
import SortIcon from "../../common/SortIcon";
import { isValidSearchString } from "../../helper/common-function";
import MasterSearchbar from "../../common/MasterSearchbar";
import * as XLSX from "xlsx";
import PageTitleForMasterData from "../../common/PageTitleForMasterData";
import usePermission from "../../../hooks/use-permission";
import { handleActionWithPermission } from "../../../store/slices/authSlice";
import SkeletonTable from "../../common/SkeletonTable";
import MasterCommonTable from "../../common/MasterCommonTable";

const EventCategory = () => {
  const [form] = Form.useForm();
  const [editableForm] = Form.useForm();

  const dispatch = useDispatch();
  const {
    eventCategoryData,
    getEventCategoryLoader,
    getSingleEventCategoryLoader,
    addEventCategoryLoader,
    deleteEventCategoryLoader,
    updateEventCategoryLoader,
    getSingleEventCategoryData,
  } = useSelector((state) => state.masterData);
  const [editingKey, setEditingKey] = useState("");
  const [search, setSearch] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [edited, setEdited] = useState(false);
  const [columnKey, setColumnKey] = useState(null);
  const [lastSearch, setLastSearch] = useState('');
  const { hasPermission } = usePermission();

  const isEditing = (record) => record?.event_category_master_id === editingKey;

  useEffect(() => {
    dispatch(actionGetEventCategoryMaster({ search }));
    // return () => setEditingKey("");
  }, []);

  useEffect(() => {
    const trimmedSearch = search?.trim();
    if (trimmedSearch !== "" && trimmedSearch !== lastSearch) {
      const timer = setTimeout(() => {
        dispatch(
          actionGetEventCategoryMaster({ search: trimmedSearch })
        );
        setLastSearch(trimmedSearch);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }

  }, [search, lastSearch]);

  const handleSearch = (e) => {
    const value = e.target.value;
    if (isValidSearchString(value)) {
      setSearch(value);
      if (value === "") {
        dispatch(
          actionGetEventCategoryMaster({
            search: value,
          })
        );
      }
    }
  };

  const handleBlurAndPressEnter = (e) => {
    const value = e.target.value;
    if (search && isValidSearchString(value)) {
      dispatch(
        actionGetEventCategoryMaster({
          search: value,
        })
      );
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === "number" ? <Input /> : <Input />;
    return (
      <td {...restProps} className="input-height">
        {editing ? (
          <Form.Item
            className="mb-0 editable-order"
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                // message: `${title} is required.`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          <Typography.Paragraph
            style={{ marginBottom: 0 }}
            ellipsis={{ rows: 1, expandable: false, tooltip: true }}
            className="px-[11px]"
          >
            {children}
          </Typography.Paragraph>
        )}
      </td>
    );
  };

  useEffect(() => {
    if (!editingKey) {
      setEdited(false);
    }
  }, [editingKey]);

  const edit = (record) => {
    dispatch(
      actionGetSingleEventCategoryMaster(record?.event_category_master_id)
    );
    setEdited(!edited);
  };

  useEffect(() => {
    if (getSingleEventCategoryData && edited) {
      editableForm.setFieldsValue({
        event_category: getSingleEventCategoryData.event_category,
      });
      setEditingKey(getSingleEventCategoryData.event_category_master_id);
    }
  }, [getSingleEventCategoryData]);

  const save = async (id) => {
    try {
      const row = await editableForm.validateFields();
      const { event_category } = row;
      const req = { event_category };
      dispatch(actionEditEventCategoryMaster({ id, req, setEditingKey }));
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "SR No.",
      dataIndex: "srno",
      key: "srno",
      width: 70,
      fixed: "left",
      editable: false,
      render: (text, record, index) => index + 1,
    },

    {
      title: "Event Category Name",
      dataIndex: "event_category",
      key: "event_category",
      width: 400,
      editable: true,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
    },

    {
      title: "Action",
      width: 100,
      key: "action",
      fixed: "right",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <div className="flex items-center ">
            {editable ? (
              <div className="flex items-center">
                {updateEventCategoryLoader[record.event_category_master_id] ? (
                  <LoadingOutlined />
                ) : (
                  <Typography.Link
                    onClick={() => save(record.event_category_master_id)}
                  >
                    <img src={editedevent} alt="" />
                  </Typography.Link>
                )}
              </div>
            ) : (
              <div className="flex items-center gap-x-2 justify-center">
                <Typography.Link
                  disabled={editingKey !== ""}
                  onClick={() => {
                    dispatch(
                      handleActionWithPermission(
                        "event-category-master",
                        "update",
                        () => edit(record)
                      )
                    );
                  }}
                >
                  <img src={editevent} alt="" />
                </Typography.Link>
              </div>
            )}
            <div className="ml-5">
              {hasPermission("event-category-master", "delete") ? (
                <Popconfirm
                  okButtonProps={{
                    loading:
                      deleteEventCategoryLoader[
                      record?.event_category_master_id
                      ],
                  }}
                  description="Are you sure you want to delete?"
                  onConfirm={() =>
                    dispatch(
                      actionDeleteEventCategoryMaster({
                        id: record?.event_category_master_id,
                        setEditingKey,
                      })
                    )
                  }
                  onOpenChange={() => { }}
                >
                  <img src={deletevent} alt="" className="cursor-pointer" />
                </Popconfirm>
              ) : (
                <img
                  src={deletevent}
                  alt=""
                  className="cursor-pointer"
                  onClick={() => {
                    dispatch(
                      handleActionWithPermission(
                        "event-category-master",
                        "delete"
                      )
                    );
                  }}
                />
              )}
            </div>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      sortIcon: () => col.sorter ? getEventCategoryLoader && col.key === columnKey ? <LoadingOutlined /> : <SortIcon /> : null,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "srno" ? "event_category_name" : "",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleSortChange = (pagination, filters, sorter) => {
    setColumnKey(sorter.columnKey)

    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
            ? "ASC"
            : "",
    };

    dispatch(
      actionGetEventCategoryMaster({
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
  };

  const getData = async (data) => {
    const newData =
      (await data) &&
      data.length > 0 &&
      data.map((d) => ({
        "Event Category": d.event_category ? d.event_category : "-",
      }));
    return newData;
  };

  const exportAllToExcel = async () => {
    if (
      !eventCategoryData ||
      (eventCategoryData && eventCategoryData.length === 0)
    ) {
      return;
    }
    const data = await getData(eventCategoryData);
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Event Categories");
    XLSX.writeFile(workbook, "event-categories.xlsx");
  };

  useEffect(() => {
    console.log(columnKey)
  }, [columnKey])


  return (
    <div className="event-input">
      <PageTitleForMasterData
        title="Event Categories"
        buttonText="Add Event Category"
        onButtonClick={showModal}
        resource="event-category-master"
      />
      <Modal
        centered
        // destroyOnClose={() => form.resetFields()}
        onOk={() => handleCancel()}
        onCancel={() => handleCancel()}
        afterClose={() => form.resetFields()}
        footer={false}
        // className="-model"
        width={500}
        title=""
        open={isModalOpen}
      >
        <h1 className="text-[#6883FD] text-[20px]  mt-[5px] font-medium">
        Event Category
        </h1>
        <Form
          form={form}
          onFinish={(values) =>
            dispatch(actionAddEventCategoryMaster({ values, setIsModalOpen }))
          }
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="event_category"
            label="Event Category Name"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please enter event category name!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Category Name"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-center mt-[40px]">
            <Form.Item className="mb-0">
              <Button
                onClick={handleCancel}
                type="button"
                className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addEventCategoryLoader}
                  type="primary"
                  className="root-btn min-h-[45px] min-w-[120px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
      <MasterCommonTable
       moduleKey="event-categories"
        columns={mergedColumns}
        dataSource={eventCategoryData}
        rowKey="event_category_master_id"
        isLoading={getEventCategoryLoader}
        EditableCell={EditableCell}
        onTableChange={handleSortChange}
        editableForm={editableForm}
        searchBarProps={{
          handleSearch,
          handleBlurAndPressEnter,
          isImportEnabled: false,
          search,
          onClickDownloadMenu: exportAllToExcel,
        }}
        columnKey={columnKey}
      />
    </div>
  );
};

export default EventCategory;
