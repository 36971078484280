

import { Space, Typography, Image, Card } from 'antd'
import logo from '../../assets/img/logo.svg';
import playStore from '../../assets/img/play-store.svg';
import appStore from '../../assets/img/app-store.svg';

const { Title, Text } = Typography

export default function MobileDownload() {
    return (
        <div className="min-h-screen flex items-center justify-center p-4">
            <Card>
                <div className="flex flex-col items-center gap-6 w-full max-w-xs">
                    <div className="flex flex-col items-center gap-2">
                        <Image preview={false} src={logo} alt="Logo" />
                    </div>

                    <Text className="text-base text-center font-popinsMedium">
                        For better experience please download CRM Harbor app
                    </Text>

                    <Space direction="horizontal" align='start' size={12}>
                        <a
                            href="#"
                            className="inline-block"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Image preview={false}
                                src={appStore}
                                alt="Download on App Store"
                                className="object-contain"
                            />
                        </a>
                        <a
                            href="#"
                            className=""
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Image preview={false}
                                src={playStore}

                                alt="Get it on Google Play"
                                className="object-contain"
                            />
                        </a>
                    </Space>
                </div>
            </Card>
        </div>
    )
}

