import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  DatePicker,
  Select,
  Checkbox,
  Col,
  InputNumber,
  Spin,
} from "antd";
import PhoneInputComponent from "./PhoneInputComponent";
import { PhoneInput } from "react-international-phone";
import { FieldType } from "../helper/constants";
import { useSelector } from "react-redux";

const CustomFormFieldsComp = ({ data, form, id }) => {
  const { getCustomFieldsLoader, customFields } = useSelector(
    (state) => state.customField
  );
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const [errors, setErrors] = useState("Please Enter Mobile no!");

  const handlePhoneChange = (val, data, name) => {
    let error = "";
    let formValid = true;

    // Create a new variable to replace spaces with dashes
    const formattedInputValue = data.inputValue.replace(/\s+/g, "-");

    if (!val && is_required) {
      error = "Please Enter Mobile no!";
      formValid = false;
    } else if (val && val.length < 4) {
      error = "Please Enter Mobile no!";
      formValid = false;
    } else if (val.length > 15) {
      error = "Phone number must be 15 digits or less!";
      formValid = false;
    }

    /*   form.setFieldValue(["custom_fields", name, "value"], formattedInputValue);
    form.setFieldValue(["custom_fields", name, "field_type"], FieldType.PHONE);

 */
    setErrors(error);
    setIsPhoneValid(formValid);
  };

  const renderInput = (type, label, options = []) => {
    const normalizedOptions =
      typeof options === "string"
        ? options.split(",")
        : Array.isArray(options)
          ? options
          : [];

    const tranformedOptions =
      normalizedOptions && normalizedOptions.length > 0
        ? normalizedOptions.map((option) => ({
          label: option,
          value: option,
        }))
        : [];

    const isSelect =
      type === FieldType.CHECKBOX ||
      type === FieldType.DROPDOWN ||
      type === FieldType.MULTI_SELECT;
    const placeholder = `${isSelect ? "Select" : "Enter"} ${label}`;

    switch (type) {
      case FieldType.TEXT:
        return <Input placeholder={placeholder} />;
      case FieldType.EMAIL:
        return <Input placeholder={placeholder} />;
      case FieldType.NUMBER:
        return (
          <InputNumber
            changeOnWheel={false}
            step={1}
            min={0}
            style={{ width: "100%" }}
            controls={false}
            placeholder={placeholder}
          />
        );
      case FieldType.TEXTAREA:
        return <Input.TextArea rows={4} placeholder={placeholder} />;
      case FieldType.DATE:
        return <DatePicker placeholder={placeholder} className="w-full" />;
      case FieldType.CHECKBOX:
        return <Checkbox.Group options={tranformedOptions} />;
      case FieldType.DROPDOWN:
        return (
          <Select
            showSearch
            optionFilterProp="label"
            placeholder={placeholder}
            options={tranformedOptions}
          />
        );
      case FieldType.MULTI_SELECT:
        return (
          <Select
            mode="multiple"
            placeholder={placeholder}
            options={tranformedOptions}
          />
        );
      case FieldType.URL:
        return <Input placeholder={placeholder} />;
      default:
        return null;
    }
  };

  /* useEffect(() => {
    const initialValues = customFields.reduce((acc, field) => {
      acc[`custom_fields.${field.name}`] = field.defaultValue || "";
      return acc;
    }, {});
    form.setFieldsValue(initialValues);
  }, [customFields, form]); */

  return data.map((field) => {
    const {
      field_type: type,
      field_options: options,
      field_name: name,
      label,
      is_required,
      help_text,
    } = field;

    const rules = [];
    if (is_required) {
      rules.push({
        required: true,
        message: `Please ${type === FieldType.CHECKBOX ||
            type === FieldType.MULTI_SELECT ||
            type === FieldType.DROPDOWN
            ? "select"
            : "enter"
          } ${label}!`,
      });
    }
    if (type === FieldType.URL) {
      rules.push({
        type: "url",
        message: `Please enter a valid url!`,
      });
    }
    if (type === FieldType.EMAIL) {
      rules.push({
                        pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,

        message: `Please enter a valid email address!`,
      });
    }
    if (type === FieldType.NUMBER) {
      rules.push({
        type: "number",
        message: `Please enter a valid number!`,
      });
    }
    return (
      <Col
        key={field.custom_field_id}
        span={type === FieldType.TEXTAREA ? 24 : 12}
      >
        {type === FieldType.PHONE ? (
          <Col>
            <Form.Item
              validateTrigger={["onSubmit"]}
              name={["custom_fields", name, "value"]}
              rules={[
                {
                  required: is_required,
                  message: "Please enter a phone number!",
                },

                {
                  validator: (_, value) => {

                    if (!value) {
                      return Promise.resolve();
                    }

                    if (is_required) {
                      if (value.length <= 3) {
                        return Promise.reject(
                          new Error("Please enter a phone number!")
                        );
                      }
                      if (value.length < 8) {
                        return Promise.reject(
                          new Error("Phone number must have at least 4 digits!")
                        );
                      }
                      if (value.length > 15) {
                        return Promise.reject(
                          new Error("Phone number must be 15 digits or less!")
                        );
                      }
                    }

                    return Promise.resolve();
                  },
                },
              ]}
              tooltip={help_text}
            >
              {label && (
                <div className=" block text-[#000000] text-[14px] mb-2">
                  {is_required && (
                    <span className="required text-[#ff4d4f] text-[14px]">
                      *
                    </span>
                  )}{" "}
                  {label}
                </div>
              )}
              <PhoneInput
                forceDialCode={true}
                disableFormatting={true}
                placeholder="Enter Phone Number"
                defaultCountry="in"
                className={`w-full  ${!isPhoneValid ? "" : "mb-3.5"}`}
                onBlur={() => {
                  // Trigger validation when the field loses focus
                  form.validateFields([["custom_fields", name, "value"]]);
                }}
                value={
                  id
                    ? form.getFieldValue(["custom_fields", name, "value"]) || ""
                    : ""
                }
                onChange={(val, data) =>
                  handlePhoneChange(val, data, field.field_name)
                }
              />
            </Form.Item>
          </Col>
        ) : (
          <Form.Item
            name={["custom_fields", name, "value"]}
            label={label}
            rules={rules}
            tooltip={help_text}
          >
            {renderInput(type, label, options)}
          </Form.Item>
        )}
      </Col>
    );
  });
};

export default CustomFormFieldsComp;
