import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { closeVerifyHPINModal, showHPINModal } from "../../store/slices/authSlice";
import otpIcon from "../../assets/img/otpIcon.svg";
import { Button, Form, Input, Modal } from 'antd';
import { actionSendOTPforHPIN, actionSetHPIN, actionVerifyHPIN } from '../../store/services/authService';
import OtpInputForSixDigit from './OtpInputForSixDigit';

const VerifyHpinModal = () => {

    const [verifyHPINForm] = Form.useForm();
    const { isVerifyHPINModalVisible, setHPINLoader, currentCompanyUser, verifyHPINLoader, verifyHPINParam } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [hpin, setHpin] = useState(null);
    const [errors, setErrors] = useState("");
    const [code, setCode] = useState(["", "", "", "", "", ""])

    const handleCloseVerifyHPINModal = () => {
        dispatch(closeVerifyHPINModal());
        setCode(["", "", "", "", "", ""])
        verifyHPINForm.resetFields();
        setHpin(null);
        setErrors("");
    }

    const handleVerifyOtp = () => {
        // HPIN Validation
        if (!hpin) {
            setErrors("Please enter HPIN!");
            return;
        }
        if (hpin.length !== 6) {
            setErrors("HPIN must be exactly 6 digits!");
            return;
        }

        // Proceed if all validations pass
        const req = {
            hpin: hpin,
        };
        dispatch(actionVerifyHPIN({
            req,
            handleCancel: handleCloseVerifyHPINModal,
            customParam: verifyHPINParam,
        }));
    };


    return (
        <Modal
            centered
            open={isVerifyHPINModalVisible}
            onOk={handleCloseVerifyHPINModal}
            onCancel={handleCloseVerifyHPINModal}
            afterClose={handleCloseVerifyHPINModal}
            footer={false}
            width={420}
            title=""
            className="hpin-modal"
        >
            <div>
                {/* <div className="flex">
                    <img src={otpIcon} alt="" />
                    <h1 className="text-[#6883FD] text-[20px] ml-[8px] mt-[5px] font-medium">
                        Set HPIN
                    </h1>
                </div> */}


                <Form
                    form={verifyHPINForm}
                    layout="vertical"
                    className="mt-[20px] mb-0"
                >
                    <Form.Item
                        className="mb-3"
                        label={<div>
                            <p className='text-base'>Please Enter Your HPIN</p>
                            {currentCompanyUser?.is_hpin_available == false ?
                                <p className="text-sm my-1 text-[#7c7c7c]">
                                    If you haven't set your HPIN, click 'Set HPIN' to set it.
                                </p> : <p className="text-sm my-1 text-[#7c7c7c]">
                                    If you've forgotten your HPIN, click 'Forgot HPIN' to change it
                                </p>}
                        </div>}
                    >
                        <OtpInputForSixDigit code={code} setCode={setCode} onOtpChange={(otp) => setHpin(otp)} />
                        <span className="ant-form-item-explain-error text-error">
                            {errors}
                        </span>
                    </Form.Item>
                    <div className="flex justify-between items-center mt-7">
                        <Form.Item className="mb-0 pb-0">
                            <Button
                                loading={verifyHPINLoader}
                                type="primary"
                                className="root-btn min-h-[40px] min-w-[100px]"
                                onClick={handleVerifyOtp}
                            disabled={!hpin || hpin.length < 6}
                            >
                                Verify
                            </Button>
                        </Form.Item>
                        <p
                            onClick={() => dispatch(showHPINModal())}
                            className={`mr-5 2xl:text-base text-sm text-end cursor-pointer text-primary hover:text-primary`}
                        >
                            {currentCompanyUser?.is_hpin_available == false ? "Set HPIN" : "Forgot HPIN"}
                        </p>
                    </div>
                </Form>
            </div>
        </Modal>
    )
}

export default VerifyHpinModal