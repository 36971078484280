import { createSlice } from "@reduxjs/toolkit";
import {
  actionAddSalesOrder,
  actionChangeBeatPlanningStatus,
  actionChangeSalesOrderStatus,
  actionClearAllBeatPlanning,
  actionClearAllSalesInvoice,
  actionClearAllSalesOrders,
  actionCreateBeatPlanning,
  actionDeleteSalesOrder,
  actionGetAllBeatPlanning,
  actionGetAllERPTrackingData,
  actionGetAllPlantCodes,
  actionGetAllSalesInvoices,
  actionGetAllSalesOrders,
  actionGetBeatPlanning,
  actionGetBeatPlanningDetail,
  actionGetEmpTrackingDetails,
  actionGetSalesInvoiceDetails,
  actionGetSalesInvoices,
  actionGetSalesOrderDetails,
  actionGetSalesOrders,
  actionGetUserLocationTracking,
  actionUpdateBeatPlanning,
  actionUpdateSalesOrder,
} from "../services/salesService";

const salesSlice = createSlice({
  name: "setting",
  initialState: {
    getBeatPlanningLoader: false,
    getAllBeatPlanningLoader: false,
    addBeatPlanningLoader: false,
    deleteBeatPlanningLoader: false,
    getBeatPlanningDetailLoader: false,
    updateBeatPlanningLoader: false,
    changeStatusBeatPlanningLoader: false,
    beatPlanData: [],
    beatPlanningDetail: {},
    allBeatPlanData: "",
    beatPlansCounts: 0,

    // sales orders state
    getSalesOrdersLoader: false,
    getAllSalesOrdersLoader: false,
    addSalesOrderLoader: false,
    deleteSalesOrderLoader: false,
    updateSalesOrderLoader: false,
    getSalesOrderDetailLoader: false,
    changeSalesOrderStatusLoader: false,
    salesOrdersData: [],
    allSalesOrdersCount: 0,
    allSalesOrders: [],
    salesOrderDetails: {},
    getAllPlantCodesLoader: false,
    allPlantCodes: [],

    // sales invoices
    getSalesInvoicesLoader: false,
    getAllSalesInvoicesLoader: false,
    addSalesInvoiceLoader: false,
    deleteSalesInvoiceLoader: false,
    getSalesInvoiceDetailLoader: false,
    updateSalesInvoiceLoader: false,
    salesInvoicesData: [],
    allSalesInvoicesCount: 0,
    allSalesInvoices: [],
    salesInvoiceDetails: {},

    // emp tracking states
    getAllEmpTrackingLoader: false,
    allEmpTrackingData: [],
    getAllUserLocationsLoader: false,
    allUserLocations: [],
    getEmpTrakingDetailLoader: false,
    empTrackingSingleDetail: {},
  },
  reducers: {},
  extraReducers: (buider) => {
    buider
      // sales orders stars here
      .addCase(actionGetSalesOrders.pending, (state) => {
        state.getSalesOrdersLoader = true;
      })
      .addCase(actionGetSalesOrders.fulfilled, (state, action) => {
        state.getSalesOrdersLoader = false;
        state.salesOrdersData = action.payload.data;
        state.allSalesOrdersCount = action.payload.total_records;
      })
      .addCase(actionGetSalesOrders.rejected, (state) => {
        state.getSalesOrdersLoader = false;
      })

      // clear all data
      .addCase(actionClearAllSalesOrders.pending, (state, action) => {
        state.allSalesOrders = action.payload;
      })

      .addCase(actionGetAllSalesOrders.pending, (state) => {
        state.getAllSalesOrdersLoader = true;
      })
      .addCase(actionGetAllSalesOrders.fulfilled, (state, action) => {
        state.getAllSalesOrdersLoader = false;
        state.allSalesOrders = action.payload.data;
      })

      .addCase(actionGetAllSalesOrders.rejected, (state, action) => {
        // state.allSalesOrders = action.payload;
        state.getAllSalesOrdersLoader = false;
      })

      .addCase(actionGetAllPlantCodes.pending, (state) => {
        state.getAllPlantCodesLoader = true;
      })
      .addCase(actionGetAllPlantCodes.fulfilled, (state, action) => {
        state.getAllPlantCodesLoader = false;
        state.allPlantCodes = action.payload.data;
      })
      .addCase(actionGetAllPlantCodes.rejected, (state) => {
        state.getAllPlantCodesLoader = false;
      })

      .addCase(actionAddSalesOrder.pending, (state) => {
        state.addSalesOrderLoader = true;
      })

      .addCase(actionAddSalesOrder.fulfilled, (state, action) => {
        state.addSalesOrderLoader = false;
        if (!state.salesOrdersData || !Array.isArray(state.salesOrdersData)) {
          state.salesOrdersData = [action.payload];
        } else {
          state.salesOrdersData.unshift(action.payload);
        }

        state.allSalesOrdersCount = state.allSalesOrdersCount + 1;
      })
      .addCase(actionAddSalesOrder.rejected, (state) => {
        state.addSalesOrderLoader = false;
      })

      .addCase(actionDeleteSalesOrder.pending, (state) => {
        state.deleteSalesOrderLoader = true;
      })
      .addCase(actionDeleteSalesOrder.fulfilled, (state, action) => {
        state.deleteSalesOrderLoader = false;
        state.salesOrdersData = state.salesOrdersData.filter(
          (item) => item.sales_order_id !== action.payload
        );
      })
      .addCase(actionDeleteSalesOrder.rejected, (state) => {
        state.deleteSalesOrderLoader = false;
      })

      .addCase(actionGetSalesOrderDetails.pending, (state) => {
        state.getSalesOrderDetailLoader = true;
      })
      .addCase(actionGetSalesOrderDetails.fulfilled, (state, action) => {
        state.getSalesOrderDetailLoader = false;
        state.salesOrderDetails = action.payload.data;
      })
      .addCase(actionGetSalesOrderDetails.rejected, (state) => {
        state.getSalesOrderDetailLoader = false;
      })

      .addCase(actionUpdateSalesOrder.pending, (state) => {
        state.updateSalesOrderLoader = true;
      })
      .addCase(actionUpdateSalesOrder.fulfilled, (state, action) => {
        state.updateSalesOrderLoader = false;
        state.salesOrdersData = state.salesOrdersData.map((item) =>
          item.sales_order_id === action.payload.sales_order_id
            ? { ...item, ...action.payload }
            : item
        );
      })
      .addCase(actionUpdateSalesOrder.rejected, (state) => {
        state.updateSalesOrderLoader = false;
      })

      .addCase(actionChangeSalesOrderStatus.pending, (state) => {
        state.changeSalesOrderStatusLoader = true;
      })
      .addCase(actionChangeSalesOrderStatus.fulfilled, (state, action) => {
        state.changeSalesOrderStatusLoader = false;
        state.salesOrdersData = state.salesOrdersData.map((item) =>
          item.sales_order_id === action.payload.sales_order_id
            ? { ...item, status: action.payload.status }
            : item
        );
      })
      .addCase(actionChangeSalesOrderStatus.rejected, (state) => {
        state.changeSalesOrderStatusLoader = false;
      })
      // sales orders ends here

      // sales invoices starts
      .addCase(actionGetSalesInvoices.pending, (state) => {
        state.getSalesInvoicesLoader = true;
      })
      .addCase(actionGetSalesInvoices.fulfilled, (state, action) => {
        state.getSalesInvoicesLoader = false;
        state.salesInvoicesData = action.payload.data;
        state.allSalesInvoicesCount = action.payload.total_records;
      })
      .addCase(actionGetSalesInvoices.rejected, (state) => {
        state.getSalesInvoicesLoader = false;
      })

      .addCase(actionGetAllSalesInvoices.pending, (state) => {
        state.getAllSalesInvoicesLoader = true;
      })
      .addCase(actionGetAllSalesInvoices.fulfilled, (state, action) => {
        state.getAllSalesInvoicesLoader = false;
        state.allSalesInvoices = action.payload.data;
      })
      .addCase(actionGetAllSalesInvoices.rejected, (state) => {
        state.getAllSalesInvoicesLoader = false;
      })
      /* 
      .addCase(actionAddSalesInvoice.pending, (state) => {
        state.addSalesInvoiceLoader = true;
      })

      .addCase(actionAddSalesInvoice.fulfilled, (state, action) => {
        state.addSalesInvoiceLoader = false;
        if (
          !state.salesInvoicesData ||
          !Array.isArray(state.salesInvoicesData)
        ) {
          state.salesInvoicesData = [action.payload];
        } else {
          state.salesInvoicesData.unshift(action.payload);
        }

        state.allSalesInvoicesCount = state.allSalesInvoicesCount + 1;
      })
      .addCase(actionAddSalesInvoice.rejected, (state) => {
        state.addSalesInvoiceLoader = false;
      }) */

      /*   .addCase(actionDeleteSalesInvoice.pending, (state) => {
        state.deleteSalesInvoiceLoader = true;
      })
      .addCase(actionDeleteSalesInvoice.fulfilled, (state, action) => {
        state.deleteSalesInvoiceLoader = false;
        state.salesInvoicesData = state.salesInvoicesData.filter(
          (item) => item.sales_order_id !== action.payload
        );
      })
      .addCase(actionDeleteSalesInvoice.rejected, (state) => {
        state.deleteSalesInvoiceLoader = false;
      }) */

      .addCase(actionGetSalesInvoiceDetails.pending, (state) => {
        state.getSalesInvoiceDetailLoader = true;
      })
      .addCase(actionGetSalesInvoiceDetails.fulfilled, (state, action) => {
        state.getSalesInvoiceDetailLoader = false;
        state.salesInvoiceDetails = action.payload.data;
      })
      .addCase(actionGetSalesInvoiceDetails.rejected, (state) => {
        state.getSalesInvoiceDetailLoader = false;
      })
      /* 
      .addCase(actionUpdateSalesInvoice.pending, (state) => {
        state.updateSalesOrderLoader = true;
      })

      .addCase(actionUpdateSalesInvoice.fulfilled, (state, action) => {
        state.updateSalesOrderLoader = false;
        state.salesInvoicesData = state.salesInvoicesData.map((item) =>
          item.invoice_id === action.payload.invoice_id
            ? { ...item, ...action.payload }
            : item
        );
      })
      .addCase(actionUpdateSalesInvoice.rejected, (state) => {
        state.updateSalesOrderLoader = false;
      }) */

      // clear all data
      .addCase(actionClearAllSalesInvoice.pending, (state, action) => {
        state.allSalesInvoices = action.payload;
      })

      // sales invoices ends

      // get beat planning State
      .addCase(actionGetBeatPlanning.pending, (state) => {
        state.getBeatPlanningLoader = true;
      })
      .addCase(actionGetBeatPlanning.fulfilled, (state, action) => {
        state.getBeatPlanningLoader = false;
        state.beatPlanData = action.payload.data;
        state.beatPlansCounts = action.payload.total_records;
      })
      .addCase(actionGetBeatPlanning.rejected, (state) => {
        state.getBeatPlanningLoader = false;
      })

      // get all beat planning
      .addCase(actionGetAllBeatPlanning.pending, (state) => {
        state.getAllBeatPlanningLoader = true;
      })
      .addCase(actionGetAllBeatPlanning.fulfilled, (state, action) => {
        state.getAllBeatPlanningLoader = false;
        state.allBeatPlanData = action.payload.data;
      })
      .addCase(actionGetAllBeatPlanning.rejected, (state) => {
        state.getAllBeatPlanningLoader = false;
      })

      .addCase(actionGetBeatPlanningDetail.pending, (state) => {
        state.getBeatPlanningDetailLoader = true;
      })
      .addCase(actionGetBeatPlanningDetail.fulfilled, (state, action) => {
        state.getBeatPlanningDetailLoader = false;
        state.beatPlanningDetail = action.payload.data;
      })
      .addCase(actionGetBeatPlanningDetail.rejected, (state) => {
        state.getBeatPlanningDetailLoader = false;
      })

      // Clear All beat planning Data
      .addCase(actionClearAllBeatPlanning.fulfilled, (state, action) => {
        state.allBeatPlanData = action.payload;
      })

      // add beat planning state
      .addCase(actionCreateBeatPlanning.pending, (state) => {
        state.addBeatPlanningLoader = true;
      })
      .addCase(actionCreateBeatPlanning.fulfilled, (state, action) => {
        state.addBeatPlanningLoader = false;
        if (action.payload) {
          if (!state.beatPlanData || !Array.isArray(state.beatPlanData)) {
            state.beatPlanData = [action.payload];
          } else {
            state.beatPlanData.unshift(action.payload);
          }
          // state.beatPlansCounts = state.beatPlansCounts + 1;
          state.beatPlansCounts =
            (isNaN(parseInt(state.beatPlansCounts))
              ? 0
              : parseInt(state.beatPlansCounts)) + 1;
        }
      })
      .addCase(actionCreateBeatPlanning.rejected, (state) => {
        state.addBeatPlanningLoader = false;
      })

      .addCase(actionUpdateBeatPlanning.pending, (state) => {
        state.updateBeatPlanningLoader = true;
      })
      .addCase(actionUpdateBeatPlanning.fulfilled, (state, action) => {
        state.updateBeatPlanningLoader = false;
      })
      .addCase(actionUpdateBeatPlanning.rejected, (state) => {
        state.updateBeatPlanningLoader = false;
      })

      .addCase(actionChangeBeatPlanningStatus.pending, (state) => {
        state.changeStatusBeatPlanningLoader = true;
      })
      .addCase(actionChangeBeatPlanningStatus.fulfilled, (state, action) => {
        state.changeStatusBeatPlanningLoader = false;

        state.beatPlanData = state.beatPlanData.map((beat) =>
          beat.beat_planning_id === action.payload.id
            ? { ...beat, status: action.payload.newStatus }
            : beat
        );
      })
      .addCase(actionChangeBeatPlanningStatus.rejected, (state) => {
        state.changeStatusBeatPlanningLoader = false;
      })

      // get customer tracking data
      .addCase(actionGetAllERPTrackingData.pending, (state) => {
        state.getAllEmpTrackingLoader = true;
      })
      .addCase(actionGetAllERPTrackingData.fulfilled, (state, action) => {
        state.getAllEmpTrackingLoader = false;
        state.allEmpTrackingData = action.payload.data;
      })
      .addCase(actionGetAllERPTrackingData.rejected, (state) => {
        state.getAllEmpTrackingLoader = false;
      })

      // get customer location data
      .addCase(actionGetUserLocationTracking.pending, (state) => {
        state.getAllUserLocationsLoader = true;
      })
      .addCase(actionGetUserLocationTracking.fulfilled, (state, action) => {
        state.getAllUserLocationsLoader = false;
        state.allUserLocations = action.payload.data;
      })
      .addCase(actionGetUserLocationTracking.rejected, (state) => {
        state.getAllUserLocationsLoader = false;
      })

      .addCase(actionGetEmpTrackingDetails.pending, (state) => {
        state.getEmpTrakingDetailLoader = true;
      })
      .addCase(actionGetEmpTrackingDetails.fulfilled, (state, action) => {
        state.getEmpTrakingDetailLoader = false;
        state.empTrackingSingleDetail = action.payload.data;
      })
      .addCase(actionGetEmpTrackingDetails.rejected, (state) => {
        state.getEmpTrakingDetailLoader = false;
      });
  },
});

export default salesSlice.reducer;
