import { LuPlus } from "react-icons/lu";

import {
  CloseOutlined,
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Popover,
  Row,
  Select,
  Skeleton,
  Spin,
  Tooltip,
  Typography,
  Upload,
} from "antd";
import ImgCrop from "antd-img-crop";
import React, { useEffect, useMemo, useState } from "react";
import { TbFileUpload } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import attechment from "../../../assets/img/attechment.svg";
import businessPartner from "../../../assets/img/businessPartner.svg";
import editPrimaryWhite from "../../../assets/img/editPrimaryWhite.svg";
import plusSvg from "../../../assets/img/plus.svg";
import { BASE_URL_UPLOAD } from "../../../config/web-config";
import {
  actionAddAddressTypeMaster,
  actionAddBusinessPartnerTypeMaster,
  actionAddIndustryMaster,
  actionAddTypeOfCompanyMaster,
  actionGetAddressTypeMaster,
  actionGetAllBeatMaster,
  actionGetBusinessPartnerTypeMaster,
  actionGetIndustryMaster,
  actionGetTypeOfCompanyMaster,
} from "../../../store/services/generalMasterService";
import {
  actionAllStatesIndex,
  actionGetCountries,
  actionGetStates,
} from "../../../store/services/locationService";
import {
  actionAddBusinessPartner,
  actionGetSingleBusinessPartner,
  actionUpdateBusinessPartner,
} from "../../../store/services/masterDataService";
import CustomIconText from "../../common/CustomIconText";
import {
  extractFilename,
  renderFileIcon,
  sanitizeName,
} from "../../helper/common-function";
import AddPopover from "../../common/AddPopover";
import SkeletonUpdateCard from "../../common/SkeletonUpdateCard";
import { actionGetProductCategoryMaster } from "../../../store/services/productMasterService";
const { Dragger } = Upload;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const CreateBusinessPartner = () => {
  const [form] = Form.useForm();
  const [addBusinessPartnerTypeForm] = Form.useForm();
  const [addressTypeForm] = Form.useForm();
  const [addIndustryForm] = Form.useForm();
  const [typeofCompanyForm] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [
    addBusinessPartnerTypePopoverOpen,
    setAddBusinessPartnerTypePopoverOpen,
  ] = useState(false);
  const [addIndustryPopoverOpen, setAddIndustryPopoverOpen] = useState(false);
  const [contractFile, setContractFile] = useState([]);
  const [otherCertificates, setOtherCertificates] = useState([]);
  const [gstnCertificate, setGstnCertificate] = useState("");
  const [panCard, setPanCard] = useState("");
  const [cancelledCheque, setCancelledCheque] = useState("");
  const [msmeDetail, setMsmeDetail] = useState("");
  const [countrySelected, setCountrySelected] = useState(false);
  const [BusinessPartnerLogoPicUrl, setBusinessPartnerLogoPicUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [addressTypePopovers, setAddressTypePopovers] = useState({});
  const [countrySelections, setCountrySelections] = useState({});
  const [selectedAddressTypes, setSelectedAddressTypes] = useState({});
  const [addTypeofCompanyPopoverOpen, setAddTypeofCompanyPopoverOpen] =
    useState(false);

  const {
    countries,
    states,
    getCountryLoader,
    statesLoader,
    allStatesIndexData,
    getAllStatesIndexLoader,
  } = useSelector((state) => state.location);
  const { businessPartnerId } = location.state || {};

  const {
    addBusinessPartnerLoader,
    getBusinessPartnerDetailLoader,
    businessPartnerDetail,
    updateBusinessPartnerLoader,
  } = useSelector((state) => state.masterData);
  const {
    getIndustryLoader,
    addIndustryLoader,
    getBusinessPartnerTypeLoader,
    addBusinessPartnerTypeLoader,
    businessPartnerTypeData,
    industryData,
    getTypeOfCompanyLoader,
    typeOfCompanyData,
    addTypeOfCompanyLoader,
    allBeatMasterData,
    getAllBeatMasterLoader,
    getAddressTypeLoader,
    addressTypeMasterData,
    addAddressTypeMasterLoader,
  } = useSelector((state) => state.generalMaster);

  const { productCategoryData, getProductCategoryLoader } = useSelector(
    (state) => state.productMaster
  );

  // get state and country
  useEffect(() => {
    dispatch(actionGetCountries());
    dispatch(actionGetIndustryMaster());
    dispatch(actionGetBusinessPartnerTypeMaster());
    dispatch(actionGetTypeOfCompanyMaster());
    dispatch(actionGetAllBeatMaster());
    dispatch(actionGetAddressTypeMaster());
    dispatch(actionGetProductCategoryMaster());

    if (businessPartnerId) {
      dispatch(actionGetSingleBusinessPartner(businessPartnerId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (businessPartnerDetail?.country?.id) {
      dispatch(actionGetStates(businessPartnerDetail?.country?.id));
    }
  }, [businessPartnerDetail]);

  useEffect(() => {
    if (businessPartnerId && businessPartnerDetail) {
      const newCountrySelections = {};

      if (businessPartnerDetail.business_partner_addresses?.length > 0) {
        businessPartnerDetail.business_partner_addresses.forEach(
          async (address, index) => {
            if (address.country?.id) {
              newCountrySelections[index] = true;
              await dispatch(
                actionAllStatesIndex({ countryId: address.country.id, index })
              );
            }
          }
        );
      }

      form.setFieldsValue({
        business_partner_name:
          businessPartnerDetail.business_partner_name || undefined,
        business_partner_type_master_id:
          businessPartnerDetail.business_partner_type
            ?.business_partner_type_master_id || undefined,
        material_category_ids:
          businessPartnerDetail.material_categories &&
          businessPartnerDetail.material_categories.map(
            (item) => item?.material_category?.product_category_master_id
          ),
        email: businessPartnerDetail.email || undefined,
        mobile: businessPartnerDetail.mobile || undefined,
        address_1: businessPartnerDetail.address_1 || undefined,
        address_2: businessPartnerDetail.address_2 || undefined,
        country_id: businessPartnerDetail.country?.id || undefined,
        state_id: businessPartnerDetail.state?.id || undefined,
        city: businessPartnerDetail.city || undefined,
        zip_code: businessPartnerDetail.zip_code || undefined,
        gst_number: businessPartnerDetail.gst_number || undefined,
        pan_card_no: businessPartnerDetail.pan_card_no || undefined,
        msme_number: businessPartnerDetail.msme_number || undefined,
        fssai: businessPartnerDetail.fssai || undefined,
        cin_no: businessPartnerDetail.cin_no || undefined,
        erp_code: businessPartnerDetail.erp_code || undefined,
        type_of_company_id:
          businessPartnerDetail.type_of_company_id || undefined,
        industry_master_id:
          businessPartnerDetail.industry?.industry_master_id || undefined,
        beat_master_id:
          businessPartnerDetail.beat_master?.beat_master_id || undefined,
        company_size: businessPartnerDetail.company_size || undefined,
        revenue: businessPartnerDetail.revenue || undefined,
        sales_volume: businessPartnerDetail.sales_volume || undefined,
        agreement_duration:
          businessPartnerDetail.agreement_duration || undefined,
        commission_structure:
          businessPartnerDetail.commission_structure || undefined,
        terms_and_conditions:
          businessPartnerDetail.terms_and_conditions || undefined,
        feedback_mechanism:
          businessPartnerDetail.feedback_mechanism || undefined,
        renewal_process: businessPartnerDetail.renewal_process || undefined,
        termination_conditions:
          businessPartnerDetail.termination_conditions || undefined,
        bank_name: businessPartnerDetail.bank_name || undefined,
        branch_name: businessPartnerDetail.branch_name || undefined,
        account_type: businessPartnerDetail.account_type || undefined,
        ifsc: businessPartnerDetail.ifsc || undefined,
        account_number: businessPartnerDetail.account_number || undefined,
        primary_contact_name:
          businessPartnerDetail.primary_contact_name || undefined,
        primary_contact_email:
          businessPartnerDetail.primary_contact_email || undefined,
        primary_contact_phone:
          businessPartnerDetail.primary_contact_phone || undefined,
        secondary_contact_name:
          businessPartnerDetail.secondary_contact_name || undefined,
        secondary_contact_email:
          businessPartnerDetail.secondary_contact_email || undefined,
        secondary_contact_phone:
          businessPartnerDetail.secondary_contact_phone || undefined,
        business_partners_addresses:
          businessPartnerDetail.business_partner_addresses &&
            businessPartnerDetail.business_partner_addresses.length > 0
            ? businessPartnerDetail.business_partner_addresses?.map((item) => ({
              address_1: item.address_1,
              address_2: item.address_2,
              country_id: item.country?.id || null,
              state_id: item.state?.id || null,
              city: item.city || null,
              zip_code: item.zip_code || null,
              address_type_master_id:
                item.address_type_master?.address_type_master_id || null,
            }))
            : [{}],
      });

      if (businessPartnerDetail?.country?.id) {
        setCountrySelected(true);
      }

      if (businessPartnerDetail.logo) {
        setBusinessPartnerLogoPicUrl(businessPartnerDetail.logo);
      }

      // Handle attachments and certificates
      if (businessPartnerDetail.gstn_certificate_attachment) {
        setGstnCertificate(businessPartnerDetail.gstn_certificate_attachment);
      }
      if (businessPartnerDetail.pan_card_attachment) {
        setPanCard(businessPartnerDetail.pan_card_attachment);
      }
      if (businessPartnerDetail.cancelled_cheque_attachment) {
        setCancelledCheque(businessPartnerDetail.cancelled_cheque_attachment);
      }
      if (businessPartnerDetail.msme_details_attachment) {
        setMsmeDetail(businessPartnerDetail.msme_details_attachment);
      }

      // Handle contract and other certificate attachments
      const contractAttachmentArray =
        businessPartnerDetail.contract_attachements?.map((data, index) => ({
          id: index,
          url: data,
          name: extractFilename(data),
        })) || [];

      const otherCertificateAttachmentArray =
        businessPartnerDetail.other_certificates_attachments?.map(
          (data, index) => ({
            id: index,
            url: data,
            name: extractFilename(data),
          })
        ) || [];

      setContractFile(contractAttachmentArray);
      setOtherCertificates(otherCertificateAttachmentArray);

      setCountrySelections((prev) => ({ ...prev, ...newCountrySelections }));
    }
  }, [form, businessPartnerId, businessPartnerDetail]);

  const handleRemoveContact = (file) => {
    const updatedList = contractFile.filter((item) => item.uid !== file.uid);
    setContractFile(updatedList);
  };

  const contractProps = {
    name: "file",
    multiple: true,
    action: BASE_URL_UPLOAD,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("crmWebToken"),
    },
    fileList: contractFile,
    showUploadList: true,
    onChange(info) {
      const { status, fileList } = info;
      if (status !== "uploading") {
        setContractFile(fileList);
      }
      if (status === "done") {
        toast.success(`File uploaded successfully.`);
      } else if (status === "error") {
        toast.error(`File upload failed.`);
      }
    },
    onRemove: handleRemoveContact,

    onDrop(e) { },
  };

  const handleRemoveCertificates = (file) => {
    // Filter out the file from the fileList
    const updatedList = otherCertificates.filter(
      (item) => item.uid !== file.uid
    );
    setOtherCertificates(updatedList);
  };

  const otherCertificatesProps = {
    name: "file",
    multiple: true,
    action: BASE_URL_UPLOAD,
    fileList: otherCertificates,
    showUploadList: true,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("crmWebToken"),
    },
    onChange(info) {
      const { status, fileList } = info;
      if (status !== "uploading") {
        setOtherCertificates(fileList);
      }
      if (status === "done") {
        toast.success(`File uploaded successfully.`);
      } else if (status === "error") {
        toast.error(`File upload failed.`);
      }
    },
    onRemove: handleRemoveCertificates,
    onDrop(e) { },
  };

  const gstnCertificateProps = {
    name: "file",
    multiple: false,
    action: BASE_URL_UPLOAD,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("crmWebToken"),
    },
    showUploadList: false,
    onChange(info) {
      const { status } = info.file;
      if (status === "uploading") {
        console.log("uploading...");
      } else {
        if (status === "done") {
          setGstnCertificate(info.file.response.url);
          toast.success(`File uploaded successfully.`);
        } else if (status === "error") {
          toast.error(`File upload failed.`);
        }
      }
    },
    onRemove: () => setGstnCertificate(""),
    onDrop(e) { },
  };

  const panCardProps = {
    name: "file",
    multiple: false,
    Tooltip: false,
    action: BASE_URL_UPLOAD,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("crmWebToken"),
    },
    onChange(info) {
      const { status } = info.file;
      if (status === "uploading") {
        console.log("uploading...");
      } else {
        if (status === "done") {
          setPanCard(info.file.response.url);
          toast.success(`File uploaded successfully.`);
        } else if (status === "error") {
          toast.error(`File upload failed.`);
        }
      }
    },
    onRemove: () => setPanCard(""),
    onDrop(e) { },
  };

  const cancelledChequeProps = {
    name: "file",
    multiple: false,
    Tooltip: false,
    action: BASE_URL_UPLOAD,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("crmWebToken"),
    },
    onChange(info) {
      const { status } = info.file;
      if (status === "done") {
        setCancelledCheque(info.file.response.url);
        toast.success(`File uploaded successfully.`);
      } else if (status === "error") {
        toast.error(`File upload failed.`);
      }
    },
    onRemove: () => setCancelledCheque(""),
    onDrop(e) { },
  };

  const msmeProps = {
    name: "file",
    multiple: false,
    Tooltip: false,
    showUploadList: false,
    action: BASE_URL_UPLOAD,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("crmWebToken"),
    },
    onChange(info) {
      const { status } = info.file;
      if (status === "uploading") {
        console.log("uploading...");
      } else {
        if (status === "done") {
          setMsmeDetail(info.file.response.url);
          toast.success(`File uploaded successfully.`);
        } else if (status === "error") {
          toast.error(`File upload failed.`);
        }
      }
    },
    onRemove: () => setMsmeDetail(""),
    onDrop(e) { },
  };

  const handleChangeBusinessPartnerLogo = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, () => {
        setLoading(false);
        setBusinessPartnerLogoPicUrl(info.file.response.url);
        toast.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      setLoading(false);
      toast.error(`${info.file.name} file upload failed.`);
    }
  };

  // add Business partner type
  const handleCancelAddBusinessPartnerTypePopover = () => {
    setAddBusinessPartnerTypePopoverOpen(false);
    addBusinessPartnerTypeForm.resetFields();
  };

  const showAddBusinessPartnerTypePopoverOpen = () => {
    setAddBusinessPartnerTypePopoverOpen(true);
  };

  const addBusinessPartnerTypePopover = (
    <>
      <div className="min-w-[350px]">
        <Form
          form={addBusinessPartnerTypeForm}
          onFinish={(values) =>
            dispatch(
              actionAddBusinessPartnerTypeMaster({
                values,
                handleCancel: handleCancelAddBusinessPartnerTypePopover,
              })
            )
          }
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="business_partner_type_name"
            label="Business Partner type name"
            className="mb-[12px]"
            size="small"
            normalize={(value) => {
              return value.replace(/^\s+/g, "")
                .replace(/\s+/g, " ")
                .replace(/[^a-zA-Z0-9 .&-]/g, "");
            }}
            rules={[
              {
                required: true,
                message: "Please enter business partner type!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter business partner type name"
              className="!indent-0 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            className="mb-[12px]"
            normalize={(value) => {
              return value.replace(/^\s+/g, "")
                .replace(/\s+/g, " ")
                .replace(/[^a-zA-Z0-9 .&-]/g, "");
            }}
            size="small"
          >
            <Input.TextArea
              style={{ background: "white" }}
              placeholder="Enter description"
              className="!indent-0 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={() => handleCancelAddBusinessPartnerTypePopover()}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addBusinessPartnerTypeLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );

  //add Industry
  const handleCancelAddIndustryPopover = () => {
    setAddIndustryPopoverOpen(false);
    addIndustryForm.resetFields();
  };

  const handleCancelTypeofCompanyPopover = () => {
    setAddTypeofCompanyPopoverOpen(false);
    typeofCompanyForm.resetFields();
  };

  const showAddIndustryPopoverOpen = () => {
    setAddIndustryPopoverOpen(!addIndustryPopoverOpen);
  };

  const addIndustryPopover = (
    <>
      <div className="min-w-[350px]">
        <Form
          form={addIndustryForm}
          onFinish={(values) =>
            dispatch(
              actionAddIndustryMaster({
                values,
                handleIndustryPopoverClose: handleCancelAddIndustryPopover,
              })
            )
          }
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="industry"
            label="Industry"
            className="mb-[12px]"
            normalize={(value) => value.replace(/^\s+/, "")}
            size="small"
            rules={[
              {
                required: true,
                message: "Please enter industry!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Industry"
              className="!indent-0 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={() => handleCancelAddIndustryPopover()}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addIndustryLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );

  const addTypeofCompanyPopover = (
    <>
      <div className="min-w-[350px]">
        <Form
          form={typeofCompanyForm}
          onFinish={(values) =>
            dispatch(
              actionAddTypeOfCompanyMaster({
                values,
                handleCancel: handleCancelTypeofCompanyPopover,
                form,
              })
            )
          }
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="company_type"
            label="Company Type"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please enter type of comapany!",
              },
            ]}
            normalize={(value) => {
              return value.replace(/^\s+/g, "")
                .replace(/\s+/g, " ")
                .replace(/[^a-zA-Z0-9 .&-]/g, "");
            }}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Company Type"
              className="!indent-0 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={() => handleCancelTypeofCompanyPopover()}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addTypeOfCompanyLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );

  const handleGetState = (val, data) => {
    dispatch(actionGetStates(data.value));
    setCountrySelected(true);
    form.setFieldsValue({
      state_id: null,
    });
  };

  const handleCountryChange = (value, option, index, field, form) => {
    if (value) {
      dispatch(actionAllStatesIndex({ countryId: value, index }));
      setCountrySelections((prev) => ({ ...prev, [index]: true }));
    } else {
      setCountrySelections((prev) => ({ ...prev, [index]: false }));
    }

    form.setFieldsValue({
      business_partners_addresses: form
        .getFieldValue("business_partners_addresses")
        .map((address, i) =>
          i === index ? { ...address, state_id: undefined } : address
        ),
    });
  };

  const handleClearState = (index) => {
    setCountrySelections((prev) => ({ ...prev, [index]: false }));

    const addresses = form.getFieldValue("business_partners_addresses") || [];

    form.setFieldsValue({
      business_partners_addresses: addresses.map((address, i) =>
        i === index ? { ...address, state_id: undefined } : address
      ),
    });
  };

  const handleDefautlClearState = () => {
    setCountrySelected(false);
    form.setFieldsValue({ state_id: undefined });
  };

  const country =
    (countries &&
      countries.length &&
      countries?.map(({ name, iso2, id, ...data }) => ({
        ...data,
        label: name,
        value: id,
        id: id,
      }))) ||
    [];

  const state =
    (states &&
      states.length &&
      states?.map(({ name, state_code, id, ...data }) => ({
        ...data,
        label: name,
        value: id,
      }))) ||
    [];

  const industryOptions =
    industryData &&
    industryData.length > 0 &&
    industryData.map((status) => ({
      label: status.industry,
      value: status.industry_master_id,
    }));

  const addressTypeOptions = useMemo(() => {
    return (
      addressTypeMasterData &&
      addressTypeMasterData.length > 0 &&
      addressTypeMasterData.map((data) => ({
        label: data.address_type,
        value: data.address_type_master_id,
      }))
    );
  }, [addressTypeMasterData]);

  const beatOptions =
    allBeatMasterData &&
    allBeatMasterData.length > 0 &&
    allBeatMasterData.map((data) => ({
      label: data.beat_name,
      value: data.beat_master_id,
    }));

  const materialCategoryOptions =
    productCategoryData &&
    productCategoryData.length > 0 &&
    productCategoryData.map((data) => ({
      label: data.category_name,
      value: data.product_category_master_id,
    }));

  const typeofCompanies =
    typeOfCompanyData &&
    typeOfCompanyData.length > 0 &&
    typeOfCompanyData.map(({ type_of_company_id, company_type }) => ({
      label: company_type,
      value: type_of_company_id,
    }));

  const businessTypeOptions =
    businessPartnerTypeData &&
    businessPartnerTypeData.length > 0 &&
    businessPartnerTypeData.map((status) => ({
      label: status.business_partner_type_name,
      value: status.business_partner_type_master_id,
    }));

  const handleSubmit = (values) => {
    const othersArr = [];
    otherCertificates &&
      otherCertificates.length > 0 &&
      otherCertificates?.forEach((d) => {
        if (d.response) {
          const newUrl = d.response.filename;
          othersArr.push(newUrl);
        } else if (d.url) {
          const parts = extractFilename(d.url);
          othersArr.push(parts);
        } else {
          othersArr.push(d.path);
        }
      });
    const contractsArr = [];

    contractFile &&
      contractFile.length > 0 &&
      contractFile?.forEach((d) => {
        if (d.response) {
          const newUrl = d.response.filename;
          contractsArr.push(newUrl);
        } else if (d.url) {
          const parts = extractFilename(d.url);
          contractsArr.push(parts);
        } else {
          contractsArr.push(d.path);
        }
      });

    const data = {
      ...values,
      business_partner_type_master_id: values.business_partner_type_master_id
        ? values.business_partner_type_master_id
        : null,
      beat_master_id: values.beat_master_id ? values.beat_master_id : null,
      industry_master_id: values.industry_master_id
        ? values.industry_master_id
        : null,

      pan_card_attachment: extractFilename(panCard),
      logo: BusinessPartnerLogoPicUrl
        ? extractFilename(BusinessPartnerLogoPicUrl)
        : null,
      gstn_certificate_attachment: gstnCertificate
        ? extractFilename(gstnCertificate)
        : null,
      cancelled_cheque_attachment: cancelledCheque
        ? extractFilename(cancelledCheque)
        : null,
      material_category_ids:
        values.material_category_ids && values.material_category_ids.length > 0
          ? values.material_category_ids
          : null,
      msme_details_attachment: msmeDetail ? extractFilename(msmeDetail) : null,
      other_certificates_attachments: othersArr,
      contract_attachements: contractsArr,
      business_partners_addresses:
        values.business_partners_addresses &&
          values.business_partners_addresses.length > 0
          ? values.business_partners_addresses
            .filter((item) =>
              Object.values(item).some(
                (value) =>
                  value !== null && value !== "" && value !== undefined
              )
            )
            .map((item) => ({
              address_1: item.address_1 ? item.address_1 : null,
              address_2: item.address_2 ? item.address_2 : null,
              country_id: item.country_id ? item.country_id : null,
              state_id: item.state_id ? item.state_id : null,
              city: item.city ? item.city : null,
              zip_code: item.zip_code ? item.zip_code : null,
              address_type_master_id: item.address_type_master_id
                ? item.address_type_master_id
                : null,
            }))
          : [],
    };

    businessPartnerId
      ? dispatch(
        actionUpdateBusinessPartner({
          id: businessPartnerId,
          req: data,
          navigate,
        })
      )
      : dispatch(actionAddBusinessPartner({ req: data, navigate }));
  };
  const defaultAddressStateData =
    states && states.length > 0
      ? states.map(({ name, id, ...data }) => ({
        ...data,
        label: name,
        value: id,
      }))
      : [];

  // Toggle functions for popovers
  const toggleAddressTypePopover = (index) => {
    setAddressTypePopovers((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  // Close functions for popovers
  const closeAddressTypePopover = (index) => {
    setAddressTypePopovers((prev) => ({
      ...prev,
      [index]: false,
    }));
    addressTypeForm.resetFields();
  };

  const showAddAddressTypeForm = (index) => {
    return (
      <div className="min-w-[350px]">
        <Form
          form={addressTypeForm}
          onFinish={(values) =>
            dispatch(
              actionAddAddressTypeMaster({
                values,
                handleCancel: () => closeAddressTypePopover(index),
              })
            )
          }
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="address_type"
            label="Address Type"
            className="mb-[12px]"
            size="small"
            normalize={(value) => {
              return value.replace(/^\s+/g, "")
                .replace(/\s+/g, " ")
                .replace(/[^a-zA-Z0-9 .&-]/g, "");
            }}
            rules={[
              {
                required: true,
                message: "Please enter address type!",
              },
            ]}
          >
            <Input
              placeholder="Enter Address Type"
              className="!indent-0 py-[10px]"
            />
          </Form.Item>

          <div className="flex justify-end mt-[40px]">
            <Form.Item className="mb-0">
              <Button
                onClick={() => closeAddressTypePopover(index)}
                type="button"
                className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addAddressTypeMasterLoader}
                  type="primary"
                  className="root-btn min-h-[45px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    );
  };

  return (

    <section className="main-wrapper">
      <CustomIconText
        image={businessPartner}
        title={
          businessPartnerId
            ? "Update Business Partner"
            : "Create Business Partner"
        }
        backArrow={true}
      />
      <Form
        scrollToFirstError={true}
        form={form}
        layout="vertical"
        initialValues={{ business_partners_addresses: [{}] }}
        name="basic"
        onFinish={handleSubmit}
        autoComplete="off"
      >
        <Card
          title={
            <h1 className="lg:text-lg text-base font-popinsRegular text-brand ">
              Basic Details
            </h1>
          }
          className="main-card mt-5 w-full"
        >
          <Row gutter={24}>
            <Col span={24} align="middle">
              <Form.Item className="mb-[12px]" size="small">
                {getBusinessPartnerDetailLoader ? (
                  <>
                    {Array.from({ length: 1 }).map((_, index) => (
                      <Skeleton.Avatar
                        key={index}
                        active
                        size={100}
                        shape="square"
                        className="flex-shrink-0"
                        style={{ marginRight: "10px" }}
                      />
                    ))}
                  </>
                ) : (
                  <>
                    {BusinessPartnerLogoPicUrl ? (
                      <div className="relative border border-dashed border-brand  h-[102px]  w-[102px] rounded-[6px] mb-2">
                        <img
                          src={BusinessPartnerLogoPicUrl}
                          alt="avatar"
                          className="h-full w-full rounded-[6px]"
                        />

                        <ImgCrop
                          rotationSlider
                          aspect={1 / 1}
                          fillColor="white"
                        >
                          <Upload
                            id=""
                            accept=".png,.jpg,.jpeg,.pdf,.doc"
                            name="file"
                            listType="picture-card"
                            className="border-0 absolute main-upload bottom-0 right-0 avatar-uploader"
                            showUploadList={false}
                            action={BASE_URL_UPLOAD}
                            openFileDialogOnClick={true}
                            headers={{
                              Authorization:
                                "Bearer " + localStorage.getItem("crmWebToken"),
                            }}
                            onChange={handleChangeBusinessPartnerLogo}
                          >
                            <div className=" px-1.5 py-1 border-0 absolute right-0 bottom-0 bg-brand rounded-tl-[6px] rounded-br-[6px] cursor-pointer flex">
                              <Image
                                width={15}
                                height={15}
                                src={editPrimaryWhite}
                                className="rounded-lg"
                                preview={false}
                              />
                            </div>
                          </Upload>
                        </ImgCrop>
                      </div>
                    ) : (
                      <ImgCrop aspect={1 / 1} rotationSlider fillColor="white">
                        <Upload
                          accept=".png,.jpg,.jpeg,.pdf,.doc"
                          name="file"
                          listType="picture-card"
                          className="avatar-uploader mb-2"
                          showUploadList={false}
                          action={BASE_URL_UPLOAD}
                          openFileDialogOnClick={true}
                          headers={{
                            Authorization:
                              "Bearer " + localStorage.getItem("crmWebToken"),
                          }}
                          onChange={handleChangeBusinessPartnerLogo}
                        >
                          <button
                            style={{
                              border: 0,
                              background: "none",
                            }}
                            type="button"
                          >
                            {loading ? <LoadingOutlined /> : <PlusOutlined />}
                            <div
                              style={{
                                marginTop: 8,
                              }}
                            >
                              Logo
                            </div>
                          </button>
                        </Upload>
                      </ImgCrop>
                    )}
                  </>
                )}
              </Form.Item>
            </Col>

            {getBusinessPartnerDetailLoader ? (
              <SkeletonUpdateCard length={3} />
            ) : (
              <>
                <Col span={12}>
                  <Form.Item

                    label="Business Partner Name"
                    name="business_partner_name"
                    normalize={(value) => {
                      return value.replace(/^\s+/g, "")
                        .replace(/\s+/g, " ")
                        .replace(/[^a-zA-Z0-9 .&-]/g, "");
                    }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter business partner name!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Business Partner Name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Popover
                    placement="bottomRight"
                    content={addBusinessPartnerTypePopover}
                    trigger="click"
                    open={addBusinessPartnerTypePopoverOpen}
                    onOpenChange={(visible) => {
                      if (!visible) {
                        setAddBusinessPartnerTypePopoverOpen(false);
                        addBusinessPartnerTypeForm.resetFields();
                      }
                    }}
                  >
                    <div
                      className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                      onClick={showAddBusinessPartnerTypePopoverOpen}
                    >
                      <Image
                        src={plusSvg}
                        alt="image"
                        height={20}
                        width={20}
                        preview={false}
                      />
                      <h1 className="text-[14px] font-popinsRegular ml-1">
                        Add Business Partner Type
                      </h1>
                    </div>
                  </Popover>
                  <Form.Item
                    label="Business Partner Type"
                    name="business_partner_type_master_id"
                    rules={[
                      {
                        required: true,
                        message: "Please select business partner type!",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Business Partner Type"
                      optionFilterProp="children"
                      className=""
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={businessTypeOptions}
                      disabled={getBusinessPartnerTypeLoader}
                      loading={getBusinessPartnerTypeLoader}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                                        pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,

                        message: "Please enter a valid email!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Email" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="mobile"
                    normalize={(value) => value && value.replace(/[^+\d]/g, "")}
                    label="Mobile"
                    className="mb-[12px]"
                    size="small"
                    rules={[
                      {
                        max: 15,
                        message:
                          "mobile number cannot be longer than 15 digits",
                      },
                    ]}
                  >
                    <Input
                      // rows={3}
                      style={{ background: "white" }}
                      placeholder="Enter Mobile"
                      className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Beat"
                    name="beat_master_id"
                    rules={[
                      {
                        required: false,
                        message: "Please select beat!",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      loading={getAllBeatMasterLoader}
                      placeholder="Select Beat"
                      optionFilterProp="children"
                      className=""
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={beatOptions}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Material Categories"
                    name="material_category_ids"
                  >
                    <Select
                      mode="multiple"
                      maxTagCount="responsive"
                      maxTagPlaceholder={(omittedValues) => (
                        <Tooltip
                          overlayStyle={{
                            pointerEvents: "none",
                          }}
                          title={omittedValues
                            .map(({ label }) => label)
                            .join(", ")}
                        >
                          <span>+{omittedValues.length} more</span>
                        </Tooltip>
                      )}
                      allowClear
                      showSearch
                      loading={getProductCategoryLoader}
                      placeholder="Select Material Category"
                      optionFilterProp="children"
                      className=""
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={materialCategoryOptions}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Card>

        <Card
          title={
            <h1 className="lg:text-lg text-base font-popinsRegular text-brand">
              Primary Business Address
            </h1>
          }
          className="main-card mt-5 w-full"
        >
          {getBusinessPartnerDetailLoader ? (
            <SkeletonUpdateCard length={3} />
          ) : (
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  normalize={(value) => {
                    return value.replace(/^\s+/g, "")
                      .replace(/\s+/g, " ")
                      .replace(/[^a-zA-Z0-9 .,&#/\\-]/g, "");
                  }}
                  label="Address 1" name="address_1">
                  <Input placeholder="Enter Address" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  normalize={(value) => {
                    return value.replace(/^\s+/g, "")
                      .replace(/\s+/g, " ")
                      .replace(/[^a-zA-Z0-9 .,&#/\\-]/g, "");
                  }}
                  label="Address 2" name="address_2">
                  <Input placeholder="Enter Address 2" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Country" name="country_id">
                  <Select
                    allowClear
                    showSearch
                    loading={getCountryLoader}
                    onClear={handleDefautlClearState}
                    onSelect={handleGetState}
                    placeholder="Select Country"
                    optionFilterProp="children"
                    className=""
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={country}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="State" name="state_id">
                  <Select
                    allowClear
                    showSearch
                    loading={statesLoader}
                    disabled={!countrySelected}
                    placeholder="Select State"
                    optionFilterProp="children"
                    className=""
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={defaultAddressStateData}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  normalize={(value) => {
                    return value.replace(/^\s+/g, "")
                      .replace(/\s+/g, " ")
                      .replace(/[^a-zA-Z\s-]/g, "");
                  }}
                  label="City" name="city">
                  <Input placeholder="Enter City" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Pin Code" name="zip_code"
                  normalize={(value) => value.replace(/\s+/g, "")}
                >
                  <Input max={8} type="number" aria-controls="false" placeholder="Enter Pin Code" />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Card>

        <Form.List name="business_partners_addresses">
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((field, index) => {
                  const stateOptions = allStatesIndexData[index] || [];

                  const stateData = stateOptions.map(
                    ({ name, id, ...data }) => ({
                      ...data,
                      label: name,
                      value: id,
                    })
                  );
                  return (
                    <Card
                      key={field.key}
                      title={
                        <h1 className="lg:text-lg text-base font-popinsRegular text-brand">
                          Company Address
                        </h1>
                      }
                      extra={
                        <CloseOutlined
                          disabled={fields.length === 1}
                          onClick={() => {
                            remove(index);
                            setSelectedAddressTypes((prev) => {
                              const updated = { ...prev };
                              delete updated[index];
                              return updated;
                            });
                          }}
                          className={`text-red-500 text-[16px]  ${fields.length === 1
                            ? "opacity-50 pointer-events-none"
                            : ""
                            }`}
                        />
                      }
                      className="main-card mt-5 w-full"
                    >
                      {getBusinessPartnerDetailLoader ? (
                        <SkeletonUpdateCard length={4} />
                      ) : (
                        <Row gutter={24}>
                          <Col span={12}>
                            <Form.Item
                              normalize={(value) => {
                                return value.replace(/^\s+/g, "")
                                  .replace(/\s+/g, " ")
                                  .replace(/[^a-zA-Z0-9 .,&#/\\-]/g, "");
                              }}
                              label="Address 1"
                              name={[index, "address_1"]}
                            >
                              <Input placeholder="Enter Address" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              normalize={(value) => {
                                return value.replace(/^\s+/g, "")
                                  .replace(/\s+/g, " ")
                                  .replace(/[^a-zA-Z0-9 .,&#/\\-]/g, "");
                              }}
                              label="Address 2"
                              name={[index, "address_2"]}
                            >
                              <Input placeholder="Enter Address 2" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Country"
                              name={[index, "country_id"]}
                            >
                              <Select
                                allowClear
                                showSearch
                                loading={getCountryLoader}
                                onClear={handleClearState}
                                placeholder="Select Country"
                                optionFilterProp="children"
                                className=""
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                onChange={(value, option) =>
                                  handleCountryChange(
                                    value,
                                    option,
                                    index,
                                    field,
                                    form
                                  )
                                }
                                options={country}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label="State" name={[index, "state_id"]}>
                              <Select
                                allowClear
                                showSearch
                                loading={getAllStatesIndexLoader[index]}
                                disabled={!countrySelections[index]}
                                placeholder="Select State"
                                optionFilterProp="children"
                                className=""
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                options={stateData}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              normalize={(value) => {
                                return value.replace(/^\s+/g, "")
                                  .replace(/\s+/g, " ")
                                  .replace(/[^a-zA-Z\s-]/g, "");
                              }} label="City" name={[index, "city"]}>
                              <Input placeholder="Enter City" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Pin Code"
                              normalize={(value) => value.replace(/\s+/g, "")}
                              name={[index, "zip_code"]}
                            >
                              <Input max={8} type="number" aria-controls="false" placeholder="Enter Pin Code" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <AddPopover
                              content={() => showAddAddressTypeForm(index)}
                              isOpen={addressTypePopovers[index]}
                              onOpenChange={(visible) => {
                                if (!visible) {
                                  closeAddressTypePopover(index);
                                }
                              }}
                              text="Add Address Type"
                              showModal={() => toggleAddressTypePopover(index)}
                            />
                            <Form.Item
                              label="Address Type"
                              name={[index, "address_type_master_id"]}
                            >
                              <Select
                                allowClear
                                showSearch
                                loading={getAddressTypeLoader}
                                disabled={getAddressTypeLoader}
                                placeholder="Select Address Type"
                                optionFilterProp="children"
                                className=""
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                options={addressTypeOptions}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      )}
                    </Card>
                  );
                })}

                <div
                  onClick={() => add()}
                  className={`inline-flex mt-5 cursor-pointer justify-end ml-auto w-full  items-center gap-x-2 ${false || false ? "opacity-50 pointer-events-none" : ""
                    }`}
                >
                  <LuPlus className="h-[18px] text-brand w-[18px]" />

                  <span className="text-brand text-base">Add New Address</span>
                </div>
              </>
            );
          }}
        </Form.List>

        <Card
          title={
            <h1 className="lg:text-lg text-base font-popinsRegular text-brand">
              Company Identification and Compliance
            </h1>
          }
          className="main-card mt-5 w-full"
        >
          {getBusinessPartnerDetailLoader ? (
            <SkeletonUpdateCard length={5} />
          ) : (
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="ERP Code"
                  name="erp_code"
                  normalize={(value) => {
                    const sanitizedValue = value.replace(/\s+/g, "");
                    if (/^[a-zA-Z0-9]*$/.test(sanitizedValue)) {
                      return sanitizedValue.slice(0, 20); // Limit to 20 characters
                    }
                    return sanitizedValue.slice(0, -1);
                  }}

                >
                  <Input max={20} placeholder="Enter ERP Code" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="GST Number"
                  name="gst_number"
                  rules={[
                    {
                      required: false,
                      pattern:
                        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}[Z]{1}[A-Z0-9]{1}$/,
                      message: "Please enter valid gst number!",
                    },
                  ]}
                >
                  <Input placeholder="Enter GST Number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="PAN Card No."
                  name="pan_card_no"
                  normalize={(value) => value.replace(/\s+/, "")}
                  rules={[
                    {
                      pattern: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                      message: "Please enter valid pan card no!",
                    },
                  ]}
                >
                  <Input placeholder="Enter PAN Card No." />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="MSME Number"
                  name="msme_number"
                  rules={[
                    {
                      required: false,
                      message: "Please enter msme number!",
                    },
                  ]}
                  normalize={(value = "") => {
                    const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, "").slice(0, 20);
                    return sanitizedValue;
                  }}
                >
                  <Input placeholder="Enter MSME Number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="FSSAI"
                  name="fssai"
                  normalize={(value = "") => {
                    // Remove non-alphanumeric characters and limit to 14 digits
                    const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, "").slice(0, 14);
                    return sanitizedValue;
                  }}
                  rules={[
                    {
                      required: false,
                      message: "Please enter fssai!",
                    },
                  ]}
                >
                  <Input placeholder="Enter FSSAI" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="CIN No."
                  name="cin_no"
                  normalize={(value = "") => {
                    const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, "").slice(0, 21);
                    return sanitizedValue;
                  }}
                >
                  <Input placeholder="Enter CIN No." />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Popover
                  placement="bottomRight"
                  content={addTypeofCompanyPopover}
                  trigger="click"
                  open={addTypeofCompanyPopoverOpen}
                  onOpenChange={(visible) => {
                    if (!visible) {
                      setAddTypeofCompanyPopoverOpen(false);
                      typeofCompanyForm.resetFields();
                    }
                  }}
                >
                  <div
                    className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                    onClick={() =>
                      setAddTypeofCompanyPopoverOpen(
                        !addTypeofCompanyPopoverOpen
                      )
                    }
                  >
                    <Image
                      src={plusSvg}
                      alt="image"
                      height={20}
                      width={20}
                      preview={false}
                    />
                    <h1 className="text-[14px] font-popinsRegular ml-1">
                      Add Company Type
                    </h1>
                  </div>
                </Popover>
                <Form.Item label="Company Type" name="type_of_company_id">
                  <Select
                    allowClear
                    showSearch
                    // mode="multiple"
                    placeholder="Select Company Type"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    loading={getTypeOfCompanyLoader}
                    disabled={getTypeOfCompanyLoader}
                    options={typeofCompanies}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Popover
                  placement="bottomRight"
                  content={addIndustryPopover}
                  trigger="click"
                  open={addIndustryPopoverOpen}
                  onOpenChange={(visible) => {
                    if (!visible) {
                      setAddIndustryPopoverOpen(false);
                      addIndustryForm.resetFields();
                    }
                  }}
                >
                  <div
                    className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                    onClick={showAddIndustryPopoverOpen}
                  >
                    <Image
                      src={plusSvg}
                      alt="image"
                      height={20}
                      width={20}
                      preview={false}
                    />
                    <h1 className="text-[14px] font-popinsRegular ml-1">
                      Add Industry
                    </h1>
                  </div>
                </Popover>
                <Form.Item
                  label="Industry"
                  name="industry_master_id"
                  rules={[
                    {
                      required: false,
                      message: "Please select industry!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select Industry"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={industryOptions}
                    disabled={getIndustryLoader}
                    loading={getIndustryLoader}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Company Size"
                  name="company_size"
                  rules={[
                    {
                      required: false,
                      message: "Please enter company size!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select Company Size"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[
                      {
                        value: "Small",
                        label: "Small",
                      },
                      {
                        value: "Medium",
                        label: "Medium",
                      },
                      {
                        value: "Large",
                        label: "Large",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Revenue"
                  name="revenue"
                  normalize={(value = "") => {
                    const sanitizedValue = value.replace(/[^0-9₹$€]/g, "");
                    return sanitizedValue;
                  }}
                >
                  <Input placeholder="Enter Revenue" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Sales Volume"
                  name="sales_volume"
                  normalize={(value = "") => {
                    const sanitizedValue = value.replace(/[^0-9]/g, "");
                    return sanitizedValue;
                  }}
                  rules={[
                    {
                      required: false,
                      message: "Please enter sales volume!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Sales Volume" />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Card>

        <Card
          title={
            <h1 className="lg:text-lg text-base font-popinsRegular text-brand">
              Agreement Details
            </h1>
          }
          className="main-card mt-5 w-full"
        >
          {getBusinessPartnerDetailLoader ? (
            <SkeletonUpdateCard length={3} />
          ) : (
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Agreement Duration"
                  name="agreement_duration"
                  normalize={(value) => {
                    return value.replace(/^\s+/g, "")
                      .replace(/\s+/g, " ")
                      .replace(/[^a-zA-Z0-9 .&-]/g, "");
                  }}
                  rules={[
                    {
                      required: false,
                      message: "Please enter agreement duration!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Agreement Duration" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  normalize={(value = "") => {
                    const trimmedValue = value.replace(/^\s+/, "");
                    const sanitizedValue = trimmedValue.replace(/[^a-zA-Z0-9.% ]/g, "");
                    return sanitizedValue;
                  }}
                  label="Commission Structure"
                  name="commission_structure"
                >
                  <Input placeholder="Enter Commission Structure" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  normalize={(value = "") => {
                    const trimmedValue = value.replace(/^\s+/, "");
                    const sanitizedValue = trimmedValue.replace(/[^\w\s.,!?;'"-]/g, "");
                    return sanitizedValue;
                  }}

                  label="Terms and Conditions"
                  name="terms_and_conditions"
                >
                  <Input.TextArea placeholder="Enter Terms And Conditions" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  normalize={(value = "") => {
                    const trimmedValue = value.replace(/^\s+/, "");
                    const sanitizedValue = trimmedValue.replace(/[^\w\s.,!?;'"-]/g, "");
                    return sanitizedValue;
                  }}
                  label="Feedback Mechanism"
                  name="feedback_mechanism"
                >
                  <Input placeholder="Enter Feedback Mechanism" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Renewal Process"
                  name="renewal_process"
                  normalize={(value = "") => value.replace(/^\s+/, "")}
                >
                  <Input placeholder="Enter Renewal Process" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Termination Conditions"
                  name="termination_conditions"
                  normalize={(value = "") => value.replace(/^\s+/, "")}

                >
                  <Input placeholder="Enter Termination Conditions" />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Card>

        <Card
          title={
            <h1 className="lg:text-lg text-base font-popinsRegular text-brand">
              Bank Details
            </h1>
          }
          className="main-card mt-5 w-full"
        >
          {getBusinessPartnerDetailLoader ? (
            <SkeletonUpdateCard length={3} />
          ) : (
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Bank Name"
                  normalize={(value = "") => {
                    const trimmedValue = value.replace(/^\s+/, "");
                    const sanitizedValue = trimmedValue.replace(/[^a-zA-Z0-9\s]/g, "");
                    return sanitizedValue;
                  }}
                  name="bank_name"
                >
                  <Input placeholder="Enter Bank Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Branch Name"
                  name="branch_name"
                  normalize={(value = "") => {
                    const trimmedValue = value.replace(/^\s+/, "");
                    const sanitizedValue = trimmedValue.replace(/[^a-zA-Z0-9\s]/g, "");
                    return sanitizedValue;
                  }}
                  rules={[
                    {
                      required: false,
                      message: "Please enter branch name!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Branch Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Account Type" name="account_type">
                  <Select

                    allowClear
                    showSearch
                    placeholder="Select Account Type"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[
                      {
                        value: "Savings Account",
                        label: "Savings Account",
                      },
                      {
                        value: "Current Account",
                        label: "Current Account",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item

                  normalize={(value = "") => {
                    // Remove all non-alphanumeric characters and limit to 11 characters
                    const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, "").slice(0, 11);
                    return sanitizedValue;
                  }}
                  label="IFSC" name="ifsc">
                  <Input placeholder="Enter IFSC" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  normalize={(value = "") => {
                    // Remove all non-numeric characters and limit to 16 digits
                    const sanitizedValue = value.replace(/[^0-9]/g, "").slice(0, 16);
                    return sanitizedValue;
                  }}
                  label="Account Number" name="account_number">
                  <Input placeholder="Enter Account Number" />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Card>

        <Card
          title={
            <h1 className="lg:text-lg text-base font-popinsRegular text-brand">
              Documents / Attachments
            </h1>
          }
          className="main-card mt-5 w-full"
        >
          {getBusinessPartnerDetailLoader ? (
            <SkeletonUpdateCard length={2} />
          ) : (
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  className="text-end"
                  label="GSTN Certificate"
                  name="gstn_certificate_attachment"
                  layout="horizontal"
                >
                  {gstnCertificate ? (
                    <div className="h-[50px] flex items-center ml-auto w-7/12 p-2 px-2  rounded-md bg-gray/20 listtype gap-x-2">
                      {renderFileIcon(gstnCertificate)}

                      <Typography.Paragraph
                        style={{ marginBottom: 0 }}
                        ellipsis={{ rows: 1 }}
                      >
                        {extractFilename(gstnCertificate)}
                      </Typography.Paragraph>

                      <Button
                        onClick={() => setGstnCertificate("")}
                        danger
                        size="small"
                        style={{
                          height: 30,
                          width: 30,
                          marginLeft: "auto",
                          border: "none",
                        }}
                      >
                        <DeleteOutlined style={{ color: "red" }} />
                      </Button>
                    </div>
                  ) : (
                    <Upload
                      className="custom-upload show-remove-icon"
                      maxCount={1}
                      {...gstnCertificateProps}
                      accept=".png,.jpg,.jpeg,.pdf,.doc"
                    >
                      <Button
                        className="upload-button text-end"
                        icon={
                          <TbFileUpload
                            color="#4567ff"
                            className="h-[18px] w-[18px] font-bold text-[#4567ff]"
                          />
                        }
                      >
                        <p className="text-[#4567ff]">Upload</p>
                      </Button>
                    </Upload>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className="text-end"
                  label="PAN Card"
                  name="pan_card_attachment"
                  layout="horizontal"
                  rules={[
                    {
                      required: false,
                      message: "Please enter pan card!",
                    },
                  ]}
                >
                  {panCard ? (
                    <div className="h-[50px] flex items-center ml-auto w-7/12 p-2 px-2  rounded-md bg-gray/20 listtype gap-x-2">
                      {renderFileIcon(panCard)}
                      <Typography.Paragraph
                        style={{ marginBottom: 0 }}
                        ellipsis={{ rows: 1 }}
                      >
                        {extractFilename(panCard)}
                      </Typography.Paragraph>

                      <Button
                        onClick={() => setPanCard("")}
                        danger
                        size="small"
                        style={{
                          height: 30,
                          width: 30,
                          marginLeft: "auto",
                          border: "none",
                        }}
                      >
                        <DeleteOutlined style={{ color: "red" }} />
                      </Button>
                    </div>
                  ) : (
                    <Upload
                      className="custom-upload show-remove-icon"
                      maxCount={1}
                      {...panCardProps}
                      accept=".png,.jpg,.jpeg,.pdf,.doc"
                    >
                      <Button
                        className="upload-button text-end"
                        icon={
                          <TbFileUpload
                            color="#4567ff"
                            className="h-[18px] w-[18px] font-bold text-[#4567ff]"
                          />
                        }
                      >
                        <p className="text-[#4567ff]">Upload</p>
                      </Button>
                    </Upload>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className="text-end"
                  label="Cancelled Cheque"
                  name="cancelled_cheque_attachment"
                  layout="horizontal"
                  rules={[
                    {
                      required: false,
                      message: "Please enter cancelled cheque!",
                    },
                  ]}
                >
                  {cancelledCheque ? (
                    <div className="h-[50px] flex items-center ml-auto w-[60%] p-2 px-2  rounded-md bg-gray/20 listtype gap-x-2">
                      {renderFileIcon(cancelledCheque)}

                      <Typography.Paragraph
                        style={{ marginBottom: 0 }}
                        ellipsis={{ rows: 1 }}
                      >
                        {extractFilename(cancelledCheque)}
                      </Typography.Paragraph>

                      <Button
                        onClick={() => setCancelledCheque("")}
                        danger
                        size="small"
                        style={{
                          height: 30,
                          width: 30,
                          marginLeft: "auto",
                          border: "none",
                        }}
                      >
                        <DeleteOutlined style={{ color: "red" }} />
                      </Button>
                    </div>
                  ) : (
                    <Upload
                      className="custom-upload show-remove-icon"
                      maxCount={1}
                      {...cancelledChequeProps}
                      accept=".png,.jpg,.jpeg,.pdf,.doc"
                    >
                      <Button
                        className="upload-button text-end"
                        icon={
                          <TbFileUpload
                            color="#4567ff"
                            className="h-[18px] w-[18px] font-bold text-[#4567ff]"
                          />
                        }
                      >
                        <p className="text-[#4567ff]">Upload</p>
                      </Button>
                    </Upload>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className="text-end"
                  label="MSME Details"
                  name="msme_details_attachment"
                  layout="horizontal"
                  rules={[
                    {
                      required: false,
                      message: "Please enter msme details!",
                    },
                  ]}
                >
                  {msmeDetail ? (
                    <div className="h-[50px] flex items-center ml-auto w-[60%] p-2 px-2  rounded-md bg-gray/20 listtype gap-x-2">
                      {renderFileIcon(msmeDetail)}

                      <Typography.Paragraph
                        style={{ marginBottom: 0 }}
                        ellipsis={{ rows: 1 }}
                      >
                        {extractFilename(msmeDetail)}
                      </Typography.Paragraph>

                      <Button
                        onClick={() => setMsmeDetail("")}
                        danger
                        size="small"
                        style={{
                          height: 30,
                          width: 30,
                          marginLeft: "auto",
                          border: "none",
                        }}
                      >
                        <DeleteOutlined style={{ color: "red" }} />
                      </Button>
                    </div>
                  ) : (
                    <Upload
                      className="custom-upload show-remove-icon"
                      maxCount={1}
                      {...msmeProps}
                      accept=".png,.jpg,.jpeg,.pdf,.doc"
                    >
                      <Button
                        className="upload-button text-end"
                        icon={
                          <TbFileUpload
                            color="#4567ff"
                            className="h-[18px] w-[18px] font-bold text-[#4567ff]"
                          />
                        }
                      >
                        <p className="text-[#4567ff]">Upload</p>
                      </Button>
                    </Upload>
                  )}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Contract"
                  name="contract_attachements"
                  rules={[
                    {
                      required: false,
                      message: "Please select contract!",
                    },
                  ]}
                >
                  <Dragger
                    className="dragger-custom show-remove-icon"
                    accept=".png,.jpg,.jpeg,.pdf,.doc"
                    {...contractProps}
                  >
                    <div className="py-[30px]">
                      <Image src={attechment} alt="image" preview={false} />
                      <p className="text-[16px] font-popinsRegular text-[#7C7C7C]">
                        Facility to Attach Documents
                      </p>
                    </div>
                  </Dragger>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Other Certificates"
                  name="other_certificates_attachments"
                  rules={[
                    {
                      required: false,
                      message: "Please select other certificates!",
                    },
                  ]}
                >
                  <Dragger
                    {...otherCertificatesProps}
                    className="dragger-custom show-remove-icon"
                  >
                    <div className="py-[30px]">
                      <Image src={attechment} alt="image" preview={false} />
                      <p className="text-[16px] font-popinsRegular text-[#7C7C7C]">
                        Facility to Attach Documents
                      </p>
                    </div>
                  </Dragger>
                </Form.Item>
              </Col>
            </Row>
          )}
        </Card>

        <Card
          title={
            <h1 className="lg:text-lg text-base font-popinsRegular text-brand">
              Contact Information
            </h1>
          }
          className="main-card mt-5 w-full"
        >
          <h1 className="lg:text-lg text-base font-popinsRegular text-brand  mb-2">
            Primary Contact
          </h1>
          {getBusinessPartnerDetailLoader ? (
            <SkeletonUpdateCard length={2} />
          ) : (
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Primary Contact Name"
                  name="primary_contact_name"
                  rules={[
                    {
                      required: false,
                      message: "Please enter primary contact name!",
                    },
                  ]}
                  normalize={sanitizeName}
                >
                  <Input placeholder="Enter Primary Contact Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Primary Contact Email"
                  name="primary_contact_email"
                  rules={[
                    {
                                      pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,

                      message: "Please enter a valid email!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Primary Contact Email" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="primary_contact_phone"
                  normalize={(value) => value && value.replace(/[^+\d]/g, "")}
                  label="Primary Contact Phone"
                  className="mb-[12px]"
                  size="small"
                  rules={[
                    {
                      max: 15,
                      message: "Phone number cannot be longer than 15 digits",
                    },
                  ]}
                >
                  <Input
                    // rows={3}
                    style={{ background: "white" }}
                    placeholder="Enter Primary Contact Phone"
                    className="indent-5 begin-input bg-white  focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          <h1 className="lg:text-lg text-base font-popinsRegular text-brand mt-4 mb-2">
            Secondary Contact
          </h1>
          {getBusinessPartnerDetailLoader ? (
            <SkeletonUpdateCard length={2} />
          ) : (
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Secondary Contact Name"
                  name="secondary_contact_name"
                  rules={[
                    {
                      required: false,
                      message: "Please enter secondary contact name!",
                    },
                  ]}
                  normalize={sanitizeName}
                >
                  <Input placeholder="Enter Secondary Contact Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Secondary Contact Email"
                  name="secondary_contact_email"
                  rules={[
                    {
                                      pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,

                      message: "Please enter a valid email!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Secondary Contact Email" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="secondary_contact_phone"
                  normalize={(value) => value && value.replace(/[^+\d]/g, "")}
                  label="Secondary Contact Phone"
                  className="mb-[12px]"
                  size="small"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value && value.length > 15) {
                          return Promise.reject(
                            "Please enter a valid contact number!"
                          );
                        }

                        // You can add additional conditions if needed
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    // rows={3}
                    style={{ background: "white" }}
                    placeholder="Enter Secondary Contact Phone"
                    className="indent-5 begin-input bg-white  focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Card>
        <Row gutter={24}>
          <Col
            span={24}
            className="flex items-center my-4 justify-center gap-x-5"
          >
            <Form.Item>
              <Button
                onClick={async () => {
                  navigate(-1);
                }}
                type="button"
                className="gray-button text-white hover:text-white  min-h-[45px] min-w-[120px]"
                htmlType="button"
              >
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                loading={
                  addBusinessPartnerLoader || updateBusinessPartnerLoader
                }
                type="primary"
                className="root-btn min-h-[45px] min-w-[120px]"
                htmlType="submit"
              >
                {businessPartnerId ? "Update" : "Save"}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {/* </Spin> */}
    </section >
    // </Spin>
  );
};

export default CreateBusinessPartner;
