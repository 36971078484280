import {
  CalendarOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Dropdown,
  Flex,
  Image,
  Popconfirm,
  Typography
} from "antd";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import EventsIcon from "../../../assets/img/eventsIcon.svg";
import usePermission from "../../../hooks/use-permission";
import {
  actionCancleEvent,
  actionClearAllEventsData,
  actionDeleteEvent,
  actionGetAllEvents,
  actionGetEvents,
} from "../../../store/services/eventService";
import { handleActionWithPermission } from "../../../store/slices/authSlice";
import CommonTable from "../../common/CommonTable";
import SortIcon from "../../common/SortIcon";

const Events = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const offsetRef = useRef(0);

  const {
    eventsData,
    getEventsLoader,
    eventsDataCount,
    allEventsData,
    getAllEventsLoader,
    deleteEventsLoader,
    cancleEventLoader,
  } = useSelector((state) => state.events);

  const [search, setSearch] = useState("");
  const [selectedLabel, setSelectedLabel] = useState("All");
  const [lastSearch, setLastSearch] = useState('');
  const [pageSize, setPageSize] = useState(20);
  const { hasPermission } = usePermission();

  const handleLabelClick = (label) => {
    setSelectedLabel(label);
    dispatch(
      actionGetEvents({
        offset: offsetRef.current,
        limit: pageSize,
        search,
        eventStatus: label !== "All" ? label : "",
      })
    );
  };

  useEffect(() => {
    dispatch(
      actionGetEvents({
        offset: offsetRef.current,
        limit: pageSize,
        search,
        eventStatus: selectedLabel !== "All" ? selectedLabel : "",
      })
    );
  }, [dispatch]);

  const getStatusColor = (status) => {
    switch (status) {
      case "Running":
        return "text-green-500";
      case "Upcoming":
        return "text-blue-500";
      case "Past":
        return "text-slate-500";
      case "Cancelled":
        return "text-red-500";
      case "All":
        return "text-black";
      default:
        return "text-black";
    }
  };

  const initialColumns = [
    {
      title: "CH No.",
      key: "ch_no",
      width: 100,
      dataIndex: "event_id",
      fixed: "left",
      render: (text) =>
        <div
          onClick={() => {
            dispatch(
              handleActionWithPermission("events", "read", () => {
                localStorage.setItem("event_id", text);
                navigate(
                  `/event-overview/${text}`
                );
              })
            );
          }}
          className="flex text-brand items-center cursor-pointer"
        >
          {text ?? "-"}
        </div>,
    },
    {
      title: "Event Name",
      key: "1",
      dataIndex: "event_name",
      fixed: "left",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 250,
      render: (text, data) => (
        <div
          className="flex items-center cursor-pointer"
          onClick={() => {
            dispatch(
              handleActionWithPermission("events", "read", () => {
                localStorage.setItem("event_id", data.event_id);
                navigate(
                  `/event-overview/${data.event_id}`
                );
              })
            );
          }}
        >
          <p className="text-brand">
            {data.event_name.charAt(0).toUpperCase() + data.event_name.slice(1)}
          </p>
        </div>
      ),
    },
    {
      title: "Events Type",
      dataIndex: "event_type",
      key: "2",
      width: 200,
      render: (text, data) => (
        <span>{data.event_type ? data.event_type.event_type : "-"}</span>
      ),
    },
    {
      title: "Start Date & Time",
      dataIndex: "start_date",
      key: "4",
      width: 250,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span>
          {`${data.start_date ? dayjs(data.start_date).format("DD-MM-YYYY") : ""
            } 
          ${data.start_time
              ? dayjs(data.start_time, "HH:mm").format("h:mm A")
              : ""
            }`}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "event_status",
      key: "3",
      width: 200,
      render: (text, data) => (
        <span className={getStatusColor(data.event_status)}>
          {data.event_status ? data.event_status : "-"}
        </span>
      ),
    },

    {
      title: "Location",
      dataIndex: "address",
      // ellipsis:true,
      key: "5",
      width: 250,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1 }}
          className="!mb-0"
          title={data.address}
          style={{ width: 200 }}
        >
          {data.address ? data.address : "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Organizer",
      dataIndex: "event_organizer",
      key: "6",
      width: 200,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span>{data.event_organizer ? data.event_organizer : "-"}</span>
      ),
    },
    {
      title: "Created by",
      key: "7",
      dataIndex: "created_by",
      width: 200,
      render: (text, data) => (
        <div className="flex items-center">
          <Avatar
            style={{
              verticalAlign: "middle",
            }}
            size={30}
            src={data.created_by.profile_pic}
          />
          <p className="ml-2">
            {data.created_by.first_name && data.created_by.last_name
              ? data.created_by.first_name + " " + data.created_by.last_name
              : data.created_by.first_name}
          </p>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 120,
      align: "center",
      fixed: "right",
      render: (text, data) => (
        <>
          <Dropdown
            id="custom-drop"
            menu={{
              items: [
                {
                  label: <span>Edit</span>,
                  key: "Edit",
                  icon: <EditOutlined />,
                  onClick: () => {
                    dispatch(
                      handleActionWithPermission("events", "update", () =>
                        navigate("/update-event", {
                          state: { eventId: data.event_id },
                        })
                      )
                    );
                  },
                },
                data.event_status !== "Cancelled" && {
                  label: hasPermission("events", "update") ? (
                    <Popconfirm
                      okButtonProps={{
                        loading: cancleEventLoader,
                      }}
                      placement="topLeft"
                      title="Cancle Event"
                      description={`Are you sure to cancle this event?`}
                      onConfirm={() => {
                        dispatch(
                          actionCancleEvent({
                            id: data.event_id,
                            eventStatus: "Cancelled",
                          })
                        );
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <span>Cancle Event</span>
                    </Popconfirm>
                  ) : (
                    <span
                      onClick={() => {
                        dispatch(
                          handleActionWithPermission("events", "update")
                        );
                      }}
                    >
                      Cancle Event
                    </span>
                  ),
                  key: "cancle",
                  icon: <CalendarOutlined />,
                },
                {
                  label: hasPermission("events", "delete") ? (
                    <Popconfirm
                      okButtonProps={{
                        loading: deleteEventsLoader,
                      }}
                      placement="topLeft"
                      title="Delete"
                      description={`Are you sure to delete this event?`}
                      onConfirm={() => {
                        dispatch(
                          actionDeleteEvent({
                            id: data.event_id,
                            offset: offsetRef.current,
                            search: search,
                          })
                        );
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <div
                        className="flex items-center"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <DeleteOutlined
                          style={{ fontSize: 12, color: "#ff4d4f" }}
                        />
                        <span
                          style={{
                            marginLeft: 8,
                            color: "#ff4d4f",
                          }}
                        >
                          Delete
                        </span>
                      </div>
                    </Popconfirm>
                  ) : (
                    <div
                      className="flex items-center"
                      onClick={() => {
                        dispatch(
                          handleActionWithPermission("events", "delete")
                        );
                      }}
                    >
                      <DeleteOutlined
                        style={{ fontSize: 12, color: "#ff4d4f" }}
                      />
                      <span
                        style={{
                          marginLeft: 8,
                          color: "#ff4d4f",
                        }}
                      >
                        Delete
                      </span>
                    </div>
                  ),
                  key: "Delete",

                  onClick: () => { },
                },
              ],
            }}
          >
            <EllipsisOutlined />
          </Dropdown>
        </>
      ),
    },
  ];
  const [exportAll, setExportAll] = useState(false);

  useEffect(() => {
    const trimmedSearch = search?.trim();
    if (trimmedSearch !== "" && trimmedSearch !== lastSearch) {
      const timer = setTimeout(() => {
        dispatch(
          actionGetEvents({
            offset: offsetRef.current,
            limit: pageSize,
            search: trimmedSearch,
            eventStatus: selectedLabel !== "All" ? selectedLabel : "",
          })
        );
        setLastSearch(trimmedSearch);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }

  }, [search, lastSearch]);

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      dispatch(
        actionGetEvents({
          offset: offsetRef.current,
          limit: pageSize,
          search: e.target.value,
          eventStatus: selectedLabel !== "All" ? selectedLabel : "",
        })
      );
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      dispatch(
        actionGetEvents({
          offset: offsetRef.current,
          limit: pageSize,
          search: e.target.value,
          eventStatus: selectedLabel !== "All" ? selectedLabel : "",
        })
      );
    }
  };

  const handleRefresh = () => {
    setSelectedLabel("All");
    offsetRef.current = 0;
    dispatch(
      actionGetEvents({
        offset: offsetRef.current,
        limit: pageSize,
        search
      })
    );
  };

  const exportAllToExcel = async () => {
    const data =
      (await allEventsData) &&
      allEventsData.length > 0 &&
      allEventsData.map((data) => ({
        "Event Name": data.event_name ? data.event_name : "-",
        "Event Type": data.event_type ? data.event_type.event_type : "-",
        "Event Category": data.event_category
          ? data.event_category.event_category
          : "-",
        "Start Date and Time": `${data.start_date ? dayjs(data.start_date).format("DD MMM YYYY") : ""
          } ${data.start_time
            ? dayjs(data.start_time, "HH:mm").format("h:mm A")
            : ""
          }`,
        Location: data.address ? data.address : "-",
        "Events Organizer": data.event_organizer ? data.event_organizer : "-",
        "Created by":
          data.created_by.first_name && data.created_by.last_name
            ? data.created_by.first_name + " " + data.created_by.last_name
            : data.created_by.first_name,
      }));
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Events");
    // Generate the Excel file and initiate the download
    XLSX.writeFile(workbook, "events.xlsx");
    dispatch(actionClearAllEventsData({ setExportAll }));
  };

  useEffect(() => {
    if (exportAll && allEventsData && allEventsData.length > 0) {
      exportAllToExcel();
    }
  }, [allEventsData]);

  const onClickDownloadMenu = () => {
    dispatch(actionGetAllEvents());
    setExportAll(true);
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
            ? "ASC"
            : "",
    };

    dispatch(
      actionGetEvents({
        offset: offsetRef.current,
        limit: pageSize,
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
        eventStatus: selectedLabel !== "All" ? selectedLabel : "",
      })
    );
    // setSortedInfo(sorterParams);
  };

  return (
    <section className="main-wrapper main-table-container ">
      <CommonTable
        moduleKey="event"
        loading={getEventsLoader || getAllEventsLoader}
        columns={initialColumns}
        scrollToFirstRowOnChange={true}
        data={eventsData || []}
        rowKey={"event_id"}
        onChange={handleSortChange}
        handleRefresh={handleRefresh}
        scroll={{ y: false, x: "max-content" }}
        handleSearch={handleSearch}
        handleBlurAndPressEnter={handleBlurAndPressEnter}
        search={search}

        onClickDownloadMenu={onClickDownloadMenu}
        pageTitle={<Flex gap="middle" justify="space-between">
          <div className="text-brand flex items-center gap-x-3">
            <Image
              src={EventsIcon}
              alt="image"
              height={20}
              width={20}
              preview={false}
            />
            <h1 className="lg:text-lg text-base  font-popinsRegular">Events</h1>
          </div>

          <div className="flex items-center">
            <div className="segmented-custom flex items-center">
              {["All", "Running", "Upcoming", "Past", "Cancelled"].map(
                (label) => (
                  <div
                    key={label}
                    className={`${selectedLabel === label
                      ? "segmented-label-selected"
                      : "segmented-label"
                      }`}
                    onClick={() => handleLabelClick(label)}
                  >
                    {label}
                  </div>
                )
              )}
            </div>

          </div>
        </Flex>}
        total={eventsDataCount}
        pageSize={pageSize}
        handlePaginationChange={(page, pageSize) => {
          offsetRef.current = page - 1;
          setPageSize(pageSize);
          dispatch(
            actionGetEvents({
              offset: offsetRef.current,
              limit: pageSize,
              search,
              eventStatus: selectedLabel !== "All" ? selectedLabel : "",
            })
          );
        }}

        searchWidthClass="min-w-[70%]"
        resource="events"
        buttonLink="/create-event"
        buttonText="Create Event"
        title="Events"
      />

    </section>
  );
};

export default Events;
