import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config/web-config";
import { toast } from "sonner";
import { handleErrorMessage } from "../../components/helper/common-function";
import { handleGetResponse } from "./commonService";

//feedback mechanism
//get all feedback mechanism
export const actionGetFeedBackMechanism = createAsyncThunk(
  "actionGetFeedBackMechanism",
  async ({ offset, limit, search, orderByColumn, order, company_user_id }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/crm-users-feedback?limit=${limit}&company_user_id=${company_user_id}&offset=${offset}${search ? `&search=${search}` : ""
        }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${order ? `&order=${order}` : ""
        }`
      );

      console.log(res, "res")
      const { status, message: customMsg, data, total_records } = res.data;
      if (parseInt(status) === 200) {
        return { data, total_records };
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//get all feedback
export const actionGetAllFeedBackMechanism = createAsyncThunk(
  "actionGetAllFeedBackMechanism",
  () => {
    return handleGetResponse(`crm-users-feedback?getall=YES`);
  }
);

//single feedback mechanism
export const actionGetSingleFeedBackMechanism = createAsyncThunk(
  "actionGetSingleFeedBackMechanism",
  async (id) => {
    return handleGetResponse(`crm-users-feedback/${id}`);
  }
);

//add feedback mechanism
export const actionAddFeedBackMechanism = createAsyncThunk(
  "actionAddFeedBackMechanism",
  async ({ req, navigate }, { dispatch }) => {
    try {
      const response = await axios.post(`${BASE_URL}/crm-users-feedback`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate(-1);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//delete feedback
export const actionDeleteFeedBackMechanism = createAsyncThunk(
  "actionDeleteFeedBackMechanism",
  async (
    { feedBackMechanismId, handleCloseDeleteModal },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/crm-users-feedback/${feedBackMechanismId}`
      );
      const { status, message: customMsg } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCloseDeleteModal && handleCloseDeleteModal();
        return feedBackMechanismId;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// update feedback
export const actionUpdateFeedBackMechanism = createAsyncThunk(
  "actionUpdateFeedBackMechanism",
  async ({ id, req, navigate }, { rejectWithValue }) => {
    try {
      const res = await axios.put(`${BASE_URL}/crm-users-feedback/${id}`, req);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate(-1);
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionUpdateStatus = createAsyncThunk(
  "actionUpdateStatus",
  async ({ req, setUpdateId, id }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/crm-users-feedback/${id}`,
        req
      );
      const { data, message: customMsg, status } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg);
        setUpdateId && setUpdateId("");
        // setIsModalOpen && setIsModalOpen(false);
        return data;
      } else {
        toast.error(customMsg);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);
