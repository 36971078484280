import { MailOutlined } from "@ant-design/icons";
import back from "../../assets/img/back.svg";
import { Button, Form, Image, Input } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { actionForgotPassword } from "../../store/services/authService";

function ForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const forgotstatus = useSelector((state) => state.auth);

  const onFinish = (values) => {
    dispatch(actionForgotPassword({ values, navigate }));
  };

  return (
    <div className=" register-container">
      <div className="title mb-4 pt-20 flex flex-col justify-center items-center gap-y-2">
        <p className="xl:text-[30px] lg:text-[26px] md:text-2xl text-xl font-semibold text-secondary font-popins">
          Forgot your password
        </p>
        <p className="lg:text-[19px] text-center text-base text-gray-600">
          Enter the email address you used to register with.
        </p>
      </div>
      {/* <br /> */}
      <Form
        // form={form}
        name="normal_login"
        className="login-form w-full pt-3"
        onFinish={onFinish}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter your email!",
            },
            {
              pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: "Please enter a valid email!",
            },
          ]}
        >
          <Input
            placeholder="Enter your email"
            className="indent-5"
            prefix={
              <MailOutlined
                color="#4567ff"
                className="h-[18px] w-[18px] site-form-item-icon font-bold"
              />
            }
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            className="filled-s-button mt-3 md:text-base text-sm  border-none rounded-[10px]  min-h-[45px]"
            block
            htmlType="submit"
            loading={forgotstatus?.forgotPasswordLoader}
          >
            Submit
          </Button>
        </Form.Item>
        <Form.Item className="mt-1 text-center">
          <Link
            to="/login"
            className="flex items-center justify-center gap-x-2 "
          >
            <Image
              src={back}
              alt="back"
              preview={false}
              className="text-primary/90 "
            />
            <p className="text-primary hover:text-primary/90 md:text-[18px] text-[15px]">
              Back to sign in
            </p>
          </Link>
        </Form.Item>
      </Form>
    </div>
  );
}

export default ForgotPassword;
