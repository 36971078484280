import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { tableRoutes } from '../../components/helper/constants';

const Wrapper = ({ children, isOverview }) => {
    const history = useLocation();
    const { user, userLoader } = useSelector(state => state.auth)
    const currentPath = history.pathname;
    const [hide, setHide] = useState(true);

    useEffect(() => {
        if (user && user.company_users && !userLoader) {
            const shouldHide =
                user.company_users.length === 0 ||
                user.company_users.every(
                    (companyUser) => companyUser.status !== "ACTIVE"
                );
            setHide(shouldHide);
        } else {
            setHide(true);
        }
    }, [user, userLoader]);

    const isTableRoute = tableRoutes.includes(currentPath);

    return (
        <div
            className={`${hide ? "bg-white" : "main-bg"} min-h-64 ${!isTableRoute &&
                currentPath !== "/view-business-partner" &&
                currentPath !== "/" &&
                !isOverview &&
                "px-0 py-6"
                } ${!isTableRoute ? "overflow-auto" : ""}`}
        >
            <div
                className={`my-0 ${!isTableRoute &&
                    currentPath !== "/" &&
                    currentPath !== "/view-business-partner" &&
                    !isOverview
                    ? "2xl:mx-6 xl:mx-3 lg:mx-3 mx-3"
                    : "relative"
                    }`}
            >
                {children}
            </div>
        </div>
    );
};

export default Wrapper;
