import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import {
  Dropdown,
  Image,
  Skeleton,
  Spin,
  Tag,
  Typography
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import requestForQuotation from "../../../assets/img/request-for-quotation.svg";
import {
  actionClearAllRequestForQuotation,
  actionDeleteRequestForQuotation,
  actionGetAllRequestForQuotation,
  actionGetRequestForQuotation,
  actionGetSingleRequestForQuotation,
  actionUpdateRequestForQuotationStatus,
} from "../../../store/services/requestForQuotationService";
import { handleActionWithPermission } from "../../../store/slices/authSlice";
import CommonModal from "../../common/CommonModal";
import CommonTable from "../../common/CommonTable";
import DeleteModal from "../../common/DeleteModal";
import PageTitle from "../../common/PageTitle";
import SortIcon from "../../common/SortIcon";
import { formatString } from "../../helper/common-function";

const RequstForQuotation = () => {
  const [quotationData, setQuotationData] = useState(null);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [exportAll, setExportAll] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [requstForQuotationId, setRequestForQuotationId] = useState(null);
  const navigate = useNavigate(true);
  const [search, setSearch] = useState("");
  const [lastSearch, setLastSearch] = useState('');
  const [pageSize, setPageSize] = useState(20);
  const {
    getRequestForQuotationLoader,
    requestForQuotationDataCount,
    getSingleRequestForQuotationLoader,
    getSingleRequestForQuotationData,
    allRequestForQuotation,
    requestForQuotationData,
    deleteRequestForQuotationLoader,
    allRequestForQuotationLoader,
    updateRequestForQuotationStatusLoader
  } = useSelector((state) => state.requestForQuotation);
  const offsetRef = useRef(0);
  const dispatch = useDispatch();

  const getApiCall = (
    offset = 0,
    limit = pageSize,
    search = "",
    orderByColumn = "",
    order = ""
  ) => {
    dispatch(
      actionGetRequestForQuotation({
        offset,
        limit,
        orderByColumn,
        order,
        search,
      })
    );
  };

  useEffect(() => {
    dispatch(
      actionGetRequestForQuotation({ offset: offsetRef.current, limit: pageSize, search: search })
    );
  }, [dispatch]);

  useEffect(() => {
    if (requstForQuotationId && viewModalOpen) {
      dispatch(
        actionGetSingleRequestForQuotation({
          id: requstForQuotationId,
        })
      );
    }
  }, [dispatch, requstForQuotationId]);


  const handleCellClick = (record) => {
    setRequestForQuotationId(record.request_for_quotation_id);
    setViewModalOpen(true);
  };

  const handleCancel = () => {
    setViewModalOpen(false);
    setRequestForQuotationId(null);
  };


  const getStatusColor = (status) => {
    switch (status) {
      case "DRAFT":
        return "default";
      case "PENDING":
        return "orange";
      case "UNDER_REVIEW":
        return "purple";
      case "APPROVED":
        return "green";
      case "REJECTED":
        return "red";
      case "IN_PROGRESS":
        return "blue";
      case "COMPLETED":
        return "gold";
      case "CANCELLED":
        return "volcano";
      default:
        return "default";
    }
  };


  const handleStatusChange = (newStatus, id) => {
    const req = {
      status: newStatus,
    };
    dispatch(
      actionUpdateRequestForQuotationStatus({
        id,
        req,
      })
    );
  };

  const statusArr = [
    {
      key: "1",
      value: "DRAFT",
      label: "Draft",
    },
    {
      key: "2",
      value: "PENDING",
      label: "Pending",
    },
    {
      key: "3",
      value: "UNDER_REVIEW",
      label: "Under Review",
    },
    {
      key: "4",
      value: "APPROVED",
      label: "Approved",
    },
    {
      key: "5",
      value: "REJECTED",
      label: "Rejected",
    },
    {
      key: "6",
      value: "IN_PROGRESS",
      label: "In Progress",
    },
    {
      key: "7",
      value: "COMPLETED",
      label: "Completed",
    },
    {
      key: "8",
      value: "CANCELLED",
      label: "Cancelled",
    },
  ]


  const columns = [
    {
      title: "CH No.",
      key: "ch_no",
      width: 100,
      dataIndex: "request_for_quotation_id",
      fixed: "left",
      render: (text) =>
        <div
          onClick={() =>
            dispatch(
              handleActionWithPermission("request-for-quotation", "read", () => {
                setRequestForQuotationId(text);
                setViewModalOpen(true);
              })
            )
          }
          className="flex text-brand items-center cursor-pointer"
        >
          {text ?? "-"}
        </div>,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: 200,
      fixed: "left",
      sorter: true,
      sortIcon: () => <SortIcon />,
      onCell: (record) => ({
        onClick: () =>
          dispatch(
            handleActionWithPermission("request-for-quotation", "read", () => {
              handleCellClick(record);
            })
          ),
        style: { cursor: "pointer", color: "#6883FD" },
      }),
      render: (text, record) => (
        <span className="ml-2">
          {record.category && record.category?.category_name
            ? record.category?.category_name
            : "-"}
        </span>
      ),
    },

    {
      title: "Grade",
      dataIndex: "grade",
      key: "grade",
      width: 150,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span className="ml-2">{data.grade ? data.grade : "-"}</span>
      ),
    },
    {
      title: "Thickness",
      dataIndex: "thickness",
      key: "thickness",
      width: 200,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span className="ml-2">{data.thickness ? data.thickness : "-"}</span>
      ),
    },
    {
      title: "Size If Custom-made",
      dataIndex: "custom_made_size",
      key: "custom_made_size",
      width: 250,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span className="ml-2">
          {data.custom_made_size ? data.custom_made_size : "-"}
        </span>
      ),
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      width: 150,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span className="ml-2">{data.sku ? data.sku : "-"}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 250,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (status, record) => {
        const isStatusLoading = updateRequestForQuotationStatusLoader[record.request_for_quotation_id];

        return status ? (
          <div id="dropw">
            {isStatusLoading ? (
              <Spin size="small" loading={isStatusLoading} />
            ) : (
              <Dropdown
                menu={{
                  items: statusArr.filter(
                    (item) =>
                      item.value.toLowerCase() !==
                      status.toLowerCase()
                  ),
                  onClick: (key) => {
                    const selected = statusArr.find(
                      (item) => item.key === key.key
                    );

                    handleStatusChange(selected?.value, record.request_for_quotation_id)
                  },
                }}
                placement="bottom"
                overlayClassName="status-dropdown"
                arrow={{
                  pointAtCenter: true,
                }}
                dropdownRender={(menu) => (
                  <div className="status-dropdown-container">{menu}</div>
                )}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Tag color={getStatusColor(status)}>
                    {/*  {status?.charAt(0).toUpperCase() +
                      status.slice(1).toLowerCase()} */}
                    {formatString(status)}
                  </Tag>
                </a>
              </Dropdown>
            )}
          </div >
        ) : (
          "-"
        );
      },
    },

    {
      title: "Custom Thickness",
      dataIndex: "custom_thickness",
      key: "custom_thickness",
      width: 200,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span className="ml-2">
          {data.custom_thickness ? data.custom_thickness : "-"}
        </span>
      ),
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
      width: 200,

      render: (text, data) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: true }}
          style={{ width: 280, margin: 0 }}
          className="ml-2 !mb-0"
        >
          <span className="ml-2">{data.remark ? data.remark : "-"}</span>
        </Typography.Paragraph>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 100,
      align: "center",
      fixed: "right",
      render: (text, data) => (
        <>
          <Dropdown
            id="custom-drop"
            menu={{
              items: [
                {
                  label: <span>Edit</span>,
                  key: "Edit",
                  icon: <EditOutlined />,
                  onClick: () => {
                    dispatch(
                      handleActionWithPermission(
                        "request-for-quotation",
                        "update",
                        () =>
                          navigate("/update-request-for-quotation", {
                            state: {
                              requestForQuotationId:
                                data.request_for_quotation_id,
                            },
                          })
                      )
                    );
                  },
                },
                {
                  label: "Delete",
                  icon: <DeleteOutlined style={{ fontSize: 12 }} />,
                  danger: true,
                  // onClick: () => setIsDeleteModalOpen(true),
                  onClick: () =>
                    dispatch(
                      handleActionWithPermission(
                        "request-for-quotation",
                        "delete",
                        () => {
                          setIsDeleteModalOpen((prev) => !prev);
                          setRequestForQuotationId(
                            data?.request_for_quotation_id
                          );
                        }
                      )
                    ),
                  key: "Delete",
                },
              ].filter(Boolean),
            }}
          >
            <div className="flex justify-center items-center">
              <EllipsisOutlined />
            </div>
          </Dropdown>
        </>
      ),
    },
  ];

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.columnKey,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
            ? "ASC"
            : "",
    };

    getApiCall(
      offsetRef.current,
      pageSize,
      search,
      sorterParams.field,
      sorterParams.order
    );
  };

  const handleRefresh = () => {
    offsetRef.current = 0;
    getApiCall(offsetRef.current, pageSize, search);
  };

  useEffect(() => {
    const trimmedSearch = search?.trim();
    if (trimmedSearch !== "" && trimmedSearch !== lastSearch) {
      const timer = setTimeout(() => {
        getApiCall(offsetRef.current, pageSize, trimmedSearch);
        setLastSearch(trimmedSearch);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }

  }, [search, lastSearch]);

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      getApiCall(offsetRef.current, pageSize, e.target.value);
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      getApiCall(offsetRef.current, pageSize, e.target.value);
    }
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setRequestForQuotationId(null);
  };

  const onClickDownloadMenu = () => {
    dispatch(actionGetAllRequestForQuotation());
    setExportAll(true);
  };

  const exportAllToExcel = async () => {
    const data =
      (await allRequestForQuotation) &&
      allRequestForQuotation.length > 0 &&
      allRequestForQuotation.map((data) => ({
        Category: data.category?.category_name
          ? data.category?.category_name
          : "-",
        Grade: data?.grade ? data?.grade : "-",
        Thickness: data.thickness ? data.thickness : "-",
        Sizeifcustommade: data.custom_made_size ? data.custom_made_size : "-",
        sku: data.sku ? data.sku : "-",
        Status: data.status ? data.status : "-",
        CustomThickness: data.custom_thickness ? data.custom_thickness : "-",
        Remark: data.remark ? data.remark : "-",
      }));
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Request For Quotation");
    XLSX.writeFile(workbook, "request-for-quotation.xlsx");
    dispatch(actionClearAllRequestForQuotation({ setExportAll }));
  };

  useEffect(() => {
    if (
      exportAll &&
      allRequestForQuotation &&
      allRequestForQuotation.length > 0
    ) {
      exportAllToExcel();
    }
  }, [allRequestForQuotation]);

  const handlePaginationChange = (page, pageSize) => {
    offsetRef.current = page - 1;
    setPageSize(pageSize);
    getApiCall(offsetRef.current, pageSize, search);
  }

  return (
    <>
      <div id="sales-orders" className="main-table-container ">
        <CommonTable
          moduleKey="request_for_quotation"
          title="Request For Quotation"
          total={requestForQuotationDataCount}
          offset={offsetRef.current}
          pageSize={pageSize}
          pageTitle={<PageTitle
            margin={false}
            image={requestForQuotation}
            title="Request For Quotation"
            rightIcon={false}
          />}
          buttonText="Create Request For Quotation"
          buttonLink="/create-request-for-quotation"
          resource="request-for-quotation"
          loading={getRequestForQuotationLoader || allRequestForQuotationLoader}
          columns={columns}
          scrollToFirstRowOnChange={true}
          data={(requestForQuotationData || [])}
          rowKey={"request_for_quotation_id"}
          onChange={handleSortChange}
          handleRefresh={handleRefresh}
          handleSearch={handleSearch}
          handleBlurAndPressEnter={handleBlurAndPressEnter}
          search={search}
          scroll={{ y: false }}
          onClickDownloadMenu={onClickDownloadMenu}
          handlePaginationChange={handlePaginationChange}
        />

        <CommonModal
          className=""
          centered
          open={viewModalOpen}
          title=""
          // loading={getSingleRequestForQuotationLoader}
          onCancel={handleCancel}
          handleReset={handleCancel}
          onOk={handleCancel}
        >
          {/* <Spin spinning={getSingleRequestForQuotationLoader}> */}
          <div className="text-brand flex items-center gap-x-3 mb-2">
            <Image
              src={requestForQuotation}
              alt="image"
              height={20}
              width={20}
              preview={false}
            />
            <h1 className="lg:text-lg text-base font-popinsRegular">
              View Request For Quotation
            </h1>
          </div>
          {getSingleRequestForQuotationLoader ? (
            <>
              {Array.from({ length: 8 }).map((item) => (
                <div className="flex items-center gap-x-4 w-full skeleten-div">
                  <Skeleton.Input
                    active
                    size="small"

                    className="skeleton-first-input"
                    style={{ height: "19px", paddingBottom: "5px" }}
                  />
                  <Skeleton.Input
                    active
                    size="small"
                    className="skeleton-last-input "

                    style={{
                      height: "19px",
                      marginTop: "3px",
                    }}
                  />
                </div>
              ))}
            </>
          ) : (
            getSingleRequestForQuotationData && (
              <div>
                <div className="flex flex-col mt-3 gap-y-4">
                  <div className="flex gap-x-6 items-center gap-y-1">
                    <p className="text-xs text-[#818181] w-[30%]">Category:</p>
                    <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                      {getSingleRequestForQuotationData.category &&
                        getSingleRequestForQuotationData.category?.category_name
                        ? getSingleRequestForQuotationData.category
                          ?.category_name
                        : "-"}
                    </p>
                  </div>
                  <div className="flex gap-x-6 items-center gap-y-1">
                    <p className="text-xs text-[#818181] w-[30%]">Grade:</p>
                    <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                      {getSingleRequestForQuotationData.grade ?? "-"}
                    </p>
                  </div>
                  <div className="flex gap-x-6 items-center gap-y-1">
                    <p className="text-xs text-[#818181] w-[30%]">Thickness:</p>
                    <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                      {getSingleRequestForQuotationData.thickness ?? "-"}
                    </p>
                  </div>
                  <div className="flex gap-x-6 items-center gap-y-1">
                    <p className="text-xs text-[#818181] w-[30%]">
                      Size if custom-made:
                    </p>
                    <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                      {getSingleRequestForQuotationData.custom_made_size ?? "-"}
                    </p>
                  </div>
                  <div className="flex gap-x-6 items-center gap-y-1">
                    <p className="text-xs text-[#818181] w-[30%]">SKU:</p>
                    <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                      {getSingleRequestForQuotationData.sku ?? "-"}
                    </p>
                  </div>
                  <div className="flex gap-x-6 items-center gap-y-1">
                    <p className="text-xs text-[#818181] w-[30%]">
                      Custom Thickness:
                    </p>
                    <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                      {getSingleRequestForQuotationData.custom_thickness ?? "-"}
                    </p>
                  </div>
                  <div className="flex gap-x-6 items-center gap-y-1">
                    <p className="text-xs text-[#818181] w-[30%]">Status:</p>
                    <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                      {getSingleRequestForQuotationData.status ?? "-"}
                    </p>
                  </div>
                  <div className="flex gap-x-6 items-center gap-y-1">
                    <p className="text-xs text-[#818181] w-[30%]">Remark:</p>
                    <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                      {getSingleRequestForQuotationData.remark ?? "-"}
                    </p>
                  </div>
                </div>
              </div>
            )
          )}

        </CommonModal>
      </div>

      <DeleteModal
        title="Are you sure you want to remove this ?"
        description="All the associations will be removed for this Quotation"
        loading={deleteRequestForQuotationLoader}
        onCancel={() => {
          setIsDeleteModalOpen(false);
          setRequestForQuotationId(null);
        }}
        onConfirm={() =>
          dispatch(
            actionDeleteRequestForQuotation({
              requstForQuotationId,
              handleCloseDeleteModal,
            })
          )
        }
        isVisible={isDeleteModalOpen}
      />
    </>
  );
};

export default RequstForQuotation;
