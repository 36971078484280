import { Card, ConfigProvider, Form, Grid, Layout, Menu, Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import materialActiveImg from "../../../assets/img/sidebar/material-active-icon.svg";
import materialImg from "../../../assets/img/sidebar/material-icon.svg";
import PageTitleForMasterData from "../../common/PageTitleForMasterData";
import AddCustomField from "./AddCustomField";
import CustomFieldContent from "./CustomFieldContent";

const { Sider, Content } = Layout;
const { useBreakpoint } = Grid;

function CustomFields() {
  const offsetRef = useRef(0);
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const [key, setKey] = useState();

  const items = [
    {
      key: "Material",
      label: "Material",
      icon: (
        <img
          src={key === "Material" ? materialActiveImg : materialImg}
          alt="material-icon"
        />
      ),
    },
  ];

  useEffect(() => {
    if (items.length > 0) {
      setKey(items[0]?.key);
    }
  }, [items]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editId, setEditId] = useState(null);

  const handleCancel = () => {
    setIsModalOpen(false);
    setEditId(null);
    form && form.resetFields();
  };

  const onClick = ({ key }) => {
    setKey(key);
  };

  const openModal = () => {
    setIsModalOpen(true);
    form.setFieldValue("module_name", key);
  };

  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          width={300}
          style={{
            background: "#fff",
            border: "1px solid #DDDAE9",
            borderRight: "1px solid #DDDAE9",
          }}
        >
          <div style={{ padding: "0 10px" }}>
            <h3 className="pb-3 p-5 font-popinsMedium text-black text-xl">
              Modules
            </h3>
            <ConfigProvider
              theme={{
                components: {
                  Menu: {
                    algorithm: true,
                    itemHoverBg: "white",
                    itemHoverColor: "#6883FD",
                    itemPaddingInline: 0,
                    itemSelectedColor: "#6883FD",
                    itemSelectedBg: "white",
                  },
                },
              }}
            >
              <Menu
                onClick={onClick}
                style={{
                  width: "100%",
                }}
                defaultSelectedKeys={[items[0]?.key]}
                selectedKeys={[key]}
                mode="inline"
                items={items}
              />
            </ConfigProvider>
          </div>
        </Sider>

        <Layout
          style={{
            backgroundColor: "white",

            minHeight: "100vh",
          }}
        >
          <Content
            style={{
              padding: "0px",
              background: "#f5f5f5",
            }}
          >
            <Card
              className="custom-field-card"
              bordered={false}
              style={{
                boxShadow: "none",
                paddingBottom: 15,
                height: "100%",
                borderRadius: 0,
                borderLeft: "none",
                border: "1px solid #DDDAE9",
              }}
            >
              <PageTitleForMasterData
                buttonText={"New Custom Field"}
                title="Custom Fields"
                resource="custom-fields"
                onButtonClick={openModal}
              />

              <CustomFieldContent
                setIsModalOpen={setIsModalOpen}
                setEditId={setEditId}
                itemKey={key}
              />
            </Card>
          </Content>
        </Layout>
      </Layout>
      <Modal
        title=""
        destroyOnClose={handleCancel}
        open={isModalOpen}
        onOk={handleCancel}
        footer={null}
        width={650}
        style={{ top: 20, zIndex: 4 }}
        onCancel={handleCancel}
      >
        <AddCustomField
          itemKey={key}
          handleCancel={handleCancel}
          form={form}
          setEditId={setEditId}
          editId={editId}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
        />
      </Modal>
    </>
  );
}

export default CustomFields;
