import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config/web-config";
import { message } from "antd";
import { handleDeleteResponse, handleGetResponse } from "./commonService";
import { toast } from "sonner";
import { generateQueryString, handleErrorMessage } from "../../components/helper/common-function";
import { authMe } from "./authService";

// Action to get company users
export const actionGetCompanyUsers = createAsyncThunk(
  "actionGetCompanyUsers",
  async ({ offset, limit, search, orderByColumn, order, role_id = [], role_profile_id = [], reports_to = [], zone_id = [], material_category_ids = [], user_status = [], biomatric_face_id }, { rejectWithValue }) => {
    const roleIDs = generateQueryString(
      role_id,
      "role_id"
    );
    const roleProfileIDS = generateQueryString(
      role_profile_id,
      "role_profile_id"
    );
    const reportToIDs = generateQueryString(
      reports_to,
      "reports_to"
    );
    const zoneIDS = generateQueryString(
      zone_id,
      "zone_id"
    );
    const materialCategoryIDS = generateQueryString(
      material_category_ids,
      "material_category_ids"
    );
    const userStatus = generateQueryString(
      user_status,
      "status"
    );
    try {
      const res = await axios.get(
        `${BASE_URL}/company-users?limit=${limit}&offset=${offset}${search ? `&search=${search}` : ""
        }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${order ? `&order=${order}` : ""
        }${roleIDs}${roleProfileIDS}${reportToIDs}${zoneIDS}${materialCategoryIDS}${userStatus}${biomatric_face_id ? `&biomatric_face_id=${biomatric_face_id}` : ""
        }`
      );
      const { status, message: customMsg, data, total_records } = res.data;
      if (parseInt(status) === 200) {
        return { data, total_records };
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
        throw new Error(`API Error: ${customMsg}`);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Action for Add/invite users Profile
export const actionAddUser = createAsyncThunk(
  "actionAddUser",
  async (
    { req, form, navigate, setIsModalOpen = () => { }, isHierchy = false } = {},
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/company-users/send-invitation`,
        { data: req }
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        if (data.errors && data.errors.length > 0) {
          toast.error(data.errors[0].message, 5);
        } else {
          toast.success("invitation sent successfully", 5);
          navigate && navigate(-1);
          form && form.resetFields();
        }
        if (isHierchy) {
          setIsModalOpen(false);
          dispatch(actionGetUsersOrganogram());
        }

        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionClearUsersAllData = createAsyncThunk(
  "actionClearUsersAllData",
  async ({ setExportAll }) => {
    setExportAll && setExportAll(false);
    return "";
  }
);

//  Action to get All company users
export const actionGetCompanyAllUsers = createAsyncThunk(
  "actionGetCompanyAllUsers",
  async ({ role = "", search = 0 } = {}) => {
    return handleGetResponse(
      `company-users?getall=YES${role ? "&role_id=" + role : ""}${search ? "&search=" + search : ""
      }`
    );
  }
);

// Action for Get User DEtail
export const actionGetUserDetail = createAsyncThunk(
  "actionGetUserDetail",
  async (id) => {
    return handleGetResponse(`company-users/${id}`);
  }
);

// Action for Update User
export const actionUpdateUser = createAsyncThunk(
  "actionUpdateUser",
  async ({ req, Id, setType, accountType }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/company-users/${Id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setType && setType("VIEW");
        if (accountType) {
          dispatch(authMe())
        }
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Action for Deactivate users single/multiple
export const actionDeactivateUser = createAsyncThunk(
  "actionDeactivateUser",
  async ({ req, setSelectedRowKeys }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/company-users/deactive`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setSelectedRowKeys && setSelectedRowKeys("");
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);


export const actionDeleteInvitedUser = createAsyncThunk("actionDeleteInvitedUser", async (id) => {
  return handleDeleteResponse(`company-users/${id}`, id)
})

export const actionGetAttendanceLogs = createAsyncThunk(
  "actionGetAttendanceLogs",
  async ({
    id,
    limit,
    offset,
    getall,
    orderByColumn,
    order,
    seach,
    startDate,
    endDate,
  } = {}) => {
    return handleGetResponse(
      `company-users/check-in-out/${id}?limit=10&offset=${offset}${getall ? "&getall=" + getall : ""
      }${orderByColumn ? "&orderByColumn=" + orderByColumn : ""}${order ? "&order=" + order : ""
      }${seach ? "&seach=" + seach : ""}${startDate ? "&start_date=" + startDate : ""
      }${endDate ? "&end_date=" + endDate : ""}`
    );
  }
);

export const actionGetAllAttendanceLogs = createAsyncThunk(
  "actionGetAllAttendanceLogs",
  async (id) => {
    return handleGetResponse(`company-users/check-in-out/${id}?getall=YES`);
  }
);

// Clear all Exhibitors
export const actionClearAllAttendanceLogs = createAsyncThunk(
  "actionClearAllAttendanceLogs",
  async ({ setExportAll }) => {
    if (setExportAll) {
      setExportAll(false);
    }
    return [];
  }
);

export const actionGetUsersOrganogram = createAsyncThunk(
  "actionGetUsersOrganogram",
  () => {
    return handleGetResponse(`company-users/hierarchy`);
  }
);


export const actionUpdateBulkUsers = createAsyncThunk(
  "actionUpdateBulkUsers",
  async ({ req, handleClose, setIsFormChanged }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/company-users/bulk-update`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleClose && handleClose();
        return data;
      } else if (parseInt(status) === 404) {
        handleClose && handleClose();

        return rejectWithValue(customMsg)

      } else {
        toast.error(customMsg, 5);
        // setIsFormChanged && setIsFormChanged(false);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);