import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  LoadingOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Image,
  Input,
  InputNumber,
  List,
  Modal,
  Pagination,
  Popconfirm,
  Popover,
  Row,
  Select,
  Skeleton,
  Spin,
  Switch,
  Table,
  Typography,
  Upload,
} from "antd";
import ImgCrop from "antd-img-crop";
import dayjs from "dayjs";
import { useEffect, useMemo, useRef, useState } from "react";
import { MdOutlineChangeCircle } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import * as XLSX from "xlsx";
import attendee from "../../../assets/img/attendee.svg";
import downloadIcon from "../../../assets/img/download.svg";
import editPrimary from "../../../assets/img/editPrimary.svg";
import gallery from "../../../assets/img/gallery.svg";
import plusSvg from "../../../assets/img/plus.svg";
import { BASE_URL_UPLOAD } from "../../../config/web-config";
import { actionDownloadFile } from "../../../store/services/commonService";
import {
  actionAddEventAttendee,
  actionAttendeeChangeStatus,
  actionClearAllEventAttendeeData,
  actionDeleteEventAttendee,
  actionEventAttendeeDetail,
  actionGetAllEventAttendees,
  actionGetAllEventInventory,
  actionGetEventAttendees,
  actionUpdateEventAttendee,
} from "../../../store/services/eventService";
import {
  actionAddAttendeeStatusMaster,
  actionAddAttendeeTypeMaster,
  actionGetAttendeeStatusMaster,
  actionGetAttendeeTypeMaster,
} from "../../../store/services/masterDataService";
import { actionGetProductCategoryMaster } from "../../../store/services/productMasterService";
import CommonModal from "../../common/CommonModal";
import CustomInputWithButtons from "../../common/CustomInputWithButtons ";
import EventDescriptionCard from "../../common/EventDescriptionCard";
import PageTitle from "../../common/PageTitle";
import SortIcon from "../../common/SortIcon";
import TooltipWrapper from "../../common/TooltipWrapper";
import {
  extractFilename,
  formatUrl,
  getEventId,
  getFirstLetterCapital,
  getFullName,
  renderFileIcon,
  sanitizeName,
} from "../../helper/common-function";
import EventDetailWrapper from "./EventDetailWrapper";
import { handleActionWithPermission } from "../../../store/slices/authSlice";
import usePermission from "../../../hooks/use-permission";
import SkeletonTable from "../../common/SkeletonTable";
import SkeletonViewModal from "../../common/SkeletonViewModal";
import SkeletonUpdateCard from "../../common/SkeletonUpdateCard";
import CommonTable from "../../common/CommonTable";
import CommonPagination from "../../common/CommonPagination";

const { Dragger } = Upload;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = (file, size) => {
  const fileSizeMB = file.size / (1024 * 1024);

  if (fileSizeMB > size) {
    toast.error(`File size should be less than ${size} MB.`);
    return false;
  }

  return true;
};

const Attendees = () => {
  const dispatch = useDispatch();
  const eventId = getEventId();
  const [form] = Form.useForm();
  const [attendeeTypeModalForm] = Form.useForm();
  const [attendeeStatusForm] = Form.useForm();
  const [statusForm] = Form.useForm();
  const {
    getEventAttendeesLoader,
    eventAttendeesData,
    eventAttendeesDataCount,
    allEventAttendeesData,
    getAllEventAttendeesLoader,
    getEventAttendeeDetailLoader,
    getEventAttendeeDetail,
    addEventAttendeeLoader,
    updateEventAttendeeLoader,
    deleteEventAttendeeLoader,
    changeAttendeeStatusLoader,
    allEventInventoryData,
    getAllEventInventoryLoader,
    eventsOverview,
  } = useSelector((state) => state.events);

  const { downloadFileLoader } = useSelector((state) => state.common);

  const {
    getAttendeeStatusLoader,
    attendeeStatusData,
    getAttendeeTypeLoader,
    attendeeTypeData,
    addAttendeeTypeLoader,
    addAttendeeStatusLoader,
  } = useSelector((state) => state.masterData);

  const { getProductCategoryLoader, productCategoryData } = useSelector(
    (state) => state.productMaster
  );

  const [loading, setLoading] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [profilePicUrl, setProfilePicUrl] = useState("");
  const [search, setSearch] = useState("");
  const [businessCard, setBusinessCard] = useState([]);
  const [brochure, setBrochure] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [attendeeData, setAttendeeData] = useState("");
  const [exportAll, setExportAll] = useState(false);
  const [isAttendeeTypeModalOpen, setIsAttendeeTypeModalOpen] = useState(false);
  const [attendeeId, setAttendeeId] = useState("");
  const [quantities, setQuantities] = useState([]);
  const [convertToLead, setConvertToLead] = useState(false);
  const [convertToContact, setConvertToContact] = useState(true);

  const [isAttendeeStatusModalOpen, setIsAttendeeStatusModalOpen] =
    useState(false);
  const [lastSearch, setLastSearch] = useState('');
  const [pageSize, setPageSize] = useState(20);
  const offsetRef = useRef(0);
  const { hasPermission } = usePermission();

  const updatedEventInventoryData = useMemo(
    () =>
      allEventInventoryData &&
      allEventInventoryData.length > 0 &&
      allEventInventoryData.map((product) => {
        const attendeeProduct = getEventAttendeeDetail?.attendee_products.find(
          (ap) =>
            ap.event_inventory_product.event_inventory_id ===
            product.event_inventory_id
        );
        const updatedQty = attendeeProduct ? attendeeProduct?.qty : 0;
        return {
          ...product,
          qty: updatedQty,
          available_qty: product?.available_qty,
        };
      }),
    [allEventInventoryData, getEventAttendeeDetail]
  );

  useEffect(() => {
    if (allEventInventoryData && allEventInventoryData.length > 0) {
      let initialQuantities;

      if (getEventAttendeeDetail && attendeeData) {
        initialQuantities = updatedEventInventoryData.map((product) => ({
          event_inventory_id: product.event_inventory_id,
          qty: product.qty,
          available_qty: product?.available_qty,
        }));
      } else {
        initialQuantities = allEventInventoryData.map((product) => ({
          event_inventory_id: product.event_inventory_id,
          qty: product.available_qty === 0 ? 0 : 0,
          available_qty: product?.available_qty,
        }));
      }

      setQuantities(initialQuantities);
    }
  }, [allEventInventoryData, getEventAttendeeDetail, attendeeData]);

  const handleCancelAttendeeStatus = () => {
    setAttendeeId("");
    setStatusModal(false);
    setAttendeeData("");
    form.resetFields();
    setShowProducts(false);
    setProfilePicUrl("");
    setBrochure("");
    setBusinessCard([]);
    statusForm.resetFields();
  };

  const columns = [
    {
      title: "CH No.",
      key: "ch_no",
      width: 100,
      dataIndex: "event_attendee_id",
      fixed: "left",
      render: (text, data) =>
        <div
          onClick={() => {
            dispatch(
              handleActionWithPermission("event-attendee", "read", () => {
                setAttendeeData(data), setViewModalOpen(true);
              })
            );
          }}
          className="flex text-brand items-center cursor-pointer"
        >
          {text ?? "-"}
        </div>,
    },
    {
      title: "Attendee Name",
      key: "1",
      dataIndex: "attendee_first_name",
      ellipsis: true,
      width: 200,
      fixed: "left",
      sortIcon: () => <SortIcon />,
      sorter: true,
      render: (text, data) => {
        const name = text;
        return (
          <div
            onClick={() => {
              dispatch(
                handleActionWithPermission("event-attendee", "read", () => {
                  setAttendeeData(data), setViewModalOpen(true);
                })
              );
            }}
            className="flex items-center cursor-pointer"
          >
            <Avatar
              style={{
                verticalAlign: "middle",
              }}
              src={data.attendee_image}
              size={30}
            />
            <p className="ml-2 text-brand">{getFirstLetterCapital(name)}</p>
          </div>
        );
      },
    },
    {
      title: "Attendee Email",
      key: "2",
      width: 200,
      ellipsis: true,
      dataIndex: "attendee_email",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">{text && text != null ? text : "-"}</span>
      ),
    },
    {
      title: "Attendee Secondary Email",
      key: "3",
      width: 200,
      ellipsis: true,
      dataIndex: "attendee_secondary_email",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">{text && text != null ? text : "-"}</span>
      ),
    },
    {
      title: "Attendee Phone",
      key: "4",
      width: 200,
      ellipsis: true,
      dataIndex: "attendee_phone",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">{text && text != null ? text : "-"}</span>
      ),
    },
    {
      title: "Attendee Secondary Phone",
      key: "5",
      width: 200,
      ellipsis: true,
      dataIndex: "attendee_secondary_phone",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">{text && text != null ? text : "-"}</span>
      ),
    },
    {
      title: "Attendee Alternative Phone",
      key: "6",
      width: 200,
      ellipsis: true,
      dataIndex: "attendee_alternative_phone",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">{text && text != null ? text : "-"}</span>
      ),
    },
    {
      title: "Attendee Company",
      key: "7",
      width: 200,
      ellipsis: true,
      dataIndex: "attendee_company",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Job Title",
      key: "8",
      width: 200,
      ellipsis: true,
      dataIndex: "attendee_job_title",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Attendee Type",
      key: "9",
      width: 200,
      dataIndex: "attendee_type",
      ellipsis: true,
      render: (text) => (
        <span className="ml-2">{text?.event_attendee_type || "-"}</span>
      ),
    },
    {
      title: "Registration Date",
      key: "10",
      width: 200,
      ellipsis: true,
      dataIndex: "attendee_registration_date",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">
          {text ? dayjs(text).format("DD-MM-YYYY") : "-"}
        </span>
      ),
    },
    {
      title: "Attendee Website",
      key: "11",
      width: 200,
      ellipsis: true,
      dataIndex: "attendee_website",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <a
          href={formatUrl(text)}
          target="_blank"
          className="ml-2 text-blue-500"
        >
          {text ? text : "-"}
        </a>
      ),
    },
    {
      title: "Badge Number",
      key: "12",
      width: 200,
      ellipsis: true,
      dataIndex: "attendee_badge_number",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Attendee Status",
      key: "13",
      ellipsis: true,
      width: 200,
      dataIndex: "attendee_status",
      render: (text) => (
        <span className="ml-2">{text?.event_attendee_status || "-"}</span>
      ),
    },
    {
      title: "Attendee Address",
      key: "14",
      width: 200,
      ellipsis: true,
      dataIndex: "attendee_address",
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          style={{ width: 200 }}
          className="ml-2 !mb-0"
        >
          {text || "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Attendee Remark",
      key: "15",
      ellipsis: true,
      width: 200,
      sorter: true,
      sortIcon: () => <SortIcon />,
      dataIndex: "attendee_remark",
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          style={{ width: 200 }}
          className="ml-2 !mb-0"
        >
          {text || "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Created By",
      key: "16",
      dataIndex: "created_by",
      ellipsis: true,
      width: 200,
      fixed: "right",
      // sortIcon: () => <SortIcon />,
      // sorter: true,
      render: (text, data) => {
        return (
          <div className="flex items-center cursor-pointer">
            <Avatar
              style={{
                verticalAlign: "middle",
              }}
              src={text.profile_pic}
              size={30}
            />
            <p className="ml-2 text-brand">
              {getFirstLetterCapital(text?.full_name)}
            </p>
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 100,
      fixed: "right",
      render: (text, data) => (
        <>
          <Dropdown
            id="custom-drop"
            menu={{
              items: [
                {
                  label: <span>Edit</span>,
                  key: "Edit",
                  icon: <EditOutlined />,
                  onClick: () => {
                    dispatch(
                      handleActionWithPermission(
                        "event-attendee",
                        "update",
                        () => {
                          handleOpenModal();
                          setAttendeeData(data);
                        }
                      )
                    );
                  },
                },
                {
                  label: <span>Change Status</span>,
                  key: "change-status",
                  icon: (
                    <MdOutlineChangeCircle color="rgba(0,0,0,0.8)" size={15} />
                  ),
                  onClick: () => {
                    dispatch(
                      handleActionWithPermission(
                        "event-attendee",
                        "update",
                        () => {
                          setStatusModal(true);
                          setAttendeeData(data);
                          setAttendeeId(data.event_attendee_id);
                          dispatch(actionGetAttendeeStatusMaster());
                        }
                      )
                    );
                  },
                },
                {
                  label: hasPermission("event-attendee", "delete") ? (
                    <Popconfirm
                      okButtonProps={{
                        loading: deleteEventAttendeeLoader,
                      }}
                      placement="topLeft"
                      description={`Are you sure to delete this Attendee?`}
                      onConfirm={() => {
                        dispatch(
                          actionDeleteEventAttendee({
                            id: data.event_attendee_id,
                            eventId: eventId,
                          })
                        );
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <div
                        className="flex items-center"
                        onClick={(e) => e.stopPropagation()} // Stop event propagation
                      >
                        <DeleteOutlined
                          style={{ fontSize: 12, color: "#ff4d4f" }}
                        />
                        <span
                          style={{
                            marginLeft: 8,
                            color: "#ff4d4f",
                          }}
                        >
                          Delete
                        </span>
                      </div>
                    </Popconfirm>
                  ) : (
                    <div
                      className="flex items-center"
                      onClick={() => {
                        dispatch(
                          handleActionWithPermission("event-attendee", "delete")
                        );
                      }}
                    >
                      <DeleteOutlined
                        style={{ fontSize: 12, color: "#ff4d4f" }}
                      />
                      <span
                        style={{
                          marginLeft: 8,
                          color: "#ff4d4f",
                        }}
                      >
                        Delete
                      </span>
                    </div>
                  ),
                  key: "Delete",
                },
              ].filter(Boolean),
            }}
          >
            <div className="flex justify-center items-center">
              <EllipsisOutlined />
            </div>
          </Dropdown>
        </>
      ),
    },
  ];

  const data =
    eventAttendeesData &&
    eventAttendeesData.length > 0 &&
    eventAttendeesData.map((attendee) => {
      return {
        ...attendee,
        attendee_first_name: getFullName(
          attendee.attendee_first_name,
          attendee.attendee_last_name
        ),
      };
    });

  const businessCardProps = {
    name: "file",
    multiple: true,
    action: BASE_URL_UPLOAD,
    listType: "picture",
    showUploadList: true,
    fileList: businessCard,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("crmWebToken"),
    },
    onChange(info) {
      const { status, fileList } = info;
      if (status !== "uploading") {
        setBusinessCard(fileList);
      }
      if (status === "done") {
        toast.success(`File uploaded successfully.`);
      } else if (status === "error") {
        toast.error(`File upload failed.`);
      }
    },
    onDrop(e) { },
  };

  const brochureProps = {
    name: "file",
    multiple: false,
    action: BASE_URL_UPLOAD,
    showUploadList: false,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("crmWebToken"),
    },
    onChange(info) {
      const { status } = info.file;
      if (status === "uploading") {
      } else {
        if (status === "done") {
          setBrochure(info.file.response.url);
          toast.success(`File uploaded successfully.`);
        } else if (status === "error") {
          toast.error(`File upload failed.`);
        }
      }
    },
    onDrop(e) { },
  };

  const handleToggle = async (checked) => {
    if (checked) {
      if (!allEventInventoryData)
        await dispatch(actionGetAllEventInventory(eventId));
    }
    setShowProducts(checked);
  };

  useEffect(() => {
    if (eventId) {
      dispatch(
        actionGetEventAttendees({
          offset: offsetRef.current,
          limit: pageSize,
          search,
          eventId,
        })
      );
    }
  }, [dispatch]);

  const handleOpenModal = async () => {
    await setIsModalOpen(true);
    if (
      !attendeeStatusData ||
      (attendeeStatusData && attendeeStatusData.length === 0)
    ) {
      dispatch(actionGetAttendeeStatusMaster());
    }
    if (
      !attendeeTypeData ||
      (attendeeTypeData && attendeeTypeData.length === 0)
    ) {
      dispatch(actionGetAttendeeTypeMaster());
    }
    if (
      !productCategoryData ||
      (productCategoryData && productCategoryData.length === 0)
    ) {
      dispatch(
        actionGetProductCategoryMaster({
          orderByColumn: "category_name",
          order: "ASC",
        })
      );
    }

    dispatch(actionGetAllEventInventory(eventId));
  };

  const handleCancelAttendeeTypeModal = () => {
    setIsAttendeeTypeModalOpen(false);
    attendeeTypeModalForm.resetFields();
  };

  const showAttendeeTypeOpen = () => {
    setIsAttendeeTypeModalOpen(true);
  };

  const showAttendeeStatusOpen = () => {
    setIsAttendeeStatusModalOpen(true);
  };

  const handleCancelAttendeeStatusModal = () => {
    setIsAttendeeStatusModalOpen(false);
    attendeeStatusForm.resetFields();
  };

  const statusData =
    attendeeStatusData &&
    attendeeStatusData.length > 0 &&
    attendeeStatusData.map((data) => ({
      value: data.event_attendee_status_master_id,
      label: data.event_attendee_status,
    }));

  const typeData =
    attendeeTypeData &&
    attendeeTypeData.length > 0 &&
    attendeeTypeData.map((data) => ({
      value: data.event_attendee_type_master_id,
      label: data.event_attendee_type,
    }));

  const inquiryOptions =
    productCategoryData &&
    productCategoryData.length > 0 &&
    productCategoryData.map((data) => ({
      value: data.product_category_master_id,
      label: data.category_name,
    }));

  useEffect(() => {
    if (attendeeData) {
      dispatch(
        actionEventAttendeeDetail({
          id: attendeeData.event_attendee_id,
          eventId,
        })
      );
    }
  }, [dispatch, attendeeData]);

  useEffect(() => {
    if (attendeeData && getEventAttendeeDetail) {
      form.setFieldsValue({
        attendee_first_name: getEventAttendeeDetail.attendee_first_name,
        attendee_last_name: getEventAttendeeDetail.attendee_last_name,
        attendee_email: getEventAttendeeDetail.attendee_email,
        attendee_secondary_email:
          getEventAttendeeDetail.attendee_secondary_email,
        attendee_phone: getEventAttendeeDetail.attendee_phone,
        attendee_secondary_phone:
          getEventAttendeeDetail.attendee_secondary_phone,
        attendee_alternative_phone:
          getEventAttendeeDetail.attendee_alternative_phone,
        attendee_company: getEventAttendeeDetail.attendee_company,
        attendee_job_title: getEventAttendeeDetail.attendee_job_title,
        attendee_website: getEventAttendeeDetail.attendee_website,
        attendee_registration_date:
          getEventAttendeeDetail.attendee_registration_date
            ? dayjs(
              getEventAttendeeDetail.attendee_registration_date,
              "YYYY-MM-DD"
            )
            : undefined,
        attendee_badge_number: getEventAttendeeDetail.attendee_badge_number
          ? getEventAttendeeDetail.attendee_badge_number
          : undefined,
        attendee_status:
          getEventAttendeeDetail.attendee_status &&
            getEventAttendeeDetail.attendee_status
              ?.event_attendee_status_master_id
            ? getEventAttendeeDetail.attendee_status
              ?.event_attendee_status_master_id
            : undefined,
        attendee_type: getEventAttendeeDetail?.attendee_type
          ? getEventAttendeeDetail.attendee_type?.event_attendee_type_master_id
          : undefined,
        attendee_address: getEventAttendeeDetail.attendee_address,
        attendee_remark: getEventAttendeeDetail.attendee_remark,
        product_category:
          getEventAttendeeDetail.product_category &&
            getEventAttendeeDetail.product_category.length > 0
            ? getEventAttendeeDetail.product_category.map(
              (item) => item.product_category?.product_category_master_id
            )
            : undefined,
      });
      getEventAttendeeDetail.attendee_products &&
        getEventAttendeeDetail.attendee_products.length > 0 &&
        setShowProducts(true);
      setProfilePicUrl(getEventAttendeeDetail.attendee_image);
      setBrochure(getEventAttendeeDetail.attendee_brochure);

      const ImagesArray = [];

      getEventAttendeeDetail.business_card &&
        getEventAttendeeDetail.business_card &&
        getEventAttendeeDetail.business_card.forEach((data, index) => {
          ImagesArray.push({
            id: index,
            url: data,
            name: extractFilename(data),
          });
        });

      setBusinessCard(ImagesArray);
    }
  }, [getEventAttendeeDetail]);

  const handleRefresh = () => {
    dispatch(
      actionGetEventAttendees({
        offset: offsetRef.current,
        limit: pageSize,
        search: search,
        eventId,
      })
    );
  };

  useEffect(() => {
    const trimmedSearch = search?.trim();
    if (trimmedSearch !== "" && trimmedSearch !== lastSearch) {
      const timer = setTimeout(() => {
        dispatch(
          actionGetEventAttendees({ offset: offsetRef.current, limit: pageSize, search: trimmedSearch, eventId })
        );
        setLastSearch(trimmedSearch);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }

  }, [search, lastSearch]);

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      dispatch(
        actionGetEventAttendees({
          offset: offsetRef.current,
          limit: pageSize,
          search: e.target.value,
          eventId,
        })
      );
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      dispatch(
        actionGetEventAttendees({
          offset: offsetRef.current,
          limit: pageSize,
          search: e.target.value,
          eventId,
        })
      );
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setBusinessCard([]);
    setBrochure("");
    setAttendeeData("");
    setShowProducts(false);
    form.resetFields();
    setConvertToContact(true);
    setConvertToLead(false);
    setProfilePicUrl("");
  };

  const handleChangeUserPic = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setProfilePicUrl(info.file.response.url);
        toast.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      setLoading(false);
      toast.error(`${info.file.name} file upload failed.`);
    }
  };

  const handleAddAttendee = (values) => {
    const brochreUrl = brochure && extractFilename(brochure);

    const imagesArr = [];

    businessCard &&
      businessCard.length > 0 &&
      businessCard?.forEach((d) => {
        if (d.response) {
          const newUrl = d.response.filename;
          imagesArr.push(newUrl);
        } else if (d.url) {
          const parts = extractFilename(d.url);
          imagesArr.push(parts);
        } else {
          imagesArr.push(d.path);
        }
      });

    const imageUrl = profilePicUrl
      ? extractFilename(profilePicUrl && profilePicUrl)
      : "";

    const newAttendeeProd =
      quantities &&
      quantities.length > 0 &&
      quantities.filter(({ qty }) => qty !== 0);

    let productCategory = values.product_category || [];
    if (!Array.isArray(productCategory)) {
      productCategory = [productCategory];
    }

    const req = {
      ...values,
      event_id: eventId,
      attendee_image: imageUrl,
      business_card: imagesArr,
      attendee_registration_date: values.attendee_registration_date
        ? dayjs(values.attendee_registration_date).format("YYYY-MM-DD")
        : "",
      attendee_brochure: brochreUrl,
      attendee_products: showProducts ? newAttendeeProd : [],
      product_category: productCategory,
      convert_to_contact: attendeeData
        ? !getEventAttendeeDetail?.convert_to_contact
          ? convertToContact
          : false
        : convertToContact,
      convert_to_lead: attendeeData
        ? !getEventAttendeeDetail?.convert_to_lead
          ? convertToLead
          : false
        : convertToLead,
    };

    attendeeData
      ? dispatch(
        actionUpdateEventAttendee({
          id: getEventAttendeeDetail.event_attendee_id,
          req,
          handleCancel,
        })
      )
      : dispatch(
        actionAddEventAttendee({
          req,
          handleCancel,
        })
      );
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
            ? "ASC"
            : "",
    };

    dispatch(
      actionGetEventAttendees({
        offset: offsetRef.current,
        limit: pageSize,
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
        eventId,
      })
    );
  };

  const exportAllToExcel = async () => {
    const data =
      (await allEventAttendeesData) &&
      allEventAttendeesData.length > 0 &&
      allEventAttendeesData.map((data) => ({
        "Attendee Name": getFullName(
          data.attendee_first_name,
          data.attendee_last_name
        ),
        "Attendee Email": data.attendee_email ? data.attendee_email : "-",
        "Attendee Secondary Email": data.attendee_secondary_email
          ? data.attendee_secondary_email
          : "-",
        "Attendee Phone": data.attendee_phone ? data.attendee_phone : "-",
        "Attendee Secondary Phone": data.attendee_secondary_phone
          ? data.attendee_secondary_phone
          : "-",
        "Attendee Alternative Phone": data.attendee_alternative_phone
          ? data.attendee_alternative_phone
          : "-",
        "Attendee Company": data.attendee_company ? data.attendee_company : "-",
        "Job Title": data.attendee_job_title ? data.attendee_job_title : "-",
        "Attendee Type": data?.attendee_type?.event_attendee_type
          ? data?.attendee_type?.event_attendee_type
          : "-",
        "Registration Date": data.attendee_registration_date
          ? data.attendee_registration_date
          : "-",
        "Attendee Website":
          data.attendee_website != null ? data.attendee_website : "-",
        "Badge Number": data.attendee_badge_number
          ? data.attendee_badge_number
          : "-",
        "Attendee Status": data.attendee_status?.event_attendee_status
          ? data.attendee_status?.event_attendee_status
          : "-",
        "Attendee Remark": data.attendee_remark ? data.attendee_remark : "-",
        "Attendee Address": data.attendee_address ? data.attendee_address : "-",
      }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Attendees");
    // Generate the Excel file and initiate the download
    XLSX.writeFile(workbook, "attendees.xlsx");
    dispatch(actionClearAllEventAttendeeData({ setExportAll }));
  };

  useEffect(() => {
    if (
      exportAll &&
      allEventAttendeesData &&
      allEventAttendeesData.length > 0
    ) {
      exportAllToExcel();
    }
  }, [allEventAttendeesData]);

  const onClickDownloadMenu = () => {
    dispatch(actionGetAllEventAttendees({ eventId }));
    setExportAll(true);
  };

  const handleQuantityChange = (product, value) => {
    const newQuantity = Math.max(value, 0); // Ensure quantity doesn't go below 0

    setQuantities((prevQuantities) => {
      const index = prevQuantities.findIndex(
        (item) => item.event_inventory_id === product.event_inventory_id
      );

      if (index !== -1) {
        const newQuantities = [...prevQuantities];
        newQuantities[index] = {
          ...newQuantities[index],
          qty: newQuantity,
        };
        return newQuantities;
      }

      return prevQuantities;
    });
  };

  const attendeeTypePopover = (
    <>
      <div className="min-w-[350px]">
        <Form
          form={attendeeTypeModalForm}
          onFinish={(values) =>
            dispatch(
              actionAddAttendeeTypeMaster({
                values,
                form,
                handleCancel: handleCancelAttendeeTypeModal,
              })
            )
          }
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="event_attendee_type"
            label="Attendee Type Name"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please enter attendee type name!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Attendee Type Name"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={() => handleCancelAttendeeTypeModal()}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addAttendeeTypeLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );

  const attendeeStatusPopover = (
    <>
      <div className="min-w-[350px]">
        <Form
          form={attendeeStatusForm}
          onFinish={(values) => {
            dispatch(
              actionAddAttendeeStatusMaster({
                values,
                form,
                handleCancel: handleCancelAttendeeStatusModal,
              })
            );
          }}
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="event_attendee_status"
            label="Enter Attendee Status"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please enter attendee status!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Atteendee Status"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={() => handleCancelAttendeeStatusModal()}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  type="primary"
                  loading={addAttendeeStatusLoader}
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );



  const handleDownloadSelectedFiles = () => {
    getEventAttendeeDetail?.business_card &&
      getEventAttendeeDetail?.business_card.length > 0 &&
      getEventAttendeeDetail?.business_card.forEach((file) => {
        dispatch(actionDownloadFile(file));
      });
  };

  return (
    <EventDetailWrapper>
      <div className="event-table-container main-table-container" style={{ height: "calc(100vh - 130px)" }}>


        <CommonTable
          pageTitle={<PageTitle
            image={attendee}
            title="Attendees"
            rightIcon={false}
          />}
          title="Attendees"
          wrapperClassName="2xl:max-h-[calc(100vh-240px)] xl:max-h-[calc(100vh-120px)] max-h-[calc(100vh-120px)]"
          pageSize={pageSize}
          buttonText="Add Attendee"
          onAddClick={handleOpenModal}
          type="btn"
          resource="event-attendee"
          moduleKey="event_attendee"
          loading={getEventAttendeesLoader || getAllEventAttendeesLoader}
          columns={columns}
          scrollToFirstRowOnChange={true}
          data={(data || [])}
          rowKey={"event_attendee_id"}
          onChange={handleSortChange}
          handleRefresh={handleRefresh}
          handleSearch={handleSearch}
          handleBlurAndPressEnter={handleBlurAndPressEnter}
          search={search}
          onClickDownloadMenu={onClickDownloadMenu}
          handlePaginationChange={(page, pageSize) => {
            offsetRef.current = page - 1;
            setPageSize(pageSize);
            dispatch(
              actionGetEventAttendees({
                eventId,
                offset: offsetRef.current,
                limit: pageSize,
                search,
              })
            )
          }}

          total={eventAttendeesDataCount}
          offset={offsetRef.current}
        />

        {/*  Form Modal */}
        <Modal
          centered
          onOk={handleCancel}
          onCancel={handleCancel}
          afterClose={handleCancel}
          footer={false}
          width={1200}
          title=""
          open={isModalOpen}
        >
          <div className="text-brand flex items-center gap-x-3">
            <Image
              src={attendee}
              alt="image"
              height={20}
              width={20}
              preview={false}
            />

            <h1 className="lg:text-lg text-base  font-popinsRegular">
              {attendeeData ? "Update" : "Add"} Attendee
            </h1>
          </div>
          <Form
            initialValues={{
              attendee_registration_date: dayjs(),
            }}
            scrollToFirstError={true}
            form={form}
            onFinish={handleAddAttendee}
            layout="vertical"
            className="mt-[20px] mb-0"
          >
            <Row gutter={[16, 0]}>
              <Col span={24} align="middle">
                <Form.Item
                  className="mb-[12px]"
                  size="small"
                  rules={[
                    {
                      required: false,
                      message: "Please Enter Attendee Image!",
                    },
                  ]}
                >
                  {getEventAttendeeDetailLoader ? (
                    <Skeleton.Avatar
                      active
                      size={100}
                      shape="square"
                      className="flex-shrink-0"
                      style={{ marginRight: "10px" }}
                    />
                  ) : (
                    <ImgCrop aspect={1 / 1} rotationSlider fillColor="white">
                      <Upload
                        name="file"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action={BASE_URL_UPLOAD}
                        openFileDialogOnClick={true}
                        headers={{
                          Authorization:
                            "Bearer " + localStorage.getItem("crmWebToken"),
                        }}
                        onChange={handleChangeUserPic}
                      >
                        {profilePicUrl ? (
                          <div className="relative border border-dashed border-brand h-[102px] w-[102px] rounded-[6px]">
                            <img
                              src={profilePicUrl}
                              alt="avatar"
                              className="h-full w-full rounded-[6px]"
                            />
                            <div className="absolute bottom-0 right-0 px-1.5 py-1 bg-brand rounded-tl-xl rounded-br-[6px] cursor-pointer flex">
                              <Image
                                width={15}
                                height={15}
                                src={editPrimary}
                                className="rounded-lg"
                                preview={false}
                              />
                            </div>
                          </div>
                        ) : (
                          <button
                            style={{
                              border: 0,
                              background: "none",
                            }}
                            type="button"
                          >
                            {loading ? <LoadingOutlined /> : <PlusOutlined />}
                            <div
                              style={{
                                marginTop: 8,
                              }}
                            >
                              Upload
                            </div>
                          </button>
                        )}
                      </Upload>
                    </ImgCrop>
                  )}
                </Form.Item>
              </Col>

              {getEventAttendeeDetailLoader ? (
                <SkeletonUpdateCard length={10} />
              ) : (
                <>
                  <Col span={12}>
                    <Form.Item
                      name="attendee_first_name"
                      label="Attendee First Name"
                      className="mb-[12px]"
                      size="small"
                      rules={[
                        {
                          required: true,
                          message: "Please enter first name!",
                        },
                      ]}
                      normalize={sanitizeName}
                    >
                      <Input
                        style={{ background: "white" }}
                        placeholder="Enter Attendee First Name"
                        className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="attendee_last_name"
                      label="Attendee Last Name"
                      className="mb-[12px]"
                      size="small"
                      normalize={sanitizeName}
                    >
                      <Input
                        style={{ background: "white" }}
                        placeholder="Enter Attendee Last Name"
                        className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="attendee_email"
                      label="Attendee Email"
                      className="mb-[12px]"
                      size="small"
                      rules={[
                        {
                                          pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,

                          message: "Please enter a valid email!",
                        },
                      ]}
                    >
                      <Input
                        style={{ background: "white" }}
                        placeholder="Enter Email"
                        className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                      />
                    </Form.Item>
                  </Col>
                  {/* Attendee Email 2 */}
                  <Col span={12}>
                    <Form.Item
                      name="attendee_secondary_email"
                      label="Attendee Secondary Email"
                      className="mb-[12px]"
                      size="small"
                      rules={[
                        {
                                          pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,

                          message: "Please enter a valid email",
                        },
                      ]}
                    >
                      <Input
                        style={{ background: "white" }}
                        placeholder="Enter Secondary Email"
                        className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="attendee_phone"
                      label="Attendee Phone"
                      className="mb-[12px]"
                      normalize={(value) =>
                        value && value.replace(/[^+\d]/g, "")
                      }
                      size="small"
                      rules={[
                        {
                          required: true,
                          message: "Please enter attendee phone",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (value && value.length > 15) {
                              return Promise.reject(
                                "Phone number cannot be longer than 15 digits!"
                              );
                            }

                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input
                        aria-controls="false"
                        style={{ background: "white" }}
                        placeholder="Enter Attendee Phone"
                        className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="attendee_secondary_phone"
                      label="Attendee Secondary Phone"
                      className="mb-[12px]"
                      // normalize={(value) => value && value.replace(/[^\d]/g, "")}
                      normalize={(value) =>
                        value && value.replace(/[^+\d]/g, "")
                      }
                      size="small"
                      rules={[
                        {
                          max: 15,
                          message:
                            "Phone number cannot be longer than 15 digits",
                        },
                      ]}
                    >
                      <Input
                        aria-controls="false"
                        style={{ background: "white" }}
                        placeholder="Enter Attendee Secondary Phone"
                        className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="attendee_alternative_phone"
                      label="Attendee Alternative Phone"
                      className="mb-[12px]"
                      // normalize={(value) => value && value.replace(/[^\d]/g, "")}
                      normalize={(value) =>
                        value && value.replace(/[^+\d]/g, "")
                      }
                      size="small"
                      rules={[
                        {
                          max: 15,
                          message:
                            "Phone number cannot be longer than 15 digits",
                        },
                      ]}
                    >
                      <Input
                        aria-controls="false"
                        style={{ background: "white" }}
                        placeholder="Enter Attendee Alternative Phone"
                        className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="attendee_company"
                      label="Attendee Company"
                      className="mb-[12px]"
                      size="small"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Attendee Company!",
                        },
                      ]}
                    >
                      <Input
                        aria-controls="false"
                        style={{ background: "white" }}
                        placeholder="Enter Attendee Company"
                        className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Popover
                      placement="bottomRight"
                      content={attendeeTypePopover}
                      trigger="click"
                      open={isAttendeeTypeModalOpen}
                      onOpenChange={(visible) => {
                        if (!visible) {
                          setIsAttendeeTypeModalOpen(false);
                          attendeeTypeModalForm.resetFields();
                        }
                      }}
                    >
                      <div
                        className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                        onClick={showAttendeeTypeOpen}
                      >
                        <Image
                          src={plusSvg}
                          alt="image"
                          height={20}
                          width={20}
                          preview={false}
                        />
                        <h1 className="text-[14px] font-popinsRegular ml-1">
                          Add Attendee Type
                        </h1>
                      </div>
                    </Popover>
                    <Form.Item
                      name="attendee_type"
                      label="Attendee Type"
                      className="mb-[12px]"
                      size="small"
                      rules={[
                        {
                          required: false,
                          message: "Please Select Attendee Type!",
                        },
                      ]}
                    >
                      <Select
                        options={typeData}
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        loading={getAttendeeTypeLoader}
                        disabled={getAttendeeTypeLoader}
                        style={{ background: "white" }}
                        placeholder="Select Attendee Type"
                        className="begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                      >
                        {/* Add options here */}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Popover
                      placement="bottomRight"
                      content={attendeeStatusPopover}
                      trigger="click"
                      open={isAttendeeStatusModalOpen}
                      onOpenChange={(visible) => {
                        if (!visible) {
                          setIsAttendeeStatusModalOpen(false);
                          attendeeStatusForm.resetFields();
                        }
                      }}
                    >
                      <div
                        className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                        onClick={showAttendeeStatusOpen}
                      >
                        <Image
                          src={plusSvg}
                          alt="image"
                          height={20}
                          width={20}
                          preview={false}
                        />
                        <h1 className="text-[14px] font-popinsRegular ml-1">
                          Add Attendee Status
                        </h1>
                      </div>
                    </Popover>
                    <Form.Item
                      name="attendee_status"
                      label="Attendee Status"
                      className="mb-[12px]"
                      size="small"
                      rules={[
                        {
                          required: false,
                          message: "Please Select Attendee Status!",
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={statusData}
                        loading={getAttendeeStatusLoader}
                        disabled={getAttendeeStatusLoader}
                        style={{ background: "white" }}
                        placeholder="Select Attendee Status"
                        className="begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="attendee_job_title"
                      label="Job Title"
                      className="mb-[12px]"
                      size="small"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Job Title!",
                        },
                      ]}
                    >
                      <Input
                        style={{ background: "white" }}
                        placeholder="Enter Job Title"
                        className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                      />
                    </Form.Item>
                  </Col>{" "}
                  <Col span={12}>
                    <Form.Item
                      name="attendee_website"
                      label="Website"
                      className="mb-[12px]"
                      size="small"
                      rules={
                        [
                          /* {
                        type: "url",
                        message: "Please enter a valid URL!",
                      }, */
                        ]
                      }
                    >
                      <Input
                        style={{ background: "white" }}
                        placeholder="Enter Website URL"
                        className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="attendee_registration_date"
                      label="Registration Date"
                      className="mb-[12px]"
                      size="small"
                    >
                      <DatePicker
                        placeholder="Select date"
                        format={"DD/MM/YYYY"}
                        allowClear
                        minDate={dayjs()}
                        className="w-full"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="attendee_badge_number"
                      label="Badge Number"
                      className="mb-[12px]"
                      size="small"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Badge Number!",
                        },
                      ]}
                    >
                      <Input
                        style={{ background: "white" }}
                        placeholder="Enter Badge Number"
                        className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="product_category"
                      label="Inquiry for"
                      className="mb-[12px]"
                      size="small"
                    >
                      <Select
                        allowClear
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        mode="multiple"
                        showSearch
                        style={{ background: "white" }}
                        placeholder="Select Inquiry Type"
                        className="begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                        loading={getProductCategoryLoader}
                        disabled={getProductCategoryLoader}
                        options={inquiryOptions}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="attendee_remark"
                      label="Remark"
                      className="mb-[12px]"
                      size="small"
                    >
                      <Input.TextArea
                        rows={2}
                        style={{ background: "white" }}
                        placeholder="Enter Remark"
                        className="indent-5 begin-input bg-white pt-2 focus:bg-white active:bg-white rounded-[10px]"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="attendee_address"
                      label="Attendee Address"
                      className="mb-[12px]"
                      size="small"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Attendee Address!",
                        },
                      ]}
                    >
                      <Input.TextArea
                        rows={2}
                        style={{ background: "white" }}
                        placeholder="Enter Attendee Address"
                        className="indent-5 begin-input bg-white pt-2 focus:bg-white active:bg-white rounded-[10px]"
                      />
                    </Form.Item>
                  </Col>
                  {/* <Col span={12} /> */}
                  <Col span={12} className="my-[12px]">
                    <Form.Item size="small">
                      <Row>
                        <Col span={10}>
                          <div>
                            <h2 className="text-sm">
                              Business Card
                              {/*  <span className="text-red-500">(max 2)</span> */}
                            </h2>
                            <p className="text-[#FB2E2E] text-sm">
                              Image, PDF or Doc up to 50mb
                            </p>
                          </div>
                        </Col>
                        <Col span={14}>
                          <div>
                            <Dragger
                              beforeUpload={(file) => beforeUpload(file, 50)}
                              accept=".png,.jpg,.jpeg,.pdf,.doc"
                              {...businessCardProps}
                              // maxCount={2}
                              className="attendee-dragger "
                              height={60}
                            >
                              <div className="flex items-center gap-x-5 justify-center">
                                <Image
                                  src={gallery}
                                  preview={false}
                                  alt="Gallery"
                                />
                                <p className="text-left text-[10px]">
                                  Drop your File here or select <br />
                                  <span className="text-brand">
                                    Click to Browse
                                  </span>
                                </p>
                              </div>
                            </Dragger>
                          </div>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col>
                  <Col span={12} className="my-[12px]">
                    <Form.Item
                      name="attendee_brochure"
                      className="mb-[12px]"
                      size="small"
                    >
                      <Row>
                        <Col span={10}>
                          <div>
                            <h2 className="text-sm">Brochure</h2>
                            <p className="text-[#FB2E2E] text-sm">
                              Image or PDF up to 25mb
                            </p>
                          </div>
                        </Col>
                        <Col span={14}>
                          <div>
                            {!brochure ? (
                              <Dragger
                                beforeUpload={(file) => beforeUpload(file, 25)}
                                accept=".png,.jpg,.jpeg,.pdf"
                                {...brochureProps}
                                className="attendee-dragger flex items-center justify-center"
                                height={60}
                              >
                                <div className="flex items-center gap-x-5 justify-center">
                                  <Image
                                    src={gallery}
                                    preview={false}
                                    alt="Gallery"
                                  />
                                  <p className="text-left text-[10px]">
                                    Drop your File here or select <br />
                                    <span className="text-brand">
                                      Click to Browse
                                    </span>
                                  </p>
                                </div>
                              </Dragger>
                            ) : (
                              <div className="h-[60px] flex items-center p-2 px-2 w-full rounded-md bg-gray/20 listtype gap-x-2">
                                {renderFileIcon(brochure)}
                                <Typography.Paragraph
                                  style={{ marginBottom: 0 }}
                                  ellipsis={{ rows: 1 }}
                                >
                                  {extractFilename(brochure)}
                                </Typography.Paragraph>
                                <Button
                                  onClick={() => setBrochure("")}
                                  danger
                                  size="small"
                                  style={{
                                    height: 30,
                                    marginLeft: "auto",
                                    width: 30,
                                    border: "none",
                                  }}
                                >
                                  <DeleteOutlined
                                    style={{ color: "rgba(0, 0, 0, 0.45)" }}
                                  />
                                </Button>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col>
                  {(!attendeeData ||
                    (attendeeData &&
                      !getEventAttendeeDetail?.convert_to_lead)) && (
                      <Col span={12} className="mb-5">
                        <div className="flex items-center justify-between w-full">
                          <p className="text-[14px] font-popinsMedium">
                            Convert to Lead
                          </p>
                          <Switch
                            onChange={(checked) => setConvertToLead(checked)}
                            checked={convertToLead}
                          />
                        </div>
                      </Col>
                    )}
                  {(!attendeeData ||
                    (attendeeData &&
                      !getEventAttendeeDetail?.convert_to_contact)) && (
                      <Col span={12} className="mb-5">
                        <div className="flex items-center justify-between w-full">
                          <p className="text-[14px] font-popinsMedium">
                            Convert to Contact
                          </p>
                          <Switch
                            onChange={(checked) => setConvertToContact(checked)}
                            checked={convertToContact}
                          />
                        </div>
                      </Col>
                    )}
                  {allEventInventoryData &&
                    allEventInventoryData.length > 0 && (
                      <Col span={24}>
                        <Row gutter={[14, 0]}>
                          <Col span={12} className="mb-5">
                            <div className="flex items-center justify-between w-full">
                              <p className="text-[14px] font-popinsMedium">
                                Giveaway
                              </p>
                              <Switch
                                loading={getAllEventInventoryLoader}
                                onChange={handleToggle}
                                checked={showProducts}
                              />
                            </div>
                          </Col>

                          <Col span={12} />

                          {showProducts &&
                            (attendeeData && getEventAttendeeDetail
                              ? updatedEventInventoryData &&
                              updatedEventInventoryData.length > 0 &&
                              updatedEventInventoryData.map(
                                (product, index) => {
                                  return (
                                    <Col
                                      span={12}
                                      key={index}
                                      className="mb-2"
                                    >
                                      <div className="flex items-center product-container justify-between">
                                        <p className="text-[14px] font-popinsMedium">
                                          {
                                            product?.event_product
                                              ?.product_name
                                          }
                                        </p>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Button
                                            disabled={
                                              quantities.find(
                                                (item) =>
                                                  item.event_inventory_id ===
                                                  product.event_inventory_id
                                              )?.qty === 0
                                            }
                                            icon={<MinusOutlined />}
                                            className="disabled:cursor-auto disabled:hover:text-[rgba(0, 0, 0, 0.25)] disabled:active:text-[rgba(0, 0, 0, 0.25)] disabled:focus:text-[rgba(0, 0, 0, 0.25)] "
                                            onClick={() =>
                                              handleQuantityChange(
                                                product,
                                                (quantities.find(
                                                  (item) =>
                                                    item.event_inventory_id ===
                                                    product.event_inventory_id
                                                )?.qty || 0) - 1
                                              )
                                            }
                                          />
                                          <InputNumber
                                            controls={false}
                                            variant="borderless"
                                            value={
                                              quantities.find(
                                                (item) =>
                                                  item.event_inventory_id ===
                                                  product.event_inventory_id
                                              )?.qty || 0
                                            }
                                            onChange={(value) =>
                                              handleQuantityChange(
                                                product,
                                                value
                                              )
                                            }
                                            style={{
                                              margin: "0 16px",
                                              width: 80,
                                            }}
                                          />

                                          <Button
                                            className="disabled:cursor-auto disabled:hover:text-[rgba(0, 0, 0, 0.25)] disabled:active:text-[rgba(0, 0, 0, 0.25)] disabled:focus:text-[rgba(0, 0, 0, 0.25)] "
                                            disabled={
                                              product.available_qty === 0 ||
                                              quantities.find(
                                                (item) =>
                                                  item.event_inventory_id ===
                                                  product.event_inventory_id
                                              )?.qty === product.available_qty
                                            }
                                            icon={<PlusOutlined />}
                                            onClick={() =>
                                              handleQuantityChange(
                                                product,
                                                (quantities.find(
                                                  (item) =>
                                                    item.event_inventory_id ===
                                                    product.event_inventory_id
                                                )?.qty || 0) + 1
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                  );
                                }
                              )
                              : allEventInventoryData &&
                              allEventInventoryData.length > 0 &&
                              allEventInventoryData.map((product, index) => {
                                return (
                                  <Col span={12} key={index} className="mb-2">
                                    <div className="flex items-center product-container justify-between">
                                      <p className="text-[14px] font-popinsMedium">
                                        {product?.event_product?.product_name}
                                      </p>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Button
                                          disabled={
                                            quantities.find(
                                              (item) =>
                                                item.event_inventory_id ===
                                                product.event_inventory_id
                                            )?.qty === 0
                                          }
                                          className="disabled:cursor-auto disabled:hover:text-[rgba(0, 0, 0, 0.25)] disabled:active:text-[rgba(0, 0, 0, 0.25)] disabled:focus:text-[rgba(0, 0, 0, 0.25)] "
                                          icon={<MinusOutlined />}
                                          onClick={() =>
                                            handleQuantityChange(
                                              product,
                                              (quantities.find(
                                                (item) =>
                                                  item.event_inventory_id ===
                                                  product.event_inventory_id
                                              )?.qty || 0) - 1
                                            )
                                          }
                                        />
                                        <InputNumber
                                          controls={false}
                                          variant="borderless"
                                          value={
                                            quantities.find(
                                              (item) =>
                                                item.event_inventory_id ===
                                                product.event_inventory_id
                                            )?.qty || 0
                                          }
                                          onChange={(value) =>
                                            handleQuantityChange(
                                              product,
                                              value
                                            )
                                          }
                                          style={{
                                            margin: "0 16px",
                                            width: 80,
                                          }}
                                        />

                                        <Button
                                          disabled={
                                            quantities.find(
                                              (item) =>
                                                item.event_inventory_id ===
                                                product.event_inventory_id
                                            )?.qty === product.available_qty
                                          }
                                          className="disabled:cursor-auto disabled:hover:text-[rgba(0, 0, 0, 0.25)] disabled:active:text-[rgba(0, 0, 0, 0.25)] disabled:focus:text-[rgba(0, 0, 0, 0.25)]"
                                          icon={<PlusOutlined />}
                                          onClick={() =>
                                            handleQuantityChange(
                                              product,
                                              (quantities.find(
                                                (item) =>
                                                  item.event_inventory_id ===
                                                  product.event_inventory_id
                                              )?.qty || 0) + 1
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                );
                              }))}
                        </Row>
                      </Col>
                    )}
                  <Col
                    span={24}
                    align="middle"
                    className="flex justify-center mt-[40px]"
                  >
                    <Form.Item className="mb-0">
                      <Button
                        onClick={handleCancel}
                        type="button"
                        className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
                      >
                        Cancel
                      </Button>
                    </Form.Item>
                    <div className="ml-4">
                      <Form.Item className="mb-0">
                        <Button
                          loading={
                            addEventAttendeeLoader || updateEventAttendeeLoader
                          }
                          type="primary"
                          className="root-btn min-h-[45px] min-w-[120px]"
                          htmlType="submit"
                        >
                          {attendeeData ? "Save" : "Submit"}
                        </Button>
                      </Form.Item>
                    </div>
                  </Col>
                </>
              )}
            </Row>
          </Form>
        </Modal>
        <CommonModal
          // loading={getEventAttendeeDetailLoader}
          centered={true}
          handleReset={() => {
            setViewModalOpen(false);
            handleCancel();
          }}
          open={viewModalOpen}
          title=""
          onCancel={() => {
            setViewModalOpen(false);
            setAttendeeData(false);
            handleCancel();
          }}
          onOk={() => {
            setViewModalOpen(false);
            setAttendeeData(false);
            handleCancel();
          }}
        >
          {/* <Spin spinning={getEventAttendeeDetailLoader}> */}
          {getEventAttendeeDetailLoader ? (
            <SkeletonViewModal length={17} />
          ) : (
            getEventAttendeeDetail && (
              <>
                <div className="text-brand flex items-center gap-x-3">
                  <Image
                    src={attendee}
                    alt="image"
                    height={20}
                    width={20}
                    preview={false}
                  />

                  <h1 className="lg:text-lg text-base  font-popinsRegular">
                    View Attendee
                  </h1>
                </div>
                <div className="flex flex-col gap-y-4">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center pt-5 gap-x-4">
                      <Image
                        preview={false}
                        height={100}
                        width={100}
                        className="rounded-[10px]"
                        src={getEventAttendeeDetail.attendee_image}
                        alt="Profile Pic"
                      />
                      <div className="flex justify-start flex-col gap-y-1">
                        <p className="text-sm font-popinsMedium">
                          {getFullName(
                            getEventAttendeeDetail.attendee_first_name,
                            getEventAttendeeDetail.attendee_last_name
                          )}
                        </p>
                        <p className="text-sm  text-[#818181]">
                          {getEventAttendeeDetail.attendee_email}
                        </p>
                      </div>
                    </div>

                    {getEventAttendeeDetail?.attendee_status && (
                      <div
                        className={`flex rounded-[5px] justify-center border min-w-[120px] ml-auto  border-brand  items-center gap-x-3 py-1  px-5  text-brand`}
                      >
                        <span
                          style={{
                            padding: 1.5,
                          }}
                          className={`inline-block h-1.5 w-1.5 text-brand bg-brand rounded-full `}
                        ></span>
                        <p
                          className={`text-[15px] font-popinsMedium text-center`}
                        >
                          {
                            getEventAttendeeDetail?.attendee_status
                              ?.event_attendee_status
                          }
                        </p>
                      </div>
                    )}
                  </div>

                  <EventDescriptionCard
                    title="Attendee Secondary Email"
                    value={getEventAttendeeDetail.attendee_secondary_email}
                  />
                  <EventDescriptionCard
                    title="Attendee Phone"
                    value={getEventAttendeeDetail.attendee_phone}
                  />
                  <EventDescriptionCard
                    title="Attendee Secondary Phone"
                    value={getEventAttendeeDetail.attendee_secondary_phone}
                  />
                  <EventDescriptionCard
                    title="Attendee Alternative Phone"
                    value={getEventAttendeeDetail.attendee_alternative_phone}
                  />
                  <EventDescriptionCard
                    title="Attendee Company"
                    value={getEventAttendeeDetail.attendee_company}
                  />

                  <EventDescriptionCard
                    title="Attendee Job Title"
                    value={getEventAttendeeDetail.attendee_job_title}
                  />
                  <EventDescriptionCard
                    title="Attendee Type"
                    value={
                      getEventAttendeeDetail?.attendee_type?.event_attendee_type
                    }
                  />
                  <EventDescriptionCard
                    title="Attendee Status"
                    value={
                      getEventAttendeeDetail?.attendee_status
                        ?.event_attendee_status
                    }
                  />
                  <EventDescriptionCard
                    title="Registration Date"
                    value={
                      getEventAttendeeDetail?.attendee_registration_date != null
                        ? dayjs(
                          getEventAttendeeDetail?.attendee_registration_date
                        ).format("DD-MM-YYYY")
                        : "-"
                    }
                  />

                  <div className="flex items-center w-full gap-y-1">
                    <p className="text-xs text-[#818181] w-[30%]">
                      Attendee Website:
                    </p>
                    <a
                      target="_blank"
                      href={formatUrl(
                        getEventAttendeeDetail &&
                        getEventAttendeeDetail?.attendee_website !== null &&
                        getEventAttendeeDetail?.attendee_website
                      )}
                      className="text-sm text-start w-[70%] text-blue-500 font-popinsMedium"
                    >
                      {getEventAttendeeDetail?.attendee_website != null
                        ? getEventAttendeeDetail?.attendee_website
                        : "-"}
                    </a>
                  </div>
                  <EventDescriptionCard
                    title="Badge Number"
                    value={getEventAttendeeDetail?.attendee_badge_number}
                  />

                  <div className="flex items-center w-full gap-y-1">
                    <p className="text-xs text-[#818181] w-[30%]">
                      Inquiry for:
                    </p>
                    <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                      {getEventAttendeeDetail.product_category &&
                        getEventAttendeeDetail.product_category.length > 0
                        ? getEventAttendeeDetail.product_category.map(
                          (cat, index) =>
                            <span key={index}>{`${cat?.product_category?.category_name}${index <
                              getEventAttendeeDetail.product_category.length -
                              1
                              ? ","
                              : ""
                              }`}</span>
                        )
                        : "-"}
                    </p>
                  </div>
                  <EventDescriptionCard
                    title="Attendee Address"
                    value={getEventAttendeeDetail?.attendee_address}
                  />
                  <EventDescriptionCard
                    title="Attendee Remark"
                    value={getEventAttendeeDetail?.attendee_remark}
                  />
                  <EventDescriptionCard
                    title="Created By"
                    value={getEventAttendeeDetail.created_by?.full_name}
                  />

                  {getEventAttendeeDetail &&
                    getEventAttendeeDetail?.business_card &&
                    getEventAttendeeDetail?.business_card?.length > 0 ? (
                    <List
                      size="large"
                      header={
                        <div className="flex items-center justify-between">
                          <h2 className="text-primary text-center">
                            Business Cards
                          </h2>
                          {getEventAttendeeDetail?.business_card &&
                            getEventAttendeeDetail?.business_card.length >
                            1 && (
                              <TooltipWrapper title={"click to download all"}>
                                <Button
                                  loading={
                                    downloadFileLoader[
                                    getEventAttendeeDetail?.business_card.map(
                                      (item) => item
                                    )
                                    ]
                                  }
                                  type="primary"
                                  onClick={handleDownloadSelectedFiles}
                                  className=" text-white download-btn p-2 text-xs rounded"
                                >
                                  Download All
                                </Button>
                              </TooltipWrapper>
                            )}
                        </div>
                      }
                      bordered
                      dataSource={getEventAttendeeDetail?.business_card}
                      renderItem={(item, index) => {
                        return (
                          <List.Item>
                            <List.Item.Meta
                              avatar={renderFileIcon(item)}
                              title={
                                <div className="flex items-center">
                                  <Typography.Paragraph
                                    ellipsis={{ rows: 1 }}
                                    className="w-[90%] text"
                                    style={{ marginBottom: 0 }}
                                  >
                                    {extractFilename(item)}
                                  </Typography.Paragraph>
                                  <span className="w-[5%] ml-4">
                                    <Image
                                      preview={false}
                                      onClick={() =>
                                        dispatch(actionDownloadFile(item))
                                      }
                                      className="cursor-pointer"
                                      src={downloadIcon}
                                      alt="download"
                                      height={18}
                                      width={18}
                                    />
                                  </span>
                                </div>
                              }
                            />
                          </List.Item>
                        );
                      }}
                    />
                  ) : (
                    <div className="flex items-start gap-y-1">
                      <p className="text-xs text-[#818181] w-[30%]">
                        Business Cards:
                      </p>
                      <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                        No business cards added
                      </p>
                    </div>
                  )}
                  {getEventAttendeeDetail?.attendee_brochure ? (
                    <Card
                      className="brochure-card"
                      title={
                        <h2 className="text-primary font-popinsRegular text-start">
                          Brochure
                        </h2>
                      }
                    >
                      <div className="w-full flex items-center">
                        <div className="flex items-center gap-x-2 w-[91%]">
                          {renderFileIcon(
                            getEventAttendeeDetail?.attendee_brochure
                          )}
                          <Typography.Paragraph
                            style={{ width: "100%", marginBottom: 0 }}
                            ellipsis={{ rows: 1 }}
                          >
                            {extractFilename(
                              getEventAttendeeDetail?.attendee_brochure
                            )}
                          </Typography.Paragraph>
                        </div>
                        {/* <span className="w-[5%] ml-4"> */}
                        <div className="w-[9%] ml-auto  flex items-center justify-center">
                          <img
                            preview={false}
                            onClick={() =>
                              dispatch(
                                actionDownloadFile(
                                  getEventAttendeeDetail?.attendee_brochure
                                )
                              )
                            }
                            className="cursor-pointer"
                            src={downloadIcon}
                            alt="download"
                            height={18}
                            width={18}
                          />
                        </div>
                        {/* </span> */}
                      </div>
                    </Card>
                  ) : (
                    <div className="flex items-start gap-y-1">
                      <p className="text-xs text-[#818181] w-[30%]">
                        Brochure:
                      </p>
                      <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                        No brochure added
                      </p>
                    </div>
                  )}
                </div>
              </>
            )
          )}

          {/* </Spin> */}
        </CommonModal>

        <CommonModal
          width={500}
          centered={true}
          open={statusModal}
          title=""
          loading={getEventAttendeeDetailLoader}
          handleReset={handleCancelAttendeeStatus}
          onCancel={handleCancelAttendeeStatus}
          onOk={handleCancelAttendeeStatus}
        >
          <div className="text-brand flex items-center gap-x-3">
            <MdOutlineChangeCircle size={20} color="#6883FD" />

            <h1 className="lg:text-lg text-base  font-popinsRegular">
              Change Status
            </h1>
          </div>

          <Form
            form={statusForm}
            onFinish={(values) => {
              dispatch(
                actionAttendeeChangeStatus({
                  values,
                  id: attendeeId,
                  eventId,
                  handleCancelAttendeeStatus,
                })
              );
            }}
            layout="vertical"
            className="mt-[20px] mb-0"
          >
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <Form.Item
                  name="attendee_status"
                  label="Attendee Status"
                  className="mb-[12px]"
                  size="small"
                  rules={[
                    {
                      required: true,
                      message: "Please select attendee status!",
                    },
                  ]}
                >
                  <Select
                    options={
                      statusData &&
                      statusData.length > 0 &&
                      statusData.map((s) => ({
                        ...s,
                        disabled:
                          s.label ===
                          getEventAttendeeDetail?.attendee_status
                            ?.event_attendee_status,
                      }))
                    }
                    allowClear
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    loading={getAttendeeStatusLoader}
                    disabled={getAttendeeStatusLoader}
                    style={{ background: "white" }}
                    placeholder="Select Attendee Status"
                    className="begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>

                <Form.Item className="mt-6 mb-0">
                  <Button
                    loading={changeAttendeeStatusLoader}
                    type="primary"
                    className="root-btn min-h-[45px] min-w-[120px]"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </CommonModal>
      </div>

    </EventDetailWrapper>

  );
};

export default Attendees;
