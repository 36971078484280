import {
  Button,
  Col,
  Form,
  Image,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Tooltip,
  Typography,
  Table,
} from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import * as XLSX from "xlsx";
import { useDispatch, useSelector } from "react-redux";
import plusIcon from "../../../assets/img/add-button.svg";
import deletevent from "../../../assets/img/deleteevent.svg";
import plantCodeMaster from "../../../assets/img/plantCodeMaster.svg";
import editevent from "../../../assets/img/editevent.svg";
import {
  actionAddPlantCodeMaster,
  actionClearPlantCodeAllData,
  actionDeletePlantCodeMaster,
  actionEditPlantCodeMaster,
  actionGetAllPlantCodeMaster,
  actionGetPlantCodeMaster,
  actionGetSinglePlantCodeMaster,
} from "../../../store/services/generalMasterService";
import {
  actionGetCountries,
  actionGetStates,
} from "../../../store/services/locationService";
import MasterSearchbar from "../../common/MasterSearchbar";
import SortIcon from "../../common/SortIcon";
import { isValidSearchString } from "../../helper/common-function";
import PageTitleForMasterData from "../../common/PageTitleForMasterData";
import usePermission from "../../../hooks/use-permission";
import { handleActionWithPermission } from "../../../store/slices/authSlice";
import SkeletonTable from "../../common/SkeletonTable";
import CommonTable from "../../common/CommonTable";
import CommonPagination from "../../common/CommonPagination";

const PlantCode = ({ setSelectItem }) => {
  const [form] = Form.useForm();
  const offsetRef = useRef(0);
  const dispatch = useDispatch();
  const {
    plantCodeMasterData,
    getSinglePlantCodeMasterData,
    getSinglePlantCodeMasterLoader,
    getPlantCodeLoader,
    addPlantCodeMasterLoader,
    deletePlantCodeMasterLoader,
    updatePlantCodeMasterLoader,
    plantCodeDataCount,
    allPlantCodeMasterData,
    getAllPlantCodeLoader,
  } = useSelector((state) => state.generalMaster);

  const { countries, states } = useSelector((state) => state.location);

  const [search, setSearch] = useState("");
  const [countrySelected, setCountrySelected] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [plantCodeData, setPlantCodeData] = useState(null);
  const [exportAll, setExportAll] = useState(false);
  const [lastSearch, setLastSearch] = useState('');
  const [pageSize, setPageSize] = useState(20);
  const { hasPermission } = usePermission();

  const isEditing = (record) => record.plant_code_master_id === editingKey;

  useEffect(() => {
    dispatch(actionGetPlantCodeMaster({
      offset: offsetRef.current,
      limit: pageSize,
      search: search
    }));
  }, []);

  useEffect(() => {
    const trimmedSearch = search?.trim();
    if (trimmedSearch !== "" && trimmedSearch !== lastSearch) {
      const timer = setTimeout(() => {
        dispatch(
          actionGetPlantCodeMaster({
            offset: offsetRef.current,
            limit: pageSize,
            search: trimmedSearch
          })
        );
        setLastSearch(trimmedSearch);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }

  }, [search, lastSearch]);

  const handleSearch = (e) => {
    const value = e.target.value;
    if (isValidSearchString(value)) {
      setSearch(value);
      if (value === "") {
        dispatch(
          actionGetPlantCodeMaster({
            offset: offsetRef.current,
            limit: pageSize,
            search: value,
          })
        );
      }
    }
  };

  const handleBlurAndPressEnter = (e) => {
    const value = e.target.value;
    if (search && isValidSearchString(value)) {
      dispatch(
        actionGetPlantCodeMaster({
          offset: offsetRef.current,
          limit: pageSize,
          search: value,
        })
      );
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
    dispatch(actionGetCountries());
  };

  const countryOptions = useMemo(() => {
    return (
      countries &&
      countries.length > 0 &&
      countries.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    );
  }, [countries]);

  const stateData = [];
  states &&
    states.length > 0 &&
    states.map((data) =>
      stateData.push({
        label: data.name,
        value: data.id,
      })
    );

  const handleGetState = (val, data) => {
    dispatch(actionGetStates(data.value));
    setCountrySelected(true);
    form.setFieldsValue({
      state_id: null,
    });
  };

  useEffect(() => {
    if (getSinglePlantCodeMasterData?.country?.id) {
      dispatch(actionGetStates(getSinglePlantCodeMasterData?.country?.id));
    }
  }, [getSinglePlantCodeMasterData]);

  const handleClearState = () => {
    setCountrySelected(false);
    form.setFieldsValue({
      state_id: null,
    });
  };

  useEffect(() => {
    if (plantCodeData) {
      dispatch(
        actionGetSinglePlantCodeMaster(plantCodeData.plant_code_master_id)
      );
    }
  }, [dispatch, plantCodeData]);

  useEffect(() => {
    if (plantCodeData && getSinglePlantCodeMasterData) {
      form.setFieldsValue({
        plant_code: getSinglePlantCodeMasterData.plant_code,
        name_1: getSinglePlantCodeMasterData.name_1,
        name_2: getSinglePlantCodeMasterData.name_2,
        address_1: getSinglePlantCodeMasterData.address_1,
        address_2: getSinglePlantCodeMasterData.address_2,
        address_3: getSinglePlantCodeMasterData.address_3,
        pin_code: getSinglePlantCodeMasterData.pin_code,
        city: getSinglePlantCodeMasterData.city,
        address_1: getSinglePlantCodeMasterData.address_1,
        country_id: getSinglePlantCodeMasterData.country?.id,
        state_id: getSinglePlantCodeMasterData.state?.id,
      });
      if (getSinglePlantCodeMasterData.country?.id) {
        setCountrySelected(true);
      }
    }
  }, [dispatch, getSinglePlantCodeMasterData]);

  const handleCancel = () => {
    setIsModalOpen(false);
    setPlantCodeData(null);
    form.resetFields();
  };

  const onFinish = (values) => {
    plantCodeData
      ? dispatch(
        actionEditPlantCodeMaster({
          id: getSinglePlantCodeMasterData.plant_code_master_id,
          req: values,
          handleCancel,
        })
      )
      : dispatch(
        actionAddPlantCodeMaster({
          values,
          handleCancel,
        })
      );
  };

  const columns = [
    {
      title: <h1 className="pl-[2px]">Plant Code</h1>,
      dataIndex: "plant_code",
      key: "1",
      ellipsis: true,
      fixed: "left",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 150,
      render: (text) => text || "-",
    },
    {
      title: "Name 1",
      dataIndex: "name_1",
      key: "2",
      width: 200,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => text || "-",
    },
    {
      title: "Name 2",
      dataIndex: "name_2",
      key: "3",
      width: 200,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => text || "-",
    },
    {
      title: "Address 1",
      dataIndex: "address_1",
      key: "4",
      width: 250,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <Tooltip title={text}>
          {" "}
          <Typography.Paragraph
            ellipsis={{ rows: 1 }}
            style={{ width: 280, margin: 0 }}
            className="ml-2  !mb-0"
          >
            {text && text.length > 30
              ? `${text.substring(0, 30)}...`
              : text || "-"}
          </Typography.Paragraph>
        </Tooltip>
      ),
    },
    {
      title: "Address 2",
      dataIndex: "address_2",
      key: "5",
      width: 250,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <Tooltip title={text}>
          <Typography.Paragraph
            ellipsis={{ rows: 1 }}
            style={{ width: 280, margin: 0 }}
            className="ml-2  !mb-0"
          >
            {text && text.length > 30
              ? `${text.substring(0, 30)}...`
              : text || "-"}
          </Typography.Paragraph>
        </Tooltip>
      ),
    },
    {
      title: "Address 3",
      dataIndex: "address_3",
      key: "6",
      width: 250,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <Tooltip title={text}>
          {" "}
          <Typography.Paragraph
            ellipsis={{ rows: 1 }}
            style={{ width: 280, margin: 0 }}
            className="ml-2  !mb-0"
          >
            {text && text.length > 30
              ? `${text.substring(0, 30)}...`
              : text || "-"}
          </Typography.Paragraph>
        </Tooltip>
      ),
    },
    {
      title: "Pin Code",
      dataIndex: "pin_code",
      key: "7",
      width: 150,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "City",
      dataIndex: "city",
      key: "8",
      width: 180,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "9",
      width: 180,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (text ? text.name : "-"),
    },
    {
      title: "State",
      dataIndex: "state",
      key: "10",
      width: 180,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (text ? text.name : "-"),
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      fixed: "right",
      dataIndex: "operation",
      width: 150,
      render: (_, record) => {
        const getSinglePlantCodeMasterData = isEditing(record);
        return (
          <div className="flex items-center">
            {getSinglePlantCodeMasterData ? (
              <div className="flex items-center"></div>
            ) : (
              <div className="flex items-center gap-x-2 justify-center">
                <Typography.Link
                  disabled={editingKey !== ""}
                  onClick={() => {
                    dispatch(
                      handleActionWithPermission(
                        "plant-code-master",
                        "update",
                        () => {
                          showModal(),
                            setPlantCodeData(record),
                            dispatch(actionGetPlantCodeMaster());
                        }
                      )
                    );
                  }}
                >
                  <img src={editevent} alt="" />
                </Typography.Link>
              </div>
            )}
            <div className="ml-5">
              {hasPermission("plant-code-master", "delete") ? (
                <Popconfirm
                  okButtonProps={{
                    loading:
                      deletePlantCodeMasterLoader[record.plant_code_master_id],
                  }}
                  description="Are you sure you want to delete?"
                  onConfirm={() =>
                    dispatch(
                      actionDeletePlantCodeMaster({
                        id: record.plant_code_master_id,
                      })
                    )
                  }
                >
                  <img src={deletevent} alt="" className="cursor-pointer" />
                </Popconfirm>
              ) : (
                <img
                  src={deletevent}
                  alt=""
                  className="cursor-pointer"
                  onClick={() => {
                    dispatch(
                      handleActionWithPermission("plant-code-master", "delete")
                    );
                  }}
                />
              )}
            </div>
          </div>
        );
      },
    },
  ];

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
            ? "ASC"
            : "",
    };

    dispatch(
      actionGetPlantCodeMaster({
        offset: offsetRef.current,
        limit: pageSize,
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
  };

  const getData = async (data) => {
    const newData =
      (await data) &&
      data.length > 0 &&
      data.map((d) => ({
        "Plant Code": d.plant_code || "-",
        "Name 1": d.name_1 || "-",
        "Name 2": d.name_2 || "-",
        "Address 1": d.address_1 || "-",
        "Address 2": d.address_2 || "-",
        "Address 3": d.address_3 || "-",
        "Pin Code": d.pin_code || "-",
        City: d.city || "-",
        Country: d.country ? d.country.name : "-",
        State: d.state ? d.state.name : "-",
      }));
    return newData || [];
  };

  const exportToExcel = async (items, type = "") => {
    if (!items || (items && items.length === 0)) {
      return;
    }
    const data = await getData(items);

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Plant Codes");
    XLSX.writeFile(workbook, "plant-codes.xlsx");
    type &&
      type === "all" &&
      dispatch(actionClearPlantCodeAllData({ setExportAll }));
  };

  const onClickDownloadMenu = ({ key }) => {
    if (key === "1") {
      exportToExcel(plantCodeMasterData);
    } else {
      dispatch(actionGetAllPlantCodeMaster());
      setExportAll(true);
    }
  };

  useEffect(() => {
    if (
      exportAll &&
      allPlantCodeMasterData &&
      allPlantCodeMasterData.length > 0
    ) {
      exportToExcel(allPlantCodeMasterData, "all");
    }
  }, [allPlantCodeMasterData]);

  const handleRefresh = () => {
    dispatch(
      actionGetPlantCodeMaster({
        offset: offsetRef.current,
        limit: pageSize,
        search: search
      })
    );
  };
  const handlePaginationChange = (page, pageSize) => {
    offsetRef.current = page - 1;
    setPageSize(pageSize);
    dispatch(
      actionGetPlantCodeMaster({
        offset: offsetRef.current,
        limit: pageSize,
        search: search,
      })
    );
  };
  return (
    <div className="overflow-auto no-scrollbar pb-5">
      <Modal
        centered
        loading={getSinglePlantCodeMasterLoader}
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={false}
        width={700}
        title=""
        open={isModalOpen}
      >
        <div className="flex">
          <img src={plantCodeMaster} alt="" />
          <h1 className="text-[#6883FD] text-[20px] ml-[8px] mt-[5px] font-medium">
            Plant Code
          </h1>
        </div>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Row gutter={24}>
            <Col span={12}>
              {" "}
              <Form.Item
                name="plant_code"
                label="Plant Code"
                normalize={(value) =>
                  value && value.replace(/[^+\d]/g, "").slice(0, 4)
                }
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: true,
                    message: "Please enter plant code!",
                  },
                ]}
              >
                <Input
                  style={{ background: "white" }}
                  placeholder="Enter Plant Code"
                  className="!indent-0.5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {" "}
              <Form.Item
                name="name_1"
                label="Name 1"
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: true,
                    message: "Please enter name 1!",
                  },
                ]}
              >
                <Input
                  style={{ background: "white" }}
                  placeholder="Enter Name 1"
                  className="!indent-0.5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {" "}
              <Form.Item
                name="name_2"
                label="Name 2"
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: false,
                    message: "Please enter name 2!",
                  },
                ]}
              >
                <Input
                  style={{ background: "white" }}
                  placeholder="Enter Name 2"
                  className="!indent-0.5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {" "}
              <Form.Item
                name="address_1"
                label="Address 1"
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: true,
                    message: "Please enter address 1!",
                  },
                ]}
              >
                <Input
                  style={{ background: "white" }}
                  placeholder="Enter Address 1"
                  className="!indent-0.5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {" "}
              <Form.Item
                name="address_2"
                label="Address 2"
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: false,
                    message: "Please enter address 2!",
                  },
                ]}
              >
                <Input
                  style={{ background: "white" }}
                  placeholder="Enter Address 2"
                  className="!indent-0.5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {" "}
              <Form.Item
                name="address_3"
                label="Address 3"
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: false,
                    message: "Please enter address 3!",
                  },
                ]}
              >
                <Input
                  style={{ background: "white" }}
                  placeholder="Enter Address 3"
                  className="!indent-0.5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {" "}
              <Form.Item
                name="pin_code"
                label="Pin Code"
                normalize={(value) => value && value.replace(/[^+\d]/g, "")}
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: true,
                    message: "Please enter pin code",
                  },
                ]}
              >
                <Input
                  style={{ background: "white" }}
                  placeholder="Enter Pin Code"
                  className="!indent-0.5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="country_id"
                label="Country"
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: true,
                    message: "Please select country!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Country"
                  optionFilterProp="children"
                  className=""
                  onClear={handleClearState}
                  // loading={getBeatGradeMasterLoader}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={countryOptions}
                  onSelect={handleGetState}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="state_id"
                label="State"
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: true,
                    message: "Please select state!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select State"
                  optionFilterProp="children"
                  className=""
                  disabled={!countrySelected}
                  // loading={getBeatGradeMasterLoader}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={stateData}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {" "}
              <Form.Item
                name="city"
                label="City"
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: true,
                    message: "Please enter city",
                  },
                ]}
              >
                <Input
                  style={{ background: "white" }}
                  placeholder="Enter City"
                  className="!indent-0.5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>
            </Col>
            {/* <Col span={12}>
                  <Form.Item
                    name="to_unit_id"
                    label="To Unit"
                    className="mb-[12px]"
                    size="small"
                    rules={[
                      {
                        required: true,
                        message: "Please select to unit!",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select To Unit"
                      optionFilterProp="children"
                      className=""
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={fromUnit}
                    />
                  </Form.Item>
                </Col> */}
          </Row>

          <div className="flex justify-center mt-[40px]">
            <Form.Item className="mb-0">
              <Button
                onClick={handleCancel}
                type="button"
                className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={
                    addPlantCodeMasterLoader || updatePlantCodeMasterLoader
                  }
                  type="primary"
                  className="root-btn min-h-[45px] min-w-[120px]"
                  htmlType="submit"
                >
                  {plantCodeMasterData ? "Save" : "Submit"}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>

      <div className="pt-[25px] pb-[20px]">
        <Form component={false}>
          <CommonTable
            moduleKey="plant-code"
            title="Plant Codes"
            total={plantCodeDataCount}
            pageSize={pageSize}
            pageTitle={<h1 className="text-[#6883FD] text-[20px] font-medium">Plant Codes</h1>}
            buttonText="Add Plant Code"
            onAddClick={showModal}
            type="btn"
            resource="plant-code-master"
            loading={getPlantCodeLoader || getAllPlantCodeLoader}
            columns={columns}
            scrollToFirstRowOnChange={true}
            data={plantCodeMasterData}
            rowKey={"plant_code_master_id"}
            // rowSelection={rowSelection}
            onChange={handleSortChange}
            handleRefresh={handleRefresh}
            handleSearch={handleSearch}
            handleBlurAndPressEnter={handleBlurAndPressEnter}
            search={search}
            importRoute="/master-data/plant-code-import"
            isImportEnabled={true}
            onClickDownloadMenu={onClickDownloadMenu}
            handlePaginationChange={handlePaginationChange}
          />
        </Form>
      </div>
    </div>
  );
};

export default PlantCode;
