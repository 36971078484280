import {
  FilePdfOutlined,
  FileTextOutlined,
  FileWordOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Image } from "antd";
import * as CryptoJS from "crypto-js";
import dayjs from "dayjs";
import { PhoneNumberUtil } from "google-libphonenumber";
import { toast } from "sonner";
import { CIPHER_KEY } from "../../config/web-config";
import { FieldType } from "./constants";

const phoneUtil = PhoneNumberUtil.getInstance();

export const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

export const convertToInt = (val) => {
  return val || val != null ? parseInt(val) : null;
};

export const currentYear = dayjs().year();

// Function to retrieve userData from localStorage
export const getStoredUserData = () => {
  const storedUserData = localStorage.getItem("userData");
  if (storedUserData) {
    try {
      return JSON.parse(storedUserData);
    } catch (error) {
      console.error("Error parsing stored userData:", error);
    }
  }
  return null;
};

// Function to retrieve event from localStorage
export const getEventId = () => {
  const storedUserData = localStorage.getItem("event_id");
  if (storedUserData) {
    try {
      return JSON.parse(storedUserData);
    } catch (error) {
      console.error("Error parsing stored event id:", error);
    }
  }
  return null;
};

export const startTimer = (setTimer) => {
  let timer = 59;

  setTimer && setTimer(timer);

  const interval = setInterval(() => {
    timer -= 1;
    setTimer && setTimer(timer);

    if (timer <= 0) {
      clearInterval(interval);
    }
  }, 1000);
  return interval;
};

export const getFormattedTimezone = (offset, name, timezone) => {
  return `(${offset}) ${name}  ( ${timezone} )`;
};

export const getAllKeys = (nodes) => {
  const keys = [];
  nodes.forEach((node) => {
    keys.push(node.key);
    if (node.children) {
      keys.push(...getAllKeys(node.children));
    }
  });
  return keys;
};

export const getFormattedDateTime = (date) => {
  return dayjs(date).format("DD MMM, YYYY HH:mm:ss A");
};

export const getFirstLetterCapital = (str) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function decrypt(encryptedData) {
  const base64 = decodeURIComponent(encryptedData);
  const encrypted = CryptoJS.enc.Base64.parse(base64).toString(
    CryptoJS.enc.Utf8
  );
  // const decrypted = CryptoJS.AES.decrypt(encrypted, "lu6^S-l(U!e=6$ho)1^x7gFcoD2C%^6(H)Ke8nwn0.uzGcNhDev~Z");
  const decrypted = CryptoJS.AES.decrypt(encrypted, CIPHER_KEY);
  return decrypted.toString(CryptoJS.enc.Utf8);
}

export function formatString(input) {
  let formatted = input?.replace(/[^a-zA-Z0-9\s/]/g, " ");
  formatted = formatted?.toLowerCase();
  formatted = formatted?.replace(/(?:^|[\s/])\w/g, (char) =>
    char.toUpperCase()
  );
  return formatted?.trim();
}

export const extractFilename = (url) => {
  const startStr = "uploads/";
  const startIndex = url.indexOf(startStr);

  if (startIndex !== -1) {
    const endIndex = url.indexOf("?", startIndex);
    if (endIndex !== -1) {
      return url.substring(startIndex + startStr.length, endIndex);
    } else {
      return url.substring(startIndex + startStr.length);
    }
  }
  return "";
};

export const getFullName = (firstName, lastName) => {
  const isValidName = (name) =>
    name !== null && name !== undefined && name.trim() !== "";

  // Capitalize first letter of both names
  const formattedFirstName = isValidName(firstName)
    ? getFirstLetterCapital(firstName)
    : "";
  const formattedLastName = isValidName(lastName)
    ? getFirstLetterCapital(lastName)
    : "";

  if (formattedFirstName && formattedLastName) {
    return `${formattedFirstName} ${formattedLastName}`;
  }
  return formattedFirstName || formattedLastName || "-";
};

export const ALLOWED_SEARCH_REGEX = /^[a-zA-Z0-9 @.-]*$/;
export const isValidSearchString = (value) => ALLOWED_SEARCH_REGEX.test(value);

// export const handleErrorMessage = (error) => {
//   console.log({ error });
//   const message = error.response?.data?.message;

//   if (Array.isArray(message)) {
//     toast.error(message[0]);
//   } else if (message && message !== null) {
//     toast.error(message);
//   } else {
//     toast.error(error.message);
//   }

//   throw new Error(error.message);
// };

export const handleErrorMessage = (error) => {
  console.error("Error details:", error);

  const response = error.response;
  const data = response?.data;
  const messages = data?.message;

  if (Array.isArray(messages) && messages.length > 0) {
    const firstMessage = messages[0];

    // Check if the first message is an object
    if (
      typeof firstMessage === "object" &&
      firstMessage.field &&
      firstMessage.message
    ) {
      toast.error(`${firstMessage.message}`);
    } else if (typeof firstMessage === "string") {
      toast.error(firstMessage);
    }
  } else if (typeof messages === "string" && messages.trim() !== "") {
    toast.error(messages);
  } else if (response && response.status === 400) {
    toast.error("Bad Request: Please check your input and try again.");
  } else if (response && response.status === 401) {
    toast.error("Unauthorized: Please log in and try again.");
  } else if (response && response.status === 500) {
    toast.error("Server Error: Please try again later.");
  } else {
    toast.error(error.message);
  }

  throw new Error(messages || error.message || "An unexpected error occurred.");
};

export const formatUrl = (url) => {
  if (!url) return "";
  if (url && !url.startsWith("http://") && !url.startsWith("https://")) {
    return `https://${url}`;
  }
  return url;
};

export const renderFileIcon = (file, size = 24, preview = true) => {
  const extractFileName = extractFilename(file);
  if (
    extractFileName.endsWith("png") ||
    extractFileName.endsWith("jpg") ||
    extractFileName.endsWith("jpeg") ||
    extractFileName.endsWith("gif")
  ) {
    return (
      <Image
        src={file}
        preview={preview}
        alt="docs"
        height={size}
        width={size}
      />
    );
  } else if (extractFileName.endsWith("pdf")) {
    return <FilePdfOutlined style={{ fontSize: size, color: "#ff6347" }} />;
  } else if (
    extractFileName.endsWith("doc") ||
    extractFileName.endsWith("docx")
  ) {
    return <FileWordOutlined style={{ fontSize: size, color: "#1e90ff" }} />;
  } else if (extractFileName.endsWith("mp4")) {
    return <VideoCameraOutlined style={{ fontSize: size, color: "#1890ff" }} />;
  } else {
    return <FileTextOutlined style={{ fontSize: size, color: "#1890ff" }} />;
  }
};

export const extractFileType = (url) => {
  // Extract file extension from URL
  const extension = url.split(".").pop().split("?")[0];
  return extension;
};

export const isNullOrEmpty = (value) => {
  if (value === null || value === undefined) {
    return "- ";
  }

  if (typeof value === "string" && value.trim() === "") {
    return "- ";
  }

  if (Array.isArray(value) && value.length === 0) {
    return "- ";
  }

  if (typeof value === "object" && Object.keys(value).length === 0) {
    return "- ";
  }

  return value;
};

// Function to convert keys to human-readable titles
export const formatTitle = (key) => {
  return key.replace(/_/g, " ").replace(/\b\w/g, (c) => c.toUpperCase());
};

export const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const getPermissionMessage = (resource, action) => {
  const formattedResource = resource?.replace(/_/g, " ");

  return `You don't have permission to ${action} ${formattedResource}.`;
};

export const validatePhoneFields = (customFields) => {
  const invalidPhoneField = customFields.find(
    (field) =>
      field.field_type === "Phone" &&
      (field.value === "" || field.value.length < 3 || field.value.length > 15)
  );
  return invalidPhoneField;
};

/* export const transformToCustomFields = (
  customFieldsData,
  customFieldsReq = {}
) => {
  const customFields = Object.keys(customFieldsReq)
    .map((key) => {
      const field = customFieldsReq[key];
      const fieldData = customFieldsData.find(
        (data) => data.field_name === key
      );

      return {
        field_name: fieldData ? fieldData.field_name : key,
        field_type: fieldData ? fieldData.field_type : field.field_type,
        value:
          fieldData && fieldData.field_type === FieldType.NUMBER
            ? field?.value?.toString()
            : fieldData && fieldData.field_type === FieldType.DATE
            ? dayjs(field?.value).format("YYYY-MM-DD")
            : field.value,
      };
    })
    .filter(
      (field) =>
        field.value !== undefined && field.value !== null && field.value !== ""
    );



  const invalidPhoneField = validatePhoneFields(customFields);

  if (invalidPhoneField) {
    throw new Error(
      "Invalid phone number: must be between 3 and 15 characters."
    );
  }

  return customFields;
}; */

export const transformToCustomFields = (
  customFieldsData,
  customFieldsReq = {}
) => {
  const customFields = Object.keys(customFieldsReq)
    .map((key) => {
      const field = customFieldsReq[key];
      const fieldData = customFieldsData.find(
        (data) => data.field_name === key
      );

      const transformedField = {
        field_name: fieldData ? fieldData.field_name : key,
        field_type: fieldData ? fieldData.field_type : field.field_type,
        value:
          fieldData && fieldData.field_type === FieldType.NUMBER
            ? field?.value?.toString()
            : fieldData && fieldData.field_type === FieldType.DATE
              ? dayjs(field?.value).format("YYYY-MM-DD")
              : field.value,
      };

      // Check if the field type is phone and validate the phone number length
      if (transformedField.field_type === "Phone") {
        if (transformedField.value && transformedField.value.length <= 7) {
          return null; // Filter out if the phone number length is 7 or less
        }
      }

      return transformedField;
    })
    .filter(
      (field) =>
        field !== null &&
        field.value !== undefined &&
        field.value !== null &&
        field.value !== ""
    );

  const invalidPhoneField = validatePhoneFields(customFields);

  if (invalidPhoneField) {
    toast.error("Invalid phone number: must be between 3 and 15 characters.");
  }

  return customFields;
};

// utils/queryUtils.js

export const generateQueryString = (ids, key) => {
  return ids && ids.length > 0 ? ids.map((id) => `&${key}=${id}`).join("") : "";
};

export const safeIncrement = (value, incrementBy = 1) => {
  const num = parseInt(value);
  return (isNaN(num) ? 0 : num) + incrementBy;
};

export const convertOrgChartData = (data = []) => {
  const result = {
    name: data[0]?.name || "",
    attributes: data[0]?.attributes || {},
    _collapsed: false,
    children: [],
  };

  const buildChildren = (children) => {
    return children.map((item) => ({
      name: item.name,
      attributes: item.attributes,
      _collapsed: false,
      children: item.children.length ? buildChildren(item.children) : [],
    }));
  };

  result.children = buildChildren(data[0]?.children || []);
  return result;
};


// fuction for normlize name input
export const sanitizeName = (value) => {
  if (!value) return value;
  return value
    .replace(/^\s+/g, "")
    .replace(/\s+/g, " ")
    .replace(/[^a-zA-Z\s]/g, "");
};

export const normalizeInput = (value) => {

  if (Array.isArray(value)) {
    return value
      .map((item) => {
        if (typeof item === "string") {
          return item
            .replace(/^\s+/g, "")
            .replace(/\s+/g, " ")
            .replace(/[^a-zA-Z0-9\s]/g, "");
        }
        return item;
      })
      .filter(Boolean);
  }

  if (typeof value === "string") {
    return value
      .replace(/^\s+/g, "")
      .replace(/\s+/g, " ")
      .replace(/[^a-zA-Z0-9\s]/g, "");
  }

  return value;
};
