import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Table,
  Typography,
  Select,
  Row,
  Col,
  Popover,
  Pagination,
} from "antd";
import * as XLSX from "xlsx";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import plusIcon from "../../../assets/img/add-button.svg";
import deletevent from "../../../assets/img/deleteevent.svg";
import editedevent from "../../../assets/img/editedevent.svg";
import plusSvg from "../../../assets/img/plus.svg";
import beatmaster from "../../../assets/img/beatmaster.svg";
import editevent from "../../../assets/img/editevent.svg";
import SortIcon from "../../common/SortIcon";
import {
  actionGetBeatMaster,
  actionGetBeatGradeMaster,
  actionAddBeatMaster,
  actionDeleteBeatMaster,
  actionGetSingleBeatMaster,
  actionEditBeatMaster,
  actionGetAllAreaMaster,
  actionGetAllZoneMaster,
  actionAddZoneMaster,
  actionAddAreaMaster,
  actionAddBeatGradeMaster,
  actionGetAllBeatMaster,
  actionClearAllBeatMasterData,
} from "../../../store/services/generalMasterService";
import { isValidSearchString } from "../../helper/common-function";
import MasterSearchbar from "../../common/MasterSearchbar";
import PageTitleForMasterData from "../../common/PageTitleForMasterData";
import usePermission from "../../../hooks/use-permission";
import { handleActionWithPermission } from "../../../store/slices/authSlice";
import SkeletonTable from "../../common/SkeletonTable";
import CommonTable from "../../common/CommonTable";
import CommonPagination from "../../common/CommonPagination";

const BeatMaster = ({ setSelectItem }) => {
  const [editableForm] = Form.useForm();
  const [form] = Form.useForm();
  const offsetRef = useRef(0);
  const [zoneMasterModalForm] = Form.useForm();
  const [areaMasterModalForm] = Form.useForm();
  const [beatGradeModalForm] = Form.useForm();
  const dispatch = useDispatch();
  const {
    BeatMasterData,
    beatGradeMasterData,
    addBeatMasterLoader,
    deleteBeatMasterLoader,
    getBeatMasterLoader,
    getSingleBeatMasterData,
    updateBeatMasterLoader,
    allZoneMasterData,
    getAllAreaMasterLoader,
    getAllZoneMasterLoader,
    addZoneMasterLoader,
    addAreaMasterLoader,
    addBeatGradeMasterLoader,
    getBeatGradeMasterLoader,
    allAreaMasterData,
    beatMasterDataCount,
    allBeatMasterData,
    getAllBeatMasterLoader,
  } = useSelector((state) => state.generalMaster);
  const [search, setSearch] = useState("");
  const [editingKey, setEditingKey] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [edited, setEdited] = useState(false);
  const [data, setData] = useState([]);
  const [select, setSelect] = useState(false);
  const [isZoneModalOpen, setIsZoneModalOpen] = useState(false);
  const [isAreaModalOpen, setIsAreaModalOpen] = useState(false);
  const [isBeatGradeModalOpen, setIsBeatGradeModalOpen] = useState(false);
  const [exportAll, setExportAll] = useState(false);
  const [lastSearch, setLastSearch] = useState('');
  const [pageSize, setPageSize] = useState(20);
  const { hasPermission } = usePermission();

  const isEditing = (record) => record.beat_master_id === editingKey;

  useEffect(() => {
    dispatch(actionGetBeatMaster({
      offset: offsetRef.current,
      limit: pageSize,
      search: search
    }));
  }, [dispatch]);

  const handleAddBeatMaster = (values) => {
    dispatch(actionAddBeatMaster({ values, handleCancel }));
  };

  const showModal = () => {
    setIsModalOpen(true);
    dispatch(actionGetAllAreaMaster());
    dispatch(actionGetAllZoneMaster());
    dispatch(actionGetBeatGradeMaster());
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  useEffect(() => {
    if (select) {
      dispatch(actionGetBeatGradeMaster());
      dispatch(actionGetAllAreaMaster());
      dispatch(actionGetAllZoneMaster());
    }
  }, [select]);

  const beatGradeData =
    beatGradeMasterData &&
    beatGradeMasterData.length > 0 &&
    beatGradeMasterData.map((data) => ({
      label: data.beat_grade,
      value: data.beat_grade_master_id,
    }));

  const area =
    allAreaMasterData &&
    allAreaMasterData.length > 0 &&
    allAreaMasterData.map((data) => ({
      label: data.area_name,
      value: data.area_master_id,
    }));

  const zone =
    allZoneMasterData &&
    allZoneMasterData.length > 0 &&
    allZoneMasterData.map((data) => ({
      label: data.zone_name,
      value: data.zone_master_id,
    }));

  // add zone
  const handleAddZone = (values) => {
    dispatch(
      actionAddZoneMaster({ values, handleCancel: cancelZoneModal, form })
    );
  };

  // add area
  const handleAddArea = (values) => {
    dispatch(
      actionAddAreaMaster({
        values,
        handleCancel: cancelAreaModal,
        form,
      })
    );
  };

  //add beat grade
  const handleAdd = (values) => {
    dispatch(
      actionAddBeatGradeMaster({
        values,
        handleCancel: cancelBeatGradeModal,
        form,
      })
    );
  };

  //zone
  const showZoneModalOpen = () => {
    setIsZoneModalOpen(true);
  };

  const cancelZoneModal = () => {
    setIsZoneModalOpen(false);
    zoneMasterModalForm.resetFields();
  };

  //area
  const showAreaModalOpen = () => {
    setIsAreaModalOpen(true);
  };

  const cancelAreaModal = () => {
    setIsAreaModalOpen(false);
    areaMasterModalForm.resetFields();
  };

  //beat grade
  const showBeatGradeModalOpen = () => {
    setIsBeatGradeModalOpen(true);
  };

  const cancelBeatGradeModal = () => {
    setIsBeatGradeModalOpen(false);
    beatGradeModalForm.resetFields();
  };

  //zone
  const zoneMasterPopover = (
    <>
      <div className="min-w-[350px]">
        <Form
          name=""
          form={zoneMasterModalForm}
          onFinish={handleAddZone}
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="zone_name"
            label="Zone"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please enter zone!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Zone"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={cancelZoneModal}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addZoneMasterLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );

  //area
  const areaMasterPopover = (
    <>
      <div className="min-w-[350px]">
        <Form
          name=""
          form={areaMasterModalForm}
          onFinish={handleAddArea}
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="area_name"
            label="Area"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please enter area!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Area"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={cancelAreaModal}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addAreaMasterLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );

  //beat grade
  const beatGradePopover = (
    <>
      <div className="min-w-[350px]">
        <Form
          name=""
          form={beatGradeModalForm}
          onFinish={handleAdd}
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="beat_grade"
            label="Beat Grade"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please enter beat grade!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Beat Grade"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={cancelBeatGradeModal}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addBeatGradeMasterLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode;
    if (dataIndex === "beat_grade") {
      inputNode = (
        <Select
          className="selected-input"
          options={beatGradeData}
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          style={{ background: "white" }}
        />
      );
    } else if (dataIndex === "zone") {
      inputNode = (
        <Select
          className="selected-input"
          options={zone}
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          style={{ background: "white" }}
        />
      );
    } else if (dataIndex === "area") {
      inputNode = (
        <Select
          className="selected-input"
          options={area}
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          style={{ background: "white" }}
        />
      );
    } else {
      inputNode = <Input />;
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            className="mb-0 editable-order"
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          <Typography.Paragraph
            style={{ marginBottom: 0 }}
            ellipsis={{ rows: 1, expandable: false, tooltip: false }}
            className="px-[11px]"
          >
            {children}
          </Typography.Paragraph>
        )}
      </td>
    );
  };

  //edit case
  const edit = (record) => {
    dispatch(actionGetSingleBeatMaster(record.beat_master_id));
    setEdited(true);
    setSelect(true);
    setEditingKey(record.beat_master_id);
  };

  useEffect(() => {
    if (getSingleBeatMasterData && edited) {
      editableForm.setFieldsValue({
        beat_grade: getSingleBeatMasterData.beat_grade?.beat_grade_master_id,
        area: getSingleBeatMasterData.area?.area_master_id,
        zone: getSingleBeatMasterData.zone?.zone_master_id,
        beat_name: getSingleBeatMasterData.beat_name,
        city: getSingleBeatMasterData.city,
      });
      // setEditingKey(getSingleBeatMasterData.beat_master_id);
    }
  }, [getSingleBeatMasterData, edited]);

  const save = async (id) => {
    try {
      const row = await editableForm.validateFields();
      const { beat_grade, area, zone, beat_name, city } = row;
      const req = {
        beat_grade_id: beat_grade,
        area_id: area,
        zone_id: zone,
        beat_name,
        city,
      };
      dispatch(
        actionEditBeatMaster({
          id,
          req,
          setEditingKey,
          setEdited,
        })
      );
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  useEffect(() => {
    const trimmedSearch = search?.trim();
    if (trimmedSearch !== "" && trimmedSearch !== lastSearch) {
      const timer = setTimeout(() => {
        dispatch(
          actionGetBeatMaster({
            offset: offsetRef.current,
            limit: pageSize,
            search: trimmedSearch
          })
        );
        setLastSearch(trimmedSearch);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }

  }, [search, lastSearch]);

  const handleSearch = (e) => {
    if (isValidSearchString(e.target.value)) {
      setSearch(e.target.value);
      if (e.target.value === "") {
        dispatch(
          actionGetBeatMaster({
            offset: offsetRef.current,
            limit: pageSize,
            search: e.target.value,
          })
        );
      }
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search && isValidSearchString(e.target.value)) {
      dispatch(
        actionGetBeatMaster({
          offset: offsetRef.current,
          limit: pageSize,
          search: e.target.value,
        })
      );
    }
  };

  const columns = [
    {
      title: "SR No.",
      dataIndex: "srno",
      key: "srno",
      width: 100,
      fixed: "left",
      className: "bg-color",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Beat Name",
      dataIndex: "beat_name",
      key: "1",
      width: 100,
      editable: true,
      fixed: "left",
      ellipsis: true,
      className: "bg-color",
      sorter: true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Beat Grade",
      dataIndex: "beat_grade",
      key: "2",
      width: 200,
      editable: true,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text, record) => (
        <span className="ml-2">
          {record.beat_grade && record.beat_grade.beat_grade
            ? record.beat_grade.beat_grade
            : "-"}
        </span>
      ),
    },
    {
      title: "Zone",
      dataIndex: "zone",
      key: "3",
      width: 120,
      editable: true,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text, record) => (
        <span className="ml-2">
          {record.zone && record.zone.zone_name ? record.zone.zone_name : "-"}
        </span>
      ),
    },
    {
      title: "City",
      dataIndex: "city",
      key: "4",
      width: 120,
      editable: true,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Area",
      dataIndex: "area",
      key: "5",
      width: 120,
      editable: true,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text, record) => (
        <span className="ml-2">
          {record.area && record.area.area_name ? record.area.area_name : "-"}
        </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 150,
      key: "action",
      fixed: "right",
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <div className="flex items-center">
            {editable ? (
              <div className="flex items-center">
                {updateBeatMasterLoader[record.beat_master_id] ? (
                  <LoadingOutlined />
                ) : (
                  <Typography.Link onClick={() => save(record.beat_master_id)}>
                    <img src={editedevent} alt="Edited Event" />
                  </Typography.Link>
                )}
              </div>
            ) : (
              <div className="flex items-center gap-x-2 justify-center">
                <Typography.Link
                  disabled={editingKey !== ""}
                  onClick={() => {
                    dispatch(
                      handleActionWithPermission("beat-master", "update", () =>
                        edit(record)
                      )
                    );
                  }}
                >
                  <img src={editevent} alt="Edit" />
                </Typography.Link>
              </div>
            )}
            <div className="ml-5">
              {hasPermission("beat-master", "delete") ? (
                <Popconfirm
                  okButtonProps={{
                    loading: deleteBeatMasterLoader[record.beat_master_id],
                  }}
                  title="Are you sure you want to delete?"
                  onConfirm={() =>
                    dispatch(
                      actionDeleteBeatMaster({
                        id: record.beat_master_id,
                        setEditingKey,
                      })
                    )
                  }
                >
                  <img
                    src={deletevent}
                    alt="Delete"
                    className="cursor-pointer"
                  />
                </Popconfirm>
              ) : (
                <img
                  src={deletevent}
                  alt=""
                  className="cursor-pointer"
                  onClick={() => {
                    dispatch(
                      handleActionWithPermission("beat-master", "delete")
                    );
                  }}
                />
              )}
            </div>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "beat_name" ? "select" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
            ? "ASC"
            : "",
    };

    dispatch(
      actionGetBeatMaster({
        offset: offsetRef.current,
        limit: pageSize,
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
  };

  const getData = async (data) => {
    const newData =
      (await data) &&
      data.length > 0 &&
      data.map((d) => ({
        "Beat Name": d.beat_name ? d.beat_name : "-",
        "Beat Grade": d.beat_grade ? d.beat_grade.beat_grade : "-",
        Zone: d.zone ? d.zone.zone_name : "-",
        City: d.city ? d.city : "-",
        Area: d.area ? d.area.area_name : "-",
      }));
    return newData;
  };

  const exportToExcel = async (items, type = "") => {
    if (!items || (items && items.length === 0)) {
      return;
    }
    const data = await getData(items);
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Beats");
    XLSX.writeFile(workbook, "beats.xlsx");
    type &&
      type === "all" &&
      dispatch(actionClearAllBeatMasterData({ setExportAll }));
  };

  const onClickDownloadMenu = ({ key }) => {
    if (key === "1") {
      exportToExcel(BeatMasterData);
    } else {
      dispatch(actionGetAllBeatMaster());
      setExportAll(true);
    }
  };

  useEffect(() => {
    if (exportAll && allBeatMasterData && allBeatMasterData.length > 0) {
      exportToExcel(allBeatMasterData, "all");
    }
  }, [allBeatMasterData]);

  const handleRefresh = () => {
    dispatch(
      actionGetBeatMaster({
        offset: offsetRef.current,
        limit: pageSize,
        search: search
      })
    );
  };

   const handlePaginationChange = (page, pageSize) => {
      offsetRef.current = page - 1;
      setPageSize(pageSize);
      dispatch(
        actionGetBeatMaster({
          offset: offsetRef.current,
          limit: pageSize,
          search: search,
        })
      );
    };

  return (
    <div className="event-input">
      <Modal
        centered
        onOk={() => handleCancel()}
        onCancel={() => handleCancel()}
        footer={false}
        width={500}
        title=""
        open={isModalOpen}
      >
        <div className="flex items-center">
          <img src={beatmaster} alt="Beat Master" />
          <h1 className="text-[#6883FD] text-[20px] ml-[8px] mt-[5px] font-medium">
            Add Beat Master
          </h1>
        </div>
        <Form
          form={form}
          onFinish={handleAddBeatMaster}
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="beat_name"
            label="Beat Name"
            className="mb-[12px]"
            rules={[
              {
                required: true,
                message: "Please enter beat name!",
              },
            ]}
          >
            <Input placeholder="Enter Beat name" className="rounded-[10px]" />
          </Form.Item>

          <Row gutter={[24, 0]}>
            <Col span={24}>
              <Popover
                placement="bottomRight"
                content={beatGradePopover}
                trigger="click"
                open={isBeatGradeModalOpen}
                onOpenChange={(visible) => {
                  if (!visible) {
                    setIsBeatGradeModalOpen(false);
                    beatGradeModalForm.resetFields();
                  }
                }}
              >
                <div
                  className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                  onClick={showBeatGradeModalOpen}
                >
                  <Image
                    src={plusSvg}
                    alt="image"
                    height={20}
                    width={20}
                    preview={false}
                  />
                  <h1 className="text-[14px] font-popinsRegular ml-1">
                    Add Beat Grade
                  </h1>
                </div>
              </Popover>
              <Form.Item
                name="beat_grade_id"
                label="Beat Grade"
                className=""
                rules={[
                  { required: true, message: "Please select beat grade!" },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Beat Grade"
                  className=""
                  loading={getBeatGradeMasterLoader}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={beatGradeData}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[24, 0]}>
            <Col span={24}>
              <Popover
                placement="bottomRight"
                content={zoneMasterPopover}
                trigger="click"
                open={isZoneModalOpen}
                onOpenChange={(visible) => {
                  if (!visible) {
                    setIsZoneModalOpen(false);
                    zoneMasterModalForm.resetFields();
                  }
                }}
              >
                <div
                  className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                  onClick={showZoneModalOpen}
                >
                  <Image
                    src={plusSvg}
                    alt="image"
                    height={20}
                    width={20}
                    preview={false}
                  />
                  <h1 className="text-[14px] font-popinsRegular ml-1">
                    Add Zone
                  </h1>
                </div>
              </Popover>

              <Form.Item
                name="zone_id"
                label="Zone"
                className=""
                rules={[{ required: true, message: "Please select zone!" }]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Zone"
                  optionFilterProp="children"
                  className=""
                  loading={getAllZoneMasterLoader}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={zone}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="city"
            label="City"
            className="mb-[12px]"
            rules={[
              {
                required: true,
                message: "Please enter city!",
              },
            ]}
          >
            <Input placeholder="Enter City" className="rounded-[10px]" />
          </Form.Item>

          <Row gutter={[24, 0]}>
            <Col span={24}>
              <Popover
                placement="bottomRight"
                content={areaMasterPopover}
                trigger="click"
                open={isAreaModalOpen}
                onOpenChange={(visible) => {
                  if (!visible) {
                    setIsAreaModalOpen(false);
                    areaMasterModalForm.resetFields();
                  }
                }}
              >
                <div
                  className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                  onClick={showAreaModalOpen}
                >
                  <Image
                    src={plusSvg}
                    alt="image"
                    height={20}
                    width={20}
                    preview={false}
                  />
                  <h1 className="text-[14px] font-popinsRegular ml-1">
                    Add Area
                  </h1>
                </div>
              </Popover>
              <Form.Item
                name="area_id"
                label="Area"
                className=""
                rules={[{ required: true, message: "Please select area!" }]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Area"
                  optionFilterProp="children"
                  className=""
                  loading={getAllAreaMasterLoader}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={area}
                />
              </Form.Item>
            </Col>
          </Row>
          <div className="flex justify-center mt-[40px]">
            <Form.Item className="mb-0">
              <Button
                type="button"
                className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Form.Item>
            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  type="primary"
                  className="root-btn min-h-[45px] min-w-[120px]"
                  loading={addBeatMasterLoader}
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>

      <div className="pt-[25px] pb-[20px]">
          <Form form={editableForm} component={false}>
            <CommonTable
              moduleKey="beat-master"
              title="Beats"
              total={beatMasterDataCount}
              pageSize={pageSize}
              pageTitle={<h1 className="text-[#6883FD] text-[20px] font-medium">Beats</h1>}
              buttonText="Add Beat Master"
              onAddClick={showModal}
              type="btn"
              resource="beat-master"
              loading={getBeatMasterLoader || getAllBeatMasterLoader}
              columns={mergedColumns}
              scrollToFirstRowOnChange={true}
              data={BeatMasterData}
              rowKey={"plant_code_master_id"}
              onChange={handleSortChange}
              handleRefresh={handleRefresh}
              handleSearch={handleSearch}
              handleBlurAndPressEnter={handleBlurAndPressEnter}
              search={search}
              className="bg-color"
              importRoute="/master-data/beat-master-import"
              isImportEnabled={true}
              onClickDownloadMenu={onClickDownloadMenu}
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              handlePaginationChange={handlePaginationChange}
           />
          </Form>
        </div>
    </div>
  );
};

export default BeatMaster;
