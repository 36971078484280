import { createSlice } from "@reduxjs/toolkit";
import { actionDownloadFile, actionGetAdvancedFilterFields } from "../services/commonService";

const commonSlice = createSlice({
  name: "common",
  initialState: {
    downloadFileLoader: {},
    getAdvanedFiltersLoader: false,
    advancedFilters: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      //Download File Slices
      .addCase(actionDownloadFile.pending, (state, action) => {
        const uploadId = action.meta.arg.upload_id
          ? action.meta.arg.upload_id
          : action.meta.arg;
        state.downloadFileLoader[uploadId] = true;
      })
      .addCase(actionDownloadFile.fulfilled, (state, action) => {
        const uploadId = action.meta.arg.upload_id || undefined;
        state.downloadFileLoader[uploadId] = false;
      })
      .addCase(actionDownloadFile.rejected, (state, action) => {
        const uploadId = action.meta.arg.upload_id || undefined;
        state.downloadFileLoader[uploadId] = false;
      })

      .addCase(actionGetAdvancedFilterFields.pending, (state, action) => {
        state.getAdvanedFiltersLoader = true;
      })
      .addCase(actionGetAdvancedFilterFields.fulfilled, (state, action) => {
        state.getAdvanedFiltersLoader = false;
        state.advancedFilters = action.payload.data || []

      })
      .addCase(actionGetAdvancedFilterFields.rejected, (state, action) => {
        state.getAdvanedFiltersLoader = false;
        state.advancedFilters = []
      })
  },
});
export default commonSlice.reducer;
