
import { DownOutlined } from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Form,
  Image,
  Input,
  Select,
  Space,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import company from "../../assets/img/company-name.svg";
import logo from "../../assets/img/logo.svg";
import rightArrow from "../../assets/img/right-arrow.svg";
import {
  actionGetTimezones,
  actionRegisterCompany,
} from "../../store/services/authService";
import { getFormattedTimezone } from "../helper/common-function";
import useDeviceInfo, { DeviceType } from "../../hooks/use-device-info";

const CompanyName = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { registerCompanyLoader, user } = useSelector((state) => state.auth);
  const { timezones, timezoneLoader } = useSelector((state) => state.auth);
  const [timezone, setTimezone] = useState(null)
  const token = localStorage.getItem("crmWebToken");


  useEffect(() => {
    const findOne = timezones && timezones.length > 0 && timezones.find((t) => t.id === 70);
    const selectedTimezone = findOne?.id || 70;
    setTimezone(selectedTimezone);
  }, [timezones]);

  const {
    osName,
    osVersion,
    deviceName,
    deviceType,
    batteryPercentage,
    latitude,
    browserName,
    longitude,
  } = useDeviceInfo();

  const company_user_logs = {
    device_name: deviceName,
    os_name: osName,
    os_version: osVersion,
    device_type: deviceType,
    is_tablet: deviceType === DeviceType.TABLET,
    battery_parcentage: batteryPercentage,
    latitude,
    longitude,
    user_log_from: "LOG_IN",
  };

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [navigate, token]);

  useEffect(() => {
    if (user && user.company_users && user.company_users.length > 0) {
      navigate("/");
    }
  }, [user, navigate]);

  useEffect(() => {
    dispatch(actionGetTimezones());
  }, [dispatch]);

  const onFinish = async (values) => {
    const request = {
      ...values,
      fcm_token: localStorage.getItem("firebase_token"),
      company_user_logs,
      timezone,
      currency_locale: "India",
    };
    dispatch(actionRegisterCompany({ request, navigate, user }));
  };

  const items =
    timezones &&
    timezones.length > 0 &&
    timezones.map((tzone) => {
      return {
        label: getFormattedTimezone(tzone.offset, tzone.name, tzone.timezone),
        value: tzone.id,
      };
    });

  return (
    <section className="flex-1  min-h-[500px] my-auto flex flex-col items-center justify-center relative lg:py-12 md:py-10 py-5  px-4 2xl:max-w-xl max-w-lg w-full mx-auto">
      <div className="flex  lg:pt-10 pt-5  flex-col items-center justify-center">
        <Image
          className="text-center mx-auto"
          preview={false}
          height={50}
          src={logo}
          alt="Logo"
        />
        <p className="lg:text-2xl md:text-xl text-base lg:py-4 md:py-3 py-2 text-[#676767]">
          Welcome {user && user.first_name}
        </p>
      </div>
      <Form
        form={form}
        layout="vertical"
        name="normal_login"
        className="login-form w-full pt-5"
        onFinish={onFinish}
      >
        <Form.Item
          name="company_name"
          label="Company Name"
          normalize={(value) => {
            return value.replace(/^\s+/g, "").replace(/\s+/g, " ").replace(/[^a-zA-Z0-9 .]/g, "");
          }}
          rules={[
            {
              required: true,
              message: "Please enter company name!",
            },
          ]}
        >
          <Input
            placeholder="Company Name"
            className="indent-5"
            prefix={
              <Image
                src={company}
                className="h-[18px] w-[18px] site-form-item-icon font-bold"
                preview={false}
              />
            }
          />
        </Form.Item>

        <div className="flex-center flex-col gap-y-1 mb-10 company-dropdown">
          <p className="text-[#6C6C6C] text-center">
            Your current time zone is
          </p>

          <Select
            loading={timezoneLoader}
            disabled={timezoneLoader}
            value={timezone}
            suffixIcon={false}
            onChange={(e) => setTimezone(e)}
            className="w-full text-center text-primary mx-auto"
            rules={[{ required: true, message: "Please select a country" }]}
            variant="borderless"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={items}
          />
          <p className="text-[#6C6C6C]">
            Currency Locale is{" "}
            <span className="text-primary text-center cursor-not-allowed">
              India
            </span>
          </p>
        </div>
        <Form.Item className="flex-center">
          <Button
            loading={registerCompanyLoader}
            style={{ minHeight: 40 }}
            type="primary"
            className="filled-button flex-center gap-x-1"
            htmlType="submit"
          >
            Continue
            <Image preview={false} src={rightArrow} alt="Right Image" />
          </Button>
        </Form.Item>
      </Form>
    </section>
  );
};

export default CompanyName;
