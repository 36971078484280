import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/web-config";
import { toast } from "sonner";
import axios from "axios";
import { handleGetResponse } from "./commonService";
import { handleDeleteResponse } from "./commonService";
import {
  generateQueryString,
  handleErrorMessage,
} from "../../components/helper/common-function";

// get all productsss
export const actionGetAllProductsMaster = createAsyncThunk(
  "actionGetAllProductsMaster",
  async ({ search = "", orderByColumn = "", order = "", status = "" } = {}) => {
    return handleGetResponse(
      `material-master?getall=YES${search ? `&search=${search}` : ""}${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

export const actionGetFilteredProduts = createAsyncThunk(
  "actionGetFilteredProduts",
  async ({
    search = "",
    material_category_id = [],
    material_sub_category_id = [],
    material_type_id = [],
    customFieldParams = {},
    offset = 0,
    limit = 20,
    getall = "NO",
    onFilterModalClose = () => { },
  } = {}) => {
    const queryParams = [];

    const addArrayToQuery = (arr, key) => {
      if (arr && arr.length > 0) {
        arr.forEach((id) => queryParams.push(`${key}=${id}`));
      }
    };

    if (search) {
      queryParams.push(`search=${search}`);
    }

    addArrayToQuery(material_category_id, "material_category_id");
    addArrayToQuery(material_sub_category_id, "material_sub_category_id");
    addArrayToQuery(material_type_id, "material_type_id");

    if (Object.keys(customFieldParams).length > 0) {
      const customFieldsQuery = Object.entries(customFieldParams)
        .map(([key, values]) =>
          values.map((value) => `_${key}=${value}`).join("&")
        )
        .join("&");

      queryParams.push(customFieldsQuery);
    }

    queryParams.push(`offset=${offset}`);
    queryParams.push(`getall=${getall}`);

    const queryString = `material-master?limit=${limit}&${queryParams.join(
      "&"
    )}`;

    return handleGetResponse(queryString);
  }
);

export const actionGetProductsMaster = createAsyncThunk(
  "actionGetProductsMaster",
  async (
    {
      limit,
      search = "",
      orderByColumn = "",
      order = "",
      offset = 0,
      material_category_id = [],
      material_sub_category_id = [],
      material_type_id = [],
      customFieldParams = {},
      // onFilterModalClose,
      // form: filterForm,
    } = {},
    { rejectWithValue }
  ) => {
    const mCategory = generateQueryString(
      material_category_id,
      "material_category_id"
    );
    const mSubCategory = generateQueryString(
      material_sub_category_id,
      "material_sub_category_id"
    );
    const mCategoryType = generateQueryString(
      material_type_id,
      "material_type_id"
    );

    const customFieldsQuery = Object.entries(customFieldParams)
      .map(([key, values]) =>
        values.map((value) => `_${key}=${encodeURIComponent(value)}`).join("&")
      )
      .join("&");


    try {
      // Make the API request
      const res = await axios.get(
        `${BASE_URL}/material-master?limit=${limit}&offset=${offset}${search ? `&search=${encodeURIComponent(search)}` : ""
        }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${order ? `&order=${order}` : ""
        }${mCategory}${mSubCategory}${mCategoryType}${customFieldsQuery ? `&${customFieldsQuery}` : ""
        }`
      );

      // Extract and return data
      const { status, message: customMsg, data, total_records } = res.data;
      if (parseInt(status) === 200) {
        // onFilterModalClose && onFilterModalClose();
        return { data, total_records };
      } else if (parseInt(status) === 404) {
        return [];
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
      return rejectWithValue(error.message);
    }
  }
);

export const actionClearProductsAllData = createAsyncThunk(
  "actionClearProductsAllData",
  async ({ setExportAll }) => {
    setExportAll && setExportAll(false);
    return "";
  }
);

export const actionGetProductMaster = createAsyncThunk(
  "actionGetProductMaster",
  async (id) => {
    return handleGetResponse(`material-master/${id}`);
  }
);

export const actionAddProductMaster = createAsyncThunk(
  "actionAddProductMaster",
  async ({ values, handleCancel }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/material-master`, values);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionUpdateProductMaster = createAsyncThunk(
  "actionUpdateProductMaster",
  async ({ materialId, values, handleCancel }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/material-master/${materialId}`,
        values
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        await toast.success(customMsg, 5);
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionDeleteProductMaster = createAsyncThunk(
  "actionDeleteProductMaster",
  async (id, { dispatch }) => {
    return handleDeleteResponse(`material-master/${id}`, id, null, dispatch);
  }
);

// get single product category
export const actionGetProductDetailMaster = createAsyncThunk(
  "actionGetProductDetailMaster",
  (id) => {
    return handleGetResponse(`product-master/${id}`);
  }
);

// product category start here
//  Action to get All product category
export const actionGetProductCategoryMaster = createAsyncThunk(
  "actionGetProductCategoryMaster",
  async ({ search = "", orderByColumn = "", order = "", status = "" } = {}) => {
    return handleGetResponse(
      `product-category-master?getall=YES${search ? `&search=${search}` : ""}${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}${status ? `&status=${status}` : ""}`
    );
  }
);

// get all
export const actionGetProductCategoryMasterWithPagination = createAsyncThunk(
  "actionGetProductCategoryMasterWithPagination",
  async ({
    offset = 0,
    limit,
    search = "",
    orderByColumn = "",
    order = "",
    status = "",
  } = {}) => {
    return handleGetResponse(
      `product-category-master?limit=${limit}&offset=${offset}${search ? `&search=${search}` : ""
      }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${order ? `&order=${order}` : ""
      }${status ? `&status=${status}` : ""}`
    );
  }
);

//add product category
export const actionAddProductCategoryMaster = createAsyncThunk(
  "actionAddProductCategoryMaster",
  async ({ values, handleCancel, form }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/product-category-master`,
        values
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        form &&
          form.setFieldsValue({
            material_category_id: data.product_category_master_id,
          });
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//delete product category
export const actionDeleteProductCategoryMaster = createAsyncThunk(
  "actionDeleteProductCategoryMaster",
  async ({ id, setEditingKey }, { dispatch }) => {
    return handleDeleteResponse(
      `product-category-master/${id}`,
      id,
      setEditingKey,
      dispatch
    );
  }
);

//get single product category
export const actionGetSingleProductCategoryMaster = createAsyncThunk(
  "actionGetSingleProductCategoryMaster",
  (id) => {
    return handleGetResponse(`product-category-master/${id}`);
  }
);

//get product sub category
export const actionGetProductSubCategoryMaster = createAsyncThunk(
  "actionGetProductSubCategoryMaster",
  async ({
    offset = 0,
    limit,
    search = "",
    orderByColumn = "",
    order = "",
    status = "",
  } = {}) => {
    return handleGetResponse(
      `product-sub-category-master?limit=${limit}&offset=${offset}${search ? `&search=${search}` : ""
      }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${order ? `&order=${order}` : ""
      }${status ? `&status=${status}` : ""}`
    );
  }
);
// get All
export const actionGetAllProductSubCategoryMaster = createAsyncThunk(
  "actionGetAllProductSubCategoryMaster",
  async () => {
    return handleGetResponse(`product-sub-category-master?getall=YES`);
  }
);

export const actionClearAllProductSubCategory = createAsyncThunk(
  "actionClearAllProductSubCategory",
  async ({ setExportAll }) => {
    setExportAll && setExportAll(false);
    return "";
  }
);

//add product sub category
export const actionAddProductSubCategoryMaster = createAsyncThunk(
  "actionAddProductSubCategoryMaster",
  async ({ values, handleCancel, form }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/product-sub-category-master`,
        values
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form &&
          form.setFieldsValue({
            material_sub_category_id: data.product_sub_category_master_id,
          });
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//delete product  sub category
export const actionDeleteProductSubCategoryMaster = createAsyncThunk(
  "actionDeleteProductSubCategoryMaster",
  async ({ id, setEditingKey }, { dispatch }) => {
    return handleDeleteResponse(
      `product-sub-category-master/${id}`,
      id,
      setEditingKey,
      dispatch
    );
  }
);

//edit product category master
export const actionEditProductCategoryMaster = createAsyncThunk(
  "actionEditProductCategoryMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/product-category-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//get single product  sub category
export const actionGetSingleProductSubCategoryMaster = createAsyncThunk(
  "actionGetSingleProductSubCategoryMaster",
  (id) => {
    return handleGetResponse(`product-sub-category-master/${id}`);
  }
);

//edit product sub category master
export const actionEditProductSubCategoryMaster = createAsyncThunk(
  "actionEditProductSubCategoryMaster",
  async ({ id, req, setEditingKey, setEdited }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/product-sub-category-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//product type start here
//get all product type
export const actionGetProductTypeMaster = createAsyncThunk(
  "actionGetProductTypeMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `product-type-master?getall=YES${search ? `&search=${search}` : ""}${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

//add product type
export const actionAddProductTypeMaster = createAsyncThunk(
  "actionAddProductTypeMaster",
  async ({ values, handleCancel, setMaterialType }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/product-type-master`,
        values
      );
      const { status, message: customMsg, data } = response.data;

      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setMaterialType && setMaterialType(data.product_type_master_id);
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//delete product type
export const actionDeleteProductTypeMaster = createAsyncThunk(
  "actionDeleteProductTypeMaster",
  async ({ id, setEditingKey }, { dispatch }) => {
    return handleDeleteResponse(`product-type-master/${id}`, id, setEditingKey, dispatch);
  }
);

//edit product type
export const actionEditProductTypeMaster = createAsyncThunk(
  "actionEditProductTypeMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/product-type-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//get single product type
export const actionGetSingleProductTypeMaster = createAsyncThunk(
  "actionGetSingleProductTypeMaster",
  (id) => {
    return handleGetResponse(`product-type-master/${id}`);
  }
);

//hsn/sac code start here
export const actionGetHsnSacMaster = createAsyncThunk(
  "actionGetHsnSacMaster",
  async ({ offset = 0, limit, search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `hsn-code-master?limit=${limit}&offset=${offset}${search ? `&search=${search}` : ""
      }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${order ? `&order=${order}` : ""
      }`
    );
  }
);
export const actionGetAllHsnSacMaster = createAsyncThunk(
  "actionGetAllHsnSacMaster",
  async () => {
    return handleGetResponse(`hsn-code-master?getall=YES`);
  }
);

export const actionClearAllHSnCodes = createAsyncThunk(
  "actionClearAllHSnCodes",
  async ({ setExportAll }) => {
    setExportAll && setExportAll(false);
    return "";
  }
);

//add hsn/sac
export const actionAddHsnSacCodeMaster = createAsyncThunk(
  "actionAddHsnSacCodeMaster",
  async ({ values, handleCancel, form }) => {
    try {
      const response = await axios.post(`${BASE_URL}/hsn-code-master`, values);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        form &&
          form.setFieldsValue({
            hsn_code_master_id: data.hsn_code_master_id,
          });
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//delete hsn/sac code
export const actionDeleteHsnSacCodeMaster = createAsyncThunk(
  "actionDeleteHsnSacCodeMaster",
  async ({ id, setEditingKey }, { dispatch }) => {
    return handleDeleteResponse(`hsn-code-master/${id}`, id, setEditingKey, dispatch);
  }
);

//edit Hsn/sac code
export const actionEditHsnSacMaster = createAsyncThunk(
  "actionEditHsnSacMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/hsn-code-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//get single hsn/sac
export const actionGetSingleHsnSacCodeMaster = createAsyncThunk(
  "actionGetSingleHsnSacCodeMaster",
  (id) => {
    return handleGetResponse(`hsn-code-master/${id}`);
  }
);

//get all unit
export const actionGetUnitMaster = createAsyncThunk(
  "actionGetUnitMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `unit-master?getall=YES${search ? `&search=${search}` : ""}${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

export const actionClearAllUnits = createAsyncThunk(
  "actionClearAllUnits",
  async ({ setExportAll }) => {
    setExportAll && setExportAll(false);
    return "";
  }
);

//add unit
export const actionAddUnitMaster = createAsyncThunk(
  "actionAddUnitMaster",
  async ({ values, handleCancel, form, field }) => {
    try {
      const response = await axios.post(`${BASE_URL}/unit-master`, values);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);

        handleCancel && handleCancel();
        form &&
          form.setFieldsValue({
            [field]: data.unit_master_id,
          });
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//delete unit master
export const actionDeleteUnitMaster = createAsyncThunk(
  "actionDeleteUnitMaster",
  async ({ id, setEditingKey }, { dispatch }) => {
    return handleDeleteResponse(`unit-master/${id}`, id, setEditingKey, dispatch);
  }
);

//get single unit
export const actionGetSingleUnitMaster = createAsyncThunk(
  "actionGetSingleUnitMaster",
  (id) => {
    return handleGetResponse(`unit-master/${id}`);
  }
);

//edit unit master
export const actionEditUnitMaster = createAsyncThunk(
  "actionEditUnitMaster",
  async ({ id, req, setEditingKey, setEdited }) => {
    try {
      const response = await axios.patch(`${BASE_URL}/unit-master/${id}`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionExportAllProductCategoryMaster = createAsyncThunk(
  "actionExportAllProductCategoryMaster",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/product-category-master/import-excel-format`,
        { responseType: "blob" }
      );
      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "product_categories.xlsx");
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        const { message: customMsg } = response.data;
        toast.error(customMsg, 5);
        throw new Error(`API Error: ${customMsg}`);
      }
    } catch (error) {
      handleErrorMessage(error);
      return rejectWithValue(error.message);
    }
  }
);

export const actionClearAllProductCategory = createAsyncThunk(
  "actionClearAllProductCategory",
  async ({ setExportAll }) => {
    setExportAll && setExportAll(false);
    return "";
  }
);

//UPLOAD ALL PRODUCT CATEGORY
export const actionImportUploadProductCategoryMaster = createAsyncThunk(
  "actionImportUploadProductCategoryMaster",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}product-category-master/import`,
        { responseType: "blob" }
      );
      if (response.status === 200) {
        // Directly return the Blob data
        return { data: response.data };
      } else if (response.status === 404) {
        return rejectWithValue("File not found");
      } else {
        toast.error("Failed to download file");
        throw new Error("API Error");
      }
    } catch (error) {
      handleErrorMessage(error);
      return rejectWithValue(error.message);
    }
  }
);

// Material Conversion start
// get all material conversion
export const actionGetAllMaterialConversions = createAsyncThunk(
  "actionGetAllMaterialConversions",
  async (id) => {
    return handleGetResponse(`material-conversion/${id}?getall=YES`);
  }
);

// add material conversion
export const actionAddMaterialConversion = createAsyncThunk(
  "actionAddMaterialConversion",
  async ({ values, form }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/material-conversion`,
        values
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        // toast.success(customMsg, 5);
        form && form.resetFields();
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// single material conversion
export const actionGetSingleMaterialConversion = createAsyncThunk(
  "actionGetSingleMaterialConversion",
  async ({ materialId, id }) => {
    return handleGetResponse(`material-conversion/${materialId}/${id}`);
  }
);

//update material conversion
export const actionUpdateMaterialConversion = createAsyncThunk(
  "actionUpdateMaterialConversion",
  async ({ materialConversionId, values, form, setMaterialConversionId }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/material-conversion/${materialConversionId}`,
        values
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form && form.resetFields();
        setMaterialConversionId && setMaterialConversionId(null);
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// delete material conversion
export const actionDeleteMaterialConversion = createAsyncThunk(
  "actionDeleteMaterialConversion",
  async ({ materialId, id }) => {
    return handleDeleteResponse(`material-conversion/${materialId}/${id}`, id);
  }
);
// Material Conversion end
