import {
  Col,
  ConfigProvider,
  Input,
  Layout,
  Menu,
  Row,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import usePermission from "../../hooks/use-permission";

const MasterData = () => {
  const { currentCompanyUser } = useSelector((state) => state.auth);

  const { hasPermission } = usePermission();

  const initialItems = [
    {
      label: (
        <h1 className="text-[15px] font-popinsMedium">
          Business Partner Master
        </h1>
      ),
      key: "business-partner-master",
      visible:
        hasPermission("address-type-master", "read") ||
        hasPermission("business-partner-type-master", "read") ||
        hasPermission("department-master", "read") ||
        hasPermission("industry-master", "read") ||
        hasPermission("type-of-company-master", "read"),
      path: "business-partner-master",
      children: [
        {
          label: (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="text-[15px]"
            >
              Address Types
            </Typography.Paragraph>
          ),
          key: "address-type",
          visible: hasPermission("address-type-master", "read"),
        },
        {
          label: (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="text-[15px]"
            >
              Business Partner types
            </Typography.Paragraph>
          ),
          key: "business-partner-type",
          visible: hasPermission("business-partner-type-master", "read"),
        },
        {
          label: (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="text-[15px]"
            >
              Departments
            </Typography.Paragraph>
          ),
          key: "department",
          visible: hasPermission("department-master", "read"),
        },
        {
          label: (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="text-[15px]"
            >
              Industries
            </Typography.Paragraph>
          ),
          key: "industry-master",
          visible: hasPermission("industry-master", "read"),
        },
        {
          label: (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="text-[15px]"
            >
             Company Types
            </Typography.Paragraph>
          ),
          key: "type-of-company",
          visible: hasPermission("type-of-company-master", "read"),
        },
      ],
    },
    {
      label: <h1 className="text-[15px] font-popinsMedium">Event Master</h1>,
      key: "event-masters",
      path: "event-masters",
      visible:
        hasPermission("event-attendee-status-master", "read") ||
        hasPermission("event-attendee-type-master", "read") ||
        hasPermission("event-category-master", "read") ||
        hasPermission("event-online-platforms-master", "read") ||
        hasPermission("event-product-master", "read") ||
        hasPermission("event-type-master", "read") ||
        hasPermission("event-exhibitor-category-master", "read"),
      children: [
        {
          label: (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="text-[15px]"
            >
              Attendee Statuses
            </Typography.Paragraph>
          ),
          key: "attendee-status",
          path: "attendee-status",
          visible: hasPermission("event-attendee-status-master", "read"),
        },
        {
          label: (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="text-[15px]"
            >
              Attendee Types
            </Typography.Paragraph>
          ),
          key: "attendee-type",
          path: "attendee-type",
          visible: hasPermission("event-attendee-type-master", "read"),
        },
        {
          label: (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="text-[15px]"
            >
              Event Categories
            </Typography.Paragraph>
          ),
          key: "event-category",
          path: "event-category",
          visible: hasPermission("event-category-master", "read"),
        },
        {
          label: (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="text-[15px]"
            >
              Event Online Platforms
            </Typography.Paragraph>
          ),
          key: "event-online-platforms",
          path: "event-online-platforms",
          visible: hasPermission("event-online-platforms-master", "read"),
        },
        {
          label: (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="text-[15px]"
            >
              Event Products
            </Typography.Paragraph>
          ),
          key: "event-products",
          path: "event-products",
          visible: hasPermission("event-product-master", "read"),
        },
        {
          label: (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="text-[15px]"
            >
              Event Types
            </Typography.Paragraph>
          ),
          key: "event-type",
          path: "event-type",
          visible: hasPermission("event-type-master", "read"),
        },
        {
          label: (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="text-[15px]"
            >
              Exhibitor Categories
            </Typography.Paragraph>
          ),
          key: "exhibitor-category",
          path: "exhibitor-category",
          visible: hasPermission("event-exhibitor-category-master", "read"),
        },
      ],
    },
    {
      label: <h1 className="text-[15px] font-popinsMedium">Finance Master</h1>,
      key: "finance-masters",
      path: "finance-masters",
      visible:
        hasPermission("currency-exchange", "read") ||
        hasPermission("deposit-master", "read") ||
        hasPermission("expense-category-type-master", "read") ||
        hasPermission("payment-method-master", "read"),
      children: [
        {
          label: (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="text-[15px]"
            >
              Currency Exchange
            </Typography.Paragraph>
          ),
          key: "currency-exchange",
          visible: hasPermission("currency-exchange", "read"),
        },
        {
          label: (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="text-[15px]"
            >
              Deposits
            </Typography.Paragraph>
          ),
          key: "deposit-master",
          visible: hasPermission("deposit-master", "read"),
        },
        {
          label: (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="text-[15px]"
            >
              Expense Categories
            </Typography.Paragraph>
          ),
          key: "expense-category",
          visible: hasPermission("expense-category-type-master", "read"),
        },
        {
          label: (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="text-[15px]"
            >
              Payment Methods
            </Typography.Paragraph>
          ),
          key: "payment-method",
          visible: hasPermission("payment-method-master", "read"),
        },
      ],
    },
    {
      label: <h1 className="text-[15px] font-popinsMedium">General Master</h1>,
      key: "general-master",
      path: "general-master",
      visible:
        hasPermission("opportunity-stage", "read") ||
        hasPermission("plant-code-master", "read") ||
        hasPermission("task-status-master", "read"),
      children: [
        {
          label: (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="text-[15px]"
            >
              Opportunity Stages
            </Typography.Paragraph>
          ),
          key: "opportunity-stage",
          visible: hasPermission("opportunity-stage", "read"),
        },
        {
          label: (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="text-[15px]"
            >
              Plant Codes
            </Typography.Paragraph>
          ),
          key: "plant-code",
          visible: hasPermission("plant-code-master", "read"),
        },
        {
          label: (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="text-[15px]"
            >
              Task Statuses
            </Typography.Paragraph>
          ),
          key: "task-status",
          visible: hasPermission("task-status-master", "read"),
        },
        // {
        //   label: (
        //     <Typography.Paragraph ellipsis={{ rows: 1 }} className="text-[15px]">
        //       Unit Conversion
        //     </Typography.Paragraph>
        //   ),
        //   key: "unit-conversion",
        // },
      ],
    },
    {
      label: <h1 className="text-[15px] font-popinsMedium">Lead Master</h1>,
      key: "lead-master",
      path: "lead-master",
      visible:
        hasPermission("lead-rating", "read") ||
        hasPermission("lead-source", "read") ||
        hasPermission("leads-status", "read"),

      children: [
        {
          label: (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="text-[15px]"
            >
              Lead Ratings
            </Typography.Paragraph>
          ),
          key: "lead-rating",
          visible: hasPermission("lead-rating", "read"),
        },
        {
          label: (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="text-[15px]"
            >
              Lead Sources
            </Typography.Paragraph>
          ),
          key: "lead-source",
          visible: hasPermission("lead-source", "read"),
        },
        {
          label: (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className=" text-[15px]"
            >
              Lead Statuses
            </Typography.Paragraph>
          ),
          key: "lead-status",
          visible: hasPermission("leads-status", "read"),
        },
      ],
    },
    {
      label: <h1 className="text-[15px] font-popinsMedium">Material Master</h1>,
      key: "material-master",
      path: "material-master",
      visible:
        hasPermission("hsn-code-master", "read") ||
        hasPermission("material-master", "read") ||
        hasPermission("product-category-master", "read") ||
        hasPermission("product-sub-category-master", "read") ||
        hasPermission("product-type-master", "read") ||
        hasPermission("unit-master", "read"),
      children: [
        {
          label: (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="text-[15px]"
            >
              HSN/SAC Codes
            </Typography.Paragraph>
          ),
          key: "hsn-sac-code",
          visible: hasPermission("hsn-code-master", "read"),
        },
        {
          label: (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="text-[15px]"
            >
              Materials
            </Typography.Paragraph>
          ),
          key: "material",
          visible: hasPermission("material-master", "read"),
        },

        {
          label: (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="text-[15px]"
            >
              Material Categories
            </Typography.Paragraph>
          ),
          key: "material-category",
          visible: hasPermission("product-category-master", "read"),
        },
        // {
        //   label: (
        //     <Typography.Paragraph ellipsis={{ rows: 1 }} className="text-[15px]">
        //       Material Group
        //     </Typography.Paragraph>
        //   ),
        //   key: "material-group",
        //   visible: hasPermission("material-group-master", "read"),
        // },
        {
          label: (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="text-[15px]"
            >
              Material Sub Categories
            </Typography.Paragraph>
          ),
          key: "material-sub-category",
          visible: hasPermission("product-sub-category-master", "read"),
        },
        {
          label: (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="text-[15px]"
            >
              Material Types
            </Typography.Paragraph>
          ),
          key: "material-type",
          visible: hasPermission("product-type-master", "read"),
        },
        {
          label: (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="text-[15px]"
            >
              Units
            </Typography.Paragraph>
          ),
          key: "unit",
          visible: hasPermission("unit-master", "read"),
        },
      ],
    },
    {
      label: <h1 className="text-[15px] font-popinsMedium">SFA Master</h1>,
      key: "sfa-master",
      path: "sfa-master",
      visible:
        hasPermission("area-master", "read") ||
        hasPermission("beat-grade-master", "read") ||
        hasPermission("beat-master", "read") ||
        hasPermission("zone-master", "read"),
      children: [
        {
          label: (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="text-[15px]"
            >
              Areas
            </Typography.Paragraph>
          ),
          key: "area",
          visible: hasPermission("area-master", "read"),
        },
        {
          label: (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="text-[15px]"
            >
              Beat Grades
            </Typography.Paragraph>
          ),
          key: "beat-grade",
          visible: hasPermission("beat-grade-master", "read"),
        },
        {
          label: (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="text-[15px]"
            >
              Beats
            </Typography.Paragraph>
          ),
          key: "beat-master",
          visible: hasPermission("beat-master", "read"),
        },
        {
          label: (
            <Typography.Paragraph
              ellipsis={{ rows: 1 }}
              className="text-[15px]"
            >
              Zones
            </Typography.Paragraph>
          ),
          key: "zone",
          visible: hasPermission("zone-master", "read"),
        },
      ],
    },
  ];

  // role permission

  const location = useLocation();
  const navigete = useNavigate();
  const [selectItem, setSelectItem] = useState("");
  const [items, setItems] = useState(initialItems);
  const [searchTerm, setSearchTerm] = useState("");
  const [openKeys, setOpenKeys] = useState([]); 

  useEffect(() => {
    if (searchTerm === "") {
      const visibleItems = initialItems.filter((item) => item.visible);
      setItems(visibleItems);
      setOpenKeys([]);
    } else {
      const filteredItems = initialItems
        .map((item) => {
          //
          const parentMatches =
            item.visible &&
            item.label.props.children
              .toLowerCase()
              .includes(searchTerm.toLowerCase());

          const filteredChildren = item.children.filter(
            (child) =>
              child.visible &&
              child.label.props.children
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
          );

          if (parentMatches) {
            return {
              ...item,
              children: item.children.filter((child) => child.visible),
            };
          }

          if (filteredChildren.length > 0) {
            return {
              ...item,
              children: filteredChildren,
            };
          }

          return null;
        })
        .filter((item) => item !== null);

      setItems(filteredItems);

      const matchingKeys = filteredItems.map((item) => item.key);
      setOpenKeys(matchingKeys);
    }
  }, [searchTerm]);

  useEffect(() => {
    const path = location.pathname.split("/").pop();

    if (path === "master-data" || !path) {
      const firstItem = initialItems.filter((item) => item.visible)[0];

      setSelectItem(firstItem.key);

      if (firstItem.children && firstItem.children.length > 0) {
        navigete(
          `/master-data/${firstItem.children.filter((item) => item.visible)[0].key
          }`
        );
      }
    } else {
      setSelectItem(path);
    }
  }, [location, navigete, items]);

  const handleClick = (item) => {
    navigete(`/master-data/${item.key}`);
    setSelectItem(item.key);
  };

  const moduleConnections = useMemo(
    () => ({
      eventType: ["event-type"],
      attendeeStatus: ["attendee-status"],
      attendeeType: ["attendee-type"],
      eventCategory: ["event-category"],
      eventOnlinePlatforms: ["event-online-platforms"],
      eventProducts: ["event-products"],
      exhibitorCategory: ["exhibitor-category"],

      leadStatus: ["lead-status", "lead-status-import"],
      leadSource: ["lead-source", "lead-source-import"],
      leadRating: ["lead-rating", "lead-rating-import"],
      industryMaster: ["industry-master", "industry-master-import"],
      opportunityStage: ["opportunity-stage", "opportunity-stage-import"],
      beatGrade: ["beat-grade", "beat-grade-import"],
      businessPartner: [
        "business-partner-type",
        "business-partner-type-import",
      ],
      beatMaster: ["beat-master", "beat-master-import"],
      department: ["department", "department-import"],
      typeOfCompany: ["type-of-company", "type-of-company-import"],
      zone: ["zone", "zone-import"],
      area: ["area", "area-import"],
      taskStatus: ["task-status", "task-status-import"],
      unitConversion: ["unit-conversion", "unit-conversion-import"],
      materialGroup: ["material-group", "material-group-import"],
      expenseCategory: ["expense-category", "expense-category-import"],
      paymentMethod: ["payment-method", "payment-method-import"],
      addressType: ["address-type", "address-type-import"],
      currencyExchange: ["currency-exchange", "currency-exchange-import"],
      depositeMaster: ["deposit-master", "deposit-master-import"],
      plantCode: ["plant-code", "plant-code-import"],
      material: ["material", "material-import"],
      materialType: ["material-type", "material-type-import"],
      hsnSacCode: ["hsn-sac-code", "hsn-sac-code-import"],
      materialCategory: ["material-category", "material-category-import"],
      materialSubCategory: [
        "material-sub-category",
        "material-sub-category-import",
      ],
      unit: ["unit", "unit-import"],
    }),
    []
  );

  const getSelectedKey = useCallback(() => {
    return (
      Object.entries(moduleConnections).find(([, values]) =>
        values.includes(selectItem)
      )?.[1][0] || ""
    );
  }, [selectItem, moduleConnections]);

  const getParentKey = (key) => {
    for (let item of items) {
      if (
        item.children &&
        item.children.some((subItem) => subItem.key === key)
      ) {
        return item.key;
      }
    }
    return null;
  };

  return (
    <div className="bg-white border border-full border-[#DDDAE9] h-full">
      <Row className="flex bg-white">
        <Col span={5} className="border-bColor border-r">
          <Layout className="inner-permission-layout">
            <h1 className="pl-[27px] text-[20px] font-popinsMedium text-brand pt-[14px]">
              Master Data
            </h1>
            <div className="flex items-center justify-center mt-4 mb-2">
              <Input
                className="w-11/12"
                value={searchTerm}
                placeholder="Search..."
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="master-menu">
              <Menu
                onSelect={({ key }) => setSelectItem(key)}
                className="pt-2 pb-4 event-master-menus"
                theme="light"
                mode="inline"
                
                defaultOpenKeys={
                  getSelectedKey() ? [getParentKey(getSelectedKey())] : []
                }
                openKeys={openKeys}
                onOpenChange={(keys) => setOpenKeys(keys)}
                selectedKeys={[getSelectedKey()]}
                items={items
                  .filter((item) => item.visible)
                  .map((item) => ({
                    key: item.key,
                    label: item.label,
                    children: item.children
                      ? item.children
                          .filter((child) => child.visible) 
                          .map((subItem) => ({
                            key: subItem.key,
                            label: subItem.label,
                            onClick: () => handleClick(subItem), 
                          }))
                      : [],
                  }))}
              />
                {/* {items
                  .filter((item) => item.visible)
                  .map((item) => (
                    <Menu.SubMenu
                      key={item.key}
                      title={item.label}
                      className="remove-bg"
                    >
                      {item.children &&
                        item.children
                          .filter((child) => child.visible)
                          .map((subItem) => (
                            <Menu.Item
                              key={subItem.key}
                              onClick={() => handleClick(subItem)}
                            >
                              {subItem.label}
                            </Menu.Item>
                          ))}
                    </Menu.SubMenu>
                  ))}
              </Menu> */}
            </div>
          </Layout>
        </Col>
        <Col span={19} className="px-[20px]">
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  cellPaddingBlock: 8,
                },
              },
            }}
          >
            <Outlet />
          </ConfigProvider>
        </Col>
      </Row>
    </div>
  );
};

export default MasterData;
