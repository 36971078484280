import {
  CaretRightOutlined,
  DeleteOutlined,
  SearchOutlined,
  EditOutlined,
  HighlightOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Descriptions,
  Divider,
  Dropdown,
  Form,
  Image,
  Input,
  Result,
  Row,
  Skeleton,
  Space,
  Table,
  Tag,
  Timeline,
  Typography,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { SlSocialTwitter } from "react-icons/sl";
import { FiFacebook } from "react-icons/fi";
import dayjs from "dayjs";
import { FaInstagram } from "react-icons/fa6";
import { LiaYoutube } from "react-icons/lia";
import { HiLink } from "react-icons/hi2";
import { VscMail } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import avnOrderingTime from "../../../assets/img/avnOrderingTime.svg";
import backarrow from "../../../assets/img/backarrow.svg";
import downloadIcon from "../../../assets/img/download.svg";
import noteHistory from "../../../assets/img/noteHistory.svg";
import notesAttechment from "../../../assets/img/notesAttechment.svg";
import { FaMapMarkerAlt } from "react-icons/fa";
import notesProfile from "../../../assets/img/notesProfile.svg";
import targetHistory from "../../../assets/img/targetHistory.svg";
import threedot from "../../../assets/img/threedot.svg";
import totalAmount from "../../../assets/img/totalAmount.svg";
import totalDuo from "../../../assets/img/totalDuo.svg";
import totalOrder from "../../../assets/img/totalOrder.svg";
import { PiInstagramLogoLight } from "react-icons/pi";
import { PiTwitterLogoLight } from "react-icons/pi";
import viewbusinesspartner from "../../../assets/img/viewbusinesspartner.svg";
import { actionDownloadFile } from "../../../store/services/commonService";
import {
  actionGetSingleBusinessPartner,
  actionGetSalesOrderByBusinessPartner,
  actionGetInvoiceByBusinessPartner,
} from "../../../store/services/masterDataService";
import { extractFilename } from "../../helper/common-function";
import SkeletonTable from "../../common/SkeletonTable";

const ViewBusinessPartner = (backArrow) => {
  const locationImg = "path/to/locationImg.png";
  const { RangePicker } = DatePicker;
  const [activeTab, setActiveTab] = useState("1");
  const [search, setSearch] = useState("");
  const location = useLocation();
  const dateFormat = "YYYY/MM";
  const { id } = location.state || {};
  const tabsRef = useRef({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [type, setType] = useState("VIEW");
  const [form] = Form.useForm();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const [selectedTab, setSelectedTab] = useState("Pending");
  const [mode, setMode] = useState("left");
  const [isEditing, setIsEditing] = useState(undefined);

  const { Text } = Typography;
  const [links, setLinks] = useState([
    {
      id: 1,
      icon: <PiInstagramLogoLight color="#7C7C7C" />,
      url: "instagram@example.com",
    },
    {
      id: 2,
      icon: <PiTwitterLogoLight color="#7C7C7C" />,
      url: "twitter@example.com",
    },
    {
      id: 3,
      icon: <FiFacebook color="#7C7C7C" />,
      url: "facebook@example.com",
    },
    { id: 4, icon: <LiaYoutube color="#7C7C7C" />, url: "youtube@example.com" },
    { id: 5, icon: <HiLink color="#7C7C7C" />, url: "link@example.com" },
    { id: 6, icon: <VscMail color="#7C7C7C" />, url: "mail@example.com" },
  ]);

  const handleDeleteClick = (id) => {
    setLinks(links.filter((link) => link.id !== id));
  };

  const handleSave = (id, text) => {
    if (text && text.trim() !== "") {
      setLinks(
        links.map((link) => (link.id === id ? { ...link, url: text } : link))
      );
      setIsEditing(undefined);
    }
  };

  // const tabs = ["All Orders", "Pending", "Completed", "Partially Invoiced"];

  const {
    getBusinessPartnerDetailLoader,
    businessPartnerDetail,
    salesOrderByBusinessPartnerDetail,
    invoiceByBusinessPartnerDetail,
    getSalesOrderByBusinessPartnerLoader,
  } = useSelector((state) => state.masterData);

  useEffect(() => {
    if (id) {
      dispatch(actionGetSingleBusinessPartner(id));
    }
  }, [id, dispatch]);

  const handleChangeTab = (key) => {
    setActiveTab(key);
  };

  const {
    business_partner_id,
    business_partner_name,
    email,
    phone,
    mobile,
    address_1,
    address_2,
    logo,
    city,
    beat_master,
    material_categories,
    zip_code,
    gst_number,
    pan_card_no,
    erp_code,
    msme_number,
    fssai,
    cin_no,
    type_of_company,
    company_size,
    revenue,
    sales_volume,
    agreement_duration,
    commission_structure,
    terms_and_conditions,
    feedback_mechanism,
    renewal_process,
    termination_conditions,
    bank_name,
    branch_name,
    account_type,
    ifsc,
    account_number,
    gstn_certificate_attachment,
    pan_card_attachment,
    cancelled_cheque_attachment,
    msme_details_attachment,
    contract_attachements,
    other_certificates_attachments,
    primary_contact_name,
    primary_contact_email,
    primary_contact_phone,
    secondary_contact_name,
    secondary_contact_email,
    secondary_contact_phone,
    created_at,
    updated_at,
    created_by,
    industry,
    business_partner_type,
    country,
    state,
  } = businessPartnerDetail || {};

  const items = [
    { key: "1", label: "Overview", targetId: "overview" },
    // { key: "2", label: "Notes", targetId: "notes" },
    { key: "3", label: "Attachment", targetId: "attachment" },
    { key: "4", label: "Sales Orders", targetId: "sales-orders" },
    { key: "5", label: "Invoices", targetId: "invoices" },
    // { key: "6", label: "Social", targetId: "social" },
    // { key: "7", label: "Activity", targetId: "activity" },
  ];
  const handleChangeScroll = (key) => {
    setActiveTab(key);
    const targetItem = items.find((item) => item.key === key);
    const targetElement = document.getElementById(targetItem.targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const notes = [
    {
      text: "This is test note",
      contactName: "Sage Wieser",
      userName: "John M. Freeman",
      ellipsis: true,
      attachment: null,
    },
    {
      text: "This is Second note",
      contactName:
        "Sage Wieserrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr",
      userName: "John M. Freeman",
      attachment: null,
    },
    {
      text: "Note with attachment",
      contactName: "Sage Wieser",
      ellipsis: true,
      userName: "John M. Freeman",
      attachment: { notesAttechment },
    },
  ];

  const companyDetails = [
    {
      key: "19",
      label: "ERP Code",
      children: <p>{erp_code ?? "-"}</p>,
    },
    {
      key: "9",
      label: "GST Number",
      children: <p>{gst_number ?? "-"}</p>,
    },
    // {
    //   key: "10",
    //   label: "PAN Number",
    //   children: <p>{pan_card_no ?? "-"}</p>,
    // },
    {
      key: "11",
      label: "MSME Number",
      children: <p>{msme_number ?? "-"}</p>,
    },
    {
      key: "12",
      label: "FSSAI",
      children: <p>{fssai ?? "-"}</p>,
    },

    {
      key: "14",
      label: "Company Type",
      children: <p>{type_of_company?.company_type ?? "-"}</p>,
    },
    {
      key: "15",
      label: "Industry",
      children: <p>{industry?.industry ?? "-"}</p>,
    },
    {
      key: "16",
      label: "Company Size",
      children: <p>{company_size ?? "-"}</p>,
    },
    {
      key: "17",
      label: "Revenue",
      children: <p>{revenue ?? "-"}</p>,
    },
    {
      key: "18",
      label: "Sales Volume",
      children: <p>{sales_volume ?? "-"}</p>,
    },
  ];

  const agreementDetails = [
    {
      key: "3",
      label: "Agreement Duration",
      children: <p>{agreement_duration ?? "-"}</p>,
    },
    {
      key: "4",
      label: "Commission Structure",
      children: <p>{commission_structure ?? "-"}</p>,
    },
    {
      key: "5",
      label: "Terms and Conditions",
      children: <p>{terms_and_conditions ?? "-"}</p>,
    },
    {
      key: "6",
      label: "Feedback Mechanism",
      children: <p>{feedback_mechanism ?? "-"}</p>,
    },
    {
      key: "7",
      label: "Renewal Process",
      children: <p>{renewal_process ?? "-"}</p>,
    },
    {
      key: "8",
      label: "Termination Conditions",
      children: <p>{termination_conditions ?? "-"}</p>,
    },
  ];

  const bankDetails = [
    {
      key: "1",
      label: "Bank Name",
      children: <p>{bank_name ?? "-"}</p>,
    },
    {
      key: "2",
      label: "Branch Name",
      children: <p>{branch_name ?? "-"}</p>,
    },
    {
      key: "3",
      label: "Account Type",
      children: <p>{account_type ?? "-"}</p>,
    },
    {
      key: "4",
      label: "IFSC",
      children: <p>{ifsc ?? "-"}</p>,
    },
    {
      key: "5",
      label: "Account Number",
      children: <p>{account_number ?? "-"}</p>,
    },
  ];

  const contactDetails = [
    {
      key: "1",
      label: "Primary Contact Name",
      children: <p>{primary_contact_name ?? "-"}</p>,
    },
    {
      key: "2",
      label: "Primary Contact Email",
      children: <p>{primary_contact_email ?? "-"}</p>,
    },
    {
      key: "3",
      label: "Primary Contact Phone",
      children: <p>{primary_contact_phone ?? "-"}</p>,
    },

    {
      key: "5",
      label: "Secondary Contact Name",
      children: <p>{secondary_contact_name ?? "-"}</p>,
    },
    {
      key: "6",
      label: "Secondary Contact Email",
      children: <p>{secondary_contact_email ?? "-"}</p>,
    },
    {
      key: "7",
      label: "Secondary Contact Phone",
      children: <p>{secondary_contact_phone ?? "-"}</p>,
    },
  ];

  if (!id) {
    return (
      <Result status="404" title="404" subTitle="Business Partner Not Found!" />
    );
  }

  const docsColumns = [
    {
      title: "Document",
      dataIndex: "document",
      key: "document",
    },
    {
      title: "file name",
      dataIndex: "url",
      key: "url",
      render: (text) => (text ? extractFilename(text) : "-"),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return record?.url ? (
          <Space size="middle">
            <img
              onClick={() => dispatch(actionDownloadFile(record?.url))}
              className="cursor-pointer"
              src={downloadIcon}
              alt="download"
            />
          </Space>
        ) : (
          "-"
        );
      },
    },
  ];

  const otherDocsColumns = [
    {
      title: "File Name",
      dataIndex: "",
      key: "1",
      render: (text) => (text ? extractFilename(text) : "-"),
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return record ? (
          <Space size="middle">
            <img
              onClick={() => dispatch(actionDownloadFile(record))}
              className="cursor-pointer"
              src={downloadIcon}
              alt="download"
            />
          </Space>
        ) : (
          "-"
        );
      },
    },
  ];

  const contactDocsColumns = [
    {
      title: "File Name",
      dataIndex: "",
      key: "1",
      render: (text) => (text ? extractFilename(text) : "-"),
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return record ? (
          <Space size="middle">
            <img
              onClick={() => dispatch(actionDownloadFile(record))}
              className="cursor-pointer"
              src={downloadIcon}
              alt="download"
            />
          </Space>
        ) : (
          "-"
        );
      },
    },
  ];

  const docsData = [
    {
      key: "1",
      url: gstn_certificate_attachment
        ? gstn_certificate_attachment
        : undefined,
      document: "GSTN Certificate",
    },
    {
      key: "2",
      url: pan_card_attachment ? pan_card_attachment : undefined,
      document: "PAN Card",
    },
    {
      key: "3",
      url: cancelled_cheque_attachment
        ? cancelled_cheque_attachment
        : undefined,
      document: "Cancelled Cheque",
    },
    {
      key: "4",
      url: msme_details_attachment ? msme_details_attachment : undefined,
      document: "MSME Details",
    },
  ];

  const getStatusClass = (status) => {
    switch (status) {
      case "PENDING":
        return "border-[#C69619] text-[#C69619]";
      case "DONE":
        return "border-[#15A02B] text-[#15A02B]";
      case "FAILED":
        return "border-[#DD1B1B] text-[#DD1B1B]";
      default:
        return "border-[#7C7C7C] text-[#7C7C7C]";
    }
  };

  const getPaymentStatusClass = (status) => {
    switch (status) {
      case "Collected":
        return "text-orange-800";
      case "Partially Collected":
        return "text-green-800";
      case "Pending":
        return "text-red-800";
      default:
        return "text-gray-800";
    }
  };

  const salesOrderColumns = [
    {
      title: "SO Number",
      dataIndex: "sales_order_number",
      key: "sales_order_number",
      width: 200,
      fixed: "left",
      render: (text, data) => (
        <span className="ml-2">
          {data.sales_order_number ? data.sales_order_number : "-"}
        </span>
      ),
    },
    {
      title: "Business Partner / Customer",
      dataIndex: ["customer", "business_partner_name"],
      key: "customer",
      ellipsis: true,
      width: 200,
      render: (text, data) =>
        data.customer && data.customer?.business_partner_name
          ? data.customer?.business_partner_name
          : "-",
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      width: 150,
      render: (text) => dayjs(text).format("DD-MM-YYYY"),
    },
    {
      title: "Ship to Party",
      dataIndex: ["ship_to_party", "business_partner_name"],
      key: "ship_to_party",
      ellipsis: true,
      width: 250,

      render: (text, data) =>
        data.ship_to_party && data.ship_to_party?.business_partner_name
          ? data.ship_to_party?.business_partner_name
          : "-",
    },
    {
      title: "Plant Code",
      dataIndex: ["plant_code", "plant_code"],
      key: "ship_to_party",
      ellipsis: true,
      width: 250,
      render: (text, data) =>
        data.plant_code && data.plant_code?.plant_code
          ? data.plant_code?.plant_code
          : "-",
    },
    {
      title: "SAP Sales Order Number",
      dataIndex: "sap_sales_order_number",
      key: "sap_sales_order_number",
      ellipsis: true,
      width: 250,
      /*   sorter: true,
      sortIcon: () => <SortIcon />, */
      render: (text, data) => (
        <span className="ml-2">
          {data.sap_sales_order_number ? data.sap_sales_order_number : "-"}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,

      render: (text) => (
        <span
          className={`${getStatusClass(
            text
          )} border-[0.5px] py-1 rounded-[5px] px-2.5 text-sm`}
        >
          {text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()}
        </span>
      ),
    },
    {
      title: "SO Status",
      dataIndex: "so_status",
      key: "so_status",
      width: 150,
      render: (text) => (
        <span
          className={`${getStatusClass(
            text
          )} border-[0.5px] py-1 rounded-[5px] px-2.5 text-sm`}
        >
          {text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()}
        </span>
      ),
    },
  ];

  const invoiceColumns = [
    {
      title: "Invoice Number",
      dataIndex: "invoice_number",
      key: "invoice_number",
      width: 200,
      fixed: "left",

      render: (text, data) => (
        <span className="ml-2">
          {data.invoice_number ? data.invoice_number : "-"}
        </span>
      ),
    },
    {
      title: "Invoice Date",
      dataIndex: "invoice_date",
      key: "invoice_date",
      width: 150,
      render: (text) => (text ? dayjs(text).format("DD-MM-YYYY") : "-"),
    },
    {
      title: "Customer",
      dataIndex: ["customer", "business_partner_name"],
      key: "customer",
      ellipsis: true,
      width: 250,
      render: (text, data) =>
        data.customer && data.customer?.business_partner_name
          ? data.customer?.business_partner_name
          : "-",
    },
    {
      title: "Ship To Party",
      dataIndex: ["ship_to_party", "business_partner_name"],
      key: "ship_to_party",
      ellipsis: true,
      width: 250,
      render: (text, data) =>
        data.ship_to_party && data.ship_to_party?.business_partner_name
          ? data.ship_to_party?.business_partner_name
          : "-",
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      ellipsis: true,
      width: 250,
      render: (text, data) => (
        <span className="ml-2">{data.note ? data.note : "-"}</span>
      ),
    },
    {
      title: "IRN Number",
      dataIndex: "irn_number",
      key: "irn_number",
      ellipsis: true,
      width: 200,
      render: (text, data) => (
        <span className="ml-2">{data.irn_number ? data.irn_number : "-"}</span>
      ),
    },
    {
      title: "Eway Bill Number",
      dataIndex: "eway_bill_number",
      key: "eway_bill_number",
      ellipsis: true,
      width: 250,
      render: (text, data) => (
        <span className="ml-2">
          {data.eway_bill_number ? data.eway_bill_number : "-"}
        </span>
      ),
    },
    {
      title: "Invoice File Name",
      dataIndex: "invoice_file_name",
      key: "invoice_file_name",
      ellipsis: true,
      width: 250,
      render: (file) => {
        if (!file || !file.url) return "-";

        const isImage = file.mimetype.startsWith("image");
        const isDocument = [
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ].includes(file.mimetype);

        if (isImage) {
          return (
            <Image
              preview={{ mask: <EyeOutlined /> }}
              className="cursor-pointer"
              src={file.url}
              alt={file.originalname}
              style={{ width: 50, height: 50, objectFit: "contain" }}
            />
          );
        } else if (isDocument) {
          return (
            <a
              href={file.url}
              className="text-blue-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              {file.originalname}
            </a>
          );
        } else {
          return file.originalname;
        }
      },
    },
    {
      title: "Total Base Value",
      dataIndex: "total_base_value",
      key: "total_base_value",
      width: 200,
      render: (text, data) => (
        <span className="ml-2">
          {data.total_base_value ? data.total_base_value : "-"}
        </span>
      ),
    },
    {
      title: "Total Discount",
      dataIndex: "total_discount",
      key: "total_discount",
      width: 150,
      render: (text, data) => (
        <span className="ml-2">
          {data.total_discount ? data.total_discount : "-"}
        </span>
      ),
    },
    {
      title: "Total Other Charges",
      dataIndex: "total_other_charges",
      key: "total_other_charges",
      width: 200,
      render: (text, data) => (
        <span className="ml-2">
          {data.total_other_charges ? data.total_other_charges : "-"}
        </span>
      ),
    },
    {
      title: "Total CGST",
      dataIndex: "total_cgst",
      key: "total_cgst",
      width: 200,
      render: (text, data) => (
        <span className="ml-2">{data.total_cgst ? data.total_cgst : "-"}</span>
      ),
    },
    {
      title: "Total SGST",
      dataIndex: "total_sgst",
      key: "total_sgst",
      width: 200,
      render: (text, data) => (
        <span className="ml-2">{data.total_sgst ? data.total_sgst : "-"}</span>
      ),
    },
    {
      title: "Total UTGST",
      dataIndex: "total_utgst",
      key: "total_utgst",
      width: 200,
      render: (text, data) => (
        <span className="ml-2">
          {data.total_utgst ? data.total_utgst : "-"}
        </span>
      ),
    },
    {
      title: "Total Net Value",
      dataIndex: "total_net_value",
      key: "total_net_value",
      width: 200,
      render: (text, data) => (
        <span className="ml-2">
          {data.total_net_value ? data.total_net_value : "-"}
        </span>
      ),
    },
    {
      title: "Total Value",
      dataIndex: "total_value",
      key: "total_value",
      width: 200,
      render: (text, data) => (
        <span className="ml-2">
          {data.total_value ? data.total_value : "-"}
        </span>
      ),
    },
    {
      title: "TCS Value",
      dataIndex: "tcs_value",
      key: "tcs_value",
      width: 150,
      render: (text, data) => (
        <span className="ml-2">{data.tcs_value ? data.tcs_value : "-"}</span>
      ),
    },
    {
      title: "Round Off",
      dataIndex: "round_off",
      key: "round_off",
      width: 150,
      render: (text, data) => (
        <span className="ml-2">{data.round_off ? data.round_off : "-"}</span>
      ),
    },
  ];

  const invoiceStatuses = [
    "Pending",
    "Completed",
    "Cancelled",
    "Partially Invoiced",
  ];
  const paymentStatuses = ["Collected", "Partially Collected", "Pending"];
  const erpInvoiceNos = [
    "Pending for ASM Approval",
    "Pending for RSM Approval",
    "Pending for NSM Approval",
    "Pending for ZSM Approval",
  ];

  const latitude = businessPartnerDetail?.latitude;
  const longitude = businessPartnerDetail?.longitude;

  const handleNavigate = () => {
    if (latitude && longitude) {
      const googleMapsUrl = `https://www.google.com/maps?q=${encodeURIComponent(
        latitude
      )},${encodeURIComponent(longitude)}`;
      window.open(googleMapsUrl, "_blank");
    } else {
      console.error("Latitude or Longitude not available");
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(actionGetSalesOrderByBusinessPartner(id));
    }
  }, [id, dispatch]);

  const handleSalesOrder = () => {
    navigate(`/sales-orders?businessPartnerId=${id}`);
  };

  useEffect(() => {
    if (id) {
      dispatch(actionGetInvoiceByBusinessPartner(id));
    }
  }, [id, dispatch]);

  const handleInvoice = () => {
    navigate("/sales-invoices");
  };

  return (
    <>
      <div className="px-6 h-[70px] justify-between sticky z-50 overflow-x-auto top-0 border-b border-bColor flex items-center bg-white">
        <div className="flex flex-col space-y-2 justify-start items-start w-[35%]">
          <div className="flex">
            <img src={logo} className="rounded-full size-10"></img>
            <div className="pl-3">
              <h1 className="text-[17px] font-popinsMedium text-[#2F2F2F]">
                {business_partner_name}
              </h1>
              {email && <p className="text-[12px] text-[#7C7C7C]">{email}</p>}
            </div>
          </div>
        </div>

        <div className="custom-tabs remove-scrollbar w-[65%]">
          {items.map((item) => (
            <div
              key={item.key}
              className={`tab-item flex items-center justify-center ${
                activeTab === item.key ? "active" : ""
              }`}
              onClick={() => handleChangeScroll(item.key)}
            >
              {item.label}
            </div>
          ))}
        </div>
      </div>

      <section className="main-section px-4 custom-color" id="overview">
        <div className="flex items-center gap-x-3 justify-between">
          {backArrow && (
            <div
              className="flex items-center gap-x-3 cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <div className="p-[19px] rounded-[10px] flex items-center border border-[#7C7C7C]/15 bg-white mt-3">
                <Image
                  src={backarrow}
                  alt="image"
                  height={15}
                  width={20}
                  preview={false}
                />
              </div>
              <h1 className="lg:text-lg text-base mt-[10px] font-popinsMedium">
                Back
              </h1>
            </div>
          )}
          {/* 
          <div className="flex">
            <div className="mt-3 mr-2">
              <Input
                className="py-[8px] rounded-[10px]"
                placeholder="Search"
                value={search}
                // onChange={handleSearch}
                // onBlur={handleBlurAndPressEnter}
                // onPressEnter={handleBlurAndPressEnter}
                prefix={<SearchOutlined className="text-[#7C7C7C]" />}
              />
            </div>

            <div className="mt-3">
              <RangePicker
                className="custom-color"
                defaultValue={[
                  dayjs("2015/01", dateFormat),
                  dayjs("2015/12", dateFormat),
                ]}
                format={dateFormat}
                picker="month"
                allowClear
              />
            </div>

            <div className="mt-3">
              <Dropdown menu={{ items }} placement="bottom">
                <Button>
                  <img src={threedot}></img>
                </Button>
              </Dropdown>
            </div>
          </div> */}
        </div>
        <div className="flex border border-[#DDDAE9] rounded-lg my-5 bg-white items-center justify-between">
          <div className="flex-1 flex justify-between px-8 py-5 items-center">
            <div className="flex flex-col gap-y-1 text-center">
              <p className="text-base font-popinsMedium text-black">
                Total Orders
              </p>
              <p className="text-[20px] font-popinsSemiBold  text-black text-start">
                {"16"}
              </p>
            </div>
            <img src={totalOrder} alt="Attendees Icon" className="w-8 h-8" />
          </div>
          <div className="border-l border-[#DDDAE9] h-12"></div>
          <div className="flex-1 flex justify-between px-8 py-5 items-center">
            <div className="flex flex-col gap-y-1 text-center">
              <p className="text-base font-popinsMedium text-black text-align: start">
                Total Amount
              </p>
              <p className="text-[20px] font-popinsSemiBold text-black text-start">
                {"60"}
              </p>
            </div>
            <img src={totalAmount} alt="Exhibitors Icon" className="w-8 h-8" />
          </div>
          <div className="border-l border-[#DDDAE9] h-12"></div>
          <div className="flex-1 flex justify-between px-8 py-5 items-center">
            <div className="flex flex-col gap-y-1 text-center">
              <p className="text-base font-popinsMedium text-black">
                Total Duo
              </p>
              <p className="text-[20px] font-popinsSemiBold text-black text-start">
                {"15"}
              </p>
            </div>
            <img src={totalDuo} alt="Sessions Icon" className="w-8 h-8" />
          </div>
          <div className="border-l border-[#DDDAE9] h-12"></div> {/* Divider */}
          <div className="flex-1 flex justify-between px-8 py-5 items-center">
            <div className="flex flex-col gap-y-1 text-center">
              <p className="text-base font-popinsMedium text-black">
                Avg Ordering Time
              </p>
              <p className="text-[20px] font-popinsSemiBold text-black text-start">
                {"5 Days"}
              </p>
            </div>
            <img
              src={avnOrderingTime}
              alt="Speakers Icon"
              className="w-8 h-8"
            />
          </div>
        </div>

        <Card>
          <Row className="flex" align="middle">
            <Col span={9} className="border-r border-[#DDDAE9]">
              <div className="flex items-center gap-x-5 my-4">
                {getBusinessPartnerDetailLoader ? (
                  <Skeleton.Avatar active size={100} shape="square" />
                ) : (
                  <Image
                    fallback="data:image/png;base64,..."
                    src={logo}
                    className="rounded-md"
                    alt="logo"
                    width={100}
                    preview={false}
                    height={100}
                  />
                )}

                {getBusinessPartnerDetailLoader ? (
                  <div style={{ width: "100%" }}>
                    <Skeleton
                      active
                      title={{ width: 150 }}
                      paragraph={{ rows: 2, width: ["100%", "80%"] }}
                    />
                  </div>
                ) : (
                  <div className="flex flex-col gap-y-1">
                    <div className="flex items-center gap-x-3">
                      <p className="font-popinsSemiBold text-[23px]">
                        {business_partner_name ?? "-"}
                      </p>
                      <Tag color="blue">
                        {business_partner_type?.business_partner_type_name ??
                          "-"}
                      </Tag>
                    </div>

                    <p className="font-popinsSemiBold text-[13px] mt-2">
                      Email:
                      <span className="text-[#7C7C7C]"> {email ?? "-"}</span>
                    </p>
                    <p className="font-popinsSemiBold text-[13px] mt-2">
                      Mobile:
                      <span className="text-[#7C7C7C]"> {mobile ?? "-"}</span>
                    </p>
                  </div>
                )}
              </div>
            </Col>

            <Col span={15} className="pl-8 flex justify-between">
              {getBusinessPartnerDetailLoader ? (
                <div style={{ width: "100%" }}>
                  <Skeleton
                    active
                    // title={{ width: 150 }}
                    paragraph={{ rows: 3, width: ["100%", "80%"] }}
                  />
                </div>
              ) : (
                <>
                  <div>
                    {/* Address */}
                    <p className="font-popinsSemiBold text-[13px] mt-2">
                      Address:
                      <span className="text-[#7C7C7C]">
                        {" "}
                        {address_1 || "-"}
                      </span>
                    </p>

                    {/* Beat */}
                    <p className="font-popinsSemiBold text-[13px] mt-2">
                      Beat:
                      <span className="text-[#7C7C7C]">
                        {" "}
                        {beat_master?.beat_name || "-"}
                      </span>
                    </p>
                    <p className="font-popinsSemiBold text-[13px] mt-2">
                      Material Access:
                      <span className="text-[#7C7C7C] ml-2">
                        {material_categories?.length > 0
                          ? material_categories
                              .map(
                                (category) =>
                                  category.material_category.category_name
                              )
                              .join(", ")
                          : "-"}
                      </span>
                    </p>

                    {/* PIN Code */}
                    <p className="font-popinsSemiBold text-[13px] mt-2">
                      PIN Code:
                      <span className="text-[#7C7C7C]"> {zip_code || "-"}</span>
                    </p>

                    {/* PAN and CIN Numbers */}
                    <div className="flex">
                      <p className="font-popinsSemiBold text-[13px] mt-2">
                        PAN Number:
                        <span className="text-[#7C7C7C]">
                          {" "}
                          {pan_card_no || "-"}
                        </span>
                      </p>
                      <p className="font-popinsSemiBold text-[13px] mt-2 ml-28">
                        CIN/UIN Number:
                        <span className="text-[#7C7C7C]"> {cin_no || "-"}</span>
                      </p>
                    </div>

                    {/* Map Button */}
                  </div>
                  <span
                    className="text-brand inline-flex h-7 items-center text-sm cursor-pointer"
                    onClick={handleNavigate}
                    disabled={!latitude || !longitude}
                  >
                    <FaMapMarkerAlt className="mr-1" /> View on Map
                  </span>
                </>
              )}
            </Col>
          </Row>
        </Card>
        <>
          <div className="w-full">
            <div id="notes" className="mt-4">
              <Card
                loading={getBusinessPartnerDetailLoader}
                className="main-card mt-5 w-full border-[#DDDAE9] p-2"
              >
                <Collapse
                  loading={getBusinessPartnerDetailLoader}
                  className="company-detail-collapse"
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined
                      style={{ color: "#000000" }}
                      rotate={isActive ? 90 : 0}
                    />
                  )}
                  expandIconPosition="end"
                  defaultActiveKey={[]}
                  items={[
                    {
                      key: "1",
                      label: (
                        <p
                          style={{
                            color: "#000000",
                            fontWeight: 600,
                            letterSpacing: 0.5,
                          }}
                        >
                          Multiple Address
                        </p>
                      ),
                      children: (
                        <Collapse
                          className="company-detail-collapse custom-panel"
                          expandIcon={({ isActive }) => (
                            <CaretRightOutlined
                              style={{ color: "#000000" }}
                              rotate={isActive ? 90 : 0}
                            />
                          )}
                          defaultActiveKey={[]}
                          expandIconPosition="end"
                          bordered={false}
                        >
                          {businessPartnerDetail?.business_partner_addresses
                            ?.length > 0 ? (
                            businessPartnerDetail.business_partner_addresses.map(
                              (address, index) => (
                                <Collapse.Panel
                                  className={({ isActive }) =>
                                    `custom-panel ${
                                      isActive ? "bg-red-500" : "bg-green-500"
                                    }`
                                  }
                                  key={index + 1}
                                  header={
                                    <p
                                      style={{
                                        color: "#000000",
                                        fontWeight: 600,
                                        letterSpacing: 0.5,
                                        marginBottom: 0,
                                      }}
                                    >
                                      Company Address
                                    </p>
                                  }
                                  style={{
                                    marginBottom: "10px",
                                    border: "1px solid #EAEAEA",
                                    borderRadius: "4px",
                                  }}
                                >
                                  <div className="collapse-item">
                                    <div className="p-3">
                                      <Row gutter={[16, 16]}>
                                        <Col span={12}>
                                          <div className="mb-2">
                                            <p className="font-popinsMedium text-[15px] text-black">
                                              Address Line 1:
                                            </p>
                                            <span className="text-[#7C7C7C] font-monaSansRegular">
                                              {address.address_1 ?? "-"}
                                            </span>
                                          </div>
                                          <div className="mb-2">
                                            <p className="font-popinsMedium text-[15px] text-black">
                                              City:
                                            </p>
                                            <span className="text-[#7C7C7C] font-monaSansRegular">
                                              {address.city ?? "-"}
                                            </span>
                                          </div>
                                          <div className="mb-2">
                                            <p className="font-popinsMedium text-[15px] text-black">
                                              State:
                                            </p>
                                            <span className="text-[#7C7C7C] font-monaSansRegular">
                                              {address.state?.name ?? "-"}
                                            </span>
                                          </div>
                                          <div className="mb-2">
                                            <p className="font-popinsMedium text-[15px] text-black">
                                              Address Type:
                                            </p>
                                            <span className="text-[#7C7C7C] font-monaSansRegular">
                                              {address.address_type_master
                                                ?.address_type ?? "-"}
                                            </span>
                                          </div>
                                        </Col>
                                        <Col span={12}>
                                          <div className="mb-2">
                                            <p className="font-popinsMedium text-black text-[15px]">
                                              Address Line 2:
                                            </p>
                                            <span className="text-[#7C7C7C] font-monaSansRegular">
                                              {address.address_2 ?? "-"}
                                            </span>
                                          </div>
                                          <div className="mb-2">
                                            <p className="font-popinsMedium text-black text-[15px]">
                                              Pin Code:
                                            </p>
                                            <span className="text-[#7C7C7C] font-monaSansRegular">
                                              {address.zip_code ?? "-"}
                                            </span>
                                          </div>
                                          <div className="mb-2">
                                            <p className="font-popinsMedium text-black text-[15px]">
                                              Country:
                                            </p>
                                            <span className="text-[#7C7C7C] font-monaSansRegular">
                                              {address.country?.name ?? "-"}
                                            </span>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                </Collapse.Panel>
                              )
                            )
                          ) : (
                            <p>No Data Found</p>
                          )}
                        </Collapse>
                      ),
                    },
                  ]}
                />
              </Card>
            </div>

            {/* Notess Start here */}
            {/*  <div id="notes">
              <Card
                loading={getBusinessPartnerDetailLoader}
                className="main-card mt-5 w-full border-[#DDDAE9] p-2"
              > */}
            {/* <Collapse
              className="company-detail-collapse mt-5"
              expandIcon={({ isActive }) => (
                <CaretRightOutlined
                  style={{ color: "#000000" }}
                  rotate={isActive ? 90 : 0}
                />
              )}
              defaultActiveKey={["1"]}
              expandIconPosition="end"
              items={[
                {
                  key: "1",
                  label: (
                    <p
                      style={{
                        color: "#000000",
                        fontWeight: 600,
                        letterSpacing: 0.5,
                      }}
                    >
                      Note
                    </p>
                  ),
                  children: (
                    <div className="collapse-item">
                      <div className="p-4">
                        {notes.map((note, index) => (
                          <div key={index} className="mb-4">
                            <div className="flex items-center">
                              <Avatar
                                size={48}
                                // icon={<UserOutlined />}
                                className="mr-4"
                                src={notesProfile}
                              />
                              <div className="flex-grow">
                                <p className="text-base text-gray-800">
                                  {note.text}
                                </p>
                              </div>
                            </div>
                            {note.attachment && (
                              <div className="ml-16">
                                <Image
                                  preview={false}
                                  width={90}
                                  height={90}
                                  src={notesAttechment}
                                  alt="attachment"
                                  className="rounded-md"
                                />
                              </div>
                            )}
                            <p className="text-sm text-gray-600 mt-3 ml-16">
                              Contact:{" "}
                              <Typography.Text
                                ellipsis={{ tooltip: true }}
                                style={{ maxWidth: 150 }}
                                className="text-blue-600"
                              >
                                {note.contactName}
                              </Typography.Text>
                              • <span>{note.userName}</span>
                            </p>
                          </div>
                        ))}
                        <div className="mt-4">
                          <input
                            type="text"
                            placeholder="Add a Note"
                            className="w-[50%] px-4 py-3 border  border-[#DDDAE9] rounded-md focus:outline-none "
                          />
                        </div>
                      </div>
                    </div>
                  ),
                },
              ]}
            /> */}
            {/* </Card>
            </div> */}
            {/* <br /> */}

            {/* Document Attechment section start here  Start here */}
            <div id="attachment">
              <Card
                loading={getBusinessPartnerDetailLoader}
                className="main-card mt-5 w-full border-[#DDDAE9] p-2"
              >
                <Collapse
                  className="company-detail-collapse"
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined
                      style={{ color: "#000000" }}
                      rotate={isActive ? 90 : 0}
                    />
                  )}
                  // defaultActiveKey={["6"]}
                  defaultActiveKey={[]}
                  expandIconPosition="end"
                  items={[
                    {
                      key: "6",
                      label: (
                        <p
                          style={{
                            color: "#000000",
                            fontWeight: 600,
                            letterSpacing: 0.5,
                          }}
                        >
                          Documents / Attachments
                        </p>
                      ),
                      children: (
                        <Table
                          pagination={false}
                          columns={docsColumns}
                          dataSource={docsData}
                        />
                      ),
                    },
                  ]}
                />

                <br />
                <Row gutter={14}>
                  <Col span={12}>
                    <Collapse
                      className="company-detail-collapse"
                      expandIcon={({ isActive }) => (
                        <CaretRightOutlined
                          style={{ color: "#000000" }}
                          rotate={isActive ? 90 : 0}
                        />
                      )}
                      expandIconPosition="end"
                      defaultActiveKey={[]}
                      items={[
                        {
                          key: "7",
                          label: (
                            <p
                              style={{
                                color: "#000000",
                                fontWeight: 600,
                                letterSpacing: 0.5,
                              }}
                            >
                              Contact Attachments
                            </p>
                          ),
                          children: (
                            <Table
                              pagination={false}
                              columns={contactDocsColumns}
                              dataSource={contract_attachements}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>
                  <Col span={12}>
                    <Collapse
                      className="company-detail-collapse"
                      expandIcon={({ isActive }) => (
                        <CaretRightOutlined
                          style={{ color: "#000000" }}
                          rotate={isActive ? 90 : 0}
                        />
                      )}
                      defaultActiveKey={[]}
                      expandIconPosition="end"
                      items={[
                        {
                          key: "8",
                          label: (
                            <p
                              style={{
                                color: "#000000",
                                fontWeight: 600,
                                letterSpacing: 0.5,
                              }}
                            >
                              Other Certificates Attachments
                            </p>
                          ),
                          children: (
                            <Table
                              pagination={false}
                              columns={otherDocsColumns}
                              dataSource={other_certificates_attachments}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </Card>
            </div>

            {/* Sales oeder table start here  */}
            <div id="sales-orders">
              <div className="flex items-center justify-between py-4">
                <div className="flex">
                  <h2 className="text-lg font-semibold text-gray-800 pr-4 mr-4">
                    Sales Order
                  </h2>
                  {/* <div className="flex space-x-2">
                    {tabs.map((tab) => (
                      <button
                        key={tab}
                        className={`px-3 py-1 text-sm font-medium rounded ${
                          selectedTab === tab
                            ? "bg-blue-100 text-blue-700"
                            : "bg-white text-gray-700 border-[0.5px] border-[#DDDAE9]"
                        }`}
                        onClick={() => setSelectedTab(tab)}
                      >
                        {tab}
                      </button>
                    ))}
                  </div> */}
                </div>

                <div
                  className="px-3 py-1 text-sm font-medium rounded bg-white text-gray-700 border-[0.5px] border-[#DDDAE9] cursor-pointer"
                  onClick={handleSalesOrder}
                >
                  View All
                </div>
              </div>

              {getSalesOrderByBusinessPartnerLoader ? (
                <SkeletonTable columns={salesOrderColumns} length={10} />
              ) : (
                <div>
                  <Table
                    // rowSelection={rowSelection}
                    columns={salesOrderColumns}
                    pagination={false}
                    bordered
                    dataSource={salesOrderByBusinessPartnerDetail}
                    // virtual
                    scroll={{
                      x: "max-content",
                      y: 350,
                    }}
                  />
                </div>
              )}
            </div>

            {/* Invoices table start here */}
            <div id="invoices">
              <div className="flex items-center justify-between py-4 cursor-pointer">
                <h2
                  className="text-lg font-semibold text-gray-800 pr-4 mr-4 "
                  onClick={handleInvoice}
                >
                  Invoices
                </h2>
                <div
                  className="px-3 py-1 text-sm font-medium rounded bg-white text-gray-700 border-[0.5px] border-[#DDDAE9]"
                  onClick={() => {
                    navigate("/sales-invoices");
                  }}
                >
                  View All
                </div>
              </div>

              {invoiceByBusinessPartnerDetail ? (
                <SkeletonTable columns={invoiceColumns} />
              ) : (
                <div>
                  <Table
                    // rowSelection={rowSelection}
                    columns={invoiceColumns}
                    pagination={false}
                    bordered
                    dataSource={invoiceByBusinessPartnerDetail}
                    // virtual
                    scroll={{
                      x: "max-content",
                      y: 350,
                    }}
                  />
                </div>
              )}
            </div>

            {/* History and Social link section start here */}
            <div id="social">
              <div className="mt-5">
                <Row gutter={24}>
                  {/* <Col span={12}>
                    <Card>
                      <h2 className="text-lg font-semibold text-gray-800 ">
                        History
                      </h2>

                      <div className="flex  justify-start mt-7">
                        <Timeline
                          mode={mode}
                          lineHeight={50}
                          itemPaddingBottom="20px"
                          items={[
                            {
                              dot: (
                                <>
                                  <div className="px-[20px] py-[5px] bg-[#F1F0F6]  text-black font-medium rounded-[5px]">
                                    Mar 18, 2024
                                  </div>
                                  <Divider type="vertical" />
                                </>
                              ),
                              color: "gray",

                              dot: (
                                <div className=" w-full">
                                  <div className="border min-w-[100px] border-[#D8D8D8] text-xs text-[#7C7C7C] font-popinsMedium p-2 bg-white rounded-[5px]">
                                    Hello world
                                  </div>
                                </div>
                              ),
                            },
                            {
                              label: (
                                <p className="mr-4 text-[12px] ">05:18 PM</p>
                              ),
                              dot: (
                                <Image preview={false} src={targetHistory} />
                              ),
                              children: (
                                <p className=" text-[12px] ml-3">
                                  Tag added -{" "}
                                  <span className="font-popinsSemiBold">
                                    New client
                                  </span>{" "}
                                  by John M. Freeman Mar 18, 2024{" "}
                                </p>
                              ),
                            },
                            {
                              label: (
                                <p className="mr-4 text-[12px]">05:16 PM</p>
                              ),
                              dot: <Image preview={false} src={noteHistory} />,
                              children: (
                                <p className=" text-[12px] ml-3">
                                  Tag added - This is second test note by John
                                  M. Freeman Mar 18, 2024{" "}
                                </p>
                              ),
                            },
                            {
                              label: (
                                <p className="mr-4 text-[12px]">05:15 PM</p>
                              ),
                              dot: <Image preview={false} src={noteHistory} />,
                              children: (
                                <>
                                  <p className=" text-[12px] ml-3">
                                    Tag Updated - This is test note by John M.
                                    Freeman Mar 18, 2024
                                  </p>
                                </>
                              ),
                            },
                            {
                              dot: (
                                <>
                                  <div className="px-[20px] py-[5px] bg-[#F1F0F6] rounded-[5px] text-black font-medium">
                                    Mar 18, 2024
                                  </div>
                                  <Divider type="vertical" />
                                </>
                              ),
                            },
                            {
                              label: (
                                <p className="mr-4 text-[12px]">11:53 PM</p>
                              ),
                              dot: (
                                <Image preview={false} src={targetHistory} />
                              ),
                              children: (
                                <p className=" text-[12px] ml-3">
                                  Contact Image uploaded by John M. Freeman Mar
                                  12, 2024
                                </p>
                              ),
                            },
                          ]}
                        />
                      </div>
                    </Card>
                  </Col> */}

                  {/* <Col span={12}>
                    <Collapse
                      className="company-detail-collapse"
                      expandIcon={({ isActive }) => (
                        <CaretRightOutlined
                          style={{ color: "#000000" }}
                          rotate={isActive ? 90 : 0}
                        />
                      )}
                      defaultActiveKey={["6"]}
                      expandIconPosition="end"
                      items={[
                        {
                          key: "6",
                          label: (
                            <p
                              style={{
                                color: "#000000",
                                fontWeight: 600,
                                letterSpacing: 0.5,
                              }}
                            >
                              Social Links
                            </p>
                          ),
                          children: (
                            <div className="social-links-input social-custom-icon">
                              {links.map((link) => (
                                <div
                                  key={link.id}
                                  className="flex items-center justify-between  border input-box"
                                >
                                  <div className="flex items-center w-full ">
                                    <span className="px-2 custom-devider text-2xl">
                                      {link.icon}
                                    </span>
                                    <Divider type="vertical" className="mr-4" />
                                    <Text
                                      editable={{
                                        editing: isEditing === link.id,
                                        // editing:true,
                                        icon: (
                                          <HighlightOutlined className="hidden" />
                                        ),
                                        onChange: (text) =>
                                          handleSave(link.id, text),
                                        onEnd: (text) =>
                                          handleSave(link.id, text),
                                      }}
                                      className="w-full border-none"
                                    >
                                      {link.url}
                                    </Text>
                                  </div>
                                  <div className="flex items-center">
                                    <Divider type="vertical" />
                                    <Button
                                      type="text"
                                      icon={
                                        <EditOutlined
                                          style={{ color: "#7C7C7C" }}
                                        />
                                      }
                                      onClick={() => setIsEditing(link.id)}
                                    />
                                    <Divider type="vertical" />
                                    <Button
                                      type="text"
                                      icon={
                                        <DeleteOutlined
                                          style={{ color: "#FB2E2E" }}
                                        />
                                      }
                                      onClick={() => handleDeleteClick(link.id)}
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                          ),
                        },
                      ]}
                    />
                  </Col> */}
                </Row>
              </div>
            </div>

            <Card
              loading={getBusinessPartnerDetailLoader}
              className="main-card w-full border-[#DDDAE9] p-2"
            >
              <Collapse
                className="company-detail-collapse"
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined
                    style={{ color: "#000000" }}
                    rotate={isActive ? 90 : 0}
                  />
                )}
                defaultActiveKey={[]}
                expandIconPosition="end"
                items={[
                  {
                    key: "2",
                    label: (
                      <p
                        style={{
                          color: "#000000",
                          fontWeight: 600,
                          letterSpacing: 0.5,
                        }}
                      >
                        Company Identification and Compliance
                      </p>
                    ),
                    children: (
                      <div className="collapse-item">
                        <Descriptions
                          labelStyle={{
                            color: "black",
                            fontWeight: 600,
                            letterSpacing: 0.5,
                          }}
                          column={2}
                          items={companyDetails}
                        />
                      </div>
                    ),
                  },
                ]}
              />
              <br />

              <Collapse
                className="company-detail-collapse"
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined
                    style={{ color: "#000000" }}
                    rotate={isActive ? 90 : 0}
                  />
                )}
                defaultActiveKey={[]}
                expandIconPosition="end"
                items={[
                  {
                    key: "3",
                    label: (
                      <p
                        style={{
                          color: "#000000",
                          fontWeight: 600,
                          letterSpacing: 0.5,
                        }}
                      >
                        Agreement Details
                      </p>
                    ),
                    children: (
                      <div className="collapse-item">
                        <Descriptions
                          labelStyle={{
                            color: "black",
                            fontWeight: 600,
                            letterSpacing: 0.5,
                          }}
                          column={2}
                          items={agreementDetails}
                        />
                      </div>
                    ),
                  },
                ]}
              />
              <br />

              <Collapse
                className="company-detail-collapse"
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined
                    style={{ color: "#000000" }}
                    rotate={isActive ? 90 : 0}
                  />
                )}
                defaultActiveKey={[]}
                expandIconPosition="end"
                items={[
                  {
                    key: "4",
                    label: (
                      <p
                        style={{
                          color: "#000000",
                          fontWeight: 600,
                          letterSpacing: 0.5,
                        }}
                      >
                        Bank Details
                      </p>
                    ),
                    children: (
                      <div className="collapse-item">
                        <Descriptions
                          labelStyle={{
                            color: "black",
                            fontWeight: 600,
                            letterSpacing: 0.5,
                          }}
                          column={2}
                          items={bankDetails}
                        />
                      </div>
                    ),
                  },
                ]}
              />
              <br />
              <Collapse
                className="company-detail-collapse"
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined
                    style={{ color: "#000000" }}
                    rotate={isActive ? 90 : 0}
                  />
                )}
                defaultActiveKey={[]}
                expandIconPosition="end"
                items={[
                  {
                    key: "5",
                    label: (
                      <p
                        style={{
                          color: "#000000",
                          fontWeight: 600,
                          letterSpacing: 0.5,
                        }}
                      >
                        Contact Details
                      </p>
                    ),
                    children: (
                      <div className="collapse-item">
                        <Descriptions
                          labelStyle={{
                            color: "black",
                            fontWeight: 600,
                            letterSpacing: 0.5,
                          }}
                          column={2}
                          items={contactDetails}
                        />
                      </div>
                    ),
                  },
                ]}
              />
              <br />
              {/* <Collapse
                className="company-detail-collapse"
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined
                    style={{ color: "#6883FD" }}
                    rotate={isActive ? 90 : 0}
                  />
                )}
                defaultActiveKey={["6"]}
                expandIconPosition="end"
                items={[
                  {
                    key: "6",
                    label: (
                      <p
                        style={{
                          color: "#6883FD",
                          fontWeight: 600,
                          letterSpacing: 0.5,
                        }}
                      >
                        Documents / Attachments
                      </p>
                    ),
                    children: (
                      <Table
                        pagination={false}
                        columns={docsColumns}
                        dataSource={docsData}
                      />
                    ),
                  },
                ]}
              /> */}
              <br />
              {/* <Row gutter={14}>
                <Col span={12}>
                  <Collapse
                    className="company-detail-collapse"
                    expandIcon={({ isActive }) => (
                      <CaretRightOutlined
                        style={{ color: "#6883FD" }}
                        rotate={isActive ? 90 : 0}
                      />
                    )}
                    expandIconPosition="end"
                    defaultActiveKey={["7"]}
                    items={[
                      {
                        key: "7",
                        label: (
                          <p
                            style={{
                              color: "#6883FD",
                              fontWeight: 600,
                              letterSpacing: 0.5,
                            }}
                          >
                            Contact Attachments
                          </p>
                        ),
                        children: (
                          <Table
                            pagination={false}
                            columns={contactDocsColumns}
                            dataSource={contract_attachements}
                          />
                        ),
                      },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <Collapse
                    className="company-detail-collapse"
                    expandIcon={({ isActive }) => (
                      <CaretRightOutlined
                        style={{ color: "#6883FD" }}
                        rotate={isActive ? 90 : 0}
                      />
                    )}
                    defaultActiveKey={["8"]}
                    expandIconPosition="end"
                    items={[
                      {
                        key: "8",
                        label: (
                          <p
                            style={{
                              color: "#6883FD",
                              fontWeight: 600,
                              letterSpacing: 0.5,
                            }}
                          >
                            Other Certificates Attachments
                          </p>
                        ),
                        children: (
                          <Table
                            pagination={false}
                            columns={otherDocsColumns}
                            dataSource={other_certificates_attachments}
                          />
                        ),
                      },
                    ]}
                  />
                </Col>
              </Row> */}

              <br />
            </Card>
          </div>
        </>
      </section>
    </>
  );
};

export default ViewBusinessPartner;
