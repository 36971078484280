import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Dropdown,
  Image,
  Tag,
  Tooltip,
  Typography
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import feedBackMechanism from "../../assets/img/feedback-mechanism.svg";
import PageTitle from "../common/PageTitle";
// import SortIcon from "../../common/SortIcon";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  // actionClearAllRequestForQuotation,
  actionDeleteFeedBackMechanism,
  actionGetFeedBackMechanism,
  actionGetSingleFeedBackMechanism,
  actionUpdateStatus,
} from "../../store/services/feedBackMechanismService";
import { handleActionWithPermission } from "../../store/slices/authSlice";
import CommonPagination from "../common/CommonPagination";
import CommonTable from "../common/CommonTable";
import DeleteModal from "../common/DeleteModal";
import SortIcon from "../common/SortIcon";
import { getFeedBackStatusColor } from "../helper/statusUtils";

const RequstForQuotation = () => {
  const [quotationData, setQuotationData] = useState(null);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [exportAll, setExportAll] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  // const [feedBackMechanismId, setFeedBackeMechanismId] = useState(null);
  const [feedBackMechanismId, setFeedBackeMechanismId] = useState(null);
  const navigate = useNavigate(true);
  const [search, setSearch] = useState("");
  const [lastSearch, setLastSearch] = useState('');
  const [pageSize, setPageSize] = useState(20);
  const {
    getFeedBackMechanismLoader,
    feedBackMechanismDataCount,
    feedBackMechanismData,
    deleteFeedBackMechanismLoader,
    allFeedBackMechanismLoader,
  } = useSelector((state) => state.feedBackMechanism);

  const { currentCompanyUser } = useSelector((state) => state.auth);
  const offsetRef = useRef(0);
  const dispatch = useDispatch();

  const getApiCall = (
    offset = 0,
    limit = pageSize,
    search = "",
    orderByColumn = "",
    order = ""
  ) => {
    if (currentCompanyUser && currentCompanyUser?.id) {
      dispatch(
        actionGetFeedBackMechanism({
          offset,
          limit,
          orderByColumn,
          order,
          search,
          company_user_id: currentCompanyUser?.id,
        })
      );
    }
  };

  useEffect(() => {
    getApiCall();
  }, [dispatch]);

  useEffect(() => {
    if (feedBackMechanismId && viewModalOpen) {
      dispatch(
        actionGetSingleFeedBackMechanism({
          id: feedBackMechanismId,
        })
      );
    }
  }, [dispatch, feedBackMechanismId]);

  const statusItems = [
    {
      key: "1",
      label: "Pending",
      value: "Pending",
    },
    {
      key: "2",
      label: "Reviewed",
      value: "Reviewed",
    },
    {
      key: "3",
      label: "In Progress",
      value: "In Progress",
    },
    {
      key: "4",
      label: "Resolved",
      value: "Resolved",
    },
  ];

  const columns = [
    {
      title: "CH No.",
      key: "ch_no",
      width: 100,
      dataIndex: "crm_users_feedback_id",
      fixed: "left",
      render: (text) =>
        <div
          onClick={() => {
            dispatch(
              handleActionWithPermission("crm-users-feedback", "read", () => {
                navigate(
                  `/view-feedback-mechanism/${text}`
                );
              })
            );
          }}
          className="flex text-brand items-center cursor-pointer"
        >
          {text ?? "-"}
        </div>,
    },
    {
      title: "Feedback Type",
      dataIndex: "feedback_type",
      key: "feedback_type",
      fixed: "left",
      width: 200,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <div
          onClick={() => {
            dispatch(
              handleActionWithPermission("crm-users-feedback", "read", () => {
                navigate(
                  `/view-feedback-mechanism/${data?.crm_users_feedback_id}`
                );
              })
            );
          }}
          className="flex text-brand items-center cursor-pointer"
        >
          {text ?? "-"}
        </div>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 250,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{
            rows: 1,
            tooltip: {
              title: text || "-",
              styles: { maxWidth: 600, wordWrap: "break-word" },
            },
          }}
          style={{ width: 700, margin: 0 }}
          className="ml-2"
        >
          {text || "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Attachment",
      dataIndex: "attachments",
      key: "attachments",
      width: 150,

      render: (text, data) => {
        // Check if attachments exist
        if (data.attachments && data.attachments.length > 0) {
          const attachment = data.attachments[0];
          const isVideo = attachment.upload?.mimetype === "video/mp4";
          if (isVideo) {
            return (
              <Tooltip title="Video Attachment">
                <Badge count={data.attachments.length}>
                  <div
                    style={{
                      width: 45,
                      height: 45,
                      backgroundColor: "#f0f0f0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // borderRadius: "8px",
                      cursor: "pointer",
                    }}
                  >
                    <VideoCameraOutlined
                      style={{ fontSize: 24, color: "#1890ff" }}
                    />
                  </div>
                </Badge>
              </Tooltip>
            );
          }

          // If it's an image
          return (
            <Image.PreviewGroup
              items={data.attachments.map((item) => ({
                src: item.upload?.url || "",
              }))}
            >
              <Badge count={data.attachments.length}>
                <Image
                  width={45}
                  height={45}
                  preview={false}
                  className="object-contain"
                  src={attachment.upload?.url || ""}
                  style={{
                    objectFit: "cover",
                  }}
                />
              </Badge>
            </Image.PreviewGroup>
          );
        }

        // If no attachments
        return <span>-</span>;
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 100,
      render: (text, data) => {
        return text ? (
          <Dropdown
            menu={{
              items: statusItems.filter(
                (item) => item.value.toLowerCase() !== data.status.toLowerCase()
              ),
              onClick: (key) => {
                const selected = statusItems.find(
                  (item) => item.key === key.key
                );
                const req = {
                  status: selected.value,
                };

                dispatch(
                  actionUpdateStatus({
                    id: data.crm_users_feedback_id,
                    req,
                  })
                );
              },
            }}
            placement="bottom"
            arrow={{
              pointAtCenter: true,
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Tag
                color={getFeedBackStatusColor(text)}
              // className="py-1 px-2.5 text-sm font-popinsRegular"
              >
                {text}
              </Tag>
            </a>
          </Dropdown>
        ) : (
          "-"
        );
      },
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 100,
      align: "center",
      fixed: "right",
      render: (text, data) => (
        <Dropdown
          id="custom-drop"
          menu={{
            items: [
              {
                label: <span>Edit</span>,
                key: "Edit",
                icon: <EditOutlined />,
                onClick: () => {
                  dispatch(
                    handleActionWithPermission(
                      "crm-users-feedback",
                      "update",
                      () =>
                        navigate("/update-feedback-mechanism", {
                          state: {
                            feedBackMechanismId: data.crm_users_feedback_id,
                          },
                        })
                    )
                  );
                },
              },
              {
                label: "Delete",
                icon: <DeleteOutlined style={{ fontSize: 12 }} />,
                danger: true,
                onClick: () =>
                  dispatch(
                    handleActionWithPermission(
                      "crm-users-feedback",
                      "delete",
                      () => {
                        setIsDeleteModalOpen((prev) => !prev);
                        setFeedBackeMechanismId(data?.crm_users_feedback_id);
                      }
                    )
                  ),
                key: "Delete",
              },
            ].filter(Boolean),
          }}
        >
          <EllipsisOutlined className="cursor-pointer" />
        </Dropdown>
      ),
    },
  ];

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.columnKey,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
            ? "ASC"
            : "",
    };

    getApiCall(
      offsetRef.current,
      pageSize,
      search,
      sorterParams.field,
      sorterParams.order
    );
  };

  const handleRefresh = () => {
    getApiCall(offsetRef.current, pageSize, search);
  };

  useEffect(() => {
    const trimmedSearch = search?.trim();
    if (trimmedSearch !== "" && trimmedSearch !== lastSearch) {
      const timer = setTimeout(() => {
        getApiCall(offsetRef.current, pageSize, trimmedSearch);
        setLastSearch(trimmedSearch);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }

  }, [search, lastSearch]);

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      getApiCall(offsetRef.current, pageSize, e.target.value);
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      getApiCall(offsetRef.current, pageSize, e.target.value);
    }
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setFeedBackeMechanismId(null);
  };


  /* const handlePaginationChange = (page, pageSize) => {
    offsetRef.current = page - 1;
    setPageSize(pageSize);
    getApiCall(offsetRef.current, pageSize, search, "", "", roleID, roleProfileID, reprotToID, zoneID, productCategoryIDs, status, isFaceIdAvailable);
  } */

  return (
    <>
      <section id="sales-orders" className="main-table-container ">


        <CommonTable
          pageTitle={<PageTitle
            margin={false}
            image={feedBackMechanism}
            title="Feedback"
            rightIcon={false}
          />}
          title={"Feedback"}
          buttonText="Raise a Ticket"
          buttonLink="/create-feedback-mechanism"
          resource="crm-users-feedback"
          moduleKey="feedback"
          loading={getFeedBackMechanismLoader || allFeedBackMechanismLoader}
          columns={columns}
          scrollToFirstRowOnChange={true}
          data={(feedBackMechanismData || [])}
          rowKey={"crm_users_feedback_id"}
          onChange={handleSortChange}
          handleRefresh={handleRefresh}
          handleSearch={handleSearch}
          handleBlurAndPressEnter={handleBlurAndPressEnter}
          search={search}
          isAllowDownload={false}
          total={feedBackMechanismDataCount}
          pageSize={50}
        /*   handlePaginationChange={(e) => {
            offsetRef.current = e - 1;
            getApiCall(offsetRef.current, search);
          }} */
        />

        <div className="flex justify-between mt-3">
          <p className="text-[15px] font-popinsMedium">
            Total :{" "}
            {feedBackMechanismDataCount ? feedBackMechanismDataCount : 0}
          </p>
          <CommonPagination
            current={offsetRef.current + 1}
            total={feedBackMechanismDataCount}
            onChange={(page, pageSize) => {
              offsetRef.current = page - 1;
              setPageSize(pageSize);
              getApiCall(offsetRef.current, pageSize, search);
            }}
            loading={getFeedBackMechanismLoader}
            pageSize={pageSize}
          />
        </div>

        {/* <CommonModal
          className=""
          centered
          open={viewModalOpen}
          title=""
          loading={getSingleFeedBackMechanismLoader}
          onCancel={handleCancel}
          handleReset={handleCancel}
          onOk={handleCancel}
        >
          <Spin spinning={getSingleRequestForQuotationLoader}>
          {getSingleFeedBackMechanismData && (
            <div>
              <div className="text-brand flex items-center gap-x-3">
                <Image
                  src={requestForQuotation}
                  alt="image"
                  height={20}
                  width={20}
                  preview={false}
                />
                <h1 className="lg:text-lg text-base font-popinsRegular">
                  View Request For Quotation
                </h1>
              </div>
              <div className="flex flex-col mt-3 gap-y-4">
                <div className="flex gap-x-6 items-center gap-y-1">
                  <p className="text-xs text-[#818181] w-[30%]">Category:</p>
                  <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                    {getSingleFeedBackMechanismData.category &&
                    getSingleFeedBackMechanismData.category?.category_name
                      ? getSingleFeedBackMechanismData.category?.category_name
                      : "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-center gap-y-1">
                  <p className="text-xs text-[#818181] w-[30%]">Grade:</p>
                  <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                    {getSingleFeedBackMechanismData.grade ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-center gap-y-1">
                  <p className="text-xs text-[#818181] w-[30%]">Thickness:</p>
                  <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                    {getSingleFeedBackMechanismData.thickness ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-center gap-y-1">
                  <p className="text-xs text-[#818181] w-[30%]">
                    Size if custom-made:
                  </p>
                  <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                    {getSingleFeedBackMechanismData.custom_made_size ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-center gap-y-1">
                  <p className="text-xs text-[#818181] w-[30%]">SKU:</p>
                  <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                    {getSingleFeedBackMechanismData.sku ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-center gap-y-1">
                  <p className="text-xs text-[#818181] w-[30%]">
                    Custom Thickness:
                  </p>
                  <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                    {getSingleFeedBackMechanismData.custom_thickness ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-center gap-y-1">
                  <p className="text-xs text-[#818181] w-[30%]">Status:</p>
                  <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                    {getSingleFeedBackMechanismData.status ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-center gap-y-1">
                  <p className="text-xs text-[#818181] w-[30%]">Remark:</p>
                  <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                    {getSingleFeedBackMechanismData.remark ?? "-"}
                  </p>
                </div>
              </div>
            </div>
          )}
          </Spin>
        </CommonModal> */}
      </section>

      <DeleteModal
        title="Are you sure you want to remove this?"
        // description="All the associations will be removed for this Feedback"
        loading={deleteFeedBackMechanismLoader}
        onCancel={() => {
          setIsDeleteModalOpen(false);
          setFeedBackeMechanismId(null);
        }}
        onConfirm={() =>
          dispatch(
            actionDeleteFeedBackMechanism({
              feedBackMechanismId,
              handleCloseDeleteModal,
            })
          )
        }
        isVisible={isDeleteModalOpen}
      />
    </>
  );
};

export default RequstForQuotation;
