import { EyeInvisibleOutlined, EyeOutlined, InfoCircleOutlined, KeyOutlined } from "@ant-design/icons";
import { Skeleton, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

const AccessKey = ({ keyValue, isDisabled }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };



  return (
    <div
      className={`mb-4 p-4 bg-white rounded-lg border-[0.5px] border-[#d4d4d4] ${isDisabled ? 'opacity-50' : ''
        }`}
    >
      <div className="flex flex-wrap items-center justify-between">
        <div className="flex items-start space-x-4">
          <KeyOutlined className="text-[24px] text-blue-500" />
          <div>
            <p className="font-semibold text-base sm:text-lg">API Key <Tooltip title="This API key is allowed in SAP integration.">
              <InfoCircleOutlined
                style={{ fontSize: "16px", color: "rgb(107 114 128)" }}
              />
            </Tooltip></p>
            <div className="flex items-center space-x-2">
              <Typography.Text
                className="font-popinsRegular text-sm break-all"
                copyable={isVisible ? { text: keyValue, tooltips: ['Copy', 'Copied'] } : false}
              >
                {isVisible ? keyValue : '••••••••••••••••••••••••••••••••'}
              </Typography.Text>

            </div>
          </div>
        </div>
        <div className="flex items-center space-x-4 mt-2 sm:mt-0">
          <button
            onClick={toggleVisibility}
            disabled={isDisabled}
            className={`p-2 rounded-md focus:outline-none ${isDisabled ? 'cursor-not-allowed' : 'hover:bg-gray-100'
              }`}
          >
            {isVisible ? (
              <EyeInvisibleOutlined style={{ fontSize: 18 }} className='text-[rgb(107 114 128)]' />
            ) : (
              <EyeOutlined style={{ fontSize: 18 }} className='text-[rgb(107 114 128)]' />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

const AccessKeys = ({ data, loading }) => {
  const dispatch = useDispatch();


  return (
    <div className="max-w-full sm:max-w-[800px] pt-7 mx-auto p-4 space-y-4">

      {!data && loading ? <Skeleton avatar={false} /> : data && !loading ? (
        <AccessKey keyValue={data} isDisabled={loading} />
      ) : (
        <div className="mb-4 p-4 bg-white rounded-lg border-[0.5px] border-[#d4d4d4]">
          <div className="flex items-center justify-center">
            <p className="text-base sm:text-lg text-gray-600">
              No API keys available. Please generate a new key.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccessKeys;
