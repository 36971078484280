import { Image } from "antd";
import React from "react";
import ascSortIcon from "../../assets/img/asc-sort-icon.svg";
import descSortIcon from "../../assets/img/desc-sort-icon.svg";
import sortIcon from "../../assets/img/default-sort-icon.svg";

const SortIcon = ({ onClick = () => { }, sortOrder = "" }) => {
  return <Image onClick={onClick} preview={false} src={sortOrder === "ascend" ? ascSortIcon : sortOrder === "descend" ? descSortIcon : sortIcon} />;
};

export default SortIcon;
