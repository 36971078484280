import { Table, Skeleton } from "antd";
import React from "react";

const SkeletonTable = ({ columns = [], isCheckbox = false, length = 9, isMaster = false }) => {
  const skeletonColumns = columns.map((column) => ({
    ...column,
    render: () => (
      <Skeleton.Button
        block
        active
        size="small"
        style={{
          width: "100%",
          borderRadius: "4px",
        }}
      />
    ),
  }));

  const dataSource = Array.from({ length: length }, (_, index) => ({
    key: index,
  }));

  return (
    <>
      <Table
        bordered
        rowKey={"key"}
        scroll={{ x: false }}
        rowSelection={isCheckbox ? { selectedRowKeys: [] } : undefined}
        pagination={false}
        columns={skeletonColumns}
        dataSource={dataSource}
      />
    </>
  );
};

export default SkeletonTable;
