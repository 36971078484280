import { SearchOutlined } from "@ant-design/icons";
import { Badge, Button, Dropdown, Image, Input, Space } from "antd";
import React from "react";
import importIcon from "../../assets/img/import-icon.svg";
import excelIcon from "../../assets/img/excel-icon.svg";
import { isValidSearchString } from "../helper/common-function";
import { SiMicrosoftexcel } from "react-icons/si";
import TooltipWrapper from "./TooltipWrapper";
import exportIcon from "../../assets/img/exportIcon.svg";
import { useNavigate } from "react-router-dom";
import { CiFilter } from "react-icons/ci";

const dropdownItems = [
  {
    label: "Export This Page",
    key: "1",
    icon: <Image src={excelIcon} preview={false} alt="Excel Icon" />,
  },
  {
    label: "Export All",
    key: "2",
    icon: <Image src={excelIcon} preview={false} alt="Excel Icon" />,
  },
];

const MasterSearchbar = ({
  search,
  handleSearch,
  handleBlurAndPressEnter,
  isDownloadEnabled = true,
  isImportEnabled = true,
  filterValues = 0,
  isAdvanceFilter = false,
  onClickFilter = () => { },
  items = dropdownItems,
  isPagination = false,
  onClickDownloadMenu,
  importRoute,
  ...props
}) => {
  const navigate = useNavigate();
  const handleInputChange = (e) => {
    if (isValidSearchString(e.target.value)) {
      handleSearch(e);
    }
  };

  const handleBlurAndPressEnterChange = (e) => {
    if (search && isValidSearchString(e.target.value)) {
      handleBlurAndPressEnter(e);
    }
  };

  const handleExportClick = (key) => {
    onClickDownloadMenu(key);
  };

  return (
    <section className="main-section">
      <Space.Compact className="w-full custom-input-sec relative flex mb-5">
        <Input
          {...props}
          value={search}
          onChange={handleInputChange}
          // onBlur={handleBlurAndPressEnterChange}
          onPressEnter={handleBlurAndPressEnterChange}
          size="large"
          style={{ borderRight: "none" }}
          className=" hover:border-gray-400 bg-transparent"
          id="none-border"
          allowClear
          prefix={<SearchOutlined className="text-[#7C7C7C]" />}
          addonAfter={

            <div className="flex h-full items-center ">
              {isAdvanceFilter && (
                <TooltipWrapper title={"Filter"}>
                  <Button
                    style={{ backgroundColor: "white" }}
                    onClick={onClickFilter}
                    className="h-full default-button bg-white icon-btn"
                  >
                    <Badge
                      count={filterValues}
                      size="small"
                      overflowCount={9}
                      style={{
                        backgroundColor: "#ff4d4f",
                      }}
                    >
                      <CiFilter
                        strokeWidth={0.6}
                        style={{ fontWeight: "bold" }}
                        size={17}
                        color="#6883fd"
                      />
                    </Badge>
                  </Button>
                </TooltipWrapper>
              )}
              {isDownloadEnabled && (
                <TooltipWrapper title="Export">
                  {isPagination ? (
                    <Dropdown
                      menu={{
                        items,
                        onClick: (key) => handleExportClick(key),
                      }}
                    >
                      <Button
                        style={{ backgroundColor: "white" }}
                        className="h-full  default-button px-5 !w-[50px] icon-btn"
                        icon={
                          <Image
                            src={exportIcon}
                            alt="import-icon"
                            preview={false}
                          />
                        }
                      />
                    </Dropdown>
                  ) : (
                    <Button
                      style={{ backgroundColor: "white" }}
                      onClick={onClickDownloadMenu}
                      className="h-full  default-button px-5 !w-[50px] icon-btn"
                      icon={
                        <Image
                          src={exportIcon}
                          alt="import-icon"
                          preview={false}
                        />
                      }
                    />
                  )}
                </TooltipWrapper>
              )}
              {isImportEnabled && (
                <TooltipWrapper title="Import">
                  <Button
                    onClick={() => navigate(importRoute)}
                    disabled={false}
                    style={{ backgroundColor: "white" }}
                    className="h-full  default-button px-5 !w-[50px]  icon-btn"
                    icon={
                      <Image
                        src={importIcon}
                        alt="import-icon"
                        preview={false}
                      />
                    }
                  />
                </TooltipWrapper>
              )}
            </div>

          }
          placeholder="Search here..."
        />
      </Space.Compact>
    </section>
  );
};

export default MasterSearchbar;
