import {
    DeleteOutlined,
    EditOutlined,
    EllipsisOutlined,
} from "@ant-design/icons";
import { Avatar, ConfigProvider, Dropdown, Modal, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import * as XLSX from "xlsx";
import quickReminderIcon from "../../assets/img/quick-reminder-icon.svg";
import { actionClearAllReminders, actionDeleteReminder, actionGetAllReminders, actionGetReminders, actionGetSingleReminderDetail } from '../../store/services/quickRemiderService';
import { handleActionWithPermission } from '../../store/slices/authSlice';
import CommonTable from '../common/CommonTable';
import DeleteModal from '../common/DeleteModal';
import PageTitle from '../common/PageTitle';
import { getFirstLetterCapital } from '../helper/common-function';
import { getRepeatPlanText } from '../sales-force/BeatPlanning';
import CreateQuickReminder from './CreateQuickReminder';

const QuickReminder = () => {
    const offsetRef = useRef(0);
    const dispatch = useDispatch();
    const [search, setSearch] = useState("");
    const [exportAll, setExportAll] = useState(false);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [reminderId, setReminderId] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams({ id: "" });
    const [lastSearch, setLastSearch] = useState('');
    const [pageSize, setPageSize] = useState(20);
    const { remindersCounts, remindersData, getRemindersLoader, allRemindersData, getAllRemindersLoader, deleteReminderLoader, singleReminderDetail, getSingleReminderDetailLoader } = useSelector(
        (state) => state.reminder
    );
    const getApiCall = (
        offset = 0,
        limit = pageSize,
        search = "",
        orderByColumn = "",
        order = "",
    ) => {
        dispatch(
            actionGetReminders({
                offset,
                limit,
                orderByColumn,
                order,
                search,
            })
        );
    };

    useEffect(() => {
        getApiCall();
    }, [dispatch]);

    useEffect(() => {
        const reminderId = searchParams.get("id");
        if (reminderId) {
            dispatch(
                handleActionWithPermission("reminder", "update", () => {
                    setCreateModalOpen(true);
                    setReminderId(reminderId);
                    dispatch(actionGetSingleReminderDetail(reminderId));
                })
            );
        }
    }, [searchParams, dispatch]);

    const columns = [
        {
            title: "CH No.",
            key: "ch_no",
            width: 100,
                  dataIndex: "reminder_id",
            fixed: "left",
            render: (text) =>
                <div
                    onClick={() => {
                        dispatch(
                            handleActionWithPermission("reminder", "update", () => {
                                setCreateModalOpen(true),
                                    setReminderId(text)
                                dispatch(actionGetSingleReminderDetail(text));
                            })
                        );
                    }}
                    className="flex text-brand items-center cursor-pointer"
                >
                    {text ?? "-"}
                </div>,
        },
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
            fixed: "left",
            width: 250,
            ellipsis: true,
            render: (text, data) => (
                <div
                    onClick={() => {
                        dispatch(
                            handleActionWithPermission("reminder", "update", () => {
                                setCreateModalOpen(true),
                                    setReminderId(data.reminder_id)
                                dispatch(actionGetSingleReminderDetail(data.reminder_id));
                            })
                        );
                    }}
                    className="flex items-center text-brand cursor-pointer"
                >
                    <p>
                        {text.length > 40
                            ? `${getFirstLetterCapital(text.slice(0, 40))}...`
                            : getFirstLetterCapital(text)}
                    </p>
                </div>
            ),
        },
        {
            title: "Assigned To",
            dataIndex: "reminder_company_users",
            key: "reminder_company_users",
            width: 250,
            render: (assignees) => (
                <Avatar.Group
                    size={30}
                    max={{
                        count: 5,
                        style: {
                            color: "white",
                            backgroundColor: "#6883FD",
                            cursor: "pointer",
                            fontSize: 15,
                        },
                        popover: { trigger: "click" },
                    }}
                >
                    {assignees && assignees.length > 0
                        ? assignees.map((item, index) => (
                            <Tooltip
                                key={item?.id}
                                title={item?.full_name}
                            >
                                <Avatar
                                    key={index}
                                    src={item?.profile_pic}
                                />
                            </Tooltip>
                        ))
                        : "-"}
                </Avatar.Group>
            ),
        },
        {
            title: "Date",
            dataIndex: "start_date",
            key: "start_date",
            width: 130,
            render: (text) => (text ? dayjs(text).format("DD-MM-YYYY") : "-"),
        },
        {
            title: "Time",
            dataIndex: "reminder_on_time",
            key: "reminder_on_time",
            width: 130,
            render: (text) => (text ? text : "-"),
        },
        {
            title: "Repeat",
            dataIndex: "name",
            key: "name",
            width: 400,
            ellipsis: true,
            render: (text, data) => (
                <Typography.Paragraph className="!mb-0">
                    {getRepeatPlanText(data)}
                </Typography.Paragraph>
            ),
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            align: "center",
            width: 100,
            fixed: "right",
            render: (text, data) => (
                <>
                    <Dropdown
                        id="custom-drop"
                        menu={{
                            items: [
                                {
                                    label: <span>Edit</span>,
                                    key: "Edit",
                                    icon: <EditOutlined />,
                                    onClick: () => {
                                        dispatch(
                                            handleActionWithPermission(
                                                "reminder",
                                                "update",
                                                () => {
                                                    setCreateModalOpen(true),
                                                        setReminderId(data.reminder_id)
                                                    dispatch(
                                                        actionGetSingleReminderDetail(data.reminder_id)
                                                    );
                                                }
                                            )
                                        );
                                    },
                                },
                                {
                                    label: "Delete",
                                    icon: <DeleteOutlined style={{ fontSize: 12 }} />,
                                    danger: true,
                                    onClick: () =>
                                        dispatch(
                                            handleActionWithPermission(
                                                "reminder",
                                                "delete",
                                                () => {
                                                    setIsDeleteModalOpen((prev) => !prev);
                                                    setReminderId(data?.reminder_id);
                                                }
                                            )
                                        ),
                                    key: "Delete",
                                }
                            ],
                        }}
                    >
                        <div className="flex justify-center items-center">
                            <EllipsisOutlined />
                        </div>
                    </Dropdown>
                </>
            ),
        },
    ];

    const handleRefresh = () => {
        offsetRef.current = 0;
        getApiCall(offsetRef.current, pageSize, search);
    };

    useEffect(() => {
        const trimmedSearch = search?.trim();
        if (trimmedSearch !== "" && trimmedSearch !== lastSearch) {
            const timer = setTimeout(() => {
                getApiCall(offsetRef.current, pageSize, trimmedSearch, "", "");
                setLastSearch(trimmedSearch);
            }, 500);

            return () => {
                clearTimeout(timer);
            };
        }

    }, [search, lastSearch]);

    const handleSearch = (e) => {
        offsetRef.current = 0;
        setSearch(e.target.value);
        if (e.target.value === "") {
            // Call Action
            getApiCall(
                offsetRef.current,
                pageSize,
                e.target.value,
                "",
                "",
            );
        }
    };

    const handleBlurAndPressEnter = (e) => {
        if (search) {
            offsetRef.current = 0;
            getApiCall(
                offsetRef.current,
                pageSize,
                e.target.value,
                "",
                "",
            );
        }
    };

    const exportAllToExcel = async () => {
        const data =
            (await allRemindersData) &&
            allRemindersData.length > 0 &&
            allRemindersData.map((data) => ({
                Title: data.title ? data.title : "-",
                "Assigned Users":
                    data.reminder_company_users && data.reminder_company_users.length > 0
                        ? data.reminder_company_users
                            .map(
                                (user) =>
                                    user &&
                                    user?.full_name
                            )
                            .join(", ")
                        : "-",
                Date: data.start_date ? dayjs(data.start_date).format("DD-MM-YYYY") : "-",
                Time: data.reminder_on_time ? data.reminder_on_time : "-",
                Repeat: data ? getRepeatPlanText(data) : "-"

            }));
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Reminder");
        // Generate the Excel file and initiate the download
        XLSX.writeFile(workbook, "reminder.xlsx");
        dispatch(actionClearAllReminders({ setExportAll }));
    };

    useEffect(() => {
        if (exportAll && allRemindersData && allRemindersData.length > 0) {
            exportAllToExcel();
        }
    }, [allRemindersData]);

    const onClickDownloadMenu = () => {
        dispatch(actionGetAllReminders());
        setExportAll(true);
    };

    const handleSortChange = (pagination, filters, sorter) => {
        const sorterParams = {
            field: sorter.key,
            order:
                sorter.order === "descend"
                    ? "DESC"
                    : sorter.order === "ascend"
                        ? "ASC"
                        : "",
        };

        getApiCall(
            offsetRef.current,
            pageSize,
            search,
            sorterParams.field,
            sorterParams.order,
        );
    };
    // add-update Quick Reminder start
    const handleOpenModal = () => {
        setCreateModalOpen(true);
        searchParams.delete("id");
        setSearchParams(searchParams);
    };
    const handleCloseCreateModal = () => {
        setCreateModalOpen(false);
        setReminderId("");

    };
    // add-update Quick Reminder end

    const handleCloseDeleteModal = () => {
        setIsDeleteModalOpen(false);
        setReminderId(null);
    };


    const handlePaginationChange = (page, pageSize) => {
        offsetRef.current = page - 1;
        setPageSize(pageSize);
        getApiCall(
            offsetRef.current,
            pageSize,
            search,
            "",
            "",
        );
    }

    return (
        <>
            <section className="main-wrapper main-table-container ">

                <ConfigProvider
                    theme={{
                        components: {
                            Checkbox: {
                                colorPrimary: "#6883FD",
                                colorPrimaryHover: "#6883FD",
                            },
                        },
                    }}
                >
                    <CommonTable
                        pageSize={pageSize}
                        pageTitle={<PageTitle
                            margin={false}
                            image={quickReminderIcon}
                            title="Reminder"
                            rightIcon={false}
                        />
                        }
                        title='Reminders'
                        type="btn"
                        onAddClick={handleOpenModal}
                        buttonText="Reminder"
                        resource="reminder"
                        total={remindersCounts}
                        handlePaginationChange={handlePaginationChange}
                        moduleKey="remind"
                        loading={getRemindersLoader || getAllRemindersLoader}
                        columns={columns}
                        scrollToFirstRowOnChange={true}
                        data={(remindersData || [])}
                        rowKey={"reminder_id"}
                        onChange={handleSortChange}
                        handleRefresh={handleRefresh}
                        handleSearch={handleSearch}
                        handleBlurAndPressEnter={handleBlurAndPressEnter}
                        search={search}
                        onClickDownloadMenu={onClickDownloadMenu}

                    />

                </ConfigProvider>

            </section>
            <Modal
                destroyOnClose={true}
                // closeIcon={<Image preview={false} src={closeIcon} alt="close-icon" />}
                className="create-modal"
                centered
                loading={getSingleReminderDetailLoader}
                onOk={handleCloseCreateModal}
                onCancel={handleCloseCreateModal}
                afterClose={handleCloseCreateModal}
                footer={false}
                width={900}
                title=""
                open={createModalOpen}
            >
                <CreateQuickReminder
                    data={singleReminderDetail}
                    handleCloseCreateModal={handleCloseCreateModal}
                    id={reminderId}
                    setReminderId={setReminderId}
                    setCreateModalOpen={setCreateModalOpen}
                    createModalOpen={createModalOpen}
                />
            </Modal>
            <DeleteModal
                title="Are you sure you want to remove this reminder?"
                loading={deleteReminderLoader}
                onCancel={() => {
                    setIsDeleteModalOpen(false);
                    setReminderId(null);
                }}
                onConfirm={() =>
                    dispatch(
                        actionDeleteReminder({
                            reminderId,
                            handleCloseDeleteModal,
                        })
                    )
                }
                isVisible={isDeleteModalOpen}
            />
        </>
    )
}

export default QuickReminder