import { ExclamationCircleFilled, HolderOutlined } from "@ant-design/icons";
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  Checkbox,
  DatePicker,
  Input,
  Modal,
  Popconfirm,
  Select,
  Table,
  Tooltip,
} from "antd";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import deleteIcon from "../../../assets/img/deleteevent.svg";
import editIcon from "../../../assets/img/editevent.svg";
import {
  actionChangeOrderCustomField,
  actionDeleteCustomField,
  actionGetCustomFields,
} from "../../../store/services/customFieldService";
import MasterSearchbar from "../../common/MasterSearchbar";
import PhoneInputComponent from "../../common/PhoneInputComponent";
import SkeletonTable from "../../common/SkeletonTable";

const RowContext = React.createContext({});

const DragHandle = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <HolderOutlined
      style={{
        cursor: "move",
      }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const renderFieldInput = (type, options = []) => {
  if (type === "Dropdown" || type === "MultiSelect" || type === "Checkbox") {
    if (typeof options === "string") {
      options = options.split(",").map((option) => option.trim());
    } else if (typeof options === "number") {
      options = [options.toString()];
    } else if (Array.isArray(options)) {
      options = options.map((option) => option.toString());
    } else {
      options = [];
    }
  }

  switch (type) {
    case "Text":
      return (
        <Input
          readOnly
          placeholder="Enter text"
          value={""}
          className="rounded-md"
        />
      );
    case "Phone":
      return (
        <PhoneInputComponent
          value={""}
          onChange={() => {}}
          setIsValid={() => {}}
          isValid={true}
          setValue={() => {}}
        />
      );
    case "URL":
      return (
        <Input
          value={""}
          readOnly
          placeholder="Enter URL"
          className="rounded-md"
        />
      );
    case "Email":
      return (
        <Input
          value={""}
          readOnly
          placeholder="Enter email"
          className="rounded-md"
        />
      );
    case "Number":
      return (
        <Input
          value={0}
          readOnly
          type="number"
          placeholder="Enter number"
          className="rounded-md"
        />
      );
    case "TextArea":
      return (
        <Input.TextArea
          value={""}
          readOnly
          rows={3}
          placeholder="Enter detailed text"
          className="rounded-md"
        />
      );
    case "Date":
      return (
        <DatePicker
          value={""}
          readOnly
          placeholder="Select date"
          className="w-full rounded-md"
        />
      );
    case "Checkbox":
      return options && options.length > 0 ? (
        <Checkbox.Group className="w-full">
          {options.slice(0, 4).map((option, index) => (
            <Checkbox key={index} value={option}>
              {option}
            </Checkbox>
          ))}
          {options.length > 4 && (
            <Tooltip
              title={
                <div>
                  {options.slice(4).map((option, index) => (
                    <div key={index}>{option}</div>
                  ))}
                </div>
              }
              placement="bottom"
            >
              <span className="cursor-pointer text-blue-500"> ...</span>
            </Tooltip>
          )}
        </Checkbox.Group>
      ) : (
        <Checkbox>Checkbox</Checkbox>
      );

    case "Dropdown":
      return (
        <Select allowClear className="w-full" placeholder="Select an option">
          {options && options.length > 0
            ? options.map((option, index) => (
                <Select.Option key={index} value={option}>
                  {option}
                </Select.Option>
              ))
            : "No Options Found"}
        </Select>
      );
    case "MultiSelect":
      return (
        <Select
          allowClear
          className="w-full"
          mode="multiple"
          placeholder="Select multiple options"
        >
          {options &&
            options.length > 0 &&
            options.map((option, index) => (
              <Select.Option key={index} value={option}>
                {option}
              </Select.Option>
            ))}
        </Select>
      );
    default:
      return null;
  }
};

const Row = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };
  const contextValue = useMemo(
    () => ({
      setActivatorNodeRef,
      listeners,
    }),
    [setActivatorNodeRef, listeners]
  );
  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

const CustomFieldContent = ({ itemKey, setIsModalOpen, setEditId }) => {
  const dispatch = useDispatch();
  const {
    getCustomFieldsLoader,
    customFields,
    deleteCustomFieldLoader,
    customFieldsCount,
  } = useSelector((state) => state.customField);
  const [dataSource, setDataSource] = useState([]);
  const [search, setSearch] = useState("");
  const offsetRef = useRef(0);

  const handleEdit = (id) => {
    setIsModalOpen(true);
    setEditId(id);
  };

  const columns = [
    {
      key: "sort",
      align: "center",
      width: 80,
      render: () => <DragHandle />,
    },
    {
      title: "Label",
      dataIndex: "label",
      ellipsis: true,
      width: 150,
    },
    {
      title: "Preview",
      dataIndex: "type",
      // ellipsis: true,
      render: (text, data) => renderFieldInput(text, data.field_options),
    },
    {
      title: "Is Mandatory",
      width: 150,
      align: "middle",
      dataIndex: "is_required",
      render: (text) => (
        <span
          className={`font-popinsSemiBold text-center ${
            text ? "text-green-500" : "text-red-500"
          }`}
        >
          {text ? "YES" : "NO"}
        </span>
      ),
    },

    {
      key: "Action",
      width: 100,
      title: "Action",
      dataIndex: "Action",
      render: (text, data) => (
        <div className="flex items-center gap-x-3 justify-center">
          <img
            src={editIcon}
            onClick={() => handleEdit(data.custom_field_id)}
            alt=""
          />
          {/*  <Popconfirm
            trigger={"click"}
            okButtonProps={{
              loading: deleteCustomFieldLoader,
            }}
            placement="topLeft"
            title="Delete Custom Field"
            description={`Are you sure to delete this custom field?`}
            onConfirm={() =>
              dispatch(actionDeleteCustomField(data.custom_field_id))
            }
            okText="Yes"
            cancelText="No"
          >
            {" "} */}
          <img
            onClick={() => showDeleteConfirm(data.custom_field_id)}
            src={deleteIcon}
            alt=""
          />
          {/*  </Popconfirm> */}
        </div>
      ),
    },
  ];

  const getApiCall = (
    search = "",
    orderByColumn = "sort_order",
    order = "ASC"
  ) => {
    dispatch(
      actionGetCustomFields({
        module_name: itemKey,
        orderByColumn,
        order,
        search,
      })
    );
  };

  useEffect(() => {
    if (itemKey) {
      getApiCall();
    }
  }, [dispatch, itemKey]);

  useEffect(() => {
    if (customFields && customFields.length > 0) {
      const newData = customFields.map((d, index) => ({
        ...d,
        key: index,
        label: d.label,
        type: d.field_type,
        id: d.custom_field_id,
        is_required: d.is_required,
        address: d.address || "",
      }));
      setDataSource(newData);
    } else {
      setDataSource([]);
    }
  }, [customFields]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    })
  );

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      getApiCall(e.target.value);
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      getApiCall(search);
    }
  };

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id);
        const overIndex = prev.findIndex((i) => i.key === over?.id);
        const newArray = arrayMove(prev, activeIndex, overIndex);
        const req = {
          custom_field_id: newArray.map((arr) => arr.custom_field_id),
          module_name: itemKey,
        };
        dispatch(actionChangeOrderCustomField({ req }));
        return newArray;
      });
    }
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };
    getApiCall(search, sorterParams.field, sorterParams.order);
  };

  const showDeleteConfirm = (id) => {
    return Modal.confirm({
      title: "Are you sure you want to delete this custom field?",
      icon: <ExclamationCircleFilled />,
      content: `If you delete this custom field, all related data in the associated with ${itemKey} will also be permanently deleted. This action cannot be undone.
    
      Are you sure you want to proceed?`,
      okText: "Yes",
      cancelText: "No",
      okType: "danger",
      cancelButtonProps: {
        className: "border hover:border-[#d4d4d4]",
      },
      okButtonProps: {
        className: "danger-btn !text-white",
      },

      onOk: async () => {
        try {
          await dispatch(actionDeleteCustomField({ id }));
        } catch (error) {
          console.error("Error deleting custom field:", error);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <>
      <div className="search-container">
        <MasterSearchbar
          handleSearch={handleSearch}
          handleBlurAndPressEnter={handleBlurAndPressEnter}
          search={search}
          isImportEnabled={false}
          isDownloadEnabled={false}
        />
      </div>{" "}
      <DndContext
        sensors={sensors}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={onDragEnd}
      >
        <SortableContext
          items={dataSource.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          {getCustomFieldsLoader ? (
            <SkeletonTable columns={columns} />
          ) : (
            <div className="custom-field-table-container !z-0">
              {/* <Skeleton loading=getCustomFieldsLoader} > */}
              <Table
                components={{
                  body: {
                    row: Row,
                  },
                }}
                style={{ zIndex: 0 }}
                scroll={{ y: window.innerWidth > 1400 ? 500 : undefined }}
                pagination={false}
                onChange={handleSortChange}
                // loading=getCustomFieldsLoader}
                rowKey="key"
                columns={columns}
                dataSource={dataSource || []}
              />
              {/* </Skeleton> */}
            </div>
          )}
        </SortableContext>
      </DndContext>
    </>
  );
};
export default CustomFieldContent;
