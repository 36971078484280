import React, { createContext, useState, useContext } from 'react';

// Create the AuthContext
const AuthContext = createContext();

// Create the Provider Component
export const AuthProvider = ({ children }) => {

    const [verified, setVerifed] = useState({
        is_mobile_verified: 0,
        is_email_verified: 0,
    });

    const [emailTimer, setEmailTimer] = useState(0);
    const [phoneTimer, setPhoneTimer] = useState(0);

    const clearContextState = () => {
        setEmailTimer(0);
        setPhoneTimer(0);
        setVerifed({
            is_mobile_verified: 0,
            is_email_verified: 0
        })
    }



    return (
        <AuthContext.Provider value={{ setVerifed, verified, phoneTimer, emailTimer, setEmailTimer, setPhoneTimer, clearContextState }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthContext = () => useContext(AuthContext);
