import {
  DatePicker,
  Form,
  Input,
  Select,
  Skeleton,
  Spin,
  TimePicker,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionGetTimezones } from "../../../store/services/authService";
import { actionGetIndustryMaster } from "../../../store/services/generalMasterService";
import {
  actionGetCountries,
  actionGetStates,
} from "../../../store/services/locationService";
import {
  actionGetCompanyDetail,
  actionUpdateCompanyDetail,
} from "../../../store/services/settingService";
import { formatUrl, getFormattedTimezone } from "../../helper/common-function";
import { handleActionWithPermission } from "../../../store/slices/authSlice";

const CompanyDetails = () => {
  const [form] = Form.useForm();
  const [type, setType] = useState("VIEW");
  const dispatch = useDispatch();
  const [isStateDisabled, setIsStateDisabled] = useState(true);
  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const { getCompanyDetailLoader, companyDetail, updateCompanyDetailLoader } =
    useSelector((state) => state.setting);
  const { countries, states, statesLoader, getCountryLoader } = useSelector(
    (state) => state.location
  );
  const { industryData } = useSelector((state) => state.generalMaster);
  const { timezones, timezoneLoader } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(actionGetCompanyDetail());
  }, []);

  const handleEditClick = async () => {
    await Promise.all([
      dispatch(actionGetCountries()),
      dispatch(actionGetIndustryMaster()),
      dispatch(actionGetTimezones()),
    ]);

    if (companyDetail?.country?.id) {
      await dispatch(actionGetStates(companyDetail.country.id));
    }

    if (companyDetail) {
      await form.setFieldsValue({
        company_name: companyDetail.company_name
          ? companyDetail.company_name
          : "",
        company_code: companyDetail.company_code
          ? companyDetail.company_code
          : "",
        website: companyDetail.website ? companyDetail.website : "",
        industry_id: companyDetail.industry?.industry_master_id
          ? companyDetail.industry?.industry_master_id
          : undefined,
        currency_id: companyDetail.currency?.id
          ? companyDetail.currency?.id
          : undefined,
        company_size: companyDetail.company_size
          ? companyDetail.company_size
          : "",
        phone: `${
          companyDetail.phone_country_code
            ? companyDetail.phone_country_code
            : ""
        }${companyDetail.phone ? companyDetail.phone : ""}`,
        email: companyDetail.email ? companyDetail.email : "",
        fax_number: companyDetail.fax_number ? companyDetail.fax_number : "",
        gst_in: companyDetail.gst_in ? companyDetail.gst_in : "",
        c_in: companyDetail.c_in ? companyDetail.c_in : "",
        pan: companyDetail.pan ? companyDetail.pan : "",
        tan: companyDetail.tan ? companyDetail.tan : "",
        bank_name: companyDetail.bank_name ? companyDetail.bank_name : "",
        bank_account_number: companyDetail.bank_account_number
          ? companyDetail.bank_account_number
          : "",
        ifsc_code: companyDetail.ifsc_code ? companyDetail.ifsc_code : "",
        swift_bic_code: companyDetail.swift_bic_code
          ? companyDetail.swift_bic_code
          : "",
        bank_account_type: companyDetail.bank_account_type
          ? companyDetail.bank_account_type
          : undefined,
        timezone: companyDetail.timezone ? companyDetail.timezone.id : "",
        primary_contact_name: companyDetail.primary_contact_name
          ? companyDetail.primary_contact_name
          : "",
        primary_contact_email: companyDetail.primary_contact_email
          ? companyDetail.primary_contact_email
          : "",
        legal_entity_name: companyDetail.legal_entity_name
          ? companyDetail.legal_entity_name
          : "",
        company_registration_number: companyDetail.company_registration_number
          ? companyDetail.company_registration_number
          : "",
        incorporation_date: companyDetail.incorporation_date
          ? dayjs(companyDetail.incorporation_date)
          : "",
        default_auto_checkout_time: companyDetail.default_auto_checkout_time
          ? dayjs(companyDetail.default_auto_checkout_time)
          : "",
        street_address: companyDetail.street_address
          ? companyDetail.street_address
          : "",
        country_id: companyDetail.country
          ? companyDetail.country.id
          : undefined,
        state_id: companyDetail.state ? companyDetail.state.id : undefined,
        city: companyDetail.city ? companyDetail.city : "",
        postal_code: companyDetail.postal_code ? companyDetail.postal_code : "",
        billing_address: companyDetail.billing_address
          ? companyDetail.billing_address
          : "",
        shipping_address: companyDetail.shipping_address
          ? companyDetail.shipping_address
          : "",
      });
    }

    setType("EDIT");
  };

  const onFinish = (values) => {
    const data = {
      ...values,
      // company_size: values.company_size ? parseInt(values.company_size) : "",
      industry_id: values.industry_id ? values.industry_id : null,
      currency_id: values.currency_id ? values.currency_id : null,
      country_id: values.country_id ? values.country_id : null,
      default_auto_checkout_time: values.default_auto_checkout_time
        ? dayjs(values.default_auto_checkout_time).format("HH:mm")
        : null,
      state_id: values.state_id ? values.state_id : null,
      bank_account_type: values.bank_account_type
        ? values.bank_account_type
        : "",
    };

    dispatch(
      actionUpdateCompanyDetail({
        req: data,
        setType,
      })
    );
  };

  const { TextArea } = Input;

  const urlRegex = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\/\w\-.?&=%]*)?$/i;

  const countriesData = [];
  const currencyData = [];
  const stateData = [];
  const timeZoneData = [];

  const industryMasterData =
    industryData &&
    industryData.length > 0 &&
    industryData.map((data) => ({
      label: data.industry,
      value: data.industry_master_id,
    }));

  countries &&
    countries.length > 0 &&
    countries.map((data) =>
      countriesData.push({
        label: data.name,
        value: data.id,
      })
    );

  countries &&
    countries.length > 0 &&
    countries.map((data) =>
      currencyData.push({
        label: data.currency,
        value: data.id,
      })
    );

  const indianCurrency =
    currencyData &&
    currencyData.length > 0 &&
    currencyData?.find((data) => data.label === "INR");

  states &&
    states.length > 0 &&
    states.map((data) =>
      stateData.push({
        label: data.name,
        value: data.id,
      })
    );

  timezones &&
    timezones.length > 0 &&
    timezones.map((tzone) => {
      timeZoneData.push({
        label: getFormattedTimezone(tzone.offset, tzone.name, tzone.timezone),
        value: tzone.id,
        name: tzone.name,
        timezone: tzone.timezone,
      });
    });

  const handleGetState = (val, data) => {
    dispatch(actionGetStates(val));
    form.setFieldsValue({
      state_id: null,
    });
    setIsStateDisabled(false);
  };

  const FormField = ({
    label,
    name,
    rules,
    type,
    value,
    options,
    component,
    normalize = (val) => val,
    getCompanyDetailLoader = false,
    ...props
  }) => {
    const formattedValue =
      name === "incorporation_date" && value && dayjs(value).isValid()
        ? dayjs(value).format("YYYY-MM-DD")
        : value || "-";

    return (
      <div className="flex items-center text-muted">
        <p className="font-popinsMedium text-md min-w-[12rem]">{label}</p>

        {getCompanyDetailLoader ? (
          <>
            <div className="flex items-center gap-x-4 ">
              <Skeleton.Input
                active
                size="small"
                className="ant-input-cus"
                style={{
                  height: "19px",
                  width: "100px",
                  marginTop: "3px" /*  width: "70%" */,
                }}
              />
            </div>
          </>
        ) : type === "VIEW" ? (
          name === "website" ? (
            <a
              href={value ? formatUrl(value) : "#"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="font-popinsMedium text-md ml-10 min-w-80 text-brand">
                {value ? value : "-"}
              </p>
            </a>
          ) : (
            // VIEW mode
            <p className="font-popinsMedium text-md ml-10 min-w-80 text-black">
              {formattedValue}
            </p>
          )
        ) : (
          // Editable field
          <Form.Item
            className="ml-10 min-w-80"
            name={name}
            rules={rules}
            initialValue={value}
            normalize={normalize}
          >
            {component ? (
              React.createElement(component, {
                ...props,
                value,
                options,
              })
            ) : options ? (
              <Select {...props} value={value}>
                {options.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <Input {...props} value={value} />
            )}
          </Form.Item>
        )}
      </div>
    );
  };

  return (
    // <Spin spinning={getCompanyDetailLoader}>

    <div className="main-card company-input">
      <div className="pt-[0px] pl-[23px] pb-[0px]">
        <div className="flex items-start justify-between">
          <div className="flex items-start"></div>
        </div>

        <div className="mb-[20px]">
          <div className="flex items-start justify-between mt-10">
            <p className="font-popinsMedium text-lg ">
              Company Profile Information
            </p>

            {type === "VIEW" ? (
              <p
                onClick={() => {
                  dispatch(
                    handleActionWithPermission("company", "update", () =>
                      handleEditClick()
                    )
                  );
                }}
                className="font-popinsRegular text-center mr-[3%] ml-3 bg-transparent text-brand md:text-sm text-xs border border-brand py-1.5 px-4 rounded-lg cursor-pointer"
              >
                Edit
              </p>
            ) : (
              <p
                onClick={async () => {
                  if (!updateCompanyDetailLoader) {
                    await form.submit();
                  }
                }}
                className="font-popinsRegular text-center ml-3 mr-[3%] bg-transparent text-brand md:text-sm text-xs border border-brand py-1.5 px-4 rounded-lg cursor-pointer"
              >
                {updateCompanyDetailLoader ? (
                  <Spin spinning={updateCompanyDetailLoader} size="small" />
                ) : (
                  "Save"
                )}
              </p>
            )}
          </div>
          <div>
            <Form
              form={form}
              className="mt-5 space-y-3"
              name="basic"
              scrollToFirstError={true}
              onFinish={onFinish}
              initialValues={{
                industry_id:
                  companyDetail?.industry?.industry_master_id || undefined,
              }}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <FormField
                label="Company Name"
                name="company_name"
                rules={[
                  { required: true, message: "Please enter company name!" },
                ]}
                value={companyDetail?.company_name}
                type={type}
                // value="Vytech Enterprise"
                placeholder="Enter Company Name!"
                disabled
              />

              <FormField
                label="Company Code"
                name="company_code"
                rules={[
                  { required: false, message: "Please enter company code!" },
                ]}
                value={companyDetail?.company_code}
                type={type}
                placeholder="Enter Company Code!"
              />

              <FormField
                label="Website"
                name="website"
                rules={[
                  { required: false, message: "Please Enter Your Website!" },
                  () => ({
                    validator(_, value) {
                      if (!value || urlRegex.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject("Please enter a valid URL");
                    },
                  }),
                ]}
                value={companyDetail?.website}
                type={type}
                placeholder="Enter Website!"
              />

              <FormField
                label="Industry"
                name="industry_id"
                value={companyDetail?.industry?.industry}
                rules={[{ required: false, message: "Please enter industry!" }]}
                type={type}
                options={industryMasterData}
                allowClear
                showSearch
                optionFilterProp="children"
                placeholder="Select Industry!"
              />

              <FormField
                label="Company Size"
                name="company_size"
                rules={[
                  { required: false, message: "Please enter company size!" },
                ]}
                value={companyDetail?.company_size}
                type={type}
                placeholder="Enter Company Size!"
              />

              <FormField
                label="Phone Number"
                name="phone"
                rules={[
                  {
                    required: false,
                    max: 15,
                    message: "phone number cannot be longer than 15 digits",
                  },
                ]}
                value={companyDetail?.phone}
                normalize={(value) => value && value.replace(/[^+\d]/g, "")}
                type={type}
                placeholder="Enter Phone Number!"
              />

              <FormField
                label="Email Address"
                name="email"
                rules={[
                  {
                                    pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,

                    message: "Please enter a valid email!",
                  },
                ]}
                value={companyDetail?.email}
                type={type}
                placeholder="Enter Email Address!"
              />

              <FormField
                label="Fax Number"
                name="fax_number"
                normalize={(value) => value && value.replace(/[^+\d]/g, "")}
                rules={[
                  {
                    required: false,
                    message: "Please enter fax!",
                  },
                ]}
                value={companyDetail?.fax_number}
                type={type}
                placeholder="Enter Fax Number!"
              />

              <FormField
                label="GSTIN"
                name="gst_in"
                rules={[
                  {
                    required: false,
                    pattern:
                      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}[Z]{1}[A-Z0-9]{1}$/,
                    message: "Please enter valid gst number!",
                  },
                ]}
                value={companyDetail?.gst_in}
                type={type}
                placeholder="Enter GstNo!"
              />

              <FormField
                label="CIN"
                name="c_in"
                rules={[
                  {
                    required: false,
                    message: "Please enter cin!",
                  },
                ]}
                value={companyDetail?.c_in}
                type={type}
                placeholder="Enter CIN!"
              />

              <FormField
                label="Currency"
                name="currency_id"
                value={companyDetail?.currency?.currency}
                rules={[
                  { required: false, message: "Please select currency!" },
                ]}
                type={type}
                placeholder="Select Currency!"
                options={[indianCurrency]}
                allowClear
                showSearch
                optionFilterProp="children"
                disabled={!!companyDetail?.currency?.currency}
              />

              <FormField
                label="Pan Number"
                name="pan"
                rules={[
                  {
                    required: false,
                    message: "Please enter pan number!",
                  },
                ]}
                value={companyDetail?.pan}
                type={type}
                placeholder="Enter Pan Number!"
              />

              <FormField
                label="Tan Number"
                name="tan"
                rules={[
                  {
                    required: false,
                    message: "Please enter tan number!",
                  },
                ]}
                value={companyDetail?.tan}
                type={type}
                placeholder="Enter Tan Number!"
              />

              <FormField
                label="Bank Name"
                name="bank_name"
                rules={[
                  {
                    required: false,
                    message: "Please enter bank name!",
                  },
                ]}
                value={companyDetail?.bank_name}
                type={type}
                placeholder="Enter Bank Name!"
              />

              <FormField
                label="Bank Account Number "
                name="bank_account_number"
                normalize={(value) => value && value.replace(/[^+\d]/g, "")}
                rules={[
                  {
                    required: false,
                    message: "Please enter bank account number!",
                  },
                ]}
                value={companyDetail?.bank_account_number}
                type={type}
                placeholder="Enter Bank Account Number!"
              />

              <FormField
                label="IFSC Code"
                name="ifsc_code"
                rules={[
                  {
                    required: false,
                    message: "Please enter ifsc code!",
                  },
                ]}
                value={companyDetail?.ifsc_code}
                type={type}
                placeholder="Enter IFSC Code!"
              />

              <FormField
                label="SWIFT/BIC Code"
                name="swift_bic_code"
                rules={[
                  {
                    required: false,
                    message: "Please enter swift/bic code!",
                  },
                ]}
                value={companyDetail?.swift_bic_code}
                type={type}
                placeholder="Enter Swift/Bic Code!"
              />

              <FormField
                label="Account Type"
                name="bank_account_type"
                value={companyDetail?.bank_account_type}
                rules={[
                  { required: false, message: "Please enter account type!" },
                ]}
                type={type}
                placeholder="Select Account Type!"
                options={[
                  {
                    value: "Saving Account",
                    label: "Saving Account",
                  },
                  {
                    value: "Current Account",
                    label: "Current Account",
                  },
                ]}
                allowClear
                optionFilterProp="children"
              />

              <FormField
                label="Time Zone"
                name="time_zone"
                rules={[
                  { required: false, message: "Please enter account type!" },
                ]}
                type={type}
                component={Select}
                placeholder="Select Account Type!"
                options={timeZoneData}
                value={
                  companyDetail?.timezone
                    ? getFormattedTimezone(
                        companyDetail.timezone?.offset,
                        companyDetail.timezone?.name,
                        companyDetail.timezone?.timezone
                      )
                    : ""
                }
                filterOption={(input, option) =>
                  option.name.toLowerCase().includes(input.toLowerCase()) ||
                  option.timezone.toLowerCase().includes(input.toLowerCase())
                }
                disabled
                allowClear
                showSearch
                optionFilterProp="children"
              />

              <FormField
                label="Primary Contact"
                name="primary_contact_name"
                normalize={(value) => value && value.replace(/[^+\d]/g, "")}
                rules={[
                  {
                    required: false,
                    max: 15,
                    message: "phone number cannot be longer than 15 digits",
                  },
                ]}
                value={companyDetail?.primary_contact_name}
                type={type}
                placeholder="Enter Primary Contact!"
              />

              <FormField
                label="Primary Contact Email"
                name="primary_contact_email"
                rules={[
                  {
                                    pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,

                    message: "Please enter a valid email!",
                  },
                ]}
                value={companyDetail?.primary_contact_email}
                type={type}
                placeholder="Enter Primary Contact Email!"
              />

              <FormField
                label="Legal Entity Name"
                name="legal_entity_name"
                rules={[
                  {
                    required: false,
                    message: "Please enter legal Entity Name!",
                  },
                ]}
                value={companyDetail?.legal_entity_name}
                type={type}
                placeholder="Enter legal Entity Name!"
              />

              <FormField
                label="Company Registration No"
                name="company_registration_number"
                rules={[
                  {
                    required: false,
                    message: "Please enter registration number!",
                  },
                ]}
                value={companyDetail?.company_registration_number}
                type={type}
                placeholder="Enter Company Registration Number!"
              />

              <FormField
                label="Incorporation Date"
                name="incorporation_date"
                type={type}
                value={companyDetail.incorporation_date}
                component={DatePicker}
                className="min-w-80"
              />

              <FormField
                label="Default Checkout Time"
                name="default_auto_checkout_time"
                type={type}
                value={companyDetail.default_auto_checkout_time}
                component={TimePicker}
                format="HH:mm"
                className="min-w-80"
                // form={form} // Make sure to pass the form instance to the component
              />

              <p className="font-popinsMedium text-lg pt-[20px] pb-3">
                Address Information
              </p>

              <FormField
                label="Street Address"
                name="street_address"
                rules={[
                  {
                    required: false,
                    message: "Please enter street address!",
                  },
                ]}
                value={companyDetail?.street_address}
                type={type}
                placeholder="Enter Street Address"
              />

              <FormField
                label="Country"
                name="country_id"
                rules={[
                  { required: false, message: "Please enter your Country!" },
                ]}
                type={type}
                placeholder="Select Country"
                component={Select}
                options={countriesData}
                onClear={() => setCountry(undefined)}
                value={companyDetail.country?.name}
                onSelect={handleGetState}
                disabled={getCountryLoader}
                // filterOption={(input, option) =>
                //   option.label.toLowerCase().includes(input.toLowerCase())
                // }
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                // allowClear
                showSearch
                optionFilterProp="children"
              />
              <FormField
                label="State"
                name="state_id"
                rules={[
                  { required: false, message: "Please enter your State!" },
                ]}
                value={companyDetail.state?.name}
                type={type}
                component={Select}
                options={stateData}
                onClear={() => setState(null)}
                disabled={statesLoader}
                placeholder="Select State"
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                // allowClear
                showSearch
                optionFilterProp="children"
              />

              <FormField
                label="City"
                name="city"
                rules={[
                  {
                    required: false,
                    message: "Please enter city!",
                  },
                ]}
                value={companyDetail?.city}
                type={type}
                placeholder="Enter City"
              />

              <FormField
                label="Postal Code"
                name="postal_code"
                rules={[
                  {
                    required: false,
                    message: "Please enter postal code!",
                  },
                ]}
                value={companyDetail?.postal_code}
                type={type}
                placeholder="Enter Postal Code"
              />

              <FormField
                label="Billing Address"
                name="billing_address"
                value={companyDetail?.billing_address}
                rules={[
                  {
                    required: false,
                    message: "Please enter billing address!",
                  },
                ]}
                type={type}
                placeholder="Enter Billing Address"
                // isTextArea={true}
              />

              <FormField
                label="Shipping Address"
                name="shipping_address"
                value={companyDetail?.shipping_address}
                rules={[
                  {
                    required: false,
                    message: "Please enter shipping address!",
                  },
                ]}
                type={type}
                placeholder="Enter Shipping Address"
                // isTextArea={true}
              />
            </Form>
          </div>
        </div>
      </div>
    </div>
    // </Spin>
  );
};

export default CompanyDetails;
