import {
  CloseCircleOutlined,
  DeleteOutlined,
  LockOutlined,
  StopOutlined,
} from "@ant-design/icons";
import editPrimaryWhite from "../../assets/img/editPrimaryWhite.svg";

import {
  Avatar,
  Button,
  Card,
  DatePicker,
  Dropdown,
  Form,
  Image,
  Input,
  Popconfirm,
  Select,
  Skeleton,
  Spin,
  Tooltip,
  Modal,
  Upload,
  message,
} from "antd";
import ImgCrop from "antd-img-crop";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import callSvg from "../../assets/img/call.svg";
import editWhite from "../../assets/img/editWhite.svg";
import facebookSvg from "../../assets/img/facebook.svg";
import instagramSvg from "../../assets/img/instagram.svg";
import leftArrow from "../../assets/img/leftArrowPrimary.svg";
import linkedinSvg from "../../assets/img/linkedin.svg";
import mailSvg from "../../assets/img/mail.svg";
import twitterSvg from "../../assets/img/twitterx.svg";
import userRound from "../../assets/img/user-round.svg";
import CustomIconText from "../common/CustomIconText";
import {
  actionGetUserDetail,
  actionUpdateUser,
  actionGetCompanyAllUsers,
  actionDeactivateUser,
  actionAddUser,
} from "../../store/services/usersService";
import {
  actionGetRoles,
  actionGetAllRoleProfile,
} from "../../store/services/settingService";
import {
  actionGetCountries,
  actionGetStates,
} from "../../store/services/locationService";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { actionGetTimezones, actionUpdateProfile } from "../../store/services/authService";
import {
  extractFilename,
  formatUrl,
  getFormattedTimezone,
  getFullName,
  renderFileIcon,
} from "../helper/common-function";
import { toast } from "sonner";
import { BASE_URL_UPLOAD } from "../../config/web-config";
import { actionGetProductCategoryMaster } from "../../store/services/productMasterService";
import { handleActionWithPermission, showHPINModal, showVerifyHPINModal } from "../../store/slices/authSlice";
import { actionGetAllZoneMaster } from "../../store/services/generalMasterService";
import { TbFileUpload, TbX } from "react-icons/tb";
import axios from "axios";
import EmailPhoneChangeModal from "./EmailPhoneChangeModal";

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const contactTypeEnum = {
  EMAIL: "email",
  PHONE: "phone"
}

const UpdateUser = () => {
  const [form] = Form.useForm();
  const { currentCompanyUser } = useSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    accountType,
    updateType = false,
    isState = false,
    isCurrentUser
  } = location.state || {};

  const { userId = currentCompanyUser?.id } = useParams();
  const curretUserId = isCurrentUser ? currentCompanyUser?.id : userId
  const {
    getUserDetailLoader,
    userDetail,
    getCompanyAllUserLoader,
    companyAllUsers,
    updateUserLoader,
  } = useSelector((state) => state.users);

  const { getRolesLoader, roles, getAllRoleProfileLoader, roleAllProfile } =
    useSelector((state) => state.setting);

  const { countries, states, getCountryLoader, statesLoader } = useSelector(
    (state) => state.location
  );

  const {
    getAllZoneMasterLoader,
    allZoneMasterData,
  } = useSelector((state) => state.generalMaster);


  console.log(currentCompanyUser,"currentCompanyUser")

  const { productCategoryData, getProductCategoryLoader } = useSelector(
    (state) => state.productMaster
  );

  const { timezones } = useSelector((state) => state.auth);

  const [type, setType] = useState("VIEW");
  const [links, setLinks] = useState("");
  const [updateDataFetchLoader, setUpdateDataFetchLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profilePicUrl, setProfilePicUrl] = useState();
  const [contactType, setContactType] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadFiles, setUploadFiles] = useState({
    aadhar_card_attachment_front: { loading: false, url: null },
    aadhar_card_attachment_back: { loading: false, url: null },
    pan_card_attachment: { loading: false, url: null },
  });

  useEffect(() => {
    if (curretUserId) {
      dispatch(actionGetUserDetail(curretUserId));
    }
  }, [curretUserId]);

  useEffect(() => {
    if (updateType) {
      checkAndUpdateData();
    }
  }, [updateType]);

  useEffect(() => {
    if (userDetail) {
      checkAndUpdateData();
    }
  }, [userDetail]);

  const checkAndUpdateData = () => {
    if (updateType && userDetail && type === "VIEW") {
      updateButtonClickData();
    }
  };

  useEffect(() => {
    if (userDetail && userDetail.profile_pic) {
      setProfilePicUrl(userDetail.profile_pic);
    }
  }, [userDetail]);

  const updateButtonClickData = async () => {
    const newLinks = {};
    setUpdateDataFetchLoader(true);
    await Promise.all([
      dispatch(actionGetRoles()),
      dispatch(actionGetTimezones()),
      dispatch(actionGetAllRoleProfile()),
      dispatch(actionGetCompanyAllUsers()),
      dispatch(actionGetCountries()),
      dispatch(actionGetProductCategoryMaster()),
      dispatch(actionGetAllZoneMaster())
    ]);

    // Fetch states if country ID exists
    if (userDetail?.country?.id) {
      await dispatch(actionGetStates(userDetail.country.id));
    }

    const linkeInData = userDetail.social_profiles?.find(
      (d) => d.type == "Linkedin"
    );
    const instagramData = userDetail.social_profiles?.find(
      (d) => d.type == "Instagram"
    );
    const facebookData = userDetail.social_profiles?.find(
      (d) => d.type == "Facebook"
    );
    const twitterData = userDetail.social_profiles?.find(
      (d) => d.type == "X (Twitter)"
    );

    if (linkeInData) {
      newLinks.linkedIn = true;
    }
    if (instagramData) {
      newLinks.instagram = true;
    }
    if (facebookData) {
      newLinks.facebook = true;
    }
    if (twitterData) {
      newLinks.twitter = true;
    }
    setLinks(newLinks);

    if (userDetail) {
      await form.setFieldsValue({
        first_name: userDetail.first_name ? userDetail.first_name : "",
        last_name: userDetail.last_name ? userDetail.last_name : "",
        email: userDetail.email ? userDetail.email : "",
        role_id: userDetail.role ? userDetail.role.id : "",
        role_profile_id: userDetail.roleprofile.role_profile
          ? userDetail.roleprofile.role_profile.id
          : "",
        reports_to_id: userDetail.reports_to ? userDetail.reports_to.id : "",
        phone: `${userDetail.phone_country_code ? userDetail.phone_country_code : ""
          }${userDetail.phone ? userDetail.phone : ""}`,
        website: userDetail.website ? userDetail.website : "",
        fax: userDetail.fax ? userDetail.fax : "",
        dob: userDetail.dob ? dayjs(userDetail.dob) : "",
        street: userDetail.street ? userDetail.street : "",
        city: userDetail.city ? userDetail.city : "",
        state_id: userDetail.state ? userDetail.state.id : null,
        zip_code: userDetail.zip_code ? userDetail.zip_code : "",
        country_id: userDetail.country ? userDetail.country.id : null,
        language: userDetail.language ? userDetail.language : "",
        country_locale: userDetail.country_locale
          ? userDetail.country_locale
          : "",
        date_format: userDetail.date_format ? userDetail.date_format : "",
        time_format: userDetail.time_format ? userDetail.time_format : "",
        timezone: userDetail.timezone ? userDetail.timezone.id : null,
        number_format: userDetail.number_format ? userDetail.number_format : "",
        linkedIn: linkeInData?.url,
        instagram: instagramData?.url,
        facebook: facebookData?.url,
        twitter: twitterData?.url,
        designation: userDetail.designation ? userDetail.designation : "",
        emp_code: userDetail.emp_code ? userDetail.emp_code : "",
        aadhar_card_number: userDetail.aadhar_card_number ? userDetail.aadhar_card_number : "",
        pan_card_number: userDetail.pan_card_number ? userDetail.pan_card_number : "",
        zone_id: userDetail.zone?.zone_master_id ? userDetail.zone?.zone_master_id : null,
        material_category_ids:
          userDetail.material_category_access &&
          userDetail.material_category_access.map(
            (item) => item.material_category.product_category_master_id
          ),
      });

      setUploadFiles({
        aadhar_card_attachment_front: userDetail.aadhar_card_attachment_front ? { url: userDetail.aadhar_card_attachment_front, loading: false } : { url: null, loading: false },
        aadhar_card_attachment_back: userDetail.aadhar_card_attachment_back ? { url: userDetail.aadhar_card_attachment_back, loading: false } : { url: null, loading: false },
        pan_card_attachment: userDetail.pan_card_attachment ? { url: userDetail.pan_card_attachment, loading: false } : { url: null, loading: false },
        // Add any other file fields if needed
      });
    }
    setType("EDIT");
    setUpdateDataFetchLoader(false);
  };

  const urlRegex = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\/\w\-.?&=%]*)?$/i;

  const roleData = [];
  const roleProfileData = [];
  const reportsToData = [];
  const countriesData = [];
  const stateData = [];
  const timeZoneData = [];
  const materialCategoryData = [];

  roles &&
    roles.map((data) =>
      roleData.push({
        label: data.name,
        value: data.id,
      })
    );
  roleAllProfile &&
    roleAllProfile.map((data) =>
      roleProfileData.push({
        label: data.name,
        value: data.id,
      })
    );
  companyAllUsers &&
    companyAllUsers.map((data) =>
      reportsToData.push({
        label: (
          <span>
            <Avatar
              src={data.profile_pic}
              style={{
                verticalAlign: "middle",
              }}
              className=" mr-2"
            />

            {`${data.first_name ? data.first_name : ""} ${data.last_name ? data.last_name : ""
              }`}
          </span>
        ),
        value: data.id,
        main:
          data.first_name && data.last_name !== null
            ? data?.first_name + " " + data?.last_name
            : data.first_name,
      })
    );

  countries &&
    countries.map((data) =>
      countriesData.push({
        label: data.name,
        value: data.id,
      })
    );

  states &&
    states.map((data) =>
      stateData.push({
        label: data.name,
        value: data.id,
      })
    );

  timezones &&
    timezones.map((tzone) => {
      timeZoneData.push({
        label: getFormattedTimezone(tzone.offset, tzone.name, tzone.timezone),
        value: tzone.id,
        name: tzone.name,
        timezone: tzone.timezone,
      });
    });

  productCategoryData &&
    productCategoryData.map((data) =>
      materialCategoryData.push({
        label: data.category_name,
        value: data.product_category_master_id,
      })
    );

  const zoneOptions = useMemo(
    () =>
      allZoneMasterData?.length > 0
        ? allZoneMasterData.map((zone) => ({
          label: zone.zone_name,
          value: zone.zone_master_id,
        }))
        : [],
    [allZoneMasterData]
  );


  const onFinish = (values) => {
    const social_profiles = [];
    if (values.linkedIn) {
      social_profiles.push({
        type: "Linkedin",
        url: values.linkedIn,
      });
    }
    if (values.instagram) {
      social_profiles.push({
        type: "Instagram",
        url: values.instagram,
      });
    }
    if (values.facebook) {
      social_profiles.push({
        type: "Facebook",
        url: values.facebook,
      });
    }
    if (values.twitter) {
      social_profiles.push({
        type: "X (Twitter)",
        url: values.twitter,
      });
    }

    const fileUrl = extractFilename(profilePicUrl && profilePicUrl);

    const data = {
      ...values,
      social_profiles,
      dob: values.dob ? dayjs(values.dob).format("YYYY-MM-DD") : "",
      profile_pic: fileUrl,
      reports_to_id: values.reports_to_id
        ? parseInt(values.reports_to_id)
        : values.reports_to_id,
    };

    const req = {
      emp_code: values.emp_code ? values.emp_code : null,
      aadhar_card_number: values.aadhar_card_number ? values.aadhar_card_number : null,
      pan_card_number: values.pan_card_number ? values.pan_card_number : null,
      zone_id: values.zone_id ? values.zone_id : null,
      country_id
        : values.country_id ? values.country_id : null,
      state_id: values?.country_id || values?.country_id !== null && values.state_id ? values.state_id : null,
      aadhar_card_attachment_front: uploadFiles.aadhar_card_attachment_front?.url
        ? extractFilename(uploadFiles.aadhar_card_attachment_front?.url)
        : null,
      aadhar_card_attachment_back: uploadFiles.aadhar_card_attachment_back?.url
        ? extractFilename(uploadFiles.aadhar_card_attachment_back?.url)
        : null,
      pan_card_attachment: uploadFiles.pan_card_attachment?.url
        ? extractFilename(uploadFiles.pan_card_attachment?.url)
        : null,

      ...Object.fromEntries(
        Object.entries(data).filter(
          ([_, v]) =>
            v !== undefined &&
            v !== null &&
            !(typeof v === "string" && v.trim() === "") &&
            (!Array.isArray(v) || v.length > 0)
        )
      ),
    };


    isCurrentUser ?
      dispatch(
        actionUpdateProfile({
          req,
          setType,
        })
      ) : dispatch(
        actionUpdateUser({
          req,
          Id: curretUserId,
          setType,
          accountType: accountType ? accountType : "",
        })
      );
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const FormField = ({
    label,
    name,
    rules,
    type,
    component,
    value,
    children,
    options,

    ...props
  }) => {
    const isLoading = getUserDetailLoader || false;
    // isDisabled = isCurrentUser ? 

    return (
      // <Spin spinning={isLoading}>
      <div className="flex items-center text-muted">
        <p className="font-popinsMedium text-md min-w-28">{label}</p>

        {isLoading ? (
          // Shimmer effect during loading
          <>
            <div className="flex items-center gap-x-4 ">
              <Skeleton.Input
                active
                size="small"
                className="ant-input-cus"
                style={{
                  height: "19px",
                  width: "100px",
                  marginTop: "3px",
                }}
              />
            </div>
          </>
        ) : type === "VIEW" ? (
          name === "website" ? (
            <a
              href={formatUrl(value)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="font-popinsMedium text-md ml-10 min-w-80 text-brand">
                {value ? value : "-"}
              </p>
            </a>
          ) : name === "material_category_ids" && Array.isArray(value) ? (
            <p className="font-popinsMedium text-md ml-10 min-w-80 text-black">
              {value.length ? value.join(", ") : "-"}
            </p>
          ) : (
            <p className="font-popinsMedium text-md ml-10 min-w-80 text-black">
              {value || "-"}
            </p>
          )
        ) : (
          <Form.Item
            className="ml-10 min-w-80"
            name={name}
            rules={rules}
            initialValue={name === "dob" && value ? dayjs(value) : value}
          >
            {component
              ? React.createElement(component, {
                ...props,
                value,
                options,
                disabledDate: (current) =>
                  name === "dob" &&
                  current &&
                  current > dayjs().subtract(18, "years"),
              })
              : children || <Input {...props} value={value} />}
          </Form.Item>
        )}
      </div>
      // </Spin>
    );
  };

  const handleChangeUserPic = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      /* return; */
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setProfilePicUrl(info.file.response.url);
        toast.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      toast.error(`${info.file.name} file upload failed.`);
    }
  };

  const handleMenuClick = ({ key }) => {
    setLinks((prevLinks) => ({
      ...prevLinks,
      [key]: true,
    }));
  };

  const handleRemove = (key) => {
    setLinks((prevLinks) => ({
      ...prevLinks,
      [key]: false,
    }));
  };

  const handleGetState = (val, data) => {
    dispatch(actionGetStates(val));
    form.setFieldsValue({
      state_id: null,
    });
  };

  const handleInvitation = () => {
    const req = [
      {
        email: userDetail.email,
        role_id: userDetail?.role.id,
        role_profile_id: userDetail?.roleprofile?.role_profile?.id,
        reports_to_id: userDetail?.reports_to?.id,
        is_resend: true,
      },
    ];
    dispatch(actionAddUser({ req }));
  };


  const handleUploadFile = async (fieldName, e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadFiles((prev) => ({
        ...prev,
        [fieldName]: { ...prev[fieldName], loading: true },
      }));

      try {
        const formData = new FormData();
        formData.append('file', file);

        // Replace this URL with your API endpoint
        const response = await axios.post(BASE_URL_UPLOAD, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + localStorage.getItem('crmWebToken'),
          },
        });


        setUploadFiles((prev) => ({
          ...prev,
          [fieldName]: {
            loading: false,
            url: response.data.url || URL.createObjectURL(file),
          },
        }));
      } catch (error) {
        console.error('File upload failed:', error);
        setUploadFiles((prev) => ({
          ...prev,
          [fieldName]: { loading: false, url: null },
        }));
      }
    }
  };


  const onUpdateModalCancel = () => {
    setIsModalOpen(false)
    setContactType("");
  }


  return (
    <>
      <section className="main-section profile-card">
        <div className="mb-4">
          <CustomIconText
            image={userRound}
            title=" Personal Setting"
            backArrow={accountType !== "OWN_ACCOUNT" || isState}
          />
        </div>
        <Card className="main-card mt-5 w-full border-[#DDDAE9] p-2">
          <div className="flex items-start justify-between">
            <div className="flex items-start">
              {getUserDetailLoader ? (
                <>
                  {Array.from({ length: 1 }).map((_, index) => (
                    <Skeleton.Avatar
                      key={index}
                      active
                      size={100}
                      shape="square"
                      className="flex-shrink-0"
                      style={{ marginRight: "10px" }}
                    />
                  ))}
                </>
              ) : (
                <div className="relative h-[80px] w-[80px]  bg-white rounded-lg">
                  {profilePicUrl ? (
                    <Image
                      height={80}
                      width={80}
                      className="!h-full w-full rounded-lg"
                      style={{ objectFit: "cover", borderRadius: "0.75rem" }}
                      src={profilePicUrl}
                      preview={false}
                    />
                  ) : (
                    <Avatar
                      shape="square"
                      style={{
                        height: 80,
                        borderRadius: "0.75rem",
                        width: 80,
                        fontSize: 20,
                      }}
                      src={userDetail?.profile_pic}
                      size={30}
                    />
                  )}

                  {type === "EDIT" && (
                    <ImgCrop rotationSlider aspect={1 / 1} fillColor="white">
                      <Upload
                        name="file"
                        maxCount={1}
                        showUploadList={false}
                        action={BASE_URL_UPLOAD}
                        headers={{
                          Authorization:
                            "Bearer " + localStorage.getItem("crmWebToken"),
                        }}
                        onChange={handleChangeUserPic}
                      >
                        <div className="absolute bottom-0 right-0 px-1.5 py-1 bg-brand rounded-tl-xl rounded-br-lg cursor-pointer flex">
                          <Image
                            width={15}
                            height={15}
                            src={editWhite}
                            className="rounded-lg"
                            preview={false}
                          />
                        </div>
                      </Upload>
                    </ImgCrop>
                  )}
                </div>
              )}

              <div className="ml-5">
                {/* Full Name */}
                <div className="flex items-center">
                  {getUserDetailLoader ? (
                    <Skeleton.Input
                      active
                      size="small"
                      style={{ width: "200px" }}
                    />
                  ) : (
                    <p className="font-popinsMedium">
                      {getFullName(userDetail.first_name, userDetail.last_name)}
                    </p>
                  )}
                </div>

                {/* Designation and Company */}
                <div>
                  {getUserDetailLoader ? (
                    <Skeleton.Input
                      active
                      size="small"
                      style={{ width: "300px", marginTop: "8px" }}
                    />
                  ) : (
                    <p className="font-popinsRegular text-muted">
                      {`${userDetail.designation ? userDetail.designation : "-"
                        } at ${userDetail.company?.company_name}`}
                    </p>
                  )}
                </div>

                {/* Email and Phone */}
                <div className="mt-3 flex items-center">
                  {/* Email */}
                  <div className="flex items-center">
                    {getUserDetailLoader ? (
                      <Skeleton.Input
                        active
                        size="small"
                        style={{ width: "150px" }}
                      />
                    ) : (
                      <>
                        <Image
                          className="cursor-pointer"
                          src={mailSvg}
                          alt="email"
                          height={15}
                          width={15}
                          preview={false}
                        />
                        <p className="font-popinsRegular ml-2">
                          {userDetail.email ? userDetail.email : ""}
                        </p>
                      </>
                    )}
                  </div>

                  {/* Phone */}
                  <div className="flex items-center ml-5">
                    {getUserDetailLoader ? (
                      <Skeleton.Input
                        active
                        size="small"
                        style={{ width: "150px" }}
                      />
                    ) : (
                      <>
                        <Image
                          className="cursor-pointer"
                          src={callSvg}
                          alt="phone"
                          height={15}
                          width={15}
                          preview={false}
                        />
                        <p className="font-popinsRegular ml-2">{`${userDetail.phone && userDetail.phone_country_code
                          ? "+" + userDetail.phone_country_code
                          : ""
                          } ${userDetail.phone ? userDetail.phone : "-"}`}</p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-x-2.5">
              {isCurrentUser &&
                <p
                onClick={() => {
                  if (currentCompanyUser?.is_hpin_available === false) {
                    dispatch(showHPINModal()); 
                  } else {
                    dispatch(showVerifyHPINModal(({ customParam: "show_set_hpin_modal" }))); 
                  }
                }}
                  className={`${getUserDetailLoader ? "cursor-not-allowed" : 'cursor-pointer'}
                  font-popinsRegular text-center bg-transparent text-brand md:text-sm text-xs border border-brand py-1.5 px-4 rounded-lg`}
                >
                  {currentCompanyUser?.is_hpin_available === false ? "Set HPIN" : "Change HPIN"}
                </p>
              }
              {accountType !== "OWN_ACCOUNT" &&
                (userDetail.status === "ACTIVE" ||
                  userDetail.status === "DEACTIVE") && (
                  <Popconfirm
                    title={
                      userDetail.status === "ACTIVE" ? "Deactivate" : "Activate"
                    }
                    description={`Are you sure to ${userDetail.status === "ACTIVE" ? "deactivate" : "activate"
                      } this user?`}
                    onConfirm={() => {
                      const req = {
                        users: [userDetail.id],
                      };
                      userDetail.status === "ACTIVE" &&
                        dispatch(actionDeactivateUser({ req }));
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <p
                      className={`font-popinsRegular text-center bg-transparent ${userDetail.status == "ACTIVE"
                        ? "text-green-500 border-green-500"
                        : userDetail.status == "DEACTIVE"
                          ? "text-orange-400 border-orange-400"
                          : "text-black border-black"
                        } md:text-sm text-xs border  py-1.5 px-3 rounded-lg cursor-pointer`}
                    >
                      {userDetail.status}
                    </p>
                  </Popconfirm>
                )}

              {userDetail.status !== "ACTIVE" &&
                userDetail.status !== "DEACTIVE" && (
                  <p
                    className={`font-popinsRegular text-center bg-transparent ${userDetail.status == "INVITED"
                      ? "text-blue-700 border-blue-700"
                      : userDetail.status == "REJECT_INVITATION"
                        ? "text-red-500 border-red-500"
                        : "text-black border-black"
                      } md:text-sm text-xs border  py-1.5 px-3 rounded-lg`}
                  >
                    {userDetail.status == "REJECT_INVITATION"
                      ? "REJECT"
                      : userDetail.status
                        ? userDetail.status
                        : "-"}
                  </p>
                )}

              {userDetail.status !== "ACTIVE" && (
                <p
                  onClick={handleInvitation}
                  className={`${getUserDetailLoader ? "cursor-not-allowed" : 'cursor-pointer'} font-popinsRegular text-center bg-transparent text-brand md:text-sm text-xs border border-brand py-1.5 px-4 rounded-lg`}
                >
                  Resend Invitation
                </p>
              )}
              {/*   {accountType !== "OWN_ACCOUNT" && (
              <p className="font-popinsRegular text-center ml-3 bg-transparent text-red-500 md:text-sm text-xs border border-redtext-red-500 py-1.5 px-4 rounded-lg cursor-pointer">
                Delete
              </p>
            )}
 */}
              {type == "VIEW" ? (
                <p
                  onClick={() => {
                    dispatch(
                      handleActionWithPermission("company-users", "update", () =>
                        updateButtonClickData()
                      )
                    );
                  }}
                  className="font-popinsRegular text-center bg-transparent text-brand md:text-sm text-xs border border-brand py-1.5 px-4 rounded-lg cursor-pointer"
                >
                  {updateDataFetchLoader ?
                    <Spin spinning={updateDataFetchLoader} size="small" /> : "Edit"
                  }</p>
              ) : (
                <p
                  onClick={async () => {
                    if (!updateUserLoader) {
                      await form.submit();
                    }
                  }}
                  className="font-popinsRegular text-center bg-transparent text-brand md:text-sm text-xs border border-brand py-1.5 px-4 rounded-lg cursor-pointer"
                >
                  {updateUserLoader ? (
                    <Spin spinning={updateUserLoader} size="small" />
                  ) : (
                    "Save"
                  )}
                </p>
              )}
            </div>
          </div>
          <div>
            <p className="font-popinsMedium text-lg mt-10">User Information</p>
            <Form
              form={form}
              className="mt-5 space-y-3"
              name="basic"
              scrollToFirstError={true}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <FormField
                label="First Name"
                name="first_name"
                rules={[
                  { required: true, message: "Please enter your First Name!" },
                ]}
                value={userDetail.first_name}
                type={type}
                placeholder="Enter First Name!"
              />
              <FormField
                label="Last Name"
                name="last_name"
                rules={[
                  { required: true, message: "Please enter your Last Name!" },
                ]}
                value={userDetail.last_name}
                type={type}
                placeholder="Enter Last Name!"
              />
              <div className="flex items-center gap-x-2">
                <FormField
                  label="Email"
                  disabled={false}
                  type={type}
                  value={userDetail.email}
                  suffix={<LockOutlined />}
                  placeholder="Enter email!"
                />
                {/*  {isCurrentUser && (
                  <p onClick={() => {
                    setIsModalOpen(true);
                    setContactType(contactTypeEnum.EMAIL)
                  }} className="text-brand  cursor-pointer font-popinsMedium">
                    Change Email
                  </p>
                )} */}
              </div>
              <FormField
                label="Role"
                name="role_id"
                rules={[{ required: true, message: "Please Select your Role!" }]}
                type={type}
                disabled={isCurrentUser || getRolesLoader}
                component={Select}
                options={roleData}
                value={userDetail.role?.name}
                placeholder="Select Role"
                allowClear
                showSearch
                optionFilterProp="children"
              />
              <FormField
                label="Profile"
                name="role_profile_id"
                rules={[
                  { required: true, message: "Please Select your Profile!" },
                ]}
                type={type}
                component={Select}
                options={roleProfileData}
                value={userDetail?.roleprofile?.role_profile?.name}
                placeholder="Select Profile"
                disabled={isCurrentUser || getAllRoleProfileLoader}
                allowClear
                showSearch
                optionFilterProp="children"
              />

              <FormField
                label="Report To"
                name="reports_to_id"
                rules={[
                  { required: true, message: "Please Select your Report To!" },
                ]}
                type={type}
                component={Select}
                options={reportsToData}
                disabled={isCurrentUser || getCompanyAllUserLoader}

                value={`${userDetail.reports_to?.first_name ?? ""} ${userDetail.reports_to?.last_name ?? ""
                  }`.trim()}
                placeholder="Select Report To"
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label?.props?.children[1] ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
              <FormField
                label="Phone"
                name="phone"
                type={type}
                suffix={<LockOutlined />}
                value={`${userDetail.phone && userDetail.phone_country_code
                  ? "+" + userDetail.phone_country_code
                  : ""
                  }${userDetail.phone ? " " + userDetail.phone : ""}`}
                disabled
              />
              <FormField
                label="Website"
                name="website"
                rules={[
                  // { required: true, message: "Please Enter Your Website!" },
                  () => ({
                    validator(_, value) {
                      if (!value || urlRegex.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject("Please enter a valid URL");
                    },
                  }),
                ]}
                type={type}
                value={userDetail.website}
                placeholder="Enter Website!"
              />
              <FormField
                label="Fax"
                name="fax"
                value={userDetail.fax}
                type={type}
                placeholder="Enter Fax!"
              />
              <FormField
                label="Date Of Birth"
                name="dob"
                type={type}
                value={userDetail.dob}
                component={DatePicker}
                className="min-w-80"
              />
              <FormField
                label="Designation"
                name="designation"
                type={type}
                value={userDetail.designation}
                placeholder="Enter Designation!"
              />
              <FormField
                disabled={isCurrentUser}
                label="Emp Code"
                name="emp_code"
                type={type}
                value={userDetail.emp_code}
                placeholder="Enter EMP Code!"
              />
              <FormField
                label="Zone"
                name="zone_id"
                type={type}
                component={Select}
                options={zoneOptions}
                disabled={isCurrentUser || getAllZoneMasterLoader}
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                value={userDetail.zone?.zone_name}
                placeholder="Select Zone!"
              />

              <FormField
                maxLength={12}
                normalize={(value) => (value ? value.replace(/[^0-9]/g, '') : '')}
                label="Aadhar Card No"
                name="aadhar_card_number"
                type={type}
                value={userDetail.aadhar_card_number}
                placeholder="Enter Aadhar Card Number"
              />

              {['aadhar_card_attachment_front', 'aadhar_card_attachment_back'].map((field) => {
                return (
                  <div className="flex items-center mb-3 text-muted" key={field}>
                    <p className="font-popinsMedium text-md min-w-28">
                      {field === "aadhar_card_attachment_front" ? "Aadhar Card (Front)" :
                        field === "aadhar_card_attachment_back" ? "Aadhar Card (Back)" : ""}
                    </p>
                    <div className="ml-10 min-w-80">
                      {type === "EDIT" ? (
                        uploadFiles[field].url ? (
                          uploadFiles[field].loading ? (
                            <Spin size="small" />
                          ) : (

                            <div style={{ position: 'relative', borderRadius: 7, width: 100, border: '1px solid #d4d4d4' }}>
                              <Image
                                src={uploadFiles[field].url}
                                alt={field}
                                width={100}
                                height={40}
                                style={{ objectFit: 'cover', borderRadius: "6px" }}
                              />
                              <label
                                onClick={(e) => document.getElementById(field).click()}
                                className=" px-1 py-1 border-0 absolute right-0 bottom-0 bg-brand rounded-tl-[6px] rounded-br-[6px] cursor-pointer flex"
                              >
                                <Image
                                  width={15}
                                  height={15}
                                  src={editPrimaryWhite}
                                  className="rounded-lg"
                                  preview={false}
                                />
                              </label>
                            </div>
                          )
                        ) : (
                          <label htmlFor={field}>
                            <Button
                              loading={uploadFiles[field].loading}
                              className="upload-button text-end"
                              icon={<TbFileUpload color="#4567ff" className="h-[18px] w-[18px] font-bold text-[#4567ff]" />}
                              onClick={(e) => document.getElementById(field).click()}
                            >
                              <p className="text-[#4567ff]">{uploadFiles[field].loading ? "Uploading..." : "Upload"}</p>
                            </Button>
                          </label>
                        )
                      ) : getUserDetailLoader ? <Skeleton.Node paragraph={false} active style={{ width: 160, height: 50 }}>
                        <p className="opacity-0">Hello</p>
                      </Skeleton.Node>
                        : userDetail?.[field] ? (
                          <Image
                            src={userDetail?.[field]}
                            alt={field}
                            width={100}
                            height={40}
                            style={{ objectFit: 'cover', borderRadius: "6px" }}
                          />
                        ) : (
                          <p style={{ color: '#999', fontStyle: 'italic' }}>No file uploaded</p>
                        )}
                      <input
                        type="file"
                        id={field}
                        accept=".png,.jpg,.jpeg,.pdf,.doc"
                        onChange={(e) => handleUploadFile(field, e)}
                        style={{ display: 'none' }}
                      />
                    </div>
                  </div>
                );
              })}

              <FormField
                label="PAN Card No"
                normalize={(value) =>
                  value ? value.toUpperCase().replace(/[^A-Z0-9]/g, '') : ''
                }
                rules={[
                  () => ({
                    validator(_, value) {

                      const normalizedValue = value ? value.toUpperCase().replace(/[^A-Z0-9]/g, "") : "";

                      // PAN Card Regex validation if there's a value
                      const panCardRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

                      if (value && !panCardRegex.test(normalizedValue)) {
                        return Promise.reject("Please enter a valid PAN Card number");
                      }

                      // No validation needed if the value is empty
                      return Promise.resolve();
                    },
                  })
                ]}
                name="pan_card_number"
                type={type}
                value={userDetail.pan_card_number}
                placeholder="Enter PAN Card Number"
              />

              {['pan_card_attachment'].map((field) => (
                <div className="flex items-center mb-3 text-muted" key={field}>
                  <p className="font-popinsMedium text-md min-w-28">PAN Card</p>
                  <div className="ml-10 min-w-80">
                    {type === "EDIT" ? (
                      uploadFiles[field].url ? (
                        uploadFiles[field].loading ? (
                          <Spin size="small" />
                        ) : (
                          <div style={{ position: 'relative', borderRadius: 7, width: 100, border: '1px solid #d4d4d4' }}>
                            <Image
                              src={uploadFiles[field].url}
                              alt={field}
                              width={100}
                              height={40}
                              style={{ objectFit: 'cover', borderRadius: "6px" }}
                            />
                            <label
                              onClick={(e) => document.getElementById(field).click()}
                              className=" px-1 py-1 border-0 absolute right-0 bottom-0 bg-brand rounded-tl-[6px] rounded-br-[6px] cursor-pointer flex"
                            >
                              <Image
                                width={15}
                                height={15}
                                src={editPrimaryWhite}
                                className="rounded-lg"
                                preview={false}
                              />
                            </label>
                          </div>
                        )
                      ) : (
                        <label htmlFor={field}>
                          <Button
                            loading={uploadFiles[field].loading}
                            className="upload-button text-end"
                            icon={<TbFileUpload color="#4567ff" className="h-[18px] w-[18px] font-bold text-[#4567ff]" />}
                            onClick={(e) => document.getElementById(field).click()}
                          >
                            <p className="text-[#4567ff]">{uploadFiles[field].loading ? "Uploading..." : "Upload"}</p>
                          </Button>
                        </label>
                      )
                    ) : userDetail?.[field] ? (
                      <Image
                        src={userDetail?.[field]}
                        alt={field}
                        width={100}
                        height={40}
                        style={{ objectFit: 'cover', borderRadius: "6px" }}
                      />
                    ) : (
                      <p style={{ color: '#999', fontStyle: 'italic' }}>No file uploaded</p>
                    )}
                    <input
                      type="file"
                      id={field}
                      accept=".png,.jpg,.jpeg,.pdf,.doc"
                      onChange={(e) => handleUploadFile(field, e)}
                      style={{ display: 'none' }}
                    />
                  </div>
                </div>
              )
              )}







              <div className="flex items-center text-muted">
                <p className="font-popinsMedium text-md min-w-28">Added By</p>

                {
                  getUserDetailLoader ?
                    <>
                      <div className="flex items-center gap-x-4 ">
                        <Skeleton.Input
                          active
                          size="small"
                          className="ant-input-cus"
                          style={{
                            height: "19px",
                            width: "100px",
                            marginTop: "3px",
                          }}
                        />
                      </div>
                    </> : <p className="font-popinsMedium ml-10">
                      <span className="text-black">
                        {userDetail.added_by?.first_name
                          ? userDetail.added_by?.first_name
                          : ""}{" "}
                        {userDetail.added_by?.last_name
                          ? userDetail.added_by?.last_name
                          : ""}
                      </span>
                      <span className="ml-2">
                        {dayjs(userDetail?.created_at).format(
                          "ddd, DD MMM YYYY HH:mm A"
                        )}
                      </span>
                    </p>
                }
              </div>
              <FormField
                className="!max-w-80"
                label="Material Access"
                name="material_category_ids"

                mode="multiple"
                rules={[{ required: false, message: "Please Select Material!" }]}
                type={type}
                maxTagCount="responsive"
                maxTagPlaceholder={(omittedValues) => (
                  <Tooltip
                    overlayStyle={{
                      pointerEvents: "none",
                    }}
                    title={omittedValues.map(({ label }) => label).join(", ")}
                  >
                    <span>+{omittedValues.length} more</span>
                  </Tooltip>
                )}
                component={Select}
                options={materialCategoryData}
                value={
                  userDetail.material_category_access &&
                  userDetail.material_category_access.map(
                    (item) => item.material_category.category_name
                  )
                }
                loading={getProductCategoryLoader}
                disabled={isCurrentUser || getProductCategoryLoader}
                placeholder="Select Material"
                allowClear
                showSearch
                optionFilterProp="children"
              />
              <p className="font-popinsMedium text-lg py-3">
                Address Information
              </p>

              <FormField
                label="Street"
                name="street"
                value={userDetail.street}
                type={type}
                placeholder="Enter Street!"
              />

              <FormField
                label="Country"
                name="country_id"
                type={type}
                component={Select}
                options={countriesData}
                value={userDetail.country?.name}
                onSelect={handleGetState}
                disabled={getCountryLoader}
                placeholder="Select Country"
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                allowClear
                showSearch
                optionFilterProp="children"

                onClear={() => form.setFieldsValue({ state_id: null, country_id: null })}
              />
              <FormField
                label="State"
                name="state_id"
                value={userDetail.state?.name}
                type={type}
                component={Select}
                options={stateData}
                disabled={statesLoader}
                placeholder="Select State"
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                allowClear
                showSearch
                optionFilterProp="children"
              />
              <FormField
                label="City"
                name="city"
                // rules={[{ required: true, message: "Please enter your City!" }]}
                value={userDetail.city}
                type={type}
                placeholder="Enter City!"
              />
              <FormField
                label="Zip Code"
                name="zip_code"
                // rules={[
                //   { required: true, message: "Please enter your Zip Code!" },
                // ]}
                value={userDetail.zip_code}
                type={type}
                placeholder="Enter Zip Code!"
              />

              <div className="flex items-center" id="social-profile">
                <p className="font-popinsMedium text-lg py-3">Social Profiles</p>
                {type === "EDIT" && (
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: "twitter",
                          label: (
                            <div className="flex items-center">
                              <Image
                                className="cursor-pointer"
                                src={twitterSvg}
                                alt="Twitter"
                                height={20}
                                width={20}
                                preview={false}
                              />
                              <p className="font-popinsRegular ml-2">
                                X (Twitter)
                              </p>
                            </div>
                          ),
                          disabled: links.twitter,
                        },
                        {
                          key: "linkedIn",
                          label: (
                            <div className="flex items-center">
                              <Image
                                className="cursor-pointer"
                                src={linkedinSvg}
                                alt="LinkedIn"
                                height={20}
                                width={20}
                                preview={false}
                              />
                              <p className="font-popinsRegular ml-2">LinkedIn</p>
                            </div>
                          ),
                          disabled: links.linkedIn,
                        },
                        {
                          key: "instagram",
                          label: (
                            <div className="flex items-center">
                              <Image
                                className="cursor-pointer"
                                src={instagramSvg}
                                alt="Instagram"
                                height={20}
                                width={20}
                                preview={false}
                              />
                              <p className="font-popinsRegular ml-2">Instagram</p>
                            </div>
                          ),
                          disabled: links.instagram,
                        },
                        {
                          key: "facebook",
                          label: (
                            <div className="flex items-center">
                              <Image
                                className="cursor-pointer"
                                src={facebookSvg}
                                alt="Facebook"
                                height={20}
                                width={20}
                                preview={false}
                              />
                              <p className="font-popinsRegular ml-2">Facebook</p>
                            </div>
                          ),
                          disabled: links.facebook,
                        },
                      ],
                      onClick: handleMenuClick,
                    }}
                    placement="bottom"
                    arrow
                  >
                    <p className="font-popinsRegular ml-10 text-brand cursor-pointer">
                      + Add
                    </p>
                  </Dropdown>
                )}
              </div>
              {type === "VIEW" &&
                userDetail.social_profiles?.map((data, index) => (
                  <div className="flex items-center text-muted" key={index}>
                    <p className="font-popinsMedium text-md min-w-28">
                      {data.type}
                    </p>
                    <a href={data.url} target="_blank" rel="noopener noreferrer">
                      <p className="font-popinsMedium text-md ml-10 min-w-80 text-brand">
                        {data.url}
                      </p>
                    </a>
                  </div>
                ))}
              {type === "EDIT" &&
                Object.entries(links).map(
                  ([platform, link]) =>
                    link && (
                      <div className="flex items-center" key={link}>
                        <FormField
                          label={
                            platform.charAt(0).toUpperCase() + platform.slice(1)
                          }
                          name={platform}
                          rules={[
                            {
                              required: true,
                              message: `Please Enter ${platform.charAt(0).toUpperCase() +
                                platform.slice(1)
                                } Link`,
                            },
                            () => ({
                              validator(_, value) {
                                if (!value || urlRegex.test(value)) {
                                  return Promise.resolve();
                                }
                                return Promise.reject("Please enter a valid URL");
                              },
                            }),
                          ]}
                          type={type}
                          placeholder={`Please Enter ${platform.charAt(0).toUpperCase() + platform.slice(1)
                            } Link`}
                        />
                        <CloseCircleOutlined
                          onClick={() => handleRemove(platform)}
                          className="ml-2 text-red-500 cursor-pointer text-lg"
                        />
                      </div>
                    )
                )}
              <p className="font-popinsMedium text-lg py-3">Locale Information</p>
              <FormField
                label="Language"
                name="language"
                placeholder="Select Language"
                value={userDetail.language}
                type={type}
                suffix={<LockOutlined />}
                disabled
              />
              <FormField
                label="Country"
                name="country_locale"
                value={userDetail.country_locale}
                placeholder="Select Country"
                type={type}
                suffix={<LockOutlined />}
                disabled
              />
              <FormField
                label="Date Format"
                name="date_format"
                value={userDetail.date_format}
                placeholder="Select Date Format"
                type={type}
                suffix={<LockOutlined />}
                disabled
              />
              <FormField
                label="Time Format"
                name="time_format"
                value={userDetail.time_format}
                placeholder="Select Date Format"
                type={type}
                suffix={<LockOutlined />}
                disabled
              />

              <FormField
                label="Time Zone"
                name="timezone"
                type={type}
                component={Select}
                options={timeZoneData}
                value={
                  userDetail.timezone
                    ? getFormattedTimezone(
                      userDetail.timezone?.offset,
                      userDetail.timezone?.name,
                      userDetail.timezone?.timezone
                    )
                    : ""
                }
                filterOption={(input, option) =>
                  option.name.toLowerCase().includes(input.toLowerCase()) ||
                  option.timezone.toLowerCase().includes(input.toLowerCase())
                }
                placeholder="Select Time Zone"
                disabled
                allowClear
                showSearch
                optionFilterProp="children"
              />
              <FormField
                label="Number Format"
                name="number_format"
                placeholder="Select Number Format"
                value={userDetail.number_format}
                type={type}
                suffix={<LockOutlined />}
                disabled
              />
              <p className="font-popinsMedium text-lg py-3">Clusters</p>
              <div className="flex items-center text-muted">
                <p className="font-popinsMedium text-md min-w-28">Member In</p>
                <p className="font-popinsMedium ml-10 text-brand">-</p>
              </div>
            </Form>
          </div>
          {type == "EDIT" && (
            <div className="flex items-center justify-end">
              <p
                onClick={async () => {
                  if (!updateUserLoader) {
                    await form.submit();
                  }
                }}
                className="font-popinsRegular text-center ml-3 bg-transparent text-brand md:text-sm text-xs border border-brand py-1.5 px-4 rounded-lg cursor-pointer"
              >
                {updateUserLoader ? (
                  <Spin spinning={updateUserLoader} size="small" />
                ) : (
                  "Save"
                )}
              </p>
            </div>
          )}
        </Card>
      </section>
      <Modal
        maskClosable={false}
        title={null}
        open={isModalOpen}
        onCancel={onUpdateModalCancel}
        footer={null}
        afterClose={onUpdateModalCancel}
      >
        <EmailPhoneChangeModal
          setContactType={setContactType}
          contactType={contactType}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen} />
      </Modal>
    </>

  );
};

export default UpdateUser;
