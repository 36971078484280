import { createSlice } from "@reduxjs/toolkit";
import { actionGetNotifications } from "../services/notificationService";

const notificationSlice = createSlice({
  name: "customField",
  initialState: {
    getNotificationsLoader: false,
    notifications: [],
    allNotificationsCount: 0,
  },

  reducers: {},
  extraReducers: (buider) => {
    buider
      // get notification State
      .addCase(actionGetNotifications.pending, (state) => {
        state.getNotificationsLoader = true;
      })
      .addCase(actionGetNotifications.fulfilled, (state, action) => {
        state.getNotificationsLoader = false;
        state.notifications = action.payload.data;
        state.allNotificationsCount = action.payload.total_records;
      })
      .addCase(actionGetNotifications.rejected, (state) => {
        state.getNotificationsLoader = false;
      });
  },
});

export default notificationSlice.reducer;
