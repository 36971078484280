

import { notification } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { messaging, requestPermission } from "../../config/firebase";
import { onMessage } from "firebase/messaging";


const NotificationListener = ({ appendData, setOffset }) => {
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification({ stack: 3 });
  const { currentCompanyUser } = useSelector((state) => state.auth);

  useEffect(() => {
    // Request Notification Permission
    requestPermission();

    // Create a BroadcastChannel to prevent duplicate notifications
    const broadcastChannel = new BroadcastChannel("firebase_notifications");

    const unsubscribe = onMessage(messaging, (payload) => {

      const { notification: notifData, fcmOptions } = payload || {};
      const { title, body } = notifData || {};
      const { link } = fcmOptions || {};



      if (currentCompanyUser && currentCompanyUser?.id) {
        appendData(true);
        setOffset(0);
      }

      api.info({
        message: title,
        description: body,
        duration: 10,
        onClick: () => {
          if (link) {
            window.open(link, "_blank");
          }
        },
      });
    });

    return () => {
      unsubscribe();
    };
  }, [api, currentCompanyUser, dispatch]);

  return contextHolder;
};

export default NotificationListener;
