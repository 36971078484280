import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  ConfigProvider,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { IoIosRefresh } from "react-icons/io";
import { RiDownloadLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { toast } from "sonner";
import * as XLSX from "xlsx";
import expenseVoucherIcon from "../../../assets/img/expense-voucher.svg";
import expensefirstdetail from "../../../assets/img/expensefirstdetail.svg";
import {
  actionChangeExpenseVoucherStatus,
  actionClearAllExpenseVouchers,
  actionDeleteExpenseVoucher,
  actionGetAllExpenseVouchers,
  actionGetExpenseVoucherDetails,
  actionGetExpenseVouchers,
} from "../../../store/services/financeService";
import PageTitle from "../../common/PageTitle";
import SortIcon from "../../common/SortIcon";
import TooltipWrapper from "../../common/TooltipWrapper";
import { green } from "@ant-design/colors";
import { actionGetCompanyAllUsers } from "../../../store/services/usersService";
import { getFullName } from "../../helper/common-function";
import { handleActionWithPermission } from "../../../store/slices/authSlice";
import usePermission from "../../../hooks/use-permission";
import SkeletonTable from "../../common/SkeletonTable";
import CommonTable from "../../common/CommonTable";
import CommonPagination from "../../common/CommonPagination";

const ExpenseVoucher = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const offsetRef = useRef(0);
  const [searchValue, setSearchValue] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [exportAll, setExportAll] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentOption, setPaymentOption] = useState(null);
  const [isDropdown, setIsDropdown] = useState(false);
  const [approvedBys, setApprovedBys] = useState([]);
  const [submitters, setSubmitters] = useState([]);
  const [expenseId, setExpenseId] = useState("");
  const [lastSearch, setLastSearch] = useState("");
  const [pageSize, setPageSize] = useState(20);
  const navigate = useNavigate();
  const { hasPermission } = usePermission();

  const {
    getExpenseVouchersLoader,
    expenseVoucherData,
    expenseVoucherDataCount,
    allExpenseVoucherData,
    getAllExpenseVouchersLoader,
    deleteExpenseVoucherLoader,
    changeStatusLoader,
    expenseVoucherDetailData,
  } = useSelector((state) => state.finance);

  const { companyAllUsers, getCompanyAllUserLoader } = useSelector(
    (state) => state.users
  );

  const { currentCompanyUser } = useSelector((state) => state.auth);

  const getApiCall = (
    offset = 0,
    limit = pageSize,
    search = "",
    orderByColumn = "",
    order = "",
    approve_by = [],
    submitter = []
  ) => {
    dispatch(
      actionGetExpenseVouchers({
        offset,
        limit,
        orderByColumn,
        order,
        search,
        approve_by,
        submitter,
      })
    );
  };

  useEffect(() => {
    dispatch(actionGetCompanyAllUsers());
    getApiCall(offsetRef.current, pageSize);
  }, [dispatch]);

  const expenseVoucherStatusItems = [
    {
      key: "submitted",
      label: "Submitted",
      value: "Submitted",
    },
    {
      key: "approved",
      label: "Approved",
      value: "Approved",
    },
    {
      key: "unsubmitted",
      label: "Unsubmitted",
      value: "Unsubmitted",
    },
    {
      key: "rejected",
      label: "Rejected",
      value: "Rejected",
    },
    {
      key: "reimbursed",
      label: "Reimbursed",
      value: "Reimbursed",
    },
  ];

  const handleChangeStatus = (key, id) => {
    const selected = expenseVoucherStatusItems.find(
      (item) => item.key === key.key
    );

    const values = {
      status: selected.value,
      expense_voucher_id: [id],
    };

    if (selected.value.toLowerCase() === "reimbursed") {
      setIsModalOpen(true);
      setExpenseId(id);
      setIsDropdown(true);
      dispatch(actionGetExpenseVoucherDetails(id));
    } else {
      dispatch(actionChangeExpenseVoucherStatus({ values }));
    }
  };

  const statusColors = {
    submitted: "blue",
    approved: "green",
    unsubmitted: "default",
    rejected: "red",
    reimbursed: green[7],
    "pending approval": "orange",
    "awaiting approval": "magenta",
    default: "default",
  };

  const getStatusTextAndColor = (status, data) => {
    if (status.toLowerCase() === "submitted") {
      return data.approve_by?.id === currentCompanyUser?.id
        ? { text: "PENDING APPROVAL", color: statusColors["pending approval"] }
        : {
          text: "AWAITING APPROVAL",
          color: statusColors["awaiting approval"],
        };
    }

    return {
      text: status.toUpperCase(),
      color: statusColors[status.toLowerCase()] || statusColors.default,
    };
  };

  const users =
    companyAllUsers && companyAllUsers.length > 0
      ? companyAllUsers.map((data) => ({
        text: (
          <span>
            <Avatar
              src={data.profile_pic}
              style={{
                verticalAlign: "middle",
              }}
              className=" mr-2"
            />

            {getFullName(data.first_name, data.last_name)}
          </span>
        ),
        value: data.id,
      }))
      : [];

  const columns = [
    {
      title: "CH No.",
      key: "ch_no",
      width: 100,
      dataIndex: "expense_voucher_id",
      fixed: "left",
      render: (text) =>
        <div
          onClick={() => {
            dispatch(
              handleActionWithPermission("expense-voucher", "read", () =>
                navigate(`/view-expense-voucher/${text}`, {
                  state: { id: text },
                })
              )
            );
          }}
          className="flex text-brand items-center cursor-pointer"
        >
          {text ?? "-"}
        </div>,
    },
    {
      title: "Voucher No",
      dataIndex: "voucher_no",
      key: "voucher_no",
      fixed: "left",
      sorter: true,
      sortIcon: () => <SortIcon />,
      width: 200,
      ellipsis: true,
      render: (text, data) => (
        <div
          onClick={() => {
            dispatch(
              handleActionWithPermission("expense-voucher", "read", () =>
                navigate(`/view-expense-voucher/${data.expense_voucher_id}`, {
                  state: { id: data.expense_voucher_id },
                })
              )
            );
          }}
          className="flex items-center text-brand cursor-pointer"
        >
          {text ? text : "-"}
        </div>
      ),
    },
    {
      title: "Voucher Date",
      dataIndex: "voucher_date",
      key: "voucher_date",
      width: 200,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span>
          {data.voucher_date
            ? dayjs(data.voucher_date).format("DD-MM-YYYY")
            : "-"}
        </span>
      ),
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      // width: 200,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (data.city ? data.city : "-"),
    },
    {
      title: "Expenses",
      dataIndex: "expenses",
      key: "expenses",
      width: 100,
      ellipsis: true,
      render: (text, data) => data.expense_voucher_items_count,
    },
    {
      title: "Total",
      dataIndex: "expense_voucher_items_amount_count",
      key: "expense_voucher_items_amount_count",
      width: 200,
      ellipsis: true,
      render: (text) => {
        const symbol = currentCompanyUser?.company?.currency
          ? currentCompanyUser.company.currency.currency_symbol
          : "";

        return (
          <>
            <span
              style={{
                fontFamily: "Arial, sans-serif",
              }}
            >
              {symbol}
            </span>
            <span style={{ marginLeft: "4px" }}>{text || ""}</span>
          </>
        );
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 200,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => {
        const status = data.status || "";

        const excludedStatusMap = {
          approved: ["approved", "unsubmitted", "submitted", "rejected"],
          submitted: ["submitted", "unsubmitted"],
          "pending approval": [
            "unsubmitted",
            "submitted",
            "pending approval",
            "awaiting approval",
          ],
          "awaiting approval": [
            "unsubmitted",
            "submitted",
            "pending approval",
            "awaiting approval",
          ],
          unsubmitted: [
            "unsubmitted",
            "approved",
            "rejected",
            "reimbursed",
            "pending approval",
            "awaiting approval",
          ],
          rejected: ["rejected", "unsubmitted", "submitted", "approved"],
        };

        const filteredExpenseVoucherStatusItems =
          expenseVoucherStatusItems.filter((item) => {
            const currentStatus = status.toLowerCase();
            const exclusions = excludedStatusMap[currentStatus] || [
              currentStatus,
            ];

            const isExcludedStatus = exclusions.includes(item.key);

            const isApprover =
              data.approve_by && data.approve_by.id === currentCompanyUser.id;
            const shouldIncludeApprover =
              currentStatus.toLowerCase() === "submitted" ? isApprover : true;

            return !isExcludedStatus && shouldIncludeApprover;
          });

        const isApprover =
          data.approve_by && data.approve_by.id === currentCompanyUser.id;
        const isSubmitter =
          data.submitter && data.submitter.id === currentCompanyUser.id;

        return status ? (
          <div id="dropw">
            {status.toLowerCase() === "reimbursed" ||
              status.toLowerCase() === "rejected" ||
              (data.status.toLowerCase() === "submitted" && !isApprover) ||
              (data.status.toLowerCase() === "unsubmitted" && !isSubmitter) ||
              (data.status.toLowerCase() === "awaiting approval" &&
                !isApprover) ||
              ((data.status.toLowerCase() === "approved" ||
                data.status.toLowerCase() === "rejected") &&
                !isApprover) ? (
              <Tag color={getStatusTextAndColor(status, data).color}>
                {getStatusTextAndColor(status, data).text}
              </Tag>
            ) : (
              <Dropdown
                menu={{
                  items: filteredExpenseVoucherStatusItems,
                  onClick: (key) =>
                    handleChangeStatus(key, data.expense_voucher_id),
                }}
                placement="bottom"
                arrow={{
                  pointAtCenter: true,
                }}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Tag
                    color={getStatusTextAndColor(status, data).color}
                  // className="py-1 px-2.5 text-sm font-popinsRegular"
                  >
                    {getStatusTextAndColor(status, data).text}
                  </Tag>
                </a>
              </Dropdown>
            )}
          </div>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Approver",
      dataIndex: "approve_by",
      key: "approve_by",
      width: 200,
      ellipsis: true,
      filteredValue: approvedBys,
      filters: users,

      render: (text) => (
        <div className="flex items-center gap-x-3">
          <Avatar size={30} src={text?.profile_pic} />
          <span className="text-[#7C7C7C] text-[15px]">{text?.full_name}</span>
        </div>
      ),
    },
    {
      title: "Submitter",
      dataIndex: "submitter",
      key: "submitter",
      filters: users,
      filteredValue: submitters,

      width: 200,
      ellipsis: true,
      render: (text, data) => (
        <div className="flex items-center gap-x-3">
          <Avatar size={30} src={text?.profile_pic} />
          <div className="flex flex-col gap-y-1">
            <span className="text-[#7C7C7C] text-[15px]">
              {text?.full_name}
            </span>
            <span className="text-[#7C7C7C] text-[11px]">
              on : {dayjs(data.created_at).format("DD-MM-YYYY")}
            </span>
          </div>
        </div>
      ),
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 100,
      fixed: "right",
      ellipsis: true,
      render: (text, data) => {
        const isUnsubmitted = data.status.toLowerCase() === "unsubmitted";
        const isSubmitter = data.submitter.id === currentCompanyUser?.id;

        return (
          <>
            <Dropdown
              disabled={!isUnsubmitted && !isSubmitter}
              id="custom-drop"
              menu={{
                items: [
                  isUnsubmitted && {
                    label: <span>Edit</span>,
                    key: "Edit",
                    icon: <EditOutlined />,
                    onClick: () => {
                      dispatch(
                        handleActionWithPermission(
                          "expense-voucher",
                          "update",
                          () =>
                            navigate(`/update-expense-voucher`, {
                              state: data.expense_voucher_id,
                            })
                        )
                      );
                    },
                    disabled: data.status === "Reimbursed",
                  },
                  isUnsubmitted &&
                  isSubmitter && {
                    label: (
                      <Popconfirm
                        okButtonProps={{
                          loading: changeStatusLoader,
                        }}
                        placement="topLeft"
                        description={`Are you sure to change status to submit?`}
                        onConfirm={() => {
                          changeStatus("Submitted", [
                            data.expense_voucher_id,
                          ]);
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <div
                          className="flex items-center"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <CheckCircleOutlined
                            style={{ fontSize: 12, color: "green" }}
                          />
                          <span
                            style={{
                              marginLeft: 8,
                              color: "green",
                            }}
                          >
                            Submit
                          </span>
                        </div>
                      </Popconfirm>
                    ),
                    key: "change-status",
                  },

                  isSubmitter && {
                    label: hasPermission("expense-voucher", "delete") ? (
                      <Popconfirm
                        okButtonProps={{
                          loading: deleteExpenseVoucherLoader,
                        }}
                        placement="topLeft"
                        description={`Are you sure to delete this expense?`}
                        onConfirm={() => {
                          dispatch(
                            actionDeleteExpenseVoucher(data.expense_voucher_id)
                          );
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <div
                          className="flex items-center"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <DeleteOutlined
                            style={{ fontSize: 12, color: "#ff4d4f" }}
                          />
                          <span
                            style={{
                              marginLeft: 8,
                              color: "#ff4d4f",
                            }}
                          >
                            Delete
                          </span>
                        </div>
                      </Popconfirm>
                    ) : (
                      <div
                        className="flex items-center"
                        onClick={() => {
                          dispatch(
                            handleActionWithPermission(
                              "expense-voucher",
                              "delete"
                            )
                          );
                        }}
                      >
                        <DeleteOutlined
                          style={{ fontSize: 12, color: "#ff4d4f" }}
                        />
                        <span
                          style={{
                            marginLeft: 8,
                            color: "#ff4d4f",
                          }}
                        >
                          Delete
                        </span>
                      </div>
                    ),
                    key: "Delete",
                  },
                ].filter(Boolean),
              }}
            >
              <EllipsisOutlined
                className={` ${!isUnsubmitted && !isSubmitter ? "cursor-not-allowed" : ""
                  }`}
              />
            </Dropdown>
          </>
        );
      },
    },
  ];

  useMemo(() => { }, [expenseVoucherData]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
    setIsDropdown(false);
    setExpenseId("");
  };

  useEffect(() => {
    const trimmedSearch = searchValue?.trim();
    if (trimmedSearch !== "" && trimmedSearch !== lastSearch) {
      const timer = setTimeout(() => {
        dispatch(
          actionGetExpenseVouchers({
            offset: offsetRef.current,
            limit: pageSize,
            search: trimmedSearch,
          })
        );
        setLastSearch(trimmedSearch);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [searchValue, lastSearch]);

  const handleSearchChange = (e) => {
    offsetRef.current = 0;
    setSearchValue(e.target.value);
    if (e.target.value === "") {
      dispatch(
        actionGetExpenseVouchers({
          offset: offsetRef.current,
          limit: pageSize,
          search: e.target.value,
        })
      );
    }
  };

  const handleBlurAndEnter = (e) => {
    if (searchValue) {
      offsetRef.current = 0;
      dispatch(
        actionGetExpenseVouchers({
          offset: offsetRef.current,
          limit: pageSize,
          search: e.target.value,
        })
      );
    }
  };

  const handleRefresh = () => {
    dispatch(
      actionGetExpenseVouchers({
        offset: offsetRef.current,
        limit: pageSize,
        search: searchValue,
      })
    );
  };

  const exportAllToExcel = async () => {
    const data =
      (await allExpenseVoucherData) &&
      allExpenseVoucherData.length > 0 &&
      allExpenseVoucherData.map((data) => ({
        "Voucher No": data?.voucher_no ? data?.voucher_no : "-",
        "Voucher Date": data.voucher_date ? data.voucher_date : "-",
        City: data.city ? data.city : "-",
        Expenses: data.expense_voucher_items
          ? data.expense_voucher_items.length
          : "-",
        "Total Amount": data.expense_voucher_items_amount_count
          ? data.expense_voucher_items_amount_count
          : "-",
        Status: data.status ? data.status : "-",
        Approver: data.approve_by ? data.approve_by.full_name : "-",
        Submitter: data.submitter ? data.submitter.full_name : "-",
      }));
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Expense Vouchers");
    // Generate the Excel file and initiate the download
    XLSX.writeFile(workbook, "expense-vouchers.xlsx");
    dispatch(actionClearAllExpenseVouchers({ setExportAll }));
  };

  useEffect(() => {
    if (
      exportAll &&
      allExpenseVoucherData &&
      allExpenseVoucherData.length > 0
    ) {
      exportAllToExcel();
    }
  }, [allExpenseVoucherData]);

  const onClickDownloadMenu = () => {
    dispatch(actionGetAllExpenseVouchers());
    setExportAll(true);
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
            ? "ASC"
            : "",
    };
    setApprovedBys(filters.approve_by);
    setSubmitters(filters.submitter || []);

    getApiCall(
      offsetRef.current,
      pageSize,
      searchValue,
      sorterParams.field,
      sorterParams.order,
      filters.approve_by,
      filters.submitter
    );
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 15 },
    },
  };

  const changeStatus = (newStatus, rows) => {
    const filteredRows = rows.filter((rowId) => {
      const matchingRow = expenseVoucherData.find(
        (data) => data.expense_voucher_id === rowId
      );
      return matchingRow && matchingRow.status !== newStatus;
    });

    if (filteredRows.length > 0) {
      const values = {
        status: newStatus,
        expense_voucher_id: filteredRows,
      };

      dispatch(actionChangeExpenseVoucherStatus({ values }));
    } else {
      toast.error(
        "No rows to update. All selected vouchers already have the specified status."
      );
    }
  };

  const selectedItems =
    expenseVoucherData?.filter((item) =>
      selectedRowKeys.includes(item.expense_voucher_id)
    ) || [];

  const total = selectedItems.reduce((sum, item) => {
    const amount = parseFloat(item.expense_voucher_items_amount_count) || 0;
    return sum + amount;
  }, 0);

  const totalAmount = total.toFixed(2);

  const handleChangeStatusReimbush = (values) => {
    const req = {
      status: "Reimbursed",
      expense_voucher_id: isDropdown ? [expenseId] : selectedRowKeys,
      expense_voucher_reimbursed: {
        reimbursed_date: dayjs(values.reimbursed_date).format("YYYY-MM-DD"),
        paid_via: values.paid_via,
        paid_via_remark: values.paid_via_remark,
        reference: values.reference,
        note: values.note,
      },
    };

    dispatch(actionChangeExpenseVoucherStatus({ values: req, setIsModalOpen }));
  };

  const getVouchersByStatus = useMemo(() => {
    if (!expenseVoucherData?.length || !selectedRowKeys?.length) {
      return {};
    }

    return expenseVoucherData.reduce((acc, data) => {
      if (selectedRowKeys.includes(data.expense_voucher_id)) {
        const status = data.status.toLowerCase();
        if (!acc[status]) {
          acc[status] = [];
        }
        acc[status].push(data);
      }
      return acc;
    }, {});
  }, [expenseVoucherData, selectedRowKeys]);

  const isApprover = useMemo(() => {
    if (!expenseVoucherData?.length || !selectedRowKeys?.length) {
      return false;
    }

    return expenseVoucherData.some(
      (data) =>
        selectedRowKeys.includes(data.expense_voucher_id) &&
        data.approve_by?.id === currentCompanyUser?.id
    );
  }, [expenseVoucherData, selectedRowKeys, currentCompanyUser]);

  const isSubmitter = useMemo(() => {
    if (!expenseVoucherData?.length || !selectedRowKeys?.length) {
      return false;
    }

    return expenseVoucherData.some(
      (data) =>
        selectedRowKeys.includes(data.expense_voucher_id) &&
        data.submitter?.id === currentCompanyUser?.id
    );
  }, [expenseVoucherData, selectedRowKeys, currentCompanyUser]);

  const isExistsSubmittedData = useMemo(() => {
    return (
      (expenseVoucherData &&
        expenseVoucherData.length > 0 &&
        expenseVoucherData.filter(
          (data) =>
            selectedRowKeys.includes(data.expense_voucher_id) &&
            data.status.toLowerCase() === "pending approval"
        )) ||
      []
    );
  }, [expenseVoucherData, selectedRowKeys]);

  const isApprovedData = useMemo(() => {
    return (
      (expenseVoucherData &&
        expenseVoucherData.length > 0 &&
        expenseVoucherData.filter(
          (data) =>
            selectedRowKeys.includes(data.expense_voucher_id) &&
            data.status.toLowerCase() === "approved"
        )) ||
      []
    );
  }, [expenseVoucherData, selectedRowKeys]);

  return (
    <section className="main-wrapper main-table-container ">

      <ConfigProvider
        theme={{
          components: {
            Checkbox: {
              colorPrimary: "#6883FD",
              colorPrimaryHover: "#6883FD",
            },
          },
        }}
      >
        <CommonTable
          loading={
            getExpenseVouchersLoader ||
            changeStatusLoader ||
            getAllExpenseVouchersLoader
          }
          isCheckbox={true}
          content={
            selectedRowKeys.length > 0 &&
            ((getVouchersByStatus["unsubmitted"]?.length > 0 && isSubmitter) ||
              (isApprover && isExistsSubmittedData?.length > 0) ||
              (isApprover &&
                (isApprovedData?.length > 0 ||
                  isExistsSubmittedData?.length > 0)) ? (
              <div className="flex items-center gap-x-3 h-full border-r px-3 border-[#d9d9d9] bg-white">
                {getVouchersByStatus["unsubmitted"]?.length > 0 &&
                  isSubmitter && (
                    <TooltipWrapper title={"Submit"}>
                      <Popconfirm
                        okButtonProps={{ loading: changeStatusLoader }}
                        placement="topLeft"
                        title="Approve"
                        description="Are you sure to submit All expense vouchers?"
                        onConfirm={() =>
                          changeStatus("Submitted", selectedRowKeys)
                        }
                        okText="Yes"
                        cancelText="No"
                      >
                        <span className="text-yellow-500 cursor-pointer border-[.8px] border-yellow-500 text-[12px] rounded-[5px] py-[8px] px-[8px]">
                          Submit
                        </span>
                      </Popconfirm>
                    </TooltipWrapper>
                  )}

                {isApprover && isExistsSubmittedData?.length > 0 && (
                  <>
                    <TooltipWrapper title={"Approve"}>
                      <Popconfirm
                        okButtonProps={{ loading: changeStatusLoader }}
                        placement="topLeft"
                        title="Approve"
                        description="Are you sure to approve All expense vouchers?"
                        onConfirm={() =>
                          changeStatus("Approved", selectedRowKeys)
                        }
                        okText="Yes"
                        cancelText="No"
                      >
                        <span className="text-green-500 cursor-pointer border-[.8px] border-green-500 text-[12px] rounded-[5px] py-[8px] px-[8px]">
                          Approve
                        </span>
                      </Popconfirm>
                    </TooltipWrapper>

                    <TooltipWrapper title={"Reject"}>
                      <Popconfirm
                        okButtonProps={{ loading: changeStatusLoader }}
                        placement="topLeft"
                        title="Reject"
                        description="Are you sure to reject All expense vouchers?"
                        onConfirm={() =>
                          changeStatus("Rejected", selectedRowKeys)
                        }
                        okText="Yes"
                        cancelText="No"
                      >
                        <span className="text-red-500 cursor-pointer border-[.8px] border-red-500 text-[12px] rounded-[5px] py-[8px] px-[8px]">
                          Reject
                        </span>
                      </Popconfirm>
                    </TooltipWrapper>
                  </>
                )}

                {isApprover &&
                  (isApprovedData?.length > 0 ||
                    isExistsSubmittedData?.length > 0) && (
                    <span
                      onClick={showModal}
                      className="text-[#0299C8] cursor-pointer border-[.8px] border-[#0299C8] text-[12px] rounded-[5px] py-[8px] px-[8px]"
                    >
                      Record Reimbursement
                    </span>
                  )}
              </div>
            ) : null)
          }
          moduleKey="expense-voucher"
          columns={columns}
          data={expenseVoucherData}
          rowKey={"expense_voucher_id"}
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
            columnWidth: 50,
            getCheckboxProps: (record) => ({
              disabled:
                record.status.toLowerCase() === "reimbursed" ||
                record.status.toLowerCase() === "rejected",
              name: record.status,
            }),
          }}
          onChange={handleSortChange}
          handleRefresh={handleRefresh}
          handleSearch={handleSearchChange}
          handleBlurAndPressEnter={handleBlurAndEnter}
          search={searchValue}
          onClickDownloadMenu={onClickDownloadMenu}
          total={expenseVoucherDataCount}
          offset={offsetRef.current}
          title="Expense Vouchers"
          pageSize={pageSize}
          buttonText="Add Expense/s"
          buttonLink="/add-expense-voucher"
          resource="expense-voucher"
          pageTitle={<PageTitle
            image={expenseVoucherIcon}
            margin={false}
            rightIcon={false}
            title="Expense Vouchers"
          />}
          handlePaginationChange={(page, pageSize) => {
            offsetRef.current = page - 1;
            setPageSize(pageSize);
            dispatch(
              actionGetExpenseVouchers({
                offset: offsetRef.current,
                limit: pageSize,
                search: searchValue,
              })
            );
          }}
        />
      </ConfigProvider>


      <Modal
        centered
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={false}
        width={600}
        title=""
        open={isModalOpen}
      >
        <div className="flex items-center">
          <img src={expensefirstdetail} alt="Beat Master" />
          <h1 className="text-[#6883FD] text-[20px] ml-[8px] mt-[5px] font-medium">
            Record Reimbursement
          </h1>
        </div>
        <Form
          labelAlign="left"
          {...formItemLayout}
          form={form}
          onFinish={handleChangeStatusReimbush}
          layout="horizontal"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            // name="beat_name"
            label="Reimbursement Amount"
            className="mb-[12px] text-[17px]"
          >
            <div className="ml-3 text-[25px]">
              <span>
                {`${currentCompanyUser.company?.currency !== null
                  ? currentCompanyUser.company?.currency.currency_symbol
                  : ""
                  } `}
              </span>{" "}
              {isDropdown
                ? expenseVoucherDetailData &&
                expenseVoucherDetailData?.expense_voucher_items_amount_count
                : totalAmount}
            </div>
          </Form.Item>
          <Divider />
          <Form.Item
            name="reimbursed_date"
            label="Reimbursed on"
            className="text-[17px] font-popinsMedium"
            rules={[
              {
                required: true,
                message: "Please enter reimbursed on!",
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              placeholder="Enter reimbursed on"
              className="rounded-[10px]"
            />
          </Form.Item>

          <Form.Item
            name="paid_via"
            label="Paid Via"
            className="text-[17px] font-popinsMedium"
            rules={[
              {
                required: true,
                message: "Please enter paid via!",
              },
            ]}
          >
            <Select
              allowClear
              placeholder="Select Paid Via"
              options={[
                { label: "Cash", value: "Cash" },
                {
                  label: "Bank Transfer",
                  value: "Bank Transfer",
                },
                { label: "Credit Card", value: "Credit Card" },
                { label: "Debit Card", value: "Debit Card" },
                { label: "Check", value: "Check" },
                {
                  label: "Mobile Payment",
                  value: "Mobile Payment",
                },
                {
                  label: "Direct Deposit",
                  value: "Direct Deposit",
                },
                {
                  label: "Company Account",
                  value: "Company Account",
                },
                { label: "Gift Card", value: "Gift Card" },
                { label: "Other", value: "Other" },
              ]}
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item
            name="reference"
            label="Reference#"
            className="text-[17px] font-popinsMedium"
            rules={[
              {
                required: true,
                message: "Please enter reference!",
              },
            ]}
          >
            <Input placeholder="Enter Reference" className="rounded-[10px]" />
          </Form.Item>

          <Form.Item
            name="note"
            label="Note"
            className="text-[17px] font-popinsMedium"
          >
            <Input.TextArea
              placeholder="Enter Note"
              className="rounded-[10px]"
            />
          </Form.Item>

          <Form.Item
            name="paid_via_remark"
            label="Remark"
            className="text-[17px] font-popinsMedium"
          >
            <Input.TextArea
              placeholder="Enter remark"
              className="rounded-[10px]"
            />
          </Form.Item>
          <div className="flex justify-center mt-[40px]">
            <Form.Item className="mb-0">
              <Button
                type="button"
                className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Form.Item>
            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  type="primary"
                  className="root-btn min-h-[45px] min-w-[120px]"
                  loading={changeStatusLoader}
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
    </section>
  );
};

export default ExpenseVoucher;
