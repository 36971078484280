import React, { useState, useRef, useEffect } from "react";

export default function OtpInputForSixDigit({ onOtpChange,code = ["", "", "", "", "", ""],setCode = () => {}  }) {
    const inputRefs = useRef([]);

    useEffect(() => {
        // Call the callback function whenever code changes
        onOtpChange(code.join(""));
    }, [code, onOtpChange]);


    const handleChange = (e, index) => {
        const value = e.target.value;
        if (/^\d*$/.test(value) && index >= 0 && index <= 5) { 
            const newCode = [...code];
            newCode[index] = value.slice(-1); // Take only the last digit entered
            setCode(newCode);

            // Move focus to the next input field
            if (value !== "" && index < 5 && inputRefs.current[index + 1]) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && index > 0 && code[index] === "") {
            // Move focus to the previous input field on Backspace press
            inputRefs.current[index - 1].focus();
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pasteData = e.clipboardData.getData("text/plain").trim();
        if (pasteData.length === 6) { // Adjusted for 6 digits
            const newCode = [...pasteData.split("").slice(0, 6)];
            setCode(newCode);

            // Move focus to the first input field after paste
            if (inputRefs.current[0]) {
                inputRefs.current[0].focus();
            }
        }
    };

    const inputs = Array.from({ length: 6 }, (_, index) => (
        <input
            key={index}
            aria-controls="false"
            ref={(el) => (inputRefs.current[index] = el)}
            type="number"
            maxLength="1"
            value={code[index]}
            onChange={(e) => handleChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onPaste={(e) => handlePaste(e, index)}
            className="border border-[#dddae9] w-[14%] rounded-lg outline-none text-center mr-2 h-[45px]" 
        />
    ));

    return inputs;
}
