import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Image,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Select,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import plusIcon from "../../../assets/img/add-button.svg";
import deletevent from "../../../assets/img/deleteevent.svg";
import editedevent from "../../../assets/img/editedevent.svg";
import editevent from "../../../assets/img/editevent.svg";
import productsubcategory from "../../../assets/img/productsubcategory.svg";
import {
  actionAddProductSubCategoryMaster,
  actionClearAllProductSubCategory,
  actionDeleteProductSubCategoryMaster,
  actionEditProductSubCategoryMaster,
  actionGetAllProductSubCategoryMaster,
  actionGetProductCategoryMaster,
  actionGetProductSubCategoryMaster,
  actionGetSingleProductSubCategoryMaster,
} from "../../../store/services/productMasterService";
import MasterSearchbar from "../../common/MasterSearchbar";
import SortIcon from "../../common/SortIcon";
import { isValidSearchString } from "../../helper/common-function";
import PageTitleForMasterData from "../../common/PageTitleForMasterData";
import usePermission from "../../../hooks/use-permission";
import { handleActionWithPermission } from "../../../store/slices/authSlice";
import SkeletonTable from "../../common/SkeletonTable";
import CommonPagination from "../../common/CommonPagination";
import MasterCommonTable from "../../common/MasterCommonTable";

const ProductSubCategory = ({ setSelectItem }) => {
  const [editableForm] = Form.useForm();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    getProductCategoryLoader,
    productCategoryData,
    productSubCategoryData,
    getProductSubCategoryLoader,
    addProductSubCategoryLoader,
    getSingleProductSubCategoryData,
    deleteProductSubCategoryLoader,
    updateProductSubCategoryLoader,
    productSubCategoryDataCount,
    getAllProductSubCategoryLoader,
    allProductSubCategoryData,
  } = useSelector((state) => state.productMaster);

  const offsetRef = useRef(0);

  const [search, setSearch] = useState("");
  const [edited, setEdited] = useState(false);
  const [eventTypeData, setEventTypeData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [exportAll, setExportAll] = useState(false);
  const [columnKey, setColumnKey] = useState(null);
  const [lastSearch, setLastSearch] = useState('');
  const [pageSize, setPageSize] = useState(20);
  const { hasPermission } = usePermission();

  const isEditing = (record) =>
    record.product_sub_category_master_id === editingKey;

  useEffect(() => {
    dispatch(actionGetProductSubCategoryMaster({
      offset: offsetRef.current,
      limit: pageSize
    }));
  }, [dispatch]);

  const handleAddProductSubCategory = (values) => {
    dispatch(actionAddProductSubCategoryMaster({ values, handleCancel }));
  };

  const showModal = () => {
    setIsModalOpen(true);
    dispatch(actionGetProductCategoryMaster());
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const productCateData =
    productCategoryData &&
    productCategoryData.length > 0 &&
    productCategoryData.map((data) => ({
      label: data.category_name,
      value: data.product_category_master_id,
    }));

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode;

    if (dataIndex === "product_category") {
      inputNode = (
        <Select
          className="selected-input"
          options={productCateData}
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          style={{ background: "white" }}
        />
      );
    } else if (dataIndex === "status") {
      inputNode = (
        <Select
          className="selected-input"
          allowClear
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={[
            {
              value: "Active",
              label: "Active",
            },
            {
              value: "Archive",
              label: "Archive",
            },
            {
              value: "Inactive",
              label: "Inactive",
            },
          ]}
          style={{ background: "white" }}
        />
      );
    } else {
      inputNode = <Input />;
    }

    return (
      <td {...restProps} className="input-height">
        {editing ? (
          <Form.Item
            className="mb-0 editable-order"
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          <Typography.Paragraph
            style={{ marginBottom: 0 }}
            ellipsis={{ rows: 1, expandable: false, tooltip: true }}
            className="px-[11px]"
          >
            {children}
          </Typography.Paragraph>
        )}
      </td>
    );
  };

  const edit = (record) => {
    dispatch(
      actionGetSingleProductSubCategoryMaster(
        record.product_sub_category_master_id
      )
    );

    dispatch(
      actionGetProductCategoryMaster({
        orderByColumn: "category_name",
        order: "ASC",
      })
    );
    setEdited(true);
    // setSelect(true);
  };

  useEffect(() => {
    if (getSingleProductSubCategoryData && edited) {
      editableForm.setFieldsValue({
        product_category:
          getSingleProductSubCategoryData.product_category
            ?.product_category_master_id,
        sub_category_name: getSingleProductSubCategoryData.sub_category_name,
        status: getSingleProductSubCategoryData.status,
      });
      setEditingKey(
        getSingleProductSubCategoryData.product_sub_category_master_id
      );
    }
  }, [getSingleProductSubCategoryData]);

  const save = async (id) => {
    try {
      const row = await editableForm.validateFields();
      const { product_category, sub_category_name, status } = row;
      const req = {
        product_category_id: product_category,
        sub_category_name,
        status,
      };
      dispatch(
        actionEditProductSubCategoryMaster({
          id,
          req,
          setEditingKey,
          setEdited,
        })
      );
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "SR No.",
      dataIndex: "srno",
      key: "srno",
      width: 70,
      fixed: "left",
      editable: false,
      render: (text, record, index) => {
        const pageSize = 10;
        return offsetRef.current * pageSize + index + 1;
      },
    },
    {
      title: "Category Name",
      dataIndex: "product_category",
      key: "product_category",
      width: 250,
      editable: true,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text, record) => (
        <span className="ml-2">
          {record.product_category && record.product_category.category_name
            ? record.product_category.category_name
            : "-"}
        </span>
      ),
    },
    {
      title: "Sub Category Name",
      dataIndex: "sub_category_name",
      key: "sub_category_name",
      width: 250,
      editable: true,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
    },

    {
      title: "Action",
      key: "action",
      width: 100,
      fixed: "right",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <div className="flex items-center ">
            {editable ? (
              <div className="flex items-center">
                {updateProductSubCategoryLoader[
                  record.product_sub_category_master_id
                ] ? (
                  <LoadingOutlined />
                ) : (
                  <Typography.Link
                    onClick={() => save(record.product_sub_category_master_id)}
                  >
                    <img src={editedevent} alt="" />
                  </Typography.Link>
                )}
              </div>
            ) : (
              <div className="flex items-center gap-x-2 justify-center">
                <Typography.Link
                  disabled={editingKey !== ""}
                  onClick={() => {
                    dispatch(
                      handleActionWithPermission(
                        "product-sub-category-master",
                        "update",
                        () => edit(record)
                      )
                    );
                  }}
                >
                  <img src={editevent} alt="" />
                </Typography.Link>
              </div>
            )}
            <div className="ml-5">
              {hasPermission("product-sub-category-master", "delete") ? (
                <Popconfirm
                  okButtonProps={{
                    loading:
                      deleteProductSubCategoryLoader[
                      record.product_sub_category_master_id
                      ],
                  }}
                  description="Are you sure you want to delete?"
                  onConfirm={() =>
                    dispatch(
                      actionDeleteProductSubCategoryMaster({
                        id: record.product_sub_category_master_id,
                        setEditingKey,
                      })
                    )
                  }
                >
                  <img src={deletevent} alt="" className="cursor-pointer" />
                </Popconfirm>
              ) : (
                <img
                  src={deletevent}
                  alt=""
                  className="cursor-pointer"
                  onClick={() => {
                    dispatch(
                      handleActionWithPermission(
                        "product-sub-category-master",
                        "delete"
                      )
                    );
                  }}
                />
              )}
            </div>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      sortIcon: () => col.sorter ? getProductSubCategoryLoader && col.key === columnKey ? <LoadingOutlined /> : <SortIcon /> : null,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "Product Sub Category" ? "select" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  useEffect(() => {
    const trimmedSearch = search?.trim();
    if (trimmedSearch !== "" && trimmedSearch !== lastSearch) {
      const timer = setTimeout(() => {
        dispatch(
          actionGetProductSubCategoryMaster({
            offset: offsetRef.current,
            limit: pageSize,
            search: trimmedSearch
          })
        );
        setLastSearch(trimmedSearch);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }

  }, [search, lastSearch]);

  const handleSearch = (e) => {
    if (isValidSearchString(e.target.value)) {
      offsetRef.current = 0;
      setSearch(e.target.value);
      if (e.target.value === "") {
        dispatch(
          actionGetProductSubCategoryMaster({
            offset: offsetRef.current,
            limit: pageSize,
            search: e.target.value
          })
        );
      }
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search && isValidSearchString(e.target.value)) {
      offsetRef.current = 0;
      dispatch(
        actionGetProductSubCategoryMaster({
          offset: offsetRef.current,
          limit: pageSize,
          search: e.target.value,
        })
      );
    }
  };

  const handleSortChange = (pagination, filters, sorter) => {
    setColumnKey(sorter.columnKey)

    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
            ? "ASC"
            : "",
    };

    dispatch(
      actionGetProductSubCategoryMaster({
        offset: offsetRef.current,
        limit: pageSize,
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
  };

  const getData = async (data) => {
    const newData =
      (await data) &&
      data.length > 0 &&
      data.map((d) => ({
        "Category Name": d.product_category
          ? d.product_category.category_name
          : "-",
        "Sub Category Name": d.sub_category_name ? d.sub_category_name : "-",
        // Status: d.status ? d.status : "-",
      }));
    return newData;
  };

  const exportToExcel = async (items, type = "") => {
    if (!items || (items && items.length === 0)) {
      return;
    }
    const data = await getData(items);

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Material Sub Categories");
    XLSX.writeFile(workbook, "material-sub-categories.xlsx");
    type &&
      type === "all" &&
      dispatch(actionClearAllProductSubCategory({ setExportAll }));
  };

  const onClickDownloadMenu = ({ key }) => {

    if (key === "1") {
      exportToExcel(productSubCategoryData);
    } else {
      dispatch(actionGetAllProductSubCategoryMaster());
      setExportAll(true);
    }
  };

  useEffect(() => {
    if (
      exportAll &&
      allProductSubCategoryData &&
      allProductSubCategoryData.length > 0
    ) {
      exportToExcel(allProductSubCategoryData, "all");
    }
  }, [allProductSubCategoryData]);

  return (
    <div className="event-input">
      <PageTitleForMasterData
        title="Material Sub Categories"
        buttonText="Add Material Sub Category"
        onButtonClick={showModal}
        resource="product-sub-category-master"
      />
      <Modal
        centered
        onOk={() => handleCancel()}
        onCancel={() => handleCancel()}
        footer={false}
        width={500}
        title=""
        open={isModalOpen}
      >
        <div className="flex">
          <img src={productsubcategory} alt="" />
          <h1 className="text-[#6883FD] text-[20px] ml-[8px]  mt-[5px] font-medium">
            Material Sub Category
          </h1>
        </div>
        <Form
          form={form}
          initialValues={{
            status: "Active",
          }}
          onFinish={handleAddProductSubCategory}
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="product_category_id"
            label="Material Category"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please select material category!",
              },
            ]}
          >
            <Select
              options={productCateData}
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              loading={getProductCategoryLoader}
              disabled={getProductCategoryLoader}
              style={{ background: "white" }}
              placeholder="Select Material Category"
              className="begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>
          <Form.Item
            name="sub_category_name"
            label="Material Sub Category Name"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please enter material sub category name!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Material Sub Category Name"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          {/* <Form.Item
                name="status"
                label="Status"
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: true,
                    message: "Please select status!",
                  },
                ]}
              >
                <Select
                  allowClear
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "Active",
                      label: "Active",
                    },
                    {
                      value: "Archive",
                      label: "Archive",
                    },
                    {
                      value: "Inactive",
                      label: "Inactive",
                    },
                  ]}
                  // defaultValue="Active"
                  style={{ background: "white" }}
                  placeholder="Select Status"
                  className="begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item> */}

          <div className="flex justify-center mt-[40px]">
            <Form.Item className="mb-0">
              <Button
                onClick={handleCancel}
                type="button"
                className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addProductSubCategoryLoader}
                  type="primary"
                  className="root-btn min-h-[45px] min-w-[120px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>

      <MasterCommonTable
        moduleKey="material-sub-categories"
        columns={mergedColumns}
        dataSource={productSubCategoryData}
        rowKey="product_sub_category_master_id"
        isLoading={getProductSubCategoryLoader || getAllProductSubCategoryLoader}
        EditableCell={EditableCell}
        onTableChange={handleSortChange}
        editableForm={editableForm}
        searchBarProps={{
          handleSearch,
          handleBlurAndPressEnter,
          importRoute: "/master-data/material-sub-category-import",
          search,
          onClickDownloadMenu: onClickDownloadMenu,
          isPagination: true
        }}
        columnKey={columnKey}
      />

      <div className="flex justify-end mb-5 mt-5">
        <CommonPagination
          current={offsetRef.current + 1}
          total={productSubCategoryDataCount}
          onChange={(page, pageSize) => {
            offsetRef.current = page - 1;
            setPageSize(pageSize);
            dispatch(
              actionGetProductSubCategoryMaster({
                offset: offsetRef.current,
                limit: pageSize,
                search: search
              })
            );
          }}
          loading={getProductSubCategoryLoader}
          pageSize={pageSize}
        />
      </div>
    </div>
  );
};

export default ProductSubCategory;
