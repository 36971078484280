
import { Button, Checkbox, ConfigProvider, DatePicker, Drawer, Form, Input, Select, Space, Spin } from "antd"
import { useEffect, useState } from "react"
import { DATE_CONDITIONS, DROPDOWN_CONDITIONS, NUMBER_CONDITIONS, STRING_CONDITIONS } from "../helper/filter-conditions"
import { useDispatch, useSelector } from "react-redux"
import { actionGetAdvancedFilterFields } from "../../store/services/commonService"
import { ADVANCED_FILTER_FIELD_TYPES } from "../helper/constants"
const { RangePicker } = DatePicker

export default function AdvancedFilter({ module = "", onApplyFilter = () => { } }) {
    const dispatch = useDispatch();
    const { getAdvanedFiltersLoader
        , advancedFilters } = useSelector(state => state.common);

    const [form] = Form.useForm()
    const [selectedFields, setSelectedFields] = useState(new Set())
    const [conditions, setConditions] = useState({})


    useEffect(() => {
        if (module) {
            dispatch(actionGetAdvancedFilterFields({ module_name: module }))
        }
    }, [module, dispatch]);



    const getConditionsForFieldType = (type) => {
        switch (type) {
            case ADVANCED_FILTER_FIELD_TYPES.STRING:
                return STRING_CONDITIONS
            case ADVANCED_FILTER_FIELD_TYPES.NUMBER:
                return NUMBER_CONDITIONS
            case ADVANCED_FILTER_FIELD_TYPES.DROPDOWN:
                return DROPDOWN_CONDITIONS
            case ADVANCED_FILTER_FIELD_TYPES.DATE:
                return DATE_CONDITIONS
            default:
                return []
        }
    }

    const handleFieldToggle = (field, checked) => {
        const { field_name, field_type: { field_type }, } = field || {}
        const newSelectedFields = new Set(selectedFields)
        if (checked) {

            const firstCondition = getConditionsForFieldType(field_type)[0]?.value;

            form.setFieldsValue({
                [field_name]: {
                    condition: firstCondition,
                    value: field_type === ADVANCED_FILTER_FIELD_TYPES.DROPDOWN ? null : "",
                    value2: field_type === ADVANCED_FILTER_FIELD_TYPES.DROPDOWN ? null : "",
                }
            })
            setConditions({ ...conditions, [field_name]: firstCondition })

            newSelectedFields.add(field_name)
        } else {
            newSelectedFields.delete(field_name)
        }
        setSelectedFields(newSelectedFields);
    }


    const handleConditionChange = (fieldName, value) => {
        setConditions({ ...conditions, [fieldName]: value })
    }

    const renderInputForCondition = (field, condition) => {
        const { field_name, field_type: { field_type }, options = []
        } = field || {}
        const conditionConfig = getConditionsForFieldType(field_type).find((c) => c.value === condition)
        if (!conditionConfig?.requiresInput) return null;


        switch (field_type) {
            case ADVANCED_FILTER_FIELD_TYPES.NUMBER:
                if (conditionConfig.requiresSecondInput) {
                    return (
                        <Space>
                            <Form.Item name={[field_name, "value"]} noStyle>
                                <Input className="remove-css" type="number" placeholder="From" style={{ width: 100 }} />
                            </Form.Item>
                            <Form.Item name={[field_name, "value2"]} noStyle>
                                <Input className="remove-css" type="number" placeholder="To" style={{ width: 100 }} />
                            </Form.Item>
                        </Space>
                    )
                }
                return (
                    <Form.Item name={[field_name, "value"]} noStyle>
                        <Input className="remove-css" type="number" style={{ width: 300 }} />
                    </Form.Item>
                )

            case ADVANCED_FILTER_FIELD_TYPES.DATE:
                if (conditionConfig.requiresUnit) {
                    return (
                        <Space>
                            <Form.Item name={[field_name, "value"]} noStyle>
                                <Input className="remove-css" type="number" style={{ width: 100 }} />
                            </Form.Item>
                            <Form.Item name={[field_name, "unit"]} noStyle>
                                <Select className="remove-css" style={{ width: 100 }}>
                                    <Select.Option value="days">Days</Select.Option>
                                    <Select.Option value="weeks">Weeks</Select.Option>
                                    <Select.Option value="months">Months</Select.Option>
                                </Select>
                            </Form.Item>
                        </Space>
                    )
                }
                if (conditionConfig.requiresSecondInput) {
                    return (
                        <Form.Item name={[field_name, "value"]} noStyle>
                            <RangePicker className="remove-css" />
                        </Form.Item>
                    )
                }
                return (
                    <Form.Item name={[field_name, "value"]} noStyle>
                        <DatePicker className="remove-css" />
                    </Form.Item>
                )

            case ADVANCED_FILTER_FIELD_TYPES.DROPDOWN:
                return (
                    <Form.Item name={[field_name, "value"]} noStyle>
                        <Select className="remove-css"
                            mode="multiple"
                            style={{ width: 300 }}
                            options={options?.map((opt) => ({ label: opt.label, value: opt.value }))}
                        />
                    </Form.Item>
                )

            default:
                return (
                    <Form.Item name={[field_name, "value"]} noStyle>
                        <Input className="remove-css" style={{ width: 300 }} />
                    </Form.Item>
                )
        }
    }

    const handleSubmit = (values) => {
        const filterConditions = []

        selectedFields.forEach((fieldName) => {
            const fieldValue = values[fieldName]
            if (fieldValue) {
                filterConditions.push({
                    name: fieldName,
                    operator: fieldValue.condition,
                    value: fieldValue.value,
                    value2: fieldValue.value2,
                })
            }
        })


        onApplyFilter(filterConditions)
    }

    const handleClear = () => {
        form.resetFields()
        setSelectedFields(new Set())
        setConditions({})
    }


    return (


        <Form form={form} onFinish={handleSubmit} layout="vertical"
            className="max-w-[600px] color-scrollbar"
        >
            {/* Scrollable Fields */}
            <div className="space-y-3 px-[18px] mb-[55px] p-4">
                <Spin spinning={getAdvanedFiltersLoader}>
                    {advancedFilters && advancedFilters.length > 0 ? advancedFilters?.map((field) => {
                        const { display_name, field_name, field_type: { field_type } } = field || {}
                        return (
                            <div key={field_name} className="flex flex-col pb-1.5 space-y-2">
                                <Checkbox className="remove-checkbox-css"
                                    checked={selectedFields.has(field_name)}
                                    onChange={(e) => handleFieldToggle(field, e.target.checked)}
                                >
                                    {display_name}
                                </Checkbox>

                                {selectedFields.has(field_name) && (
                                    <div className="ml-6 space-y-2">
                                        <Form.Item name={[field_name, "condition"]} noStyle>
                                            <Select className="remove-css"
                                                style={{ width: 300 }}
                                                onChange={(value) => handleConditionChange(field_name, value)}
                                                options={getConditionsForFieldType(field_type)}
                                            />
                                        </Form.Item>
                                        {conditions[field_name] && renderInputForCondition(field, conditions[field_name])}
                                    </div>
                                )}
                            </div>
                        )
                    }) : <h1>No Fields Found</h1>}
                </Spin>
            </div>

            <div className=" bg-white border-t border-[#ededed] fixed bottom-0  py-3 w-full  px-[18px] flex justify-start space-x-2">
                <Button type="primary" className="remove-primary-btn-css pl-[15px]" htmlType="submit">
                    Apply Filter
                </Button>
                <Button className="remove-outline-btn-css" onClick={handleClear}>Clear</Button>
            </div>
        </Form>

    )
}

