import { LoadingOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import {
  Button,
  Form,
  Image,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import plusIcon from "../../../assets/img/add-button.svg";
import deletevent from "../../../assets/img/deleteevent.svg";
import editedevent from "../../../assets/img/editedevent.svg";
import editevent from "../../../assets/img/editevent.svg";
import zone from "../../../assets/img/zone.svg";
import {
  actionAddZoneMaster,
  actionClearAllZoneMasterData,
  actionDeleteZoneMaster,
  actionEditZoneMasterMaster,
  actionGetAllZoneMaster,
  actionGetSingleZoneMaster,
  actionGetZoneMaster,
} from "../../../store/services/generalMasterService";
import MasterSearchbar from "../../common/MasterSearchbar";
import SortIcon from "../../common/SortIcon";
import { isValidSearchString } from "../../helper/common-function";
import PageTitleForMasterData from "../../common/PageTitleForMasterData";
import usePermission from "../../../hooks/use-permission";
import { handleActionWithPermission } from "../../../store/slices/authSlice";
import SkeletonTable from "../../common/SkeletonTable";
import CommonPagination from "../../common/CommonPagination";
import MasterCommonTable from "../../common/MasterCommonTable";

const Zone = ({ setSelectItem }) => {
  const [editableForm] = Form.useForm();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const offsetRef = useRef(0);
  const {
    zoneMasterData,
    getSingleZoneMasterData,
    getSingleZoneMasterLoader,
    getZoneMasterLoader,
    addZoneMasterLoader,
    deleteZoneMasterLoader,
    updateZoneMasterLoader,
    zoneMasterDataCount,
    getAllZoneMasterLoader,
    allZoneMasterData,
  } = useSelector((state) => state.generalMaster);
  const [search, setSearch] = useState("");
  const [edited, setEdited] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [columnKey, setColumnkey] = useState(null);
  const [lastSearch, setLastSearch] = useState('');
  const [exportAll, setExportAll] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const { hasPermission } = usePermission();

  const isEditing = (record) => record.zone_master_id === editingKey;

  const showModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    dispatch(actionGetZoneMaster({
      offset: offsetRef.current,
      limit: pageSize,
      search: search
    }));
  }, [dispatch]);

  const handleAddZone = (values) => {
    dispatch(actionAddZoneMaster({ values, handleCancel }));
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      inputType === "number" ? <Input /> : <Input variant="borderless" />;
    return (
      <td {...restProps} className="input-height">
        {editing ? (
          <Form.Item
            className="mb-0 editable-order"
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          <Typography.Paragraph
            style={{ marginBottom: 0 }}
            ellipsis={{ rows: 1, expandable: false, tooltip: true }}
            className="px-[11px]"
          >
            {children}
          </Typography.Paragraph>
        )}
      </td>
    );
  };

  const edit = (record) => {
    dispatch(actionGetSingleZoneMaster(record.zone_master_id));
    setEdited(!edited);
  };

  useEffect(() => {
    if (getSingleZoneMasterData && edited) {
      editableForm.setFieldsValue({
        zone_name: getSingleZoneMasterData.zone_name,
      });
      setEditingKey(getSingleZoneMasterData.zone_master_id);
    }
  }, [getSingleZoneMasterData]);

  const save = async (id) => {
    try {
      const row = await editableForm.validateFields();
      const { zone_name } = row;
      const req = { zone_name };
      dispatch(
        actionEditZoneMasterMaster({ id, req, setEditingKey, setEdited })
      );
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "SR No.",
      dataIndex: "srno",
      key: "srno",
      width: 70,
      fixed: "left",
      editable: false,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Zone",
      dataIndex: "zone_name",
      key: "zone_name",
      width: 300,
      editable: true,
      ellipsis: true,
      sorter: true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Action",
      key: "action",
      width: 100,
      fixed: "right",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <div className="flex items-center ">
            {editable ? (
              <div className="flex items-center">
                {updateZoneMasterLoader[record.zone_master_id] ||
                  getSingleZoneMasterLoader ? (
                  <LoadingOutlined />
                ) : (
                  <Typography.Link onClick={() => save(record.zone_master_id)}>
                    <img src={editedevent} alt="" />
                  </Typography.Link>
                )}
              </div>
            ) : (
              <div className="flex items-center gap-x-2 justify-center">
                <Typography.Link
                  disabled={editingKey !== ""}
                  onClick={() => {
                    dispatch(
                      handleActionWithPermission("zone-master", "update", () =>
                        edit(record)
                      )
                    );
                  }}
                >
                  <img src={editevent} alt="" />
                </Typography.Link>
              </div>
            )}
            <div className="ml-5">
              {hasPermission("zone-master", "delete") ? (
                <Popconfirm
                  okButtonProps={{
                    loading: deleteZoneMasterLoader[record.zone_master_id],
                  }}
                  description="Are you sure you want to delete?"
                  onConfirm={() =>
                    dispatch(
                      actionDeleteZoneMaster({
                        id: record.zone_master_id,
                        setEditingKey,
                      })
                    )
                  }
                >
                  <img src={deletevent} alt="" className="cursor-pointer" />
                </Popconfirm>
              ) : (
                <img
                  src={deletevent}
                  alt=""
                  className="cursor-pointer"
                  onClick={() => {
                    dispatch(
                      handleActionWithPermission("zone-master", "delete")
                    );
                  }}
                />
              )}
            </div>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      sortIcon: () => col.sorter ? getZoneMasterLoader && col.key === columnKey ? <LoadingOutlined /> : <SortIcon /> : null,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "zone_name" ? "text" : "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  useEffect(() => {
    const trimmedSearch = search?.trim();
    if (trimmedSearch !== "" && trimmedSearch !== lastSearch) {
      const timer = setTimeout(() => {
        dispatch(
          actionGetZoneMaster({
            offset: offsetRef.current,
            limit: pageSize,
            search: trimmedSearch
          })
        );
        setLastSearch(trimmedSearch);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }

  }, [search, lastSearch]);

  const handleSearch = (e) => {
    if (isValidSearchString(e.target.value)) {
      setSearch(e.target.value);
      if (e.target.value === "") {
        dispatch(
          actionGetZoneMaster({
            offset: offsetRef.current,
            limit: pageSize,
            search: e.target.value,
          })
        );
      }
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search && isValidSearchString(e.target.value)) {
      dispatch(
        actionGetZoneMaster({
          offset: offsetRef.current,
          limit: pageSize,
          search: e.target.value,
        })
      );
    }
  };

  const handleSortChange = (pagination, filters, sorter) => {
    setColumnkey(sorter.columnKey);

    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
            ? "ASC"
            : "",
    };

    dispatch(
      actionGetZoneMaster({
        offset: offsetRef.current,
        limit: pageSize,
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
  };

  const getData = async (data) => {
    const newData =
      (await data) &&
      data.length > 0 &&
      data.map((d) => ({
        Zone: d.zone_name ? d.zone_name : "-",
      }));
    return newData;
  };

  const exportToExcel = async (items, type = "") => {
    if (!items || (items && items.length === 0)) {
      return;
    }
    const data = await getData(items);
    // console.log({ data });
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Zones");
    XLSX.writeFile(workbook, "zones.xlsx");
    type &&
      type === "all" &&
      dispatch(actionClearAllZoneMasterData({ setExportAll }));
  };

  const onClickDownloadMenu = ({ key }) => {
    if (key === "1") {
      exportToExcel(zoneMasterData);
    } else {
      dispatch(actionGetAllZoneMaster());
      setExportAll(true);
    }
  };

  useEffect(() => {
    if (exportAll && allZoneMasterData && allZoneMasterData.length > 0) {
      exportToExcel(allZoneMasterData, "all");
    }
  }, [allZoneMasterData]);

  return (
    <div className="event-input">
      <PageTitleForMasterData
        title="Zones"
        buttonText="Add Zone"
        onButtonClick={showModal}
        resource="zone-master"
      />
      <Modal
        centered
        onOk={() => handleCancel()}
        onCancel={() => handleCancel()}
        footer={false}
        width={500}
        title=""
        open={isModalOpen}
      >
        <div className="flex">
          <img src={zone} alt="" />
          <h1 className="text-[#6883FD] text-[20px] ml-[8px] mt-[5px] font-medium">
            Zone
          </h1>
        </div>

        <Form
          form={form}
          onFinish={handleAddZone}
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="zone_name"
            label="Zone"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please enter zone!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Zone"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-center mt-[40px]">
            <Form.Item className="mb-0">
              <Button
                onClick={handleCancel}
                type="button"
                className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addZoneMasterLoader}
                  type="primary"
                  className="root-btn min-h-[45px] min-w-[120px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>

      <MasterCommonTable
        moduleKey="zones"
        columns={mergedColumns}
        dataSource={zoneMasterData}
        rowKey="zone_master_id"
        isLoading={getZoneMasterLoader}
        EditableCell={EditableCell}
        onTableChange={handleSortChange}
        editableForm={editableForm}
        searchBarProps={{
          handleSearch,
          handleBlurAndPressEnter,
          importRoute: "/master-data/zone-import",
          search,
          onClickDownloadMenu: onClickDownloadMenu,
          isPagination: true
        }}
        columnKey={columnKey}
      />

      <div className="flex justify-between mt-5 mb-3 lg:mb-5">
        <p className="text-[15px] font-popinsMedium">
          Total : {zoneMasterDataCount ? zoneMasterDataCount : 0}
        </p>
        {zoneMasterDataCount > 0 && (
          <CommonPagination
            current={offsetRef.current + 1}
            total={zoneMasterDataCount}
            onChange={(page, pageSize) => {
              offsetRef.current = page - 1;
              setPageSize(pageSize);
              dispatch(
                actionGetZoneMaster({
                  offset: offsetRef.current,
                  limit: pageSize,
                  search: search,
                })
              );
            }}
            loading={getZoneMasterLoader}
            pageSize={pageSize}
          />
        )}
      </div>
    </div>
  );
};

export default Zone;
