import { createSlice } from "@reduxjs/toolkit";
import {
  actionGetCompanyUsers,
  actionAddUser,
  actionGetCompanyAllUsers,
  actionGetUserDetail,
  actionUpdateUser,
  actionDeactivateUser,
  actionClearUsersAllData,
  actionGetAttendanceLogs,
  actionGetAllAttendanceLogs,
  actionClearAllAttendanceLogs,
  actionGetUsersOrganogram,
  actionUpdateBulkUsers,
  actionDeleteInvitedUser,
} from "../services/usersService";
import { convertOrgChartData } from "../../components/helper/common-function";
import { data } from "../../components/helper/tree-helpers";

const usersSlice = createSlice({
  name: "users",
  initialState: {
    companyUsers: [],
    companyUsersCount: 0,
    companyAllUsersCount: 0,
    companyAllUsers: [],
    attendanceLogs: [],
    allAttendanceLogs: [],
    organogramHierachy: {},
    getUsersHierachyLoader: false,
    getCompanyUserLoader: false,
    addUserLoader: false,
    getCompanyAllUserLoader: false,
    attendanceLogsCount: 0,
    getUserDetailLoader: false,
    updateBulkUsersLoader: false,
    userDetail: "",
    updateUserLoader: false,
    deactivateUsersLoader: false,
    getAttendanceLogsLoader: false,
    getAllAttendanceLogsLoader: false,
    deleteInvitedUsers: false
  },
  reducers: {
    appendCompanyUsers: (state, action) => {
      if (!state.companyAllUsers || !Array.isArray(state.companyAllUsers)) {
        state.companyAllUsers = [action.payload];
      } else {
        state.companyAllUsers.unshift(action.payload);
      }
    }
  },
  extraReducers: (buider) => {
    buider
      // actionAddUser State
      .addCase(actionAddUser.pending, (state) => {
        state.addUserLoader = true;
      })
      .addCase(actionAddUser.fulfilled, (state, action) => {
        state.addUserLoader = false;
      })
      .addCase(actionAddUser.rejected, (state) => {
        state.addUserLoader = false;
      })

      // actionGetCompanyUsers State
      .addCase(actionGetCompanyUsers.pending, (state) => {
        state.getCompanyUserLoader = true;
      })
      .addCase(actionGetCompanyUsers.fulfilled, (state, action) => {
        state.companyUsers = action.payload.data;
        state.companyUsersCount = action.payload.total_records;
        state.getCompanyUserLoader = false;
      })
      .addCase(actionGetCompanyUsers.rejected, (state) => {
        state.getCompanyUserLoader = false;
      })

      // actionGetCompanyAllUsers State
      .addCase(actionGetCompanyAllUsers.pending, (state) => {
        state.getCompanyAllUserLoader = true;
      })
      .addCase(actionGetCompanyAllUsers.fulfilled, (state, action) => {
        state.companyAllUsers = action.payload.data;
        state.companyAllUsersCount = action.payload.total_records;
        state.getCompanyAllUserLoader = false;
      })
      .addCase(actionGetCompanyAllUsers.rejected, (state) => {
        state.getCompanyAllUserLoader = false;
      })
      // Clear All Users Data
      .addCase(actionClearUsersAllData.fulfilled, (state, action) => {
        state.companyAllUsers = action.payload;
      })

      // actionUpdateUser State
      .addCase(actionUpdateUser.pending, (state) => {
        state.updateUserLoader = true;
      })
      .addCase(actionUpdateUser.fulfilled, (state, action) => {
        state.userDetail = action.payload;
        state.updateUserLoader = false;
      })
      .addCase(actionUpdateUser.rejected, (state) => {
        state.updateUserLoader = false;
      })

      // actionGetUserDetail State
      .addCase(actionGetUserDetail.pending, (state) => {
        state.getUserDetailLoader = true;
      })
      .addCase(actionGetUserDetail.fulfilled, (state, action) => {
        state.userDetail = action.payload.data;
        state.getUserDetailLoader = false;
      })
      .addCase(actionGetUserDetail.rejected, (state) => {
        state.getUserDetailLoader = false;
      })

      // actionDeactivateUser State
      .addCase(actionDeactivateUser.pending, (state) => {
        state.deactivateUsersLoader = true;
      })
      .addCase(actionDeactivateUser.fulfilled, (state, action) => {
        const updatedUsers = state.companyUsers.map((user) => {
          const updatedUser = action.payload.find(
            (updated) => updated.id === user.id
          );
          if (updatedUser) {
            return {
              ...user,
              status: updatedUser.status,
            };
          }
          return user;
        });

        const DeactiveStatusChange =
          state.userDetail &&
          action.payload.find((updated) => updated.id === state.userDetail.id);
        // If found, update the user's status,
        if (DeactiveStatusChange && state.userDetail) {
          const req = {
            ...state.userDetail,
            status: action.payload[0].status,
          };
          state.userDetail = req;
        }
        state.companyUsers = updatedUsers;
        state.deactivateUsersLoader = false;
      })
      .addCase(actionDeactivateUser.rejected, (state) => {
        state.deactivateUsersLoader = false;
      })

      .addCase(actionGetAttendanceLogs.pending, (state) => {
        state.getAttendanceLogsLoader = true;
      })
      .addCase(actionGetAttendanceLogs.fulfilled, (state, action) => {
        state.attendanceLogs = action.payload.data;
        state.attendanceLogsCount = action.payload.total_records;
        state.getAttendanceLogsLoader = false;
      })
      .addCase(actionGetAttendanceLogs.rejected, (state) => {
        state.getAttendanceLogsLoader = false;
      })

      .addCase(actionGetAllAttendanceLogs.pending, (state) => {
        state.getAllAttendanceLogsLoader = true;
      })

      .addCase(actionGetAllAttendanceLogs.fulfilled, (state, action) => {
        state.allAttendanceLogs = action.payload.data;
        state.getAllAttendanceLogsLoader = false;
      })
      .addCase(actionGetAllAttendanceLogs.rejected, (state) => {
        state.getAllAttendanceLogsLoader = false;
      })

      .addCase(actionClearAllAttendanceLogs.fulfilled, (state, action) => {
        state.allAttendanceLogs = action.payload;
      })

      .addCase(actionGetUsersOrganogram.pending, (state) => {
        state.getUsersHierachyLoader = true;
        state.organogramHierachy = convertOrgChartData(data);
      })

      .addCase(actionGetUsersOrganogram.fulfilled, (state, action) => {
        state.getUsersHierachyLoader = false;
        state.organogramHierachy = convertOrgChartData(action.payload.data);
      })
      .addCase(actionGetUsersOrganogram.rejected, (state) => {
        state.getUsersHierachyLoader = false;
      })

      .addCase(actionUpdateBulkUsers.pending, (state) => {
        state.updateBulkUsersLoader = true;
      })
      .addCase(actionUpdateBulkUsers.fulfilled, (state, action) => {
        state.updateBulkUsersLoader = false;
        state.companyUsers = state.companyUsers.map((data) => {
          const updateFields = action.payload.find(
            (updateItem) =>
              updateItem.id === data.id
          );
          return updateFields ? { ...data, ...updateFields } : data;
        });
      })
      .addCase(actionUpdateBulkUsers.rejected, (state) => {
        state.updateBulkUsersLoader = false;
      })


      // Delete Invited Users
      .addCase(actionDeleteInvitedUser.pending, (state) => {
        state.deleteInvitedUsers = true;
      })
      .addCase(actionDeleteInvitedUser.fulfilled, (state, action) => {
        state.deleteInvitedUsers = false;
        state.companyUsers = state.companyUsers.filter((data) => data.id !== action.payload);
      })
      .addCase(actionDeleteInvitedUser.rejected, (state) => {
        state.deleteInvitedUsers = false;
      })



  },
});

export const { appendCompanyUsers } = usersSlice.actions
export default usersSlice.reducer;
