import { Avatar, Button, Col, DatePicker, Descriptions, Form, Image, Input, InputNumber, Radio, Row, Select, Space, Switch, TimePicker, Tooltip } from 'antd'
import {
    InfoCircleOutlined,
} from "@ant-design/icons";
import { useForm } from 'antd/es/form/Form';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { actionGetCompanyAllUsers } from '../../store/services/usersService';
import { getFullName } from '../helper/common-function';
import dayjs from "dayjs";
import calendar from "../../assets/img/beat-calendar.svg";
import clock from "../../assets/img/clock.svg";
import quickReminderIcon from "../../assets/img/quick-reminder-icon.svg";
import { actionCreateReminder, actionUpdateReminder } from '../../store/services/quickRemiderService';

const RecurrenceTypes = {
    DOES_NOT_REPEAT: "Does not repeat",
    DAILY: "daily",
    WEEKLY: "weekly",
    MONTHLY: "monthly",
    YEARLY: "yearly",
};
const RecurringEndTypes = {
    NEVER: "never",
    DATE: "date",
    OCCURRENCES: "occurrences",
};
const CreateQuickReminder = ({ data, handleCloseCreateModal, id, setReminderId, setCreateModalOpen, createModalOpen }) => {
    const [form] = useForm();
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(null);
    const [time, setTime] = useState(null);
    const [recurrenceType, setRecurrenceType] = useState(
        RecurrenceTypes.DOES_NOT_REPEAT
    );
    const [recurringEndsType, setRecurringEndsType] = useState(
        RecurringEndTypes.NEVER
    );
    const [endDate, setEndDate] = useState(null);
    const [afterOccurrence, setAfterOccurrence] = useState(1);
    const [errors, setErrors] = useState({});
    const [recurrenceDays, setRecurrenceDays] = useState([]);
    const [repeatUnit, setRepeatUnit] = useState("days");
    const [recurrenceInterval, setRecurrenceInterval] = useState(1);
    const [notificationOnEmail, setNotificationOnEmail] = useState(false);
    const { getCompanyAllUserLoader, companyAllUsers } = useSelector(
        (state) => state.users
    );
    const { addReminderLoader, updateReminderLoader } = useSelector(
        (state) => state.reminder
    );
    const { currentCompanyUser } = useSelector(
        (state) => state.auth
    );

    useEffect(() => {
        dispatch(actionGetCompanyAllUsers());
    }, [dispatch]);

    useEffect(() => {
        if (!id && currentCompanyUser && companyAllUsers && companyAllUsers.length > 0) {
            form.setFieldsValue({
                reminder_company_user_ids: currentCompanyUser?.id ? [currentCompanyUser.id] : []
            });
        }
    }, [currentCompanyUser, companyAllUsers]);

    const userOptions = companyAllUsers && companyAllUsers.length > 0
        ? companyAllUsers.map((user) => {
            const fullName = `${user.first_name ? user.first_name : ""} ${user.last_name ? user.last_name : ""
                }`.trim();

            return {
                key: user.id,
                label: (
                    <>
                        <Avatar
                            src={user.profile_pic}
                            style={{
                                verticalAlign: "middle",
                            }}
                            size={"small"}
                            className="mr-2"
                        />
                        {fullName}
                    </>
                ),
                value: user.id,
                fullName, // Store the full name as a string
            };
        })
        : [];

    const handleDateChange = (date) => {
        setStartDate(date);
        setTime(null);
        form.setFieldsValue({ reminder_on_time: null });
    };

    const disabledTime = () => {
        if (startDate && dayjs(startDate).isSame(dayjs(), "day")) {
            const currentHour = dayjs().hour();
            const currentMinute = dayjs().minute();

            return {
                disabledHours: () => [...Array(currentHour).keys()], // Disable past hours
                disabledMinutes: (selectedHour) => {
                    if (selectedHour === currentHour) {
                        return [...Array(currentMinute).keys()]; // Disable past minutes if in the current hour
                    }
                    return [];
                },
                disabledSeconds: () => [], // Example: no disabled seconds
            };
        }
        return {};
    };

    const handleTimeChange = (time) => {
        setTime(time);
    };

    const recurrenceOptions = [
        {
            value: RecurrenceTypes.DOES_NOT_REPEAT,
            label: "Does not repeat",
        },
        { value: RecurrenceTypes.DAILY, label: "Daily" },
        { value: "custom", label: "Custom...." },
    ];

    const daysOfWeek = [
        { label: "Monday", value: "M" },
        { label: "Tuesday", value: "T" },
        { label: "Wednesday", value: "W" },
        { label: "Thursday", value: "Th" },
        { label: "Friday", value: "F" },
        { label: "Saturday", value: "S" },
        { label: "Sunday", value: "Su" },
    ];

    const toggleDay = (day) => {
        if (recurrenceDays.includes(day)) {
            setRecurrenceDays(recurrenceDays.filter((d) => d !== day));
        } else {
            setRecurrenceDays([...recurrenceDays, day]);
        }
    };

    const handleRecurrenceTypeChange = (value) => {
        setRecurrenceType(value);
        setRepeatUnit("days");
        setRecurrenceInterval(1);
        setRecurringEndsType(RecurringEndTypes.NEVER);
        setErrors({});
    };

    const RepeatInput = ({
        recurrenceInterval,
        repeatUnit,
        onRepeatValueChange,
        onRepeatUnitChange,
        id,
    }) => {
        const onNumberChange = (value) => {
            const newNumber = parseInt(value || 1, 10);
            if (!Number.isNaN(newNumber)) {
                onRepeatValueChange(newNumber);
            }
        };

        const onSelectChange = (value) => {
            onRepeatUnitChange(value);
        };

        return (
            <Space id={id} size={"middle"} className="flex repeat-unit">
                <InputNumber
                    min={1}
                    value={recurrenceInterval}
                    onChange={onNumberChange}
                />
                <Select
                    className="repeat-unit-select"
                    value={repeatUnit}
                    onSelect={onSelectChange}
                >
                    <Select.Option value="days">days</Select.Option>
                    <Select.Option value="weeks">weeks</Select.Option>
                    <Select.Option value="months">months</Select.Option>
                    <Select.Option value="years">years</Select.Option>
                </Select>
            </Space>
        );
    };

    const handleRepeatValueChange = (newValue) => {
        setRecurrenceInterval(newValue);
    };

    const handleRepeatUnitChange = (newUnit) => {
        setRepeatUnit(newUnit);
    };

    const handleChangeRecurringEndsType = (e) => {
        setRecurringEndsType(e.target.value);
        setErrors({ ...errors, date: "" });
    };

    const handleValidation = () => {
        let errors_list = {};
        let formValid = true;

        if (
            repeatUnit === "weeks" &&
            recurrenceDays &&
            recurrenceDays.length === 0
        ) {
            errors_list.days = "Please select atleast 1 day!";
            formValid = false;
        }

        if (recurringEndsType === "date" && (!endDate || endDate === null)) {
            errors_list.date = "Please select end date!";
            formValid = false;
        }

        setErrors(errors_list);
        return formValid;
    };

    // set data in form in update case
    useEffect(() => {
        if (id && data) {
            form.setFieldsValue({
                title: data.title,
                description: data.description,
                reminder_company_user_ids: data.reminder_company_users?.map(user => user.id),
                start_date: dayjs(data.start_date, "YYYY-MM-DD"),
                reminder_on_time: dayjs(data.reminder_on_time, "HH:mm"),
                recurring_type:
                    data.recurring_type === "Does not repeat" ||
                        (data.recurring_type === "daily" && data.recurring_interval === 1)
                        ? data.recurring_type
                        : "custom",
            });
            setStartDate(dayjs(data.start_date));
            setTime(dayjs(data.reminder_on_time));
            setEndDate(
                data.recurring_ends_on_date
                    ? dayjs(data.recurring_ends_on_date)
                    : undefined
            );
            setRecurrenceDays(data.recurring_days);
            setRecurrenceInterval(data.recurring_interval);
            setRecurringEndsType(data.recurring_ends_on_type);
            setAfterOccurrence(
                data.recurring_ends_on_occurrences
                    ? data.recurring_ends_on_occurrences
                    : 1);

            // Updated condition for setting recurrence type
            if (
                data.recurring_type === "Does not repeat" ||
                (data.recurring_type === "daily" && data.recurring_interval === 1)
            ) {
                setRecurrenceType(data.recurring_type);
            } else if (data.recurring_type === "daily" && data.recurring_interval > 1) {
                setRecurrenceType("custom");
            } else {
                setRecurrenceType("custom");
            }

            if (data.recurring_type === "yearly") {
                setRepeatUnit("years");
            } else if (data.recurring_type === "monthly") {
                setRepeatUnit("months");
            } else if (data.recurring_type === "weekly") {
                setRepeatUnit("weeks");
            } else {
                setRepeatUnit("days");
            }
            setNotificationOnEmail(data.is_email)
        }
    }, [id, data]);

    const onFinish = (values) => {
        if (handleValidation()) {
            const req = {
                ...values,
                recurring_type:
                    recurrenceType === "custom"
                        ? repeatUnit === "weeks"
                            ? "weekly"
                            : repeatUnit === "months"
                                ? "monthly"
                                : repeatUnit === "years"
                                    ? "yearly"
                                    : repeatUnit === "days"
                                        ? "daily"
                                        : null // Default if repeatUnit doesn't match any expected value
                        : recurrenceType,
                // recurring_type:
                //     recurrenceType === "custom"
                //         ? repeatUnit === "weeks"
                //             ? "weekly"
                //             : repeatUnit === "months"
                //                 ? "monthly"
                //                 : "yearly"
                //         : recurrenceType,
                recurring_days: repeatUnit === "weeks" ? recurrenceDays : [],
                recurring_ends_on_date:
                    recurrenceType === "Does not repeat"
                        ? dayjs(startDate).format("YYYY-MM-DD")
                        : recurringEndsType === "date"
                            ? dayjs(endDate).format("YYYY-MM-DD")
                            : "",
                start_date: values.start_date
                    ? dayjs(values.start_date).format("YYYY-MM-DD")
                    : "",
                reminder_on_time: values.reminder_on_time ? dayjs(values.reminder_on_time).format("HH:mm") : "",
                recurring_interval: recurrenceInterval,
                recurring_ends_on_type:
                    recurrenceType === "Does not repeat" ? "date" : recurringEndsType,
                recurring_ends_on_occurrences:
                    recurringEndsType === "occurrences" ? afterOccurrence : 0,
                is_email: notificationOnEmail
            };

            id
                ? dispatch(
                    actionUpdateReminder({
                        id,
                        req,
                        handleCloseCreateModal,
                    })
                )
                :
                dispatch(
                    actionCreateReminder({
                        req,
                        handleCloseCreateModal,
                    })
                );
        }
    };

    const clearData = async () => {
        await setCreateModalOpen(false);
        setReminderId("");
        form.resetFields();
        setStartDate(null);
        setRecurrenceInterval(1);
        setRecurrenceType(recurrenceType.DOES_NOT_REPEAT);
        setRecurrenceDays([]);
        setRecurringEndsType(RecurringEndTypes.NEVER);
        setEndDate(null);
        setAfterOccurrence(0);
        setTime(null);
    };

    useEffect(() => {
        if (!createModalOpen) {
            clearData();
        }
    }, [createModalOpen]);

    return (
        <>
            <div className="flex mb-1">
                <img src={quickReminderIcon} alt="" />
                <h1 className="text-[#6883FD] text-[20px] ml-[8px] mt-[5px] font-medium">
                    Reminder
                </h1>
            </div>

            <Form
                className="py-2"
                layout="vertical"
                form={form}
                initialValues={{
                    recurring_type: "Does not repeat",
                    recurring_ends_on_type: "never",
                }}
                name="basic"
                onFinish={onFinish}
                autoComplete="off"
            >
                <Row gutter={[10, 0]}>
                    <Col span={16}>
                        <Form.Item
                            name="title"
                            label="Title"
                            className='mb-3'
                            layout="vertical"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter title!",
                                },
                                {
                                    max: 40,
                                    message: "Title cannot exceed 40 characters!",
                                },
                            ]}
                        >
                            <Input placeholder="Enter Title" style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="reminder_company_user_ids"
                            label="Assign to"
                            className="mb-3"
                            rules={[
                                { required: true, message: "Please select users!" },
                            ]}
                        >
                            <Select
                                mode="multiple"
                                maxTagCount='responsive'
                                allowClear
                                showSearch
                                placeholder="Select users"
                                optionFilterProp="children"
                                className=""
                                loading={getCompanyAllUserLoader}
                                disabled={getCompanyAllUserLoader}
                                filterOption={(input, option) =>
                                    (option?.fullName ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={userOptions}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="Description"
                            name="description"
                            className='mb-3'
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter description!",
                                },
                            ]}
                        >
                            <Input.TextArea
                                rows={3}
                                style={{ background: "white" }}
                                placeholder="Enter Description"
                                className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="start_date"
                            label="On"
                            className='mb-3'
                            layout="vertical"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select date!",
                                },
                            ]}
                        >
                            <DatePicker
                                placeholder='Start Date'
                                format={"DD-MM-YYYY"}
                                minDate={dayjs(new Date())}
                                suffixIcon={<Image src={calendar} alt="calendar" />}
                                style={{ width: "100%" }}
                                onChange={handleDateChange}
                                value={startDate}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="reminder_on_time"
                            label="At"
                            className='mb-3'
                            layout="vertical"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select time!",
                                },
                            ]}
                        >
                            <TimePicker
                                placeholder='Start Time'
                                format={"HH:mm"}
                                suffixIcon={<Image src={clock} alt="clock" />}
                                style={{ width: "100%" }}
                                onChange={handleTimeChange}
                                disabled={!startDate}
                                disabledTime={disabledTime}
                                value={time}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <p className="opacity-0 py-[5px]">Label</p>
                        <Form.Item
                            className="mb-2"
                            name="recurring_type"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select recurring type!",
                                },
                            ]}
                        >
                            <Select
                                style={{ width: "100%" }}
                                placeholder="Select recurrence type"
                                allowClear
                                onClear={() => setRecurrenceType(undefined)}
                                value={recurrenceType}
                                onChange={handleRecurrenceTypeChange}
                                options={recurrenceOptions}
                                disabled={false}
                            />
                        </Form.Item>
                    </Col>

                    {recurrenceType === "custom" && (
                        <Col span={24} className='flex items-center mt-4'>
                            <span className="pr-5 font-popinsMedium">Repeat Every</span>
                            <RepeatInput
                                recurrenceInterval={recurrenceInterval}
                                repeatUnit={repeatUnit}
                                onRepeatValueChange={handleRepeatValueChange}
                                onRepeatUnitChange={handleRepeatUnitChange}
                            />
                        </Col>
                    )}

                    {repeatUnit === "weeks" && (
                        <Col span={14}>
                            <div className="repeat-days flex-items-center gap-x-2 mt-4">
                                <span className="pr-5 font-popinsMedium">Repeat on</span>
                                <Space>
                                    {daysOfWeek.map((day, index) => (
                                        <div
                                            key={index}
                                            className={`${recurrenceDays.includes(day.value)
                                                ? "bg-brand text-white"
                                                : "bg-[#F2F2F2] text-black"
                                                }  cursor-pointer rounded-full  flex items-center justify-center h-7 w-7`}
                                            onClick={() => toggleDay(day.value)}
                                        >
                                            {day.value}
                                        </div>
                                    ))}
                                </Space>
                            </div>

                            {errors.days && (
                                <div className="ant-form-item-explain-error text-error">
                                    {errors.days}
                                </div>
                            )}
                        </Col>
                    )}

                    <Col span={24}
                        className={`${recurrenceType === "Does not repeat" ? "hidden" : ""} `}
                    >
                        <Space size={"middle"} className="mt-4" id="repeat-unit">
                            <span className="font-popinsMedium">Ends</span>

                            <Radio.Group
                                onChange={handleChangeRecurringEndsType}
                                value={recurringEndsType}
                            >
                                <Radio value={RecurringEndTypes.NEVER}>Never</Radio>
                                <Radio value={RecurringEndTypes.DATE}>
                                    <div className="flex items-center flex-col">
                                        <div className="flex items-center">
                                            On
                                            <DatePicker
                                                format={"DD-MM-YYYY"}
                                                placeholder='Select Date'
                                                suffixIcon={false}
                                                minDate={dayjs(new Date(startDate))}
                                                disabled={recurringEndsType === "date" ? false : true}
                                                value={endDate}
                                                onChange={(date) => setEndDate(date)}
                                                style={{ marginLeft: 8, width: "100%" }}
                                            />
                                        </div>
                                        {errors.date && (
                                            <div className="ant-form-item-explain-error absolute bottom-[-32px] text-error">
                                                {errors.date}
                                            </div>
                                        )}
                                    </div>
                                </Radio>
                                <Radio value={RecurringEndTypes.OCCURRENCES}>
                                    <div
                                        style={{ display: "flex", alignItems: "center" }}
                                        className="occurrences-input-wrapper"
                                    >
                                        <span>After</span>
                                        <div>
                                            <InputNumber
                                                type='number'
                                                className="!border-none !bg-white  focus:!border-none focus:!shadow-none focus-within:!shadow-none indent-3 focus:!bg-white"
                                                disabled={
                                                    recurringEndsType === "occurrences" ? false : true
                                                }
                                                min={1}
                                                suffix="occurrences"
                                                controls={false}
                                                value={afterOccurrence}
                                                onChange={(e) => setAfterOccurrence(e)}
                                                // formatter={(value) => `${value} occurrences`}
                                                // parser={(value) => value.replace(" occurrences", "")}
                                                style={{ width: "100%" }}
                                            />
                                        </div>
                                    </div>
                                </Radio>
                            </Radio.Group>
                        </Space>
                    </Col>

                    <Col span={9} className="flex items-center mt-4">
                        <div className="flex items-center justify-between w-full">
                            <div className='flex items-center gap-x-1'>
                                <p className="text-[14px] font-popinsMedium">
                                    Email Notification{" "}
                                </p>
                                <Tooltip title="Enable to receive email notifications for this reminder.">
                                    <InfoCircleOutlined
                                        className='text-[14px] text-[#ababab] cursor-pointer'
                                    />
                                </Tooltip>
                            </div>
                            <Switch
                                onChange={(checked) => setNotificationOnEmail(checked)}
                                checked={notificationOnEmail}
                            />
                        </div>
                    </Col>

                    <Col span={24} className="flex justify-center items-center mt-[30px] !mb-0">
                        <Form.Item className="mb-0">
                            <Button
                                onClick={handleCloseCreateModal}
                                type="button"
                                className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
                            >
                                Cancel
                            </Button>
                        </Form.Item>

                        <Form.Item className="mb-0 ml-4">
                            <Button
                                loading={addReminderLoader || updateReminderLoader}
                                type="primary"
                                className="root-btn min-h-[45px] min-w-[120px]"
                                htmlType="submit"
                            >
                                Save
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default CreateQuickReminder