import plusSvg from "../../../assets/img/plus.svg";

import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Popover,
  Row,
  Select,
  Skeleton,
  Spin,
  Upload,
} from "antd";
import ImgCrop from "antd-img-crop";
import React, { useEffect, useState } from "react";
import { toast } from "sonner";
import editWhite from "../../../assets/img/editWhite.svg";
import LeadIcon from "../../../assets/img/leadsIcon.svg";
import { BASE_URL_UPLOAD } from "../../../config/web-config";
import CustomIconText from "../../common/CustomIconText";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  actionAddLead,
  actionGetLeadDetail,
  actionUpdateLead,
} from "../../../store/services/leadsService";
import { extractFilename, sanitizeName } from "../../helper/common-function";
import { actionGetCompanyAllUsers } from "../../../store/services/usersService";
import {
  actionAddIndustryMaster,
  actionAddLeadRatingMaster,
  actionAddLeadSourceMaster,
  actionAddLeadStatusMaster,
  actionGetIndustryMaster,
  actionGetLeadRatingMaster,
  actionGetLeadSourceMaster,
  actionGetLeadStatusMaster,
} from "../../../store/services/generalMasterService";
import {
  actionGetCountries,
  actionGetStates,
} from "../../../store/services/locationService";
import SkeletonUpdateCard from "../../common/SkeletonUpdateCard";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const CreateLead = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [leadStatusForm] = Form.useForm();
  const [leadSourceForm] = Form.useForm();
  const [ratingForm] = Form.useForm();
  const [industryForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isStateDisabled, setIsStateDisabled] = useState(true);
  const [profilePicUrl, setProfilePicUrl] = useState("");
  const [isLeadStatusPopoverOpen, setIsLeadStatusPopoverOpen] = useState(false);
  const [isLeadSourcePopoverOpen, setIsLeadSourcePopoverOpen] = useState(false);
  const [isRatingPopoverOpen, setIsRatingPopoverOpen] = useState(false);
  const [isIndustryPopoverOpen, setIsIndustryPopoverOpen] = useState(false);
  const { countries, states, cities, getCountryLoader, statesLoader } =
    useSelector((state) => state.location);

  const {
    addLeadLoader,
    updateLeadLoader,
    leadDetailData,
    getLeadDetailLoader,
  } = useSelector((state) => state.leads);
  const { getCompanyAllUserLoader, companyAllUsers } = useSelector(
    (state) => state.users
  );
  const {
    leadStatusData,
    getLeadStatusLoader,
    addLeadStatusLoader,
    getLeadSourceLoader,
    addLeadSourceLoader,
    leadSourceData,
    getLeadRatingLoader,
    leadRatingData,
    addLeadRatingLoader,
    getIndustryLoader,
    addIndustryLoader,
    industryData,
  } = useSelector((state) => state.generalMaster);
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { leadId } = location.state || {};

  const currenCompanyUser =
    user &&
    user.company_users &&
    user.company_users.length > 0 &&
    user.company_users.find((companyUser) => companyUser.status === "ACTIVE");

  useEffect(() => {
    dispatch(actionGetCompanyAllUsers());
    dispatch(actionGetLeadStatusMaster());
    dispatch(actionGetLeadSourceMaster());
    dispatch(actionGetLeadRatingMaster());
    dispatch(actionGetIndustryMaster());
    dispatch(actionGetCountries());
    if (leadId) {
      dispatch(actionGetLeadDetail(leadId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (leadDetailData.country?.id) {
      dispatch(actionGetStates(leadDetailData.country?.id));
    }
  }, [leadDetailData]);

  const handleGetState = (val, data) => {
    dispatch(actionGetStates(data.value));
    form.setFieldsValue({
      state_id: null,
    });
    setIsStateDisabled(false);
  };

  const country =
    (countries &&
      countries.length &&
      countries?.map(({ name, iso2, id, ...data }) => ({
        ...data,
        label: name,
        value: id,
        country_code: iso2,
        id: id,
      }))) ||
    [];

  const state =
    (states &&
      states.length &&
      states?.map(({ name, state_code, id, ...data }) => ({
        ...data,
        label: name,
        value: id,
        state_code,
      }))) ||
    [];

  useEffect(() => {
    if (leadId && leadDetailData) {
      form.setFieldsValue({
        first_name: leadDetailData.first_name,
        last_name: leadDetailData.last_name,
        lead_owner_company_user_id: leadDetailData.lead_owner?.id,
        email: leadDetailData.email,
        secondary_email: leadDetailData.secondary_email,
        phone: leadDetailData.phone,
        mobile: leadDetailData.mobile,
        fax: leadDetailData.fax,
        skype_id: leadDetailData.skype_id,
        twitter: leadDetailData.twitter,
        company_name: leadDetailData.company_name,
        job_title: leadDetailData.job_title,
        number_of_employees: leadDetailData.number_of_employees,
        annual_revenue: leadDetailData.annual_revenue,
        website: leadDetailData.website,
        street: leadDetailData.street,
        country_id: leadDetailData.country?.id,
        state_id: leadDetailData.state?.id,
        city: leadDetailData.city,
        address_line_1: leadDetailData.address_line_1,
        address_line_2: leadDetailData.address_line_2,
        description: leadDetailData.description,
        zip_code: leadDetailData.zip_code,
        lead_status_master_id:
          leadDetailData.lead_status?.lead_status_master_id,
        lead_source_master_id:
          leadDetailData.lead_source?.lead_source_master_id,
        lead_rating_master_id:
          leadDetailData.lead_rating?.lead_rating_master_id,
        industry_master_id: leadDetailData.industry?.industry_master_id,
      });

      if (leadDetailData?.country || leadDetailData?.country != null) {
        setIsStateDisabled(false);
      }
      setProfilePicUrl(leadDetailData?.lead_image);
    }
  }, [leadId, leadDetailData, form]);

  const handleChangeUserPic = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setProfilePicUrl(info.file.response.url);
        toast.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      setLoading(false);
      toast.error(`${info.file.name} file upload failed.`);
    }
  };

  const leadOwners =
    companyAllUsers && companyAllUsers.length > 0
      ? companyAllUsers.map((user) => ({
          key: user.id,
          label: (
            <span>
              <Avatar
                src={user.profile_pic}
                style={{
                  verticalAlign: "middle",
                }}
                className=" mr-2"
              />

              {`${user.first_name ? user.first_name : ""} ${
                user.last_name ? user.last_name : ""
              }`}
            </span>
          ),
          value: user.id,
        }))
      : [];

  const industryOptions =
    industryData &&
    industryData.length > 0 &&
    industryData.map((status) => ({
      label: status.industry,
      value: status.industry_master_id,
    }));

  const leadStatusOptions =
    leadStatusData &&
    leadStatusData.length > 0 &&
    leadStatusData.map((status) => ({
      label: status.lead_status,
      value: status.lead_status_master_id,
    }));

  const leadSourceOptions =
    leadSourceData && leadSourceData.length > 0
      ? leadSourceData.map((source) => ({
          key: source.lead_source_master_id,
          label: source.lead_source,
          value: source.lead_source_master_id,
        }))
      : [];

  const ratingOptions =
    leadRatingData && leadRatingData.length > 0
      ? leadRatingData.map((rating) => ({
          label: rating?.lead_rating,
          value: rating?.lead_rating_master_id,
        }))
      : [];

  const handleLeadStatusPopoverClose = () => {
    setIsLeadStatusPopoverOpen(false);
    leadStatusForm.resetFields();
  };

  const handleLeadSourcePopoverClose = () => {
    setIsLeadSourcePopoverOpen(false);
    leadSourceForm.resetFields();
  };

  const handleRatingPopoverClose = () => {
    setIsRatingPopoverOpen(false);
    ratingForm.resetFields();
  };

  const handleIndustryPopoverClose = () => {
    setIsIndustryPopoverOpen(false);
    industryForm.resetFields();
  };

  const leadSourcePopoverContent = (
    <div className="min-w-[350px]">
      <Form
        form={leadSourceForm}
        onFinish={(values) =>
          dispatch(
            actionAddLeadSourceMaster({
              form,
              values,
              handleCancel: handleLeadSourcePopoverClose,
            })
          )
        }
        layout="vertical"
        className="my-[10px] mx-2 lead-inner-form"
      >
        <Form.Item
          name="lead_source"
          label="Lead Source"
          className="mb-[12px]"
          size="small"
          rules={[
            {
              required: true,
              message: "Please enter lead source!",
            },
          ]}
        >
          <Input
            style={{ background: "white" }}
            placeholder="Enter lead source"
            className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
          />
        </Form.Item>

        <div className="flex justify-start mt-[30px]">
          <Form.Item className="mb-0">
            <Button
              onClick={handleLeadSourcePopoverClose}
              type="button"
              style={{ fontSize: 12 }}
              className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
            >
              Cancel
            </Button>
          </Form.Item>

          <div className="ml-4">
            <Form.Item className="mb-0">
              <Button
                type="primary"
                loading={addLeadSourceLoader}
                className="root-btn min-h-[40px] min-w-[100px]"
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );

  const industryPopoverContent = (
    <div className="min-w-[350px]">
      <Form
        form={industryForm}
        onFinish={(values) =>
          dispatch(
            actionAddIndustryMaster({
              values,
              form,
              handleCancel: handleIndustryPopoverClose,
            })
          )
        }
        layout="vertical"
        className="my-[10px] mx-2 lead-inner-form"
      >
        <Form.Item
          name="industry"
          label="Industry"
          className="mb-[12px]"
          size="small"
          rules={[
            {
              required: true,
              message: "Please enter industry!",
            },
          ]}
        >
          <Input
            style={{ background: "white" }}
            placeholder="Enter Industry"
            className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
          />
        </Form.Item>

        <div className="flex justify-start mt-[30px]">
          <Form.Item className="mb-0">
            <Button
              onClick={handleIndustryPopoverClose}
              type="button"
              style={{ fontSize: 12 }}
              className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
            >
              Cancel
            </Button>
          </Form.Item>

          <div className="ml-4">
            <Form.Item className="mb-0">
              <Button
                type="primary"
                loading={addIndustryLoader}
                className="root-btn min-h-[40px] min-w-[100px]"
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );

  const leadStatusPopoverContent = (
    <div className="min-w-[350px]">
      <Form
        form={leadStatusForm}
        onFinish={(values) =>
          dispatch(
            actionAddLeadStatusMaster({
              values,
              form,
              handleCancel: handleLeadStatusPopoverClose,
            })
          )
        }
        layout="vertical"
        className="my-[10px] mx-2 lead-inner-form"
      >
        <Form.Item
          name="lead_status"
          label="Lead Status"
          className="mb-[12px]"
          size="small"
          rules={[
            {
              required: true,
              message: "Please enter lead status!",
            },
          ]}
        >
          <Input
            style={{ background: "white" }}
            placeholder="Enter lead status"
            className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
          />
        </Form.Item>

        <div className="flex justify-start mt-[30px]">
          <Form.Item className="mb-0">
            <Button
              onClick={handleLeadStatusPopoverClose}
              type="button"
              className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
            >
              Cancel
            </Button>
          </Form.Item>

          <div className="ml-4">
            <Form.Item className="mb-0">
              <Button
                loading={addLeadStatusLoader}
                type="primary"
                className="root-btn min-h-[40px] min-w-[100px]"
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );

  const ratingPopoverContent = (
    <div className="min-w-[350px]">
      <Form
        form={ratingForm}
        onFinish={(values) =>
          dispatch(
            actionAddLeadRatingMaster({
              values,
              form,
              handleCancel: handleRatingPopoverClose,
            })
          )
        }
        layout="vertical"
        className="my-[10px] mx-2 lead-inner-form"
      >
        <Form.Item
          name="lead_rating"
          label="Rating"
          className="mb-[12px]"
          size="small"
          rules={[
            {
              required: true,
              message: "Please enter rating!",
            },
          ]}
        >
          <Input
            style={{ background: "white" }}
            placeholder="Enter rating"
            className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
          />
        </Form.Item>

        <div className="flex justify-start mt-[30px]">
          <Form.Item className="mb-0">
            <Button
              onClick={handleRatingPopoverClose}
              type="button"
              className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
            >
              Cancel
            </Button>
          </Form.Item>

          <div className="ml-4">
            <Form.Item className="mb-0">
              <Button
                loading={addLeadRatingLoader}
                type="primary"
                className="root-btn min-h-[40px] min-w-[100px]"
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );

  const handleFinish = (values) => {
    const data = {
      ...values,
      lead_owner_company_user_id: values.lead_owner_company_user_id
        ? values.lead_owner_company_user_id
        : null,
      industry_master_id: values.industry_master_id
        ? values.industry_master_id
        : null,
      lead_status_master_id: values.lead_status_master_id
        ? values.lead_status_master_id
        : null,
      lead_source_master_id: values.lead_source_master_id
        ? values.lead_source_master_id
        : null,
      lead_rating_master_id: values.lead_rating_master_id
        ? values.lead_rating_master_id
        : null,
      lead_rating_master_id: values.lead_rating_master_id
        ? values.lead_rating_master_id
        : null,
      lead_rating_master_id: values.lead_rating_master_id
        ? values.lead_rating_master_id
        : null,
      state_id: values.state_id ? values.state_id : null,
      country_id: values.country_id ? values.country_id : null,

      lead_image: extractFilename(profilePicUrl),
    };

    leadId
      ? dispatch(actionUpdateLead({ id: leadId, req: data, navigate }))
      : dispatch(actionAddLead({ req: data, navigate }));
  };

  return (
    <section className="main-wrapper">
      <CustomIconText
        image={LeadIcon}
        title={leadId ? "Update Lead" : "Create Lead"}
        backArrow={true}
      />
      <Form
        initialValues={{
          lead_owner_company_user_id: currenCompanyUser?.id,
        }}
        scrollToFirstError={true}
        className="lead-form"
        form={form}
        layout="vertical"
        name="basic"
        onFinish={handleFinish}
        autoComplete="off"
      >
        {/* <Spin spinning={getLeadDetailLoader}> */}
        <Card className="main-card rounded-[10px] mt-5 w-full">
          <p className="text-xl text-[#6883FD] font-popinsMedium">
            Business Details
          </p>

          <Row gutter={[16, 0]}>
            <Col span={24} align="middle">
              <Form.Item className="mb-[12px]" size="small">
                {getLeadDetailLoader ? (
                  <>
                    {Array.from({ length: 1 }).map((_, index) => (
                      <Skeleton.Avatar
                        key={index}
                        active
                        size={100}
                        shape="square"
                        className="flex-shrink-0"
                        style={{ marginRight: "10px" }}
                      />
                    ))}
                  </>
                ) : (
                  <>
                    {profilePicUrl ? (
                      <div className="relative border border-dashed border-brand h-[102px] w-[102px] rounded-[6px]">
                        <img
                          src={profilePicUrl}
                          alt="avatar"
                          className="h-full w-full rounded-[7px]"
                        />

                        <ImgCrop
                          rotationSlider
                          aspect={1 / 1}
                          fillColor="white"
                        >
                          <Upload
                            name="file"
                            listType="picture-card"
                            className="avatar-uploader main-upload"
                            showUploadList={false}
                            action={BASE_URL_UPLOAD}
                            openFileDialogOnClick={true}
                            headers={{
                              Authorization:
                                "Bearer " + localStorage.getItem("crmWebToken"),
                            }}
                            onChange={handleChangeUserPic}
                          >
                            <div className="absolute bottom-0 right-0 px-1.5 py-1 bg-brand  rounded-tl-xl rounded-br-[6px] cursor-pointer flex">
                              <Image
                                width={15}
                                height={15}
                                src={editWhite}
                                className="rounded-lg"
                                preview={false}
                              />
                            </div>
                          </Upload>
                        </ImgCrop>
                      </div>
                    ) : (
                      <ImgCrop aspect={1 / 1} rotationSlider fillColor="white">
                        <Upload
                          name="file"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          action={BASE_URL_UPLOAD}
                          openFileDialogOnClick={true}
                          headers={{
                            Authorization:
                              "Bearer " + localStorage.getItem("crmWebToken"),
                          }}
                          onChange={handleChangeUserPic}
                        >
                          <button
                            style={{
                              border: 0,
                              background: "none",
                            }}
                            type="button"
                          >
                            {loading ? <LoadingOutlined /> : <PlusOutlined />}
                            <div
                              style={{
                                marginTop: 8,
                              }}
                            >
                              Upload
                            </div>
                          </button>
                        </Upload>
                      </ImgCrop>
                    )}
                  </>
                )}
              </Form.Item>
            </Col>

            {getLeadDetailLoader ? (
              <SkeletonUpdateCard length={2} />
            ) : (
              <>
                <Col span={12}>
                  <Form.Item
                    label="First Name"
                    name="first_name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your first name!",
                      },
                    ]}
                    normalize={(value) => {
                      if (!value) return value;
                      return value
                        .replace(/^\s+/g, "") // Remove leading spaces
                        .replace(/\s+/g, " ") // Normalize multiple spaces to a single space
                        .replace(/[^a-zA-Z0-9*]/g, ""); // Allow only letters, numbers, and asterisk (*)
                    }}
                  >
                    <Input placeholder="Enter First Name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Last Name"
                    name="last_name"
                    normalize={sanitizeName}
                    /*  rules={[
          {
            required: true,
            message: "Please enter your last name!",
          },
        ]} */
                  >
                    <Input placeholder="Enter Last Name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    rules={[
                      { required: true, message: "Please select lead owner!" },
                    ]}
                    label="Lead Owner"
                    name="lead_owner_company_user_id"
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select Lead Owner"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label?.props?.children[1] ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      disabled={getCompanyAllUserLoader}
                      options={leadOwners}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Card>

        <Card className="main-card mt-5 w-full">
          <p className="text-xl mb-6 text-[#6883FD] font-popinsMedium">
            Contact Information
          </p>
          {getLeadDetailLoader ? (
            <SkeletonUpdateCard length={4} />
          ) : (
            <Row gutter={[16, 0]}>
              <Col span={12}>
                <Form.Item
                  rules={[
                    {
                                      pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,

                      message: "Please enter a valid email!",
                    },
                  ]}
                  label="Email"
                  name="email"
                >
                  <Input placeholder="Enter Email" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  rules={[
                    {
                                      pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,

                      message: "Please enter a valid email!",
                    },
                  ]}
                  label="Secondary Email"
                  name="secondary_email"
                >
                  <Input placeholder="Enter Secondary Email" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  normalize={(value) => value && value.replace(/[^+\d]/g, "")}
                  label="Phone"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      // max: 15,
                      message: "Please enter phone!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value && value.length > 15) {
                          return Promise.reject(
                            "Phone number cannot be longer than 15 digits!"
                          );
                        }

                        // You can add additional conditions if needed
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Enter Phone Number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  normalize={(value) => value && value.replace(/[^+\d]/g, "")}
                  label="Mobile"
                  name="mobile"
                  rules={[
                    {
                      max: 15,
                      message: "Phone number cannot be longer than 15 digits",
                    },
                  ]}
                >
                  <Input placeholder="Enter Mobile Number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Fax"
                  name="fax"
                  normalize={(value) => value && value.replace(/[^+\d]/g, "")}
                >
                  <Input placeholder="Enter Fax Number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Skype ID"
                  name="skype_id"
                  normalize={sanitizeName}
                >
                  <Input placeholder="Enter Skype ID" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Twitter"
                  name="twitter"
                  normalize={sanitizeName}
                >
                  <Input placeholder="Enter Twitter" />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Card>
        <Card className="main-card mt-5 w-full">
          <p className="text-xl mb-6 text-[#6883FD] font-popinsMedium">
            Company Information
          </p>
          {getLeadDetailLoader ? (
            <SkeletonUpdateCard length={4} />
          ) : (
            <Row gutter={[16, 0]}>
              <Col span={12}>
                <Form.Item
                  label="Company"
                  name="company_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your company!",
                    },
                  ]}
                  normalize={sanitizeName}
                >
                  <Input placeholder="Enter Company Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Job Title"
                  name="job_title"
                  normalize={sanitizeName}
                >
                  <Input placeholder="Enter Title" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  normalize={(value) => value && value.replace(/[^\d]/g, "")}
                  label="Number of Employees"
                  name="number_of_employees"
                >
                  <Input placeholder="Enter Number Of Employees" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Popover
                  placement="topRight"
                  content={industryPopoverContent}
                  trigger="click"
                  open={isIndustryPopoverOpen}
                  onOpenChange={(visible) => {
                    if (!visible) {
                      setIsIndustryPopoverOpen(false);
                      industryForm.resetFields();
                    }
                  }}
                >
                  <div
                    className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                    onClick={() => {
                      setIsIndustryPopoverOpen(!isIndustryPopoverOpen);
                    }}
                  >
                    <Image
                      src={plusSvg}
                      alt="image"
                      height={20}
                      width={20}
                      preview={false}
                    />
                    <h1 className="text-[14px] font-popinsRegular ml-1">
                      Add Industry
                    </h1>
                  </div>
                </Popover>

                <Form.Item label="Industry" name="industry_master_id">
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select industry"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    loading={getIndustryLoader}
                    disabled={getIndustryLoader}
                    options={industryOptions}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Annual Revenue"
                  name="annual_revenue"
                  normalize={sanitizeName}
                >
                  <Input placeholder="Enter Annual Revenue" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Website"
                  rules={[
                    {
                      type: "url",
                      message: "Please enter a valid URL!",
                    },
                  ]}
                  name="website"
                >
                  <Input placeholder="Enter Website URL" />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Card>
        <Card className="main-card mt-5 w-full">
          <p className="text-xl mb-6 text-[#6883FD] font-popinsMedium">
            Location Information
          </p>
          {getLeadDetailLoader ? (
            <SkeletonUpdateCard length={3} />
          ) : (
            <Row gutter={[16, 0]}>
              <Col span={12}>
                <Form.Item
                  label="Street"
                  name="street"
                  normalize={sanitizeName}
                >
                  <Input placeholder="Enter Street" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Country" name="country_id" className="">
                  <Select
                    allowClear
                    showSearch
                    loading={getCountryLoader}
                    onSelect={handleGetState}
                    onClear={() => {
                      form.setFieldsValue({
                        state_id: undefined,
                        country_id: undefined,
                      });
                      setIsStateDisabled(true);
                    }}
                    placeholder="Select Country"
                    optionFilterProp="children"
                    className=""
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    disabled={getCountryLoader}
                    options={country}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="State" name="state_id">
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select State"
                    optionFilterProp="children"
                    disabled={isStateDisabled}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    loading={statesLoader}
                    options={state}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="City" name="city">
                  <Input placeholder="Enter City" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  normalize={(value) => value && value.replace(/[^\d]/g, "")}
                  label="Zip Code"
                  name="zip_code"
                >
                  <Input placeholder="Enter Zip Code" />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Card>
        <Card className="main-card mt-5 w-full">
          <p className="text-xl mb-6 text-[#6883FD] font-popinsMedium">
            Lead Information
          </p>
          {getLeadDetailLoader ? (
            <SkeletonUpdateCard length={2} />
          ) : (
            <Row gutter={[16, 0]}>
              <Col span={12}>
                <Popover
                  placement="topRight"
                  content={leadStatusPopoverContent}
                  trigger="click"
                  open={isLeadStatusPopoverOpen}
                  onOpenChange={(visible) => {
                    if (!visible) {
                      setIsLeadStatusPopoverOpen(false);
                      leadStatusForm.resetFields();
                    }
                  }}
                >
                  <div
                    className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                    onClick={() => {
                      setIsLeadStatusPopoverOpen(!isLeadStatusPopoverOpen);
                    }}
                  >
                    <Image
                      src={plusSvg}
                      alt="image"
                      height={20}
                      width={20}
                      preview={false}
                    />
                    <h1 className="text-[14px] font-popinsRegular ml-1">
                      Add Lead Status
                    </h1>
                  </div>
                </Popover>

                <Form.Item label="Lead Status" name="lead_status_master_id">
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select Lead Status"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    loading={getLeadStatusLoader}
                    disabled={getLeadStatusLoader}
                    options={leadStatusOptions}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Popover
                  placement="bottomRight"
                  content={leadSourcePopoverContent}
                  trigger="click"
                  open={isLeadSourcePopoverOpen}
                  onOpenChange={(visible) => {
                    if (!visible) {
                      setIsLeadSourcePopoverOpen(false);
                      leadSourceForm.resetFields();
                    }
                  }}
                >
                  <div
                    className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                    onClick={() => {
                      setIsLeadSourcePopoverOpen(!isLeadSourcePopoverOpen);
                    }}
                  >
                    <Image
                      src={plusSvg}
                      alt="image"
                      height={20}
                      width={20}
                      preview={false}
                    />
                    <h1 className="text-[14px] font-popinsRegular ml-1">
                      Add Lead Source
                    </h1>
                  </div>
                </Popover>

                <Form.Item label="Lead Source" name="lead_source_master_id">
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select Lead Source"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    disabled={getLeadSourceLoader}
                    loading={getLeadSourceLoader}
                    options={leadSourceOptions}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Popover
                  placement="bottomRight"
                  content={ratingPopoverContent}
                  trigger="click"
                  open={isRatingPopoverOpen}
                  onOpenChange={(visible) => {
                    if (!visible) {
                      setIsRatingPopoverOpen(false);
                      ratingForm.resetFields();
                    }
                  }}
                >
                  <div
                    className="flex items-center cursor-pointer absolute right-0 mr-4 z-10 text-brand"
                    onClick={() => {
                      setIsRatingPopoverOpen(!isRatingPopoverOpen);
                    }}
                  >
                    <Image
                      src={plusSvg}
                      alt="image"
                      height={20}
                      width={20}
                      preview={false}
                    />
                    <h1 className="text-[14px] font-popinsRegular ml-1">
                      Add Rating
                    </h1>
                  </div>
                </Popover>
                <Form.Item label="Rating" name="lead_rating_master_id">
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select Rating"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    loading={getLeadRatingLoader}
                    disabled={getLeadRatingLoader}
                    options={ratingOptions}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Card>
        <Card className="main-card mt-5 w-full">
          <p className="text-xl mb-6 text-[#6883FD] font-popinsMedium">
            Address Information
          </p>
          {getLeadDetailLoader ? (
            <SkeletonUpdateCard length={3} />
          ) : (
            <Row gutter={[16, 0]}>
              <Col span={12}>
                <Form.Item
                  label="Address - 1"
                  name="address_line_1"
                  normalize={sanitizeName}
                >
                  <Input placeholder="Enter Address 1" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Address - 2"
                  name="address_line_2"
                  normalize={sanitizeName}
                >
                  <Input placeholder="Enter Address 2" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Description" name="description">
                  <Input.TextArea placeholder="Enter Description" rows={4} />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Card>

        <div className="flex w-full items-center my-6 justify-center gap-x-5">
          <Form.Item>
            <Button
              onClick={async () => {
                navigate(-1);
              }}
              type="button"
              className="gray-button text-white hover:text-white  min-h-[45px] min-w-[120px]"
              htmlType="button"
            >
              Cancel
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              loading={addLeadLoader || updateLeadLoader}
              type="primary"
              className="root-btn min-h-[45px] min-w-[120px]"
              htmlType="submit"
              // disabled={addLeadLoader || updateLeadLoader}
            >
              {leadId ? "Update" : "Save"}
            </Button>
          </Form.Item>
        </div>
        {/* </Spin> */}
      </Form>
    </section>
  );
};

export default CreateLead;
